import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { closeDrawer } from 'actions/drawerActions';

const useCloseDrawer = () => {
  const dispatch = useDispatch();

  const handleCloseDrawer = useCallback(() => {
    dispatch(closeDrawer());
  }, [dispatch]);

  return handleCloseDrawer;
};

export default useCloseDrawer;
