/**
 * @description Helpers function to format array of objects rendered
 * within the `SelfPayWidgetDrawer` component
 * @param {object} selectedSubscription Parent subscription of journal item
 * passed to `SelfPayWidgetDrawer`
 * @param {string} clientName The client's name
 * @returns {array} transaction details [{}]
 */

const makeTransactionDetails = selectedSubscription => {
  const { programs, start_date, status, amount } = selectedSubscription;
  return [
    {
      label: 'PROGRAM',
      value: programs[0].name
    },
    {
      label: 'SUBSCRIPTION START',
      value: start_date
    },
    {
      label: 'STATUS',
      value: status
    },
    {
      label: 'AMOUNT',
      value: amount
    }
  ];
};

export { makeTransactionDetails };
