import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { API_URL_PREFIX } from 'utils/Constants';
import { catchErrors } from 'utils/apiHelpers';

const Login = ({ loggedIn, login }) => {
  const [message, setMessage] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (loggedIn) {
      history.push('/dashboard');
    } else {
      login();
      axios
        .post(`${API_URL_PREFIX}apiv1/global_notifications/get`)
        .then(res => {
          if (res.data.message) {
            require('./style.scss');
            setMessage(res.data.message.message_body);
          }
        })
        .catch(catchErrors);
    }
  }, [loggedIn, login]);

  return (
    <div id="place-holder">
      {message && (
        <div
          id="global-message"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};

Login.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired
};

export default Login;
