import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import { getToRender } from 'components/FormProvider/utils';
import { FormFieldChange } from 'components/Form/FieldWrap';
import { InputError, InputLabel } from 'components/Input';
import { startCase } from 'lodash';
import './style.scss';

export class SelectConfig {
  static placeholder = 'Select an option';
  static theme(theme) {
    return {
      ...theme,
      borderRadius: 2,
      colors: {
        ...theme.colors,
        text: '#2f3d49',
        primary25: '#f4f4f4',
        primary: '#ccc'
      }
    };
  }
  static styles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused && '0 0 0 1px #4b858e',
      cursor: 'pointer',
      background: '#fbfafb',
      height: !state.isMulti ? '36px' : 'auto',
      minHeight: !state.isMulti ? 'unset' : 'auto'
    }),
    option: styles => ({
      ...styles,
      cursor: 'pointer'
    })
  };
}

const CustomSelect = ({
  autoFocus = false,
  blockGroup = false,
  className = '',
  containerClass = '',
  disabled = false,
  error = false,
  handleCustomSelectChange,
  onChange,
  id = '',
  isSearchable = true,
  label = '',
  multiple = false,
  name,
  options = [],
  placeholder = SelectConfig.placeholder,
  required = false,
  setErrorsCallback,
  tooltip,
  value = '',
  validation,
  control = {},
  // eslint-disable-next-line no-unused-vars
  ...props
}) => {
  if (!blockGroup) {
    blockGroup = control.blockGroup;
  }

  const optionValue = useMemo(() => {
    let val;
    if (value === undefined || value === null) {
      if (control?._default) {
        val = control._default.value;
      } else {
        val = '';
      }
    } else {
      val = options.filter(o => {
        if (o === null || o.value === null) return false;
        if (Array.isArray(value)) {
          return value.includes(o.value);
        }
        return value.toString() === o.value.toString();
      });
    }

    return val;
  }, [value, options]);

  const actualOptions = useMemo(() => {
    const actual = [];
    for (const o of options) {
      if (
        (Array.isArray(o.renderChecks) && !getToRender(o.renderChecks, blockGroup, props.getStateValue)) ||
        o.hidden
      ) {
        continue;
      }

      actual.push(o);
    }

    return actual;
  }, [options]);

  useEffect(() => {
    if ('function' === typeof validation && 'function' === typeof setErrorsCallback) {
      validation(name, blockGroup, optionValue, setErrorsCallback, error, props.getStateValue);
    }
  }, [optionValue]);

  const getOptionLabel = option => {
    if (option.labelFromValue) {
      const valueFromState = props.getStateValue(option.labelValue, blockGroup);
      if (option.labelValue2) {
        const value2FromState = props.getStateValue(option.labelValue2, blockGroup);
        return value2FromState ? option.labelFromValue(valueFromState, value2FromState) : option.label;
      }
      return valueFromState ? option.labelFromValue(valueFromState) : option.label;
    } else if ('string' === typeof option.label && option.label.includes('_')) {
      return startCase(option.label.replace(/_/gi, ' '));
    }
    return option.label;
  };

  function handleChange(o) {
    if (typeof setErrorsCallback === 'function') {
      setErrorsCallback(name, blockGroup, false);
    }

    let hasValue;
    let value;
    if (!multiple) {
      hasValue = (!!o && !!o.value !== undefined && o.value !== null) || isBoolean(o.value);
      value = hasValue ? o.value : '';
    } else {
      hasValue = isArray(o) && !isEmpty(o);
      value = hasValue ? [...o.map(opt => opt.value)] : [];
    }
    const updateBackup = !!multiple && hasValue && o.findIndex(opt => opt.value === '') > -1;
    const change = new FormFieldChange(name, value, blockGroup, o?.callback);
    Object.assign(change.target, o); // not sure this was put here, but this is used in too many places to find out

    handleCustomSelectChange(
      change,
      blockGroup,
      o?.callback ?? false,
      updateBackup,
      control._rootControl,
      control.formGroup?.index
    );

    if (typeof props?.callback === 'function') {
      props.callback(o.value, control, props);
    }
  }

  return (
    <div className={`input-container ${containerClass ?? ''}`}>
      <InputLabel inputId={id} label={label} required={required} tooltip={tooltip} />
      <Select
        menuPlacement="auto"
        id={id}
        styles={SelectConfig.styles}
        theme={SelectConfig.theme}
        getOptionLabel={getOptionLabel}
        closeMenuOnSelect={!multiple}
        className={`base-multi-select ${className}`}
        classNamePrefix="react-select"
        autoFocus={autoFocus}
        options={actualOptions}
        isMulti={!!multiple}
        placeholder={placeholder}
        isSearchable={isSearchable}
        name={name}
        value={optionValue}
        onChange={handleChange}
        isDisabled={disabled}
        isClearable={!!multiple}
        onBlur={props.handleBlur}
        getStateValue={props.getStateValue}
        menuPortalTarget={props.menuPortalTarget}
      />
      <InputError inputId={id} error={error} />
    </div>
  );
};

export default CustomSelect;
