import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from 'components/Clients/BaselineImages/styles';
import StatusList from 'components/Clients/BaselineImages/StatusList';
import { BaselineContext } from './BaselineContext';

const Explainer = () => {
  const { state } = useContext(BaselineContext);
  const classes = useStyles();

  return (
    <div className={classes.bordered}>
      <Typography variant="h5" component="h3" style={{ textAlign: 'left' }}>
        Submitted Images
      </Typography>
      <p>
        These images have {state.active ? '' : 'not'} been approved
        and are {state.active ? '' : 'not'} currently used when
        assessing the authenticity of check-in images.
      </p>
      {!state.canApprove ? (
        <p>
          Clients must have at least one of each type of
          baseline image before a collection can be approved.
        </p>
      ) : null}
      <StatusList />
    </div>
  )
}

export default Explainer;