/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import './style.scss';

const Badge = ({ className = '', number = 0 }) => {
  return (
    number !== 0 && (
      <span
        className={`badge ${className}`}
        title={number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      >
        {number >= 1000 ? parseInt(number / 100) / 10 + 'k+' : number}
      </span>
    )
  );
};

Badge.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string
};

export default Badge;
