/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import CustomSelect from 'components/CustomSelect';
import Label from 'components/Label';
import './style.scss';

const TableFilterSelect = ({
  name,
  label,
  value,
  handleChange,
  options,
  hideLabel = false,
  ...props
}) => {
  return (
    <div className="table-filter-select">
      {!hideLabel ? (
        <Label htmlFor={name} bold>
          {label}
        </Label>
      ) : null}
      <CustomSelect
        name={name}
        value={value}
        handleCustomSelectChange={handleChange}
        options={options}
        {...props}
      />
    </div>
  );
};

export default TableFilterSelect;
