import React from 'react';

const getPasswordHints = (feedback, passwordLength, score) => {
  if (!feedback || !passwordLength) {
    return (
      <span>
        Passwords must receive at least a Good or Strong rating to pass, and
        must be at least 8 characters long.
      </span>
    );
  } else if (score > 2) {
    return (
      <span>
        The supplied password meets or exceeds our strength requirements!
      </span>
    );
  } else {
    const { warning, suggestions } = feedback;
    return (
      <span>{`${
        !!warning && suggestions.length
          ? `${warning}. ${suggestions[0]}`
          : !warning
          ? `${suggestions[0]}`
          : warning
      }`}</span>
    );
  }
};

const evalCheck = (score, passwordLength) => score > 2 && !!passwordLength;

const getColor = (score, hasTestedPw, passwordLength) => {
  let color;

  evalCheck(score, passwordLength)
    ? (color = '#6dc049')
    : !hasTestedPw || !passwordLength
    ? (color = 'inherit')
    : (color = '#da6767');

  return color;
};

export const PasswordHints = ({ score, hasTestedPw, password, feedback }) => {
  const passwordLength = password.length;
  return (
    <div className="small-12 row" style={{ marginBottom: '1rem' }}>
      <label style={{ backgroundColor: 'inherit' }}>
        <span
          style={{
            color: getColor(score, hasTestedPw, passwordLength)
          }}
        >
          {getPasswordHints(feedback, passwordLength, score)}
        </span>
      </label>
    </div>
  );
};
