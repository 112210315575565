import React from 'react';

import StatusBlock from './StatusBlock';
import InfoBlock from './InfoBlock';
import { MapMarker } from './drawerHelpers';

const BlockWrapper = ({
  clientName,
  hasEvent,
  status = false,
  hasPos,
  hasSpoofedLocation,
  block,
  checkUrl,
  eventSubmitted,
  closeDrawer = undefined
}) => {
  const { checkInReason, checkInWindow, date, specifics, blockLabel } = block;
  const blockStatus = status ? status : blockLabel;

  return (
    <>
      {status ? <StatusBlock status={blockStatus} blockLabel={blockLabel} specifics={[specifics]} /> : null}
      <InfoBlock
        clientName={clientName}
        eventSubmitted={eventSubmitted}
        date={date}
        eventTime={!!hasEvent && hasEvent.time}
        checkedInLocation={!!hasPos && <MapMarker lat={hasPos.lat} lng={hasPos.lng} handleClose={closeDrawer} />}
        eventLocation={
          !!hasEvent && (
            <>
              <p>{hasEvent.name}</p>
              <p>
                <MapMarker addr={hasEvent.address} lat={hasEvent.lat} lng={hasEvent.lng} handleClose={closeDrawer} />
              </p>
            </>
          )
        }
        checkInWindow={checkInWindow ? checkInWindow : false}
        checkInReason={checkInReason ? checkInReason : false}
        hasPos={hasPos ? hasPos : false}
        hasSpoofedLocation={hasSpoofedLocation}
        hasEvent={!!hasEvent}
        block={block}
        checkUrl={checkUrl}
        closeDrawer={closeDrawer}
      />
    </>
  );
};

export default BlockWrapper;
