/**
 * @description returns `skus` object for Payments `FormProvider`
 * data, where each key (sku.id) has a boolean value (when it's checked)
 * @param {object} selectedItem the currently selected program
 * @param {array} Reconnect-wide and facility-specific SKUs
 * @returns {object}
 */
const makeSkus = (selectedItem, baseSkus) => {
  const { skus } = selectedItem;
  return baseSkus?.reduce((acc, curr) => {
    const found = skus?.find(
      s => s.price_external_id === curr.price_external_id
    );
    return { ...acc, [curr.id]: !!found };
  }, {});
};

/**
 * @description Instanced when the user wishes to create a new program
 * @returns {object} starter program object with id, value, label, etc.
 */
const makeStarterProgram = () => {
  const id = Math.floor(Math.random() * 100000000);
  const name = 'New Program';
  return {
    id: id,
    value: id,
    label: name,
    name: name,
    skus: [],
    unsubmitted: true
  };
};

/**
 * @description Formats Stripe prices from long-form whole numbers to
 * currency strings
 * @param {integer} amount long-form unit_amount (3000 is 30.00)
 * @returns {string} the formatted amount/cost
 */
const formatCost = amount =>
  amount > 0
    ? `${(amount / 100)?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })} / month`
    : '$0.00';

/**
 * @description Creates SKU options consumed via the schema by `FormProvider`
 * @param {array} skuOptions unformatted SKUs
 * @returns {array} formatted SKUs (rendered via `CheckboxGroup`)
 */
const makeSkuOptions = skuOptions =>
  skuOptions?.map(o => ({
    name: o.id,
    label: o.name,
    subhead: formatCost(o?.amount ?? 0)
  })) ?? [];

/**
 * @description Helper function to determine whether a program may be updatable
 * @param {object} state `FormProvider` state submitted to `handleSubmit` handler
 * @returns {object} {skus: [int, ...], name: string}
 */
const buildUpdateObject = state => {
  const {
    skus: { options },
    program
  } = state;
  const programUpdateData = {};
  const skuIds = [];

  Object.entries(options).forEach(([skuId, bool]) => {
    if (bool) {
      skuIds.push(+skuId);
    }
  });

  programUpdateData.skus = skuIds;

  programUpdateData.name = program.selectedItemName;

  if (Object.keys(programUpdateData).length || program.canUpdate) {
    return programUpdateData;
  }
  return false;
};

export {
  makeSkus,
  makeStarterProgram,
  formatCost,
  makeSkuOptions,
  buildUpdateObject
};
