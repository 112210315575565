import findIndex from 'lodash/findIndex';

function updateClientResults(currentClients, payload) {
  const { id, testResult, testNote } = payload;

  const idx = findIndex(currentClients, client => client.id === id);

  // check to make sure we found something.
  if (idx === -1) {
    return currentClients;
  }

  currentClients[idx].test_result = testResult;
  currentClients[idx].test_notes = testNote;

  return [...currentClients];
}

// reducer reduces action and state -> state
export default function todaysReport(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_TODAYS_REPORT':
      return {
        ...state,
        pending: true
      };
    case 'REMOVE_PENDING_TODAYS_REPORT':
      return {
        ...state,
        pending: false
      };
    case 'LOAD_SUCCESSFUL_TODAYS_REPORT':
      return {
        doneInitialLoad: true,
        pending: false,
        report: {
          call_totals: action.payload.call_totals,
          clients: action.payload.clients,
          date: action.payload.date,
          result_totals: action.payload.result_totals
        },
        hash: action.payload.hash
      };
    case 'UPDATE_TODAYS_TEST_RESULT':
      return {
        ...state,
        report: {
          ...state.report,
          clients: updateClientResults(state.report.clients, action.payload)
        }
      };
  }
  return state;
}
