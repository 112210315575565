/**
 * External dependencies
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import getSingularPlural from 'utils/getSingularPlural';
import './style.scss';

CharacterCount.propTypes = {
  limit: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.bool
};

function CharacterCount({ limit = -Infinity, text = '', tooltip = false }) {
  const remainingChars = limit - text.length;
  const hasFewRemaining = remainingChars < limit / 3;
  const hasReachedMax = 0 === remainingChars;

  return hasReachedMax ? (
    <Fragment />
  ) : (
    <span
      className={`character-count ${
        hasFewRemaining ? 'character-count--few' : ''
      } ${hasReachedMax ? 'character-count--max' : ''}
      ${tooltip ? 'has-tooltip' : ''}`}
    >
      {getSingularPlural(
        `${remainingChars} character remaining`,
        `${remainingChars} characters remaining`,
        remainingChars
      )}
    </span>
  );
}

export default CharacterCount;
