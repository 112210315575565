import React, { useContext, useMemo } from 'react';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';
import SideNavSub from './SideNavSub';

const SideNavClients = ({ pathname }) => {
  const { hasAccessTo } = useContext(AppContext);

  const NestedMenu = useMemo(() => {
    if (pathname.match(/^\/clients(?!\/get)/)) {
      return (
        <SideNavSub
          title="Clients"
          links={[
            {
              to: '/clients',
              label: 'Client List',
              access: hasAccessTo('clients')
            },
            {
              to: '/clients/add',
              label: 'Add Client',
              access: hasAccessTo('add_client')
            },
            {
              to: '/clients/locations',
              label: 'Client Locations',
              access: hasAccessTo('clients') && hasAccessTo('constant_location_tracking')
            }
          ]}
        />
      );
    }

    return null;
  }, [pathname, hasAccessTo]);

  if (hasAccessTo('clients')) {
    return (
      <NavLink to="/clients" data-testid="sidenav-clients" title="Clients" nestedMenu={NestedMenu}>
        <Icon name="clients" size={24} />
        <span className="sidenav__item-label">Clients</span>
      </NavLink>
    );
  }

  return null;
};

export default SideNavClients;
