/**
 * External dependencies
 */
import get from 'lodash/get';

/**
 * Get the list of locations.
 *
 * @param {object} state App state tree.
 *
 * @returns {array} A collection of locations.
 */
const getLocationsList = state => get(state.locations, 'list', []);

export default getLocationsList;
