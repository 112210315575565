import React, { useContext, useMemo } from 'react';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';
import SideNavSub from './SideNavSub';

const SideNavCalendar = ({ calendar }) => {
  const { hasAccessTo } = useContext(AppContext);

  const links = useMemo(
    () => [
      {
        to: '/calendar',
        label: 'Calendar',
        access: hasAccessTo('calendar')
      },
      {
        to: `/calendar/edit/${calendar ? calendar : ''}`,
        label: 'Edit Calendar',
        access: hasAccessTo('edit_calendar')
      },
      {
        to: '/calendar/add',
        label: 'Add Calendar',
        access: hasAccessTo('multi_calendar')
      },
      {
        to: '/calendar/delete',
        label: 'Delete Calendar',
        access: hasAccessTo('delete_calendar')
      },
      {
        to: '/calendar/generate',
        label: 'Advanced Testing',
        access: hasAccessTo('advance_test')
      },
      {
        to: '/calendar/force',
        label: 'Bulk Force',
        access: hasAccessTo('bulk_force')
      },
      {
        to: '/calendar/excuse',
        label: 'Bulk Excuse',
        access: hasAccessTo('bulk_excuse')
      }
    ],
    [calendar, hasAccessTo]
  );

  if (hasAccessTo('calendar') && hasAccessTo('daily_drug_check_ins')) {
    return (
      <NavLink
        to="/calendar"
        data-testid="sidenav-clients"
        title="Calendar"
        nestedMenu={<SideNavSub title="Calendar" links={links} />}
      >
        <Icon name="calendar" className="calendar-icon" size={24} />
        <span className="sidenav__item-label">Calendar</span>
      </NavLink>
    );
  }

  return null;
};

export default SideNavCalendar;
