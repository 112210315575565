import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';

import Icon from 'components/Icon';
import Label from 'components/Label';
import { BaselineContext } from 'components/Clients/BaselineImages/BaselineContext';
import BaselineUtil from 'utils/BaselineUtil';

const useStyles = makeStyles(() => ({
  list: {
    listStyleType: 'none',
    paddingLeft: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    '& li': {
      display: 'flex',
      alignItems: 'center',
      gap: 12
    }
  }
}));

const StatusList = () => {
  const { state: { images } = { images: [] } } = useContext(BaselineContext);
  const classes = useStyles();

  return (
    <>
      <Label bold>Image Statuses</Label>
      <ul className={classes.list}>
        {BaselineUtil.TYPES.map(type => (
          <li key={type}>
            {images?.find(i => i.type === type && !!+i.active) ? (
              <Icon name="checkmark" />
            ) : (
              <Icon name="crossmark" />
            )}
            <span>{BaselineUtil.getLabel(type)}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default StatusList;
