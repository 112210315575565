import moment, { isMoment } from 'moment';
import momentTz from 'moment-timezone';
import { get, omit, startCase, uniq } from 'lodash';
import { isNumeric } from 'lib/util';

class CurriculumUtil {
  constructor() {}

  DATE_FORMAT = 'MMM DD, YYYY, hh:mm A';
  DATE_FORMAT_SHORT = 'MMM DD, YYYY';
  API_DATE_FORMAT = 'YYYY-MM-DD';
  API_DATETIME_FORMAT = 'YYYY-MM-DD H:mm:ss';
  API_TIME_FORMAT = 'H:mm A';
  TIME_FORMAT = 'h:mm A';

  DRAWER_TYPES = {
    TOPIC: 'TOPIC',
    RESOURCE: 'RESOURCE',
    PACKET: 'PACKET',
    ASSIGNMENT: 'ASSIGNMENT'
  };

  SELECTOR_KEYS = {
    TOPICS: 'topics',
    RESOURCES: 'resources',
    PACKETS: 'packets',
    ASSIGNMENTS: 'assignments'
  };

  SUPPORTED_SEGMENTS = [
    'group_id',
    'profile_id',
    'window_id',
    'calendar_id',
    'case_manager_id',
    'client_id',
    'current_treatment',
    'employment_status',
    'education_level',
    'program_type',
    'phase',
    'monitoring_type',
    'gender'
  ];

  ENROLLMENT_TYPES = {
    MANUAL: 'manual',
    AUTOMATIC: 'automatic'
  };

  OVERLAP_TYPES = {
    AND: 'AND',
    OR: 'OR'
  };

  ENROLLMENT_COLORS = {
    manual: '#9ec8d3',
    automatic: '#f9edc7'
  };

  get drawerTypes() {
    return Object.values(this.DRAWER_TYPES);
  }

  isSupportedDrawerType(drawerType) {
    if (!this.drawerTypes.includes(drawerType)) {
      throw new Error(`Drawer type ${drawerType} unsupported, must be one of ${this.drawerTypes.join(', ')}`);
    }

    return true;
  }

  get selectorKeys() {
    return Object.values(this.SELECTOR_KEYS);
  }

  isSupportedSelectorKey(selectorKey) {
    if (!this.selectorKeys.includes(selectorKey)) {
      throw new Error(
        `Invalid Learning Lab (LMS) selector key ${selectorKey} given, must be one of ${this.selectorKeys.join(', ')}`
      );
    }

    return true;
  }

  formatDate(date, short = false, customFormat) {
    if (date) {
      const format = customFormat ? customFormat : short ? this.DATE_FORMAT_SHORT : this.DATE_FORMAT;

      if (isMoment(date)) {
        return date.clone().format(format);
      }

      return moment(date).format(format);
    }

    return '-';
  }

  formatTime(date) {
    if (date) {
      if (isMoment(date)) {
        return date.clone().format(this.API_TIME_FORMAT);
      }

      return moment(date).format(this.API_TIME_FORMAT);
    }

    return '-';
  }

  formatDuration(duration) {
    if ('number' === typeof duration) {
      return moment.utc(duration * 1000).format('H:mm:ss');
    }

    return '-';
  }

  formatRange(min, max, value) {
    if ('number' === typeof min && 'number' === typeof max) {
      const start = moment.utc(min * 1000);

      const descriptor = value === 1 ? 'client' : 'clients';

      if (!min) {
        return `${value} clients have yet to complete this resource`;
      }

      if (max === Infinity) {
        return `${value} ${descriptor} spent at least ${start.format('m [minutes]')} on this resource`;
      }

      const end = moment.utc(max * 1000).format('m [minutes] s [seconds]');

      return `${value} ${descriptor} spent from ${start.format('m')} to ${end} on this resource`;
    }

    return '-';
  }

  getInitialValues({ isEdit = false, isView = false, isDuplicate = false, original = {}, fallback = {} }) {
    if (isEdit || isView) {
      return original;
    }

    /**
     * @NOTE Currently only used when duplicating packets. All we need to do
     * is make sure, on re-render (e.g. the `initialValues` passed to `formik` change),
     * the object is passed without an `id` property. Easy!
     */
    if (isDuplicate && Object.keys(original).length) {
      return omit(original, 'id');
    }

    return fallback;
  }

  buildEmptyMessage(type) {
    return [
      `No ${type} found.`,
      `You may not have access to view ${type}, or no ${type} have been added yet for your account.`,
      `Click <strong>Add ${startCase(this.getSingularFromKey(type))}</strong> to get started.`
    ];
  }

  getTopicsFromResources(resources, asString = false) {
    const topics = new Set();

    for (const resource of resources) {
      if (Array.isArray(resource?.curriculum_topic)) {
        for (const { title } of resource.curriculum_topic) {
          topics.add(title);
        }
      }
    }

    if (asString) {
      return [...topics].join(' ');
    }

    return [...topics];
  }

  getAssigneesFromClientSegments(segments, calendars, clients, groups, users, profiles, windows, asString = false) {
    const assignees = new Set();

    if (segments?.all_clients) {
      assignees.add('All');
    } else {
      for (const key in segments) {
        const segment = segments[key];

        switch (key) {
          case 'group_id': {
            const names = this.getNameFromProps(segment, groups?.list ?? []);
            // addToAssignees(names, key);

            if (Array.isArray(names)) {
              for (const name of names) {
                assignees.add(name);
              }
            }

            break;
          }
          case 'profile_id': {
            const names = this.getNameFromProps(segment, profiles?.list ?? []);
            // addToAssignees(names, key);

            if (Array.isArray(names)) {
              for (const name of names) {
                assignees.add(name);
              }
            }

            break;
          }
          case 'window_id': {
            const names = this.getNameFromProps(segment, windows?.list ?? []);
            // addToAssignees(names, key);

            if (Array.isArray(names)) {
              for (const name of names) {
                assignees.add(name);
              }
            }

            break;
          }
          case 'calendar_id': {
            const names = this.getNameFromProps(segment, calendars?.list ?? []);
            // addToAssignees(names, key);

            if (Array.isArray(names)) {
              for (const name of names) {
                assignees.add(name);
              }
            }

            break;
          }
          case 'case_manager_id': {
            const names = this.getNameFromProps(segment, users?.list ?? []);
            // addToAssignees(names, key);

            if (Array.isArray(names)) {
              for (const name of names) {
                assignees.add(name);
              }
            }

            break;
          }
          case 'client_id': {
            const names = this.getNameFromProps(segment, clients?.list ?? []);
            // addToAssignees(names, key);

            if (Array.isArray(names)) {
              for (const name of names) {
                assignees.add(name);
              }
            }

            break;
          }
          case 'current_treatment':
          case 'employment_status':
          case 'education_level':
          case 'program_type':
          case 'phase':
          case 'monitoring_type':
          case 'gender': {
            if (Array.isArray(segment)) {
              for (const item of segment) {
                assignees.add(item);
              }
            }
            break;
          }
          default:
            break;
        }
      }
    }

    if (asString) {
      return [...assignees].join(' ');
    }

    return [...assignees];
  }

  getNameFromProps(segment, prop) {
    if (Array.isArray(segment) && Array.isArray(prop)) {
      const names = [];

      for (const id of segment) {
        const found = prop.find(p => +p.id === +id);

        names.push(found?.full_name ?? found?.name);
      }

      return names.filter(Boolean);
    }

    return undefined;
  }

  getSingularFromKey(key) {
    if (this.isSupportedSelectorKey(key)) {
      return key.slice(0, -1);
    }
  }

  buildAssignmentRequestData(values, timezone) {
    return {
      curriculum_packet_id: values.curriculum_packet_id,
      start_date: this.getAssignmentStartDateForSubmission(values.start_date, timezone),
      due_in_days: values.due_in_days,
      create_alert: values?.enrollment_type === this.ENROLLMENT_TYPES.AUTOMATIC ? false : values.create_alert,
      client_segments: this.buildClientSegmentsForRequest(values),
      curriculum_resource_details: this.buildCurriculumResourceDetailsForRequest(values),
      enrollment_type: values.enrollment_type,
      overlap_type: values?.overlap_type ?? this.OVERLAP_TYPES.OR,
      client_completion: values?.client_completion ?? true
    };
  }

  getAssignmentStartDateForSubmission(startDate, timezone) {
    const date = isMoment(startDate) ? startDate.format(this.API_DATE_FORMAT) : moment().format(this.API_DATE_FORMAT);
    const time = moment().format('H:mm:ss');
    const final = moment(`${date} ${time}`, this.API_DATETIME_FORMAT);

    if (moment(final).isSame(moment(), 'day')) {
      final.add(10, 'minutes');
    }

    return momentTz(final)
      .tz(timezone)
      .format(this.API_DATETIME_FORMAT);
  }

  buildClientSegmentsForRequest(values) {
    return {
      group_id: values.group_id,
      profile_id: values.profile_id,
      window_id: values.window_id,
      calendar_id: values.calendar_id,
      case_manager_id: values.case_manager_id,
      current_treatment: values.current_treatment,
      employment_status: values.employment_status,
      education_level: values.education_level,
      program_type: values.program_type,
      phase: values.phase,
      monitoring_type: values.monitoring_type,
      gender: values.gender,
      client_id: values.client_id,
      all_clients: values.all_clients
    };
  }

  buildCurriculumResourceDetailsForRequest(values) {
    return values.curriculum_resource_details.map((detail, i) => {
      if (isNumeric(values.due_in_days) && isNumeric(detail.due_in_days)) {
        if (+detail.due_in_days > +values.due_in_days) {
          detail.due_in_days = values.due_in_days;
        }
      } else if (!isNumeric(values.due_in_days) && isNumeric(detail.due_in_days)) {
        detail.due_in_days = null;
      }

      if (!isNumeric(detail.order)) {
        detail.order = i;
      }

      return detail;
    });
  }

  buildClientsFromStore(clients) {
    if (!Array.isArray(clients)) {
      return [];
    }

    return clients
      .filter(c => c.a && c.app_status !== 'no-device')
      .map(c => ({
        id: c.id,
        active: c.a,
        name: `${c.f} ${c.l}`,
        // make it an array
        calendar_id: [c.ca],
        // already an array
        group_id: c.cg,
        case_manager_id: c.cm,
        profile_id: c.tp,
        window_id: [c.w]
      }));
  }

  buildAssigneesOptions(calendars, clients, groups, users, profiles, windows, user, hasAccessTo, isManualEnrollment) {
    return {
      calendar_id: hasAccessTo('multi_calendar')
        ? this.getOptionsForType(calendars, 'calendars')
        : this.getCalendarForUser(user, calendars),
      client_id: isManualEnrollment ? this.getOptionsForType(clients, 'clients') : [],
      group_id: this.getOptionsForType(groups, 'groups'),
      case_manager_id: hasAccessTo('user_management') ? this.getOptionsForType(users, 'users') : [],
      profile_id: this.getOptionsForType(profiles, 'profiles'),
      window_id: this.getOptionsForType(windows, 'windows')
    };
  }

  getOptionsForType(elements, type) {
    switch (type) {
      case 'calendars':
      case 'groups':
      case 'profiles':
      case 'windows':
        return elements.map(e => ({
          label: e.name,
          value: e.id
        }));
      case 'clients':
        return elements.map(e => ({
          ...e,
          label: e.name,
          value: e.id
        }));
      case 'users':
        return elements.map(e => ({
          label: `${e.first_name} ${e.last_name}`,
          value: e.id
        }));
      default:
        return [];
    }
  }

  getCalendarForUser(user, calendars) {
    const userCalendarOptions = [];

    for (const userCalendar of user.calendars) {
      const calendar = calendars.find(c => +c.id === +userCalendar.calendar_id);

      if (calendar) {
        userCalendarOptions.push({
          label: calendar.name,
          value: calendar.id
        });
      }
    }

    return userCalendarOptions;
  }

  getAssignmentItemStyles(enrollmentType) {
    return {
      backgroundColor: this.ENROLLMENT_COLORS[enrollmentType]
    };
  }

  getAssignmentDrawerResourceClasses(curriculum_resource) {
    const classes = {};

    if (Array.isArray(curriculum_resource)) {
      for (let i = 0; i < curriculum_resource.length; i++) {
        const engagements = get(curriculum_resource, `${[i]}.engagements.clients`, []);

        if (Array.isArray(engagements) && engagements.length) {
          for (const engagement of engagements) {
            if (engagement?.duration > 0) {
              classes[i] = 'honeydew';
            }
          }
        }
      }
    }

    return classes;
  }

  getAssignmentNextDisabled(activeStep, values, isClientContext) {
    if (isClientContext) {
      return this.getAssignmentClientNextDisabled(activeStep, values);
    }

    return this.getAssignmentFacilityDisabled(activeStep, values);
  }

  getAssignmentPromptWhen(isClientContext, hasChanged, activeStep) {
    if (isClientContext) {
      return hasChanged && ![0, 4].includes(activeStep);
    }

    return hasChanged && ![0, 6].includes(activeStep);
  }

  // eslint-disable-next-line no-unused-vars
  getAssignmentClientNextDisabled(activeStep, values) {
    switch (activeStep) {
      case 0:
      case 3:
      case 4:
      case 5: {
        return false;
      }
      case 1: {
        if (!values?.curriculum_packet_id) {
          return true;
        } else if (isNumeric(values?.due_in_days) && !values?.start_date) {
          return true;
        }
        return false;
      }
    }
  }

  getAssignmentFacilityDisabled(activeStep, values) {
    switch (activeStep) {
      case 0:
      case 1:
      case 3:
      case 6:
      case 7: {
        return false;
      }
      case 2: {
        if (!values?.curriculum_packet_id) {
          return true;
        } else if (isNumeric(values?.due_in_days) && !values?.start_date) {
          return true;
        }
        return false;
      }
      case 4: {
        if (values?.all_clients) {
          return false;
        }

        return !this.SUPPORTED_SEGMENTS.some(segment => Array.isArray(values?.[segment]) && values[segment].length);
      }
    }
  }

  getDraggableResourceTooltip({ enrollment_type, due_in_days }) {
    if (enrollment_type === this.ENROLLMENT_TYPES.AUTOMATIC) {
      return 'You cannot set a deadline for resources with the Automatic enrollment type';
    }

    if (due_in_days) {
      return 'The deadline for specific resources has to be on or before the deadline for the Packet';
    }

    return 'You cannot set a deadline for resources without first setting a deadline for the Packet';
  }

  getDueInDaysDate(date, due_in_days) {
    if (isMoment(date) && isNumeric(due_in_days)) {
      return date
        .clone()
        .add(due_in_days, 'days')
        .format(this.DATE_FORMAT_SHORT);
    }

    if (moment(date).isValid() && isNumeric(due_in_days)) {
      return moment(date)
        .add(due_in_days, 'days')
        .format(this.DATE_FORMAT_SHORT);
    }

    return 'N/A';
  }

  mutateStateForType(curriculum, type, items) {
    switch (type) {
      case this.SELECTOR_KEYS.TOPICS:
        return this.mutateTopicsForInUse(curriculum, items);
      case this.SELECTOR_KEYS.RESOURCES:
        return this.mutateResourcesForInUse(curriculum, items);
      case this.SELECTOR_KEYS.PACKETS:
        return this.mutatePacketsForInUse(curriculum, items);
      case this.SELECTOR_KEYS.ASSIGNMENTS:
        return this.mutateAssignmentsForInUse(items);
      default:
        return items;
    }
  }

  mutateAssignmentsForInUse(assignments = []) {
    const now = moment().format('YYYY-MM-DD H:mm:ss');

    for (const assignment of assignments) {
      const { start_date, due_in_days, enrollment_type, curriculum_packet } = assignment;
      const isAutomatic = enrollment_type === this.ENROLLMENT_TYPES.AUTOMATIC;
      const isManual = !isAutomatic;

      if (isAutomatic || (isManual && !due_in_days)) {
        assignment.in_use = true;
        continue;
      }

      if (enrollment_type == this.ENROLLMENT_TYPES.MANUAL) {
        const deadline = moment(start_date)
          .add(due_in_days, 'days')
          .endOf('day')
          .format('YYYY-MM-DD H:mm:ss');
        const pastDeadline = moment(now).isAfter(moment(deadline), 'minute');

        assignment.in_use = pastDeadline === false;

        if (!pastDeadline) {
          resourcesLoop: for (const resource of get(curriculum_packet, 'curriculum_resource', [])) {
            for (const client of get(resource, 'engagements.clients', [])) {
              if (!client.completed) {
                assignment.in_use = true;
                break resourcesLoop;
              }
            }
          }
        }
      }

      /**
       * @NOTE This is just in case
       */
      if ('undefined' === typeof assignment.in_use) {
        assignment.in_use = false;
      }
    }

    return assignments;
  }

  mutateTopicsForInUse(curriculum, topics) {
    const assignments = get(curriculum, 'assignments.items', []);
    const idSet = new Set();

    for (const assignment of assignments) {
      const { in_use, curriculum_packet } = assignment;
      const resources = get(curriculum_packet, 'curriculum_resource', []);

      for (const resource of resources) {
        const resourceTopics = get(resource, 'curriculum_topic', []);

        for (const resourceTopic of resourceTopics) {
          if (in_use) {
            idSet.add(+resourceTopic.id);
          }
        }
      }
    }

    for (const topic of topics) {
      topic.in_use = idSet.has(+topic.id);
    }

    return topics;
  }

  mutateResourcesForInUse(curriculum, resources) {
    const assignments = get(curriculum, 'assignments.items', []);
    const idSet = new Set();

    for (const assignment of assignments) {
      const { in_use, curriculum_packet } = assignment;
      const assignmentResources = get(curriculum_packet, 'curriculum_resource', []);

      for (const resource of assignmentResources) {
        if (in_use) {
          idSet.add(+resource.id);
        }
      }
    }

    for (const resource of resources) {
      resource.in_use = idSet.has(+resource.id);
    }

    return resources;
  }

  mutatePacketsForInUse(curriculum, packets) {
    const assignments = get(curriculum, 'assignments.items', []);
    const idSet = new Set();

    for (const assignment of assignments) {
      const { in_use, curriculum_packet } = assignment;

      if (in_use) {
        idSet.add(+curriculum_packet.id);
      }
    }

    for (const packet of packets) {
      packet.in_use = idSet.has(+packet.id);
    }

    return packets;
  }

  getLongestWord(words, padding = 48) {
    const spans = words.map(str => {
      const span = document.createElement('span');
      span.append(str);
      Object.assign(span.style, {
        position: 'absolute',
        visibility: 'hidden',
        fontSize: '14px',
        fontWeight: 400
      });

      return span;
    });

    document.querySelector('html').prepend(...spans);

    const maxPixels = Math.max(...spans.map(span => span.getBoundingClientRect().width));

    spans.forEach(span => span.remove());

    return maxPixels + padding;
  }

  getRandomColor(brightness) {
    return '#' + this.randomChannel(brightness) + this.randomChannel(brightness) + this.randomChannel(brightness);
  }

  randomChannel(brightness) {
    const r = 255 - brightness;
    const n = 0 | (Math.random() * r + brightness);
    const s = n.toString(16);

    return s.length === 1 ? '0' + s : s;
  }

  percentage(segmentValue, total) {
    if ('number' === typeof segmentValue && 'number' === typeof total) {
      return ((100 * segmentValue) / total).toFixed(2);
    }
  }

  getResourcesMetricsTooltip(payload) {
    const key = get(payload, '[0].dataKey');
    const data = get(payload, '[0].payload');
    const assignees = get(data, 'assignees', 0);
    const name = get(data, 'name', '').toLowerCase();
    const plural = assignees === 1 ? '' : 's';
    const base = `Of ${assignees} client${plural},`;
    const count = get(data, key);
    const toHave = this.getToHave(count);

    switch (key) {
      case 'value': {
        return `${count} / ${assignees}`;
      }
      case 'open': {
        return `${base} ${data?.open ?? 0} ${toHave} yet to submit this resource`;
      }
      case 'opened': {
        return `${base} ${data.opened} ${toHave} engaged with this resource`;
      }
      case 'unopened': {
        return `${base} ${data.unopened} ${toHave} yet to engage with this resource`;
      }
      case 'completed': {
        return `${base} ${data.completed} ${toHave} completed this resource`;
      }
      case 'incomplete': {
        return `${base} ${data.incomplete} ${toHave} failed to submit this resource on time`;
      }
      default: {
        return this.getChartsFallback(name);
      }
    }
  }

  getChartsFallback(name) {
    return [
      `There is no ${name} engagement information to show. If you are not an Administrator of the account,`,
      'you will not see information about assignments made by Administrators here.'
    ].join(' ');
  }

  getToHave(count) {
    return count === 1 ? 'has' : 'have';
  }

  getResourcesMetricsData(resources, engagements, isCompletions, isSimple) {
    if (isSimple) {
      if (isCompletions) {
        return this.makeDataForSimpleCompletions(engagements);
      }

      return this.makeDataForSimpleEngagments(engagements);
    }

    return resources.map(r => {
      const id = +r.id;
      const engagementsForResource = engagements
        ?.filter(e => this.filterResourceMetricsByEngagement(e, id, isCompletions))
        .map(e => ({
          ...e,
          deadline: isCompletions ? moment(this.getDueInDaysDate(e.start_date, e.due_in_days)).endOf('day') : null
        }));

      const assignees = uniq(engagementsForResource.map(e => e.id));

      if (isCompletions) {
        return this.makeDatumForCompletion(r.title, id, assignees, engagementsForResource);
      }

      return this.makeDatumForEngagement(r.title, id, assignees, engagementsForResource);
    });
  }

  getResourcesTotals(data = []) {
    let completions = 0;
    let incompleted = 0;
    let open = 0;
    let opened = 0;
    let unopened = 0;
    let value = 0;

    for (const datum of data) {
      if (datum.completed > 0) {
        completions++;
      }

      if (datum.incomplete > 0) {
        incompleted++;
      }

      if (datum.open > 0) {
        open++;
      }

      if (datum.opened > 0) {
        opened++;
      }

      if (datum.unopened > 0) {
        unopened++;
      }

      if (datum.value) {
        value++;
      }
    }

    return {
      hasCompletions: completions > 0,
      hasIncompletions: incompleted > 0,
      hasOpens: open > 0,
      hasOpened: opened > 0,
      hasUnopened: unopened > 0,
      hasValue: value > 0
    };
  }

  filterResourceMetricsByEngagement(engagement, curriculum_resource_id, isCompletions) {
    if (+engagement.curriculum_resource_id === curriculum_resource_id) {
      if (
        isCompletions &&
        engagement.enrollment_type === this.ENROLLMENT_TYPES.MANUAL &&
        engagement.due_in_days !== null
      ) {
        return true;
      } else if (!isCompletions && engagement.due_in_days === null) {
        return true;
      }
    }

    return false;
  }

  makeDatumForCompletion(title, id, assignees, engagementsForResource) {
    const hasEngagements = !!engagementsForResource.length;
    const completed = engagementsForResource.filter(e => e.completed);
    const percentCompleted = hasEngagements ? this.percentage(completed.length, engagementsForResource.length) : 0;
    const notCompleted = engagementsForResource.filter(e => !e.completed);
    const incomplete = notCompleted.filter(e => moment().isAfter(e.deadline));
    const percentIncomplete = hasEngagements ? this.percentage(incomplete.length, engagementsForResource.length) : 0;
    const isOpen = notCompleted.filter(e => moment().isBefore(e.deadline));
    const percentOpen = hasEngagements ? this.percentage(isOpen.length, engagementsForResource.length) : 0;

    return {
      title: title,
      id: id,
      assignees: assignees.length,
      completed: !assignees.length ? 0 : completed.length,
      percent_completed: percentCompleted,
      incomplete: !assignees.length ? 0 : incomplete.length,
      percent_incomplete: percentIncomplete,
      open: isOpen.length,
      open_percentage: percentOpen
    };
  }

  makeDataForSimpleCompletions(engagements) {
    let completed = 0;
    let incompleteNotOpened = 0;
    let incompleteOpened = 0;
    let total = 0;

    for (const engagement of engagements) {
      if (engagement.due_in_days !== null) {
        total++;

        if (engagement.completed) {
          completed++;
        } else {
          if (engagement.duration > 0) {
            incompleteOpened++;
          } else {
            incompleteNotOpened++;
          }
        }
      }
    }

    return [
      {
        title: 'Completed',
        fill: '#00b050d9',
        value: completed,
        assignees: total,
        message: 'successfully completed'
      },
      {
        title: 'Incomplete - Past Deadline',
        fill: '#eeeeee',
        value: incompleteNotOpened,
        assignees: total,
        message: 'not opened or completed'
      },
      {
        title: 'Incomplete - Future Deadline',
        fill: '#ffd966d9',
        value: incompleteOpened,
        assignees: total,
        message: 'opened but not completed'
      }
    ];
  }

  makeDataForSimpleEngagments(engagements) {
    let unopened = 0;
    let opened = 0;
    let total = 0;

    for (const engagement of engagements) {
      if (engagement.due_in_days === null) {
        total++;

        if (engagement.duration > 0) {
          opened++;
        } else {
          unopened++;
        }
      }
    }

    return [
      {
        title: 'Opened',
        fill: '#ffd966d9',
        value: opened,
        assignees: total,
        message: 'opened'
      },
      {
        title: 'Unopened',
        fill: '#eeeeee',
        value: unopened,
        assignees: total,
        message: 'unopened'
      }
    ];
  }

  makeDatumForEngagement(title, id, assignees, engagementsForResource) {
    const opened = engagementsForResource.filter(e => e.duration > 0);
    const unopened = engagementsForResource.filter(e => !e.duration);

    return {
      title: title,
      id: id,
      assignees: assignees.length,
      opened: opened.length,
      percentage_opened: engagementsForResource.length
        ? this.percentage(opened.length, engagementsForResource.length)
        : 0,
      unopened: unopened.length,
      percentage_unopened: engagementsForResource.length
        ? this.percentage(unopened.length, engagementsForResource.length)
        : 0
    };
  }
}

export default new CurriculumUtil();
