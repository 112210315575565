import { SelectOption } from 'components/Clients/ClientsGet/Info/BasicInfo/Schemas/helpers';
import { get, isEmpty, keyBy } from 'lodash';

import curriculumUtil from 'utils/CurriculumUtil';

export const getItemsByKey = key => ({ curriculum }) => {
  curriculumUtil.isSupportedSelectorKey(key);

  return curriculum[key].items;
};

export const getItemByKey = key => ({ curriculum }) => {
  curriculumUtil.isSupportedSelectorKey(key);

  return curriculum[key].item;
};

export const getBulkLoading = key => ({ curriculum }) => {
  curriculumUtil.isSupportedSelectorKey(key);

  return curriculum[key].pending || !curriculum[key].doneInitialLoad;
};

export const getCurriculumError = state => {
  return state.curriculum.error;
};

export const getCurriculumDrawerProps = ({ drawer }) => {
  return {
    isEdit: get(drawer, 'drawerProps.isEdit', false),
    isNew: get(drawer, 'drawerProps.isNew', false),
    isView: get(drawer, 'drawerProps.isView', false),
    isDrawerOpen: get(drawer, 'drawerOpen', false),
    isDuplicate: get(drawer, 'drawerProps.isDuplicate', false)
  };
};

export const getPropsForResourceDrawer = ({ curriculum, facility }) => {
  const topics = getItemsByKey('topics')({ curriculum });

  return {
    forms: facility.isRequestingFacilityForms ? [] : facility.forms.map(f => new SelectOption(f.name, f.id)),
    topics: topics.map(t => new SelectOption(t.title, t.id))
  };
};

export const getOptionsFromList = key => ({ curriculum }) => {
  curriculumUtil.isSupportedSelectorKey(key);

  if (Array.isArray(curriculum?.[key]?.items)) {
    const filtered = [];

    for (const item of curriculum[key].items) {
      if (!item.hidden) {
        filtered.push(new SelectOption(item.title, item.id));
      }
    }
    
    return filtered;
  }

  return [];
};

export const getResourcesCustomizationDetails = (curriculum_packet_id, due_in_days) => ({ curriculum }) => {
  if (!curriculum_packet_id) {
    return [];
  }

  const packet = curriculum?.packets?.items?.find(p => +p.id === +curriculum_packet_id);

  if (!isEmpty(packet) && Array.isArray(packet.curriculum_resource)) {
    return packet.curriculum_resource.map((r, i) => ({
      id: r.id,
      title: r.title,
      due_in_days: due_in_days,
      order: i
    }));
  }

  return [];
};

export const getPacketById = curriculum_packet_id => ({ curriculum }) => {
  if (!curriculum_packet_id) {
    return {};
  }

  return curriculum.packets.items.find(p => +p.id === +curriculum_packet_id);
};

export const getItemSubmitting = ({ curriculum }) => curriculum.isItemSubmitting;

export const getClientsForResource = engagements => ({ clients }) => {
  const clientsMap = keyBy(clients.list, 'id');

  const engagementClients = get(engagements, 'clients', []);

  if (engagementClients.length) {
    const clientsWithNames = engagementClients.map(e => {
      const client = get(clientsMap, e.client_id, {});

      return {
        ...e,
        name: client?.f + ' ' + client?.l
      };
    });

    engagements.clients = clientsWithNames;
  }

  return engagements;
};

export const getResourceAccessTracking = metrics => ({ clients }) => {
  const clientsMap = keyBy(clients.list, 'id');

  if (Array.isArray(metrics?.engagements)) {
    return metrics.engagements.map(e => {
      const client = get(clientsMap, e.client_id, {});
      const date = get(e, 'date_completed', e.updated);

      return {
        id: e.id,
        name: client?.f + ' ' + client?.l,
        resource: e.resource_title,
        packet: e.packet_title,
        date: curriculumUtil.formatDate(date, true),
        time: curriculumUtil.formatTime(date),
        length: curriculumUtil.formatDuration(e.duration),
        completed: e.completed ? 'Yes' : 'No'
      };
    });
  }

  return [];
};
