export class FormStates {
  static hasChanged = 'hasChanged'
  static hasNoErrors = 'hasNoErrors'
  static canSave = 'canSave'
}

export class RenderCheck {
  static isEqual(key, value) {
    return new RenderCheck(key, value, 'isEqual')
  }
  constructor(key, value, method) {
    this.key = key
    this.value = value
    this.method = method
  }
}

export class RenderChecks {
  static hasChanged = RenderCheck.isEqual(FormStates.hasChanged, true)
  static hasNotChanged = RenderCheck.isEqual(FormStates.hasChanged, false)
  static hasNoErrors = RenderCheck.isEqual(FormStates.hasNoErrors, true)
  static hasErrors = RenderCheck.isEqual(FormStates.hasNoErrors, false)
}
