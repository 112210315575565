/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import './style.scss';

const TableRow = ({ children, className, ...props }) => (
  <tr className={`base-tr${className ? ` ${className}` : ''}`} {...props}>
    {children}
  </tr>
);

export default TableRow;
