import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';

import { closeDrawer } from 'actions/actionCreators';
import {
  acknowledgeFormResponseChanged,
  getChangedFormResponses
} from 'utils/apiHelpers';
import { useToast } from 'components/Toast';
import BlockWrapper from '../common/BlockWrapper';
import InfoBlockRow from '../InfoBlockRow.js';
import Icon from 'components/Icon';
import { getStatusStyles } from 'components/Violations/violations-helpers';
import Button from 'components/Button';
import ActionButtons from './ActionButtons';
import Loading from 'components/Helpers/Loading';
import ClientNameLink from 'components/ClientNameLink';
import './style.scss';

function TodoFormResponseChanged({
  drawer: {
    drawerOpen,
    drawerProps: { todo, getCallBack }
  },
  closeDrawer
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState({});

  const toast = useToast();

  useEffect(() => {
    fetchChangedFormResponses();
  }, []);

  const fetchChangedFormResponses = useCallback(async () => {
    const response = await getChangedFormResponses(
      todo.client_id,
      todo.related_external_id
    );
    setResult(response.data.result);
    setIsLoading(false);
  }, []);

  const handlePrint = useCallback(e => {
    e?.preventDefault();

    const body = document.querySelector('body');
    body.classList.add('print-violations');

    window.print();
  }, []);

  const handleAcknowledgeTodo = useCallback(() => {
    acknowledgeFormResponseChanged(todo.client_id, todo.external_id).then(
      response => {
        if (response.data.status == 'success') {
          getCallBack();
          closeDrawer();
          toast.addToast({
            duration: 3000,
            type: 'checkmark',
            content: `Todo successfully acknowledged.`
          });
        } else {
          toast.addToast({
            duration: 3000,
            type: 'warning',
            content: `There was an error acknowledging the todo, please try again.`
          });
        }
      }
    );
  }, [todo.client_id, todo.external_id, getCallBack, closeDrawer, toast]);

  const { ...todoStyle } = getStatusStyles(todo.status);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="vc-container">
      <div className="vc-container__scroll-wrapper">
        <div className="print-only">
          <img className="print-logo" src="/static/img/r7t-logo.jpg" />
          <h2>{moment(todo.created_at).format('MMM D, YYYY h:mm A')}</h2>
          <h2>
            Client Event for{' '}
            {todo.client.first_name + ' ' + todo.client.last_name}
          </h2>
        </div>
        <div
          className={classnames('vc-container__header', {
            'drawer-open': drawerOpen
          })}
        >
          <h3 className="vc-container__header--heading">Client Event</h3>
          <div className={classnames('vc-status', todoStyle)}>
            <span>{todo.status}</span>
          </div>
        </div>
        <div className="vc-container__print-button">
          <Button secondary micro handleButtonClick={handlePrint}>
            Print
          </Button>
        </div>
        <BlockWrapper
          clientName={<ClientNameLink client_id={todo.client_id} clientName={`${todo.client.first_name} ${todo.client.last_name}`} />}
          closeDrawer={closeDrawer}
          status={todo.status}
          block={{
            blockLabel: 'Form Response Changed'
          }}
        />
        <div className="drawer-body vc-container__info-block form-changed">
          {[
            ['Form Name', result.form_name],
            [
              'Latest',
              // eslint-disable-next-line
              <Link
                to={`/clients/get/${todo.client_id}/forms/${todo.related_external_id}`}
              >
                {moment(result.latest_date).format('MMM D, YYYY h:mm A')}&nbsp;
                <Icon name="chevron-right" />
              </Link>
            ],
            [
              'Previous',
              // eslint-disable-next-line
              <Link
                to={`/clients/get/${todo.client_id}/forms/${result.last_submission_id}`}
              >
                {moment(result.prior_date).format('MMM D, YYYY h:mm A')}&nbsp;
                <Icon name="chevron-right" />
              </Link>
            ]
          ].map((e, i) => (
            <InfoBlockRow key={i} title={e[0]} value={e[1]} />
          ))}
        </div>
        <div className="info-block__list">
          <h4 className="changes-header">RESPONSES CHANGED</h4>
          <ul>
            {result.differences.map((d, i) => (
              <>
                <li key={i} className="question">
                  {i + 1}.&nbsp;{d.question}
                </li>
                <li className="after">Latest:&nbsp;{d.after}</li>
                <li className="before">Previous:&nbsp;{d.before}</li>
              </>
            ))}
          </ul>
        </div>
        <ActionButtons
          drawerOpen={drawerOpen}
          acknowledgeTodo={handleAcknowledgeTodo}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  drawer: state.drawer
});

export default connect(mapStateToProps, { closeDrawer })(
  TodoFormResponseChanged
);
