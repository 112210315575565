/**
 * External dependencies
 */
import axios from 'axios';

/**
 * Internal dependencies
 */
import { API_URL_PREFIX } from './Constants';
import { getAxiosConfig } from './apiHelpers';

/**
 * Retreives the GraphQL endpoint based on the
 * environment. Debugging mode is enabled by default
 * for the `development` environment.
 *
 * @returns {String} endpoint
 */
const getEndpoint = () => {
  let queryParam = ENVIRONMENT !== 'production' ? '?debug=true' : '';

  return API_URL_PREFIX + `gql${queryParam}`;
};

export const parseDocument = document => {
  return document?.loc?.source?.body;
};

/**
 * Creates the GraphQL client.
 */
const getClient = () => {
  const client = axios.create({
    method: 'POST',
    ...getAxiosConfig()
  });

  return {
    request: query => {
      return client
        .post(getEndpoint(), {
          query
        })
        .then(response => {
          return new Promise(resolve => {
            resolve(response.data.data);
          });
        });
    }
  };
};

export { getClient };
