import curriculumUtil from "./CurriculumUtil";
import ResourceDetail from "./ResourceDetail";

export class CurriculumResourceUx {
  title = '';
  form_id = null;
  link_url = null;
  file_url = null;
  curriculum_topic_ids = [];

  constructor() { };

  setData(resource) {
    this.id = resource.id;
    this.title = resource.title;
    this.file_url = resource.file_url;
    this.link_url = resource.link_url;
    this.form_id = resource.form_id;
    this.curriculum_topic_ids = resource.curriculum_topic.map(t => t.id);

    return this;
  }
}

export class CurriculumPacketUx {
  title = '';
  curriculum_resource_ids = [];

  constructor() { };

  setId(id) {
    this.id = id;

    return this;
  }

  setTitle(title) {
    this.title = title;

    return this;
  }

  setResources(resources) {
    this.curriculum_resource_ids = resources.map(r => r.id);

    return this;
  }

  setIsEdit(isEdit) {
    if (isEdit) {
      this.new_curriculum_resource_ids = [];
    }

    return this;
  }
}

export class CurriculumAssignmentUx {
  title = '';
  group_id = [];
  profile_id = [];
  window_id = [];
  calendar_id = [];
  case_manager_id = [];
  current_treatment = [];
  employment_status = [];
  education_level = [];
  program_type = [];
  phase = [];
  monitoring_type = [];
  gender = [];
  client_id = [];
  all_clients = false;
  curriculum_resource_details = [];
  curriculum_packet_id = '';
  start_date = null;
  due_in_days = null;
  create_alert = false;
  overlap_type = curriculumUtil.OVERLAP_TYPES.OR;
  enrollment_type = curriculumUtil.ENROLLMENT_TYPES.MANUAL;
  curriculum_resource = [];
  client_completion = true;

  constructor() {};

  setData(assignment = {}) {
    this.id = assignment.id;
    this.group_id = assignment?.client_segments?.group_id ?? [];
    this.profile_id = assignment?.client_segments?.profile_id ?? [];
    this.window_id = assignment?.client_segments?.window_id ?? [];
    this.calendar_id = assignment?.client_segments?.calendar_id ?? [];
    this.case_manager_id = assignment?.client_segments?.case_manager_id ?? [];
    this.current_treatment = assignment?.client_segments?.current_treatment ?? [];
    this.employment_status = assignment?.client_segments?.employment_status ?? [];
    this.education_level = assignment?.client_segments?.education_level ?? [];
    this.program_type = assignment?.client_segments?.program_type ?? [];
    this.phase = assignment?.client_segments?.phase ?? [];
    this.monitoring_type = assignment?.client_segments?.monitoring_type ?? [];
    this.gender = assignment?.client_segments?.gender ?? [];
    this.client_id = assignment?.client_segments?.client_id ?? [];
    this.all_clients = assignment?.client_segments?.all_clients ?? false;
    this.curriculum_resource_details = assignment?.curriculum_packet?.curriculum_resource?.map((r, i) => {
      return new ResourceDetail(r.id, r.title, r.due_in_days, r?.order ?? i);
    }) ?? [];
    this.curriculum_packet_id = assignment?.curriculum_packet?.id ?? null;
    this.title = assignment?.curriculum_packet?.title ?? '';
    this.start_date = assignment?.start_date ?? null;
    this.due_in_days = assignment?.due_in_days ?? null;
    this.create_alert = assignment?.create_alert ?? false;
    this.enrollment_type = assignment?.enrollment_type ?? this.enrollment_type;
    this.curriculum_resource = assignment?.curriculum_packet?.curriculum_resource ?? [];
    this.client_completion = assignment?.client_completion ?? true
    return this;
  }
}

export class CurriculumEntityForUx {

}