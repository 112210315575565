import React, { PureComponent } from 'react';

import convertAccuracy from 'utils/convertAccuracy';
import { getMarkerWithAddress } from './drawerHelpers';
import CustomTooltip from 'components/CustomTooltip';
import InfoBlockRow from '../InfoBlockRow';

export default class InfoBlock extends PureComponent {
  render() {
    const {
      block,
      clientName,
      date,
      eventTime,
      eventLocation,
      checkInReason,
      checkInWindow,
      hasEvent,
      hasPos,
      hasSpoofedLocation,
      checkUrl,
      eventSubmitted
    } = this.props;
    return (
      <div className="vc-container__info-block">
        {[
          { title: 'CLIENT', value: clientName, render: !!clientName },
          { title: 'SUBMITTED', value: eventSubmitted, render: !!eventSubmitted },
          { title: 'DATE', value: date, render: !!date },
          {
            title: 'EVENT TIME',
            value: eventTime,
            render: !!hasEvent && !!block.renderEvent && !!eventTime
          },
          {
            title: 'EVENT LOCATION',
            value: eventLocation,
            render: !!hasEvent && !!block.renderEvent && !!eventTime // events without times won't have a location either
          },
          {
            title: 'AUTHENTICITY SCORE',
            value: (
              <div className="authenticity">
                <span>{(block.authenticity_probability * 100).toFixed(2)}/100</span>
                <div className="authenticity-bar">
                  <div className="authenticity-score" style={{ width: block.authenticity_probability * 100 }}></div>
                  <div className="authenticity-background"></div>
                </div>
                <CustomTooltip>
                  Authenticity Score is used as a confidence gauge to detect liveness and spoofing attempts. The higher
                  the score, the more likely the photo is of a real person.
                </CustomTooltip>
              </div>
            ),
            render: !!block.authenticity_probability
          },
          {
            title: 'CALLED IN FROM',
            value: checkUrl,
            render: !!checkUrl && 'Facial Mismatch' !== block.blockLabel
          },
          {
            title: 'CHECKED IN',
            value: block.called_in ? 'Yes' : 'No',
            render: !!block.called_in
          },
          {
            title: 'CHECK-IN TIME',
            value: block.callInTime,
            render: !!block.callInTime
          },
          {
            title: 'REQUESTED AT',
            value: block.requestTime,
            render: !!block.requestTime
          },
          {
            title: 'REQUESTED BY',
            value: block.requestedBy,
            render: !!block.requestedBy
          },
          {
            title: 'TEST REQUIRED',
            value: block.ad_hoc ? 'Ad Hoc' : block.test_today ? 'Yes' : 'No',
            render: block.test_today !== undefined
          },
          {
            title: 'REQUIRED TESTS',
            value: block.requiredTests,
            render: !!block.requiredTests
          },
          {
            title: 'CONFIRMATION #',
            value: block.confirmation,
            render: !!block.confirmation
          },
          {
            title: 'CHECK-IN REASON',
            value: checkInReason,
            render: !!checkInReason
          },
          {
            title: 'CHECK-IN WINDOW',
            value: checkInWindow,
            render: !!checkInWindow && checkInWindow?.indexOf('null') === -1
          },
          {
            title: 'SPOOFED LOCATION',
            value: block.hasSpoofed ? getMarkerWithAddress(block.hasSpoofed) : '',
            render: !!block.hasSpoofed
          },
          {
            title: 'CHECK-IN TIME',
            value: block.checkInTime,
            render: !!block.checkInTime
          },
          {
            title: 'RESULT NOTE',
            value: block.note,
            render: !!block.note
          },
          {
            title: 'LOCATION ACCURACY',
            value: !!hasPos && !!hasPos.accuracy ? convertAccuracy(hasPos.accuracy) : 0,
            render: !!hasPos && !!hasPos.accuracy && !hasSpoofedLocation
          },
          {
            title: 'LOCATION AGE',
            value: !!hasPos && !!hasPos.location_age_ms ? (hasPos.location_age_ms / 1000).toFixed(2) + ' seconds' : 0,
            render: !!hasPos && !!hasPos.location_age_ms && !hasSpoofedLocation
          },
          {
            title: 'BAC',
            value: block.bac,
            render: !!block.bac
          },
          {
            title: 'SIMILARITY',
            value: block.similarity,
            render: !!block.similarity
          },
          {
            title: 'SUBSCRIPTION',
            value: block.name,
            render: !!block.subscription_violation
          },
          {
            title: 'SUBSCRIPTION START',
            value: block.start,
            render: !!block.subscription_violation
          },
          {
            title: 'STATUS',
            value: block.status,
            render: !!block.subscription_violation
          },
          {
            title: 'AMOUNT',
            value: block.amount,
            render: !!block.subscription_violation
          },
          {
            title: 'DETAILS',
            value: block.details,
            render: !!block.subscription_violation
          },
          {
            title: 'EXCLUSION ZONE',
            value: block.exclusion_zone_name,
            render: !!block.exclusion_zone_name
          },
          {
            title: 'INCLUSION ZONE',
            value: block.inclusion_name,
            render: !!block.inclusion_name
          },
          {
            title: 'TIME OF LOCATION',
            value: block.client_location_time,
            render: !!block.client_location_time
          },
          {
            title: 'ZONE EXIT',
            value: block.zone_exit,
            render: !!block.zone_exit
          },
          {
            title: 'ZONE ENTRY',
            value: block.zone_entry,
            render: !!block.zone_entry
          },
          {
            title: 'ZONE RE-ENTRY',
            value: block.zone_re_entry,
            render: !!block.zone_re_entry
          },
          {
            title: 'LAST KNOWN LOCATION',
            value: (
              <>
                {block.last_known_location}
                <br />
                <small>(COMMUNICATION WAS LOST PAST THIS TIME)</small>
              </>
            ),
            render: !!block.last_known_location
          },
          {
            title: 'TOTAL TIME OUT OF ZONE',
            value: block.total_time_out_of_zone,
            render: !!block.total_time_out_of_zone
          },
          {
            title: 'TOTAL TIME IN ZONE',
            value: block.total_time_in_of_zone,
            render: !!block.total_time_in_of_zone
          },
          {
            title: 'OFFLINE',
            value: block.time_out,
            render: !!block.time_out
          },
          {
            title: 'BACK ONLINE',
            value: block.time_in,
            render: !!block.time_in
          },
          {
            title: 'CHECK-IN DEADLINE',
            value: block.checkInDeadline,
            render: !!block.checkInDeadline
          },
          {
            title: 'FORM',
            value: block.form,
            render: !!block.form
          },
          { title: 'GOAL NAME', value: block.goal_incomplete?.name, render: !!block.goal_incomplete },
          { title: 'GOAL START', value: block.goal_incomplete?.start_date, render: !!block.goal_incomplete },
          { title: 'GOAL END', value: block.goal_incomplete?.end_date, render: !!block.goal_incomplete },
          {
            title: 'TOTAL PROGRESS',
            value: (
              <div className="authenticity">
                <span>{(block.goal_incomplete?.progress * 100).toFixed(2)}%</span>
                <div className="authenticity-bar">
                  <div className="authenticity-score" style={{ width: block.goal_incomplete?.progress * 100 }}></div>
                </div>
              </div>
            ),
            render: !!block.goal_incomplete
          },
          {
            title: 'TOTAL COMPLETED',
            value: (
              <div>
                <span>
                  {block.goal_incomplete?.total_completed}/{block.goal_incomplete?.total_units}
                </span>
              </div>
            ),
            render: !!block.goal_incomplete
          },
          {
            title: 'REQUIREMENTS',
            value: (
              <div>
                {block.goal_incomplete?.requirements.map(requirement => {
                  return (
                    <div>
                      <span>{requirement.action_item}: {requirement.completed}/{requirement.requirement_type_value}</span>
                    </div>
                  );
                })}
              </div>
            ),
            render: !!block.goal_incomplete
          }
        ].map((info, i) => {
          return !!info.render && <InfoBlockRow key={`${info.title}-${i}`} title={info.title} value={info.value} />;
        })}
      </div>
    );
  }
}
