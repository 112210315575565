import { loadPending, loadSuccessful, loadFailed } from '../helperActions';
import { getData } from 'data/dashboard';

const ACTION_IDENTIFIER = 'DASHBOARD';

export const getDashboardData = (
  userId,
  blocks = [],
  checkInReportDate = null,
  violationsUnresolvedOnly = true,
  violationsLimit = 10,
  messagesLimit = 10
) => dispatch => {
  dispatch(loadPending(ACTION_IDENTIFIER));

  return getData(
    userId,
    blocks,
    checkInReportDate,
    violationsUnresolvedOnly,
    violationsLimit,
    messagesLimit
  )
    .then(data => {
      dispatch(loadSuccessful(ACTION_IDENTIFIER, data));
    })
    .catch(error => {
      dispatch(loadFailed(ACTION_IDENTIFIER, error));
    });
};

/**
 * Requests data for a block.
 *
 * @param {object} param The current user id and a string with the slug of the block
 *
 * @returns {Promise}
 */
export const getDashboardBlockData = ({ userId, block }) => dispatch => {
  dispatch({
    type: 'LOAD_PENDING_' + ACTION_IDENTIFIER + '_BLOCK',
    payload: { block }
  });
  return getData(userId, [block])
    .then(data => {
      dispatch(loadSuccessful(ACTION_IDENTIFIER + '_BLOCK', { block, data }));
    })
    .catch(error => {
      dispatch(loadFailed(ACTION_IDENTIFIER + '_BLOCK', error));
    });
};

export function updateDashboardViolation(updatedViolation) {
  return dispatch => {
    dispatch({
      type: `${ACTION_IDENTIFIER}_UPDATE_VIOLATION`,
      payload: { updatedViolation }
    });
  };
}

/**
 * Update a test in the redux store.
 *
 * @param {object} updatedTest New data to update the test. Includes id, result, notes.
 *
 * @returns {Promise}
 */
export function updateDashboardTestResult(updatedTest) {
  return dispatch => {
    dispatch({
      type: `${ACTION_IDENTIFIER}_UPDATE_TEST`,
      payload: { updatedTest }
    });
  };
}
