/**
 * External dependencies
 */
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';

/**
 * Internal dependencies
 */
import Label from 'components/Label';
import Icon from 'components/Icon';
import { getToRender } from 'components/FormProvider/utils';
import './style.scss';

const initialOptions = (options, blockGroup, getStateValue) =>
  options
    .map(o => {
      if (o.renderChecks && !getToRender(o.renderChecks, blockGroup, getStateValue)) {
        return null;
      }
      return o;
    })
    .filter(x => x !== null);

const CustomSelectCreatable = ({
  autoFocus = false,
  blockGroup = false,
  className = '',
  disabled = false,
  error = false,
  handleCustomSelectChange,
  id = '',
  isSearchable = true,
  label = '',
  multiple = false,
  name,
  options = [],
  placeholder = 'Type or select...',
  required = false,
  setErrorsCallback,
  tooltip,
  createOption,
  value = '',
  validation,
  // eslint-disable-next-line no-unused-vars
  ...props
}) => {
  const [optionsList, setOptionsList] = useState([]);

  useEffect(() => {
    if (optionsList.length == 0) {
      if (Array.isArray(options)) {
        setOptionsList(initialOptions(options, blockGroup, props.getStateValue));
      } else {
        options
          .getter()
          .then(optionsCollection =>
            setOptionsList(initialOptions(optionsCollection, blockGroup, props.getStateValue))
          );
        options = [];
      }
    }
  }, []);

  useEffect(() => {
    const defaultSelected = optionsList.find(o => o.defaultSelected);
    if (defaultSelected) {
      handleCustomSelectChange(
        {
          target: {
            name,
            value: defaultSelected.value === '*' ? [...optionsList.map(o => o.value)] : [defaultSelected.value]
          }
        },
        blockGroup,
        defaultSelected.callback && defaultSelected.callback,
        true
      );
    }
  }, []);

  useEffect(() => {
    if ('function' === typeof validation && 'function' === typeof setErrorsCallback) {
      validation(name, blockGroup, value, setErrorsCallback, error);
    }
  }, [setErrorsCallback, value, validation]);

  const getOptionLabel = option => {
    if (option.labelFromValue) {
      const valueFromState = props.getStateValue(option.labelValue, blockGroup);
      if (option.labelValue2) {
        const value2FromState = props.getStateValue(option.labelValue2, blockGroup);
        return value2FromState ? option.labelFromValue(valueFromState, value2FromState) : option.label;
      }
      return valueFromState ? option.labelFromValue(valueFromState) : option.label;
    }
    return option.label;
  };

  const getVal = () => {
    if (!!value && typeof value === 'string') {
      return { label: value, value };
    } else {
      return optionsList.filter(o => (Array.isArray(value) ? value.includes(o.value) : value == o.value));
    }
  };

  const handleChange = (o, actionMeta) => {
    // clear errors if there are any
    if ('function' === typeof setErrorsCallback) {
      setErrorsCallback(name, blockGroup, false);
    }
    const { action } = actionMeta;

    if (action === 'select-option' || action === 'remove-value') {
      const hasValue = !multiple
        ? (!!o && !!o.value && null !== o.value) || isBoolean(o.value)
        : isArray(o) && !isEmpty(o);

      const selectedOption = (() => {
        if (multiple) return hasValue ? o.map(opt => opt.value) : [];
        else return hasValue ? o.value : '';
      })();

      const args = [
        {
          target: {
            ...o,
            name,
            value: selectedOption
            // value: !multiple ? (hasValue ? o.value : '') : hasValue ? [...o.map(opt => opt.value)] : [] //...wut
          }
        },
        blockGroup,
        o?.callback ?? false,
        false,
        ...(props.control ? [
          props.control._rootControl,
          props.control.formGroup?.index
        ] : [])
      ];
      
      handleCustomSelectChange.apply(null, args);
    }
    if (action === 'create-option') {
      const newOpt = Array.isArray(o) ? o.find(opt => opt.__isNew__ === true) : o;
      createOption(newOpt.label);
      const actualValue = {
        label: newOpt.label,
        value: newOpt.value
      };
      setOptionsList([...optionsList, actualValue]);

      const newVal = Array.isArray(o) ? o.map(opt => opt.value) : o.value;
      handleCustomSelectChange({ target: { ...o, name, value: newVal } }, blockGroup, false, false, false);
    }
  };

  return (
    <>
      {error && (
        <div className="error-container">
          <Icon name="warning" />
          <Label htmlFor={id} alert mBottom>
            {error}
          </Label>
        </div>
      )}
      {!!label && (
        <Label bold htmlFor={id} required={required} tooltip={tooltip}>
          {label}
        </Label>
      )}
      <CreatableSelect
        // menuIsOpen={true}
        menuPlacement="auto"
        id={id}
        styles={{
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused && '0 0 0 1px #4b858e',
            cursor: 'pointer',
            background: '#fbfafb',
            height: !state.isMulti ? '36px' : 'auto',
            minHeight: !state.isMulti ? 'unset' : 'auto'
          }),
          option: styles => ({
            ...styles,
            cursor: 'pointer'
          })
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 2,
          colors: {
            ...theme.colors,
            text: '#2f3d49',
            primary25: '#f4f4f4',
            primary: '#ccc'
          }
        })}
        getOptionLabel={getOptionLabel}
        // menuIsOpen // only for testing
        closeMenuOnSelect={!multiple}
        className={`base-multi-select ${className}`}
        classNamePrefix="react-select"
        autoFocus={autoFocus}
        options={optionsList.filter(o => !o.isHidden)}
        isMulti={multiple}
        placeholder={placeholder}
        isSearchable={isSearchable}
        name={name}
        value={getVal(value)}
        onChange={handleChange}
        isDisabled={disabled}
        isClearable={multiple}
      />
    </>
  );
};

export default CustomSelectCreatable;
