/**
 * @description Helpers function to format array of objects rendered
 * within the `SubscriptionDrawer` component
 * @param {object} selectedSubscription Parent subscription of journal item
 * passed to `SubscriptionDrawer`
 * @param {string} clientName The client's name
 * @param {string} [paidThrough] The paid-through date for the shown line item
 * @param {string} [paidDate] The payment date, if present, of the shown line item
 * @returns {array} transaction details [{}]
 */
const makeTransactionDetails = (selectedSubscription, clientName) => {
  const {
    program: { name },
    start_date,
    status,
    amount,
    paidThrough,
    paidDate
  } = selectedSubscription;
  return [
    {
      label: 'CLIENT',
      value: clientName
    },
    {
      label: 'PROGRAM',
      value: name
    },
    {
      label: 'SUBSCRIPTION START',
      value: start_date
    },
    {
      label: 'PAYMENT DATE',
      value: paidDate
    },
    {
      label: 'STATUS',
      value: status
    },
    {
      label: 'PAID THROUGH',
      value: paidThrough
    },
    {
      label: 'AMOUNT',
      value: amount
    }
  ];
};

export { makeTransactionDetails };
