/**
 * External dependencies
 */
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

/**
 * Internal dependencies
 */
import {Toggle} from 'components/Toggle';
import CustomTooltip from 'components/CustomTooltip';
import './style.scss';

export const DevicePermissionsDrawer = ({
  drawer: {
    drawerOpen,
    drawerProps: {
      device: { devicePermissions }
    }
  }
}) => {
  const parsedPermissions = JSON.parse(devicePermissions);
  const permissions = [];
  const shownValues = ['granted', 'authorized', 'blocked', 'denied'];
  const toggleOnValues = ['authorized', 'granted'];
  for (const [key, value] of Object.entries(parsedPermissions)) {
    if (shownValues.indexOf(value) > -1) {
      const toggleOn = toggleOnValues.indexOf(value) > -1;
      permissions.push({ key, value, toggleOn });
    }
  }

  const formatPermissionName = name => {
    return name.replace('_', ' ');
  };

  const formatTooltip = (name, value) => {
    switch (value) {
      case 'authorized':
      case 'granted':
        return (
          'This client granted the ' +
          formatPermissionName(name) +
          ' permission.'
        );
      case 'blocked':
        return (
          'This client blocked the ' +
          formatPermissionName(name) +
          ' permission. This means that they will not be asked for this permission again until they grant the permission in their phone settings.'
        );
      case 'denied':
        return (
          'This client denied the ' +
          formatPermissionName(name) +
          ' permission. Please ask them to grant this permission.'
        );
    }
  };

  return (
    <>
      <div className="vc-container device-permissions-drawer">
        <div className="vc-container__scroll-wrapper">
          <div
            className={classnames('vc-container__header', {
              'drawer-open': drawerOpen
            })}
          >
            <h3 className="vc-container__header--heading">
              Device Permissions
            </h3>
          </div>
          <div className="vc-container__info-block">
            {permissions.map(permission => (
              <div key={permission.key} className="info-block-row">
                <div className="info-block-row__title">
                  {formatPermissionName(permission.key)}
                </div>
                <div className="info-block-row__value">
                  <Toggle checked={permission.toggleOn} disabled={true} />
                  <CustomTooltip absolute={false}>
                    {formatTooltip(permission.key, permission.value)}
                  </CustomTooltip>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  drawer: state.drawer
});

export default connect(mapStateToProps, {})(DevicePermissionsDrawer);
