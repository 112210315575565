import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/Icon';
import './style.scss';

export function FormToggle({}) {

}

export function Toggle({
                  checked = false,
                  handleToggle,
                  id,
                  useCheckmark = true,
                  disabled = false,
                  textBefore = false,
                  textAfter = false
                }) {
  const toggleSwitch = useCallback(
    e => {
      e.persist();
      e.preventDefault();

      if (!disabled) {
        if (handleToggle instanceof Function) {
          handleToggle(!checked);
        }
      }
    },
    [disabled, handleToggle, checked]
  );

  const inlineControl = !!textBefore || !!textAfter;

  return (
    <>
      <div className={`toggle-container ${inlineControl ? 'inline-control' : ''}`} onClick={toggleSwitch}>
        {inlineControl && <span className='inline-control-label left'>{textBefore}</span>}
        <input
          readOnly
          checked={checked}
          id={id ? id : 'toggle'}
          className='toggle-container__toggle-checkbox'
          type='checkbox'
        />
        <label
          htmlFor='toggle'
          className={classnames('toggle-container__toggle-label', {
            checked: !!checked,
            disabled: disabled,
            'inline-control': inlineControl
          })}
        >
          <span className='toggle-container__toggle-button'>
            {checked && useCheckmark && <Icon name='check' className='check' />}
          </span>
        </label>
        {inlineControl && <span className='inline-control-label right'>{textAfter}</span>}
      </div>
    </>
  );
}

