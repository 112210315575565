import { loadPending, removePending, loadSuccessful } from './helperActions';

import { getReport, catchErrors } from 'utils/apiHelpers';

export function getTodaysReport(today, hash) {
  return dispatch => {
    // set to pending
    dispatch(loadPending('TODAYS_REPORT'));

    if (hash) {
      getReport(today, hash)
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending('TODAYS_REPORT'));
          } else {
            dispatch(loadSuccessful('TODAYS_REPORT', res.data));
          }
        })
        .catch(catchErrors);
    } else {
      getReport(today)
        .then(res => {
          dispatch(loadSuccessful('TODAYS_REPORT', res.data));
        })
        .catch(catchErrors);
    }
  };
}

// the async portion has already happened non-reduxily.
export function updateTodaysTestResult(id, testResult, testNote) {
  return {
    type: 'UPDATE_TODAYS_TEST_RESULT',
    payload: { id, testResult, testNote }
  };
}
