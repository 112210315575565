import { loadPending, removePending, loadSuccessful } from './helperActions';

import { getCallInWindows, catchErrors } from 'utils/apiHelpers';

export function getWindows(windows) {
  let hash = windows.doneInitialLoad && windows.hash;

  return dispatch => {
    // set to pending
    dispatch(loadPending('WINDOWS'));

    if (hash) {
      getCallInWindows(hash)
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending('WINDOWS'));
          } else {
            dispatch(loadSuccessful('WINDOWS', res.data));
          }
        })
        .catch(catchErrors);
    } else {
      getCallInWindows(false)
        .then(res => {
          dispatch(loadSuccessful('WINDOWS', res.data));
        })
        .catch(catchErrors);
    }
  };
}
