import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { omit } from 'lodash';

import { getToRender } from 'components/FormProvider/utils';
import './style.scss';

const Button = ({
  // eslint-disable-next-line no-unused-vars
  action,
  // eslint-disable-next-line no-unused-vars
  disabled = false,
  handleButtonClick = undefined,
  onClick,
  // eslint-disable-next-line no-unused-vars
  buttonType = '',
  // eslint-disable-next-line no-unused-vars
  renderChecks,
  // eslint-disable-next-line no-unused-vars
  setErrorsCallback,
  primary = false,
  secondary = false,
  none = false,
  transparent,
  invisible,
  micro,
  type = 'button',
  form,
  className,
  children,
  group,
  getStateValue,
  disabledChecks,
  to = '',
  mBottom = true,
  // ignored schema variables, listing here until better implementation
  _blockKey,
  _isInput,
  _getValue,

  ...props
}) => {
  const getDisabled = () => {
    if (disabledChecks && disabledChecks.length && getStateValue) {
      const isDisabled = getToRender(disabledChecks, group, getStateValue, true);

      return isDisabled;
    }
    return disabled;
  };

  const isDisabled = getDisabled();

  const classesList = classnames(`base-button${className ? ` ${className}` : ''}`, {
    primary: !!primary,
    secondary: !!secondary,
    transparent: !!transparent,
    invisible: !!invisible,
    none: !!none,
    disabled: !!isDisabled,
    micro: !!micro,
    mBottom: !!mBottom
  });

  if ('link' === type && '' !== to) {
    return (
      <Link to={to} className={classesList}>
        {children}
      </Link>
    );
  }

  const Wrapper = props?.tooltip?.title ? Tooltip : Fragment;
  const MaybeSpan = props?.tooltip?.title ? 'span' : Fragment;

  return (
    <Wrapper {...{ ...(props?.tooltip ?? {}) }}>
      <MaybeSpan>
        <button
          {...omit(props, ['tooltip'])}
          className={classesList}
          type={type}
          onClick={handleButtonClick || onClick}
          disabled={!!isDisabled}
          form={form ? form : undefined}
        >
          {children}
        </button>
      </MaybeSpan>
    </Wrapper>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button', 'link']),
  to: PropTypes.string,
  handleButtonClick: PropTypes.func,
  label: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  none: PropTypes.bool,
  transparent: PropTypes.bool,
  invisible: PropTypes.bool,
  micro: PropTypes.bool,
  form: PropTypes.string,
  tooltip: PropTypes.shape({
    title: PropTypes.string
  })
};

export default Button;
