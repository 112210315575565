/**
 * External dependencies
 */
import get from 'lodash/get';

/**
 * Get the geographical states in the app state.
 *
 * @param {object} state App state tree.
 *
 * @returns {object} A collection of geographical states. An empty object if they're not yet available.
 */
const getStates = state => get(state, 'locations.states', {});

export default getStates;
