import React, { useMemo, useCallback } from 'react';
import { array, number, object, string } from 'yup';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useDispatch } from 'react-redux';

import Input from 'components/Input';
import Label from 'components/Label';
import Icon from 'components/Icon';
import curriculumUtil from 'utils/CurriculumUtil';
import CustomSelect from 'components/CustomSelect';
import { useCurriculumItemProps } from 'components/Curriculum/hooks';
import CurriculumDrawerForm from 'components/Curriculum/CurriculumDrawerForm';
import AssigneesItem from '../Assignment/AssigneesItem';
import PacketDrawerManager from 'utils/PacketDrawerManager';
import { openDrawer } from 'actions/drawerActions';
import { getCurriculumItem } from 'actions/curriculumActions';

const schema = isEdit => {
  return object({
    title: string()
      .trim()
      .min(3, 'Title must be at least three characters')
      .required('Title is required'),
    curriculum_resource_ids: isEdit
      ? array().nullable()
      : array()
        .of(number())
        .min(1, 'Packets must have at least one resource')
        .required('Packets must have at least one resource'),
    new_curriculum_resource_ids: isEdit
      ? array().of(number())
      : array().nullable()
  });
}

const PacketDrawer = () => {
  const {
    item: packet,
    isEdit,
    isNew,
    isView,
    isDuplicate,
    isDrawerOpen,
    isItemSubmitting,
    resources,
    handleAdd,
    handleEdit
  } = useCurriculumItemProps({ selector: curriculumUtil.SELECTOR_KEYS.PACKETS });

  const dispatch = useDispatch();

  const manager = useMemo(() => {
    return new PacketDrawerManager(packet, resources, { isNew, isEdit, isView, isDuplicate }).init();
  }, [packet, resources, isNew, isEdit, isView, isDuplicate]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
    isValid
  } = useFormik({
    initialValues: manager.formikInitialValues,
    validationSchema: schema(isEdit),
    onSubmit: handleSubmitIntermediary,
    validateOnMount: false,
    validateOnBlur: true,
    isInitialValid: !isEdit,
    validateOnChange: true,
    enableReinitialize: true
  });

  async function handleSubmitIntermediary() {
    if (isEdit) {
      handleEdit({ id: packet.id, ...omit(values, 'curriculum_resource_ids') });
    } else if (isNew || isDuplicate) {
      handleAdd(omit(values, 'new_curriculum_resource_ids'));
    }
  }

  const isSubmitDisabled = useMemo(() => {
    return isSubmitting || !isValid || isItemSubmitting || isView;
  }, [isSubmitting, isValid, isItemSubmitting, isView]);

  const handleBlurIntermediary = useCallback(
    field => () => {
      if (!touched[field]) {
        setFieldTouched(field, true, true);
      }
    },
    [setFieldTouched, touched]
  );

  const handleGetItem = useCallback(
    (id, propsForDrawer = {}) => () => {
      function handleOpenDrawer() {
        dispatch(openDrawer(curriculumUtil.DRAWER_TYPES.PACKET, propsForDrawer));
      }

      if (id) {
        dispatch(getCurriculumItem(curriculumUtil.SELECTOR_KEYS.PACKETS, [id])).then(() => handleOpenDrawer());
      } else {
        handleOpenDrawer();
      }
    },
    [dispatch]
  );

  return (
    <CurriculumDrawerForm
      isDrawerOpen={isDrawerOpen}
      header={`${isEdit ? 'Edit' : isNew || isDuplicate ? 'Add' : 'View'} Packet`}
      showHeaderButton={isEdit || isView}
      headerButtonTitle="Duplicate"
      headerButtonOnClick={handleGetItem(null, { isDuplicate: true })}
      handleSubmit={handleSubmit}
      isSubmitDisabled={isSubmitDisabled}
      isSubmitting={isSubmitting || isItemSubmitting}
    >
      <Input
        autoFocus
        placeholder="Enter a packet title"
        value={values.title}
        tooltip={!isView ? 'Packet titles must be a minimum of three characters long' : undefined}
        required={!isView}
        handleChange={handleChange}
        handleBlur={handleBlur}
        id="title"
        label="Title"
        name="title"
        disabled={isView}
      />
      {touched.title && errors.title && (
        <div className="error-container">
          <Icon name="warning" />
          <Label htmlFor="title" alert>
            {errors.title}
          </Label>
        </div>
      )}
      {!isNew ? (
        <AssigneesItem
          header="Current Resources"
          items={manager.current}
          tooltip="The following are the resources chosen when the packet was first created"
          isDrawer
        />
      ) : null}
      {!isView ? (
        <>
          <CustomSelect
            id={manager.resourceIdsField}
            multiple
            isSearchable
            label="Resources"
            name={manager.resourceIdsField}
            disabled={isView}
            handleCustomSelectChange={handleChange}
            handleBlur={handleBlurIntermediary(manager.resourceIdsField)}
            placeholder="Select resources"
            required={isNew || !values?.[manager?.resourceIdsField]?.length}
            tooltip={!isView ? 'Once assigned, resources act as distinct tasks clients must complete' : undefined}
            value={values?.[manager?.resourceIdsField] ?? []}
            options={manager.options}
          />
          {touched[manager.resourceIdsField] && errors[manager.resourceIdsField] && (
            <div className="error-container">
              <Icon name="warning" />
              <Label htmlFor={manager.resourceIdsField} alert>
                {errors[manager.resourceIdsField]}
              </Label>
            </div>
          )}
        </>
      ) : null}
    </CurriculumDrawerForm>
  );
};

export default PacketDrawer;
