import { getOrdinalFromNumber } from 'lib/util';
import React from 'react';

import curriculumUtil from 'utils/CurriculumUtil';

const CompletionHeader = ({ title = '', order = 0, start_date, due_in_days }) => {
  return (
    <>
      <p>
        <strong>Title</strong>:&nbsp;{title}
      </p>
      <p>
        <strong>Presented</strong>:&nbsp;{getOrdinalFromNumber(+order + 1)}
      </p>
      <p>
        <strong>Deadline</strong>:&nbsp;{curriculumUtil.getDueInDaysDate(start_date, due_in_days)}
      </p>
    </>
  );
};

export default CompletionHeader;
