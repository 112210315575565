/**
 * External dependencies
 */
import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import './style.scss';

class Intercom extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    facility: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.addIntercom();
  }

  addIntercom = () => {
    const { user, facility } = this.props;
    const INTERCOM_APP_ID = 'qu4mclgh';

    const config = {
      app_id: INTERCOM_APP_ID,
      alignment: 'left',
      email: user.email,
      user_id: user.id,
      name: user.first_name + ' ' + user.last_name,
      company: {
        company_id: facility.id,
        name: facility.facility_name
      },
      custom_launcher_selector: '.custom-intercom-launcher'
    };

    if (user.access_level <= 10) {
      config.user_hash = user?.intercom_user_hash ?? '';
    }

    window.Intercom('boot', config);
  };

  render() {
    return null;
  }
}

export default Intercom;
