/**
 * Internal dependencies
 */
import mapStartEndDates from 'utils/mapStartEndDates';

/**
 * Reduces the action/payload.
 *
 * @param {object} state
 * @param {object} action
 */
export default function dashboard(state = { doneInitialLoad: false }, action) {
  switch (action.type) {
    case 'LOAD_PENDING_DASHBOARD':
      return {
        ...state,
        doneInitialLoad: false
      };
    case 'LOAD_SUCCESSFUL_DASHBOARD':
      return {
        ...state,
        doneInitialLoad: true,
        ...action.payload.dashboard,
        agenda: {
          isRequestingClientEvents: false,
          events: mapStartEndDates(
            action.payload.dashboard?.agenda?.events ?? []
          )
        }
      };
    case 'FAILED_REQUEST_DASHBOARD':
      return {
        ...state,
        doneInitialLoad: true
      };
    case 'LOAD_PENDING_DASHBOARD_BLOCK':
      return {
        ...state,
        [`isRequesting_${action.payload.block}`]: true
      };
    case 'LOAD_SUCCESSFUL_DASHBOARD_BLOCK':
      return {
        ...state,
        [`isRequesting_${action.payload.block}`]: false,
        [action.payload.block]:
          'agenda' === action.payload.block
            ? {
                events: (() => {
                  // Makes a new set with all the existing event ids
                  const ids = new Set(state.agenda.events.map(x => x.id));
                  return [
                    ...state.agenda.events,
                    ...mapStartEndDates(
                      (action.payload.data?.dashboard?.agenda.events ?? [])
                        // filters out events whose id is already in the ids array
                        .filter(x => !ids.has(x.id))
                    )
                  ];
                })()
              }
            : action.payload.data?.dashboard?.[action.payload.block] ?? []
      };
    case 'FAILED_REQUEST_DASHBOARD_BLOCK':
      return {
        ...state,
        [`isRequesting_${action.payload.block}`]: false
      };
    case 'DASHBOARD_UPDATE_VIOLATION':
      return {
        ...state,
        violations: state.violations.map(v => {
          return v.id !== action.payload.updatedViolation.id
            ? v
            : {
                ...v,
                status: action.payload.updatedViolation.status
              };
        })
      };
    case 'DASHBOARD_UPDATE_TEST':
      return {
        ...state,
        checkInReport: {
          tests: state.checkInReport?.tests ?? [],
          clients:
            state.checkInReport?.clients?.map(client => {
              return client.id !== action.payload.updatedTest?.id ?? -Infinity
                ? client
                : {
                    ...client,
                    test_result: action.payload.updatedTest?.result ?? '',
                    test_notes: action.payload.updatedTest?.notes ?? ''
                  };
            }) ?? []
        }
      };
  }

  return state;
}
