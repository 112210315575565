import React, { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const CardGroupInfo = ({ id, header, info, toggles, open }) => {
  const [toggleIconOpen, setToggleIconOpen] = useState(open);

  const cardHeaderClicked = () => {
    const divs = document.querySelectorAll(`div[data-for-header="${header}"]`);
    divs.forEach(div => {
      if (div.classList.contains('hidden')) {
        setToggleIconOpen(true);
        div.classList.remove('hidden');
      } else {
        setToggleIconOpen(false);
        div.classList.add('hidden');
      }
    });
  };

  return (
    <div id={id} className="card-info__container">
      <h3
        className={`card-header ${toggles ? 'cursor-pointer' : ''}`}
        onClick={() => {
          toggles ? cardHeaderClicked() : null;
        }}
      >
        {header}
      </h3>
      {toggles && (
        <Tooltip title={`Click to ${toggleIconOpen ? 'hide' : 'expand'} this section`}>
          <IconButton
            onClick={cardHeaderClicked}
            size="medium"
          >
            {toggleIconOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Tooltip>
      )}
      {!!info && (
        <div data-for-header={header} className={`card-info ${toggles ? (open ? '' : 'hidden') : ''}`}>
          {'function' === typeof info ? info() : info}
        </div>
      )}
    </div>
  );
};

export default CardGroupInfo;
