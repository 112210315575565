/**
 * External dependencies
 */
import { useContext } from 'react';
import ToastContext from './context';

const useToast = () => {
  const { addToast, removeToast, setCallbackRef, resetCallbackRef } = useContext(ToastContext);

  return { addToast, removeToast, setCallbackRef, resetCallbackRef };
};

export default useToast;
