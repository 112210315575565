import React from 'react';

const WindowReportHeader = ({
  condition = false,
  className = 'open',
  heading = 'Required to Test',
  message = 'No Clients In Window'
}) => {
  return (
    <div className={`vc-container__status-block details-${className} tests`}>
      <div className="vc-details">
        <h3 className="vc-type">{heading}</h3>
        {condition ? (
          <p className="vc-specifics">
            <em>{message}</em>
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default WindowReportHeader;