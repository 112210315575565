/**
 * This reducer controls the behavior of certain global UI elements like the contextual menu displayed for a client or in Settings.
 *
 * @param {object} state Info about the UI state.
 * @param {object} action The action to be performed.
 *
 * @returns {object} state The new app state tree.
 */
export default function ui(state = {}, action) {
  switch (action.type) {
    case 'TOGGLE_MOBILE_MENU':
      if (state.mobileMenuOpen) {
        document.body.classList.remove('is-mobile-menu-open');
      } else {
        document.body.classList.add('is-mobile-menu-open');
        document.body.classList.remove('is-contextual-menu-open');
      }
      return {
        ...state,
        contextualMenuOpen: false,
        mobileMenuOpen: !state.mobileMenuOpen
      };

    case 'CLOSE_MOBILE_MENU':
      document.body.classList.remove('is-mobile-menu-open');
      return {
        ...state,
        mobileMenuOpen: false
      };

    case 'TOGGLE_CONTEXTUAL_MENU':
      if (state.contextualMenuOpen) {
        document.body.classList.remove('is-contextual-menu-open');
      } else {
        document.body.classList.add('is-contextual-menu-open');
        document.body.classList.remove('is-mobile-menu-open');
      }
      return {
        ...state,
        mobileMenuOpen: false,
        contextualMenuOpen: !state.contextualMenuOpen
      };

    case 'CLOSE_CONTEXTUAL_MENU':
      document.body.classList.remove('is-contextual-menu-open');
      return {
        ...state,
        contextualMenuOpen: false
      };

    default:
      return {
        ...state,
        contextualMenuOpen: false,
        mobileMenuOpen: false
      };
  }
}
