import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import Loading from 'components/Helpers/Loading';
import { useToast } from 'components/Toast';
import { schema, getActionButtons } from './schema';
import FormProvider from 'components/FormProvider';
import { getClients, addCommunityEvent } from 'actions/actionCreators';
import { formTimeCalculations, eventInitialState } from 'utils/eventHelpers';
import {
  addMedia,
  removeUnusedKeys,
  setEventSeriesRules,
  setReminders,
  setTags,
  setAddressCoordinates
} from 'utils/eventHelpers';
import './style.scss';

function IndividualMeetingDrawer({
  clients,
  getClients,
  drawerOpen,
  addCommunityEvent,
}) {
  const toast = useToast();

  const { minDeadlineGrace, adjustedStartTime } = formTimeCalculations();

  const [shouldReset, setShouldReset] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);

  useEffect(() => {
    getClients(clients);
  }, []);

  useEffect(() => {
    if (!isReady && clients.doneInitialLoad) {
      const activeClients = clients.list.filter(client => client.a);
      setClientOptions([
        { value: '', label: 'Select a Client' },
        ...activeClients.map(c => ({
          value: c.id,
          label: `${c.f} ${c.l}`
        }))
      ]);
      setIsReady(true);
    }
  }, [clients]);

  const formContext = useMemo(() => {
    return {};
  }, []);

  const prepareData = event => {
    /* eslint-disable no-unused-vars */

    const {
      form_id,
      attach_photo,
      send_location,
      ...communityEvent
    } = cloneDeep(event);

    let createViolation = true;

    if (communityEvent.check_in_setting == 'never') {
      delete communityEvent.check_in_setting;
      createViolation = false;
    }

    setEventSeriesRules(communityEvent);

    /* eslint-disable no-unused-vars */
    const data = {
      ...communityEvent,
      title: communityEvent?.title,
      creates_violation: createViolation,
      clients: [communityEvent.clients],
      media: [],
      start_date: communityEvent.start_date?.format('YYYY-MM-DD'),
      end_date: communityEvent.end_date?.format('YYYY-MM-DD'),
      actions: []
    };

    addMedia(data, 'url', 'url');
    setReminders(data);
    removeUnusedKeys(data);
    setTags(data);
    setAddressCoordinates(data);

    return data;
  };

  const handleCreateEvent = ({ communityEvent }) => {
    const data = prepareData(communityEvent);
    
    addCommunityEvent(data, res => {
      if (res.data.success) {
        toast.addToast({
          position: 'top',
          type: 'checkmark',
          content: 'Your event has been created.'
        });
        setShouldReset(true);
        setTimeout(() => setShouldReset(false), 250);
      } else {
        toast.addToast({
          position: 'top',
          type: 'warning',
          content: res.data.error ? res.data.error : 'Something went wrong.'
        });
      }
    });    
  };

  if (!isReady) return <Loading />;

  return (
    <div className="vc-container">
      <div className="vc-container__scroll-wrapper">
        <div
          className={classnames('vc-container__header', {
            'drawer-open': drawerOpen
          })}
        >
          <h3 className="vc-container__header--heading">Schedule Individual Meeting</h3>
          <div className="vc-status"></div>
        </div>
        <div className="vc-container__form">
          <FormProvider
            schema={schema(
              clientOptions
            )}
            data={{
              communityEvent: { ...eventInitialState(minDeadlineGrace, adjustedStartTime) }
            }}
            handleSubmit={handleCreateEvent}
            actionButtons={getActionButtons()}
            shouldReset={shouldReset}
            formContext={formContext}
          ></FormProvider>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  drawerOpen: state.drawer.drawerOpen,
  clients: state.clients
});

const mapDispatchToProps = {
  getClients,
  addCommunityEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualMeetingDrawer);