export default function programs(state = { list: [], base_skus: [] }, action) {
  switch (action.type) {
    case 'LOAD_PENDING_GET_FACILITY_PROGRAMS':
      return {
        ...state,
        isLoadingFacilityPrograms: true
      };
    case 'LOAD_SUCCESSFUL_GET_FACILITY_PROGRAMS':
      return {
        ...state,
        isLoadingFacilityPrograms: false,
        list: action?.payload?.data?.list ?? state.list,
        base_skus: action?.payload?.data?.base_skus ?? state.base_skus
      };
    case 'LOAD_PENDING_REMOVE_FACILITY_PROGRAM':
      return {
        ...state,
        isRemovingFacilityProgram: true
      };
    case 'LOAD_SUCCESSFUL_REMOVE_FACILITY_PROGRAM':
      return {
        ...state,
        isRemovingFacilityProgram: false
      };
    case 'LOAD_PENDING_UPDATE_FACILITY_PROGRAM':
      return {
        ...state,
        isUpdatingFacilityProgram: true
      };
    case 'LOAD_SUCCESSFUL_UPDATE_FACILITY_PROGRAM':
      return {
        ...state,
        isUpdatingFacilityProgram: false
      };
    case 'LOAD_PENDING_GET_CLIENT_SUBSCRIPTIONS':
      return {
        ...state,
        isLoadingClientSubscriptions: true,
        clientSubscriptions: null
      };
    case 'LOAD_SUCCESSFUL_GET_CLIENT_SUBSCRIPTIONS':
      return {
        ...state,
        isLoadingClientSubscriptions: false,
        clientSubscriptions: action?.payload?.data?.subscriptions ?? []
      };
    case 'RESET_CLIENT_SUBSCRIPTIONS':
      return {
        ...state,
        clientSubscriptions: null
      };
  }
  return state;
}
