import React from 'react';
import axios from 'axios';
import Icon from 'components/Icon';

export const makePoints = (hasEvent, hasPos, extra) => {
  const points = [];
  if (hasEvent) {
    points.push({
      lat: hasEvent.lat,
      lng: hasEvent.lng
    });
  }

  if (hasPos) {
    points.push({ lat: hasPos.lat, lng: hasPos.lng });
  }

  if (extra && extra.hasSpoofed) {
    points.push({
      lat: parseFloat(extra.hasSpoofed.lat),
      lng: parseFloat(extra.hasSpoofed.lng)
    });
  }

  return points;
};

export const getMarkerWithAddress = async ({ lat, lng, handleClose }) => {
  let address = '';
  const apiKey = 'AIzaSyDbGHPq9kU_iuhsGDTpls0iAfxF829fDpo';
  let response = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
  );
  if (response.data.status === 'OK' && response.data.results[0]) {
    const firstResult = response.data.results[0];
    const getComponentValue = type => {
      let componentValue = firstResult.address_components.filter(
        component => component.types.indexOf(type) >= 0
      );
      return componentValue && componentValue.length
        ? componentValue[0].long_name
        : '';
    };
    const country = getComponentValue('country');
    const postalCode = getComponentValue('postal_code');
    address = firstResult.formatted_address
      .replace(`${postalCode},`, '')
      .replace(country, '')
      .replace(/\s+/g, ' ');
  }
  const attributes = { lat, lng, handleClose, address };
  return <MapMarker {...attributes} />;
};
export const MapMarker = ({ lat, lng, handleClose, address }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
      className="map-marker"
      onClick={'function' === typeof handleClose ? handleClose : undefined}
    >
      <Icon name="marker" size="14" className="map-icon" />
      {address && <>&nbsp;{address}</>}
    </a>
  );
};
