import React from 'react';
import classnames from 'classnames';
import TableFilterSelect from 'components/TableFilterSelect';
import './style.scss';

const DisplayFilter = ({
  header = '',
  isEmpty = false,
  message = '',
  showFilter = false,
  value,
  handleChange,
  name = '',
  options = [],
  className = '',
  hideLabel = false
}) => {
  return (
    <div className={`display-filter${className ? ` ${className}` : ''}`}>
      {header ? <h1 className="header">{header}</h1> : null}
      {header ? <div className="spacer" /> : null}
      <div
        className={classnames('display-filter__message', {
          'no-records': !showFilter
        })}
      >
        {!isEmpty ? message : null}
      </div>
      {showFilter && !isEmpty && (
        <TableFilterSelect
          name={name}
          hideLabel={hideLabel}
          label={<span className="filter-text">Display&nbsp;</span>}
          value={value}
          handleChange={handleChange}
          options={options}
        />
      )}
    </div>
  );
};

export default DisplayFilter;
