import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';

import curriculumUtil from 'utils/CurriculumUtil';
import Icon from 'components/Icon';
import StyledLink from 'components/StyledLink';

// eslint-disable-next-line react/display-name
const IconWrapper = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <span ref={ref} {...props}>
      {children}
    </span>
  );
});

const ResourceClient = ({ client = {}, isTrackingClientCompletions = true }) => {
  const completedDate = useMemo(() => {
    if (isTrackingClientCompletions && client?.date_completed) {
      return curriculumUtil.formatDate(client.date_completed);
    }

    return '';
  }, [client?.duration, client?.updated, isTrackingClientCompletions]);

  const updatedDate = useMemo(() => {
    if (client?.duration > 0) {
      return curriculumUtil.formatDate(client.updated);
    }

    return '';
  }, [client?.duration, client?.updated]);

  const tooltipMessage = useMemo(() => {
    if (isTrackingClientCompletions && client?.completed) {
      return `This resource was completed by the assignee ${completedDate}`;
    }

    if (client?.duration > 0) {
      return `The assignee last engaged with the resource on ${updatedDate}`;
    }

    return `The assignee has yet to engage this resource`;
  }, [client?.completed, completedDate, updatedDate, isTrackingClientCompletions]);

  if (isEmpty(client)) {
    return null;
  }

  return (
    <>
      <div className="assignee__container">
        <div className="assignee__container--content">
          <div className="assignee__container--content__info">
            <div className="assignee__container--content__info--item">
              <strong>Name</strong>:&nbsp;{client.name}
            </div>
            <div className="assignee__container--content__info--item">
              <strong>Duration</strong>:&nbsp;
              {client.duration > 0 ? curriculumUtil.formatDuration(client.duration) : 'Not Started'}
            </div>
            {isTrackingClientCompletions ? (
              <div className="assignee__container--content__info--item">
                <strong>Date Completed</strong>:&nbsp;
                {completedDate || '-'}
              </div>
            ) : null}
            {client?.form_response_id ? (
              <div className="assignee__container--content__info--item">
                <StyledLink href={`/clients/get/${client.client_id}/forms/${client.form_response_id}`}>
                  View Form Responses
                </StyledLink>
                &nbsp;(opens in a new tab)
              </div>
            ) : null}
          </div>
          {isTrackingClientCompletions ? (
            <div className="assignee__container--content__status">
              <Tooltip title={tooltipMessage}>
                <IconWrapper>
                  <Icon name={client.completed ? 'checkmark' : 'warning'} />
                </IconWrapper>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
      <div className="divider" />
    </>
  );
};

export default ResourceClient;
