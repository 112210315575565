/**
 * External dependencies
 */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { validate } from 'email-validator';

/**
 * Internal dependencies
 */
import Input from 'components/Input';

const EmailWidget = props => {
  const [state, setEmailState] = useState({
    email1: props?.value,
    email2: '',
    startingEmail: props?.value,
    email1validated: !!props?.value,
    email2validated: false
  });

  const handleSetEmail = useCallback(
    ({ target: { name, value } }) => {
      const newState = {
        ...state,
        [name]: value,
        [`${name}validated`]: validate(value)
      };
      setEmailState(newState);

      if (newState.email1validated && newState.email2validated) {
        if (newState.email1 === newState.email2) {
          props.handleChange(
            { target: { name: props.name, value: newState.email1 } },
            props.blockGroup
          );
          resetErrors();
        } else if (!props.error) {
          props.setErrorsCallback(
            props.name,
            props.blockGroup,
            'Emails do not match'
          );
        }
      }
    },
    [
      state,
      props.handleChange,
      props.name,
      props.blockGroup,
      props.error,
      props.setErrorsCallback,
      resetErrors
    ]
  );

  useEffect(() => {
    if (state.email1 === '') {
      setEmailState(prevState => ({
        ...prevState,
        email2: '',
        email1validated: false,
        email2validated: false
      }));
      resetErrors();
    }
  }, [state.email1, resetErrors]);

  const resetErrors = useCallback(() => {
    if (props.error) {
      props.setErrorsCallback(props.name, props.blockGroup, '');
    }
    if (props.apiError) {
      if ('function' === typeof props.resetCalleeErrors) {
        props.resetCalleeErrors('');
      }
    }
  }, [
    props.error,
    props.setErrorsCallback,
    props.name,
    props.blockGroup,
    props.apiError,
    props.resetCalleeErrors
  ]);

  return (
    <>
      <Input
        label={props.label}
        name="email1"
        value={state.email1}
        handleChange={handleSetEmail}
        id="email1"
        required={props.required}
        error={props.error}
        apiError={props.apiError}
        autoFocus={props.autoFocus}
      />
      {state.email1validated && state.startingEmail !== state.email1 ? (
        <Input
          label="Confirm Email"
          name="email2"
          value={state.email2}
          handleChange={handleSetEmail}
          id="email1"
        />
      ) : null}
    </>
  );
};

EmailWidget.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string
};

export default EmailWidget;
