/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { apiHelp } from 'utils/apiHelpers';
import Input from 'components/Input';

const validationErrors = {
  percentage: 'The value must be between 0 and 100 (inclusive)'
};
const checks = {
  username: {
    params: 'username',
    api: 'check_username',
    replace: 'username'
  },
  phone: {
    params: 'phone',
    api: 'check_phone_number',
    replace: 'phone number'
  },
  pin: {
    params: 'check_in_pin',
    api: 'check_pin_number',
    replace: 'testing pin'
  },
  email: {
    params: 'check_email',
    api: 'check_email',
    replace: 'email'
  }
};

export const ValidatedInput = props => {
  // set starting value as a point of reference since FormProvider constantly sends updated value through props
  const [startingValue, setStartingValue] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isTaken, setTaken] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isValid, setValid] = useState(true);
  const [isSearching, setSearching] = useState(false);
  const [errors, setErrors] = useState('');

  const { id } = useParams();

  const check = () => {
    switch (props.subType) {
      case 'pin':
        {
          evalCheck(!!/^[0-9]+$/.test(props.value) && props.value.length === 6);
        }
        break;
      case 'username':
        {
          evalCheck(
            props.value.length > 2 &&
              !!/^[a-zA-Z]*$/.test(props.value.substring(0, 1) && !!/^[a-zA-Z0-9_]+$/.test(props.value))
          );
        }
        break;
      case 'percentage':
        {
          let val = props.value ? parseInt(props.value) : 0;
          evalCheck(!isNaN(val) && val >= 0 && val <= 100);
        }
        break;
      case 'phone':
        {
          let phone = props.value ? props.value.replace(/\D/g, '') : '';
          evalCheck(
            props.validate === false || (!!phone && !!phone.length && phone.length === 10 && !!/^\d+$/.test(phone))
          );
        }
        break;
      case 'email':
        {
          evalCheck(true); // always let the API handle it
        }
        break;
      default:
        return true;
    }
  };

  const evalCheck = bool => {
    setValid(bool);
    setSearching(bool);
  };

  // on mount, set the start value
  useEffect(() => {
    setStartingValue(props.value);
  }, []);

  // whenever !!isValid and !isTaken, clear errors
  useEffect(() => {
    if (!!isValid && !isTaken) {
      setErrors('');
      props.setErrorsCallback(props.name, props.blockGroup, false);
    }
  }, [isValid, isTaken]);

  // whenever props.value and the startin value aren't the same, run checks
  useEffect(() => {
    if (props.value !== startingValue) {
      check();
    }
  }, [props.value, startingValue]);

  useEffect(() => {
    if (isSearching && isValid && props.value !== startingValue && props.validate !== false) {
      const fetch = async () => {
        const subTypeCheck = checks[props.subType];
        if (!subTypeCheck) {
          setErrors('');
          return;
        }
        const param = {
          [subTypeCheck.params]: props.value,
          client_id: id
        };
        const res = await apiHelp(`clients/${subTypeCheck.api}`, param);
        if (res.data.status === 'error') {
          const error = res.data.error[subTypeCheck.params].replace(subTypeCheck.params, subTypeCheck.replace);
          setErrors(error);
          props.setErrorsCallback(props.name, props.blockGroup, true);
        } else if (res.data.status === 'success') {
          if (res.data.available) {
            setTaken(false);
          }
          if (parseInt(res.data.client, 10) === parseInt(id, 10)) {
            setValid(true);
          }
          setErrors('');
          props.setErrorsCallback(props.name, props.blockGroup, false);
        }
      };
      fetch();
    } else if (!isValid) {
      setErrors(validationErrors[props.subType] ?? 'Invalid value');
    }
    const timer = setTimeout(() => setSearching(false), 4000);

    return clearTimeout(timer);
  }, [props.value, isSearching, isValid]);

  const handleChange = e => {
    let {
      target: { name, value: val, dataset }
    } = e;
    const value = name.includes('phone') ? val.replace(/\D/g, '') : val;
    const evt = { target: { name, value, dataset } };
    props.handleChange(evt, props.blockGroup);
  };
  // eslint-disable-next-line no-unused-vars
  const handleBlur = () => check();

  const getFormattedValue = subType => {
    if (subType === 'phone') {
      // Strip all characters from the input except digits
      let val = props.value.replace(/\D/g, '').substring(0, 10);

      const len = val.length;

      if (len > 3 && len < 7) {
        val = `${val.substring(0, 3)}-${val.substring(3, 6)}`;
      } else if (len > 6) {
        val = `(${val.substring(0, 3)}) ${val.substring(3, 6)}-${val.substring(6, 10)}`;
      }
      return val;
    }
    return props.value;
  };

  const {
    subType,
    // eslint-disable-next-line no-unused-vars
    staticContext,
    inputType,
    ...rest
  } = props;

  return (
    <Input
      {...rest}
      id={props.id}
      label={props.label}
      name={props.name}
      error={!!errors && !!errors.length ? errors : false}
      value={props.value ? getFormattedValue(subType) : ''}
      handleChange={handleChange}
      handleBlur={handleBlur}
      mBottom
      required={props.required}
      type={inputType}
    />
  );
};

ValidatedInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  subType: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  blockGroup: PropTypes.string,
  required: PropTypes.bool,
};

export default ValidatedInput;
