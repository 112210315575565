import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { AdHocAlertTypes } from 'components/Violations/violations-helpers';

export default class CheckEvaluator {
  /**
   * @param {object} event
   * @param {object} violation
   * @param {object} test_check_request_log
   * @returns {object} check-in or violation data into props for `CheckInHistory` or `ViolationsDrawer`, respectively
   */
  constructor(event, violation, test_check_request_log) {
    this.violation = violation ? violation : false;
    this.event = event ? event : false;
    this.test_check_request_log = test_check_request_log ? test_check_request_log : false;

    this.blocks = [];
    this.checkUrl = false;
    this.formattedStatus = '';
    this.fromPhone = '';
    this.hasEvent = false;
    this.hasPos = false;
    this.hasRecording = false;
    this.notifications = [];
    this.status = '';
    this.geozonePositions = false;
    this.showGeozonePolyline = false;

    this.tsFormat = 'YYYY-MM-DD HH:mm:ss';
    this.hrFormat = 'h:mm A';
    this.dayFormat = 'MMM D, YYYY';
    this.dayTimeFormat = 'MMM D, YYYY h:mm A';
    this.isoFormat = 'YYYY-MM-DDTHH:mm:ssZ';
    this.disableDraw = false;
  }

  // entry: called when instantiating the class, routes to appropriate methods depending on props

  getDurationBetweenTimestamps(future, past) {
    const pluralize = (n, word) => `${n} ${word}${n == 1 ? '' : 's'}`;
    const total_time_in_of_zone = moment(future, this.dayTimeFormat).diff(moment(past, this.dayTimeFormat));
    let duration = moment.duration(total_time_in_of_zone);
    let durationFormatted = [];
    if (duration.days()) {
      durationFormatted.push(pluralize(duration.days(), 'day'));
    }
    if (duration.hours()) {
      durationFormatted.push(pluralize(duration.hours(), 'hour'));
    }
    if (duration.minutes()) {
      durationFormatted.push(pluralize(duration.minutes(), 'minute'));
    }
    return durationFormatted.join(' ');
  }
  parseData() {
    const { event, violation, test_check_request_log } = this;
    if (event) return this.setCheckInData();
    if (violation) return this.setViolationData();
    if (test_check_request_log) return this.setRequestData();
  }

  setCheckInData() {
    const {
      event: { check_in_setting, notifications, request_check }
    } = this;
    const log = !!request_check && request_check.length ? request_check[0] : false;
    if (!!check_in_setting && check_in_setting !== 'never' && !!request_check && request_check.length) {
      this.setCheckInBlocks(request_check, notifications);
    }
    this.setEvent(this.event);
    if (!this.violation.test_master_result_id) {
      this.setClientPos(log);
    }
    return this;
  }

  setViolationData() {
    const {
      violation: { event, test_check_request_log, test_master }
    } = this;

    const log =
      !!test_check_request_log && test_check_request_log.test_check_log.length
        ? test_check_request_log.test_check_log[0]
        : !!test_master && test_master.test_check_log.length
        ? test_master.test_check_log[0]
        : false;

    this.setClientName();
    // this needs to be before setViolationBlocks since we sometimes need to reset the position there
    // should only be called if it's not a results type
    if (!this.violation.test_master_result_id) {
      this.setClientPos(log);
    }
    this.setFormattedStatus();
    if (event) {
      this.setEvent(event);
    } else if (test_check_request_log.event) {
      this.setEvent(test_check_request_log.event);
    }
    this.setViolationBlocks();
    return this;
  }

  setRequestData() {
    const log = this.test_check_request_log.test_check_log.length
      ? this.test_check_request_log.test_check_log[0]
      : false;
    // if we have test check logs, set them to have checked 1, needed for the details below
    this.test_check_request_log.test_check_log.map(check => {
      check.checked = '1';
      check.request_time = this.test_check_request_log.request_time;
      check.request_deadline = this.test_check_request_log.request_deadline;
    });
    const request_checks = this.test_check_request_log.test_check_log.length
      ? this.test_check_request_log.test_check_log
      : [this.test_check_request_log];
    this.setCheckInBlocks(request_checks);
    if (!this.violation.test_master_result_id) {
      this.setClientPos(log);
    }
    return this;
  }

  setClientName() {
    const { violation } = this;
    if (violation) {
      this.clientName = `${violation.first_name} ${violation.last_name}`;
    }
  }

  /**
   * makes distinct blocks of data, which are used to render `StatusBlock` and `InfoBlock`
   * components on `CheckInHistoryDrawer`
   */

  setCheckInBlocks(request_check, notifications = false) {
    this.notifications = !!notifications && notifications.length ? notifications : [];

    if (request_check.length) {
      const blocks = [];
      request_check.forEach(check => {
        let blockLabel = 'Pending';
        let specifics = '';

        const meta = !!check.meta && JSON.parse(check.meta);
        const late = this.isCheckInLate(check);

        if (!!meta && !!meta.out_of_bounds && !isEmpty(meta.out_of_bounds)) {
          blockLabel = 'Out of Bounds';
          specifics = this.getOutOfBounds(meta.distance_from_location);
          const { ...details } = this.getDetails('out_of_bounds', check);
          blocks.push({ blockLabel, specifics, ...details });
        }

        if (check.checked === '1' && !late) {
          blockLabel = 'Checked In';
          specifics = false;
          const { ...details } = this.getDetails('checked_in', check);
          blocks.push({ blockLabel, specifics, ...details });
        }

        if (late) {
          blockLabel = 'Late';
          const minutesLate = moment(check.time).diff(check.request_deadline, 'minutes');
          const secondsLate = minutesLate < 1 ? moment(check.time).diff(check.request_deadline, 'seconds') : false;
          specifics = !secondsLate ? this.getLate(minutesLate) : this.getLate(secondsLate, 'seconds');
          const { ...details } = this.getDetails('late', check);
          blocks.push({ blockLabel, specifics, ...details });
        }

        if (check.early === '1') {
          blockLabel = 'Early';
          specifics = false;
          const { ...details } = this.getDetails('early', check);
          blocks.push({ blockLabel, specifics, ...details });
        }

        if (moment().isAfter(check.request_deadline) && check.checked === '0') {
          blockLabel = 'Failed to Check In';
          specifics = false;
          const { ...details } = this.getDetails('failed_to_check_in', check);
          blocks.push({ blockLabel, specifics, ...details });
        }

        if (blockLabel === 'Pending' && specifics === '') {
          specifics = 'The client is not yet required to check in.';
          const { ...details } = this.getDetails('pending', check);
          blocks.push({ blockLabel, specifics, ...details });
        }
        this.checkUrl = this.parseCheckUrl();
      });

      this.blocks = blocks;
    }
  }

  /**
   * makes distinct blocks of data, which are used to render `StatusBlock` and `InfoBlock`
   * components on `ViolationsDrawer`
   */

  setViolationBlocks() {
    const { violation } = this;

    if (violation && violation.violation_details.length) {
      const { test_check_request_log_id, test_check_request_log } = violation;

      for (let i = 0; i < violation.violation_details.length; i++) {
        const detail = violation.violation_details[i];
        let { type, meta } = detail;

        let actionType =
          !!test_check_request_log_id &&
          test_check_request_log &&
          (test_check_request_log.event || test_check_request_log.external_event_id)
            ? 'Scheduled Check-In'
            : test_check_request_log_id
            ? 'Ad-Hoc Check-In'
            : '';

        let blockLabel = '';
        let specifics = '';

        meta = JSON.parse(meta);

        const {
          minutes_late,
          distance_out_of_bounds,
          event_location,
          result,
          logged_out_datetime,
          tamper_detected
        } = meta;

        // add type-specific details to the block
        const { ...details } = this.getDetails(type, detail);

        switch (type) {
          case 'out_of_bounds':
            {
              blockLabel = `Out of Bounds (${actionType})`;
              specifics = this.getOutOfBounds(distance_out_of_bounds);
              this.setEvent(event_location);
            }
            break;
          case 'daily_late':
            {
              blockLabel = 'Late (Daily)';
              specifics = this.getLate(minutes_late);
            }
            break;
          case 'scheduled_late':
            {
              blockLabel = 'Late (' + actionType.trim() + ')';
              specifics = this.getLate(minutes_late);
            }
            break;
          case 'spoofed_message_location':
            {
              blockLabel = `${actionType ? `(${actionType})` : ''}Spoofed Message Location Attempt`;
            }
            break;
          case 'spoofed_location':
            {
              blockLabel = `${actionType ? `(${actionType})` : ''}Spoofed Location Attempt`;
            }
            break;
          case 'drug_test':
            {
              blockLabel = 'Drug Test Results';
              specifics = `Result: ${this.fixResult(result)}`;
            }
            break;
          case 'missed_daily_check_in':
            blockLabel = 'Missed (Daily Check-in)';
            break;
          case 'no_location_during_check_in':
            blockLabel = 'No Location Data During Check-In';
            break;
          case 'missed_event_check_in':
            blockLabel = 'Missed (Event Check-in)';
            break;
          case 'missed_random_check_in':
            blockLabel = 'Missed (Random Check-in)';
            break;
          case 'missed_scheduled_check_in':
            blockLabel = 'Missed (Scheduled Check-in)';
            break;
          case 'missed_scheduled_form':
            blockLabel = 'Missed (Scheduled Form)';
            break;
          case 'breathalyzer':
            blockLabel = 'Alcohol Present';
            break;
          case 'facial_mismatch':
            blockLabel = 'Facial Mismatch';
            break;
          case 'low_authenticity':
            blockLabel = 'Low Authenticity Score';
            break;
          case 'no_payment_attempt':
            blockLabel = 'No Payment Attempted';
            break;
          case 'payment_failed':
            blockLabel = 'Failed Payment';
            break;
          case 'goal_incomplete':
            blockLabel = 'Incomplete Goal';
            return this.blocks.push({ blockLabel, specifics, ...details });
          case 'outside_inclusion_zone':
            blockLabel = 'Outside Inclusion Zone';
            return this.blocks.push({ blockLabel, specifics, ...details });
          case 'inside_exclusion_zone':
            blockLabel = 'Inside Exclusion Zone';
            return this.blocks.push({ blockLabel, specifics, ...details });
          case 'no_data_violation':
            blockLabel = 'Offline';
            return this.blocks.push({ blockLabel, specifics, ...details });
          case 'no_ble_data_violation':
            blockLabel = 'No Bluetooth Beacon Data';
            return this.blocks.push({ blockLabel, specifics, ...details });
          case 'location_tracking_logout':
            blockLabel = 'Logged Out (Location Tracking)';
            specifics = 'Logged out at: ' + this.formatDateAndTimeLocale(logged_out_datetime);
            break;
          case 'reconnectband_tamper_detected':
            blockLabel = 'ReconnectBand Tamper Detected';
            specifics = 'Tamper Detected at: ' + this.formatDateAndTimeLocale(tamper_detected);
            break;
          case 'reconnectband_tamper_glitch':
            blockLabel = 'ReconnectBand Tamper Glitch Detected';
            specifics = 'Tamper Glitch was Detected at: ' + this.formatDateAndTimeLocale(tamper_detected);
            break;
          default: {
            if (type in AdHocAlertTypes) {
              blockLabel = AdHocAlertTypes[type];
              return this.blocks.push({ blockLabel, specifics, ...details });
            }
          }
        }

        /**
         * if the block isn't one of these types, check for an image/recording
         * and set it on the instance -- there will only ever be one of these.
         */
        if (
          [
            'drug_test',
            'missed_daily_check_in',
            'missed_event_check_in',
            'missed_random_check_in',
            'missed_scheduled_check_in',
            'missed_scheduled_form',
            'outside_inclusion_zone',
            'inside_exclusion_zone',
            'no_location_during_check_in',
            'no_data_violation',
            'no_payment_attempt',
            'payment_failed',
            'no_ble_data_violation',
            'low_authenticity',
            'goal_incomplete'
          ].indexOf(type) === -1
        ) {
          this.checkUrl = this.parseCheckUrl();
        }

        if (type === 'facial_mismatch') {
          this.hasMismatch = this.parseMismatchData(detail);
        }

        this.blocks.push({ blockLabel, specifics, ...details });
      }
    }
  }

  /**
   * `status` is used in the callee to conditionally apply classes,
   *  but the text itself is also displayed in title case. Currently,
   * it's only used on `ViolationsDrawer`
   */

  setFormattedStatus() {
    const {
      violation: { status }
    } = this;

    this.formattedStatus = status ? `${status.charAt(0).toUpperCase()}${status.slice(1)}` : 'Unknown';
    this.status = status ? status : 'Unknown';
  }

  /**
   * @param {string} type, the type of check or violation
   * @param {object} detail, currently the violation detail
   * @returns {void} sets `blocks` array of object props on instance
   */

  getDetails(type, detail) {
    const { violation } = this;
    const meta = JSON.parse(detail.meta);
    const test_check_request_log = this.test_check_request_log
      ? this.test_check_request_log
      : violation.test_check_request_log;

    switch (type) {
      case 'out_of_bounds':
        {
          const { daily_check } = detail;
          if (test_check_request_log) {
            const { reason, request_time, request_deadline, test_check_log } = test_check_request_log;
            if (test_check_log && test_check_log.length) {
              const { time, authenticity_probability } = test_check_log[0];
              return {
                renderEvent: daily_check !== '1',
                checkInWindow: this.formatCheckInWindow(request_time, request_deadline),
                checkInReason: reason,
                checkInTime: this.formatCheckInTime(time),
                authenticity_probability
              };
            }
          }
        }
        break;
      case 'daily_late':
        {
          const { test_master } = violation;
          if (test_master) {
            const { date, meta: test_master_meta, test_check_log } = test_master;
            if (test_check_log.length && test_master_meta) {
              return {
                checkInWindow: this.parseLametsFormat(date, test_master_meta),
                checkInTime:
                  !!test_check_log && !!test_check_log.length ? this.formatCheckInTime(test_check_log[0].time) : false,
                authenticity_probability:
                  !!test_check_log && !!test_check_log.length ? test_check_log[0].authenticity_probability : false
              };
            }
          }
        }
        break;
      case 'scheduled_late':
        {
          if (test_check_request_log) {
            const { reason, request_time, request_deadline, test_check_log } = test_check_request_log;
            if (request_time && request_deadline) {
              return {
                checkInWindow: this.formatCheckInWindow(request_time, request_deadline),
                checkInReason: reason,
                checkInTime:
                  !!test_check_log && !!test_check_log.length ? this.formatCheckInTime(test_check_log[0].time) : false,
                renderEvent: true,
                authenticity_probability:
                  !!test_check_log && !!test_check_log.length ? test_check_log[0].authenticity_probability : false
              };
            }
          }
        }
        break;
      case 'spoofed_message_location':
        {
          if (meta.spoofed_position) {
            return {
              date: moment(detail.created, this.tsFormat).format('MMM D, YYYY h:mm A'),
              hasSpoofed: {
                lat: parseFloat(meta.spoofed_position.latitude),
                lng: parseFloat(meta.spoofed_position.longitude)
              }
            };
          }
        }
        break;
      case 'spoofed_location':
        {
          if (meta.spoofed_position) {
            const {
              test_master: { date, meta: test_master_meta, test_check_log }
            } = violation;

            const { daily_check } = detail;
            return {
              checkInWindow:
                !!test_master_meta && daily_check !== '1' && this.parseLametsFormat(date, test_master_meta),
              checkInTime:
                !!test_check_log && !!test_check_log.length ? this.formatCheckInTime(test_check_log[0].time) : false,
              hasSpoofed: {
                lat: parseFloat(meta.spoofed_position.latitude),
                lng: parseFloat(meta.spoofed_position.longitude)
              },
              renderEvent: daily_check !== '1',
              authenticity_probability:
                !!test_check_log && !!test_check_log.length ? test_check_log[0].authenticity_probability : false
            };
          }
        }
        break;
      case 'drug_test': {
        const { test_master } = violation;
        let note;
        if (test_master) {
          const { test_master_note } = test_master;
          note = !!test_master_note && !!test_master_note.length ? test_master_note[0].note : false;
        }
        return {
          date: moment(detail.created, this.tsFormat).format('MMM D, YYYY'),
          note
        };
      }
      case 'missed_daily_check_in':
        {
          const { test_master } = violation;

          if (test_master && test_master.meta) {
            return {
              checkInWindow: this.parseLametsFormat(test_master.date, test_master.meta),
              date: this.formatDate(meta.missed_daily_check_in_date)
            };
          }
        }
        break;
      case 'no_location_during_check_in': {
        const { date } = meta;
        return {
          date: this.formatDate(date),
          renderEvent: false
        };
      }
      case 'missed_event_check_in': {
        const { reason, request_time, request_deadline } = test_check_request_log;
        const { missed_event_check_in_date } = meta;
        return {
          checkInWindow: this.formatCheckInWindow(request_time, request_deadline),
          checkInReason: reason,
          date: this.formatDate(missed_event_check_in_date),
          renderEvent: true
        };
      }
      case 'missed_scheduled_check_in':
        {
          const { request_time, request_deadline, reason } = test_check_request_log;
          const { daily_check } = detail;
          if (request_time && request_deadline) {
            return {
              checkInWindow: this.formatCheckInWindow(request_time, request_deadline),
              checkInReason: reason,
              renderEvent: daily_check !== '1'
            };
          }
        }
        break;
      case 'goal_incomplete':
        {
          const { goal_incomplete } = detail;
          if (goal_incomplete) {
            return {
              goal_incomplete: goal_incomplete
            };
          }
        }
        break;
      case 'missed_random_check_in':
        {
          const { request_time, request_deadline } = test_check_request_log;
          const { missed_random_check_in_date } = meta;
          const { daily_check } = detail;
          if (request_time && request_deadline) {
            return {
              checkInWindow: this.formatCheckInWindow(request_time, request_deadline),
              date: this.formatDate(missed_random_check_in_date),
              renderEvent: daily_check !== '1'
            };
          }
        }
        break;
      case 'missed_scheduled_form': {
        const {
          event: { id, closed_datetime }
        } = meta;
        return {
          date: moment(closed_datetime, this.isoFormat).format(this.dayTimeFormat),
          form: (
            <Link to={`/forms/details/${id}`} className="form-link">
              View Form
            </Link>
          )
        };
      }
      case 'failed_to_check_in': {
        const { daily_check, request_time, request_deadline } = detail;
        return {
          checkInWindow: this.formatCheckInWindow(request_time, request_deadline),
          date: this.formatDate(request_deadline),
          renderEvent: daily_check !== '1'
        };
      }
      case 'checked_in': {
        const { daily_check, time } = detail;
        const request_time = detail.request_time ? detail.request_time : test_check_request_log.request_time;
        const request_deadline = detail.request_deadline
          ? detail.request_deadline
          : test_check_request_log.request_deadline;
        return {
          checkInWindow: this.formatCheckInWindow(request_time, request_deadline),
          date: this.formatDate(request_deadline),
          checkInTime: this.formatCheckInTime(time),
          renderEvent: daily_check !== '1'
        };
      }
      case 'late': {
        const { daily_check, time } = detail;
        const request_time = detail.request_time ? detail.request_time : test_check_request_log.request_time;
        const request_deadline = detail.request_deadline
          ? detail.request_deadline
          : test_check_request_log.request_deadline;
        return {
          checkInWindow: this.formatCheckInWindow(request_time, request_deadline),
          date: this.formatDate(request_deadline),
          checkInTime: this.formatCheckInTime(time),
          renderEvent: daily_check !== '1'
        };
      }
      case 'pending': {
        const { daily_check, request_deadline, request_time } = detail;
        return {
          checkInWindow: this.formatCheckInWindow(request_time, request_deadline),
          date: this.formatDate(request_deadline),
          renderEvent: daily_check !== '1'
        };
      }
      case 'breathalyzer': {
        const { bac } = meta;
        return {
          bac: bac
        };
      }
      case 'low_authenticity': {
        const { probability, test_check_log } = meta;
        // we'll need to set some more data here in case there is more than one test_check_log on test_master
        this.checkUrl = test_check_log.check_url;
        // if we have a test_check_request_log, then we are good
        if (!test_check_request_log) {
          const log_id = test_check_log.test_check_log_id;
          const { test_master } = violation;
          // go through all our test_check_logs on test_master to find the matching one
          if (test_master.test_check_log) {
            test_master.test_check_log.forEach(log => {
              if (log.id === log_id) {
                this.setClientPos(log);
              }
            });
          }
        }
        return {
          authenticity_probability: probability
        };
      }
      case 'facial_mismatch': {
        let { meta: mismatchMeta } = detail;
        mismatchMeta = JSON.parse(mismatchMeta);
        const {
          test_check_log: { time }
        } = meta;
        return {
          similarity: `${parseInt(mismatchMeta.similarity, 10).toFixed(2)}% Similar`,
          date: this.formatDate(time)
        };
      }
      case 'no_payment_attempt':
      case 'payment_failed': {
        let subscription = JSON.parse(detail.meta).subscription;
        subscription.subscription_violation = true;
        subscription.amount = 0;
        const toDecimal = value => ((value * 100) / 100).toFixed(2);

        subscription.details = subscription.skus.map(sku => {
          subscription.amount += sku.price;
          return <p key={sku.name}>{`${sku.name} ($${toDecimal(sku.price)}/mo.)`}</p>;
        });
        subscription.amount = `$${toDecimal(subscription.amount)}/mo.`;
        return subscription;
      }
      case 'outside_inclusion_zone': {
        const {
          violation: { violation_details }
        } = this;
        let inclusion_name = false;
        const found = violation_details.find(d => {
          const meta = JSON.parse(d?.meta);
          if (meta && meta.inclusion_zone_data) {
            return true;
          }
        });
        if (found) {
          const {
            inclusion_zone_data: { name }
          } = JSON.parse(found.meta);
          inclusion_name = name;
        }

        const timeSpan = this.getTimeSpan(violation);
        const [zone_exit, zone_entry] = timeSpan.split('-');

        const resultData = {
          hasPhone: false,
          inclusion_name,
          zone_exit
        };
        if (zone_entry) {
          if (violation.status == 'active') {
            resultData.zone_re_entry = '';
            resultData.last_known_location = zone_entry;
          } else {
            resultData.zone_re_entry = zone_entry;
            resultData.total_time_out_of_zone = this.getDurationBetweenTimestamps(zone_entry, zone_exit);
          }
        }
        return resultData;
      }
      case 'inside_exclusion_zone': {
        let { meta: exclusionZoneMeta } = detail;
        exclusionZoneMeta = JSON.parse(exclusionZoneMeta);
        const exclusion_name_link = (
          <Link
            className="address-link"
            to={`/clients/get/${exclusionZoneMeta.exclusion_zone_data.client_id}/location/zones/edit/${exclusionZoneMeta.exclusion_zone_data.id}`}
            target="_blank"
          >
            {exclusionZoneMeta.exclusion_zone_data.name}
          </Link>
        );

        const timeSpan = this.getTimeSpan(violation);
        const [zone_entry, zone_exit] = timeSpan.split('-');
        const resultData = {
          client_location_time: this.getTimeSpan(violation),
          hasPhone: false,
          zone_entry,
          exclusion_zone_name: exclusion_name_link,
          exclusion_zone_address: exclusionZoneMeta.exclusion_zone_data.address
        };
        if (zone_exit) {
          resultData.zone_exit = zone_exit;
          resultData.total_time_in_of_zone = this.getDurationBetweenTimestamps(zone_exit, zone_entry);
        }
        return resultData;
      }
      case 'no_data_violation': {
        // we may want to optionally pass this for other maps, or just wholesale?
        // for now, design only asked to limit when the violation is this type
        this.disableDraw = true;
        const client_location_time = this.getTimeSpan(violation).split(' - ');
        return {
          hasPhone: false,
          time_out: client_location_time[0],
          time_in: client_location_time[1]
        };
      }
      case 'no_ble_data_violation': {
        return {
          client_location_time: this.getTimeSpan(violation)
        };
      }
    }
  }

  /**
   * @param {string} distance_out_of_bounds (example: "23.4 miles")
   * @returns {string} a conditionally reformatted string (example: 1650 miles -> 1,650 miles)
   */

  getOutOfBounds(distance_out_of_bounds) {
    if (distance_out_of_bounds !== undefined && typeof distance_out_of_bounds === 'string') {
      const [...distance] = distance_out_of_bounds.split(' ');
      const bounds_string = parseFloat(distance[0]);
      return `Distance Out of Bounds: ${
        bounds_string >= 1000 ? `${bounds_string.toLocaleString()} ${distance[1]}` : distance_out_of_bounds
      }`;
    } else return 'Unknown'; // should never happen
  }

  /**
   * @param {string} minutes_late (example: 24 minutes late)
   * @returns {string} a conditionally reformatted string (example: 120 -> Late: 2 hours)
   */

  getLate(lateMeasure, type) {
    if (lateMeasure !== undefined) {
      if (type !== 'seconds') {
        if (lateMeasure > 59) {
          const hours = lateMeasure / 60;
          const roundedHours = Math.floor(hours);
          const min = (hours - roundedHours) * 60;
          const roundedMin = Math.round(min);
          return `Hours Late: ${roundedHours}hr${roundedMin > 0 ? ` ${roundedMin}min` : ''}`;
        }
        return `Minutes Late: ${lateMeasure >= 1000 ? lateMeasure.toLocaleString() : lateMeasure}`;
      } else {
        return `Seconds Late: ${lateMeasure}`;
      }
    }
  }

  /**
   * @param {object} violation
   * @returns {string} string with time span
   */
  getTimeSpan(violation) {
    // sort the violation details by location time, in case of offline where they come in in reverse order
    const details = violation.violation_details.sort((a, b) => {
      const aMeta = JSON.parse(a.meta);
      const bMeta = JSON.parse(b.meta);
      const aTime = get(aMeta, 'client_location.time')
        ? moment(get(aMeta, 'client_location.time'))
        : get(aMeta, 'last_successful_scan.last_successful_scan_time')
        ? moment(get(aMeta, 'last_successful_scan.last_successful_scan_time'))
        : moment();
      const bTime = get(bMeta, 'client_location.time')
        ? moment(get(bMeta, 'client_location.time'))
        : get(bMeta, 'last_successful_scan.last_successful_scan_time')
        ? moment(get(bMeta, 'last_successful_scan.last_successful_scan_time'))
        : moment();
      if (aTime.isBefore(bTime)) {
        return -1;
      } else {
        return 1;
      }
    });
    const firstDetailsMeta = JSON.parse(details[0].meta);

    // get the formatted start_time from the violation details
    let start_time = '';
    if (undefined !== get(firstDetailsMeta, 'last_time')) {
      start_time = this.formatDateAndTime(firstDetailsMeta.last_time);
    } else if (undefined !== get(firstDetailsMeta, 'client_location.time')) {
      start_time = this.formatDateAndTime(firstDetailsMeta.client_location.time);
    } else if (undefined !== get(firstDetailsMeta, 'last_successful_scan.last_successful_scan_time')) {
      start_time = this.formatDateAndTime(firstDetailsMeta.last_successful_scan.last_successful_scan_time);
    }
    // end_time is an empty string by default, so that we display
    // just the start_time if there is no end time
    let end_time = '';

    // see if we have more than one violation details
    if (details.length > 1) {
      const lastDetailsMeta = JSON.parse(details[details.length - 1].meta);
      // now that we have the last violation details, we'll give a range
      end_time = ' - ';

      // concat the formatted end_time from the meta of the last violation details
      if (undefined !== get(lastDetailsMeta, 'end_time')) {
        end_time += this.formatDateAndTime(lastDetailsMeta.end_time);
      } else if (undefined !== get(lastDetailsMeta, 'client_location.time')) {
        end_time += this.formatDateAndTime(lastDetailsMeta.client_location.time);
      } else if (undefined !== get(lastDetailsMeta, 'last_successful_scan.last_successful_scan_time')) {
        end_time += this.formatDateAndTime(lastDetailsMeta.last_successful_scan.last_successful_scan_time);
      }
    }
    return start_time + end_time;
  }

  /**
   * @param {string} date, 'YYYY-MM-DD'
   * @param {string} meta, containing, for example, `{ wsh: 7, wsm: 0, weh: 16, wem: 15 }`
   * @returns {string} a formatted range (example: `9:00 AM - 1:00 PM`)
   */

  parseLametsFormat(date, meta) {
    const { wsh, wsm, weh, wem } = JSON.parse(meta);
    if (!wsh || !wsm || !weh || !wem) return null;
    const start = `${wsh < 10 && wsh.length === 1 ? `0${wsh}` : wsh}:${wsm < 10 && wsm.length === 1 ? `0${wsm}` : wsm}`;
    const end = `${weh < 10 && weh.length === 1 ? `0${weh}` : weh}:${wem < 10 && wem.length === 1 ? `0${wem}` : wem}`;
    return `${moment(`${date} ${start}`, 'YYYY-MM-DD HH:mm').format(this.hrFormat)} - ${moment(
      `${date} ${end}`,
      'YYYY-MM-DD HH:mm'
    ).format(this.hrFormat)}`;
  }

  /**
   * @returns {void}, sets an object `hasEvent` with event-specific details on the instance
   */

  setEvent(event) {
    if (event) {
      const { location, latitude, longitude, lenience } = event;
      if (location) {
        const lat = location.locationLatLng ? location.locationLatLng.split(',')[0] : location.lat;

        const lng = location.locationLatLng ? location.locationLatLng.split(' ')[1] : location.lng;

        this.hasEvent = {
          address: this.formatAddress(location.address),
          lat: parseFloat(lat),
          lng: parseFloat(lng),
          date: moment(event.end_time, 'YYYY-MM-DD').format('MMM D, YYYY'),
          lenience: event.lenience ? event.lenience : 0,
          name: location.label ? location.label : location.name,
          time: `${moment(event.start_time, this.tsFormat).format(this.hrFormat)} - ${moment(
            event.end_time,
            this.tsFormat
          ).format(this.hrFormat)}`
        };
      }
      // if we have overrides (for updated locations), let's set them here
      if (latitude && longitude && lenience) {
        this.hasEvent = {
          ...this.hasEvent,
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
          lenience: parseFloat(lenience)
        };
      }
    }
    return false;
  }

  /**
   * @param {string} address
   * @returns {string} a formatted address with `, USA` removed
   */

  formatAddress(address) {
    return address.replace(/ [0-9]{5}(-[0-9]{4})?/, '').replace(/, USA/, '');
  }

  /**
   * Checks both `test_check_request_log` and `test_master` (if the former check fails),
   * or `request_check` from the check-in `event` property,
   * for the `check_url` property
   *
   * @returns {string} the URL of the image or recording
   */

  parseCheckUrl() {
    const {
      violation: { test_check_request_log, test_master },
      event: { request_check },
      test_check_request_log: { test_check_log }
    } = this;

    const log =
      !!test_check_request_log && test_check_request_log.test_check_log.length
        ? test_check_request_log.test_check_log[0]
        : !!test_master && test_master.test_check_log.length
        ? test_master.test_check_log[0]
        : !!request_check && !!request_check.length
        ? request_check[0]
        : !!test_check_log && test_check_log.length
        ? test_check_log[0]
        : false;

    if (log && log.check_url) {
      if (log.check_url.indexOf('mp3') >= 0) {
        this.fromPhone = !!log.from_phone && this.formatPhone(log.from_phone);
        this.hasRecording = true;
      }
      return log.check_url;
    }
    return false;
  }

  /**
   * @returns {void}, sets the instance's `hasPos` property with client positional props
   */

  setClientPos(log) {
    if (log) {
      const meta = log.meta ? JSON.parse(log.meta) : false;
      if (meta) {
        const {
          pos,
          phone_meta: { location_age_ms },
          event_location
        } = meta;
        if (pos && !this.hasMismatch) {
          const { accuracy, latitude, longitude } = pos;
          if (latitude && longitude) {
            this.hasPos = {
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
              accuracy: accuracy ? accuracy : 0,
              location_age_ms
            };
          }
          if (event_location) {
            const { latitude, longitude, lenience } = event_location;
            if (latitude && longitude) {
              this.hasEvent = {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
                lenience: parseFloat(lenience)
              };
            }
          }
        }
      }
    }

    const meta_has_zone_type = this.violation.violation_details?.[0].type.indexOf('_zone') > -1 ?? false;

    if (meta_has_zone_type) {
      this.geozonePositions = this.violation.violation_details.map(detail => {
        // only show the polyline for non no_data_violations
        if (detail.type !== 'no_data_violation') {
          this.showGeozonePolyline = true;
        }
        const meta = JSON.parse(detail.meta);
        const clientLocation = meta.client_location;
        return {
          accuracy: clientLocation.accuracy,
          lat: parseFloat(clientLocation.latitude),
          lng: parseFloat(clientLocation.longitude)
        };
      });
    }
  }

  /**
   * @param {string} result
   * @returns {string}, for check-ins, formatted results string w/ the same wording found via dropdowns in the web app
   */

  fixResult(result) {
    switch (result) {
      case 'not_logged':
        return 'Not Logged';
      case 'pass':
        return 'Passed';
      case 'fail':
        return 'Failed';
      case 'reported':
        return 'Reported';
      case 'no_show':
        return 'Did Not Report';
      case 'reported_not_provided':
        return 'Reported - Did Not Provide';
      case 'excused':
        return 'Excused';
      default:
        return 'Unknown';
    }
  }

  /**
   * @param {string} number
   * @returns {string}, a formatted phone number (example: 5555555555 -> (555) 555-5555)
   */

  formatPhone(number) {
    const stepOne = number.replace(/\D/g, '');
    const match = stepOne.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return number;
  }

  /**
   *
   * @param {object} checkIn
   * @returns {boolean}, whether the check is classifiably late
   */

  isCheckInLate(checkIn) {
    const { event_id, test_check_request_log_id, time, request_deadline, late } = checkIn;
    // events and test_check_request_logs are treated a little differently since late is for daily checks
    if (event_id || test_check_request_log_id) {
      // if there is no check-in time, it isn't late, but missed
      if (time) {
        return moment(time).isAfter(request_deadline);
      } else {
        return false;
      }
    }
    // check-in late is a string, so we'll return a boolean
    return late === '1';
  }

  /**
   *
   * @param {string} start, the starting timestamp in the range
   * @param {string} end, the ending timestamp in the range
   * @returns {string}, a formatted time range (example: 2019-10-16 16:30:00, 2019-10-16 17:30:00 -> 4:30 PM - 5:30 PM)
   */

  formatCheckInWindow(start, end) {
    return `${moment(start, this.tsFormat).format(this.hrFormat)} - ${moment(end, this.tsFormat).format(
      this.hrFormat
    )}`;
  }

  /**
   *
   * @param {string} time
   * @returns {string}, a formatted time (example: 2019-07-03 13:42:00 -> 1:42 PM)
   */

  formatCheckInTime(time) {
    return moment(time, this.tsFormat).format(this.hrFormat);
  }

  /**
   *
   * @param {string} date
   * @returns {string} a formatted month, day, and year (example: 2019-05-01 14:25:00 -> May 1, 2019)
   */

  formatDate(date) {
    return moment(date, this.tsFormat).format(this.dayFormat);
  }

  /**
   * Format Date and Time
   *
   * @param {string} date
   * @returns {string} a formatted month, day, and year with time (example: 2019-05-01 14:25:00 -> May 1, 2019 2:25pm)
   */

  formatDateAndTime(date) {
    return moment(date, this.tsFormat).format(this.dayTimeFormat);
  }

  /**
   * Format Date and Time with locale
   *
   * @param {string} date
   * @returns {string} a formatted month, day, and year with time (example: 2019-05-01 14:25:00 -> May 1, 2019 2:25pm) in the users timezone according to their browser
   */
  formatDateAndTimeLocale(date) {
    const jsDate = new Date(date + ' UTC');
    return moment(jsDate, this.tsFormat).format(this.dayTimeFormat);
  }

  parseMismatchData(detail) {
    let { meta } = detail;

    if (meta) {
      meta = JSON.parse(meta);

      const {
        test_check_log: { check_url },
        baseline_front_url
      } = meta;

      return { check_url, baseline_front_url };
    }
  }
}
