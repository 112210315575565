/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import {
  closeDrawer,
  getLocations,
  removeLocation,
  assignLocation
} from 'actions/actionCreators';
import getLocationsList from 'selectors/getLocationsList';
import getClientLocations from 'selectors/getClientLocations';
import Button from 'components/Button';
import { catchErrors } from 'utils/apiHelpers';
import Icon from 'components/Icon';
import Checkbox from 'components/Checkbox';

/**
 * Collect ids from a given list of locations.
 *
 * @param {array} collection List of locations with id as one of their properties.
 *
 * @returns {array} List of ids extracted from the locations.
 */
const pluckIds = collection => collection.map(o => (({ id }) => id)(o));

function LocationsDrawer({
  drawer: {
    drawerOpen,
    drawerProps: { clientId, viewLocationOnMap }
  },
  closeDrawer,
  facilityLocations = [],
  clientLocations = [],
  assignLocation,
  removeLocation,
  getLocations
}) {
  const [selectedLocations, setSelectedLocations] = useState(clientLocations);

  useEffect(() => {
    getLocations({});
  }, []);

  const handleAssign = () => {
    const clientLocationIds = pluckIds(clientLocations);
    const selectedLocationIds = pluckIds(selectedLocations);
    const updatedLocations = [];

    // Go through the existing client locations first and delete those that were unselected.
    clientLocations.forEach(location => {
      if (!selectedLocationIds.includes(location.id)) {
        try {
          removeLocation(location.id, clientId);
        } catch (error) {
          catchErrors(error);
        }
      } else {
        updatedLocations.push(location);
      }
    });

    // Now go through the selected locations and create the new ones that were assigned.
    selectedLocations.forEach(({ id }) => {
      if (!clientLocationIds.includes(id)) {
        try {
          assignLocation(id, clientId);
        } catch (error) {
          catchErrors(error);
        }
      }
    });
    closeDrawer();
  };

  const allLocations = facilityLocations.filter(
    fL => !clientLocations.some(cL => cL.name === fL.name)
  );
  const isEmptyAllLocations = 0 === allLocations.length;
  const isSelectedAll = selectedLocations.length === allLocations.length;

  return (
    <div className="vc-container locations-drawer">
      <div className="vc-container__scroll-wrapper">
        <div
          className={classnames('vc-container__header', {
            'drawer-open': drawerOpen
          })}
        >
          <h3 className="vc-container__header--heading">Assigned Locations</h3>
        </div>
        {isEmptyAllLocations ? (
          <div className="location-drawer__no-locations">
            <p>There are currently no facility locations to assign.</p>
          </div>
        ) : (
          <>
            <div className="location-drawer__header">
              <Checkbox
                checked={isSelectedAll}
                aria-label="Select all locations"
                handleChange={() =>
                  isSelectedAll
                    ? setSelectedLocations([])
                    : setSelectedLocations(allLocations)
                }
              />
              <span className="location-drawer__header-type">Type</span>
              <span className="location-drawer__header-name">Name</span>
            </div>
            <ul className="locations-drawer__list">
              {allLocations.map(location => {
                const isLocationSelected = !!selectedLocations.find(
                  loc => location.id === loc.id
                );
                const isExclusion = JSON.parse(location.exclusion);
                const handleLocationClick = () =>
                  setSelectedLocations(
                    isLocationSelected
                      ? selectedLocations.filter(loc => location.id !== loc.id)
                      : [...selectedLocations, location]
                  );
                const handleMapLaunch = () => viewLocationOnMap(location);
                return (
                  <li key={`location-${location.id}`}>
                    <Checkbox
                      id={`location-${location.id}`}
                      checked={isLocationSelected}
                      aria-label={location.name}
                      handleChange={handleLocationClick}
                    />
                    <span
                      title={isExclusion ? 'Exclusion' : 'Inclusion'}
                      className={`location-type__${
                        isExclusion ? 'exclusion' : 'inclusion'
                      }`}
                    >
                      <Icon name={isExclusion ? 'crossmark' : 'checkmark'} />
                    </span>
                    <label htmlFor={`location-${location.id}`}>
                      {location.name}
                    </label>
                    <a onClick={handleMapLaunch} className="location-view">
                      <Icon name="marker" size="12" />
                    </a>
                  </li>
                );
              })}
            </ul>
          </>
        )}
        <div className="locations-drawer__actions">
          {!isEmptyAllLocations && (
            <Button handleButtonClick={handleAssign} primary>
              Save
            </Button>
          )}
          <Button handleButtonClick={closeDrawer} primary transparent>
            {isEmptyAllLocations ? 'Close' : 'Cancel'}
          </Button>
        </div>
      </div>
    </div>
  );
}

LocationsDrawer.propTypes = {
  drawer: PropTypes.shape({
    drawerProps: PropTypes.shape({
      clientId: PropTypes.number.isRequired,
      viewLocationOnMap: PropTypes.func.isRequired
    }),
    drawerOpen: PropTypes.bool.isRequired
  }).isRequired,
  handleLightbox: PropTypes.func.isRequired,
  facilityLocations: PropTypes.array.isRequired,
  clientLocations: PropTypes.array.isRequired,

  // Connected props
  closeDrawer: PropTypes.func.isRequired,
  removeLocation: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    drawer,
    drawer: {
      drawerProps: { clientId }
    }
  } = state;
  return {
    drawer,
    // todo: filter to include only facility and same client locations
    facilityLocations: getLocationsList(state).filter(
      location =>
        'facility' === location.scope && parseInt(location.exclusion, 10) !== 2
    ),
    clientLocations: getClientLocations(state, clientId)
  };
};

const mapDispatchToProps = {
  closeDrawer,
  removeLocation,
  getLocations,
  assignLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsDrawer);
