/**
 * External dependencies
 */
import Cookies from 'js-cookie';
import queryString from 'query-string';

export const getIsAuthenticated = () => getToken('expires_in') < new Date().getTime();

export const getIsLoggedIn = () => !!getToken('id_token');

export const logout = () => {
  Cookies.remove('id_token');
  Cookies.remove('expires_in');
  // turn off intercom convos
  if (ENVIRONMENT === 'production') {
    window.Intercom('shutdown');
  }

  if (localStorage.getItem('onesignal_browser_active') == 'true') {
    // remove our users tag
    /* eslint-disable no-undef */
    OneSignal.push(function() {
      OneSignal.deleteTag('userId'); /* eslint-disable no-undef */
    });
  }

  window.location.replace(
    `https://call2test.auth0.com/v2/logout?returnTo=${window.location.protocol}//${window.location.host}/login`
  );
};

export const checkQueryString = hash => {
  let { id_token, expires_in } = queryString.parse(hash);

  if (!id_token && !expires_in) {
    const result = queryString.parse(window.location.search);
    id_token = result.id_token;
    expires_in = result.expires_in;
  }
  if (id_token && expires_in) {
    setToken('id_token', id_token);
    setToken('expires_in', expires_in);
    const redirectUrl = getToken('redirectUrl');
    if (redirectUrl) {
      deleteToken('redirectUrl');
      window.location.replace(redirectUrl);
    }
    return true;
  }
  return false;
};

export const getToken = key => Cookies.get(key);

export const setToken = (key, token) => Cookies.set(key, token);

export const deleteToken = key => Cookies.remove(key);
