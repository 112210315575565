import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import ActionButtons from 'components/Curriculum/ActionButtons';
import Button from 'components/Button';
import 'components/CardBlock/style.scss';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 9999
  }
}));

const CurriculumDrawerForm = ({
  isDrawerOpen,
  header,
  showHeaderButton,
  headerButtonTitle,
  headerButtonOnClick,
  handleSubmit,
  isSubmitDisabled,
  isSubmitting,
  children
}) => {
  const classes = useStyles();

  return (
    <>
      <div className="vc-container">
        <div className="vc-container__scroll-wrapper">
          <div
            className={classnames('vc-container__header', {
              'drawer-open': isDrawerOpen
            })}
          >
            <h3 className="vc-container__header--heading">{header}</h3>
            {showHeaderButton ? (
              <Button secondary micro handleButtonClick={headerButtonOnClick}>
                {headerButtonTitle}
              </Button>
            ) : null}
          </div>
          <div className="curriculum-content">
            <form className="curriculum-content__element" id="curriculum-drawer-form" onSubmit={handleSubmit}>
              {children}
              <Backdrop className={classes.backdrop} open={isSubmitting}>
                <CircularProgress />
              </Backdrop>
            </form>
          </div>
        </div>
      </div>
      <ActionButtons
        handleSubmit={handleSubmit}
        drawerOpen={isDrawerOpen}
        submitDisabled={isSubmitDisabled}
        formId="curriculum-drawer-form"
      />
    </>
  );
};

export default CurriculumDrawerForm;
