import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/Icon';

import './style.scss';

/**
 * @param {string} classes, a string of additional classes to add
 *  - currently, there's one class available in `c2t.scss`, `error-confirm__max-width`, which limits the width of the component to `650px`
 * @param {function} handleClose, an optional callback to handle closing the component
 * @param {string} messages, a newline-separated string of messages
 * @param {object} styles, an optional styles object (soon to be deprecated)
 * @param {string} type, a type (`error`, `confirmation`, and others as they arise from Design) that drives the styling of the box
 * @param {object} children, a React object consisting of optional nested elements
 *  -  only `messages` or `children` are allowed, not both
 */

const ErrorOrConfirm = ({
  classes,
  handleClose,
  messages = false,
  styles = {},
  type,
  children = false
}) => {
  const name = type === 'error' ? 'warning' : 'checkmark';

  return (
    (messages || !!children) && (
      <div
        style={styles}
        className={classnames(`error-confirm ${classes ? classes : ''}`, {
          'error-confirm__confirm': type !== 'error',
          'error-confirm__error': type === 'error'
        })}
      >
        {messages.length ? (
          messages
            .split('\n')
            .filter(error => error.length > 0)
            .map((error, i) => (
              <div key={`${i}-error}`} className="error-confirm__block">
                <Icon name={name} size={14} />
                <p className="error-confirm__block--text">{error}</p>
                {!!handleClose && (
                  <Icon name="close" size={12} onClick={() => handleClose()} />
                )}
              </div>
            ))
        ) : (
          <div className="error-confirm__block">
            <Icon name={name} size={14} />
            <p className="error-confirm__block--text">{children}</p>
            {!!handleClose && (
              <Icon name="close" size={12} onClick={() => handleClose()} />
            )}
          </div>
        )}
      </div>
    )
  );
};

ErrorOrConfirm.propTypes = {
  classes: PropTypes.string,
  messages: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  styles: PropTypes.object,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default ErrorOrConfirm;
