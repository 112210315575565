/**
 * Internal dependencies
 */
import { loadPending, loadSuccessful } from './helperActions';
import {
  catchErrors,
  requestAddBreathalyzerEvent,
  requestUpdateBreathalyzerEvent,
  requestGetBreathalyzerEvent,
  requestDeleteBreathalyzerEvent
} from 'utils/apiHelpers';

export function addBreathalyzerEvent(data, callback = false) {
  return dispatch => {
    dispatch(loadPending('ADD_BREATHALIZER_EVENT'));

    requestAddBreathalyzerEvent(data)
      .then(res => {
        dispatch(
          loadSuccessful('ADD_BREATHALIZER_EVENT', {
            breathalyzerEvent: {
              ...res.data
            }
          })
        );
        if (callback !== false) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function updateBreathalyzerEvent(id, data, callback = false) {
  return dispatch => {
    dispatch(loadPending('UPDATE_BREATHALIZER_EVENT'));

    requestUpdateBreathalyzerEvent(id, data)
      .then(res => {
        dispatch(
          loadSuccessful('UPDATE_BREATHALIZER_EVENT', {
            breathalyzerEvent: {
              ...res.data
            }
          })
        );
        if (callback !== false) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getBreathalyzerEvent(id, callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_BREATHALIZER_EVENT'));

    requestGetBreathalyzerEvent(id)
      .then(res => {
        dispatch(loadSuccessful('GET_BREATHALIZER_EVENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function deleteBreathalyzerEvent(id, callback = false) {
  return dispatch => {
    dispatch(loadPending('DELETE_BREATHALIZER_EVENT'));

    requestDeleteBreathalyzerEvent(id)
      .then(res => {
        dispatch(loadSuccessful('DELETE_BREATHALIZER_EVENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}
