/**
 * External dependencies
 */
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import Auth0Lock from '@httptoolkit/auth0-lock';

/**
 * Internal dependencies
 */
import { AUTH0_CLIENT_ID, AUTH0_URL, AUTH0_OPTIONS } from 'utils/Constants';
import App from 'components/App';
import Login from 'components/Auth/Login';
import AuthProvider, { AuthContext } from 'components/Context/AuthContext';
import { getIsLoggedIn, getIsAuthenticated, logout, setToken, checkQueryString } from './helpers';

const auth = new Auth0Lock(AUTH0_CLIENT_ID, AUTH0_URL, AUTH0_OPTIONS);

const AuthLock = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => doAuth(), []);

  const doAuth = useCallback(() => {
    setIsLoggedIn(getIsLoggedIn() || checkQueryString(history?.location?.hash));
    setIsReady(true);
  }, [isLoggedIn, checkQueryString, history?.location?.hash]);

  const login = useCallback(() => {
    // not all urls are allowed as callbacks for auth0 (no wildcarding), so we send them to login which is a valid callback url
    if (!['/login', '/dashboard'].includes(location.pathname)) {
      // if we have url to redirect to, set it here
      if (location.pathname !== '/') {
        setToken('redirectUrl', window.location.href);
      }
      window.location.replace(`${window.location.protocol}//${window.location.host}/login`);
    }
    return auth.show();
  }, []);

  return (
    !!isReady && (
      <AuthProvider
        value={{
          isAuthenticated: getIsAuthenticated,
          login: login,
          logout: logout,
          loggedIn: isLoggedIn
        }}
      >
        <AuthContext.Consumer>
          {value =>
            isLoggedIn ? <App logout={value.logout} /> : <Login loggedIn={value.loggedIn} login={value.login} />
          }
        </AuthContext.Consumer>
      </AuthProvider>
    )
  );
};

export default hot(AuthLock);
