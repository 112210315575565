import React from 'react';

import Input from 'components/Input';
import CardHeader from 'components/CardHeader';
import NoteHeading from 'components/Clients/ClientNotes/NoteHeading';

const HistoryCard = ({ note, classes }) => {
  return (
    <div className={classes.card}>
      <CardHeader title={<NoteHeading author={note.updated_by} date={note.updated} classes={classes} />} />
      <Input value={note.note} Component="textarea" disabled className={classes.input} />
    </div>
  );
};

export default HistoryCard;
