/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import './style.scss';

const Footer = ({ year }) => (
  <footer className="footer">
    <p>
      ©{year} Reconnect<span className="footer__sep">&bull;</span>
      <a
        href="https://status.reconnect.io/"
        target="_blank"
        rel="noopener noreferrer"
      >
        System Status
      </a>
      <span className="footer__sep">&bull;</span>
      <a
        href="https://reconnect.io/terms-of-use/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Use
      </a>
      <span className="footer__sep">&bull;</span>
      <a
        href="https://reconnect.io/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
    </p>
  </footer>
);

export default Footer;
