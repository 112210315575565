import React, { useContext, useMemo } from 'react';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';
import SideNavSub from './SideNavSub';

const SideNavSettings = () => {
  const { hasAccessTo } = useContext(AppContext);

  const accessChecks = useMemo(
    () => ({
      settings: hasAccessTo('settings'),
      dailyDrug: hasAccessTo('daily_drug_check_ins'),
      schedule: hasAccessTo('schedule_management'),
      location: hasAccessTo('constant_location_tracking'),
      selfPay: hasAccessTo('self_pay'),
      maxTests: hasAccessTo('rand_max_tests_per_day'),
      customYes: hasAccessTo('custom_yes_message'),
      customNo: hasAccessTo('custom_no_message'),
      customLate: hasAccessTo('custom_late_message'),
      randomDays: hasAccessTo('rand_reduced_days'),
      disableCheckIn: hasAccessTo('disable_check_in_requirement')
    }),
    [hasAccessTo]
  );

  const links = useMemo(() => {
    const canViewOther =
      [
        accessChecks.maxTests,
        accessChecks.customYes,
        accessChecks.customNo,
        accessChecks.customLate,
        accessChecks.randomDays
      ].some(Boolean) && accessChecks.dailyDrug;

    const canViewLocations = [accessChecks.settings, accessChecks.schedule, accessChecks.location].every(Boolean);

    return [
      {
        to: '/settings',
        label: 'Email Notifications',
        access: accessChecks.settings
      },
      {
        to: '/settings/check_in_windows',
        label: 'Check-In Windows',
        access: accessChecks.settings && accessChecks.dailyDrug && !accessChecks.disableCheckIn
      },
      {
        to: '/settings/client_groups',
        label: 'Client Groups',
        access: accessChecks.settings
      },
      {
        to: '/settings/test_types',
        label: 'Test Types',
        access: accessChecks.settings && accessChecks.dailyDrug
      },
      {
        to: '/settings/testing_profiles/:id?',
        label: 'Testing Profiles',
        access: accessChecks.settings && accessChecks.dailyDrug
      },
      {
        to: '/settings/locations',
        label: 'Facility Locations',
        access: canViewLocations
      },
      {
        to: '/settings/forms',
        label: 'Forms',
        access: accessChecks.settings
      },
      {
        to: '/settings/payment-programs',
        label: 'Payments',
        access: accessChecks.settings && accessChecks.selfPay
      },
      {
        to: '/settings/other',
        label: 'Other',
        access: canViewOther
      }
    ];
  }, [accessChecks]);

  if (accessChecks.settings) {
    return (
      <NavLink
        to="/settings"
        data-testid="settings"
        title="Settings"
        nestedMenu={<SideNavSub title="Settings" links={links} />}
      >
        <Icon name="settings" size={24} />
        <span className="sidenav__item-label">Settings</span>
      </NavLink>
    );
  }

  return null;
};

export default SideNavSettings;
