import { FieldBuilder } from 'components/Form/Model/FieldBuilder';
import NumberConfig from 'components/Form/Model/NumberConfig';

export const SUBSTANCES_USED = [
  'Unknown',
  'Alcohol',
  'Ayahuasca',
  'Cannabis',
  'Central Nervous System Depressants (Benzos)',
  'Cocaine',
  'GHB',
  'Hallucinogens',
  'Heroin',
  'Inhalants',
  'Ketamine',
  'Khat',
  'Kraton',
  'LSD (Acid)',
  'MDMA (Ecstacy)',
  'Mescaline (Peyote)',
  'Methamphetamine',
  'Over-the-Counter Medicines- Dextromethorphan (DXM)',
  'Over-the-Counter Medicines-Loperamide',
  'PCP',
  'Prescription Opioids',
  'Prescription Stimulants',
  'Psilocybin',
  'Rohypnol',
  'Salvia',
  'Steroids (Anabolic)',
  'Synthetic Cannabinoids',
  'Synthetic Cathinones',
  'Synthetic Opiates',
  'Tobacco/Nicotine',
  'Vaping',
  'Opiates'
];

export class SubstanceUseForm {
  blockGroup = 'substanceUseFields';
  constructor() {
    const builder = new FieldBuilder();

    builder.subKey('additional');
    this.generalSubstanceCategories = builder.multiSelect(
      'general_substance_categories',
      'General Substance categories',
      builder.options(
        'Unknown',
        'Depressants',
        'Stimulants',
        'Hallucinogens',
        'Dissociatives',
        'Narcotic Analgesics',
        'Inhalants',
        'Cannabinoids',
        'Opiates'
      )
    );

    this.substancesUsed = builder.multiSelect(
      'substances_used',
      'Substances Used',
      builder.options(...SUBSTANCES_USED)
    );

    this.fentanyl = builder.radioGroup('fentanyl', 'Fentanyl', builder.yesNoOptions());
    this.xylazine = builder.radioGroup('xylazine', 'Xylazine', builder.yesNoOptions());

    builder.subKey('json_data');

    this.drugAlcoholUse = builder.radioGroup('drug_alcohol_use', 'Drug/Alcohol Use', builder.yesNoOptions());
    this.drugChoice = builder.list('drug-choice', 'Drug of Choice', b => {
      b.number('rank', 'Rank', new NumberConfig(1, 100, null));
      b.text('substance', 'Substance');
      b.number('age', 'Age of First Use', 1, 100);
      b.date('last_date_of_use', 'Last Known Date Of Use');
      b.select(
        'route',
        'Route of Use',
        b.options(
          'Oral',
          'Smoking',
          'Snorting',
          'Shooting (Injecting)',
          'Skin Popping',
          'Plugging/Suppositories',
          'Huffing And Transdermal',
          'Other'
        )
      );
    });

    this.knownHistoryOfOverdose = builder.radioGroup(
      'known_history_of_overdose',
      'Known History of Overdose',
      builder.yesNoOptions()
    );

    this.knownOccurrencesOfOverdose = builder.textarea(
      'known_occurrences_of_overdose',
      'Known Occurrences of Overdose'
    );

    this.substancesAssociatedWithArrest = builder.multiSelect(
      'substances_associated_with_arrest',
      'Substances Associated with Arrest',
      builder.options(...SUBSTANCES_USED)
    );

    builder.subKey('additional');
    this.ageAlcohol = builder.select('first_use_alcohol', 'Alcohol', builder.optionRange(1, 100));
    this.ageMarijuana = builder.select('first_use_marijuana', 'Marijuana', builder.optionRange(1, 100));
    this.useOther = builder.input('first_use_other', 'Other');
    this.ageOther = builder.input('first_use_other_age', 'Age', builder.optionRange(1, 100));
    this.substanceNotes = builder.textarea('substance_notes', 'Substance Use Notes');

    this.wantsSubstanceUseTreatment = builder.select(
      'wants_substance_use_treatment',
      'Wants Substance Use Treatment',
      builder.options('Yes', 'No', 'Unknown')
    );
    this.readyStartToday = builder.radioGroup('ready_start_today', 'Ready to Start Today?', builder.yesNoOptions());
    this.additionalCoordinationAndFollowUpNeeded = builder.radioGroup(
      'additional_coordination_and_follow_up_needed',
      'Additional Coordination and Follow-Up Needed',
      builder.yesNoOptions()
    );
    this.todaysDate = builder.date('todaysDate', 'Today’s Date');

    this.children = builder.fields;
  }
}
