import React, { useState, useEffect, useCallback, useMemo } from 'react';
import zxcvbn from 'zxcvbn';

import { PasswordHints } from './PasswordHints';
import { getScore } from './userGet-helpers';

const Filler = ({ strengthText, width, password }) => {
  const shownWidth = width * 25;
  return (
    <div
      className={`password-strength-meter-progress strength-${strengthText}`}
      style={{ width: password !== '' ? `${shownWidth}%` : 0 }}
    />
  );
};

const PasswordMeter = ({ password, onPasswordChangeCallback }) => {
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [hasTestedPW, setHasTestedPW] = useState(false);

  useEffect(() => {
    checkPassword();
  }, [password]);

  const checkPassword = useCallback(() => {
    const testPassword = zxcvbn(password);
    const { score, feedback } = testPassword;
    setScore(score);
    setFeedback(feedback);
    setHasTestedPW(true);
    onPasswordChangeCallback(score);
  }, [password, onPasswordChangeCallback]);

  const strengthText = useMemo(() => getScore(score), [score]);

  return (
    <div className="pw-bottom-container">
      <div className="row" style={{ marginBottom: '1rem' }}>
        <div className="password-strength-meter">
          <div className="progress-bar" id="password-strength-readout">
            <Filler
              strengthText={strengthText}
              width={score}
              password={password}
            />
          </div>
          <label
            className="password-strength-meter-label"
            htmlFor="password-strength-readout"
            style={{ pointerEvents: 'none' }}
          >
            <span>
              Password Strength:&nbsp;
              {password !== '' && <span>{strengthText}</span>}
            </span>
          </label>
        </div>
      </div>
      <div className="row">
        <PasswordHints
          hasTestedPw={hasTestedPW}
          password={password}
          score={score}
          feedback={feedback}
        />
      </div>
    </div>
  );
};

export default PasswordMeter;
