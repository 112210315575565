import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';
import { useCloseDrawer } from './Curriculum/hooks';

const ClientNameLink = ({ client_id, clientName, to = 'info' }) => {
  const handleCloseDrawer = useCloseDrawer();

  return (
    <Link
      className="profile-link"
      to={`/clients/get/${client_id}/${to}`}
      onClick={handleCloseDrawer}
    >
      {clientName}
      <Icon name="profile-dark" className="profile-dark" />
    </Link>
  );
}

export default ClientNameLink;