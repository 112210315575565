import { useState, useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isObject, startCase } from 'lodash';
import { useHistory } from 'react-router-dom';

import { openDrawer } from 'actions/drawerActions';
import { useCurriculumError } from 'components/Curriculum/hooks';
import curriculumUtil from 'utils/CurriculumUtil';
import { getItemsByKey } from 'selectors/curriculumSelectors';
import {
  deleteCurriculumItem,
  getCurriculumItems,
  getCurriculumItem,
  editUserSettings
} from 'actions/curriculumActions';
import { useToast } from 'components/Toast';
import useToggle from './useToggle';

const useCurriculumListProps = ({ drawerType, selectorKey, fetchParam = null }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [shownItems, setShownItems] = useState([]);
  const dispatch = useDispatch();
  const items = useSelector(getItemsByKey(selectorKey), shallowEqual);
  const toast = useToast();
  const history = useHistory();
  const [isShowingHidden, handleToggleHidden] = useToggle(false);

  useCurriculumError();

  useEffect(() => {
    dispatch(getCurriculumItems(curriculumUtil.SELECTOR_KEYS.ASSIGNMENTS))
      .then(() => dispatch(getCurriculumItems(selectorKey, fetchParam)))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    curriculumUtil.isSupportedDrawerType(drawerType);
  }, [drawerType]);

  useEffect(() => {
    if (Array.isArray(items)) {
      const shown = [];

      for (const item of items) {
        if (!item.hidden) {
          shown.push(item);
        }
      }

      setShownItems(shown);
    }
  }, [items]);

  const handleGetItem = useCallback((id, propsForDrawer = {}) => () => {
    if (id) {
      dispatch(getCurriculumItem(selectorKey, [id, fetchParam]))
        .then(result => {
          if (!result?.error) {
            dispatch(openDrawer(drawerType, propsForDrawer));
          }
        });
    } else {
      dispatch(openDrawer(drawerType, propsForDrawer));
    }
  }, [dispatch, selectorKey, history, fetchParam]);

  const handleCreateNew = useCallback(() => {
    switch (selectorKey) {
      case curriculumUtil.SELECTOR_KEYS.ASSIGNMENTS:
        return history.push('/learning-lab/assignment/new');
      default:
        return undefined;
    }
  }, [history, selectorKey]);

  const handleDeleteIntermediary = useCallback(
    id => () => {
      setIsModalOpen(true);
      setIdToDelete(id);
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setIdToDelete(null);
  }, []);

  const postDeleteCallback = useCallback(() => {
    toast.addToast({
      position: 'top',
      duration: 3000,
      type: 'checkmark',
      content: `${startCase(selectorKey.slice(0, -1))} successfully deleted`
    });
  }, [toast, selectorKey]);

  const handleDelete = useCallback(() => {
    setIsModalOpen(false);
    dispatch(deleteCurriculumItem(selectorKey, [idToDelete, fetchParam]))
      .then(result => {
        if (!result?.error) {
          postDeleteCallback();
          setIdToDelete(null);
        }
      });
  }, [selectorKey, idToDelete, fetchParam, postDeleteCallback]);

  const handleEditUserSettings = useCallback(data => () => {
    dispatch(editUserSettings(selectorKey, data));
  }, [dispatch, selectorKey]);

  const tableItems = useMemo(() => {
    const actualItems = isShowingHidden ? items : shownItems;

    switch (selectorKey) {
      case curriculumUtil.SELECTOR_KEYS.PACKETS:
        return actualItems.map(p => ({
          ...p,
          curriculum_resource: [...new Set(p.curriculum_resource.map(r => r.title).sort())],
          curriculum_topic: [...new Set(p.curriculum_resource.map(r => r.curriculum_topic.map(t => t.title)).flat().sort())],
        }));
      case curriculumUtil.SELECTOR_KEYS.ASSIGNMENTS:
        return actualItems
          .filter(a => isObject(a.client_segments))
          .map(a => ({
            ...a,
            title: a?.curriculum_packet?.title ?? '',
            assignees: [],
            topics: curriculumUtil.getTopicsFromResources(a?.curriculum_packet?.curriculum_resource ?? [])
          }));
      case curriculumUtil.SELECTOR_KEYS.RESOURCES:
        return actualItems.map(r => ({
          ...r,
          curriculum_topic: r.curriculum_topic.map(t => t.title)
        }));
      default:
        return actualItems;
    }
  }, [items, shownItems, isShowingHidden, selectorKey]);

  const handleAddToFacility = useCallback((type, id) => () => {
    if (type && id) {
      const params = new URLSearchParams({ type, id });
      history.replace({ pathname: history.location.pathname, search: params.toString() });
    }
  }, [history]);

  return {
    isModalOpen,
    handleGetItem,
    handleDeleteIntermediary,
    handleCloseModal,
    handleDelete,
    handleCreateNew,
    handleEditUserSettings,
    handleToggleHidden,
    isShowingHidden,
    [selectorKey]: tableItems,
    handleAddToFacility,
    isLoading
  };
};

export default useCurriculumListProps;
