
export default function documentLibrary(state = {}, action) {
  switch (action.type) {
    case 'LOAD_SUCCESSFUL_DOCUMENT_LIBRARY':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        documents: action.payload.documents
      };
    case 'LOAD_SUCCESSFUL_DOCUMENT':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        documents: action.payload.documents
      };
    case 'LOAD_SUCCESSFUL_UPDATE_DOCUMENT':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        documents: action.payload.documents
      };
    case 'LOAD_SUCCESSFUL_CLIENT_DOCUMENTS':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        documents: action.payload.documents
      };
    case 'LOAD_SUCCESSFUL_ASSIGN_DOCUMENT':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        documents: action.payload.documents
      };
  }
  return state;
}
