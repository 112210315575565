import React, { useContext } from 'react';
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import ThumbDownAltRoundedIcon from '@material-ui/icons/ThumbDownAltRounded';

import IconButton from 'components/IconButton';
import { BaselineContext } from 'components/Clients/BaselineImages/BaselineContext';

const ActionButtons = () => {
  const { updateCollection, toggleModal, isApprovedDisabled } = useContext(BaselineContext);

  return (
    <>
      <IconButton
        onClick={updateCollection(true)}
        label="Approve All"
        Icon={ThumbUpAltRoundedIcon}
        iconButtonProps={{ disabled: isApprovedDisabled }}
        tooltip={
          isApprovedDisabled
            ? 'Inactive baseline image collections, or ones missing at least one required image type, cannot be approved'
            : ''
        }
      />
      <IconButton
        onClick={toggleModal}
        Icon={ThumbDownAltRoundedIcon}
        label="Disapprove All"
        styleProps={{ backgroundColor: '#00819B' }}
      />
    </>
  );
};

export default ActionButtons;
