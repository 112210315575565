/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { getViolationsPolling } from 'actions/actionCreators';
import Badge from 'components/Badge';

class ViolationsBadge extends React.Component {
  static propTypes = {
    className: PropTypes.string
  };

  state = {
    count: 0,
    interval: false,
    isLoading: false
  };

  componentDidMount() {
    this.pollForViolations();
    this.setupPolling();
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  pollForViolations = () => {
    const { violations, getViolationsPolling } = this.props;
    getViolationsPolling(violations, () => {
      this.saveViolationsToState();
    });
  };

  setupPolling = () => {
    const { count } = this.state;
    if (count < 1000) {
      const interval = setInterval(this.pollForViolations, 30000);
      this.setState({ interval });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.violations.count !== this.props.violations.count &&
      this.props.violations.count !== prevState.count
    ) {
      if (this.state.interval) {
        clearInterval(this.state.interval);
      }
      this.setState(
        {
          count: this.props.violations.count
        },
        this.setupPolling
      );
    }
  }

  saveViolationsToState = () => {
    const {
      violations: { count }
    } = this.props;
    this.setState({
      count: count,
      isLoading: false
    });
  };

  render() {
    const { count = 0 } = this.state;
    const { className, optionClass } = this.props;
    return (
      <Badge
        className={classnames(className, {
          'violation-dropdown': optionClass
        })}
        number={count}
      />
    );
  }
}

const mapStateToProps = state => ({ violations: state.violations });

const mapDispatchToProps = {
  getViolationsPolling
};

export default connect(mapStateToProps, mapDispatchToProps)(ViolationsBadge);
