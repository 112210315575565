import React, { useContext } from 'react';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';
import SideNavSub from './SideNavSub';

const SideNavDocuments = () => {
  const { hasAccessTo } = useContext(AppContext);

  /**
   * Placeholder for when the time comes, for now always
   * disabled ... unless `true` ever equals `false` (or
   * we turn the feature on)
   */
  if (true === false && hasAccessTo('document_library')) {
    return (
      <NavLink
        to="/documents"
        liClassName="beta"
        title="Document Library"
        nestedMenu={
          <SideNavSub
            title="Document Library"
            links={[
              { to: '/documents', label: 'Documents' },
              { to: '/documents/assign', label: 'Assignments' }
            ]}
          />
        }
      >
        <Icon name="document" size={24} />
        <span className="sidenav__item-label">Document Library</span>
      </NavLink>
    );
  }

  return null;
};

export default SideNavDocuments;
