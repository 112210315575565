import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Label from 'components/Label';
import Icon from 'components/Icon';
import './style.scss';

const Checkbox = ({
  name,
  id = '',
  checked = false,
  label,
  error = false,
  disabled = false,
  handleChange,
  tooltip = '',
  // eslint-disable-next-line no-unused-vars
  blockGroup = false,
  // eslint-disable-next-line no-unused-vars
  renderChecks,
  callback = false,
  subhead = false,
  ...props
}) => {
  return (
    <span
      className={classnames('checkbox-container', {
        disabled: disabled
      })}
    >
      {!!error && <Label alert>{error}</Label>}
      <input
        {...props}
        onChange={e => !disabled && handleChange(e, callback)}
        id={id}
        type="checkbox"
        className="base-checkbox"
        name={name}
        checked={!!checked}
        disabled={disabled}
        role="checkbox"
        aria-checked={!!checked}
      />
      <Label
        className="checkbox-label"
        htmlFor={id}
        tooltip={tooltip}
        disabled={disabled}
      >
        <Icon name="check" className="check" />
        <span className="checkbox-label__text">{label}</span>
      </Label>
      {subhead ? <span className="checkbox__subhead">{subhead}</span> : null}
    </span>
  );
};

Checkbox.propTypes = {
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  group: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  blockGroup: PropTypes.string,
  handleChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
};

Checkbox.defaultProps = {
  name: '',
  label: ''
};

export default Checkbox;
