import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import Label from 'components/Label';
import Select from 'react-select';
import Input from 'components/Input';
import Icon from 'components/Icon';
import ModernizedDatePicker from 'components/ModernizedDatePicker';
import Button from 'components/Button';
import RadioButton from 'components/RadioButton';
import { createOptionsFromStringList } from '../ClientsGet/Info/BasicInfo/Schemas/helpers';
import './style.scss';

const EMPTY_PHASE = {
  phase: 1,
  start: '',
  end: '',
  end_type: '',
  t4c: undefined,
  cognitive_intervention: [],
  mrt: undefined,
  treatment_days: '',
  iop: ''
};

const tinyIntToBoolean = value => {
  if (value === '0') {
    return false;
  }
  if (value === '1') {
    return true;
  }
  return value;
};

const COGNITIVE_INTERVENTIONS = createOptionsFromStringList(
  'EPICS-II Skills, Carey & BITS, EPICS-II Cog Model, EPICS-II BA, Other'
);
const END_TYPE = createOptionsFromStringList('Graduated, Did Not Graduate');

const PHASE_OPTIONS = [1, 2, 3, 4, 5].map(n => ({ label: n, value: n }));

const ProgramPhases = ({ handleChange, name, value, ...props }) => {
  if (!value) {
    value = [cloneDeep(EMPTY_PHASE)];
  } else {
    value = value.map(v => {
      if (!v.cognitive_intervention) {
        v.cognitive_intervention = [];
      } else if (typeof v.cognitive_intervention === 'string') {
        v.cognitive_intervention = v.cognitive_intervention.split(';;;');
      }
      v.t4c = tinyIntToBoolean(v.t4c);
      v.mrt = tinyIntToBoolean(v.mrt);
      return v;
    });
  }

  const removePhase = index => {
    const newValue = cloneDeep(value).filter((_, i) => i !== index);
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleSelectChange = (key, optionValue, index) => {
    const newObj = value[index];
    newObj[key] = optionValue;
    const newValue = cloneDeep(value).map((p, i) => (i === index ? newObj : p));
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleDateChange = (date, index, key) => {
    const newObj = value[index];
    newObj[key] = date;
    const newValue = cloneDeep(value).map((p, i) => (i === index ? newObj : p));
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleButtonClick = () => {
    const newValue = cloneDeep(value);
    newValue.push(cloneDeep(EMPTY_PHASE));
    const e = { target: { name, value: newValue } };
    handleChange(e, props.blockGroup);
  };

  const handleInputChange = (key, event, index) => {
    const newObj = value[index];
    newObj[key] = event.target.value;
    const newValue = cloneDeep(value).map((p, i) => (i === index ? newObj : p));
    handleChange({ target: { name, value: newValue } }, props.blockGroup);
  };
  const handleRadioChange = (newState, key, index) => {
    const newObj = value[index];
    newObj[key] = newState;
    const newValue = cloneDeep(value).map((p, i) => (i === index ? newObj : p));
    handleChange({ target: { name, value: newValue } }, props.blockGroup);
  };

  return (
    <div className="phases-multi-input-container">
      {value.map((v, index) => {
        return (
          <div key={index} className="phases-single column">
            <div className="row">
              <div className="phases-single__input-with-icon">
                <Label htmlFor={`${name}_phase_${index}`} bold>
                  Phase
                </Label>
                <Select
                  name={`${name}_phase_${index}`}
                  className="selector"
                  value={PHASE_OPTIONS.filter(o => o.value === parseInt(v.phase))}
                  onChange={({ value }) => handleSelectChange('phase', value, index)}
                  options={PHASE_OPTIONS}
                />
              </div>
              <div>
                <Label htmlFor={`${name}_start_${index}`} bold>
                  Start Date
                </Label>
                <ModernizedDatePicker
                  id={`${name}_start_${index}`}
                  name={`${name}_start_${index}`}
                  dateFormat="M/dd/yyyy"
                  selected={v.start}
                  onChange={date => handleDateChange(date, index, 'start')}
                />
              </div>
              <div>
                <Label htmlFor={`${name}_end_${index}`} bold>
                  End Date
                </Label>
                <ModernizedDatePicker
                  id={`${name}_end_${index}`}
                  name={`${name}_end_${index}`}
                  dateFormat="M/dd/yyyy"
                  selected={v.end}
                  onChange={date => handleDateChange(date, index, 'end')}
                />
              </div>
              <div className="remove-box">
                <span onClick={() => removePhase(index)}>
                  <Icon name="minus" />
                </span>
              </div>
            </div>
            <div className="row">
              <div className="column">
                <Label htmlFor={`iop_${index}`} bold>
                  Total IOP / Outpatient Individual Treatment Hours
                </Label>
                <Input
                  type="number"
                  name={`${name}_iop_${index}`}
                  value={v.iop}
                  handleChange={e => handleInputChange('iop', e, index)}
                />
              </div>
              <div className="column">
                <Label htmlFor={`treatment_days_${index}`} bold>
                  Residential Treatment Days
                </Label>
                <Input
                  type="number"
                  name={`${name}_treatment_days_${index}`}
                  value={v.treatment_days}
                  handleChange={e => handleInputChange('treatment_days', e, index)}
                />
              </div>
            </div>
            <div className="row">
              <div className="column">
                <Label bold>MRT</Label>
                <div className="row">
                  <RadioButton
                    label="Yes"
                    checked={v.mrt}
                    name="mrt"
                    value={true}
                    handleChange={(newState, key) => handleRadioChange(newState, key, index)}
                  />
                  <RadioButton
                    label="No"
                    checked={v.mrt === false}
                    name="mrt"
                    value={false}
                    handleChange={(newState, key) => handleRadioChange(newState, key, index)}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor={`cognitive_interventions_${index}`} bold>
                  Cognitive Interventions
                </Label>
                <Select
                  name={`${name}_cognitive_interventions_${index}`}
                  className="selector"
                  isMulti
                  value={COGNITIVE_INTERVENTIONS.filter(
                    o => v.cognitive_intervention && v.cognitive_intervention.indexOf(o.value) >= 0
                  )}
                  onChange={value =>
                    handleSelectChange(
                      'cognitive_intervention',
                      (value || []).map(ci => ci.value),
                      index
                    )
                  }
                  options={COGNITIVE_INTERVENTIONS}
                />
              </div>
            </div>
            <div className="row">
              <div className="column">
                <Label bold>Thinking for a Change (T4C)</Label>
                <div className="row">
                  <RadioButton
                    label="Yes"
                    checked={v.t4c}
                    name="t4c"
                    value={true}
                    handleChange={(newState, key) => handleRadioChange(newState, key, index)}
                  />
                  <RadioButton
                    label="No"
                    checked={v.t4c === false}
                    name="t4c"
                    value={false}
                    handleChange={(newState, key) => handleRadioChange(newState, key, index)}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor={`end_type_${index}`} bold>
                  End Type
                </Label>
                <Select
                  name={`end_type_${index}`}
                  className="selector"
                  value={END_TYPE.filter(o => o.value === v.end_type)}
                  onChange={({ value }) => handleSelectChange('end_type', value, index)}
                  options={END_TYPE}
                />
              </div>
            </div>
          </div>
        );
      })}
      <Button
        secondary
        transparent
        micro
        handleButtonClick={handleButtonClick}
        disabled={
          value.length
            ? value.some(
                v =>
                  v.start === '' ||
                  v.end === '' ||
                  v.end_type === '' ||
                  v.t4c === undefined ||
                  v.cognitive_intervention === '' ||
                  v.mrt === '' ||
                  v.treatment_days === '' ||
                  v.iop === ''
              )
            : false
        }
      >
        Add a Next Phase
      </Button>
    </div>
  );
};

ProgramPhases.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array
};

export default ProgramPhases;
