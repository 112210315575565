/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Label from 'components/Label';
import Icon from 'components/Icon';
import './style.scss';

/**
 * @component
 * @description Simple `a` tag wrapper for the `CardBlock`
 * @param {String} href
 * @param {String} name
 * @param {String} label
 * @returns {Node} `FormLink` component
 */

const FormLink = ({ href, name, label }) => (
  <div className="form-link">
    <Label bold>{label}</Label>
    {href ? (
      <a href={href} name={name} target="__blank" rel="noopener noreferrer">
        Click here to access the attachment for this response
        <Icon name="chevron-right" />
      </a>
    ) : (
      'No attachment provided for this response.'
    )}
  </div>
);

FormLink.propTypes = {
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default FormLink;
