import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Sector } from 'recharts';

export const COLORS_BY_ENTITY = {
  assignments: 'rgb(219,227,237)',
  packets: 'rgb(252,214,220)',
  resources: 'rgb(247,239,215)',
  topics: 'rgb(200,230,216)',
  other: 'rgb(245,227,214)'
};

export const useCardStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    gap: 18,
    [theme.breakpoints.up('2xl')]: {
      flexDirection: 'row'
    }
  },
  root: {
    padding: 24
  }
}));

export const useSimpleWidgetStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    width: '100%',
    [theme.breakpoints.up('2xl')]: {
      width: 'calc(50% - 18px / 2)'
    }
  },
  container: {
    width: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%'
  },
  contentRoot: {
    padding: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    width: 'unset',
    height: '100%'
  },
  header: {
    padding: 8,
    backgroundColor: '#fff',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& *': {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  content: {
    backgroundColor: ({ type }) => COLORS_BY_ENTITY?.[type] ?? '#fff',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 18,
    '& p': {
      fontSize: 14,
      fontWeight: 'bold'
    }
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'start',
    padding: 24,
    gap: 6,
    '& p': {
      fontSize: 14,
      fontWeight: 600
    }
  }
}));

export const useChartCardStyles = makeStyles(() => ({
  root: {
    containerType: 'inline-size',
    containerName: 'generic-container',
    padding: props => props.padding || 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    zIndex: props => props.zIndex,
    '& .spinner-overlay': {
      margin: 0,
      zIndex: 100
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const RADIAN = Math.PI / 180;

export const renderActiveShape = (
  { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value },
  activeShapeText = null
) => {
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 5) * cos;
  const sy = cy + (outerRadius + 5) * sin;
  const mx = cx + (outerRadius + 16) * cos;
  const my = cy + (outerRadius + 16) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {payload.title}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {'function' === typeof activeShapeText
          ? activeShapeText(value, payload)
          : 'string' === typeof activeShapeText
          ? activeShapeText
          : ''}
      </text>
    </g>
  );
};
