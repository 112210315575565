/**
 * External dependencies
 */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
/**
 * Internal dependencies
 */
import Label from 'components/Label';
import Button from 'components/Button';
import BenchmarkRequirementField from './RequirementField';
import BenchmarkRequirementFieldV2 from './RequirementFieldV2';
import './style.scss';

const BenchmarkRequirementsGroup = ({
  /* eslint-disable no-unused-vars */
  facility,
  features,
  handleChange,
  name,
  value,
  blockGroup = false,
  error = false,
  group,
  label,
  required,
  setErrorsCallback,
  validation
}) => {
  if (!value) {
    value = [{ unit: 'instances', amount: 1, type: '' }];
  }

  const showsNewRequirements = facility.id === 295 || features?.['new_benchmark_requirements']?.status;
  // IMPORTANT: This is only for testing purposes, we will be using a feature for this but
  // we only care about Maine account for the moment. We're hard coding to webster for testing.
  // There will be a feature for this new requirement component, access it via the features
  const GroupComponent = showsNewRequirements ? BenchmarkRequirementFieldV2 : BenchmarkRequirementField;

  const [requirements, setRequirements] = useState(value);

  const handleButtonClick = () => {
    const newRequirements = cloneDeep(requirements);
    newRequirements.push({
      unit: 'instances',
      amount: 1,
      type: ''
    });
    setRequirements(newRequirements);
    validation(name, blockGroup, newRequirements, setErrorsCallback, error);
  };

  // update our requirements index based on what field gets changed
  const handleUpdateRequirement = (index, requirement) => {
    const newRequirements = cloneDeep(requirements);

    newRequirements[index] = requirement;
    setRequirements(newRequirements);

    const e = { target: { name, value: newRequirements } };
    handleChange(e, blockGroup);
    validation(name, blockGroup, newRequirements, setErrorsCallback, error);
  };

  const handleRemoveRequirement = index => {
    const newRequirements = cloneDeep(requirements);

    newRequirements.splice(index, 1);

    setRequirements(newRequirements);

    const e = { target: { name, value: newRequirements } };
    handleChange(e, blockGroup);
    validation(name, blockGroup, newRequirements, setErrorsCallback, error);
  };

  return (
    <div className="benchmark-requirements-group">
      <Label required={required} bold={true}>
        {showsNewRequirements && <span className="action-item-label">Action Items*</span>}
        {label}
      </Label>
      {/* need to render based on how many they have */}
      {requirements.map((requirement, index) => {
        return (
          <GroupComponent
            handleUpdateRequirement={handleUpdateRequirement}
            index={index}
            key={`requirement-${index}`}
            value={requirement}
            handleRemoveRequirement={handleRemoveRequirement}
          />
        );
      })}
      <Button secondary transparent micro handleButtonClick={handleButtonClick}>
        Add Another {showsNewRequirements ? 'Action Item' : 'Requirement'}
      </Button>
    </div>
  );
};

export default connect(({ facility, features }) => ({
  facility,
  features
}))(BenchmarkRequirementsGroup);
