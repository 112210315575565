import React, { useContext, useMemo } from 'react';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';

import Loading from 'components/Helpers/Loading';
import { Card, Divider } from '@material-ui/core';
import CardHeader from 'components/CardHeader';
import { useChartCardStyles } from 'components/Curriculum/Overview/helpers';
import IconButton from 'components/IconButton';
import CsvButton from 'components/CsvButton';
import NotesList from './NotesList';
import NoteField from './NoteField';
import TableBuilder, { Column } from 'utils/TableBuilder';
import { NotesContext } from './NotesContext';

export const builder = new TableBuilder().addColumns([
  new Column('Author', 'author').isTitle(),
  new Column('Client ID', 'client_id'),
  new Column('Date', 'date').isDate(),
  new Column('Note', 'note')
]);

const ChildWrapper = ({ Wrapper = Card, containerStyle = {}, tooltip = '', title = 'Client Notes' }) => {
  const { list, handleAddNote, note, isSaving, isLoading } = useContext(NotesContext);
  const classes = useChartCardStyles({ ...containerStyle });

  const wrapperProps = useMemo(() => {
    if ('string' === typeof Wrapper) {
      return { className: classes.root };
    }

    return { classes: { root: classes.root } };
  }, [Wrapper, classes]);

  return (
    <Wrapper {...wrapperProps}>
      <CardHeader title={title} titleTooltip={tooltip}>
        <CsvButton
          title="client-notes-history"
          isDownloadDisabled={!list.length}
          csvData={list}
          headers={builder.table.csvHeaders}
        />
        <IconButton
          tooltip="Save a new note to add it to the client's 'Notes' record"
          onClick={handleAddNote}
          label="Save New Note"
          Icon={SaveRoundedIcon}
          iconButtonProps={{ disabled: !note?.note || isSaving }}
        />
      </CardHeader>
      <NoteField />
      <Divider />
      {isLoading ? <Loading compact={true} /> : <NotesList />}
    </Wrapper>
  );
};

export default ChildWrapper;
