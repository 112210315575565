export default function violations(state = { list: [], count: 0 }, action) {
  switch (action.type) {
    case 'LOAD_PENDING_VIOLATIONS':
      return {
        ...state,
        pending: true
      };
    case 'REMOVE_PENDING_VIOLATIONS':
      return {
        ...state,
        pending: false
      };
    case 'LOAD_SUCCESSFUL_VIOLATIONS':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        list: action.payload.violations,
        listCount: action.payload.summary.total_violations,
        hash: action.payload.hash
      };
    case 'LOAD_PENDING_VIOLATIONS_POLLING':
      return {
        ...state,
        pending: true
      };
    case 'REMOVE_PENDING_VIOLATIONS_POLLING':
      return {
        ...state,
        pending: false
      };
    case 'LOAD_SUCCESSFUL_VIOLATIONS_POLLING':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        count: action.payload.count,
        hash: action.payload.hash
      };
  }
  return state;
}
