import { Options } from 'utils/TestingUtil';

class ReportRowBuilder {
  label = '';
  Component = null;
  key = null;
  required = true;
  options = null;
  rows = undefined;
  isInput = false;
  multiple = undefined;
  extra = {};

  constructor(label, Component, key) {
    this.label = label;
    this.Component = Component;
    this.key = key;
  }

  setRequired(required = false) {
    this.required = required;

    return this;
  }

  setOptions(options = null) {
    this.options = options;

    return this;
  }

  setIsInput() {
    this.isInput = true;
    this.rows = 2;

    return this;
  }

  setIsMultiple() {
    this.multiple = true;

    return this;
  }

  get props() {
    const props = { name: this.key, label: this.label, required: this.required };

    if (this.isInput) {
      props.rows = this.rows;
    } else {
      props.options = this.options;
      props.multiple = this.multiple;
    }

    return { ...props, ...this.extra };
  }

  setExtra(extra = {}) {
    this.extra = extra;

    return this;
  }
}

export default ReportRowBuilder;