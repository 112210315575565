/**
 * Internal dependencies
 */
import { loadPending, loadSuccessful } from './helperActions';
import {
  catchErrors,
  requestAddCurfewEvent,
  requestUpdateCurfewEvent,
  requestGetCurfewEvent,
  requestDeleteCurfewEvent
} from 'utils/apiHelpers';

export function addCurfewEvent(data, callback = false) {
  return dispatch => {
    dispatch(loadPending('ADD_CURFEW_EVENT'));

    requestAddCurfewEvent(data)
      .then(res => {
        dispatch(
          loadSuccessful('ADD_CURFEW_EVENT', {
            curfewEvent: {
              ...res.data
            }
          })
        );
        if (callback !== false) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function updateCurfewEvent(id, data, callback = false) {
  return dispatch => {
    dispatch(loadPending('UPDATE_CURFEW_EVENT'));

    requestUpdateCurfewEvent(id, data)
      .then(res => {
        dispatch(
          loadSuccessful('UPDATE_CURFEW_EVENT', {
            curfewEvent: {
              ...res.data
            }
          })
        );
        if (callback !== false) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getCurfewEvent(id, callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_CURFEW_EVENT'));

    requestGetCurfewEvent(id)
      .then(res => {
        dispatch(loadSuccessful('GET_CURFEW_EVENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function deleteCurfewEvent(id, callback = false) {
  return dispatch => {
    dispatch(loadPending('DELETE_CURFEW_EVENT'));

    requestDeleteCurfewEvent(id)
      .then(res => {
        dispatch(loadSuccessful('DELETE_CURFEW_EVENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}
