export const toggleContextualMenu = () => {
  return {
    type: 'TOGGLE_CONTEXTUAL_MENU'
  };
};

export const closeContextualMenu = () => {
  return {
    type: 'CLOSE_CONTEXTUAL_MENU'
  };
};

export const toggleMobileMenu = () => {
  return {
    type: 'TOGGLE_MOBILE_MENU'
  };
};

export const closeMobileMenu = () => {
  return {
    type: 'CLOSE_MOBILE_MENU'
  };
};
