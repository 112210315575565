import React from 'react';

import Info from 'components/Info';
import CustomSelect from 'components/CustomSelect';

import './style.scss';

export function PermBlock({ label, info, handleCustomSelectChange, blockGroup, name, value, options }) {
  return (
    <div className="perm-block">
      <div className="perm-block--left">
        <Info label={label} value={info} />
      </div>
      <div className="perm-block--right">
        <CustomSelect
          handleCustomSelectChange={handleCustomSelectChange}
          blockGroup={blockGroup}
          name={name}
          value={value === '' ? 0 : value}
          options={options}
        />
      </div>
    </div>
  );
}
