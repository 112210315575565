/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import './style.scss';

const TableHead = ({ children, ...props }) => {
  return (
    <thead
      className={`base-thead${props.className ? ` ${props.className}` : ''}`}
    >
      {children}
    </thead>
  );
};

export default TableHead;
