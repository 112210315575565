import React, { useState, useCallback, useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender
} from '@tanstack/react-table';
import { isEqual, isObject, omit } from 'lodash';
import * as classNames from 'classnames';

import TableUtil from 'utils/TableUtil';
import DebouncedInput from 'components/Curriculum/Table/DebouncedInput';
import { default as ReconnectTable } from 'components/Table';
import Filter from 'components/Curriculum/Table/Filter';
import Paginator from 'components/Curriculum/Paginator';
import EmptyTable from 'components/Curriculum/EmptyTable';
import CsvButton from 'components/CsvButton';
import './style.scss';

const Table = ({
  data = [],
  columns = [],
  hasSearch = false,
  csvHeaders = [],
  searchProps = {},
  emptyMessage,
  emptyNoShadow = false,
  filterFns = {},
  reactTableProps = {},
  classes = {},
  state = {},
  fixed = false,
  children
}) => {
  const [items, setItems] = useState(data);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    if (!isEqual(data, items)) {
      setItems(data);
    }
  }, [data, items]);

  const table = useReactTable({
    data: items,
    columns,
    filterFns: {
      fuzzy: TableUtil.fuzzyFilter,
      ...filterFns
    },
    state: {
      columnFilters,
      globalFilter,
      ...state
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: TableUtil.fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    ...omit(reactTableProps, ['state'])
    // debugAll: ENVIRONMENT !== 'production'
  });
  const pageCount = table.getPageCount();

  const handlePageClick = useCallback(
    ({ selected = 0 }) => {
      table.setPageIndex(selected);
    },
    [table.setPageIndex]
  );

  return (
    <div className="data-table__container">
      {data.length ? (
        <>
          {hasSearch ? (
            <div className="search-wrapper">
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={value => setGlobalFilter(String(value))}
                placeholder="Search all columns"
                {...searchProps}
              />
              {children}
              {csvHeaders.length > 0 && (
                <CsvButton
                  title="appointments-log"
                  isDownloadDisabled={!items.length}
                  csvData={table.getRowModel().rows.map(row => row.original)}
                  headers={csvHeaders}
                />
              )}
            </div>
          ) : null}
          <div className={classNames('table-wrapper', { fixed })}>
            <ReconnectTable.BaseTable className={classes.baseTable}>
              <ReconnectTable.TableHead>
                {table.getHeaderGroups().map(headerGroup => {
                  return (
                    <ReconnectTable.TableRow key={headerGroup.id}>
                      {headerGroup.headers.map(header => {
                        const sortDirection = header.column.getIsSorted();
                        const sortColumn = header.column.columnDef.accessorKey;
                        const sortable = header.column.getCanSort();

                        return (
                          <ReconnectTable.TableHeader
                            key={header.id}
                            colSpan={header.colSpan}
                            handleSort={() => header.column.toggleSorting()}
                            unsortable={!sortable}
                            headerInfo={{
                              unsortable: !sortable,
                              sortCol: sortColumn
                            }}
                            sort={{
                              type: sortDirection,
                              name: sortColumn
                            }}
                            extra={
                              header.column.getCanFilter() ? <Filter column={header.column} table={table} /> : null
                            }
                          >
                            {header.isPlaceholder ? null : (
                              <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                            )}
                          </ReconnectTable.TableHeader>
                        );
                      })}
                    </ReconnectTable.TableRow>
                  );
                })}
              </ReconnectTable.TableHead>

              <ReconnectTable.TableBody>
                {table.getRowModel().rows.map(row => {
                  let rowProps = {};

                  if ('function' === typeof table?.options?.meta?.getRowProps) {
                    rowProps = table?.options?.meta?.getRowProps(row);
                  }

                  return (
                    <ReconnectTable.TableRow key={row.id} {...(isObject(rowProps) ? rowProps : {})}>
                      {row.getVisibleCells().map(cell => {
                        const meta = cell.getContext().cell.column.columnDef.meta;

                        return (
                          <ReconnectTable.TableCell key={cell.id} {...(isObject(meta) ? meta : {})}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </ReconnectTable.TableCell>
                        );
                      })}
                    </ReconnectTable.TableRow>
                  );
                })}
              </ReconnectTable.TableBody>
            </ReconnectTable.BaseTable>
          </div>

          {pageCount <= 1 ? null : (
            <Paginator
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              forcePage={table.getState().pagination.pageIndex}
            />
          )}
        </>
      ) : (
        <EmptyTable message={emptyMessage} noShadow={emptyNoShadow} />
      )}
    </div>
  );
};

export default Table;
