import React from 'react';

const TooltipContentCompletion = ({ active, payload }) => {
  if (active && payload?.[0]?.payload?.payload?.title) {
    return (
      <div className="tooltip-content">
        <p>{payload[0].payload.payload.title}</p>
      </div>
    );
  }

  return null;
};

export default TooltipContentCompletion;
