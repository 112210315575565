/**
 * Check if n refers to a quantity that requires nouns to be expressed in plural or singular form.
 *
 * @param {string} singular String with noun in singular form.
 * @param {string} plural String with noun(s) in plural form.
 * @param {number} n Quantity pointed by the associated noun. Resolves between returning the plural or singular version of the string.
 *
 * @returns {string} If the quantity is 1, returns the singular: "1 user found". Otherwise returns the plural version: "0 users found.", "2 users found.".
 */
export default function getSingularPlural(singular, plural, n) {
  if (1 === n) {
    return singular;
  }
  return plural;
}
