export default function curfewEvents(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_GET_CURFEW_EVENTS':
      return {
        ...state,
        isLoadingCurfewEvents: true
      };
    case 'LOAD_SUCCESSFUL_GET_CURFEW_EVENTS':
      return {
        ...state,
        isLoadingCurfewEvents: false
      };
    case 'LOAD_PENDING_GET_EVENTS_BY_CLIENTS':
      return {
        ...state,
        isLoadingEventsByClient: true
      };
    case 'LOAD_SUCCESSFUL_GET_EVENTS_BY_CLIENTS':
      return {
        ...state,
        isLoadingEventsByClient: false
      };
  }
  return state;
}
