import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Button from 'components/Button';
import { useCloseDrawer } from 'components/Curriculum/hooks';
import getDrawerProps from 'selectors/getDrawerProps';
import NotesProvider from 'components/Clients/ClientNotes/NotesContext';
import ChildWrapper from 'components/Clients/ClientNotes/ChildWrapper';

function DayNotesDrawer() {
  const { clientId, date } = useSelector(getDrawerProps);
  const handleCloseDrawer = useCloseDrawer();

  return (
    <NotesProvider clientId={clientId} date={date}>
      <div className="vc-container daynotes-drawer">
        <div className="vc-container__scroll-wrapper">
          <ChildWrapper
            Wrapper="div"
            containerStyle={{ padding: '18px 18px 84px' }}
            title={`Client Notes for ${moment(date).format('MMMM D, YYYY')}`}
          />
          <div className="daynotes-drawer__actions">
            <Button handleButtonClick={handleCloseDrawer} primary transparent>
              Close
            </Button>
          </div>
        </div>
      </div>
    </NotesProvider>
  );
}

export default DayNotesDrawer;
