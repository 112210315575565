import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Dropdown from './Dropdown';
import GlobalSearch from './GlobalSearch';
import { AppContext } from 'components/Context/AppContext';
import { closeContextualMenu, closeMobileMenu } from 'actions/actionCreators';
import R7tIso from '../../img/r7t-iso.svg';
import R7tLogo from '../../img/r7t-logo.svg';
import './style.scss';

const Topbar = ({ firstName, lastName, facilityName, avatarUrl = '', closeContextualMenu, closeMobileMenu }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { hasAccessTo } = useContext(AppContext);

  const toggleMenu = () => {
    if (!isOpen) {
      closeMobileMenu();
      closeContextualMenu();
    }
    setIsOpen(!isOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };

  const userRef = useRef();

  const logoLink = !hasAccessTo('dashboard') ? '/clients' : '/dashboard';

  return (
    <header className="topbar">
      <nav className="topbar__nav" role="navigation" style={{ position: 'relative' }}>
        <div className="topbar__logo">
          <Link to={logoLink}>
            <R7tIso />
          </Link>
        </div>
        <div className="topbar__center">
          <Link to={logoLink}>
            <span className="topbar__facility-name">{facilityName}</span>
            <span className="topbar__reconnect-logo">
              <R7tLogo />
            </span>
          </Link>
        </div>
        <AppContext.Consumer>
          {() => (
            <div className="topbar__user">
              <GlobalSearch />
              <div
                className="topbar__user-logged-in"
                onClick={isOpen ? closeMenu : toggleMenu}
                data-testid="logged-in-dropdown"
                ref={userRef}
              >
                <div className="topbar__user-title">
                  <span className="topbar__user-name">{`${firstName} ${lastName}`}</span>
                  {'' === avatarUrl ? (
                    <div className="topbar__user-avatar">
                      {firstName[0] && lastName[0] ? firstName[0] + lastName[0] : ''}
                    </div>
                  ) : (
                    <img src={avatarUrl} className="topbar__user-avatar" />
                  )}
                  {isOpen && (
                    <Dropdown
                      refElement={userRef}
                      closeMenu={closeMenu}
                      name={firstName + ' ' + lastName}
                      isOpen={isOpen}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </AppContext.Consumer>
      </nav>
    </header>
  );
};

Topbar.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  facilityName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  closeContextualMenu: PropTypes.func.isRequired,
  closeMobileMenu: PropTypes.func.isRequired
};

Topbar.defaultprops = {
  avatarUrl: ''
};

const mapDispatchToProps = {
  closeContextualMenu,
  closeMobileMenu
};

export default connect(null, mapDispatchToProps)(Topbar);
