import React, { useContext } from 'react';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';
import SideNavSub from './SideNavSub';

const SideNavCurriculum = ({ pathname }) => {
  const { hasAccessTo } = useContext(AppContext);

  if (hasAccessTo('curriculum')) {
    return (
      <NavLink
        to="/learning-lab"
        title="Learning Lab (LMS)"
        nestedMenu={
          <SideNavSub
            title="Learning Lab (LMS)"
            links={[
              { to: '/learning-lab', label: 'Overview' },
              { to: '/learning-lab/topics', label: 'Topics' },
              { to: '/learning-lab/resources', label: 'Resources' },
              { to: '/learning-lab/packets', label: 'Packets' },
              {
                to: '/learning-lab/assignments',
                label: 'Assignments',
                className: pathname.includes('/learning-lab/assignment') ? 'sidenavsub__link--active' : ''
              }
            ]}
          />
        }
      >
        <Icon name="book" size={24} />
        <span className="sidenav__item-label">Learning Lab (LMS)</span>
      </NavLink>
    );
  }

  return null;
};

export default SideNavCurriculum;
