import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import useOutsideClick from 'hooks/useClickOutside';

import './style.scss';

const Modal = ({ enableClose = false, closeModal, isShowing = false, className = '', header = '', children }) => {
  const modalRef = useRef();

  useOutsideClick(modalRef, () => {
    if (enableClose) {
      closeModal();
    }
  });

  useLayoutEffect(() => {
    if (!isShowing) return;
    const { offsetWidth } = document.body;
    document.body.style.width = `${offsetWidth}px`;

    const originalOverflow = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.width = '100%';
      document.body.style.overflow = originalOverflow;
    };
  }, [isShowing]);

  return isShowing
    ? createPortal(
        <>
          <div className="modal-overlay" />
          <div ref={modalRef} className={`modal ${className}`} aria-modal aria-hidden tabIndex={-1} role="dialog">
            <div className="modal__header">{header}</div>
            <div className="modal__content">{children}</div>
          </div>
        </>,
        document.body
      )
    : null;
};

Modal.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
  enableClose: PropTypes.bool,
  isShowing: PropTypes.bool.isRequired,
  header: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default Modal;
