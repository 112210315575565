import { loadPending, loadSuccessful } from '../helperActions';
import { acceptTermsOfService } from 'utils/apiHelpers';

/**
 * Invokes the API helper to flag the terms as accepted.
 *
 * @param {integer} userId Id of the user accepting the ToS.
 * @param {integer} tosId  Id of the ToS accepted by the user.
 */
export const setTermsOfServiceAccepted = (userId, tosId) => dispatch => {
  dispatch(loadPending('TERMS_ACCEPTED'));

  return acceptTermsOfService(userId, tosId)
    .then(response => {
      if (response.data.status == 'success') {
        dispatch(loadSuccessful('TERMS_ACCEPTED', {}));
      } else {
        dispatch({
          type: 'FAILED_REQUEST_TERMS_ACCEPTED'
        });
      }
    })
    .catch(() => {
      dispatch({
        type: 'FAILED_REQUEST_TERMS_ACCEPTED'
      });
    });
};
