import { loadPending, removePending, loadSuccessful } from './helperActions';

import { getClientGroups, catchErrors } from 'utils/apiHelpers';

export function getGroups(groups) {
  let hash = groups.doneInitialLoad && groups.hash;
  return dispatch => {
    // set to pending
    dispatch(loadPending('GROUPS'));

    if (hash) {
      getClientGroups(hash)
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending('GROUPS'));
          } else {
            dispatch(loadSuccessful('GROUPS', res.data));
          }
        })
        .catch(catchErrors);
    } else {
      getClientGroups(false)
        .then(res => {
          dispatch(loadSuccessful('GROUPS', res.data));
        })
        .catch(catchErrors);
    }
  };
}
