import React from 'react';
import classnames from 'classnames';

import Label from 'components/Label';
import Button from 'components/Button';
import Icon from 'components/Icon';
import './style.scss';

const AssigneesItem = ({
  header,
  items,
  editStep = 0,
  handleEdit = null,
  style = {},
  isDrawer = false,
  tooltip = false,
  classes = {}
}) => {
  if (!items || !Array.isArray(items) || !items.length) {
    return null;
  }

  return (
    <div className="review-item">
      <div className="review-item__header">
        {header ? (
          <Label bold mBottom={false} tooltip={tooltip}>
            {header}
          </Label>
        ) : null}
        {'function' === typeof handleEdit ? (
          <Button invisible handleButtonClick={handleEdit(editStep)}>
            <Icon name="edit" />
          </Button>
        ) : null}
      </div>
      <div className="review-item__list" role="list">
        {items.map((item, i) => {
          return (
            <div
              key={`review-item-${i}`}
              className={classnames('review-item__list--item', {
                'full-width': isDrawer,
                'honeydew': classes?.[i] === 'honeydew'
              })}
              style={style}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AssigneesItem;
