export default accuracy => {
  if (accuracy > 0) {
    const feet = accuracy / 0.3048;

    if (feet < 5280 / 3) {
      return `${feet.toFixed(2).toLocaleString()} feet`;
    }
    const miles = feet / 5280;
    return `${miles.toFixed(2)} miles`;
  } else {
    return `100% accurate`;
  }
};
