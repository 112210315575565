/**
 * External dependencies
 */
import merge from 'lodash/merge';

export default function clients(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_CLIENTS':
      return {
        ...state,
        pending: true
      };
    case 'REMOVE_PENDING_CLIENTS':
      return {
        ...state,
        pending: false
      };
    case 'LOAD_SUCCESSFUL_CLIENTS':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        list: action.payload.clients,
        hash: action.payload.hash
      };
    case 'LOAD_PENDING_CLIENT_NOTES':
      return merge({}, state, {
        clientNotes: {
          [action.payload.clientId]: {
            requesting: true
          }
        }
      });
    case 'LOAD_SUCCESSFUL_CLIENT_NOTES':
      return merge({}, state, {
        clientNotes: {
          [action.payload.clientId]: {
            requesting: false,
            notes: action.payload.notes
          }
        }
      });
    case 'LOAD_PENDING_ADD_CLIENT_NOTE':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isAddingClientNote: true
          }
        }
      });
    case 'LOAD_SUCCESSFUL_ADD_CLIENT_NOTE':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isAddingClientNote: false,
            notes: [
              action.payload.note,
              ...(state.clientList[action.payload.clientId].notes || [])
            ]
          }
        }
      });
    case 'LOAD_PENDING_CLIENT_DEVICES':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isRequestingClientDevices: true
          }
        }
      });
    case 'LOAD_SUCCESSFUL_CLIENT_DEVICES':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isRequestingClientDevices: false,
            devices: action.payload.connections
          }
        }
      });
    case 'LOAD_PENDING_CLIENT_CONVERSATIONS':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isRequestingConversations: true
          }
        }
      });
    case 'LOAD_SUCCESSFUL_CLIENT_CONVERSATIONS':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isRequestingConversations: false,
            conversations: action.payload.chats
          }
        }
      });
    case 'LOAD_PENDING_CLIENT_COMPLETED_FORMS':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isRequestingClientCompletedForms: true
          }
        }
      });
    case 'LOAD_SUCCESSFUL_CLIENT_COMPLETED_FORMS':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isRequestingClientCompletedForms: false,
            forms: action.payload.form_responses
          }
        }
      });
    case 'LOAD_PENDING_SINGLE_COMPLETED_FORM':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            formResponses: {
              [action.payload.entryId]: {
                isRequestingEntry: true
              }
            }
          }
        }
      });
    case 'LOAD_SUCCESSFUL_SINGLE_COMPLETED_FORM':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            formResponses: {
              [action.payload.entryId]: {
                isRequestingEntry: false,
                entry: {
                  name: action.payload.form_response.form.name,
                  created: action.payload.form_response.created,
                  fields: action.payload.form_response.form.fields,
                  data: action.payload.form_response.data
                }
              }
            }
          }
        }
      });
    case 'LOAD_PENDING_CLIENT_LOCATIONS':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isRequestingClientLocations: true
          }
        }
      });
    case 'REMOVE_PENDING_CLIENT_LOCATIONS':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isRequestingClientLocations: false
          }
        }
      });
    case 'LOAD_SUCCESSFUL_CLIENT_LOCATIONS':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isRequestingClientLocations: false,
            locations: {
              doneInitialLoad: true,
              list: action.payload.locations,
              hash: action.payload.hash
            }
          }
        }
      });
    case 'LOAD_PENDING_ASSIGN_LOCATION':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isAddingLocation: true
          }
        }
      });
    case 'LOAD_SUCCESSFUL_ASSIGN_LOCATION':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isAddingLocation: false,
            locations: {
              list: [
                ...(state.clientList[action.payload.clientId].locations?.list ??
                  []),
                action.payload.location
              ]
            }
          }
        }
      });
    case 'LOAD_PENDING_CLIENT_LOCATION_ADD':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isAddingLocation: true,
            locations: {
              updatedLocation: null
            }
          }
        }
      });
    case 'LOAD_SUCCESSFUL_CLIENT_LOCATION_ADD':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isAddingLocation: false,
            locations: {
              updatedLocation: {
                status: action.payload.status,
                error: action.payload.error ?? null,
                locationId: action.payload.location?.id ?? null,
                locationName: action.payload.location?.name ?? null
              },
              list: [
                ...(state.clientList[action.payload.clientId].locations?.list ??
                  []),
                action.payload.location
              ]
            }
          }
        }
      });
    case 'LOAD_PENDING_CLIENT_LOCATION_UPDATE':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isUpdatingLocation: true,
            locations: {
              updatedLocation: null
            }
          }
        }
      });
    case 'LOAD_SUCCESSFUL_CLIENT_LOCATION_UPDATE': {
      const newLocationState = Object.assign({}, state);
      const locationList = [
        ...(
          state.clientList[action.payload.clientId].locations?.list ?? []
        ).map(location =>
          location.id !== action.payload.location.id
            ? location
            : action.payload.location
        )
      ];
      newLocationState.clientList[
        action.payload.clientId
      ].locations.list = locationList;
      return merge(newLocationState, {
        clientList: {
          [action.payload.clientId]: {
            isUpdatingLocation: false,
            locations: {
              updatedLocation: {
                status: action.payload.status,
                error: action.payload.error ?? null,
                locationId: action.payload.location?.id ?? null,
                locationName: action.payload.location?.name ?? null
              }
            }
          }
        }
      });
    }
    case 'LOAD_SUCCESSFUL_CLIENT_LOCATION_REFRESH':
      if (!state.clientList) {
        return state;
      }
      return merge({}, state, {
        clientList: Object.keys(state.clientList).reduce((clist, clientId) => {
          const client = state.clientList[clientId];
          return {
            ...clist,
            [clientId]: !client.locations
              ? client
              : {
                  ...client,
                  locations: {
                    ...client.locations,
                    list: client.locations.list.map(location => {
                      if (location.id == action.payload.location.id) {
                        return {
                          ...action.payload.location,
                          client_id: +clientId
                        };
                      }
                    })
                  }
                }
          };
        }, {})
      });
    case 'LOAD_PENDING_CLIENT_LOCATION_REMOVE':
      return merge({}, state, {
        clientList: {
          [action.payload.clientId]: {
            isRemovingLocation: true,
            locations: {
              updatedLocation: null
            }
          }
        }
      });
    case 'LOAD_SUCCESSFUL_CLIENT_LOCATION_REMOVE':
      var newState = { ...state };
      newState.clientList[action.payload.clientId] = {
        isRemovingLocation: false,
        locations: {
          updatedLocation: {
            status: action.payload.status,
            error: action.payload.error ?? null
          },
          list: (
            state.clientList[action.payload.clientId].locations?.list ?? []
          ).filter(location => location.id != action.payload.locationId)
        }
      };
      return newState;
  }
  return state;
}
