import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Password from 'components/PasswordMeter';
import Label from 'components/Label';
import Input from 'components/Input';
import Icon from 'components/Icon';

import './style.scss';

const PasswordWidget = props => {
  const [state, setPasswordState] = useState({
    confirmedPassword1: '',
    confirmedPassword2: '',
    ready: false,
    message: ''
  });

  const [score, setScore] = useState(0);
  const [confirmed, setConfirmed] = useState(false);

  const handleSetPassword = ({ target: { name, value } }) => {
    setPasswordState({
      ...state,
      [name]: value
    });
  };

  useEffect(() => {
    if (state.confirmedPassword1 === state.confirmedPassword2 && score > 2) {
      props.handleChange(
        { target: { name: props.name, value: state.confirmedPassword1 } },
        props.blockGroup
      );
      setConfirmed(true);
    } else {
      props.handleChange(
        { target: { name: props.name, value: '' } },
        props.blockGroup
      );
      setConfirmed(false);
    }
  }, [state.confirmedPassword2, state.confirmedPassword2, score]);

  useEffect(() => {
    if (!state.confirmedPassword1 && !!state.confirmedPassword2) {
      setPasswordState({
        ...state,
        confirmedPassword2: ''
      });
    }
  }, [state.confirmedPassword1, state.confirmedPassword2]);

  useEffect(() => {
    if (props.message) {
      setPasswordState({
        ...state,
        message: props.message
      });
    }
  }, [props.message]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPasswordState({
        ...state,
        message: ''
      });
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <>
      <Input
        label={props.passwordLabel ? props.passwordLabel : 'Change Password'}
        name={'confirmedPassword1'}
        type="password"
        value={state.confirmedPassword1}
        handleChange={handleSetPassword}
        required={props.required}
        error={props.error}
        id="change-password"
      />
      {!!state.confirmedPassword1 && (
        <Password
          password={state.confirmedPassword1}
          onPasswordChangeCallback={setScore}
        />
      )}
      {state.confirmedPassword1 !== '' && score > 2 ? (
        <div className="confirmed-container">
          <Input
            label="Confirm Password"
            name="confirmedPassword2"
            type="password"
            value={state.confirmedPassword2}
            handleChange={handleSetPassword}
            id="confirm-password"
          />
          {!!confirmed && <Icon name="checkmark" />}
        </div>
      ) : null}
      {!!state.message && (
        <Label alert mTop>
          {state.message}
        </Label>
      )}
    </>
  );
};

PasswordWidget.propTypes = {
  blockGroup: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default PasswordWidget;
