/**
 * Reduces the action/payload.
 *
 * @param {object} state
 * @param {object} action
 */
export default function termsOfService(state = { accepted: null }, action) {
  switch (action.type) {
    case 'LOAD_PENDING_TERMS_ACCEPTED':
      return {
        ...state,
        accepted: null
      };
    case 'LOAD_SUCCESSFUL_TERMS_ACCEPTED':
      return {
        ...state,
        accepted: true
      };
    case 'FAILED_REQUEST_TERMS_ACCEPTED':
      return {
        ...state,
        accepted: false
      };
  }

  return state;
}
