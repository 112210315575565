import React, { Component } from 'react';

import Input from 'components/Input';
import Button from 'components/Button';

class SingleEventNotes extends Component {
  state = {
    notes: this.props.notes
  };

  handleInputChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value });

  handleSend = () => {
    const { notes } = this.state;
    this.props.handleUpdate(notes);
    this.setState({
      notes: ''
    });
  };

  render() {
    const { title, showForm } = this.props;

    return (
      <div className="vc-container__form">
        <h3 className="header-text">{title}</h3>
        {!!showForm && (
          <div className="notes-form">
            <p className="note-hint">
              Notes are only visible to you and other facility staff.
            </p>
            <Input
              Component="textarea"
              className="new-note"
              name="notes"
              handleChange={this.handleInputChange}
              value={this.state.notes}
              id="notes-text-area"
            />
            <Button
              secondary
              micro
              transparent
              handleButtonClick={this.handleSend}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default SingleEventNotes;
