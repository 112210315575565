/**
 * External dependencies
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Internal dependencies
 */
import DataTableMixin from './Mixin';
import Header from './Header';
import Paginator from './Paginator';
import Table from 'components/Table';
import Label from 'components/Label';
import DisplayFilter from 'components/DisplayFilter';
import './style.scss';

class TableHead extends React.Component {
  static propTypes = {
    headers: PropTypes.array.isRequired,
    tableRowClass: PropTypes.string
  };

  render() {
    const { headers, tableRowClass, ...rest } = this.props;
    return (
      <thead className="base-thead">
        <Table.TableRow className={tableRowClass}>
          {headers.map((headerInfo, i) => {
            const props = { ...rest, headerInfo };
            if (headerInfo.unsortable) {
              props.handleSort = null;
            }
            return (
              <Table.TableHeader
                key={`header-${headerInfo.sortCol}-${i}`}
                className={headerInfo.className ? headerInfo.className : ''}
                {...props}
              >
                <Label tag="span">{headerInfo.children}</Label>
              </Table.TableHeader>
            );
          })}
        </Table.TableRow>
      </thead>
    );
  }
}

class TopFilterInfo extends React.Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    filteredData: PropTypes.array.isRequired,
    perPage: PropTypes.number.isRequired,
    updatePageLength: PropTypes.func.isRequired
  };

  state = {
    filteredValue: this.props.data.length,
    menuOpen: false
  };

  updatePageLength = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value
      },
      () => {
        this.props.updatePageLength(value);
      }
    );
  };

  render() {
    const { currentPage, data, filteredData, perPage } = this.props;
    const isEmpty = filteredData.length === 0;
    const first = perPage * currentPage + 1;
    let last = perPage * (currentPage + 1);

    if (last > filteredData.length) {
      last = filteredData.length;
    }
    const message = `${first}-${last} OF ${filteredData.length} RESULTS`;

    const filterOptions = [
      { value: data.length, label: 'All', isSelected: true },
      ...[10, 25, 50, 100].map(n => ({ value: n, label: `${n}` }))
    ];

    return (
      <DisplayFilter
        isEmpty={isEmpty}
        message={message}
        showFilter={data.length > 25}
        value={this.state.filteredValue}
        handleChange={this.updatePageLength}
        name="filteredValue"
        options={filterOptions}
      />
    );
  }
}

export { DataTableMixin, TableHead, Header, Paginator, TopFilterInfo };
