import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';

import { closeDrawer } from 'actions/actionCreators';
import {
  approveHomeAddressChange,
  denyHomeAddressChange
} from 'utils/apiHelpers';
import { useToast } from 'components/Toast';
import BlockWrapper from '../common/BlockWrapper';
import InfoBlockRow from '../InfoBlockRow.js';
import { getStatusStyles } from 'components/Violations/violations-helpers';
import Button from 'components/Button';
import { MapMarker } from '../common/drawerHelpers';
import ActionButtons from './ActionButtons';
import MapContainer from 'components/Map';
import ClientNameLink from 'components/ClientNameLink';

function TodoHomeAddressDrawer({
  drawer: {
    drawerOpen,
    drawerProps: { todo, getCallBack }
  },
  closeDrawer
}) {
  const toast = useToast();

  const handlePrint = e => {
    e?.preventDefault();

    const body = document.querySelector('body');
    body.classList.add('print-violations');

    window.print();
  };

  const addressBlock = () => {
    return (
      <div className="address-with-map-marker">
        <p>{todo.location.address.address_text}</p>
        <p>
          <MapMarker
            addr={todo.location.address.address_text}
            lat={todo.location.address.coordinates.latitude}
            lng={todo.location.address.coordinates.longitude}
            handleClose={closeDrawer}
          />
        </p>
      </div>
    );
  };

  const handleUpdateTodo = action => {
    if (action == 'approve') {
      approveHomeAddressChange(
        todo.related_external_id,
        todo.client_id,
        todo.external_id
      ).then(response => {
        if (response.data.status == 'success') {
          getCallBack();
          closeDrawer();
          toast.addToast({
            duration: 3000,
            type: 'checkmark',
            content: `Client home address has been successfully approved.`
          });
        } else {
          toast.addToast({
            duration: 3000,
            type: 'warning',
            content: `There was an error approving the home address, please try again.`
          });
        }
      });
    } else {
      denyHomeAddressChange(
        todo.related_external_id,
        todo.client_id,
        todo.external_id
      ).then(response => {
        if (response.data.status == 'success') {
          getCallBack();
          closeDrawer();
          toast.addToast({
            duration: 3000,
            type: 'checkmark',
            content: `Client home address has been successfully disapproved.`
          });
        } else {
          toast.addToast({
            duration: 3000,
            type: 'warning',
            content: `There was an error disapproving the client home address, please try again.`
          });
        }
      });
    }
  };

  const { ...todoStyle } = getStatusStyles(todo.status);

  const locationLatLng = {
    lat: todo.location.address.coordinates.latitude,
    lng: todo.location.address.coordinates.longitude
  };

  return (
    <div className="vc-container">
      <div className="vc-container__scroll-wrapper">
        <div className="print-only">
          <img className="print-logo" src="/static/img/r7t-logo.jpg" />
          <h2>{moment(todo.created_at).format('MMM D, YYYY h:mm A')}</h2>
          <h2>
            Client Event for{' '}
            {todo.client.first_name + ' ' + todo.client.last_name}
          </h2>
        </div>
        <div
          className={classnames('vc-container__header', {
            'drawer-open': drawerOpen
          })}
        >
          <h3 className="vc-container__header--heading">Home Address</h3>
          <div className={classnames('vc-status', todoStyle)}>
            <span>{todo.status}</span>
          </div>
        </div>
        <div className="vc-container__print-button">
          <Button secondary micro handleButtonClick={handlePrint}>
            Print
          </Button>
        </div>
        <MapContainer
          center={locationLatLng}
          containerStyles={{
            height: '400px'
          }}
          parentStyles={{
            width: '96%',
            marginLeft: '1rem',
            marginTop: '1rem',
            flex: 1
          }}
          primaryMarker={{
            position: locationLatLng,
            title: 'Home Address',
            name: 'Home Address',
            icon: '/static/img/marker_red.png'
          }}
          circle={[
            {
              radius: parseInt(todo.location.address.radius, 10) / 3.281, // lenience is in feet, we need to convert to meters
              center: locationLatLng,
              strokeColor: 'transparent',
              strokeOpacity: 0,
              strokeWeight: 5,
              fillColor: '#6cc049',
              fillOpacity: 0.2
            }
          ]}
        />
        <BlockWrapper
          clientName={<ClientNameLink client_id={todo.client_id} clientName={`${todo.client.last_name} ${todo.client.last_name}`} />}
          closeDrawer={closeDrawer}
          status={todo.status}
          block={{
            blockLabel: 'Address'
          }}
        />
        <div className="drawer-body vc-container__info-block">
          {[
            ['Date', moment(todo.created_at).format('MMMM D, YYYY')],
            ['Address', addressBlock],
            ['Lenience', parseInt(todo.location.address.radius, 10) + ' feet']
          ].map((e, i) => (
            <InfoBlockRow key={`${e[0]}-${i}`} title={e[0]} value={e[1]} />
          ))}
        </div>
        <ActionButtons
          drawerOpen={drawerOpen}
          handleUpdateTodo={handleUpdateTodo}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  drawer: state.drawer
});

export default connect(mapStateToProps, { closeDrawer })(TodoHomeAddressDrawer);
