/**
 * External dependencies
 */
import get from 'lodash/get';

/**
 * Get the loading status of the maps library.
 *
 * @param {object} state App state tree.
 *
 * @returns {string}     The statuses are:
 *                         notloaded when the loading hasn't started
 *                         loading   when the loading started but didn't end yet
 *                         ready     when the library is ready to be used
 */
export const getMapsLibStatus = state =>
  get(state, ['locations', 'mapsLibStatus'], 'notloaded');

/**
 * Check if the maps library is loaded.
 *
 * @param {object} state App state tree.
 *
 * @returns {bool}       True if the library is ready to be used.
 */
export const isMapsLibReady = state =>
  'ready' === get(state, ['locations', 'mapsLibStatus'], 'notloaded');
