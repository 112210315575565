import { FieldBuilder } from "./FieldBuilder"

export class SchemaBuilder {
  constructor(parent, schema) {
    this.parent = parent
    this.schema = schema
  }

  build() {
    const builder = new FieldBuilder()
    this.schema(builder)
    const obj = builder.build()
    return obj
  }
}