import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AppContext } from 'components/Context/AppContext';
import Image from 'components/Image';
import MapContainer from 'components/Map/';
import getDrawerProps from 'selectors/getDrawerProps';

const MapAndOrImage = ({ }) => {
  const { handleLightbox } = useContext(AppContext);
  const { block, hasPos } = useSelector(getDrawerProps);

  const hasPosLatLng = useMemo(() => {
    if (!hasPos) return undefined;

    return { lat: hasPos.lat, lng: hasPos.lng };
  }, [hasPos]);

  if (!block || (!block?.checkUrl && !hasPos)) {
    return null;
  }

  return (
    <div className="vc-container__top-block">
      {!!block.checkUrl && (
        <Image
          src={block.checkUrl}
          handleClick={handleLightbox}
        />
      )}
      {!!hasPos && (
        <MapContainer
          center={hasPosLatLng}
          containerStyles={{ height: '200px' }}
          parentStyles={{
            width: '100%',
            marginLeft: '1rem',
            flex: 1
          }}
          primaryMarker={{
            position: hasPosLatLng,
            title: 'Check-In Location',
            name: 'Checked In Here',
            icon: '/static/img/marker_red.png'
          }}
        />
      )}
    </div>
  );
}

export default MapAndOrImage;