class Faces {
  static STRAIGHT = 'straight_on';
  static LEFT = 'face_left';
  static RIGHT = 'face_right';
  static DOWN = 'face_down';
  static UP = 'face_up';
}

export class Endpoints {
  static GET = 'collection/get_client_baseline_images';
  static UPDATE = 'collection/activate_or_deactivate_collection';
  static CLEAR_SINGLE = 'collection/clear_image_from_collection';
}

export default class BaselineUtil {
  static TYPES = [Faces.STRAIGHT, Faces.LEFT, Faces.RIGHT, Faces.DOWN, Faces.UP];
  static LABELS = {
    [Faces.STRAIGHT]: 'Facing Front',
    [Faces.LEFT]: 'Facing Left',
    [Faces.RIGHT]: 'Facing Right',
    [Faces.DOWN]: 'Facing Down',
    [Faces.UP]: 'Facing Up'
  };

  static DEFAULT_STATE = {
    images: [],
    isLoading: true,
    active: false,
    loadedImages: {},
    shouldRotate: {},
    canApprove: false,
    modalOpen: false,
    pendingFaceId: null,
    singleClearModalOpen: false
  };

  static getCanApprove(images = []) {
    if (!Array.isArray(images)) return false;

    const types = images.filter(i => +i.active).map(i => i.type);

    return BaselineUtil.TYPES.every(i => types.includes(i));
  }

  static getState(images = [], active = false) {
    return {
      images: BaselineUtil._sort(images),
      active: ['1', 1, true].includes(active),
      canApprove: BaselineUtil.getCanApprove(images)
    };
  }

  static _sort(images = []) {
    const sorted = images?.sort((a, b) => (a?.type < b?.type ? 1 : -1)) ?? [];

    return sorted.map(image => ({ ...image, label: BaselineUtil.getLabel(image.type) }));
  }

  static getLabel(type) {
    return BaselineUtil.LABELS?.[type] ?? 'Check-In Image';
  }
}
