class TableProps {
  props = {
    initialState: {},
    meta: {},
    state: {}
  };

  constructor() {}

  setColumnVisibility(columnVisibility = {}) {
    this.props.initialState.columnVisibility = columnVisibility;

    return this;
  }

  setSorting(sorting = []) {
    if (Array.isArray(sorting) && sorting.length) {
      this.props.initialState.sorting = sorting;
    }

    return this;
  }

  setPagination(pagination) {
    this.props.initialState.pagination = pagination;

    return this;
  }

  setMeta(meta = {}) {
    if ('object' === typeof meta) {
      this.props.meta = { ...this.props.meta, ...meta };
    }

    return this;
  }

  setState(state = {}) {
    if ('object' === typeof state) {
      this.props.state = { ...this.props.state, ...state };
    }

    return this;
  }
}

export default TableProps;
