import { useState, useCallback } from 'react';

const useList = (defaultList = []) => {
  const [list, setList] = useState(defaultList);

  const get = useCallback(
    index => {
      return list.find((_, i) => i === index);
    },
    [list]
  );

  const set = useCallback(l => {
    setList(l);
  }, []);

  const push = useCallback(element => {
    setList(l => [...l, element]);
  }, []);

  const remove = useCallback(index => {
    setList(l => l.filter((_, i) => i !== index));
  }, []);

  const upsert = useCallback((index, element) => {
    setList(l => l.map((e, i) => (i === index ? element : e)));
  }, []);

  const unshift = useCallback(element => {
    setList(prev => [element, ...prev]);
  }, []);

  const clear = useCallback(() => setList([]), []);

  return [list, { get, set, push, unshift, remove, upsert, clear }];
};

export default useList;
