import cloneDeep from 'lodash/cloneDeep';

const makeSimpleCals = (
  calendars,
  list,
  default_calendar,
  isAdmin,
  isOwnUser
) => {
  return isAdmin && isOwnUser
    ? !!list &&
        list.map(calendar => ({
          calendar_id: calendar.id,
          active: calendar.id === default_calendar ? 1 : 0,
          name: calendar.name
        }))
    : !!calendars &&
        calendars.map(calendar => {
          return {
            calendar_id: calendar.calendar_id,
            active: calendar.active
          };
        });
};

export const getScore = score => {
  switch (score) {
    case 0:
      return 'Weak';
    case 1:
      return 'Weak';
    case 2:
      return 'Fair';
    case 3:
      return 'Good';
    case 4:
      return 'Strong';
    default:
      return '';
  }
};

export const userStateObj = (user, list, isAdmin, isOwnUser) => {
  const { calendars: oldCals } = cloneDeep(user);
  const simpleCals = makeSimpleCals(
    oldCals,
    list,
    user.default_calendar,
    isAdmin,
    isOwnUser
  );

  if (Array.isArray(simpleCals)) {
    return simpleCals.filter(cal => cal);
  }

  return [];
};
