/**
 * External dependencies
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * Internal dependencies
 */
import Label from 'components/Label';
import Image from 'components/Image';
import MapBlock from 'components/MapBlock';
import { AppContext } from 'components/Context/AppContext';
import './style.scss';

/**
 * @param {String} time at which the location was captured (at time of check in)
 * @param {String} image S3-secure image URL
 * @param {Object} meta spread to ...rest
 * @returns {Node} `ImageBlock` component instantiated by `CardBlock` if type is `image`
 */

const ImageBlock = ({ time, image, pos }) => {
  const { handleLightbox } = useContext(AppContext);

  return (
    <div className="check-block">
      {time && image ? (
        <div className="image-block">
          <Label bold>{moment(time).format('MMMM D, YYYY, h:mm A')}</Label>
          <Image src={image} handleClick={() => handleLightbox(image)} />
        </div>
      ) : null}
      {pos ? <MapBlock pos={pos} /> : null}
    </div>
  );
};

ImageBlock.propTypes = {
  time: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  addr: PropTypes.string,
  pos: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  })
};

export default ImageBlock;
