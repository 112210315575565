import * as yup from 'yup';

import { RadioOption, RangeOption } from 'components/Clients/ClientsGet/Info/BasicInfo/Schemas/helpers';
import { ControlTypes } from 'components/Form/Model/ControlTypes';
import { FormControl, SelectControl } from 'components/Form/Model/FormControl';
import { FormGroup } from 'components/Form/Model/FormGroup';
import ControlConfig from 'components/Form/Model/ControlConfig';
import { ListControl } from 'components/Form/Model/ListControl';
import NumberConfig from 'components/Form/Model/NumberConfig';

export class FieldBuilder {
  _subKey = null;
  _disabled = null;
  fields = [];

  _nameFactory = (n) => n
  _nameFactories = []

  constructor() {
  }

  pushNameFactory(func) {
    this._nameFactories.push(this._nameFactory)
    this._nameFactory = func
  }
  popNameFactory() {
    this._nameFactory = this._nameFactories.pop()
  }

  subKey(value) {
    this._subKey = value;
  }

  disabled(func) {
    this._disabled = func;
  }

  _configure(control) {
    if (this._disabled && typeof this._disabled === 'function') {
      control.disabled = this._disabled();
    }
    control.subKey = this._subKey;

    this.fields.push(control);
    return control;
  }

  _control(name, label, type, props) {
    const control = new FormControl(name, label, type, props)
    return this._configure(control)
  }

  _selectConfig(name, label, options, props) {
    const control = new SelectControl(name, label, options, props)
    return this._configure(control)
  }

  input(name, label) {
    return this._control(name, label, ControlTypes.generic())
  }

  select(name, label, options, props) {
    return this._selectConfig(name, label, options, props);
  }

  multiSelect(name, label, options, props) {
    return this.select(name, label, options, props)
      .allowMultiple();
  }

  date(name, label, props) {
    return this._control(name, label, ControlTypes.date(), props);
  }

  options(...names) {
    return names.sort().map(n => new RadioOption(n, n, null));
  }

  radioOptions(...names) {
    return names.map((n, i) => new RadioOption(n.toString(), i, null));
  }

  yesNoOptions() {
    return [
      new RadioOption('No', 0),
      new RadioOption('Yes', 1)
    ];
  }

  optionRange(min, max) {
    const options = [];
    for (let i = min; i <= max; i++) {
      options.push(new RadioOption(i, i, null));
    }
    return options;
  }

  textarea(name, label) {
    return this._control(name, label, ControlTypes.textarea());
  }

  text(name, label, props) {
    return this._control(name, label, ControlTypes.text(), props);
  }

  radioGroup(name, label, options) {
    return this._control(name, label, ControlTypes.radioGroup(), {
      groupLabel: label,
      options
    });
  }

  percentage(name, label) {
    return this.number(name, label, 0, 100)
  }
  number(name, label, min, max) {
    let config
    if (min instanceof ControlConfig)
      config = min
    else
      config = new NumberConfig(min, max)
    return this._control(name, label, config, {
      inputProps: {
        type: 'number',
        min: config.min,
        max: config.max
      }
    });
  }

  quantitative(name, label, ...ranges) {
    const options = []
    const map = {}
    for (const range of ranges) {
      for (let i = range.min; i <= range.max; i++) {
        const option = new RangeOption(i.toString(), i, range)
        options.push(option)
        map[option.value] = option
      }
    }
    const control = this.select(name, label, options)
    control.optionMap = map
    return control
  }

  toggle(name, label) {
    return this._control(name, label, ControlTypes.toggle())
  }

  list(name, label, schema) {
    return this._configure(new ListControl(name, label, schema))
  }

  build() {
    const group = new FormGroup()
    const spec = {}
    for (const child of this.fields) {
      group.controls[child.name] = child
      if (!child.config?.spec)
        throw new Error(`[${child.name}]: ${FormGroup.name} controls must have a yup spec`)
      spec[child.name] = child.config.spec
      child.formGroup = group
    }
    group.spec = yup.object(spec)
    return group
  }
}