import React from 'react';
import Select from 'react-select';
import InfoBlockRow from '../../InfoBlockRow.js';
import '../../style.scss';

const ResultDataBox = ({ resultData }) => {
  resultData = JSON.parse(resultData);
  const result =
    resultData.overall_result.charAt(0).toUpperCase() +
    resultData.overall_result.slice(1);
  return (
    <>
      <div className="vc-container__form">
        <h3 className="header-text">Result</h3>
        <Select
          isDisabled={true}
          defaultInputValue={result}
          theme={theme => ({
            ...theme,
            borderRadius: 2,
            colors: {
              ...theme.colors,
              text: '#2f3d49',
              primary25: '#f4f4f4',
              primary: '#ccc'
            }
          })}
        ></Select>
      </div>
      <div display="table" className="vc-container__info-block">
        {resultData && resultData.data
          ? resultData.data.map(assay => {
              return [
                {
                  title: 'ASSAY IN RANGE',
                  value: (
                    <div className="assay-range assay-in-range">
                      <span className="result">{Number(assay.result)}</span>
                      <span className="result-bar">
                        <span
                          className="result-score"
                          style={{
                            width:
                              (Number(assay.result) /
                                Number(assay.upper_cutoff_limit)) *
                              100
                          }}
                        ></span>

                        <span className="result-background-bar"></span>
                      </span>
                      <span className="max">
                        {Number(assay.upper_cutoff_limit)}
                      </span>
                    </div>
                  ),
                  render:
                    Number(assay.result) < Number(assay.upper_cutoff_limit)
                },
                {
                  title: 'ASSAY NOT IN RANGE',
                  value: (
                    <div className="assay-range assay-out-of-range">
                      <span className="result">{Number(assay.result)}</span>
                      <span className="result-bar">
                        <span className="result-score"></span>
                        <span className="result-background-bar"></span>
                      </span>
                      <span className="max">
                        {Number(assay.upper_cutoff_limit)}
                      </span>
                    </div>
                  ),
                  render:
                    Number(assay.result) >= Number(assay.upper_cutoff_limit)
                }
              ].map((info, j) => {
                return (
                  !!info.render && (
                    <InfoBlockRow
                      key={`${assay.assay_name}-${j}`}
                      title={assay.assay_name}
                      value={info.value}
                    />
                  )
                );
              });
            })
          : null}
      </div>
    </>
  );
};

export default ResultDataBox;
