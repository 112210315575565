/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * Internal dependencies
 */
import Label from 'components/Label';
import CustomSelect from 'components/CustomSelect';
import {
  getYearsOptions,
  getMonthsOptions,
  getDaysInMonthOptions
} from './helpers';
import './style.scss';
import { useDob } from './hooks';

const DOBWidget = ({
  label,
  name,
  error,
  value,
  blockGroup,
  handleCustomSelectChange,
  setErrorsCallback
}) => {
  const [day, setDay, month, setMonth, year, setYear, hasError] = useDob(value);

  const handleDobChange = ({ target: { name: part, value: newVal } }) => {
    switch (part) {
      case 'day':
        {
          setDay(newVal);
        }
        break;
      case 'month':
        {
          setMonth(newVal);
        }
        break;
      case 'year':
        {
          setYear(newVal);
        }
        break;
    }
    const newDob = `${part === 'month' ? newVal : month ? month : '01'}-${
      part === 'day' ? newVal : day ? day : '01'
    }-${part === 'year' ? newVal : year ? year : moment().format('YYYY')}`;

    handleCustomSelectChange({ target: { name, value: newDob } }, blockGroup);
    setErrorsCallback(name, blockGroup, hasError);
  };

  const daysInMonthOptions = getDaysInMonthOptions(month, year);

  return (
    <>
      <Label htmlFor={name} bold>
        {label}
      </Label>
      {!!error && (
        <Label htmlFor={name} alert>
          {error}
        </Label>
      )}
      <div className="dob-container">
        <CustomSelect
          handleCustomSelectChange={handleDobChange}
          blockGroup={blockGroup}
          dob
          placeholder="Month"
          name="month"
          value={!value ? '' : month || ''}
          options={getMonthsOptions()}
        />
        <CustomSelect
          handleCustomSelectChange={handleDobChange}
          blockGroup={blockGroup}
          dob
          placeholder="Day"
          name="day"
          value={!value ? '' : day || ''}
          options={daysInMonthOptions}
          disabled={!month && !year}
        />
        <CustomSelect
          handleCustomSelectChange={handleDobChange}
          blockGroup={blockGroup}
          dob
          placeholder="Year"
          name="year"
          value={!value ? '' : year || ''}
          options={getYearsOptions()}
          disabled={!month}
        />
      </div>
    </>
  );
};

DOBWidget.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blockGroup: PropTypes.string,
  handleCustomSelectChange: PropTypes.func.isRequired
};

export default DOBWidget;
