import toast from 'react-hot-toast';
import React from 'react';

const MEDIUM_WIDTH = 735;
const LARGE_WIDTH = 1172;
const SETTINGS_WIDTH = 1230;
const THIRTY_SECONDS = 30000;

// amount of months forward the calendar will go
const MAX_MONTHS = 5;

const CLIENT_PREFIXES = {
  id: 'id',
  client_id: 'cid',
  active: 'a',
  first_name: 'f',
  last_name: 'l',
  testing_profile: 'tp',
  calendar: 'ca',
  call_in_window: 'w',
  client_group: 'cg',
  case_manager: 'cm',
  app_status: 'app_status',
  status: 'status',
};

// AUTH0 Constants (CLIENT_ID is for 'call2test' client)
const AUTH0_CLIENT_ID = '8ZK25ihb95Ind74fu6Fwx6S6GZJ6FHhK';
const AUTH0_URL = 'login.reconnectmanager.com';

// Constants defined in webpack...because...
const API_URL_PREFIX = 'undefined' === typeof WEBPACK_API_URL_PREFIX ? 'http://localhost/' : WEBPACK_API_URL_PREFIX; // eslint-disable-line
const REDIRECT_URL = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/dashboard'; // eslint-disable-line

const AUTH0_OPTIONS = {
  redirectUrl: REDIRECT_URL,
  redirect: true,
  responseType: 'token',
  params: {
    scope: 'openid email user_id username'
  },
  avatar: null,
  theme: {
    logo: '/static/img/dark-reconnect-logo.svg',
    primaryColor: '#003a5c'
  },
  allowSignUp: false,
  autofocus: true,
  usernameStyle: 'username',
  rememberLastLogin: false,
  closable: false,
  configurationBaseUrl: 'https://cdn.auth0.com'
};

const OPENTOK_API_KEY =
  // eslint-disable-rule no-undef
  ENVIRONMENT === 'local' || ENVIRONMENT === 'development'
    ? '46623572'
    : ENVIRONMENT === 'staging'
    ? '46747802'
    : '46573692';

const STREAK_TITLE_OPTIONS = {
  substanceFree: { value: 'Substance-free', label: 'Substance-free' },
  sober: { value: 'Sober', label: 'Sober' },
  inTreatment: { value: 'In treatment', label: 'In treatment' },
  phaseOne: { value: 'In Phase 1', label: 'In Phase 1' },
  phaseTwo: { value: 'In Phase 2', label: 'In Phase 2' },
  phaseThree: { value: 'In Phase 3', label: 'In Phase 3' },
  phaseFour: { value: 'In Phase 4', label: 'In Phase 4' },
  phaseFive: { value: 'In Phase 5', label: 'In Phase 5' },
  alcoholFree: { value: 'Alcohol-free', label: 'Alcohol-free' },
  opioidFree: { value: 'Opioid-free', label: 'Opioid-free' },
  onMedication: { value: 'On medication(s)', label: 'On medication(s)' },
  mat: { value: 'In MAT', label: 'In MAT' }
};

const environment = {
  showDetailedErrors: ENVIRONMENT === 'local' || window.location.hostname.includes('localhost')
};

const errorToast = error => {
  if (!environment.showDetailedErrors) return;

  let message;
  if (error.status) {
    message = error.error;
  } else {
    message = typeof error === 'string' ? error : error.stack;
  }
  const duration = 5000

  if (typeof message === 'string' && message.startsWith('\n<div')) {
    toast.custom(
      <div>
        <div dangerouslySetInnerHTML={{ __html: message }}></div>
      </div>,
      { duration: duration }
    );
  } else {
    const text = typeof message === 'string' ? message : JSON.stringify(message);
    toast.error(text, { duration: duration, style: { background: '#FFF0F0', width: '32rem' } });
  }
};

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'hh:mm:ss A';
const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
const DB_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export {
  AUTH0_CLIENT_ID,
  AUTH0_OPTIONS,
  AUTH0_URL,
  MEDIUM_WIDTH,
  LARGE_WIDTH,
  MAX_MONTHS,
  CLIENT_PREFIXES,
  SETTINGS_WIDTH,
  API_URL_PREFIX,
  REDIRECT_URL,
  OPENTOK_API_KEY,
  THIRTY_SECONDS,
  STREAK_TITLE_OPTIONS,
  environment,
  errorToast,
  DATE_FORMAT,
  TIME_FORMAT,
  DATETIME_FORMAT,
  DB_TIMESTAMP_FORMAT
};
