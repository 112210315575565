/**
 * Internal dependencies
 */
import { loadPending, loadSuccessful } from './helperActions';
import { catchErrors, requestGetCurfewEvents, requestGetEventsByClient } from 'utils/apiHelpers';

export function getCurfewEvents(startDate, endDate, exclude, callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_CURFEW_EVENTS'));
    requestGetCurfewEvents(startDate, endDate, exclude)
      .then(res => {
        dispatch(loadSuccessful('GET_CURFEW_EVENTS'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getCurfewEventsByClients(startDate, endDate, exclude, clientIds, callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_CURFEW_EVENTS_BY_CLIENTS'));
    requestGetCurfewEvents(startDate, endDate, exclude, clientIds)
      .then(res => {
        dispatch(loadSuccessful('GET_CURFEW_EVENTS_BY_CLIENTS'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getEventsByClient(client_id, startDate, endDate, exclude, callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_EVENTS_BY_CLIENT'));

    requestGetEventsByClient(client_id, startDate, endDate, exclude)
      .then(res => {
        dispatch(loadSuccessful('GET_EVENTS_BY_CLIENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}
