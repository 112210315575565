import { loadPending, removePending, loadSuccessful } from './helperActions';
import { getWindowReport } from './windowReportAction';
import { getTodaysReport } from './todaysReportAction';
import isScheduled from 'utils/isScheduled';

import { getDateInfo, catchErrors } from 'utils/apiHelpers';

export function getTodaysDate(
  date,
  todaysDate,
  todaysReport,
  windowReport = null
) {
  const todaysDateHash = todaysDate.doneInitialLoad && todaysDate.hash;
  const todaysReportHash = todaysReport.doneInitialLoad && todaysReport.hash;
  const windowReportHash =
    null === windowReport
      ? null
      : windowReport.doneInitialLoad && windowReport.hash;

  return dispatch => {
    // set to pending
    dispatch(loadPending('TODAYS_DATE'));

    if (todaysDateHash) {
      getDateInfo(date, todaysDateHash)
        .then(res => {
          if (res.data.hash === todaysDateHash) {
            dispatch(removePending('TODAYS_DATE'));
          } else {
            dispatch(loadSuccessful('TODAYS_DATE', res.data));
          }
        })
        .catch(catchErrors);
      if (todaysDate.date && isScheduled(todaysDate.date.schedule)) {
        dispatch(getTodaysReport(date, todaysReportHash));
        if (null !== windowReportHash) {
          dispatch(getWindowReport(date, windowReportHash));
        }
      }
    } else {
      getDateInfo(date)
        .then(res => {
          dispatch(loadSuccessful('TODAYS_DATE', res.data));
          if (res.data.status === 'success' && isScheduled(res.data.schedule)) {
            dispatch(getTodaysReport(date, todaysReportHash));
            if (null !== windowReportHash) {
              dispatch(getWindowReport(date, windowReportHash));
            }
          }
        })
        .catch(catchErrors);
    }
  };
}
