const updateNotifications = (list, payload) => {
  const idx = list.findIndex(calendar => calendar.id === payload.id);
  return [
    ...list.slice(0, idx),
    // the part we actually want to update
    {
      ...list[idx],
      notifications: {
        call_report: payload.reportsNotifications,
        test_schedule: payload.scheduleNotifications
      },
      pending: payload.pending
    },
    ...list.slice(idx + 1)
  ];
};

const updateInfo = (list, payload) => {
  const idx = list.findIndex(calendar => calendar.id === payload.id);
  let newList = [
    ...list.slice(0, idx),
    {
      ...list[idx],
      name: payload.name,
      default: payload.default,
      pending: payload.pending
    },
    ...list.slice(idx + 1)
  ];

  // we have to make sure everything else isn't default
  if (payload.default) {
    return newList.map(calendar => {
      calendar.default = calendar.id === payload.id;
      return calendar;
    });
  }

  return newList;
};

export default function calendars(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_CALENDARS':
      return {
        ...state,
        pending: true
      };
    case 'REMOVE_PENDING_CALENDARS':
      return {
        ...state,
        pending: false
      };
    case 'LOAD_SUCCESSFUL_CALENDARS':
      return {
        doneInitialLoad: true,
        pending: false,
        list: action.payload.calendars,
        hash: action.payload.hash
      };
    case 'UPDATE_INFO_CALENDAR':
      return {
        ...state,
        list: updateInfo(state.list, action.payload)
      };
    case 'UPDATE_NOTIFICATIONS_CALENDAR':
      return {
        ...state,
        list: updateNotifications(state.list, action.payload)
      };
  }
  return state;
}
