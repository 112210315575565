class Resource {
  label = '';
  value = null;
  isCurrent = false;

  constructor(resource, curriculum_resource_ids, isDuplicate) {
    this.label = resource.label;
    this.value = resource.value;

    this.#setIsCurrent(curriculum_resource_ids, isDuplicate);
  }

  #setIsCurrent(curriculum_resource_ids, isDuplicate) {
    if (!isDuplicate) {
      this.isCurrent = curriculum_resource_ids.includes(this.value);
    }
  }
}

class Packet {
  id = null;
  title = '';
  curriculum_resource_ids = null;
  new_curriculum_resource_ids = null;

  constructor(id, title, curriculum_resource_ids = null, new_curriculum_resource_ids = null) {
    this.id = id;
    this.title = title;
    this.curriculum_resource_ids = curriculum_resource_ids;
    this.new_curriculum_resource_ids = new_curriculum_resource_ids;
  }
}

export default class PacketDrawerManager {
  packet = {};
  views = {};
  resources = [];
  resourceIdsField = 'curriculum_resource_ids';
  options = [];
  current = [];
  formikInitialValues = {};

  constructor(packet, resources, views) {
    this.packet = new Packet(packet?.id, packet?.title ?? '', packet?.curriculum_resource_ids ?? []);
    this.views = views;
    this.resources = resources.map(resource => new Resource(resource, this.packet.curriculum_resource_ids, this.views.isDuplicate));
  }

  init() {
    this.#setResourceIdsField();
    this.#setResourceOptions();
    this.#setPreviousResources();
    this.#setFormikValues();

    return this;
  }

  #setResourceIdsField() {
    if (this.views.isEdit) {
      this.resourceIdsField = 'new_curriculum_resource_ids';
    } else {
      this.resourceIdsField = 'curriculum_resource_ids';
    }
  }

  #setResourceOptions() {
    if (this.views.isNew) {
      this.options = this.resources;
    } else {
      this.options = this.resources.filter(resource => !resource.isCurrent);
    }
  }

  #setPreviousResources() {
    if (this.views.isNew) {
      this.current = [];
    } else {
      this.current = this.resources.reduce((filtered, resource) => {
        if (resource.isCurrent) {
          return [...filtered, resource.label];
        }

        return filtered;
      }, []);
    }
  }

  #setFormikValues() {
    if (this.views.isEdit) {
      this.formikInitialValues = new Packet(this.packet.id, this.packet.title, this.packet.curriculum_resource_ids, []);
    } else if (this.views.isView) {
      this.formikInitialValues = this.packet;
    } else if (this.views.isDuplicate) {
      this.formikInitialValues = new Packet(null, this.packet.title, this.packet.curriculum_resource_ids, null);
    } else {
      this.formikInitialValues = new Packet(null, '', [], null);
    }
  }
}