import React, { useContext, useMemo } from 'react';
import { When } from 'react-if';

import { AppContext } from 'components/Context/AppContext';

const FeatureRestricted = ({ features, children }) => {
  const { hasAccessTo } = useContext(AppContext);

  const condition = useMemo(() => {
    if (Array.isArray(features)) {
      return features.every(f => hasAccessTo(f) || ('boolean' === typeof f && !!f));
    }

    if ('string' === typeof features) {
      return hasAccessTo(features) || ('boolean' === typeof features && !!features);
    }

    if ('boolean' === typeof features) {
      return !!features;
    }

    return true;
  }, [hasAccessTo, features]);

  return (
    <When condition={condition}>
      {children}
    </When>
  );
}

export default FeatureRestricted;