import { short_date_format } from '../../../lib/util';

export const hydrate_client_initiated_form = todo => {
  const date = short_date_format(todo.created_at, true);
  const client_name = `${todo.client.first_name} ${todo.client.last_name}`;
  const form_name = todo.form_response.form.name;
  const form_status = {
    class: 'open',
    label: 'Open'
  };
  return { ...todo, client_name, date, form_name, form_status };
};

