/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import SingleTestPeriod from './SinglePeriodTest';

const PeriodTests = ({
  selectedProfile: { tests, type },
  saveTest,
  removeTest,
  needsSave = false,
  testTypes,
  hasRandMinDaysBetween,
  hasRandMaxDaysBetween,
  errors
}) => {
  const testIDs = Object.keys(tests);

  if (!testIDs.length) return null;

  return (
    <div className="tests-container">
      {testIDs.map(
        id =>
          !isNaN(id) && (
            <SingleTestPeriod
              errors={
                errors.tests && errors.tests.periodTests
                  ? errors.tests.periodTests
                  : false
              }
              key={`period-test-${id}`}
              saveTest={saveTest}
              removeTest={removeTest}
              hasMinDays={hasRandMinDaysBetween}
              hasMaxDays={hasRandMaxDaysBetween}
              id={+id}
              type={type}
              weekly={type === 'period_weekly'}
              testTypes={testTypes}
              needsSave={needsSave}
              {...tests[id]}
            />
          )
      )}
    </div>
  );
};

PeriodTests.propTypes = {
  selectedProfile: PropTypes.shape({
    tests: PropTypes.object,
    type: PropTypes.string
  }).isRequired,
  testIDs: PropTypes.array.isRequired,
  testTypes: PropTypes.array.isRequired,
  saveTest: PropTypes.func.isRequired,
  removeTest: PropTypes.func.isRequired,
  hasRandMinDaysBetween: PropTypes.bool,
  hasRandMaxDaysBetween: PropTypes.bool
};

export default PeriodTests;
