import React from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';

const BaselineModal = ({ isOpen, closeModal, onConfirm, header, children }) => {
  return isOpen ? (
    <Modal
      isShowing={isOpen}
      enableClose
      closeModal={closeModal}
      header={header}
    >
      <p className="modal-message">{children}</p>
      <p className="modal-message">
        This action cannot be undone.
      </p>
      <div className="action-buttons">
        <Button primary handleButtonClick={onConfirm}>
          Confirm
        </Button>
        <Button primary transparent handleButtonClick={closeModal}>
          Cancel
        </Button>
      </div>
    </Modal>
  ) : null;
}

export default BaselineModal;