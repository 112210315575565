/**
 * External dependencies
 */
import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';

/**
 * Internal dependencies
 */
import ToastContext from './context';
import Toast from './Toast';
import createId from './helpers';
import { removeToastByIndex } from 'actions/actionCreators';
import './style.scss';

function ToastProvider({ children, ...props }) {
  const [toasts, setToasts] = useState([]);

  const callbackRef = useRef(null);

  useEffect(() => {
    if (props.toasts?.length && !toasts.length) {
      // specifically from `redux` store
      addToast(props.toasts[0], true);
      // remove the toast from `redux` almost immediately
      setTimeout(() => props?.removeToastByIndex(0), 100);
    }

    return () => {
      if (props.toasts?.length) {
        for (let i = 0; i < props.toasts.length; i++) {
          props?.removeToastByIndex?.(i);
        }
      }

      if (toasts.length) {
        setToasts([]);
      }
    };
  }, [props.toasts, toasts]);

  const addToast = useCallback((content, limit) => {
    const id = createId();

    if (limit) {
      setToasts([{ id, content }]);
    } else {
      setToasts(prev => [...prev, { id, content }]);
    }
  }, []);

  const removeToast = useCallback(id => {
    setToasts(prev => prev.filter(t => t.id !== id));

    if ('function' === typeof callbackRef.current) {
      callbackRef.current();
    }
  }, []);

  const setCallbackRef = useCallback(callback => {
    if ('function' === typeof callback) {
      callbackRef.current = callback;
    }
  }, []);

  const resetCallbackRef = useCallback(() => {
    callbackRef.current = null;
  }, []);

  const values = useMemo(() => {
    return { addToast, removeToast, setCallbackRef, resetCallbackRef };
  }, [addToast, removeToast, setCallbackRef, resetCallbackRef]);

  return (
    <ToastContext.Provider value={values}>
      {children}
      {createPortal(
        <div
          className={classnames('toasts-wrapper', {
            top: toasts.some(t => t?.content?.position && t.content.position === 'top')
          })}
        >
          {toasts.map(t => (
            <Toast key={t.id} remove={() => removeToast(t.id)}>
              {t.content}
            </Toast>
          ))}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
}

export default connect(({ toasts }) => ({ toasts }), { removeToastByIndex })(ToastProvider);
