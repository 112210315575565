/**
 * External dependencies
 */
import React, { useContext } from 'react';

/**
 * Internal dependencies
 */
import FormContext from 'components/FormProvider/FormContext';
import { getToRender } from 'components/FormProvider/utils';
import renderAddress from 'utils/renderAddress';
import Icon from 'components/Icon';
import './style.scss';

// eslint-disable-next-line no-unused-vars
const EditLink = ({ valueMethod, callback, ...props }) => {
  const { getStateValue, getOptions } = useContext(FormContext);

  const value = valueMethod(getStateValue, getOptions);

  if (!value) return null;

  const toRender = props.renderChecks
    ? getToRender(
        props.renderChecks,
        props.blockGroup ? props.blockGroup : false,
        getStateValue
      )
    : true;

  return (
    toRender && (
      <span
        className="edit-link"
        onClick={() => callback?.(value[props.callbackKey])}
      >
        <>
          {renderAddress(value[props.searchKey])}&nbsp;
          <Icon name="edit" />
        </>
      </span>
    )
  );
};

export default EditLink; // need to make this an actual link w/ the edit SVG
