import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import moment from 'moment';
import { orderBy, uniqBy } from 'lodash';

import useList from 'hooks/useList';
import useNotes from 'hooks/useNotes';
import useClientNotes from 'hooks/useClientNotes';
import { AppContext } from 'components/Context/AppContext';

export const NotesContext = createContext({});

function _days(date, list) {
  const notes = [];

  for (const note of list) {
    if (moment(date).isSame(note.date, 'day')) {
      notes.push(note);
    }
  }

  return notes;
}

function _error(action) {
  return `Failed to ${action} note. Please, try again.`;
}

const NotesProvider = ({ clientId, children, date }) => {
  const { setAppError } = useContext(AppContext);
  const { get, add, update } = useClientNotes(clientId);
  const [list, { set, push, unshift, remove, upsert, clear }] = useList();
  const {
    note,
    setState,
    isLoading,
    setIsLoading,
    isSaving,
    setIsSaving,
    enableEdit,
    handleChange,
    startEdit
  } = useNotes();

  useEffect(() => {
    get()
      .then(notes => {
        const items = notes.map(n => ({ ...n, id: +n.id, client_id: +n.client_id }));
        set(date ? _days(date, items) : items);
      })
      .catch(() => setAppError(_error('fetch notes')))
      .finally(() => setIsLoading(false));
  }, [date]);

  const handleUpdateNote = useCallback(
    async (note, index) => {
      return update(note)
        .then(n => {
          if (!n) return;

          upsert(index, n);
        })
        .catch(() => setAppError(_error('update note')));
    },
    [update, upsert, setAppError]
  );

  const handleAddNote = useCallback(
    e => {
      e.preventDefault();

      const newNote = note.note.trim();

      if (newNote === '') return;

      setIsSaving(true);

      add(newNote, note.date)
        .then(n => push(n))
        .catch(() => setAppError(_error('save note')))
        .finally(() => {
          setIsSaving(false);
          setState(prev => ({ ...prev, note: '', date: null }));
        });
    },
    [note, push, add, setAppError]
  );

  const notes = useMemo(() => {
    const base = list.map(n => ({
      ...n,
      client_id: +n.client_id,
      id: +n.id,
      history: Array.isArray(n.history) ? orderBy(n.history, 'updated', 'desc') : []
    }));
    return orderBy(uniqBy(base, 'id'), 'date', 'desc');
  }, [list]);

  return (
    <NotesContext.Provider
      value={{
        list: notes,
        note,
        get,
        add,
        update,
        setState,
        isLoading,
        isSaving,
        setIsSaving,
        set,
        push,
        unshift,
        remove,
        upsert,
        clear,
        startEdit,
        enableEdit,
        handleChange,
        handleUpdateNote,
        handleAddNote
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

export default NotesProvider;
