import React from 'react';
import Sound from 'react-sound';
window.soundManager.setup({ debugMode: false });
import Icon from '../../Icon';

const Recording = ({
  togglePlay,
  stopSound,
  checkUrl,
  fromPhone,
  loadingStatus,
  playingSound
}) => {
  return (
    <div className="recording-container">
      {fromPhone && <span className="from-phone">{fromPhone}</span>}
      &nbsp;
      <a
        onClick={togglePlay}
        href={checkUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={`recording-container__button${
          playingSound ? ' playing' : ''
        }`}
      >
        <Icon name="play" />
        {playingSound && (
          <Sound
            url={checkUrl}
            playStatus={Sound.status.PLAYING}
            onLoading={loadingStatus}
            onFinishedPlaying={stopSound}
          />
        )}
      </a>
    </div>
  );
};

export default Recording;
