/**
 * Internal dependencies
 */
import { loadPending, loadSuccessful } from './helperActions';
import {
  catchErrors,
  requestCreateFacilityProgram,
  requestFacilityPrograms,
  requestRemoveFacilityProgram,
  requestUpdateFacilityProgram,
  requestGetClientSubscriptions,
  requestGetClientSubscriptionsByFacility
} from 'utils/apiHelpers';

export function getFacilityPrograms() {
  return dispatch => {
    dispatch(loadPending('GET_FACILITY_PROGRAMS'));

    return requestFacilityPrograms()
      .then(res => dispatch(loadSuccessful('GET_FACILITY_PROGRAMS', res)))
      .catch(catchErrors);
  };
}

export function removeFacilityProgram(programId) {
  return dispatch => {
    dispatch(loadPending('REMOVE_FACILITY_PROGRAM'));

    return requestRemoveFacilityProgram(programId)
      .then(() => dispatch(loadSuccessful('REMOVE_FACILITY_PROGRAM')))
      .then(getFacilityPrograms)
      .catch(catchErrors);
  };
}

export function updateFacilityProgram(id, data) {
  return dispatch => {
    dispatch(loadPending('UPDATE_FACILITY_PROGRAM'));

    return requestUpdateFacilityProgram(id, data)
      .then(() => dispatch(loadSuccessful('UPDATE_FACILITY_PROGRAM')))
      .then(getFacilityPrograms)
      .catch(catchErrors);
  };
}

export function createFacilityProgram(id, data) {
  return dispatch => {
    dispatch(loadPending('CREATE_FACILITY_PROGRAM'));

    return requestCreateFacilityProgram(id, data)
      .then(() => dispatch(loadSuccessful('CREATE_FACILITY_PROGRAM')))
      .then(getFacilityPrograms)
      .catch(catchErrors);
  };
}

export function getClientSubscriptions(client_id) {
  return dispatch => {
    dispatch(loadPending('GET_CLIENT_SUBSCRIPTIONS'));

    return requestGetClientSubscriptions(client_id)
      .then(res => {
        dispatch(loadSuccessful('GET_CLIENT_SUBSCRIPTIONS', { ...res }));
      })
      .catch(catchErrors);
  };
}

export function resetClientSubscriptions() {
  return {
    type: 'RESET_CLIENT_SUBSCRIPTIONS'
  };
}

export function getClientSubscriptionsByFacility(callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_CLIENT_SUBSCRIPTIONS'));

    return requestGetClientSubscriptionsByFacility()
      .then(res => {
        dispatch(
          loadSuccessful('GET_CLIENT_SUBSCRIPTIONS_BY_FACILITY', { ...res })
        );
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}
