import React, { Component } from 'react';
import classnames from 'classnames';

import Icon from 'components/Icon';

export default class ActionButtons extends Component {
  handleClick = e => {
    const action = e.currentTarget.getAttribute('data-action');
    this.props.handleUpdateViolation(action);
  };
  render() {
    const { drawerOpen, status } = this.props;
    return (
      <div
        className={classnames('vc-container__action-buttons', {
          'drawer-open': drawerOpen
        })}
      >
        <button
          aria-disabled={status === 'excused'}
          disabled={status === 'excused'}
          type="button"
          data-action="excused"
          className={classnames('ab-excuse', {
            'ab-disabled': status === 'excused' || status === 'active'
          })}
          onClick={this.handleClick}
        >
          <Icon name="checkmark" size="20" />
          &nbsp;
          <span className="ab-status">
            {status === 'excused' ? 'Excused' : 'Excuse'}
          </span>
        </button>
        <button
          aria-disabled={status === 'confirmed'}
          disabled={status === 'confirmed'}
          type="button"
          data-action="confirmed"
          className={classnames('ab-confirm', {
            'ab-disabled': status === 'confirmed' || status === 'active'
          })}
          onClick={this.handleClick}
        >
          <Icon name="crossmark" size="20" />
          <span className="ab-status">
            &nbsp;{status === 'confirmed' ? 'Confirmed' : 'Confirm'}
          </span>
        </button>
        <button
          aria-disabled={status === 'open'}
          disabled={status === 'open'}
          type="button"
          data-action="open"
          className={classnames('ab-open', {
            'ab-disabled': status === 'open' || status === 'active'
          })}
          onClick={this.handleClick}
        >
          <Icon name="warning" size="20" />
          <span className="ab-status">
            &nbsp;
            {status === 'open' || status === 'active' ? 'Open' : 'Reopen'}
          </span>
        </button>
      </div>
    );
  }
}
