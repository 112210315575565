import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleContextualMenu, toggleMobileMenu } from 'actions/actionCreators';
import SideNavToggle from './SideNavToggle';
import SideNavDashboard from './SideNavDashboard';
import SideNavViolations from './SideNavViolations';
import SideNavMessages from './SideNavMessages';
import SideNavClients from './SideNavClients';
import SideNavCalendar from './SideNavCalendar';
import SideNavEvents from './SideNavEvents';
import SideNavForms from './SideNavForms';
import SideNavBenchmarks from './SideNavBenchmarks';
import SideNavDocuments from './SideNavDocuments';
import SideNavReports from './SideNavReports';
import SideNavCurriculum from './SideNavCurriculum';
import SideNavSettings from './SideNavSettings';
import SideNavSupport from './SideNavSupport';
import useMobileContext from './useMobileContext';
import './style.scss';

const SideNav = ({ pathname, calendar, toggleContextualMenu, toggleMobileMenu }) => {
  useMobileContext();

  return (
    <div className="sidenav" data-equalizer-watch>
      <SideNavToggle
        pathname={pathname}
        toggleMobileMenu={toggleMobileMenu}
        toggleContextualMenu={toggleContextualMenu}
      />
      <ul className="sidenav__top">
        <SideNavDashboard />
        <SideNavViolations pathname={pathname} />
        <SideNavMessages pathname={pathname} />
        <SideNavClients pathname={pathname} />
        <SideNavCalendar calendar={calendar} />
        <SideNavEvents pathname={pathname} />
        <SideNavForms />
        <SideNavBenchmarks />
        <SideNavDocuments />
        <SideNavReports />
        <SideNavCurriculum pathname={pathname} />
        <SideNavSettings />
        <SideNavSupport />
      </ul>
    </div>
  );
};

SideNav.propTypes = {
  calendar: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  pathname: PropTypes.string.isRequired,
  toggleContextualMenu: PropTypes.func.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired
};

export default connect(null, { toggleMobileMenu, toggleContextualMenu })(SideNav);
