/**
 * Get the drawer props.
 *
 * @param {object} state App state tree.
 *
 * @returns {object}     Props currently set in the drawer.
 */
const getDrawerProps = state => state.drawer.drawerProps ?? {};

export const getIsDrawerOpen = state => state.drawer.drawerOpen || false;

export default getDrawerProps;
