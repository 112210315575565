import React from 'react';

const SideNavToggle = ({ pathname, toggleMobileMenu, toggleContextualMenu }) => {
  return (
    <>
      <a onClick={toggleMobileMenu} className="sidenav__toggle">
        <span></span>
        <span></span>
        <span></span>
      </a>
      {pathname.match(/^\/clients\/get/) && (
        <a onClick={toggleContextualMenu} className="sidenav__toggle sidenav__toggle--contextual">
          <span></span>
          <span></span>
        </a>
      )}
    </>
  );
};

export default SideNavToggle;
