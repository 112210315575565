/* eslint-disable no-unused-vars */
import { isString } from 'lodash';

/**
 * @description Helper to strip individual objects of private data
 * @param {String|Object} obj an object or Sentry-stripped object converted to [object Object] string
 * @returns {String|Object} either a string or an object stripped of identifiable information
 */
export const detailStripper = obj => {
  // we only send data at a normalization depth of 5,
  // so data may come in as [object Object] by this point,
  // such as the redux dashboard
  if (isString(obj) || !obj) {
    return obj;
  }

  // add strings to the following array to ensure they're stripped from the object
  for (let key of [
    'address',
    'billing_contact',
    'city',
    'state',
    'email',
    'f',
    'first_name',
    'l',
    'last_name',
    'phone',
    'username',
    'name',
    'description',
    'call_in_number',
    'title',
    'form_title',
    'internal_notes',
    'address',
    'location_name',
    'location_lat',
    'location_lng',
    'location',
    'notifications',
    'test_notes',
    'recording_url'
  ]) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      delete obj[key];
    }
  }
  return { ...obj };
};

/**
 * @description Intermediary helper that checks for types, strips top-level properties, etc.
 * @param {Mixed} object May be an array depending on the callee, but typically an object
 * @returns {Mixed} may turn a stripped array, null, or an object w/ stripped object property values
 */
const mutate = object => {
  // dashboard events is an array
  if (Array.isArray(object)) {
    return object?.map(detailStripper);
  }

  // just in case -- some redux state props don't have a default list until after load
  if (!object || !object?.list) {
    return object;
  }

  // strip out hash and list
  const { list, hash, ...rest } = object;

  return {
    ...rest,
    list: list?.map(detailStripper) ?? []
  };
};

/**
 * @description Helper to strip info from the redux dashboard property's various values
 * @param {Object} dashboard
 * @returns {Mixed} null or a stripped dashboard object
 */
const mutateDashboard = dashboard => {
  if (!dashboard || !Object.keys(dashboard).length) {
    return dashboard;
  }
  return {
    ...dashboard,
    agenda: {
      ...dashboard.agenda,
      events: mutate(dashboard?.agenda?.events)
    },
    checkInReport: {
      tests: dashboard?.checkInReport?.tests?.map(detailStripper),
      clients: dashboard?.checkInReport?.clients?.map(detailStripper)
    },
    messages: dashboard?.messages?.map(detailStripper),
    violations: dashboard?.violations?.map(detailStripper)
  };
};

/**
 * @description Helpers to strip PHI and non-essential
 * data from contexts before being sent to Sentry
 * @param {Object} event Sentry event object, containing breadcrumbs, redux state, etc.
 * @returns {Object} mutated event to send to Sentry
 *
 * @todo Confirm the dashboard structure suffices, and also that we don't need to dive
 * into the variable `drawer` values <-- using breadcrumbs and whatnot, should this by OK?
 */
export default event => ({
  ...event,
  contexts: {
    ...event.contexts,
    ['redux.state']: {
      ...event.contexts['redux.state'],
      calendars: mutate(event.contexts['redux.state'].calendars),
      dashboard: mutateDashboard(event.contexts['redux.state']?.dashboard),
      drawer: null,
      facility: detailStripper(event.contexts['redux.state'].facility),
      groups: mutate(event.contexts['redux.state'].groups),
      profiles: mutate(event.contexts['redux.state'].profiles),
      tests: mutate(event.contexts['redux.state'].tests),
      clients: mutate(event.contexts['redux.state'].clients),
      events: mutate(event.contexts['redux.state'].events),
      formEvents: mutate(event.contexts['redux.state'].formEvents),
      user: detailStripper(event.contexts['redux.state'].user),
      users: mutate(event.contexts['redux.state'].users),
      violations: mutate(event.contexts['redux.state'].violations),
      router: null
    }
  }
});
