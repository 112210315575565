/**
 * External dependencies
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Button from 'components/Button';
import './style.scss';

const WideBanner = ({ copy, clickToActionLabel, handleClickToAction }) => {
  useEffect(() => {
    document.body.classList.add('has-widebanner');
  }, []);

  return (
    <div className="widebanner">
      <span className="widebanner__copy">{copy}</span>
      <Button
        secondary
        transparent
        micro
        className="widebanner__cta"
        handleButtonClick={handleClickToAction}
      >
        {clickToActionLabel}
      </Button>
    </div>
  );
};

WideBanner.propTypes = {
  copy: PropTypes.string.isRequired,
  handleClickToAction: PropTypes.func,
  clickToActionLabel: PropTypes.string
};

export default WideBanner;
