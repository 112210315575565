/**
 * External dependencies
 */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

/**
 * Internal dependencies
 */
import { requestStates } from 'actions/actionCreators';
import getStates from 'selectors/getStates';
import isRequestingStates from 'selectors/isRequestingStates';

class QueryStates extends Component {
  static propTypes = {
    // Connected props
    states: PropTypes.object.isRequired,
    isRequestingStates: PropTypes.bool.isRequired,
    requestStates: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.request();
  }

  request = () =>
    !this.props.isRequestingStates &&
    isEmpty(this.props.states) &&
    this.props.requestStates();

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    states: getStates(state),
    isRequestingStates: isRequestingStates(state)
  };
}

const mapDispatchToProps = {
  requestStates
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueryStates);
