/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import CustomTooltip from 'components/CustomTooltip';
import './style.scss';

const Label = ({
  Component = 'label',
  htmlFor = '',
  bold,
  alert,
  children,
  mTop,
  mBottom,
  tooltip = false,
  tag: Tag = 'div',
  hovered = false,
  selected = false,
  required = false,
  containerClass,
  ...props
}) => (
  <Tag
    className={`label-container${containerClass ? ` ${containerClass}` : ''}`}
  >
    <Component
      className={classnames('base-label', {
        bold: !!bold,
        alert: !!alert,
        'm-top': !!mTop,
        'm-bottom': !!mBottom,
        hovered: !!hovered,
        selected: !!selected
      })}
      htmlFor={htmlFor}
      {...props}
    >
      {children}
      {!!required && <sup>*</sup>}
    </Component>
    {tooltip && <CustomTooltip>{tooltip}</CustomTooltip>}
  </Tag>
);

Label.propTypes = {
  htmlFor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bold: PropTypes.bool,
  alert: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  mTop: PropTypes.bool,
  mBottom: PropTypes.bool,
  tag: PropTypes.string,
  required: PropTypes.bool
};

export default Label;
