import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Icon from 'components/Icon';
import './style.scss';

const NoAccessYet = ({ redirect = '/dashboard', fallbackMessage }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const timeoutRef = useRef(null);
  const countdownRef = useRef(null);

  useEffect(() => {
    if (redirect) {
      timeoutRef.current = setTimeout(() => setShouldRedirect(true), 5000);
      countdownRef.current = setInterval(() => setCountdown(prev => prev - 1), 1000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (countdownRef.current) {
        clearInterval(countdownRef.current);
      }
    }
  }, [redirect]);

  return (
    <div>
      <div className="base-card no-access-yet">
        <p className="message">
          <Icon name="warning" />
          <span className="message__text">
            {!fallbackMessage
              ? `This is currently turned off for superadmins. Admin features will come eventually.`
              : `${fallbackMessage}. You will be redirected in ${countdown <= 0 ? 0 : countdown} seconds...`}
          </span>
        </p>
        {!!shouldRedirect && <Redirect to={redirect} />}
      </div>
    </div>
  );
}

NoAccessYet.propTypes = {
  message: PropTypes.string,
  redirect: PropTypes.string
};

export default NoAccessYet;
