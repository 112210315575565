import React, { Component } from 'react';
import classnames from 'classnames';

import Icon from 'components/Icon';

export default class ActionButtons extends Component {
  handleClick = e => {
    const action = e.currentTarget.getAttribute('data-action');
    this.props.handleUpdateTodo(action);
  };
  render() {
    const { drawerOpen } = this.props;
    return (
      <div
        className={classnames('vc-container__action-buttons', {
          'drawer-open': drawerOpen
        })}
      >
        <button
          type="button"
          data-action="approve"
          className={'ab-excuse'}
          onClick={this.handleClick}
        >
          <Icon name="checkmark" size="20" />
          &nbsp;
          <span className="ab-status">Approve</span>
        </button>
        <button
          type="button"
          data-action="dismiss"
          className={'ab-confirm'}
          onClick={this.handleClick}
        >
          <Icon name="crossmark" size="20" />
          <span className="ab-status">Disapprove</span>
        </button>
      </div>
    );
  }
}
