import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useDeepCompareEffect from 'use-deep-compare-effect';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import { generateId } from './utils';
import ActionBar, { actionButtonsType } from 'components/ActionBar';
import CardBlock from 'components/CardBlock';
import CustomPrompt from 'components/CustomPrompt';
import { useToast } from 'components/Toast';
import { ErrorModal } from 'components/Clients/ClientsGet/Info/BasicInfo/Modals/ErrorModal';
import { SchemaManager } from 'components/FormProvider/schemaManager';
import { FormControlChange, FormFieldChange } from 'components/Form/FieldWrap';
import { FormStates } from 'components/FormProvider/renderChecks';
import Loading from 'components/Helpers/Loading';
import useBackup from './useBackup';
import FormContext from './FormContext';

class AfterStateManager {
  callback;
  store(callback) {
    this.callback = callback;
  }
  call(state) {
    if (this.callback) {
      this.callback(state);
      this.callback = () => { };
    }
  }
}
const afterState = new AfterStateManager();

class FieldChange {
  constructor(event, blockGroup) {
    if (event instanceof FormFieldChange) {
      this.name = event.target.name
      this.blockGroup = event.blockGroup
      this.value = event.target.value
    } else if (event instanceof FormControlChange) {
      this.name = event.control.name
      this.blockGroup = event.control._blockKey
      this.value = event.value
      this.control = event.control
    } else {
      this.name = event.target.name
      this.value = event.target.value
      this.blockGroup = blockGroup
    }

    if (isNumber(this.value)) {
      this.value = parseInt(this.value, 10)
    }
  }
}

export function FormProvider({
  data = {},
  disableSavePrompt = false,
  schema = [],
  handleSubmit,
  onKeyPress = () => { },
  actionButtons = [],
  children,
  toasts = [],
  wrapFieldLabel = false,
  onStateChange = false,
  showModalErrors = false,
  preventDefaultSubmit = true,
  ...props
}) {
  const manager = useMemo(() => new SchemaManager(schema), [schema]);
  const [hasSubmitted, setSubmitted] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const [modalErrors, setModalErrors] = useState([]);
  const [errors, setErrors] = useState(manager.makeFormErrorsState());
  const initialState = useMemo(() => manager.makeFormState(data), [data]);
  const [state, setState] = useState({});
  const [backup, setBackup] = useBackup(state);

  useEffect(() => {
    if (isEmpty(state)) {
      setState(initialState);
    }
  }, [initialState, state, backup]);

  // for components instantiated from the Schema
  useEffect(() => {
    manager.state$.next(state);

    if (onStateChange && 'function' === typeof onStateChange) {
      onStateChange(state);
    }
  }, [state]);

  // get the toast context ready to show errors/success
  const toast = useToast();

  useEffect(() => {
    toasts.forEach(t => {
      if (t) {
        toast.addToast(t);
      }
    });
  }, [toasts]);

  useDeepCompareEffect(() => {
    setChanged(!isEqual(state, backup));
  }, [state, backup]);

  const canSave = useMemo(() => {
    return hasChanged && !errors._hasErrors;
  }, [hasChanged, errors._hasErrors]);

  const getOptions = useCallback(
    (name, blockGroup) => {
      const control = manager.getControl(SchemaManager._key(blockGroup, name))
      return control?.options || null;
    },
    [schema]
  );

  const getStateValue = useCallback((name = FormStates.hasChanged, group = false) => {
    switch (name) {
      case 'hasChanged':
        return !!hasChanged;
      case 'hasNoErrors':
        return !errors._hasErrors;
    }

    if (!group)
      return state[name];
    if (!state[name])
      return state[group][name];
    return true;
  },
    [state, hasChanged, errors]
  );

  const getBackupValue = useCallback(
    (string = '', group = false) => {
      if (!group) return backup?.[string];
      else if (!backup?.[string]) return backup?.[group]?.[string];
    },
    [backup, hasChanged]
  );

  const getValueHasChanged = useCallback(
    (name, blockGroup, idx) => {
      if (undefined === idx || null === idx) {
        return !isEqual(state[blockGroup][name], backup?.[blockGroup]?.[name]);
      }
      return !isEqual(state[blockGroup][name][idx], backup?.[blockGroup]?.[name]?.[idx]);
    },
    [state, backup]
  );

  const setErrorsCallback = useCallback(
    (name, blockGroup, error, validationField = false) => {
      const newErrors = manager.setError(errors, blockGroup, name, error);

      if (!error) {
        clearError(blockGroup, name);
        return;
      }
      if (validationField) {
        newErrors[SchemaManager._key(blockGroup, validationField)] = !!error;
      }
      setErrors(newErrors);
    },
    [manager, errors]
  );

  const clearError = useCallback(
    (blockGroup, name) => {
      const newErrors = manager.clearError(errors, blockGroup, name)
      setErrors(newErrors);
    },
    [manager, errors, setErrorsCallback]
  );

  const getErrors = useCallback(
    (name, blockGroup) => {
      const e = manager.getErrors(errors, blockGroup, name)
      if (e) return e
      if (props.errors[blockGroup] && props.errors[blockGroup][name]) {
        return props.errors[blockGroup][name];
      }

      return false;
    },
    [manager, errors, props.errors]
  );

  const canUpdate = useCallback(
    (blockGroup, name, value, subGroup = null, control = null) => {
      if (control?._rootControl?._blockKey) {
        return !isEqual(
          value,
          backup[control._rootControl._blockKey]?.[control._rootControl.name]?.[control.formGroup?.index]
        );
      }
      if (subGroup) {
        return !isEqual(value, backup?.[blockGroup]?.[subGroup]?.[name]);
      } else {
        return !isEqual(value, backup?.[blockGroup]?.[name]);
      }
    },
    [backup]
  );

  const updateStateValue = useCallback(
    (blockGroup, name, value, subGroup = null, control = null, callback = () => { }) => {
      let newState;
      if (control?._rootControl) {
        const rootControl = control._rootControl;
        const collection = state[rootControl?._block.blockGroup]?.[rootControl?.name];
        collection[control.formGroup.index][name] = value;
        newState = {
          ...state,
          [rootControl._block.blockGroup]: {
            ...state[rootControl._block.blockGroup],
            [rootControl.name]: collection,
            canUpdate: canUpdate(blockGroup, name, value, subGroup, control)
          }
        };
      } else if (subGroup) {
        newState = {
          ...state,
          [blockGroup]: {
            ...state[blockGroup],
            [subGroup]: {
              ...state[blockGroup][subGroup],
              [name]: value
            },
            canUpdate: canUpdate(blockGroup, name, value, subGroup, control)
          }
        };
      } else {
        newState = {
          ...state,
          [blockGroup]: {
            ...state[blockGroup],
            [name]: value,
            canUpdate: canUpdate(blockGroup, name, value, subGroup, control)
          }
        };
      }
      clearError(blockGroup, name);
      afterState.store(state => {
        callback(state);
      });
      setState(newState);
    },
    [state, backup, canUpdate, clearError]
  );

  useEffect(() => {
    afterState.call(state);
  }, [state]);

  const handleSetState = useCallback(
    (e, blockGroup, callback) => {
      const { name, value } = e.target;
      updateStateValue(blockGroup, name, value, null, null, newState => {
        if (callback) {
          callback(setState, newState, name, value);
        }
      });
    },
    [updateStateValue, backup]
  );

  const handleInputChange = useCallback(
    (event, maybeBlockGroup, onChange) => {
      const change = new FieldChange(event, maybeBlockGroup)

      clearError(change.blockGroup, change.name);
      updateStateValue(change.blockGroup, change.name, change.value, null, null, newState => {
        if (onChange && 'function' === typeof onChange) {
          onChange(setState, newState, change.blockGroup, change.name, change.value, setErrorsCallback);
        }
      });
      if (change.control && change.control.canValidate) {
        const validation = cloneDeep(errors)
        const didUpdate = manager.validateControl(change.control, getStateValue, change.value, validation);
        if (didUpdate) {
          setErrors(validation)
        }
      }
    },
    [updateStateValue, backup, getStateValue, errors]
  );

  const handleCheckboxToggleChange = useCallback(
    (e, blockGroup, callback) => {
      const { name, checked } = e.target;
      updateStateValue(blockGroup, name, checked, null, null, () => callback && callback(checked));
    },
    [state, backup]
  );

  const handleCheckboxGroupChange = useCallback(
    (props, blockGroup) => {
      const { checked, name, dataset } = props.target;
      const group = dataset.group;
      const control = manager.getControl(SchemaManager._key(blockGroup, group))
      const groupOptions = control.options;
      const groupValue = state[blockGroup][group];
      if (name === 'all') {
        for (const o of groupOptions) {
          groupValue[o.name] = checked;
        }
      } else {
        groupValue[name] = checked;
        if (groupValue.all !== undefined) {
          groupValue.all = true;
          for (const o of groupOptions) {
            if (o.name === 'all') continue;
            if (!groupValue[o.name]) {
              groupValue.all = false;
              break;
            }
          }
        }
      }
      updateStateValue(blockGroup, group, groupValue);
    },
    [state, backup]
  );

  const handleCustomSelectChange = useCallback(
    (event, blockGroup, callback, updateBackup, rootControl, index) => {
      const change = new FieldChange(event, blockGroup)

      let newState;
      if (rootControl) {
        const collection = state[rootControl._block.blockGroup][rootControl.name]
        collection[index][change.name] = change.value
        newState = {
          ...state,
          [rootControl._block.blockGroup]: {
            ...state[rootControl._block.blockGroup],
            [rootControl.name]: collection,
            canUpdate: true
          }
        };
      } else {
        newState = {
          ...state,
          [change.blockGroup]: {
            ...state[change.blockGroup],
            [change.name]: change.value,
            canUpdate: (!updateBackup && backup?.[change.blockGroup]) ? !isEqual(change.value, backup?.[change.blockGroup]?.[change.name]) : false
          }
        };
      }

      setState(newState);
      clearError(change.blockGroup, change.name);
      if (callback) {
        callback(setState, newState, change.name, change.value, backup);
      }
      if (updateBackup) {
        setBackup({
          ...backup,
          [change.blockGroup]: {
            ...(backup?.[change.blockGroup] ?? {}),
            [name]: change.value
          }
        });
      }
    },
    [clearError, state, backup, schema]
  );

  const handleRadioChange = useCallback(
    (value, name, blockGroup, group, control) => {
      updateStateValue(blockGroup, name, value, group, control);
    },
    [updateStateValue]
  );

  const handleLocationSearchChange = useCallback(
    (value, blockGroup, { name, onAddressUpdate }) => {
      const key = 'object' === typeof value ? 'coordinates' : 'text';
      const handleNewStateForLocation = newState => {
        // Make sure the coordinates are set, otherwise set an error
        if ('coordinates' === key) {
          if (onAddressUpdate && 'function' === typeof onAddressUpdate) {
            onAddressUpdate(setState, newState, blockGroup, name, value);
          }
          setErrorsCallback(name, blockGroup, false);
        } else {
          setErrorsCallback(name, blockGroup, value ? 'Please choose an address from the dropdown.' : false);
        }
      };
      if ('text' === key) {
        updateStateValue(blockGroup, name, value, null, null, newState => handleNewStateForLocation(newState));
      } else {
        handleNewStateForLocation(state);
      }
    },
    [state, backup, updateStateValue, setErrorsCallback]
  );

  const handleOverlayUpdate = useCallback(
    (geometry, blockGroup, { name, onOverlayUpdate }) => {
      if (!onOverlayUpdate || 'function' !== typeof onOverlayUpdate) return;
      onOverlayUpdate(setState, state, blockGroup, name, geometry, setErrorsCallback);
    },
    [state, backup, setErrorsCallback]
  );

  const handleCenterUpdate = useCallback(
    (coordinates, blockGroup, { name, onCenterUpdate }) => {
      if (!onCenterUpdate || 'function' !== typeof onCenterUpdate) return;
      onCenterUpdate(setState, state, blockGroup, name, coordinates);
    },
    [state, backup]
  );

  const checkRequiredFields = useCallback(() => {
    const validation = manager.validate(state, getStateValue, errors);
    setErrors(validation);

    if (validation?._hasErrors && validation._errorMessages?.length) {
      if (showModalErrors) {
        setModalErrors(validation._errorMessages);
      } else {
        toast.addToast({
          content: validation._errorMessages.join('\n\n'),
          type: 'warning'
        });
      }
    } else {
      setModalErrors([]);
    }
    return validation;
  }, [schema, state, getStateValue, toast.addToast]);

  const handleSubmitForm = useCallback(
    submitEvent => {
      if (preventDefaultSubmit && submitEvent) {
        submitEvent.preventDefault();
      }

      if (!handleSubmit || 'function' !== typeof handleSubmit) return Promise.resolve(false);
      const validation = checkRequiredFields();
      if (validation._hasErrors && validation._errorMessages?.length) return Promise.resolve(false);
      const result = handleSubmit(state, manager);
      setSubmitted(true);
      return result;
    },
    [checkRequiredFields]
  );

  const _makeProps = useCallback(
    (s, others) => {
      const {
        _rootControl,
        _isInput,
        _blockKey,
        subKey,
        _getValue,
        _setValue,
        requiredIf,
        canValidate,
        formGroup,
        ...domProps
      } = s

      return {
        id: generateId(),
        ...domProps,
        name: s.name,
        control: s,
        key: `${s._blockKey}.${s.name}`,
        blockGroup: s._blockKey,
        setState,
        setErrors,
        error: manager.getInputErrors(errors, s),
        ...others
      };
    },
    [state, manager, errors]
  );

  const getProps = useCallback((control) => {
    const value = control._getValue(state)
    const inputProps = _makeProps(control, {
      handleChange: e => {
        if (control._setValue) {
          control._setValue(state, e.target.value)
        }
        if (control._rootControl) {
          return handleInputChange(new FormControlChange(control._rootControl, control._rootControl._getValue(state)))
        }
        handleInputChange(e, control._blockKey, control.callback);
      },
      setErrorsCallback,
      value: value
    });

    if (control.options && control.type !== 'input') {
      inputProps.options = control.options;
    } else {
      delete inputProps.options;
    }
    if (control.validation) {
      inputProps.getStateValue = getStateValue;
    }
    return inputProps;
  },
    [handleInputChange, state, getStateValue, _makeProps, errors]
  );

  const getToggleProps = useCallback(
    s => {
      return _makeProps(s, {
        handleToggle: checked =>
          handleInputChange({ target: { name: s.name, value: checked } }, s._blockKey, s.handleChange),
        checked: !!state[s._blockKey][s.name]
      });
    },
    [_makeProps]
  );

  const getCheckboxProps = useCallback(
    s => {
      return _makeProps(s, {
        handleChange: (e, callback) => handleCheckboxToggleChange(e, s._blockKey, callback),
        checked: !!state[s._blockKey][s.name]
      });
    },
    [handleCheckboxToggleChange, state]
  );

  const getCheckboxGroupProps = useCallback(
    s => {
      return _makeProps(s, {
        options: s.options.map(o => {
          return {
            ...o,
            checked: !!state?.[s._blockKey]?.[s.name]?.[o.name] ?? false,
            'data-group': s.name,
            id: generateId()
          };
        }),
        handleChange: e => handleCheckboxGroupChange(e, s._blockKey)
      });
    },
    [state, handleCheckboxGroupChange, _makeProps]
  );

  const getRadioButtonGroupProps = useCallback(
    (s) => {
      let groupValue;
      if (s._blockKey) {
        groupValue = state[s._blockKey];
      } else if (s._rootControl) {
        groupValue = state[s._rootControl._blockKey]?.[s._rootControl.name]?.[s.formGroup?.index];
      }

      let value = undefined;
      if (groupValue) {
        value = groupValue[s.name];
        if (value && typeof value === 'object' && value[s.name]) {
          value = value[s.name];
        }
      }
      const clone = Object.assign({}, s);
      delete clone.handleChange;

      return _makeProps(clone, {
        options: s.options.map(o => {
          const option = {
            id: generateId(),
            ...o,
            name: s.name,
            blockGroup: s._blockKey,
            checked: value === o.value || value === o.name || parseInt(value) === parseInt(o.value)
          };
          return option
        }),
        handleChange: (value, _, __, group) => {
          handleRadioChange(value, s.name, s._blockKey, group, s);
          if (s.handleChange) {
            s.handleChange(value, s.name, s._blockKey, group, setErrorsCallback, s);
          }
        }
      });
    },
    [state, _makeProps]
  );

  const getCustomSelectProps = useCallback(
    s => {
      let blockState;
      if (s) {
        if (s._blockKey) {
          blockState = state[s._blockKey];
        } else {
          const collection = state[s._rootControl._blockKey][s._rootControl.name];
          if (collection.length) {
            blockState = state[s._rootControl._blockKey][s._rootControl.name][s.formGroup.index];
          }
        }

        let value = blockState ? blockState[s.name] : '';
        if (!value && s._default) {
          value = s._default.value;
        }

        const props = _makeProps(s, {
          value: value,
          options: s.options,
          setErrorsCallback,
          handleCustomSelectChange
        });

        return props;
      }

      return {};
    },
    [state, handleCustomSelectChange, _makeProps]
  );

  const getDobProps = useCallback(
    s => {
      return _makeProps(s, {
        handleCustomSelectChange,
        setErrorsCallback,
        value: state[s._blockKey][s.name]
      });
    },
    [handleCustomSelectChange, state, _makeProps]
  );

  const getMapProps = useCallback(
    s => {
      const block = state[s._blockKey];

      let config = {};
      if (s.hasLeniencePin) {
        config = {
          draw: 'address',
          editLenience: true,
          isNewLocation: true,
          fullscreenControl: false
        };
        if (block) {
          const value = block[s.name];
          if (value.coordinates) {
            config.primaryMarker = {
              title: 'Home',
              name: 'Home Address',
              icon: '/static/img/marker_green.png',
              position: value.coordinates
            };
            if (block.lenience) {
              config.circle = {
                center: value.coordinates,
                radius: block.lenience * 0.3048
              };
            }
            config.center = value.coordinates;
          }
        }
      }

      return _makeProps(s, {
        ...config,
        onOverlayUpdate: geometry => {
          handleOverlayUpdate(geometry, s._blockKey, s);
        },
        onCenterUpdate: coordinates => {
          handleCenterUpdate(coordinates, s._blockKey, s);
        }
      });
    },
    [state, handleOverlayUpdate, handleCenterUpdate, _makeProps]
  );

  const getLocationSearchProps = useCallback(
    s => {
      return _makeProps(s, {
        address: state[s._blockKey][s.name],
        onChange: address => {
          handleLocationSearchChange(address, s._blockKey, s);
        },
        handleLatLngChange: latLng => {
          handleLocationSearchChange(latLng, s._blockKey, s);
        }
      });
    },
    [state, handleLocationSearchChange, _makeProps]
  );

  const getTableRows = useCallback(
    s => {
      const block = state[s._blockKey];
      let tableData = [];
      if (block) {
        const value = block[s.name];
        tableData = value || block;
      }
      if (!s.headers?.length || !tableData.length) return [];
      const rows = [];
      for (let i = 0; i < tableData.length; i++) {
        const row = tableData[i];
        const child = [];
        rows.push(child);
        for (const h of s.headers) {
          let cell;
          const cellValue = row[h.key];
          if (h.renderLabel) {
            cell = h.renderLabel(cellValue);
          } else if (cellValue) {
            cell = cellValue;
          } else if (typeof row === 'string') {
            cell = row;
          } else {
            cell = '';
          }

          let key = undefined;
          if (s.rowClickKey) {
            key = row[s.rowClickKey];
          }
          child.push({
            cell,
            key
          });
        }
      }
      return rows;
    },
    [state]
  );

  const getTableProps = useCallback(
    s => {
      if (!s.tableRows) {
        return _makeProps(s, {
          tableRows: getTableRows(s, s._blockKey)
        });
      }
      // this is for calendars, where we implement radiobuttons as the rows content
      // and use them as pseudo radiobutton groups for selecting days
      return _makeProps(s, {
        tableRows: s.tableRows.map(r => {
          return s.headers.map(h => ({
            id: generateId(),
            control: s,
            key: `${s._blockKey}.${h.key}`,
            blockGroup: s._blockKey,
            ...r,
            Component: h.component,
            value: r.name,
            checked: state[s._blockKey][s.name][h.key] === r.name,
            handleChange: handleRadioChange,
            group: s.name,
            name: h.key
          }));
        })
      });
    },
    [getTableRows, state, _makeProps]
  );

  const getTestProps = useCallback(
    s => ({
      ...s,
      selectedProfile: state.selectedProfile,
      testIDs: state.testIDs,
      errors: props.errors,
      testTypes: state.testTypes,
      hasRandMinDaysBetween: state.hasRandMinDaysBetween,
      hasRandMaxDaysBetween: state.hasRandMaxDaysBetween,
      removeTest: id => {
        const newTests = Object.keys(state.selectedProfile.tests)
          .filter(key => +id !== +key)
          .reduce((obj, curr) => {
            obj[curr] = state.selectedProfile.tests[curr];
            return obj;
          }, {});
        setState({
          ...state,
          selectedProfile: {
            ...state.selectedProfile,
            tests: newTests
          }
        });
      },
      saveTest: (id, testData) => {
        setState({
          ...state,
          selectedProfile: {
            ...state.selectedProfile,
            tests: {
              ...state.selectedProfile.tests,
              [id]: testData
            }
          }
        });
      }
    }),
    [state, setState]
  );

  const getButtonProps = useCallback(
    s => {
      const buttonProps = {
        ...s
      };

      if (s.disabledChecks) {
        buttonProps.getStateValue = getStateValue;
      }
      return buttonProps;
    },
    [getStateValue]
  );

  const getCheckinBlockProps = useCallback(
    s => {
      const imageProps = {
        ...s
      };

      if (state[s.name] && state.meta) {
        return {
          ...imageProps,
          ...state[s.name],
          ...state.meta
        };
      } else if (state[s.name]) {
        return {
          ...imageProps,
          ...state[s.name]
        };
      }

      return imageProps;
    },
    [state]
  );

  const getLinkProps = useCallback(
    s => {
      const href = state?.[s._blockKey]?.[s.name];
      return {
        name: s.name,
        href,
        label: s.label
      };
    },
    [state]
  );

  const { current: _formId } = useRef(
    `_${Math.random()
      .toString(36)
      .substr(2, 9)}`
  );

  const reset = useCallback(
    callback => {
      setState({ ...backup });
      setErrors(manager.makeFormErrorsState());

      if ('function' === typeof callback) {
        callback();
      }
    },
    [setState, backup, setErrors]
  );

  useEffect(() => {
    if (props?.shouldReset) {
      reset();
    }
  }, [props?.shouldReset]);

  const handleFormButtons = useCallback(
    callback => {
      if ('function' === typeof callback) {
        callback(state, handleSetState, setState, manager);
      }
    },
    [state, handleSetState, setState, manager]
  );

  const getState = useCallback(() => state, [state]);

  const getBackup = useCallback(() => backup, [backup]);

  if (isEmpty(state)) {
    return <Loading />;
  }

  return (
    <FormContext.Provider value={{
      ...(props.formContext || {}),
      isReady: true,
      formId: _formId,
      schema,
      schemaManager: manager,
      wrapFieldLabel,
      state,
      getState,
      setState,
      getStateValue,
      getValueHasChanged,
      hasChanged,
      hasNoErrors: !errors._hasErrors,
      errors,
      getErrors,
      getToggleProps,
      getOptions,
      getBackup,
      getBackupValue,
      setBackup,
      onKeyPress,
      handleSetState,
      handleFormButtons,
      handleCustomSelectChange,
      handleInputChange,
      // @deprecated: use `submit` below instead
      handleSubmitForm,
      submit: handleSubmitForm,
      reset,
      getProps,
      getCheckboxGroupProps,
      getRadioButtonGroupProps,
      getCustomSelectProps,
      getCheckboxProps,
      getDobProps,
      getMapProps,
      getLocationSearchProps,
      getTableProps,
      getTestProps,
      getButtonProps,
      getCheckinBlockProps,
      getLinkProps,
      canSave
    }}>
      <CardBlock wrapFieldLabel={wrapFieldLabel} />
      {children}
      {disableSavePrompt ? null : (
        <CustomPrompt
          when={!!hasChanged && !hasSubmitted}
          message="You have unsaved changes. Are you sure you wish to leave?"
        />
      )}
      {actionButtons && !!actionButtons.length ? (
        <ActionBar buttons={actionButtons} formId={_formId} reset={reset} />
      ) : null}
      <ErrorModal title={'Missing Fields'} isShowing={!!modalErrors.length} handleClose={() => setModalErrors([])}>
        <p>One or more required fields are missing{!modalErrors?.length ? '.' : ':'}</p>
        {modalErrors?.length && (
          <ul>
            {modalErrors.map((e, i) => (
              <li key={`error-${i}`}>{e}</li>
            ))}
          </ul>
        )}
      </ErrorModal>
    </FormContext.Provider>
  );
}

const schemaType = PropTypes.arrayOf(
  PropTypes.shape({
    header: PropTypes.string,
    info: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    blockGroup: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.object)
  })
);

FormProvider.propTypes = {
  schema: schemaType,
  data: PropTypes.object.isRequired,
  errors: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  actionButtons: actionButtonsType
};

export default FormProvider;
