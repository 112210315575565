import moment from 'moment';

const monthsObj = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December'
};

export const getYearsOptions = () => {
  const currYear = Number(moment().format('YYYY'));
  const range = Array.from(
    { length: (currYear - 115 - currYear) / -1 + 1 },
    (_, i) => currYear + i * -1
  ).map(v => {
    return {
      value: `${v}`,
      label: `${v}`
    };
  });
  return range;
};

export const getDaysInMonthOptions = (month, year) => {
  const count = moment(`${month}-${year}`, 'MM-YYYY').daysInMonth();
  if (count) {
    return Array(count)
      .fill(null)
      .map((_, i) => {
        const dayIdx = i + 1;
        const actualValue = dayIdx < 10 ? `0${dayIdx}` : `${dayIdx}`;
        const returned = {
          value: actualValue,
          label: actualValue
        };
        return returned;
      });
  }
};

export const getMonthsOptions = () =>
  Object.keys(monthsObj)
    .map(key => ({
      value: key,
      label: monthsObj[key]
    }))
    .sort((curr, next) => curr.value - next.value);
