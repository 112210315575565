export default class DrawerTypes {
  static VIOLATIONS = 'VIOLATIONS';
  static CLIENT_EVENT = 'CLIENT_EVENT';
  static WINDOWS = 'WINDOWS';
  static LOCATIONS = 'LOCATIONS';
  static DAYNOTES = 'DAYNOTES';
  static CHECKIN = 'CHECKIN';
  static BULK_MESSAGE = 'BULK_MESSAGE';
  static FORM_SUBMISSION = 'FORM_SUBMISSION';
  static DEVICE_PERMISSIONS = 'DEVICE_PERMISSIONS';
  static SUBSCRIPTION = 'SUBSCRIPTION';
  static TODO_CLIENT_EVENT = 'TODO-CLIENT-EVENT';
  static CLIENT_INITIATED_FORM = 'CLIENT-INITIATED-FORM';
  static TODO_HOME_ADDRESS = 'TODO-HOME-ADDRESS';
  static TODO_FORM_RESPONSE_CHANGED = 'TODO-FORM-RESPONSE-CHANGED';
  static TODO_BASELINE_IMAGE_REVIEW = 'TODO-BASELINE-IMAGE-REVIEW';
  static EVENT_INFO = 'EVENT_INFO';
  static SELF_PAY_WIDGET = 'SELF_PAY_WIDGET';
  static MANUALLY_COMPLETED_REQUIREMENT = 'MANUALLY-COMPLETED-REQUIREMENT';
  static TOPIC = 'TOPIC';
  static RESOURCE = 'RESOURCE';
  static PACKET = 'PACKET';
  static ASSIGNMENT = 'ASSIGNMENT';
  static INDIVIDUAL_MEETINGS = 'INDIVIDUAL_MEETINGS';
}
