import axios from 'axios';
import Cookies from 'js-cookie';
import { startCase } from 'lodash';

import { API_URL_PREFIX } from "./Constants";
import curriculumUtil from './CurriculumUtil';

class CurriculumRequest {
  constructor() {
    this.instance = axios.create({
      baseURL: API_URL_PREFIX + 'apiv1/',
      timeout: 100000000,
      ...this.HEADERS
    });

    this.addRequestInterceptors();
  }

  KEYS = {
    TOPIC: 'curriculum_topic',
    TOPICS: 'curriculum_topics',
    RESOURCE: 'curriculum_resource',
    RESOURCES: 'curriculum_resources',
    PACKET: 'curriculum_packet',
    PACKETS: 'curriculum_packets',
    ASSIGNMENT: 'curriculum_assignment',
    ASSIGNMENTS: 'curriculum_assignments',
    METRICS: 'curriculum_metrics',
    SHARE: 'curriculum_share',
    SUCCESS: 'success'
  };

  ENDPOINTS = {
    METRICS: 'curriculum_metrics',
    TOPICS: 'topics',
    TOPIC: 'topic',
    RESOURCES: 'resources',
    RESOURCE: 'resource',
    PACKETS: 'packets',
    PACKET: 'packet',
    ASSIGNMENTS: 'assignments',
    ASSIGNMENT: 'assignment',
    SHARE: 'curriculum_share',
  };

  HEADER_KEY = 'Auth-Jwt';

  HEADERS = {
    'Content-Type': 'application/json',
    [this.HEADER_KEY]: this.getJWTFromCookie
  };

  addRequestInterceptors() {
    this.instance.interceptors.request.use(request => {
      if (!request.headers[this.HEADER_KEY]) {
        request.headers[this.HEADER_KEY] = this.getJWTFromCookie;
      }

      return request;
    });
  }

  get getJWTFromCookie() {
    return Cookies.get('id_token');
  }

  async fetchMetrics() {
    try {
      const result = await this.instance.get(this.ENDPOINTS.METRICS);

      return this.parseResult(result, this.KEYS.METRICS);
    } catch (e) {
      return this.parseError(e, this.KEYS.METRICS);
    }
  }

  async fetchTopics() {
    try {
      const result = await this.instance.get(this.ENDPOINTS.TOPICS);

      return this.parseResult(result, this.KEYS.TOPICS);
    } catch (e) {
      return this.parseError(e, this.KEYS.TOPICS);
    }
  }

  async fetchTopic(topicId) {
    try {
      const result = await this.instance.get(`${this.ENDPOINTS.TOPIC}/${topicId}`);

      return this.parseResult(result, this.KEYS.TOPIC);
    } catch (e) {
      return this.parseError(e, this.KEYS.TOPIC);
    }
  }

  async addTopic({ title }) {
    try {
      const result = await this.instance.post(this.ENDPOINTS.TOPIC, { title });

      return this.parseResult(result, this.KEYS.TOPIC);
    } catch (e) {
      return this.parseError(e, this.KEYS.TOPIC);
    }
  }

  async editTopic({ id, title }) {
    try {
      const result = await this.instance.put(`${this.ENDPOINTS.TOPIC}/${id}`, { title });

      return this.parseResult(result, this.KEYS.TOPIC);
    } catch (e) {
      return this.parseError(e, this.KEYS.TOPIC);
    }
  }

  async deleteTopic(topicId) {
    try {
      const result = await this.instance.delete(`${this.ENDPOINTS.TOPIC}/${topicId}`);

      return this.parseResult(result, this.KEYS.SUCCESS);
    } catch (e) {
      return this.parseError(e, this.KEYS.TOPIC);
    }
  }

  async fetchResources() {
    try {
      const result = await this.instance.get(this.ENDPOINTS.RESOURCES);

      return this.parseResult(result, this.KEYS.RESOURCES);
    } catch (e) {
      return this.parseError(e, this.KEYS.RESOURCES);
    }
  }

  async fetchResource(resourceId) {
    try {
      const result = await this.instance.get(`${this.ENDPOINTS.RESOURCE}/${resourceId}`);

      return this.parseResult(result, this.KEYS.RESOURCE);
    } catch (e) {
      return this.parseError(e, this.KEYS.RESOURCE);
    }
  }

  async addResource({ title, file_url, link_url, form_id, curriculum_topic_ids, facility_id = null }) {
    try {
      const result = await this.instance.post(
        this.ENDPOINTS.RESOURCE,
        { title, file_url, link_url, form_id, curriculum_topic_ids, facility_id }
      );

      return this.parseResult(result, this.KEYS.RESOURCE);
    } catch (e) {
      return this.parseError(e, this.KEYS.RESOURCE);
    }
  }

  async editResource({ id, title, file_url, link_url, form_id, curriculum_topic_ids }) {
    try {
      const result = await this.instance.put(`${this.ENDPOINTS.RESOURCE}/${id}`, {
        title,
        file_url,
        link_url,
        form_id,
        curriculum_topic_ids
      });

      return this.parseResult(result, this.KEYS.RESOURCE);
    } catch (e) {
      return this.parseError(e, this.KEYS.RESOURCE);
    }
  }

  async deleteResource(resourceId) {
    try {
      const result = await this.instance.delete(`${this.ENDPOINTS.RESOURCE}/${resourceId}`);

      return this.parseResult(result, this.KEYS.SUCCESS);
    } catch (e) {
      return this.parseError(e, this.KEYS.RESOURCE);
    }
  }

  async fetchPackets() {
    try {
      const result = await this.instance.get(this.ENDPOINTS.PACKETS);

      return this.parseResult(result, this.KEYS.PACKETS);
    } catch (e) {
      return this.parseError(e, this.KEYS.PACKETS);
    }
  }

  async fetchPacket(packetId) {
    try {
      const result = await this.instance.get(`${this.ENDPOINTS.PACKET}/${packetId}`);

      return this.parseResult(result, this.KEYS.PACKET);
    } catch (e) {
      return this.parseError(e, this.KEYS.PACKET);
    }
  }

  async addPacket({ title, curriculum_resource_ids, facility_id = null }) {
    try {
      const result = await this.instance.post(this.ENDPOINTS.PACKET, { title, curriculum_resource_ids, facility_id });

      return this.parseResult(result, this.KEYS.PACKET);
    } catch (e) {
      return this.parseError(e, this.KEYS.PACKET);
    }
  }

  async editPacket({ id, ...rest }) {
    try {
      const result = await this.instance.put(`${this.ENDPOINTS.PACKET}/${id}`, rest);

      return this.parseResult(result, this.KEYS.PACKET);
    } catch (e) {
      return this.parseError(e, this.KEYS.PACKET);
    }
  }

  async deletePacket(packetId) {
    try {
      const result = await this.instance.delete(`${this.ENDPOINTS.PACKET}/${packetId}`);

      return this.parseResult(result, this.KEYS.SUCCESS);
    } catch (e) {
      return this.parseError(e, this.KEYS.PACKET);
    }
  }

  async fetchAssignments(id = null) {
    try {
      const route = id ? `${this.ENDPOINTS.ASSIGNMENTS}/${id}` : this.ENDPOINTS.ASSIGNMENTS;
      const result = await this.instance.get(route);

      return this.parseResult(result, this.KEYS.ASSIGNMENTS);
    } catch (e) {
      return this.parseError(e, this.KEYS.ASSIGNMENTS);
    }
  }

  async fetchAssignment(assignmentId, clientId) {
    try {
      const route = clientId ? `${this.ENDPOINTS.ASSIGNMENT}/${assignmentId}/${clientId}` : `${this.ENDPOINTS.ASSIGNMENT}/${assignmentId}`;

      const result = await this.instance.get(route);

      return this.parseResult(result, this.KEYS.ASSIGNMENT);
    } catch (e) {
      return this.parseError(e, this.KEYS.ASSIGNMENT);
    }
  }

  async addAssignment(data) {
    try {
      const result = await this.instance.post(this.ENDPOINTS.ASSIGNMENT, data);

      return this.parseResult(result, this.KEYS.ASSIGNMENT);
    } catch (e) {
      return this.parseError(e, this.KEYS.ASSIGNMENT);
    }
  }

  async share(type, data) {
    try {
      const url = `${this.ENDPOINTS.SHARE}/${type}`;

      const result = await this.instance.post(url, data);

      return this.parseResult(result, this.KEYS.SHARE);
    } catch (e) {
      return this.parseError(e, this.KEYS.SHARE);
    }
  }

  // eslint-disable-next-line
  async editAssignment({ id, ...rest }) {
    return this.parseError({ error: 'Updating assignments is not currently supported' });
  }

  async deleteAssignment(assignmentId, clientId) {
    try {
      const route = clientId ? `${this.ENDPOINTS.ASSIGNMENT}/${assignmentId}/${clientId}` : `${this.ENDPOINTS.ASSIGNMENT}/${assignmentId}`;
      const result = await this.instance.delete(route);

      return this.parseResult(result, this.KEYS.SUCCESS);
    } catch (e) {
      return this.parseError(e, this.KEYS.ASSIGNMENT);
    }
  }

  parseResult(result, key) {
    if (result?.data?.error) {
      return this.parseError(result, key);
    } else {
      return result.data.data;
    }
  }

  parseError(result, key) {
    const type = startCase(key?.replace(/_|Curriculum/gi, ' ') ?? '');
    const { code, error } = this.getErrorFromResult(result);

    if (error) {
      return { error };
    }

    switch (code) {
      case 404:
        return { error: `${type} not found` };
      default:
        return { error: 'An internal error occurred. Please, try again.' };
    }
  }

  getErrorFromResult(result) {
    return {
      code: result?.request?.status ?? result?.response?.status,
      error: result?.request?.data?.error ?? result?.response?.data?.error
    };
  }

  getFetchItemsMethod(key, id) {
    if (curriculumUtil.isSupportedSelectorKey(key)) {
      switch (key) {
        case curriculumUtil.SELECTOR_KEYS.TOPICS:
          return this.fetchTopics();
        case curriculumUtil.SELECTOR_KEYS.RESOURCES:
          return this.fetchResources();
        case curriculumUtil.SELECTOR_KEYS.PACKETS:
          return this.fetchPackets();
        case curriculumUtil.SELECTOR_KEYS.ASSIGNMENTS:
          return this.fetchAssignments(id);
      }
    }
  }

  getFetchItemMethod(key, ...params) {
    if (curriculumUtil.isSupportedSelectorKey(key)) {
      switch (key) {
        case curriculumUtil.SELECTOR_KEYS.TOPICS:
          return this.fetchTopic(...params);
        case curriculumUtil.SELECTOR_KEYS.RESOURCES:
          return this.fetchResource(...params);
        case curriculumUtil.SELECTOR_KEYS.PACKETS:
          return this.fetchPacket(...params);
        case curriculumUtil.SELECTOR_KEYS.ASSIGNMENTS:
          return this.fetchAssignment(...params);
      }
    }
  }

  getAddMethod(key, data) {
    if (curriculumUtil.isSupportedSelectorKey(key)) {
      switch (key) {
        case curriculumUtil.SELECTOR_KEYS.TOPICS:
          return this.addTopic(data);
        case curriculumUtil.SELECTOR_KEYS.RESOURCES:
          return this.addResource(data);
        case curriculumUtil.SELECTOR_KEYS.PACKETS:
          return this.addPacket(data);
        case curriculumUtil.SELECTOR_KEYS.ASSIGNMENTS:
          return this.addAssignment(data);
      }
    }
  }

  getEditMethod(key, data) {
    if (curriculumUtil.isSupportedSelectorKey(key)) {
      switch (key) {
        case curriculumUtil.SELECTOR_KEYS.TOPICS:
          return this.editTopic(data);
        case curriculumUtil.SELECTOR_KEYS.RESOURCES:
          return this.editResource(data);
        case curriculumUtil.SELECTOR_KEYS.PACKETS:
          return this.editPacket(data);
        case curriculumUtil.SELECTOR_KEYS.ASSIGNMENTS:
          return this.editAssignment(data);
      }
    }
  }

  getDeleteMethod(key, ...params) {
    if (curriculumUtil.isSupportedSelectorKey(key)) {
      switch (key) {
        case curriculumUtil.SELECTOR_KEYS.TOPICS:
          return this.deleteTopic(...params);
        case curriculumUtil.SELECTOR_KEYS.RESOURCES:
          return this.deleteResource(...params);
        case curriculumUtil.SELECTOR_KEYS.PACKETS:
          return this.deletePacket(...params);
        case curriculumUtil.SELECTOR_KEYS.ASSIGNMENTS:
          return this.deleteAssignment(...params);
      }
    }
  }
}

export default new CurriculumRequest();