import { loadPending, removePending, loadSuccessful } from './helperActions';

import { apiHelp, getCalendarsList, catchErrors } from 'utils/apiHelpers';

export function getCalendars(calendars, callback = false) {
  let hash = calendars.doneInitialLoad && calendars.hash;

  return dispatch => {
    // set to pending
    dispatch(loadPending('CALENDARS'));

    if (hash) {
      getCalendarsList(hash)
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending('CALENDARS'));
            if (callback) {
              callback();
            }
          } else {
            dispatch(loadSuccessful('CALENDARS', res.data));
            if (callback) {
              callback();
            }
          }
        })
        .catch(catchErrors);
    } else {
      getCalendarsList(false)
        .then(res => {
          dispatch(loadSuccessful('CALENDARS', res.data));
          if (callback) {
            callback();
          }
        })
        .catch(catchErrors);
    }
  };
}

export function updateNotifications(
  id,
  reportsNotifications,
  scheduleNotifications
) {
  return dispatch => {
    // optimistically save
    dispatch({
      type: 'UPDATE_NOTIFICATIONS_CALENDAR',
      payload: {
        id,
        reportsNotifications,
        scheduleNotifications,
        pending: true
      }
    });

    apiHelp('facility/calendars/update', {
      id,
      call_report: reportsNotifications,
      test_schedule: scheduleNotifications
    })
      .then(res => {
        // confirm save
        let reportsNotifications = res.data.calendar.notifications.call_report;
        let scheduleNotifications =
          res.data.calendar.notifications.test_schedule;
        dispatch({
          type: 'UPDATE_NOTIFICATIONS_CALENDAR',
          payload: {
            id,
            reportsNotifications,
            scheduleNotifications,
            pending: false
          }
        });
      })
      .catch(catchErrors);
  };
}

export function updateInfo(id, name, defaultCalendar) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_INFO_CALENDAR',
      payload: { id, name, default: defaultCalendar, pending: true }
    });

    let params = { id, name };
    if (defaultCalendar) {
      params.default_calendar = 1;
    }
    apiHelp('facility/calendars/update', params)
      .then(res => {
        const { name, default: def } = res.data.calendar;
        dispatch({
          type: 'UPDATE_INFO_CALENDAR',
          payload: { id: id, name, default: def, pending: false }
        });
      })
      .catch(catchErrors);
  };
}
