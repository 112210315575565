import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { AppContext } from 'components/Context/AppContext';
import useOutsideClick from 'hooks/useClickOutside';
import Icon from 'components/Icon';
import useHasAccess from 'hooks/useHasAccess';

const Dropdown = ({ closeMenu, refElement }) => {
  const {
    hasAccessTo,
    logout,
    user: { id, accounts, access_level }
  } = useContext(AppContext);
  useOutsideClick(refElement, closeMenu);

  const superadmin = useHasAccess('superadmin');

  return (
    <div className="topbar__dropdown" data-testid="dropdown">
      <ul>
        <li>
          <Link onClick={closeMenu} to={`/users/get/${id}`}>
            My Account
            <Icon name="chevron-right" className="chevron-right" />
          </Link>
        </li>
        {hasAccessTo('user_management') && (
          <li>
            <Link onClick={closeMenu} to="/users" data-testid="edit-users">
              Edit Users
              <Icon name="chevron-right" className="chevron-right" />
            </Link>
          </li>
        )}
        {hasAccessTo('edit_billing_contact') && (
          <li>
            <Link onClick={closeMenu} to="/billing-info">
              Billing
              <Icon name="chevron-right" className="chevron-right" />
            </Link>
          </li>
        )}
        {(accounts > 1 || access_level >= 20) && (
          <li>
            <Link onClick={closeMenu} to="/switch-account">
              Switch Account
              <Icon name="chevron-right" className="chevron-right" />
            </Link>
          </li>
        )}
        <li>
          <a onClick={closeMenu} href="https://support.reconnectmanager.com" target="_blank" rel="noopener noreferrer">
            Knowledge Base
            <Icon name="chevron-right" className="chevron-right" />
          </a>
        </li>
        <li>
          <a onClick={closeMenu} href="https://reconnectcommunity.com" target="_blank" rel="noopener noreferrer">
            Reconnect
            <br /> Community
            <Icon name="chevron-right" className="chevron-right-large" />
          </a>
        </li>
        {superadmin && (
          <li>
            <Link onClick={closeMenu} to="/superadmin/dashboard">
              Super Admin
              <Icon name="chevron-right" className="chevron-right" />
            </Link>
          </li>
        )}
        <li>
          <a onClick={logout} className="topbar__dropdown-sign-out" data-testid="sign-out">
            Sign Out
          </a>
        </li>
      </ul>
    </div>
  );
};

Dropdown.propTypes = {
  closeMenu: PropTypes.func.isRequired
};

export default Dropdown;
