/* eslint-disable */
import isNumber from 'lodash/isNumber';
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import moment from 'moment';
import isString from 'lodash/isString';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';

export const generateId = () =>
  `_${Math.random()
    .toString()
    .substr(2, 9)}`;

/**
 * Checks if the end date and time is more than 24 hours away from the start date and time.
 * @param {object} startDateTime Includes the start date and time.
 * @param {object} endDateTime   Includes the end date and time.
 *
 * @returns {boolean} True if there are more than 24 hours betwen the two dates and times.
 */
export const isMoreThan24 = (
  { start_date, start_time },
  { end_date, end_time }
) => {
  const startDateTime = moment(
    start_date ? start_date.format('YYYY-MM-DD') : moment() + ' ' + start_time,
    'YYYY-MM-DD hh:mm A'
  );
  const endDateTime = moment(
    end_date ? end_date.format('YYYY-MM-DD') : moment() + ' ' + end_time,
    'YYYY-MM-DD hh:mm A'
  );
  return 24 < moment(endDateTime).diff(startDateTime, 'hours');
};

/**
 * Determine whether to enable/disable element based on state properties given in `extras` array
 * (see components/Forms/Detailed/schema.js Generate Report button for example)
 *
 * @example
 *
 * // schema.js
 *
 * // ...other schema definitions
 *
 * {
      key: 'detailedReport', // the state key to check; if no group is given, checks will be at the top level
      method: 'hasAnyFalsyValues', // formMethods
      extra: [ // array of keys to check
        'client_groups',
        'testing_profiles',
        'call_in_windows',
        'case_managers',
        'calendars',
        'test_status',
        'call_status',
        'test_result'
      ]
    }
 *
 * @param {Any} valueFromState the value from `FormProvider` state to obtain for use in check
 * @param {Void} [_] optional (unused) value from schema used in most comparisons but excluded for this method
 * @param {Array} extra an array of strings representing nested properties within the provided `valueFromState` key
 * @returns {Boolean}
 */
export const hasAnyFalsyValues = (valueFromState, _, extra) => {
  return extra
    .map(key => {
      if (isObject(valueFromState[key])) {
        return Object.values(valueFromState[key]).every(v => !v);
      } else if (isArray(valueFromState[key])) {
        return !valueFromState[key].length;
      }
    })
    .some(v => !!v);
};

export const formComparisonMethods = {
  hasAnyFalsyValues,
  isEqual: isEqual,
  dynamic: (firstValue, secondValue, extra) => extra(firstValue, secondValue),
  isFalsy: valueFromState => !valueFromState,
  isTruthy: valueFromState => !!valueFromState,
  isMoreThan24,
  isLessThan24: (startDateTime, endDateTime) =>
    !isMoreThan24(startDateTime, endDateTime),
  notEqual: (valueFromState, valueFromSchema) =>
    valueFromState !== valueFromSchema,
  isNumber: isNumber,
  isDefined: Boolean,
  isEmpty: isEmpty,
  anyAreFalse: (key1, key2) => some([key1, key2], (key) => key !== true && key !== 1),
  notEmpty: val => !isEmpty(val),
  isValidDate: (val, formatFromSchema) =>
    moment(val, formatFromSchema).isValid(),
  isNotValidDate: (val, formatFromSchema) =>
    !moment(val, formatFromSchema).isValid(),
  inArray: (valueFromState, valueFromSchema) =>
    Array.isArray(valueFromSchema) && valueFromSchema.includes(valueFromState),
  hasLength: (valueFromState, valueFromSchema) =>
    isString(valueFromState) ? valueFromState.length >= valueFromSchema : false,
  isToday: valueFromState => moment(valueFromState).isSame(moment(), 'day'),
  hasChanged: (valueFromState, backupValue) =>
    !isEqual(valueFromState, backupValue),
  atLeastOneTruthy: valueFromState => Object.values(valueFromState).some(v => !!v),
  propertyWithoutValue: (valueFromState, rule) => valueFromState !== rule.value,
  arrayContains: (valueFromState, valueFromSchema) =>
    Array.isArray(valueFromState) && valueFromState.includes(valueFromSchema)
};

/**
 * Get key values
 * @param {string|boolean} key Key to state data. Can be comma-separated like 'start_date,start_time', 'start_date', or false.
 * @param {object} groupVal Group value
 * @param {function} getStateValue Method to obtain the current state
 */
const getKeyValues = (key, groupVal, getStateValue) => {
  if (false === key) {
    // Some cases use this. In such case, there's nothing else to do.
    return getStateValue(key, groupVal);
  }
  if (key.includes(',')) {
    // This is used when a renderCheck key has the shape
    //  key: 'start_date,start_time',
    return fromPairs(
      key.split(',').map(x => {
        x = x.trim();
        return [x, getStateValue(x, groupVal)];
      })
    )
  }
  // And this is used if the renderCheck key is simple
  //  key: 'start_date',
  return getStateValue(key, groupVal)
};

export const getToRender = (
  renderChecks,
  group,
  getStateValue,
  disabledCheck,
  getBackupValue
) => {
  const toRenderArr = [];

  if (renderChecks && !!renderChecks.length) {
    for (const check of renderChecks) {
      if (isBoolean(check)) {
        toRenderArr.push(check);
        continue;
      }
      if (!check.key) {
        continue;
      }
      const groupVal = !!group ? group : !!check.group ? check.group : false;
      const method = formComparisonMethods[check.method];
      const args = [
        getKeyValues(check.key, groupVal, getStateValue)
      ];
      if (check.key2) {
        // This is used when a renderCheck has the shape
        // {
        //  key: 'start_date',
        //  key2: 'end_date',
        //  method: 'isMoreThan24'
        // }
        args.push(getKeyValues(check.key2, groupVal, getStateValue));
      } else if (check.method === 'hasChanged') {
        args.push(getBackupValue(check.key, groupVal));
      } else {
        args.push(check.value);
      }
      args.push(check.extra);
      let result = method(args[0], args[1], args[2]);
      toRenderArr.push(result);
    }
  }

  if (disabledCheck) {
    return !toRenderArr.length ? false : toRenderArr.some(c => !!c);
  }

  return !toRenderArr.length ? true : toRenderArr.every(c => !!c);
};
