/**
 * External dependencies
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

/**
 * Internal dependencies
 */
import getStates from 'selectors/getStates';
import getClientLocations from 'selectors/getClientLocations';
import getLocationsList from 'selectors/getLocationsList';
import { getLocations, requestCounties, openDrawer } from 'actions/actionCreators';
import SubNav from 'components/SubNav';
import Map from 'components/Map';
import LocationsTable from './LocationsTable';
import QueryStates from 'components/data/query-states';
import isDrawerOpen from 'selectors/isDrawerOpen';
import Button from 'components/Button';
import './style.scss';
import Loading from 'components/Helpers/Loading';

class Zones extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    }),
    client: PropTypes.any,
    mapOnly: PropTypes.bool,
    locationsOnMap: PropTypes.array,
    // Connected props
    locationList: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]).isRequired,
    requestCounties: PropTypes.func.isRequired,
    getLocations: PropTypes.func.isRequired,
    states: PropTypes.object.isRequired,
    openDrawer: PropTypes.func.isRequired,
    isDrawerOpen: PropTypes.bool.isRequired
  };

  static defaultProps = {
    mapOnly: false,
    states: {}
  };

  state = {
    locationsOnMap: [],
    stateIds: [],
    mapOnViewer: null,
    politicalZones: []
  };

  componentDidMount() {
    this.onLocationUpdate(this.props.locationList, () =>
      this.setState({
        politicalZones: this.buildLocationsWithCounties(this.parseCounties(this.state.stateIds, this.props))
      })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { stateIds } = this.state;
    let statesChangedCountiesReady = false;
    if (!isEqual(prevState.stateIds, stateIds)) {
      stateIds.forEach(stateId => {
        if (
          parseInt(stateId) !== 0 &&
          !this.isRequestingCounties(stateId) &&
          'object' !== typeof get(prevProps.states, [stateId, 'counties'], undefined)
        ) {
          this.props.requestCounties(stateId);
        } else {
          statesChangedCountiesReady = true;
        }
      });
    }

    if (!isEqual(prevProps.states, this.props.states) || statesChangedCountiesReady) {
      const locationsWithCounties = this.buildLocationsWithCounties(this.parseCounties(stateIds, this.props));
      this.setState({
        politicalZones: locationsWithCounties
      });
    }

    if (!isEqual(prevProps.locationList, this.props.locationList)) {
      this.setState({
        locations: this.props.locationList
      });
      this.onLocationUpdate(this.props.locationList, () =>
        this.setState({
          politicalZones: this.buildLocationsWithCounties(this.parseCounties(this.state.stateIds, this.props))
        })
      );
    }
  }

  parseCounties = stateIds =>
    stateIds
      .map(stateId => ({
        stateId,
        counties: mapValues(this.countiesByState(stateId), county => JSON.parse(county.coordinates))
      }))
      .reduce((acc, county) => {
        acc[county.stateId] = county.counties;
        return acc;
      }, {});

  /**
   * Build pair of coordinates and exclusion for the state/county locations.
   *
   * @param {object} counties Selected counties in a state.
   * @param {object|null} locationsOnMap Locations to display in a map
   */
  buildLocationsWithCounties = (counties, locationsOnMap = null) => {
    const locationsToBuild = null === locationsOnMap ? this.state.locationsOnMap : locationsOnMap;
    const locations = locationsToBuild
      .map(location => {
        if ('political' !== location.definition || !location.stateId) return undefined;

        const coordinates = location.coordinates[location.stateId].map(countyId => {
          return counties[location.stateId][countyId];
        });
        return {
          coordinates,
          exclusion: location.exclusion
        };
      })
      .filter(location => undefined !== location);
    return locations;
  };

  isRequestingCounties = stateId => {
    return get(this.props, ['states', stateId, 'isRequestingCounties'], false);
  };

  getCurrentStateCenter = stateId => {
    return JSON.parse(get(this.props, ['states', stateId, 'center'], '[]'));
  };

  countiesByState = stateId => {
    return get(this.props, ['states', stateId, 'counties'], []);
  };

  /**
   * Called when user clicks the corresponding button in the LocationsDrawer.
   * If a location is passed, a fullviewport map will be displayed.
   * If it's null, the fullviewport will close.
   *
   * @param {object|null} mapOnViewer Location information. Defaults to null.
   */
  viewLocationOnMap = mapOnViewer => {
    this.setState({ mapOnViewer });
  };

  /**
   * Display the map in a fullviewport overlay.
   */
  renderLocationOnViewer = () => {
    if (null === this.state.mapOnViewer) {
      return null;
    }
    document.body.classList.add('is-lightbox-open');
    const location = this.state.mapOnViewer;
    let mapCenter = null;
    let pairCoordinatesExclusion = null;
    let circle = false;
    let draw = 'area';
    let primaryMarker = false;

    switch (location.definition) {
      case 'address':
        draw = 'address';
        mapCenter = (([lat, lng]) => ({ lat, lng }))(location.coordinates);
        circle = {
          radius: location.default_lenience / 3.281, // default lenience is in feet, we need to convert it to meters
          center: mapCenter
        };
        primaryMarker = {
          title: 'Location',
          name: 'Location Radius',
          position: mapCenter,
          icon: '/static/img/marker_green.png'
        };
        break;
      case 'area':
        pairCoordinatesExclusion = [
          {
            coordinates: location.coordinates,
            exclusion: parseInt(location.exclusion, 10) === 1
          }
        ];
        break;
      case 'political':
        pairCoordinatesExclusion = this.buildLocationsWithCounties(this.parseCounties([]), [
          { ...location, stateId: null }
        ]);
        break;
    }

    return (
      <div className="location-zones__overlay">
        <div className="location-zones__overlay-controls">
          <button
            className="location-zones__overlay-close"
            onClick={e => {
              e.stopPropagation();
              document.body.classList.remove('is-lightbox-open');
              this.viewLocationOnMap(null);
            }}
          >
            &times;
          </button>
        </div>
        <Map
          center={mapCenter}
          containerStyles={{ height: '93vh' }}
          draw={draw}
          staticDraw={true}
          circle={circle}
          primaryMarker={primaryMarker}
          zone={pairCoordinatesExclusion}
          isExclusion={parseInt(location.exclusion, 10) === 1}
          disableDraw={this.props.disableDraw}
        />
      </div>
    );
  };

  onLocationUpdate = (locations, callback) => {
    // this second check is for a case where locations
    // came back as [null]
    if (0 === locations.length || !locations[0]) {
      this.setState({ isLoading: true });
      this.props.getLocations(Object.assign(locations, { clientId: this.props.client?.id ?? null }), () => {
        this.setState({ isLoading: false });
      });
      // If there are no locations to work with, clear locations on map.
      this.setState({ locationsOnMap: [] });
      return null;
    }

    // filter for the client id if we have it since we only want to show the client's locations
    const client_id = this.props.clientId ? this.props.clientId : this.props.match.params.id;
    if (client_id) {
      locations = locations.filter(location => {
        return parseInt(location.client_id) === parseInt(client_id);
      });
    }

    this.setState(
      {
        locationsOnMap: locations
          .map(location => {
            const { definition, coordinates, exclusion, ...rest } = location;
            switch (definition) {
              case 'address':
                return coordinates && coordinates[0]
                  ? {
                      ...rest,
                      lat: parseFloat(coordinates[0]),
                      lng: parseFloat(coordinates[1]),
                      definition,
                      exclusion,
                      lenience: location.default_lenience / 3.281 // default lenience is in feet, we need to convert it to meters
                    }
                  : null;
              case 'area':
                return {
                  coordinates,
                  definition,
                  exclusion
                };
              case 'political':
                return {
                  lat: null,
                  lng: null,
                  stateId: null,
                  coordinates,
                  definition,
                  exclusion
                };
            }
          })
          .filter(location => null !== location),
        stateIds: []
      },
      callback
    );
  };

  /**
   * Render a map displaying the zones assigned to a client.
   *
   * @returns {object} Map displaying zones.
   */
  renderMap = () => {
    const { locationsOnMap, politicalZones } = this.state;
    const { mapOnly, geozonePositions, showGeozonePolyline } = this.props;
    let mapConfiguration = this.props.mapConfiguration;

    const markers = locationsOnMap
      .filter(location => 'address' === location.definition)
      .map(marker => ({ ...marker, props: {} }));

    if (!mapConfiguration) {
      mapConfiguration = {
        mapType: 'satellite'
      };
    }

    const polygons = locationsOnMap
      .filter(location => 'area' === location.definition)
      .map(location => {
        return {
          exclusion: parseInt(location.exclusion, 10) === 1,
          coordinates: location.coordinates
        };
      });
    Object.values(politicalZones).forEach(item => {
      polygons.push({
        exclusion: parseInt(item.exclusion, 10) === 1,
        coordinates: item.coordinates
      });
    });

    let secondaryMarkers = markers.map(location => ({
      latitude: location.lat,
      longitude: location.lng,
      icon:
        parseInt(location.exclusion, 10) === 1
          ? '/static/img/marker_red.png'
          : parseInt(location.exclusion, 10) === 0
          ? '/static/img/marker_blue.png'
          : '/static/img/marker_green.png'
    }));

    if (geozonePositions) {
      // don't show the other markers for geozone violations
      secondaryMarkers = [];
      if (markers?.[0]?.id && markers[0].id.indexOf('ZNID') === 0) {
        let icon, fillColor;
        const isExclusion = parseInt(markers[0].exclusion) === 1;
        if (isExclusion) {
          icon = '/static/img/location-exclusion-zone-marker.svg';
          fillColor = '#EF4541';
        } else {
          icon = '/static/img/location-inclusion-zone-marker.svg';
          fillColor = '#6FBE49';
        }
        secondaryMarkers.push({
          ...markers[0],
          latitude: markers[0].lat,
          longitude: markers[0].lng,
          icon
        });
        markers[0] = {
          props: {
            strokeColor: 'transparent',
            strokeOpacity: 0,
            strokeWeight: 5,
            fillColor,
            fillOpacity: 0.2
          },
          lat: markers[0].lat,
          lng: markers[0].lng,
          lenience: parseInt(markers[0].lenience, 10),
          exclusion: isExclusion ? 1 : 0
        };
      }

      // TODO: Right now we don't have the firstLocation marker in the design, leaving this for reference in case we need it
      // const firstLocation = geozonePositions[0];
      // secondaryMarkers.push({
      //   latitude: firstLocation.lat,
      //   longitude: firstLocation.lng,
      //   icon: '/static/img/location-client-marker.svg'
      //   // icon: '/static/img/marker_green.png'
      // });
      // start circle
      // markers.push({
      //   props: {
      //     strokeColor: 'transparent',
      //     strokeOpacity: 0,
      //     strokeWeight: 5,
      //     fillColor: '#6FBE49',
      //     fillOpacity: 0.2
      //   },
      //   lat: firstLocation.lat,
      //   lng: firstLocation.lng,
      //   lenience: parseInt(firstLocation.accuracy, 10),
      //   exclusion: 1
      // });

      // end marker
      const lastLocation = geozonePositions[geozonePositions.length - 1];
      secondaryMarkers.push({
        latitude: lastLocation.lat,
        longitude: lastLocation.lng,
        icon: '/static/img/location-client-marker.svg'
      });
      // end circle
      markers.push({
        props: {
          strokeColor: 'transparent',
          strokeOpacity: 0,
          strokeWeight: 5,
          fillColor: '#FC6700',
          fillOpacity: 0.2,
          isClient: 1
        },
        lat: lastLocation.lat,
        lng: lastLocation.lng,
        lenience: parseInt(lastLocation.accuracy, 10)
      });
    }

    const polyline =
      geozonePositions && showGeozonePolyline
        ? {
            path: geozonePositions.map(location => ({
              lat: location.lat,
              lng: location.lng
            })),
            strokeColor: '#FC6700'
          }
        : null;

    const center = geozonePositions
      ? {
          lat: geozonePositions[0].lat,
          lng: geozonePositions[0].lng
        }
      : {
          // if we don't have geozone positions, let the map bounds set the center
          lat: 0,
          lng: 0
        };

    return (
      <Map
        mapType={mapConfiguration.mapType}
        center={center}
        containerStyles={{ height: mapOnly ? '200px' : '400px' }}
        secondaryMarkers={{
          markers: secondaryMarkers
        }}
        zone={polygons}
        polyline={polyline}
        draw="area"
        staticDraw={true}
        circles={markers.map(({ lat, lng, exclusion, lenience, props }) => ({
          center: { lat, lng },
          exclusion,
          radius: lenience,
          ...props
        }))}
        disableDraw={this.props.disableDraw}
      />
    );
  };

  /**
   * Render content to add zones to a client.
   *
   * @returns {object} Markup with the map if locations exist, otherwise it renders an explanation text.
   */
  displayZones = () => {
    const mapOnly = this.props.mapOnly;
    return (
      <>
        {mapOnly ? null : <h1>Zones</h1>}
        <div className="location-zones__overview">
          {mapOnly ? null : <h2>Overview</h2>}
          {this.state.locationsOnMap.length > 0 || mapOnly ? (
            this.renderMap()
          ) : (
            <p>There are still no locations for this client. Add the first one using the button below.</p>
          )}
        </div>
      </>
    );
  };

  /**
   * Render content to add zones to a client.
   *
   * @returns {object} Table displaying zones added plus action buttons.
   */
  addZones = () => {
    const { mapOnly, openDrawer, isDrawerOpen } = this.props;
    const id = this.props.clientId ? this.props.clientId : this.props.match.params.id;
    return (
      <div className="location-zones__client">
        {mapOnly ? null : (
          <>
            {0 < this.state.locationsOnMap.length && <h2>Edit Locations</h2>}
            <LocationsTable
              clientId={id}
              onLocationUpdate={this.onLocationUpdate}
              editLink={`/clients/get/${id}/location/zones/edit/%locationId%`}
              mapOnly={mapOnly}
              hasAccessTo={this.props.hasAccessTo}
              receivesLocations={true}
              locations={this.state.locationsOnMap}
            />
          </>
        )}
        {mapOnly ? null : (
          <div className="action-bar action-bar--shorten">
            <div className="container">
              <Button
                handleButtonClick={() => this.props.history.push('/clients/get/' + id + '/location/zones/add')}
                primary
              >
                Add New Location
              </Button>
              <Button
                handleButtonClick={() =>
                  openDrawer('LOCATIONS', {
                    clientId: parseInt(id),
                    viewLocationOnMap: this.viewLocationOnMap
                  })
                }
                primary
                transparent
                disabled={isDrawerOpen}
              >
                Assign Locations
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    if (null === this.props.locationList) {
      return <Loading />;
    }
    if (isEmpty(this.props.states)) {
      return <QueryStates />;
    }
    const id = this.props.clientId ? this.props.clientId : this.props.match.params.id;
    const SubNavComponent = this.props.clientId ? Fragment : SubNav;
    const subNavComponentProps = this.props.clientId
      ? null
      : {
          clientId: id,
          context: 'LocationSubNav'
        };
    return (
      <SubNavComponent {...subNavComponentProps}>
        <div className="location-zones">
          <>
            {this.renderLocationOnViewer()}
            {this.displayZones()}
            {this.addZones()}
          </>
        </div>
      </SubNavComponent>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let locationList;
  if (ownProps.mapOnly) {
    // This is loaded in the map shown in Violations
    locationList = ownProps.locationsOnMap
      ? ownProps.locationsOnMap
      : getLocationsList(state).filter(l => parseInt(l.exclusion, 10) !== 2);
  } else {
    // This is loaded in client Zones
    const clientLocations = getClientLocations(state, ownProps.client?.id ?? null);
    locationList = false !== clientLocations ? clientLocations.filter(l => parseInt(l.exclusion, 10) !== 2) : [];
  }
  return {
    states: getStates(state),
    locationList,
    isDrawerOpen: isDrawerOpen(state)
  };
}

const mapDispatchToProps = {
  getLocations,
  requestCounties,
  openDrawer
};

export default connect(mapStateToProps, mapDispatchToProps)(Zones);
