export default function messages(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_CONVERSATIONS':
      return {
        ...state,
        isRequestingConversations: true
      };
    case 'LOAD_SUCCESSFUL_CONVERSATIONS':
      return {
        ...state,
        isRequestingConversations: false,
        conversations: action.payload.chats
      };
  }
  return state;
}
