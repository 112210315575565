import { SelectOption } from "components/Clients/ClientsGet/Info/BasicInfo/Schemas/helpers";

export const AdHocAlertTypes = {
  electronic_monitoring_out_of_bounds: 'Electronic Monitoring: Out of Bounds',
  electronic_monitoring_failure_to_charge_device: 'Electronic Monitoring: Failure to Charge Device',
  electronic_monitoring_left_inclusion_zone: 'Electronic Monitoring: Left Inclusion Zone',
  electronic_monitoring_entered_exclusion_zone: 'Electronic Monitoring: Entered Exclusion Zone',
  electronic_monitoring_other: 'Electronic Monitoring: Other',
  electronic_house_arrest_left_eha_perimeter: 'Electronic House Arrest (EHA): Left EHA Perimeter',
  electronic_house_arrest_failed_to_return_to_eha_at_designated_time: 'Electronic House Arrest (EHA): Failed to Return to EHA at Designated Time',
  electronic_house_arrest_other: 'Electronic House Arrest (EHA): Other',
  other_non_compliance: 'Other Non-Compliance',
  failure_to_appear: 'Failure to Appear',
}

export const getViolationType = details => {
  let typeOfViolation = '';
  for (let i = 0; i < details.length; i++) {
    const detail = details[i];
    switch (detail.type) {
      case 'out_of_bounds':
        typeOfViolation += 'Out of Bounds, ';
        break;
      case 'daily_late':
        typeOfViolation += 'Late (Daily), ';
        break;
      case 'scheduled_late':
        typeOfViolation += 'Late (Scheduled), ';
        break;
      case 'spoofed_location':
        typeOfViolation += 'Spoofed Location Attempt, ';
        break;
      case 'spoofed_message_location':
        typeOfViolation += 'Spoofed Message Location Attempt, ';
        break;
      case 'drug_test':
        typeOfViolation += 'Results, ';
        break;
      case 'no_payment_attempt':
        typeOfViolation += 'No Payment Attempted, ';
        break;
      case 'payment_failed':
        typeOfViolation += 'Failed Payment, ';
        break;
      case 'missed_daily_check_in':
        typeOfViolation += 'Missed (Daily), ';
        break;
      case 'missed_event_check_in':
        typeOfViolation += 'Missed (Event), ';
        break;
      case 'no_location_during_check_in':
        typeOfViolation += 'No Location (Event), ';
        break;
      case 'missed_random_check_in':
        typeOfViolation += 'Missed (Random), ';
        break;
      case 'missed_scheduled_check_in':
        typeOfViolation += 'Missed (Scheduled), ';
        break;
      case 'missed_scheduled_form':
        typeOfViolation += 'Missed (Scheduled Form), ';
        break;
      case 'breathalyzer':
        typeOfViolation += 'Alcohol Present, ';
        break;
      case 'facial_mismatch':
        typeOfViolation += 'Facial Mismatch, ';
        break;
      case 'low_authenticity':
        typeOfViolation += 'Low Authenticity Score, ';
        break;
      case 'outside_inclusion_zone':
        return 'Outside inclusion zone';
      case 'inside_exclusion_zone':
        return 'Inside exclusion zone';
      case 'no_data_violation':
        return 'Offline';
      case 'no_ble_data_violation':
        return 'No Bluetooth Beacon Data';
      case 'location_tracking_logout':
        return 'Logged Out (Location Tracking)';
      case 'reconnectband_tamper_detected':
        return 'ReconnectBand Tamper Detected';
      case 'reconnectband_tamper_glitch':
        return 'ReconnectBand Tamper Glitch Detected';
      case 'goal_incomplete':
        return 'Incomplete Goal';
      default: {
        if (detail.type in AdHocAlertTypes) {
          return AdHocAlertTypes[detail.type];
        }
      }
    }
  }

  return typeOfViolation.replace(/,\s*$/, '');
};

export const getProperty = (propertyName, object) => {
  var parts = propertyName.split('.'),
    length = parts.length,
    i,
    property = object || this;

  for (i = 0; i < length; i++) {
    if (property[parts[i]]) {
      property = property[parts[i]];
    }
  }
  return property;
};

export const tableHeaders = [
  {
    sortCol: 'status',
    children: 'STATUS',
    className: 'small-th	'
  },
  { sortCol: 'first_name', children: 'FIRST NAME' },
  { sortCol: 'last_name', children: 'LAST NAME' },
  { sortCol: 'type', children: 'ALERT TYPE', unsortable: true },
  { sortCol: 'created', children: 'DATE' },
  {
    unsortable: true,
    sortCol: 'none',
    children: ''
  }
];

export const getStatusStyles = status => {
  switch (status) {
    case 'confirmed':
      return { 'status-confirmed': true };
    case 'excused':
      return { 'status-excused': true };
    case 'active':
      return { 'status-pending': true };
    default:
      return { 'status-open': true };
  }
};

export const getDetailsStyles = status => {
  switch (status) {
    case 'confirmed':
    case 'Out of Bounds':
    case 'Late':
    case 'Failed to Check In':
    case 'late':
    case 'no check':
      return { 'details-confirmed': true };
    case 'Early':
    case 'Checked In':
    case 'excused':
    case 'checked':
      return { 'details-excused': true };
    case 'Pending':
    case 'open':
    case 'pending':
    case 'checked late':
      return { 'details-open': true };
    default:
      return {};
  }
};

const baseFilterOptions = [
  new SelectOption('Breathalyzer', 'breathalyzer'),
  new SelectOption('Daily (Late)', 'daily_late'),
  new SelectOption('Test Results', 'drug_test'),
  new SelectOption('Facial Mismatch', 'facial_mismatch'),
  new SelectOption('Exclusion Zone', 'inside_exclusion_zone'),
  new SelectOption('Late', 'late'),
  new SelectOption('Facial Authenticity', 'low_authenticity'),
  new SelectOption('Missed (Daily)', 'missed_daily_check_in'),
  new SelectOption('No Payment Attempted', 'no_payment_attempt'),
  new SelectOption('Failed Payment', 'payment_failed'),
  new SelectOption('Missed (Event)', 'missed_event_check_in'),
  new SelectOption('No Location (Event)', 'no_location_during_check_in'),
  new SelectOption('Missed (Random)', 'missed_random_check_in'),
  new SelectOption('Missed (Scheduled)', 'missed_scheduled_check_in'),
  new SelectOption('Missed (Scheduled Form)', 'missed_scheduled_form'),
  new SelectOption('No Data Alert', 'no_data_violation'),
  new SelectOption('Out of Bounds', 'out_of_bounds'),
  new SelectOption('Inclusion Zone', 'outside_inclusion_zone'),
  new SelectOption('Scheduled (Late)', 'scheduled_late'),
  new SelectOption('Spoofed Location', 'spoofed_location'),
  new SelectOption('Spoofed Message Location', 'spoofed_message_location'),
  new SelectOption('Logged Out (Location Tracking)', 'location_tracking_logout'),
  new SelectOption('ReconnectBand Tamper Detected', 'reconnectband_tamper_detected'),
  new SelectOption('ReconnectBand Tamper Glitch', 'reconnectband_tamper_glitch'),
  new SelectOption('No Bluetooth Beacon Data', 'no_ble_data_violation'),
  new SelectOption('Incomplete Goal', 'goal_incomplete'),
];

const withAdHocFilters = [
  ...baseFilterOptions,
  ...Object.entries(AdHocAlertTypes).map(([type, label]) => new SelectOption(label, type))
].sort((a, b) => (a.label < b.label ? -1 : 1));

export const filterOptions = [
  new SelectOption('Select an Alert Type', ''),
  ...withAdHocFilters
];
