import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import BlockWrapper from '../common/BlockWrapper';
import useBaselineImages from 'hooks/useBaselineImages';
import getDrawerProps, { getIsDrawerOpen } from 'selectors/getDrawerProps';
import { useStyles } from 'components/Clients/BaselineImages/styles';
import ClientNameLink from 'components/ClientNameLink';
import { useCloseDrawer } from 'components/Curriculum/hooks';
import { BaselineContext } from 'components/Clients/BaselineImages/BaselineContext';
import Modals from 'components/Clients/BaselineImages/Modals';
import Explainer from 'components/Clients/BaselineImages/Explainer';
import Images from 'components/Clients/BaselineImages/Images';
import ActionButtons from 'components/Clients/BaselineImages/ActionButtons';

const TodoBaselineImageReview = () => {
  const { todo, getCallBack } = useSelector(getDrawerProps);
  const isDrawerOpen = useSelector(getIsDrawerOpen);
  const handleCloseDrawer = useCloseDrawer();
  const {
    state,
    imageRefs,
    setImageRef,
    getImages,
    updateCollection,
    handleSingleClearModal,
    closeSingleClearModal,
    removeSingleImage,
    handleLoadImage,
    isModalOpen,
    toggleModal,
    isSingleModalOpen,
    toggleIsSingleModalOpen,
    isApprovedDisabled
  } = useBaselineImages(todo?.client_id, getCallBack);
  const classes = useStyles();

  return (
    <BaselineContext.Provider
      value={{
        state,
        imageRefs,
        setImageRef,
        getImages,
        updateCollection,
        handleSingleClearModal,
        closeSingleClearModal,
        removeSingleImage,
        handleLoadImage,
        isModalOpen,
        toggleModal,
        isSingleModalOpen,
        toggleIsSingleModalOpen,
        isApprovedDisabled
      }}
    >
      <div className="vc-container full-height">
        <div className="vc-container__scroll-wrapper">
          <div
            className={classnames('vc-container__header', {
              'drawer-open': isDrawerOpen
            })}
          >
            <h3 className="vc-container__header--heading">Baseline Image Review</h3>
            <div className="vc-status">
              <span>{todo.status}</span>
            </div>
          </div>
          <BlockWrapper
            block={{ blockLabel: 'Pending Baseline Images' }}
            clientName={
              <ClientNameLink
                client_id={todo.client_id}
                clientName={`${todo.client.first_name} ${todo.client.last_name}`}
                to="baseline-images"
              />
            }
            closeDrawer={handleCloseDrawer}
            status={todo.status}
          />
          <div className={classes.drawerContainer}>
            <div className={classes.actions}>
              <ActionButtons />
            </div>
            <Explainer />
            <Images />
          </div>
        </div>
      </div>
      <Modals />
    </BaselineContext.Provider>
  );
};

export default TodoBaselineImageReview;
