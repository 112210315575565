import { useEffect } from 'react';

export default (ref, callback) => {
  const handleClick = e => {
    if (!ref.current || ref.current.contains(e.target)) {
      return;
    }
    callback(e);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [ref, callback]); // empty array list needed to ensure this only gets called on the initial render
};
