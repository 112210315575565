import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';

import { closeDrawer } from 'actions/actionCreators';
import Icon from 'components/Icon';
import InfoBlockRow from '../InfoBlockRow.js';
import MapContainer from 'components/Map';
import { MapMarker } from '../common/drawerHelpers';
import { getCommunityEvent } from 'actions/actionCreators';
import Loading from 'components/Helpers/Loading';
import './styles.scss';

function EventInfoDrawer({
  drawer: {
    drawerOpen,
    drawerProps: { event_id, extra }
  },
  closeDrawer,
  getCommunityEvent
}) {
  // loading
  const [isReady, setIsReady] = useState(false);
  const [event, setEvent] = useState({});

  useEffect(() => {
    if (!isReady) {
      getCommunityEvent(event_id, response => {
        if (response.data.success) {
          const { event } = response.data;
          setEvent(event);
          setIsReady(true);
        }
      });
    }
  }, [isReady]);

  const addressBlock = () => {
    return (
      <div className="address-with-map-marker">
        <p>{event.zone.address.address_text}</p>
        <p>
          <MapMarker
            addr={event.zone.address.address_text}
            lat={event.zone.address.coordinates.latitude}
            lng={event.zone.address.coordinates.longitude}
            handleClose={closeDrawer}
          />
        </p>
      </div>
    );
  };

  const buildRows = () => {
    let defaultRows = [
      ['Event', event.title],
      ['Date', moment(event.start_date).format('MMMM D, YYYY')],
      ['Start Time', moment(event.start_time, 'hh:mm:ss').format('h:mm A')],
      ['End Time', moment(event.end_time, 'hh:mm:ss').format('h:mm A')]
    ];

    if (event.clients.length == 1) {
      defaultRows.unshift([
        'Client',
        <Link
          className="profile-link"
          to={`/clients/get/${event.clients[0].client_id}/info`}
          onClick={closeDrawer}
          key={'client-link'}
        >
          {event.clients[0].first_name + ' ' + event.clients[0].last_name}
          <Icon name="profile-dark" className="profile-dark" />
        </Link>
      ]);
    }

    if (event.zone) {
      defaultRows.push(['Location', addressBlock]);
    }

    if (event.clients.length > 1) {
      const clientListElement = buildClientList(event.clients);
      defaultRows.push(['Attendees', clientListElement]);
    }

    if (extra?.check_in_status) {
      defaultRows.push(['Check-in', extra.check_in_status]);
    }
    if (extra?.specialization_data) {
      try {
        const specialization_data = JSON.parse(extra.specialization_data);
        if (specialization_data.outcome) {
          defaultRows.push(['Outcome', specialization_data.outcome]);
          defaultRows.push(['Visit Notes', specialization_data.visitNotes]);
        }
      } catch (e) {
        console.error('error parsing specialization_data', e);
      }
    }

    return defaultRows;
  };

  const buildClientList = clients => {
    return (
      <div>
        <p className="attendee-count">{clients.length} Attendees</p>
        {clients.map((client, index) => (
          <p key={index}>{client.first_name + ' ' + client.last_name}</p>
        ))}
      </div>
    );
  };

  return !isReady ? (
    <Loading />
  ) : (
    <div className="vc-container">
      <div className="vc-container__scroll-wrapper">
        <div
          className={classnames('vc-container__header', {
            'drawer-open': drawerOpen
          })}
        >
          <h3 className="vc-container__header--heading">{event.title}</h3>
        </div>
        {event.zone ? (
          <MapContainer
            center={{
              lat: event.zone.address.coordinates.latitude,
              lng: event.zone.address.coordinates.longitude
            }}
            containerStyles={{
              height: '200px'
            }}
            parentStyles={{
              width: '100%',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              flex: 1
            }}
            data={[
              {
                lat: event.zone.address.coordinates.latitude,
                lng: event.zone.address.coordinates.longitude
              }
            ]}
            primaryMarker={{
              position: {
                lat: event.zone.address.coordinates.latitude,
                lng: event.zone.address.coordinates.longitude
              },
              title: 'Event Location',
              name: 'Event',
              icon: '/static/img/marker_green.png'
            }}
            circle={[
              {
                radius: event.zone.address.radius,
                center: {
                  lat: event.zone.address.coordinates.latitude,
                  lng: event.zone.address.coordinates.longitude
                },
                strokeColor: 'transparent',
                strokeOpacity: 0,
                strokeWeight: 5,
                fillColor: '#6cc049',
                fillOpacity: 0.2
              }
            ]}
          />
        ) : null}
        <div className="drawer-body vc-container__info-block">
          {buildRows().map((e, i) => (
            <InfoBlockRow key={`${e[0]}-${i}`} title={e[0]} value={e[1]} />
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  drawer: state.drawer
});

const mapDispatchToProps = {
  getCommunityEvent,
  closeDrawer
};

export default connect(mapStateToProps, mapDispatchToProps)(EventInfoDrawer);
