/**
 * Trigger an action expressing the start of a process.
 *
 * @param {string} model Suffix for action to trigger.
 * @param {object} payload Optional data passed when reducing.
 *
 * @returns {object}
 */
export function loadPending(model, payload = null) {
  return Object.assign(
    {
      type: 'LOAD_PENDING_' + model
    },
    payload && { payload }
  );
}

/**
 * Trigger an action expressing the end of a process that might've ended incorrectly.
 *
 * @param {string} model Suffix for action to trigger.
 * @param {object} payload Optional data passed when reducing.
 *
 * @returns {object}
 */
export function removePending(model, payload = null) {
  return Object.assign(
    {
      type: 'REMOVE_PENDING_' + model
    },
    payload && { payload }
  );
}

/**
 * Trigger an action expressing the successful end of a process. Payload should contain some data required when reducing.
 *
 * @param {string} model Suffix for action to trigger.
 * @param {object} payload Optional data passed when reducing.
 *
 * @returns {object}
 */
export function loadSuccessful(model, payload) {
  return {
    type: 'LOAD_SUCCESSFUL_' + model,
    payload
  };
}

/**
 * Trigger an action expressing a failed request. Payload should contain some data required when reducing.
 *
 * @param {string} model Suffix for action to trigger.
 * @param {object} payload Optional data passed when reducing - usually the error object.
 *
 * @returns {object}
 */
export function loadFailed(model, payload) {
  return {
    type: 'FAILED_REQUEST_' + model,
    payload
  };
}
