import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';

import { closeDrawer } from 'actions/actionCreators';
import { approveEvent, denyEvent, updateEventNotes } from 'utils/apiHelpers';
import { useToast } from 'components/Toast';
import BlockWrapper from '../common/BlockWrapper';
import Icon from 'components/Icon';
import InfoBlockRow from '../InfoBlockRow.js';
import { getStatusStyles } from 'components/Violations/violations-helpers';
import Button from 'components/Button';
import SingleEventNotes from '../common/SingleEventNotes';
import { MapMarker } from '../common/drawerHelpers';
import ActionButtons from './ActionButtons';
import Image from 'components/Image';
import MapContainer from 'components/Map';
import isEmpty from 'lodash/isEmpty';
import Recording from 'components/Drawer/common/Recording';
import NotesForm from 'components/Drawer/common/NotesForm';
import Zones from 'components/Clients/Location/Zones';
import ResultDataBox from 'components/Drawer/Violations/ResultDataBox';
import ClientNameLink from 'components/ClientNameLink';

function TodoClientEventDrawer({
  drawer: {
    drawerOpen,
    drawerProps: { todo, getCallBack, checkIn, violationData, edit }
  },
  closeDrawer
}) {
  if (edit == null) edit = true;
  const toast = useToast();
  const handlePrint = e => {
    e?.preventDefault();

    const body = document.querySelector('body');
    body.classList.add('print-violations');

    window.print();
  };

  const addressBlock = () => {
    if (todo.event.zone) {
      return (
        <div className="address-with-map-marker">
          <p>{todo.event.zone.address.address_text}</p>
          <p>
            <MapMarker
              addr={todo.event.zone.address.address_text}
              lat={todo.event.zone.address.coordinates.latitude}
              lng={todo.event.zone.address.coordinates.longitude}
              handleClose={closeDrawer}
            />
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  const displayTags = todo => {
    const eventTags = todo.event?.event_tags || [];
    let showTags = [];

    eventTags.filter(function(e) {
      const tag = e.name.replace(/-/g, ' ');
      showTags.push(tag);
    });
    showTags = showTags.join(', ');
    return showTags;
  };

  const handleUpdateTodo = action => {
    if (action == 'approve') {
      approveEvent(todo.related_external_id, todo.client_id, todo.external_id).then(response => {
        if (response.data.status == 'success') {
          getCallBack();
          closeDrawer();
          toast.addToast({
            duration: 3000,
            type: 'checkmark',
            content: `Event has been successfully approved.`
          });
        } else {
          toast.addToast({
            duration: 3000,
            type: 'warning',
            content: `There was an error approving the event, please try again.`
          });
        }
      });
    } else {
      denyEvent(todo.related_external_id, todo.client_id, todo.external_id).then(response => {
        if (response.data.status == 'success') {
          getCallBack();
          closeDrawer();
          toast.addToast({
            duration: 3000,
            type: 'checkmark',
            content: `Event has been successfully disapproved.`
          });
        } else {
          toast.addToast({
            duration: 3000,
            type: 'warning',
            content: `There was an error disapproving the event, please try again.`
          });
        }
      });
    }
  };

  const handleUpdateEventNotes = notes => {
    updateEventNotes(todo.related_external_id, notes).then(response => {
      if (response.data.status == 'success') {
        getCallBack();
        closeDrawer();
        toast.addToast({
          duration: 3000,
          type: 'checkmark',
          content: `Event notes have been successfully added.`
        });
      } else {
        toast.addToast({
          duration: 3000,
          type: 'warning',
          content: `There was an error updating the event notes, please try again.`
        });
      }
    });
  };

  function togglePlay(e) {
    e.preventDefault();
    this.setState({
      playingSound: !this.state.playingSound
    });
  }

  function stopSound() {
    this.setState({ playingSound: false });
  }

  const { ...todoStyle } = getStatusStyles(todo.status);

  function getLocation(violation) {
    const { violation_details } = violation;
    if (violation_details.length) {
      const dataArray = violation_details
        .map(detail => {
          let locationData;
          const meta = JSON.parse(detail?.meta);
          if (meta) {
            if (meta.exclusion_zone_data || meta.inclusion_zone_data) {
              locationData = meta.exclusion_zone_data ? meta.exclusion_zone_data : meta.inclusion_zone_data;
              try {
                locationData.coordinates = JSON.parse(locationData?.coordinates);
                if (locationData.coordinates) {
                  locationData.stateId = Object.keys(locationData.coordinates)[0];
                }
                if (meta.inclusion_zone_data) {
                  locationData.inclusion = true;
                }
                return locationData;
              } catch (e) {
                return false;
              }
            }
          }
          return false;
        })
        .filter(l => !!l);
      if (dataArray.length) {
        return dataArray[0];
      }
    }
    return null;
  }

  function Violation() {
    const {
      blocks,
      checkUrl,
      clientName,
      hasEvent,
      hasPos,
      hasMismatch,
      hasRecording,
      hasSpoofed,
      formattedStatus,
      fromPhone,
      playingSound,
      status,
      violation,
      errors,
      points,
      geozonePositions,
      disableDraw,
      result_data: resultData
    } = violationData;

    function violationHasType(types) {
      const { violation_details } = violation;
      if (!violation_details) {
        return false;
      }

      return !!violation_details.filter(({ type }) => types.includes(type)).length;
    }

    function isViolationOutOfBounds() {
      return violationHasType(['out_of_bounds']);
    }
    function isViolationOutsideInclusionZone() {
      return violationHasType(['outside_inclusion_zone']);
    }
    function isViolationInsideExclusionZone() {
      return violationHasType(['inside_exclusion_zone']);
    }

    const location = getLocation(violation);

    const { ...violationStyle } = getStatusStyles(status);

    const { ...hasPosLatLng } = hasPos;
    const { lat, lng, lenience } = hasEvent;
    const eventCoords = { lat, lng };
    const { lat: spoofedLat, lng: spoofedLng } = hasSpoofed;
    const spoofedCoords = { lat: spoofedLat, lng: spoofedLng };

    const mapConfiguration = {
      center: hasPosLatLng,
      polyline:
        !isEmpty(hasPos) && !isEmpty(hasEvent)
          ? {
              path: [hasPosLatLng, eventCoords]
            }
          : null,
      primaryMarker: {
        position: hasPosLatLng,
        title: 'Check-In Location',
        name: 'Checked In Here',
        icon: '/static/img/location-client-marker.svg'
      },
      secondaryMarkers: {
        markers: []
      },
      mapType: 'roadmap',
      circle: [],
      circles: []
    };
    let mapLegendBlock = false;

    if (isViolationOutOfBounds()) {
      mapConfiguration.polyline = null;
      if (hasPos.accuracy) {
        mapConfiguration.circle.push({
          radius: parseInt(hasPos.accuracy, 10), // lenience is in feet, we need to convert to meters
          center: hasPosLatLng,
          strokeColor: 'transparent',
          strokeOpacity: 0,
          strokeWeight: 5,
          fillColor: '#fc6700',
          fillOpacity: 0.2
        });
      }
      mapLegendBlock = (
        <>
          <div className="justified">
            <div>
              <Icon name="location-client-marker" width="32" />
              <span>CLIENT&apos;S LOCATION</span>
            </div>
            {!!hasEvent && (
              <>
                <div>
                  <Icon name="location-event-marker" width="32" />
                  <span>EVENT LOCATION</span>
                </div>
                <div>
                  <span className="event radius"></span>
                  <span>EVENT RADIUS</span>
                </div>
              </>
            )}
          </div>
        </>
      );
    }
    if (isViolationOutsideInclusionZone()) {
      mapConfiguration.polyline = null;
      mapLegendBlock = (
        <>
          <div className="complete two-column">
            <div>
              <Icon name="location-client-marker" width="32" />
              <span>CLIENT&apos;S LOCATION</span>
            </div>
            <div>
              <Icon name="location-inclusion-zone-marker" width="32" />
              <span>INCLUSION ZONE</span>
            </div>
          </div>
          <div className="complete two-column">
            <div>
              <span className="client-location-accuracy radius"></span>
              <span>CLIENT&apos;S LOCATION ACCURACY</span>
            </div>
            <div>
              <span className="inclusion-zone radius"></span>
              <span>INCLUSION ZONE RADIUS</span>
            </div>
          </div>
        </>
      );
    }
    if (isViolationInsideExclusionZone()) {
      mapConfiguration.polyline = null;
      mapLegendBlock = (
        <>
          <div className="complete two-column">
            <div>
              <Icon name="location-client-marker" width="32" />
              <span>CLIENT&apos;S LOCATION</span>
            </div>
            <div>
              <Icon name="location-exclusion-zone-marker" width="32" />
              <span>EXCLUSION ZONE</span>
            </div>
          </div>
          <div className="complete two-column">
            <div>
              <span className="client-location-accuracy radius"></span>
              <span>CLIENT&apos;S LOCATION ACCURACY</span>
            </div>
            <div>
              <span className="exclusion-zone radius"></span>
              <span>EXCLUSION ZONE RADIUS</span>
            </div>
          </div>
        </>
      );
    }
    if (hasSpoofed) {
      mapConfiguration.center = spoofedCoords;
      mapConfiguration.primaryMarker = {
        position: spoofedCoords,
        title: 'Spoofed Location',
        name: 'Spoof Attempt',
        icon: '/static/img/location-spoofed.svg'
      };
      mapConfiguration.zoom = 15;
      mapConfiguration.forceZoom = true;
      mapConfiguration.circle = [];
      mapLegendBlock = (
        <>
          <div className="complete">
            <Icon name="location-spoofed" width="32" /> <span>CLIENT&apos;S SPOOFED LOCATION</span>
          </div>
        </>
      );
    }
    if (hasEvent) {
      mapConfiguration.secondaryMarkers.markers.push({
        position: eventCoords,
        title: 'Event Location',
        name: 'Event',
        icon: '/static/img/location-event-marker.svg'
      });
      mapConfiguration.circle.push({
        radius: parseInt(lenience, 10) / 3.281, // lenience is in feet, we need to convert to meters
        center: eventCoords,
        strokeColor: 'transparent',
        strokeOpacity: 0,
        strokeWeight: 5,
        fillColor: '#00819B',
        fillOpacity: 0.2
      });
    }
    return (
      <div>
        <div className="vc-container__form">
          <h3 className="header-text">Alert Details</h3>
          <div className={classnames('vc-status', violationStyle)}>
            <span>{formattedStatus}</span>
          </div>
          {(!hasRecording && (checkUrl || hasPos)) || hasMismatch || hasSpoofed ? (
            <div className="vc-container__top-block">
              {checkUrl && !hasMismatch ? <Image src={checkUrl} /> : null}
              {!!hasPos || !!hasSpoofed ? (
                <MapContainer
                  {...mapConfiguration}
                  containerStyles={{
                    height: '200px'
                  }}
                  parentStyles={{
                    width: '100%',
                    marginLeft: '1rem',
                    flex: 1
                  }}
                  data={points}
                />
              ) : null}
              {hasMismatch && (
                <div className="has-mismatch">
                  {[
                    { url: hasMismatch.check_url, title: 'CHECK-IN IMAGE' },
                    {
                      url: hasMismatch.baseline_front_url,
                      title: 'BASELINE IMAGE'
                    }
                  ].map(url => (
                    <div className="mismatch" key={url.title}>
                      <p>{url.title}</p>
                      <Image src={url.url} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : null}
          {geozonePositions ? (
            <div className="vc-container__top-block">
              <Zones
                clientId={violation.client_id}
                mapOnly={true}
                geozonePositions={geozonePositions}
                disableDraw={disableDraw}
                locationsOnMap={[location]}
                mapConfiguration={{
                  mapType: 'roadmap',
                  inclusionZoneColor: '#6FBE49'
                }}
                showGeozonePolyline={true}
              />
            </div>
          ) : null}
          {mapLegendBlock && <div className="map-legend-block">{mapLegendBlock}</div>}
          {blocks.length &&
            blocks.map((block, i) => (
              <BlockWrapper
                key={`block-${i}`}
                clientName={<ClientNameLink client_id={violation.client_id} clientName={clientName} />}
                eventSubmitted={moment(violation.created).format('MMM D, YYYY h:mm A')}
                closeDrawer={closeDrawer}
                hasEvent={hasEvent}
                status={status}
                hasPos={hasPos}
                hasSpoofedLocation={!!hasSpoofed}
                block={block}
                checkUrl={
                  hasRecording ? (
                    <Recording
                      togglePlay={togglePlay(this)}
                      stopSound={stopSound}
                      checkUrl={checkUrl}
                      fromPhone={fromPhone}
                      playingSound={playingSound}
                    />
                  ) : (
                    false
                  )
                }
              />
            ))}
          {resultData ? <ResultDataBox resultData={resultData}></ResultDataBox> : null}
          <NotesForm
            showForm={true}
            title="Notes"
            notes={
              violation.notes.length
                ? violation.notes
                    .sort(
                      (first, next) =>
                        moment(first.created, 'YYYY-MM-DD H:m:s').format('X') -
                        moment(next.created, 'YYYY-MM-DD H:m:s').format('X')
                    )
                    .reverse()
                : []
            }
            errors={errors}
            /*handleUpdateViolation={handleUpdateViolationInterMediary}*/
            type="violation"
          />
        </div>
      </div>
    );
  }
  function CheckIn() {
    const {
      blocks,
      checkUrl,
      hasEvent,
      hasPos,
      hasRecording,
      hasSpoofed,
      fromPhone,
      notifications,
      playingSound,
      status,
      points
    } = checkIn;

    const { ...hasPosLatLng } = hasPos;
    const { lat, lng, lenience } = hasEvent;
    const eventCoords = { lat, lng };
    const { lat: spoofedLat, lng: spoofedLng } = hasSpoofed;
    const spoofedCoords = { lat: spoofedLat, lng: spoofedLng };

    return (
      <div>
        <div className="vc-container__form">
          <h3 className="header-text">Event Check-In</h3>
        </div>
        {!hasRecording && (checkUrl || hasPos) ? (
          <div className="vc-container__top-block">
            {checkUrl ? <Image src={checkUrl} /> : null}
            {hasPos ? (
              <MapContainer
                center={hasPosLatLng}
                containerStyles={{
                  height: '200px'
                }}
                parentStyles={{
                  width: '100%',
                  marginLeft: '1rem',
                  flex: 1
                }}
                data={points}
                primaryMarker={{
                  position: hasPosLatLng,
                  title: 'Check-In Location',
                  name: 'Checked In Here',
                  icon: '/static/img/marker_red.png'
                }}
                secondaryMarkers={
                  !!hasEvent || !!hasSpoofed
                    ? {
                        markers: [
                          !!hasEvent && {
                            position: eventCoords,
                            title: 'Event Location',
                            name: 'Event',
                            icon: '/static/img/marker_green.png'
                          },
                          !!hasSpoofed && {
                            position: spoofedCoords,
                            title: 'Spoofed Location',
                            name: 'Spoof Attempt',
                            icon: '/static/img/marker_spoofed.png'
                          }
                        ]
                      }
                    : null
                }
                circle={[
                  !!hasEvent && {
                    radius: parseInt(lenience, 10) / 3.281, // lenience is in feet, we need to convert to meters
                    center: eventCoords,
                    strokeColor: 'transparent',
                    strokeOpacity: 0,
                    strokeWeight: 5,
                    fillColor: '#6cc049',
                    fillOpacity: 0.2
                  },
                  !!hasPos &&
                    !!hasPos.accuracy && {
                      radius: parseInt(hasPos.accuracy, 10),
                      center: { lat: hasPos.lat, lng: hasPos.lng },
                      strokeColor: 'transparent',
                      strokeOpacity: 0,
                      strokeWeight: 5,
                      fillColor: '#ff6363',
                      fillOpacity: 0.2
                    }
                ]}
                polyline={
                  !isEmpty(hasPos) && !isEmpty(hasEvent)
                    ? {
                        path: [hasPosLatLng, eventCoords]
                      }
                    : null
                }
              />
            ) : null}
          </div>
        ) : null}
        {0 < blocks.length &&
          blocks.map((block, i) => (
            <BlockWrapper
              key={`block-${i}`}
              hasEvent={hasEvent}
              status={status}
              hasPos={hasPos}
              block={block}
              checkUrl={
                hasRecording ? (
                  <Recording
                    togglePlay={togglePlay(this)}
                    stopSound={stopSound}
                    checkUrl={checkUrl}
                    fromPhone={fromPhone}
                    playingSound={playingSound}
                  />
                ) : (
                  false
                )
              }
            />
          ))}
        <NotesForm
          title="Notifications"
          notes={notifications.map(note => {
            const { payload, delivered_time, status, send_time } = note;
            if (payload) {
              const {
                data: { message },
                headings: { en }
              } = JSON.parse(payload);
              return {
                created: status !== 'delivered' ? send_time : delivered_time,
                note: message,
                user: en,
                status
              };
            }
          })}
          noBottom={true}
          type="check in"
        />
      </div>
    );
  }

  return (
    <div className="vc-container">
      <div className="vc-container__scroll-wrapper">
        <div className="print-only">
          <img className="print-logo" src="/static/img/r7t-logo.jpg" />
          <h2>{moment(todo.created_at).format('MMM D, YYYY h:mm A')}</h2>
          <h2>Client Event for {todo.client.first_name + ' ' + todo.client.last_name}</h2>
        </div>
        <div
          className={classnames('vc-container__header', {
            'drawer-open': drawerOpen
          })}
        >
          <h3 className="vc-container__header--heading">Client Event</h3>
          <div className={classnames('vc-status', todoStyle)}>
            <span>{todo.status}</span>
          </div>
        </div>
        <div className="vc-container__print-button">
          <Button secondary micro handleButtonClick={handlePrint}>
            Print
          </Button>
        </div>
        <BlockWrapper
          clientName={
            <ClientNameLink
              client_id={todo.client_id}
              clientName={`${todo.client.first_name} ${todo.client.last_name}`}
            />
          }
          closeDrawer={closeDrawer}
          status={todo.status}
          block={{
            blockLabel: displayTags(todo)
          }}
        />
        <div className="drawer-body vc-container__info-block">
          {[
            ['Submitted', moment(todo.created_at).format('MMM D, YYYY h:mm A')],
            ['Event Title', todo.event.title],
            ['Event Date', moment(todo.event.start_date).format('MMMM D, YYYY')],
            ['Start Time', moment(todo.event.start_date + ' ' + todo.event.start_time).format('h:mm A')],
            ['End Time', moment(todo.event.start_date + ' ' + todo.event.end_time).format('h:mm A')],
            ['Event Description', todo.event.description],
            ['Place Name', todo.event?.zone?.name],
            ['Address', addressBlock],
            ['URL', todo.event.url]
          ].map((e, i) => (
            <InfoBlockRow key={`${e[0]}-${i}`} title={e[0]} value={e[1]} />
          ))}
        </div>
        <SingleEventNotes
          showForm={true}
          title="Notes"
          notes={todo.event.notes}
          handleUpdate={handleUpdateEventNotes}
        />
        {checkIn && <CheckIn />}
        {violationData && <Violation />}
        {edit && <ActionButtons drawerOpen={drawerOpen} handleUpdateTodo={handleUpdateTodo} />}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  drawer: state.drawer
});

export default connect(mapStateToProps, { closeDrawer })(TodoClientEventDrawer);
