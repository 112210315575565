export default function curfewEvent(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_ADD_CURFEW_EVENT':
      return {
        ...state,
        isAddingCurfewEvent: true
      };
    case 'LOAD_SUCCESSFUL_ADD_CURFEW_EVENT':
      return {
        ...state,
        isAddingCurfewEvent: false
      };
    case 'LOAD_PENDING_UPDATE_CURFEW_EVENT':
      return {
        ...state,
        isUpdatingCurfewEvent: true
      };
    case 'LOAD_SUCCESSFUL_UPDATE_CURFEW_EVENT':
      return {
        ...state,
        isUpdatingCurfewEvent: false
      };
    case 'LOAD_PENDING_DELETE_CURFEW_EVENT':
      return {
        ...state,
        isDeletingCurfewEvent: true
      };
    case 'LOAD_SUCCESSFUL_DELETE_CURFEW_EVENT':
      return {
        ...state,
        isDeletingCurfewEvent: false
      };
    case 'LOAD_PENDING_GET_CURFEW_EVENT':
      return {
        ...state,
        isLoadingCurfewEvent: true
      };
    case 'LOAD_SUCCESSFUL_GET_CURFEW_EVENT':
      return {
        ...state,
        isLoadingCurfewEvent: false
      };
  }
  return state;
}
