/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import NavLink from './NavLink';

function _sidebarActiveClassName(to) {
  const path = window.location.pathname;

  return path === to ||
    (to.includes('/reports/single') && path.includes('/reports/single')) ||
    (to === '/communications/inbox' && path.includes('inbox'))
    ? 'sidenavsub__link--active'
    : ''
}

const SideNavSub = ({ title, links }) => (
  <div className="sidenav__submenu">
    <strong>{title}</strong>
    <ul>
      {links.map(
        ({ to, label, access = null, foldMenu = null, className = '' }) => (
          (null === access || access) && (
            <NavLink
              key={to}
              to={to}
              activeClassName={_sidebarActiveClassName(to)}
              className={`sidenavsub__link ${className ? className : ''} `}
              nestedMenu={foldMenu}
            >
              {label}
            </NavLink>
          )
        ))}
    </ul>
  </div>
);

SideNavSub.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      access: PropTypes.any,
      foldMenu: PropTypes.any
    })
  ).isRequired
};

export default SideNavSub;
