import React, { Component } from 'react';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import classnames from 'classnames';

import {
  getViolationByViolationId,
  getResultDataByViolationId,
  catchErrors,
  handleUpdateViolation
} from 'utils/apiHelpers';
import { closeDrawer, getViolationsPolling } from 'actions/actionCreators';
import { makePoints } from '../common/drawerHelpers';
import { getStatusStyles } from 'components/Violations/violations-helpers';
import NotesForm from '../common/NotesForm';
import Image from 'components/Image';
import Icon from 'components/Icon';
import Loading from 'components/Helpers/Loading';
import BlockWrapper from '../common/BlockWrapper';
import ActionButtons from './ActionButtons';
import MapContainer from 'components/Map/';
import Recording from '../common/Recording';
import CheckEvaluator from 'utils/CheckEvaluator';
import Zones from 'components/Clients/Location/Zones/index';
import Button from 'components/Button';
import ResultDataBox from './ResultDataBox';
import Modal from 'components/Modal';
import Input from 'components/Input';
import ClientNameLink from 'components/ClientNameLink';

const initialState = {
  points: [{ lat: 0, lng: 0 }],
  blocks: [],
  checkUrl: null,
  violation: null,
  hasRecording: false,
  modalOpen: false,
  ready: false,
  reason: null,
  status: null,
  note: null,
  errors: null,
  playingSound: false,
  result_data: false
};

export class ViolationsDrawer extends Component {
  state = initialState;

  componentDidMount() {
    const {
      drawer: {
        drawerProps: { violationId }
      }
    } = this.props;
    if (violationId) {
      this.getViolation(violationId);
    }
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(nextState, this.state)) {
      return true;
    }
    return false;
  }

  /**
   * If zone data is available for either exclusion or inclusion, returns an object with
   * client_id: "959701"
   * coordinates: {"38":[2087]}
   * default_lenience: "300"
   * definition: "political"
   * description: ""
   * exclusion: ["1", "2"]
   * facility_id: "295"
   * history: null
   * id: "487"
   * lat: "0.00000000"
   * lng: "0.00000000"
   * name: "Location name"
   * removed: "0"
   * removed_time: null
   * scope: "client"
   * stateId: "38"
   *
   * @param {object} violation Additional data about the location that includes the zone data.
   *
   * @returns {object}
   */
  getLocation = violation => {
    const { violation_details } = violation;
    if (violation_details.length) {
      const dataArray = violation_details
        .map(detail => {
          let locationData;
          const meta = JSON.parse(detail?.meta);
          if (meta) {
            if (meta.exclusion_zone_data || meta.inclusion_zone_data) {
              locationData = meta.exclusion_zone_data
                ? meta.exclusion_zone_data
                : meta.inclusion_zone_data;
              try {
                locationData.coordinates = JSON.parse(
                  locationData?.coordinates
                );
                if (locationData.coordinates) {
                  locationData.stateId = Object.keys(
                    locationData.coordinates
                  )[0];
                }
                if (meta.inclusion_zone_data) {
                  locationData.inclusion = true;
                }
                return locationData;
              } catch (e) {
                return false;
              }
            }
          }
          return false;
        })
        .filter(l => !!l);
      if (dataArray.length) {
        return dataArray[0];
      }
    }
    return null;
  };

  getViolation = (violationId = null, violation = null) => {
    if (violationId) {
      getViolationByViolationId(violationId)
        .then(res => {
          if (res.data.status === 'success') {
            const { blocks, disableDraw = true, ...rest } = new CheckEvaluator(
              false,
              res.data.violation
            ).parseData();

            const hasSpoofed = blocks.find(block => !!block.hasSpoofed);

            this.setState(
              {
                violation: res.data.violation,
                // Generates an object with
                location: this.getLocation(res.data.violation), // restricted to just exclusion zone data
                blocks,
                hasSpoofed:
                  !!hasSpoofed && !!hasSpoofed.hasSpoofed
                    ? hasSpoofed.hasSpoofed
                    : false,
                ready: true,
                disableDraw,
                ...rest
              },
              this.makePoints
            );
          }
        })
        .catch(catchErrors);
      this.getResultData(violationId);
    } else if (violation) {
      const { blocks, ...rest } = new CheckEvaluator(
        false,
        violation
      ).parseData();

      const hasSpoofed = blocks.find(block => !!block.hasSpoofed);

      this.setState(
        {
          violation,
          blocks,
          hasSpoofed:
            !!hasSpoofed && !!hasSpoofed.hasSpoofed
              ? hasSpoofed.hasSpoofed
              : false,
          ready: true,
          ...rest
        },
        this.makePoints
      );
    }
  };

  componentWillUnmount() {
    this.setState(initialState);
  }

  makePoints = () => {
    const { hasEvent, hasPos, blocks } = this.state;
    const extra = blocks.find(block => !!block.hasSpoofed);
    const points = makePoints(hasEvent, hasPos, extra);
    this.setState({ points });
  };

  handleGetCallBack = violation => {
    this.getViolation(false, violation);
    this.props.drawer.drawerProps.getCallBack(violation);
  };

  violationHasType = types => {
    const { violation_details } = this.state.violation;
    if (!violation_details) {
      return false;
    }
    return !!violation_details.filter(({ type }) => types.includes(type))
      .length;
  };

  isViolationOutOfBounds = () => this.violationHasType(['out_of_bounds']);
  isViolationOutsideInclusionZone = () =>
    this.violationHasType(['outside_inclusion_zone']);
  isViolationInsideExclusionZone = () =>
    this.violationHasType(['inside_exclusion_zone']);

  handleUpdateViolationInterMediary = (status = false, note = false) => {
    const { id } = this.state.violation;
    if (status === 'excused') {
      if (
        this.violationHasType([
          'out_of_bounds',
          'inside_exclusion_zone',
          'outside_inclusion_zone'
        ])
      ) {
        this.setState({
          modalOpen: true,
          status: status ? status : this.state.violation.status,
          note: note
        });
      } else {
        this.handleUpdateViolation(id, status, note);
      }
    } else {
      this.handleUpdateViolation(id, status, note);
    }
  };

  handleUpdateViolation = (id, status, note, reason) => {
    handleUpdateViolation(id, status, note, reason)
      .then(res => {
        if (res.data.status === 'error') {
          this.setState({ errors: res.data.error });
        } else {
          this.handleGetCallBack(res.data.violation);
          this.props.getViolationsPolling(this.props.violations);
        }
      })
      .catch(catchErrors);
  };

  handleUpdateViolationWithReason = () => {
    const {
      status,
      note,
      reason,
      violation: { id }
    } = this.state;
    this.setState(
      {
        modalOpen: false
      },
      () => this.handleUpdateViolation(id, status, note, reason)
    );
  };

  hasLoaded = bool =>
    this.setState({
      hasLoaded: bool
    });

  togglePlay = e => {
    e.preventDefault();
    this.setState({
      playingSound: !this.state.playingSound
    });
  };

  stopSound = () => {
    this.setState({ playingSound: false });
  };

  handlePrint = e => {
    e?.preventDefault();

    const body = document.querySelector('body');
    body.classList.add('print-violations');

    window.print();
  };

  handleInputChange = ({ target: { value } }) =>
    this.setState({ reason: value });

  getResultData = violationId => {
    getResultDataByViolationId(violationId)
      .then(res => {
        if (res.data.status === 'success' && res.data.result_data) {
          this.setState({ result_data: res.data.result_data });
        }
      })
      .catch(catchErrors);
  };

  handleCloseModal = () =>
    this.setState({
      modalOpen: false,
      status: this.state.violation.status,
      note: null,
      reason: null
    });

  render() {
    const {
      blocks,
      checkUrl,
      clientName,
      hasEvent,
      hasPos,
      hasMismatch,
      hasRecording,
      hasSpoofed,
      formattedStatus,
      fromPhone,
      playingSound,
      ready,
      status,
      violation,
      location,
      modalOpen,
      errors,
      points,
      geozonePositions,
      disableDraw,
      result_data: resultData
    } = this.state;

    if (!ready) {
      return <Loading />;
    }

    const { ...violationStyle } = getStatusStyles(status);

    const {
      drawer: { drawerOpen },
      handleLightbox
    } = this.props;

    const { ...hasPosLatLng } = hasPos;
    const { lat, lng, lenience } = hasEvent;
    const eventCoords = { lat, lng };
    const { lat: spoofedLat, lng: spoofedLng } = hasSpoofed;
    const spoofedCoords = { lat: spoofedLat, lng: spoofedLng };

    const mapConfiguration = {
      center: hasPosLatLng,
      polyline:
        !isEmpty(hasPos) && !isEmpty(hasEvent)
          ? {
              path: [hasPosLatLng, eventCoords]
            }
          : null,
      primaryMarker: {
        position: hasPosLatLng,
        title: 'Check-In Location',
        name: 'Checked In Here',
        icon: '/static/img/location-client-marker.svg'
      },
      secondaryMarkers: {
        markers: []
      },
      mapType: 'roadmap',
      circle: [],
      circles: []
    };
    let mapLegendBlock = false;
    if (this.isViolationOutOfBounds()) {
      mapConfiguration.polyline = null;
      if (hasPos.accuracy) {
        mapConfiguration.circle.push({
          radius: parseInt(hasPos.accuracy, 10), // lenience is in feet, we need to convert to meters
          center: hasPosLatLng,
          strokeColor: 'transparent',
          strokeOpacity: 0,
          strokeWeight: 5,
          fillColor: '#fc6700',
          fillOpacity: 0.2
        });
      }
      mapLegendBlock = (
        <>
          <div className="justified">
            <div>
              <Icon name="location-client-marker" width="32" />
              <span>CLIENT&apos;S LOCATION</span>
            </div>
            {!!hasEvent && (
              <>
                <div>
                  <Icon name="location-event-marker" width="32" />
                  <span>EVENT LOCATION</span>
                </div>
                <div>
                  <span className="event radius"></span>
                  <span>EVENT RADIUS</span>
                </div>
              </>
            )}
          </div>
        </>
      );
    }
    if (this.isViolationOutsideInclusionZone()) {
      mapConfiguration.polyline = null;
      mapLegendBlock = (
        <>
          <div className="complete two-column">
            <div>
              <Icon name="location-client-marker" width="32" />
              <span>CLIENT&apos;S LOCATION</span>
            </div>
            <div>
              <Icon name="location-inclusion-zone-marker" width="32" />
              <span>INCLUSION ZONE</span>
            </div>
          </div>
          <div className="complete two-column">
            <div>
              <span className="client-location-accuracy radius"></span>
              <span>CLIENT&apos;S LOCATION ACCURACY</span>
            </div>
            <div>
              <span className="inclusion-zone radius"></span>
              <span>INCLUSION ZONE RADIUS</span>
            </div>
          </div>
        </>
      );
    }
    if (this.isViolationInsideExclusionZone()) {
      mapConfiguration.polyline = null;
      mapLegendBlock = (
        <>
          <div className="complete two-column">
            <div>
              <Icon name="location-client-marker" width="32" />
              <span>CLIENT&apos;S LOCATION</span>
            </div>
            <div>
              <Icon name="location-exclusion-zone-marker" width="32" />
              <span>EXCLUSION ZONE</span>
            </div>
          </div>
          <div className="complete two-column">
            <div>
              <span className="client-location-accuracy radius"></span>
              <span>CLIENT&apos;S LOCATION ACCURACY</span>
            </div>
            <div>
              <span className="exclusion-zone radius"></span>
              <span>EXCLUSION ZONE RADIUS</span>
            </div>
          </div>
        </>
      );
    }
    if (hasSpoofed) {
      mapConfiguration.center = spoofedCoords;
      mapConfiguration.primaryMarker = {
        position: spoofedCoords,
        title: 'Spoofed Location',
        name: 'Spoof Attempt',
        icon: '/static/img/location-spoofed.svg'
      };
      mapConfiguration.zoom = 15;
      mapConfiguration.forceZoom = true;
      mapConfiguration.circle = [];
      mapLegendBlock = (
        <>
          <div className="complete">
            <Icon name="location-spoofed" width="32" />{' '}
            <span>CLIENT&apos;S SPOOFED LOCATION</span>
          </div>
        </>
      );
    }
    if (hasEvent) {
      mapConfiguration.secondaryMarkers.markers.push({
        position: eventCoords,
        title: 'Event Location',
        name: 'Event',
        icon: '/static/img/location-event-marker.svg'
      });
      mapConfiguration.circle.push({
        radius: parseInt(lenience, 10) / 3.281, // lenience is in feet, we need to convert to meters
        center: eventCoords,
        strokeColor: 'transparent',
        strokeOpacity: 0,
        strokeWeight: 5,
        fillColor: '#00819B',
        fillOpacity: 0.2
      });
    }
    return (
      <>
        <div className="vc-container">
          <div className="vc-container__scroll-wrapper">
            <div className="print-only">
              <img className="print-logo" src="/static/img/r7t-logo.jpg" />
              <h2>{moment(violation.created).format('MMM D, YYYY h:mm A')}</h2>
              <h2>Alert for {clientName}</h2>
            </div>
            <div
              className={classnames('vc-container__header', {
                'drawer-open': drawerOpen
              })}
            >
              <h3 className="vc-container__header--heading">
                Alert Details
              </h3>
              <div className={classnames('vc-status', violationStyle)}>
                <span>{formattedStatus}</span>
              </div>
            </div>
            <div className="vc-container__print-button">
              <Button secondary micro handleButtonClick={this.handlePrint}>
                Print
              </Button>
            </div>
            {(!hasRecording && (checkUrl || hasPos)) ||
            hasMismatch ||
            hasSpoofed ? (
              <div className="vc-container__top-block">
                {checkUrl && !hasMismatch ? (
                  <Image
                    src={checkUrl}
                    handleClick={handleLightbox}
                    callback={this.hasLoaded}
                  />
                ) : null}
                {!!hasPos || !!hasSpoofed ? (
                  <MapContainer
                    {...mapConfiguration}
                    containerStyles={{
                      height: '200px'
                    }}
                    parentStyles={{
                      width: '100%',
                      marginLeft: '1rem',
                      flex: 1
                    }}
                    data={points}
                  />
                ) : null}
                {hasMismatch && (
                  <div className="has-mismatch">
                    {[
                      { url: hasMismatch.check_url, title: 'CHECK-IN IMAGE' },
                      {
                        url: hasMismatch.baseline_front_url,
                        title: 'BASELINE IMAGE'
                      }
                    ].map(url => (
                      <div className="mismatch" key={url.title}>
                        <p>{url.title}</p>
                        <Image
                          src={url.url}
                          handleClick={handleLightbox}
                          callback={this.hasLoaded}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : null}
            {geozonePositions ? (
              <div className="vc-container__top-block">
                <Zones
                  clientId={this.state.violation.client_id}
                  mapOnly={true}
                  geozonePositions={geozonePositions}
                  disableDraw={disableDraw}
                  locationsOnMap={[location]}
                  mapConfiguration={{
                    mapType: 'roadmap',
                    inclusionZoneColor: '#6FBE49'
                  }}
                  showGeozonePolyline={true}
                />
              </div>
            ) : null}
            {mapLegendBlock && (
              <div className="map-legend-block">{mapLegendBlock}</div>
            )}
            {blocks.length &&
              blocks.map((block, i) => (
                <BlockWrapper
                  key={`block-${i}`}
                  clientName={<ClientNameLink client_id={this.state.violation.client_id} clientName={clientName} />}
                  eventSubmitted={moment(violation.created).format('MMM D, YYYY h:mm A')}
                  closeDrawer={this.props.closeDrawer}
                  hasEvent={hasEvent}
                  status={status}
                  hasPos={hasPos}
                  hasSpoofedLocation={!!hasSpoofed}
                  block={block}
                  checkUrl={
                    hasRecording ? (
                      <Recording
                        togglePlay={this.togglePlay}
                        stopSound={this.stopSound}
                        checkUrl={checkUrl}
                        fromPhone={fromPhone}
                        playingSound={playingSound}
                      />
                    ) : (
                      false
                    )
                  }
                />
              ))}
            {resultData ? (
              <ResultDataBox resultData={resultData}></ResultDataBox>
            ) : null}
            <NotesForm
              showForm={true}
              title="Notes"
              notes={
                violation.notes.length
                  ? violation.notes
                      .sort(
                        (first, next) =>
                          moment(first.created, 'YYYY-MM-DD H:m:s').format(
                            'X'
                          ) -
                          moment(next.created, 'YYYY-MM-DD H:m:s').format('X')
                      )
                      .reverse()
                  : []
              }
              handleGetCallBack={this.handleGetCallBack}
              errors={errors}
              handleUpdateViolation={this.handleUpdateViolationInterMediary}
              type="violation"
            />
            <ActionButtons
              drawerOpen={drawerOpen}
              status={status}
              handleUpdateViolation={this.handleUpdateViolationInterMediary}
            />
          </div>
        </div>
        {modalOpen ? (
          <Modal
            isShowing={modalOpen}
            closeModal={this.handleCloseModal}
            header="Excuse Alert"
          >
            <p className="modal-message--no-border">
              Please explain the reason for excusing this alert.
            </p>
            <Input
              autoFocus
              Component="input"
              type="textarea"
              name="reason"
              handleChange={this.handleInputChange}
              handleBlur={this.handleInputChange}
              value={this.state.reason}
            />
            <div className="action-buttons">
              <Button
                primary
                handleButtonClick={this.handleUpdateViolationWithReason}
              >
                Confirm
              </Button>
              <Button
                primary
                transparent
                handleButtonClick={this.handleCloseModal}
              >
                Cancel
              </Button>
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  violations: state.violations,
  drawer: state.drawer
});

const mapDispatchToProps = {
  closeDrawer,
  getViolationsPolling
};

export default connect(mapStateToProps, mapDispatchToProps)(ViolationsDrawer);
