import React, { useMemo, useCallback } from 'react';
import { array, number, object, string } from 'yup';
import { useFormik } from 'formik';
import { set, trim } from 'lodash';

import Input from 'components/Input';
import Label from 'components/Label';
import Icon from 'components/Icon';
import curriculumUtil from 'utils/CurriculumUtil';
import { defaultForm } from 'components/Curriculum/Resource/helpers';
import CustomSelect from 'components/CustomSelect';
import ResourceUpload from 'components/Curriculum/ResourceUpload';
import useCurriculumItemProps from 'components/Curriculum/hooks/useCurriculumItemProps';
import CurriculumDrawerForm from 'components/Curriculum/CurriculumDrawerForm';
import { SelectOption } from 'components/Clients/ClientsGet/Info/BasicInfo/Schemas/helpers';

const BASE_FIELDS = ['form_id', 'link_url', 'file_url'];

const schema = object({
  title: string()
    .trim()
    .min(3, 'Title must be at least three characters')
    .max(40, 'Resources cannot be more than 40 characters long')
    .required('Title is required'),
  link_url: string()
    .url('Link must be a valid URL')
    .nullable(),
  file_url: string().nullable(),
  // need to test this...test?
  form_id: string().nullable(),
  curriculum_topic_ids: array()
    .of(number())
    .min(1, 'Resources must have at least one topic')
    .required('Resources must have at least one topic')
});

const ResourceDrawer = () => {
  const {
    item: resource,
    isEdit,
    isNew,
    isView,
    isDrawerOpen,
    isItemSubmitting,
    forms,
    topics,
    handleAdd,
    handleEdit
  } = useCurriculumItemProps({ selector: curriculumUtil.SELECTOR_KEYS.RESOURCES });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    isSubmitting,
    isValid
  } = useFormik({
    initialValues: curriculumUtil.getInitialValues({ isEdit, isView, original: resource, fallback: defaultForm }),
    validationSchema: schema,
    onSubmit: handleSubmitIntermediary,
    validateOnMount: false,
    validateOnBlur: true,
    isInitialValid: !isEdit,
    validateOnChange: true,
    enableReinitialize: true
  });

  const evaluateResourceData = useCallback(() => {
    if (!values.file_url && !values.link_url && !values.form_id) {
      for (const key of BASE_FIELDS) {
        setFieldError(key, 'At least one resource type is required');
      }

      return false;
    }

    return true;
  }, [values, setFieldError]);

  async function handleSubmitIntermediary() {
    if (evaluateResourceData()) {
      if (isEdit) {
        const data = {
          id: resource.id,
          title: values.title,
          curriculum_topic_ids: values.curriculum_topic_ids
        };

        if (values.file_url !== resource.file_url) {
          data.file_url = values.file_url;
        } else if (values.link_url !== resource.link_url) {
          data.link_url = values.link_url;
        } else if (values.form_id !== resource.form_id) {
          data.form_id = values.form_id;
        }
        handleEdit(data);
      } else if (isNew) {
        handleAdd(values);
      }
    }
  }

  function handleChangeIntermediary(e) {
    const { name, value } = set(e.target, 'value', trim(e.target.value));

    if (value === 'reset') {
      setFieldError(name, '');
      return setFieldValue(name, '');
    }

    const FILTERED_FIELDS = BASE_FIELDS.filter(f => f !== name);

    if (BASE_FIELDS.includes(name)) {
      for (const field of FILTERED_FIELDS) {
        setFieldValue(field, '', false);
        setFieldError(field, '');
      }
    }

    handleChange(e);

    /**
     * @NOTE We do this as a convenience to ensure some
     * level of consistency between Reconnect forms and
     * resource titles.
     */
    if (name === 'form_id' && !values.title) {
      const form = forms.find(f => +f.value === +value);

      if (form) {
        setFieldValue('title', form.label);
      }
    }
  }

  const isSubmitDisabled = useMemo(() => {
    return isSubmitting || !isValid || isItemSubmitting || isView;
  }, [isSubmitting, isValid, isItemSubmitting, isView]);

  const handleBlurIntermediary = useCallback(
    field => () => {
      if (!touched[field]) {
        setFieldTouched(field, true, true);
      }
    },
    [setFieldTouched, touched]
  );

  const formOptions = useMemo(() => {
    if (forms?.length) {
      return [new SelectOption('Reset Selection', 'reset', null, !values?.form_id), ...forms];
    }

    return forms;
  }, [values?.form_id, forms]);

  return (
    <CurriculumDrawerForm
      isDrawerOpen={isDrawerOpen}
      header={`${isEdit ? 'Edit' : isNew ? 'Add' : 'View'} Resource`}
      handleSubmit={handleSubmit}
      isSubmitDisabled={isSubmitDisabled}
      isSubmitting={isSubmitting || isItemSubmitting}
    >
      <Input
        autoFocus
        placeholder="Enter a unique resource title"
        value={values.title}
        tooltip={
          isEdit || isNew ? 'Resource titles must be both unique and a minimum of three characters long' : undefined
        }
        required={isEdit || isNew}
        handleChange={handleChange}
        handleBlur={handleBlur}
        id="title"
        label="Title"
        name="title"
        disabled={isView}
      />
      {touched.title && errors.title && (
        <div className="error-container">
          <Icon name="warning" />
          <Label htmlFor="title" alert>
            {errors.title}
          </Label>
        </div>
      )}
      <Input
        placeholder="Resource link"
        value={values.link_url}
        tooltip={isEdit || isNew ? 'Links may be any valid external URL, including those to PDF documents' : undefined}
        handleChange={handleChangeIntermediary}
        handleBlur={handleBlur}
        id="link_url"
        label="Link"
        name="link_url"
        disabled={!!isView || !!values.file_url || !!values.form_id}
      />
      {touched.link_url && errors.link_url && (
        <div className="error-container">
          <Icon name="warning" />
          <Label htmlFor="link_url" alert>
            {errors.link_url}
          </Label>
        </div>
      )}
      <CustomSelect
        id="form_id"
        isSearchable
        label="Form"
        name="form_id"
        disabled={!!isView || !!values.link_url || !!values.file_url}
        handleCustomSelectChange={handleChangeIntermediary}
        handleBlur={handleBlurIntermediary('form_id')}
        placeholder="Select a form"
        tooltip=""
        value={values.form_id}
        options={formOptions}
      />
      {touched.form_id && errors.form_id && (
        <div className="error-container">
          <Icon name="warning" />
          <Label htmlFor="form_id" alert>
            {errors.form_id}
          </Label>
        </div>
      )}
      <div className="uploader-wrapper">
        <Label htmlFor="file_url" bold>
          File
        </Label>
        <ResourceUpload
          handleChange={handleChangeIntermediary}
          name="file_url"
          value={values.file_url?.startsWith('https://c2t-curriculum-files') ? undefined : values.file_url}
          presignedUrl={values.file_url?.startsWith('https://c2t-curriculum-files') ? values.file_url : undefined}
          handleBlur={handleBlurIntermediary('file_url')}
          disabled={!!isView || !!values.form_id || !!values.link_url}
        />
      </div>
      {touched.file_url && errors.file_url && (
        <div className="error-container">
          <Icon name="warning" />
          <Label htmlFor="file_url" alert>
            {errors.file_url}
          </Label>
        </div>
      )}
      <CustomSelect
        id="curriculum_topic_ids"
        multiple
        isSearchable
        label="Topics"
        name="curriculum_topic_ids"
        disabled={isView}
        handleCustomSelectChange={handleChange}
        handleBlur={handleBlurIntermediary('curriculum_topic_ids')}
        placeholder="Select topics"
        required={isEdit || isNew}
        tooltip={!isView ? 'Topics are for your convenience and are never shown to clients' : undefined}
        value={values.curriculum_topic_ids}
        options={topics}
      />
      {touched.curriculum_topic_ids && errors.curriculum_topic_ids && (
        <div className="error-container">
          <Icon name="warning" />
          <Label htmlFor="curriculum_topic_ids" alert>
            {errors.curriculum_topic_ids}
          </Label>
        </div>
      )}
    </CurriculumDrawerForm>
  );
};

export default ResourceDrawer;
