class ControlConfig {
  constructor(name, spec) {
    this.name = name;
    this.spec = spec
  }

  get defaultValue() {
    return this.spec.default()
  }

  getDefaultValue(control, stateValue) {
    if (stateValue !== undefined) return stateValue;
    if (control._default)
      return control._default.value;
    return this.defaultValue;
  }
}

export default ControlConfig;