import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import WindowReportHeader from './WindowReportHeader';
import MiniReportTable from './MiniReportTable';
import testsLookup from 'selectors/testsSelectors';

const WindowReport = ({ filteredReport: { summary = {}, clients = [] } }) => {
  const tests = useSelector(state => ({ ...state.tests, list: testsLookup(state) }));
  
  const { total, testingToday, notTestingToday } = useMemo(() => {
    let total = 0;
    const testingToday = [];
    const notTestingToday = [];

    for (const client of Object.values(clients)) {
      total += 1;

      if (client.test_today) {
        testingToday.push(client);
      } else {
        notTestingToday.push(client);
      }
    }

    return { total, testingToday, notTestingToday }
  }, [clients]);

  return (
    <>
      <WindowReportHeader
        condition={!summary.testing || !total}
        className="open"
        heading="Required to Test"
        message="No Clients In Window"
      />
      <MiniReportTable
        condition={summary.testing > 0 && !!total}
        clients={testingToday}
        tests={tests}
      />
      <WindowReportHeader
        condition={summary.testing === testingToday.length}
        className="excused"
        heading="Not Required to Test"
        message="All Eligible Clients Are Testing Today"
      />
      <MiniReportTable
        condition={summary.testing < testingToday.length}
        clients={notTestingToday}
        tests={tests}
      />
    </>
  );
}

export default WindowReport;