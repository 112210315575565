import { useState, useCallback } from 'react';

const DEFAULT_STATE = {
  note: '',
  date: null
};

const useNotes = (data = DEFAULT_STATE) => {
  const [state, setState] = useState({ ...data });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [enableEdit, setEnableEdit] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = useCallback(
    key => e => {
      if ('persist' in e && typeof e.persist === 'function') {
        e.persist();
      }

      setState(prev => ({ ...prev, [key]: e.target.value }));
    },
    []
  );

  const startEdit = useCallback(value => {
    setEnableEdit(!value);
  }, []);

  return {
    note: state,
    setState,
    isLoading,
    setIsLoading,
    isSaving,
    setIsSaving,
    enableEdit,
    setEnableEdit,
    handleChange,
    startEdit,
    isEditing,
    setIsEditing
  };
};

export default useNotes;
