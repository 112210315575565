import React from 'react';
import classnames from 'classnames';

import Icon from 'components/Icon';

const ActionButtons = ({
  approveCompleted,
  deleteCompleted,
  closeDrawer,
  drawerOpen
}) => (
  <div
    className={classnames('vc-container__action-buttons', {
      'drawer-open': drawerOpen
    })}
  >
    {approveCompleted && (
      <button
        type="button"
        data-action="approve"
        className={'ab-excuse approve'}
        onClick={approveCompleted}
      >
        <Icon name="checkmark" size="20" />
        &nbsp;
        <span className="ab-status">APPROVE</span>
      </button>
    )}
    {deleteCompleted && (
      <button
        type="button"
        data-action="delete"
        className={'ab-excuse disapprove'}
        onClick={deleteCompleted}
      >
        <Icon name="crossmark" size="20" />
        &nbsp;
        <span className="ab-status disapprove">DELETE</span>
      </button>
    )}
    <button
      type="button"
      data-action="setToDraft"
      className={'ab-excuse ab-open'}
      onClick={closeDrawer}
    >
      <Icon name="warning" size="20" />
      &nbsp;
      <span className="ab-status">CANCEL</span>
    </button>
  </div>
);

export default ActionButtons;
