import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { getToRender } from 'components/FormProvider/utils';

import Button from 'components/Button';
import ActionBarWrapper from 'components/ActionBarWrapper';
import FormContext from 'components/FormProvider/FormContext';

const ActionBar = ({ buttons, formId, ...props }) => {
  const formContext = useContext(FormContext);
  const { getStateValue, handleFormButtons, handleSubmitForm, errors, state, hasChanged } = formContext;

  const { pathname } = useLocation();

  const isDisabled = useMemo(() => {
    const list = [];
    for (const btn of buttons) {
      list.push(!getToRender(btn.renderChecks, false, getStateValue) || !!btn.disabled);
    }
    return list;
  }, [buttons, errors, state, hasChanged]);

  const mainArea = document?.querySelector('.main-area');
  if (!mainArea?.classList?.contains('has-action-bar')) {
    mainArea?.classList?.add('has-action-bar');
  }

  function handleButtonClick(btn) {
    if ('string' === typeof btn.action) {
      // for form functions, such as reset
      props[btn.action](btn.callback);
    } else if (btn.type === 'submit') {
      handleSubmitForm();
    } else {
      handleFormButtons(btn.callback);
    }
  }

  return (
    <ActionBarWrapper shorten={pathname.match(/^\/(clients|settings|reports)/)}>
      {buttons.map((btn, i) => {
        if (btn.render || getToRender(btn.renderChecks, false, getStateValue)) {
          return (
            <Button
              key={`action-bar-button-${i}`}
              type={btn.type}
              disabled={isDisabled[i]}
              handleButtonClick={(e) => {
                e.preventDefault()
                handleButtonClick(btn);
              }}
              primary={btn.primary}
              secondary={btn.secondary}
              transparent={btn.transparent}
              form={btn.type === 'submit' ? formId : undefined}
            >
              {btn.label}
            </Button>
          );
        }
      })}
    </ActionBarWrapper>
  );
};

ActionBar.propTypes = {
  buttons: PropTypes.array.isRequired,
  formId: PropTypes.string
};

export const actionButtonsType = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.arrayOf(
    PropTypes.shape({
      primary: PropTypes.bool,
      secondary: PropTypes.bool,
      transparent: PropTypes.bool,
      micro: PropTypes.bool,
      type: PropTypes.string,
      label: PropTypes.string,
      render: PropTypes.bool,
      action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      renderChecks: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.shape({
            key: PropTypes.string,
            group: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
            method: PropTypes.string
          })
        ])
      )
    })
  )
]);

export default ActionBar;
