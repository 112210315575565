import { keys, pickBy } from 'lodash';
import moment from 'moment';

const dayNumberObj = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6
};

export function setEventSeriesRules(data) {
  const ruleValueSpecifications = {
    daily_repeat: () => {
      return [
        {
          rule: 'daily_repeat',
          value: ''
        }
      ];
    },
    monthly: () => {
      return [
        {
          rule: 'monthly',
          value: ''
        }
      ];
    },
    week_day_repeat: () => {
      if (Object.values(data?.days ?? {}).some(v => !!v)) {
        let days = keys(pickBy(data.days)); // extract all truthy keys { monday: true } === ['monday']
        days = days.map(d => dayNumberObj[d]).join('|'); // convert day string to Pythonic int ['monday'] === [0]
        let rules = [
          {
            rule: 'week_day_repeat',
            value: days
          }
        ];
        if (data?.weeks > 1) {
          rules.push({
            rule: 'weekly_repeat',
            value: data.weeks + ''
          });
        }
        return rules;
      }
      return false;
    },
    weekly: () => {
      // weekly means that we just pass in the week day repeat (say Monday)
      return [
        {
          rule: 'week_day_repeat',
          value: dayNumberObj[data.start_date.format('dddd').toLowerCase()] + ''
        }
      ];
    }
  };

  const rules = [data.frequency];
  data.event_series_rules = [];
  rules.forEach(rule => {
    if (
      ruleValueSpecifications[rule] &&
      ruleValueSpecifications[rule]() !== false
    ) {
      data.event_series_rules = data.event_series_rules.concat(
        ruleValueSpecifications[rule]()
      );
    }
  });
  delete data.days;
  delete data.weeks;
  delete data.frequency;
}

const numberDayObj = {
  0: 'monday',
  1: 'tuesday',
  2: 'wednesday',
  3: 'thursday',
  4: 'friday',
  5: 'saturday',
  6: 'sunday'
};

export function getCustomFrequency(event) {
  const { frequency, frequency_rule_values } = event;

  const frequencies = frequency?.split(',') ?? [];
  const rule_values = frequency_rule_values?.split(',') ?? [];

  // set default
  let customFrequency = {
    days: {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false
    },
    weeks: 1
  };
  for (let i = 0; i < frequencies.length; i++) {
    switch (frequencies[i]) {
      case 'week_day_repeat':
        customFrequency.frequency = 'week_day_repeat';
        customFrequency.days = rule_values[i]
          ?.split('|')
          .reduce((acc, curr) => ({ ...acc, [numberDayObj[curr]]: true }), {});
        break;
      case 'weekly_repeat':
        customFrequency.weeks = parseInt(rule_values[i], 10);
        break;
    }
  }
  delete event.frequency_rule_values;
  return customFrequency;
}

export function removeUnusedKeys(event) {
  const unusedKeys = [
    'approval_status',
    'attach_photo',
    'check_in',
    'event_tags',
    'frequency_rule_values',
    'never_ends',
    'selectedClients',
    'numerator',
    'denominator',
    'check_in_setting_value'
  ];
  for (let i = 0; i < unusedKeys.length; i++) {
    if (event[unusedKeys[i]] !== undefined) {
      delete event[unusedKeys[i]];
    }
  }
}

function valueIsAnExternalId(value) {
  return !isNaN(value) && typeof value !== 'boolean';
}

export function addAction(data, type, key) {
  if (data[key]) {
    const action = {
      action_type: type
    };
    if (valueIsAnExternalId(data[key])) {
      action.action_value = data[key];
    } else if (key === 'check_in_setting' && data[key] === 'random_time') {
      action.action_value = `${data.numerator}/${data.denominator}`;
    }
    data.actions.push(action);
  }
  delete data[key];
}

export function addMedia(data, type, key) {
  if (data[key]) {
    const media = {
      media_type: type,
      value: data[key]
    };

    data.media.push(media);
  }
  delete data[key];
}

export function setReminders(data) {
  // if we don't have reminders, let's remove the default ones
  if (!data.reminders_checkbox) {
    delete data.reminders;
  }
  delete data.reminders_checkbox;
}

export function setTags(data) {
  if (data.tags) {
    data.tags = [data.tags];
  } else {
    delete data.tags;
  }
}

export function setAddressCoordinates(data) {
  if (data.single_address && data.single_address.address_text !== '') {
    if (data.single_address.coordinates) {
      data.coordinates = data.single_address.coordinates;
      data.address = data.single_address.address_text;
    } else {
      delete data.coordinates;
    }

    if (data.single_address && data.single_address.lenience) {
      data.lenience = data.single_address.lenience;
    } else {
      delete data.lenience;
    }
  } else {
    data.address = '';
  }
  delete data.single_address;
}

function formatTime(time) {
  return moment(time, 'HH:mm:ss').format('hh:mm A');
}

export function formatEvent(event) {
  event.start_date = moment(event.start_date);
  event.end_date = event.end_date ? moment(event.end_date) : null;
  event.never_ends = event.end_date === null;
  event.start_time = formatTime(event.start_time);
  event.end_time = formatTime(event.end_time);
  event.reminders_checkbox = event.reminders?.length > 0;
  event.address = event.zone?.address?.address_text ?? '';
  event.lenience = Math.ceil(event.zone?.address?.radius * 3.281) ?? null;
  // set our defaults
  event.numerator = 1;
  event.denominator = 1;
  if (event.check_in_setting == 'random_time' && event.check_in_setting_value) {
    [event.numerator, event.denominator] = event.check_in_setting_value.split(
      '/'
    );
    delete event.check_in_setting_value;
  }
  event.tags = event.event_tags?.length > 0 ? event.event_tags[0].name : '';
}

export const formTimeCalculations = () => {
  const startTimeBase = moment().add(30, 'minutes');
  const remainder = 30 - (startTimeBase.minute() % 30);

  return {
    minDeadlineGrace: 15,
    adjustedStartTime: moment(startTimeBase).add(remainder, 'minutes')
  }
}

export const eventInitialState = (minDeadlineGrace, adjustedStartTime) => {
  return {
    start_date: moment(),
    start_time: moment(adjustedStartTime).format('hh:mm A'),
    end_date: moment(),
    title: '',
    description: '',
    days_length: 1,
    notes: '',
    end_time: moment(adjustedStartTime)
      .add(1, 'hour')
      .format('hh:mm A'),
    days: {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false
    },
    weeks: 1,
    reminders_checkbox: true,
    reminders: [
      {
        value: 5,
        unit: 'minutes'
      },
      {
        value: 1,
        unit: 'hours'
      }
    ],
    frequency: 'time_period',
    never_ends: false,
    deadline_grace: minDeadlineGrace,
    address: '',
    check_in_setting: 'never',
    numerator: 1,
    denominator: 1,
    url: '',
    lenience: 500,
    coordinates: {},
    tags: '',
    single_address: {
      lenience: 500,
      coordinates: {},
      address_text: ''
    },
    hide_from_app: false
  }
};