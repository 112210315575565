import { useRef, useEffect, useState } from 'react';

import useInView from 'components/Curriculum/hooks/useInView';

const SCROLL_OPTIONS = { behavior: 'smooth' };
const INTERSECTION_OPTIONS = { threshold: 1 };

const useScrollTo = (scrollOptions = SCROLL_OPTIONS, intersectionOptions = INTERSECTION_OPTIONS) => {
  const [shouldScrollTo, setShouldScrollTo] = useState(false);
  const ref = useRef(null);
  const isIntersecting = useInView(ref, intersectionOptions);

  useEffect(() => {
    if (ref.current && shouldScrollTo && !isIntersecting) {
      ref.current?.scrollIntoView(scrollOptions);
      setShouldScrollTo(false);
    }
  }, [shouldScrollTo, isIntersecting]);

  return [ref, setShouldScrollTo];
}

export default useScrollTo;