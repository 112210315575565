import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import Label from 'components/Label';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Button from 'components/Button';

import './style.scss';

const EMPTY_URL = {
  url: ''
};

const Links = ({ handleChange, name, value, ...props }) => {
  value = value ? value : [EMPTY_URL];

  const handleRemove = index => {
    const newValue = cloneDeep(value).filter((_, i) => i !== index);
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleLinkChange = (e, index) => {
    let {
      target: { name, value: url }
    } = e;
    const newObj = value[index];
    newObj.url = url;
    const newValue = cloneDeep(value).map((p, i) => (i === index ? newObj : p));
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleButtonClick = () => {
    const newValue = cloneDeep(value);
    newValue.push(EMPTY_URL);
    const e = { target: { name, value: newValue } };
    handleChange(e, props.blockGroup);
  };

  return (
    <div className="links-multi-input-container">
      {value.map((v, index) => {
        return (
          <div key={index} className="links-single">
            <div>
              <Label htmlFor={`${name}_url_${index}`} bold>
                URL {index + 1}
              </Label>
              <Input
                name={`${name}_url_${index}`}
                value={v.url}
                placeholder="https://"
                handleChange={e => handleLinkChange(e, index)}
              />
            </div>
            <div className="remove-box">
              <span onClick={() => handleRemove(index)}>
                <Icon name="minus" />
              </span>
            </div>
          </div>
        );
      })}
      <Button
        secondary
        transparent
        micro
        handleButtonClick={handleButtonClick}
        disabled={value.length ? value.some(v => v.url === '') : false}
      >
        Add {value.length > 1 ? 'an additional ' : ''}URL
      </Button>
    </div>
  );
};

Links.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default Links;
