export const addToastToStore = toast => {
  return {
    type: 'ADD_TOAST',
    payload: toast
  };
};

export const removeToastByIndex = index => {
  return {
    type: 'REMOVE_TOAST',
    payload: index
  };
};
