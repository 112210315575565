import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';
import { Typography } from '@material-ui/core';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';

import Image from 'components/Image';
import IconButton from 'components/IconButton';
import { useStyles } from 'components/Clients/BaselineImages/styles';
import { BaselineContext } from './BaselineContext';
import { AppContext } from 'components/Context/AppContext';

const Images = () => {
  const { state, setImageRef, handleSingleClearModal, handleLoadImage } = useContext(BaselineContext);
  const classes = useStyles();
  const { handleLightbox } = useContext(AppContext);

  const handleOpenLightbox = useCallback((img_url, rotate) => () => {
    handleLightbox(img_url, rotate);
  }, [handleLightbox]);

  if (!state?.images?.length) return null;

  return state.images.map(({ id, img_url, type, face_id, label }) => {
    const rotate = img_url in state.shouldRotate;
    const readyRenderLabel = img_url in state.loadedImages;

    return (
      <div key={`${id}-${img_url}`} className={classes.bordered}>
        {readyRenderLabel && (
          <div className={classes.imageHeader}>
            <Typography variant="h6" component="label">{label}</Typography>
            <IconButton onClick={handleSingleClearModal(face_id)} label="Clear Image" Icon={DeleteOutlineRoundedIcon} />
          </div>
        )}
        <div className={`baseline-image${rotate ? ' rotate' : ''}`}>
          <div ref={setImageRef(img_url)} className={classnames('image-wrapper', { rotate: !!rotate })}>
            <Image
              disabled={!state.active}
              handleClick={handleOpenLightbox(img_url, rotate)}
              src={img_url}
              alt={`Baseline image ${label}`}
              isBaseline
              callback={handleLoadImage(img_url)}
            />
          </div>
        </div>
      </div>
    );
  });
}

export default Images;