import * as yup from 'yup';

import ControlConfig from 'components/Form/Model/ControlConfig';

class SelectConfig extends ControlConfig {
  constructor() {
    super('select', yup.lazy(val => (Array.isArray(val) ? yup.array().of(yup.string()) : yup.string())));
  }

  getDefaultValue(control, stateValue) {
    let actualValue = stateValue
    if (control.allSelected &&
      control.options &&
      control.options.length) {
      actualValue = control.options.map(o => o.value);
    }
    if (typeof actualValue === 'string' && actualValue.indexOf(';;;') > 0) {
      actualValue = actualValue.split(';;;');
    }
    return actualValue
  }
}

export default SelectConfig;