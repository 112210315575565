/**
 * Internal dependencies
 */
import { loadPending, removePending, loadSuccessful } from './helperActions';
import {
  getClientList,
  catchErrors,
  requestClientCompletedForms as requestClientCompletedFormsAPI,
  requestSingleCompletedForm as requestSingleCompletedFormAPI,
  requestClientDevices as requestClientDevicesAPI,
  updateLocation as updateLocationAPI
} from 'utils/apiHelpers';

export function getClients(clients) {
  let hash = clients.doneInitialLoad && clients.hash;

  return dispatch => {
    // set to pending
    dispatch(loadPending('CLIENTS'));

    if (hash) {
      // lets just compare first, attempt to save some bandwidth
      getClientList(true, hash)
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending('CLIENTS'));
          } else {
            dispatch(loadSuccessful('CLIENTS', res.data));
          }
        })
        .catch(catchErrors);
    } else {
      getClientList(true, false)
        .then(res => {
          // handle errors?
          dispatch(loadSuccessful('CLIENTS', res.data));
        })
        .catch(catchErrors);
    }
  };
}

export function requestClientDevices(clientId) {
  return dispatch => {
    dispatch({
      type: 'LOAD_PENDING_CLIENT_DEVICES',
      payload: { clientId }
    });

    requestClientDevicesAPI(clientId)
      .then(res => {
        res.data.clientId = clientId;
        dispatch(loadSuccessful('CLIENT_DEVICES', res.data));
      })
      .catch(catchErrors);
  };
}

export function requestClientCompletedForms(clientId) {
  return dispatch => {
    dispatch({
      type: 'LOAD_PENDING_CLIENT_COMPLETED_FORMS',
      payload: { clientId }
    });

    requestClientCompletedFormsAPI(clientId)
      .then(res => {
        res.data.clientId = clientId;
        dispatch(loadSuccessful('CLIENT_COMPLETED_FORMS', res.data));
      })
      .catch(catchErrors);
  };
}

export function requestSingleCompletedForm(clientId, entryId, checkId) {
  return dispatch => {
    dispatch({
      type: 'LOAD_PENDING_SINGLE_COMPLETED_FORM',
      payload: { clientId, entryId }
    });

    requestSingleCompletedFormAPI(entryId, checkId)
      .then(res => {
        res.data.clientId = clientId;
        res.data.entryId = entryId;
        dispatch(loadSuccessful('SINGLE_COMPLETED_FORM', res.data));
      })
      .catch(catchErrors);
  };
}

/**
 * Action dispatched to assign a location to a client.
 *
 * @param {string} locationId The id of the location that will be assigned.
 * @param {string} clientId   The id of the client that the location will be assigned to.
 *
 * @returns {function}
 */
export function assignLocation(locationId, clientId) {
  return dispatch => {
    dispatch(loadPending('ASSIGN_LOCATION', { clientId }));
    updateLocationAPI({
      id: locationId,
      client_id: clientId,
      scope: 'facility'
    })
      .then(res => {
        dispatch(
          loadSuccessful('ASSIGN_LOCATION', {
            clientId,
            location: res.data.location
          })
        );
      })
      .catch(catchErrors);
  };
}
