/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import './style.scss';

const TableCell = ({ children, ...props }) => {
  return (
    <td className={`base-td${props.className ? ` ${props.className}` : ''}`} onClick={props.onClick && props.onClick}>
      {children}
    </td>
  );
};

export default TableCell;
