import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const AuthContext = React.createContext();

class AuthProvider extends PureComponent {
  render() {
    const { value, children } = this.props;
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }
}

AuthContext.propTypes = {
  value: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default AuthProvider;
