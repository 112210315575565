import React, { useEffect, useState } from 'react';

import TableBuilder, { Column } from 'utils/TableBuilder';
import Table from 'components/Curriculum/Table';

const builder = new TableBuilder()
  .addColumns(
    [
      new Column('Name', 'name').isTitle(),
      new Column('Tests', 'tests').isList(),
    ]
  );

function getTestLines(testLines, tests) {
  const testsToTake = [];

  for (const testLine of testLines) {
    if (testLine.test_today) {
      testsToTake.push(tests[testLine.test_id].name);
    }
  }

  return testsToTake;
}

const MiniReportTable = ({ condition = false, clients = [], tests = { list: {} } }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (condition && !!clients?.length && !data.length) {
      setData(clients.map(c => ({
        name: `${c.client_info.last_name}, ${c.client_info.first_name}`,
        tests: getTestLines(c.test_lines, tests.list),
      })));
    }
  }, [condition, clients, tests]);

  if (!condition) return null;

  return (
    <Table
      data={data}
      columns={builder.table.columns}
      hasSearch={false}
      emptyMessage={["There are no tests for this report"]}
    />
  );
}

export default MiniReportTable;