import React from 'react';
import * as classNames from 'classnames';

import './style.scss';

const DEFAULT_MESSAGE = ['No results found.'];

/**
 * @component
 * @example
 *
 * ```js
 * const App = () => {
 *  return (
 *    <EmptyTable
 *      message={[
 *        // the first string will always be bolded
 *        "No results found",
 *        "To get started, {{ACTION}}."
 *      ]}
 *    />
 *  );
 * }
 * ```
 * @param {object} props
 * @param {string} props.message
 * @param {boolean} props.noShadow
 * @returns {JSX.Element}
 */
const EmptyTable = ({ message = DEFAULT_MESSAGE, noShadow = false }) => {
  return (
    <div className={classNames('empty-table', { noShadow })}>
      {message.map((part, i) => (
        <p key={`empty-table-part-${i}`} dangerouslySetInnerHTML={{ __html: part }} />
      ))}
    </div>
  );
};

export default EmptyTable;
