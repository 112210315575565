import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import { Modal } from '@material-ui/core';
import { ToastProvider } from 'components/Toast';
import theme from '../../theme';
import useApiError from 'hooks/useApiError';
import '../../scss/_tailwind.scss';

export const AppContext = React.createContext({});

export function AppProvider({ value, children }) {
  const [error, { set, clear }] = useApiError();

  return (
    <AppContext.Provider value={{ ...value, setAppError: set }}>
      <MuiThemeProvider theme={theme}>
        <ToastProvider>
          {children}
          <Modal open={!!error} onClose={clear}>
            <div className={`modal MappingSelector`}>
              <div className="modal__header flex flex-row justify-between">
                <h3>{error?.title}</h3>
              </div>
              <div className="modal__content flex flex-col">
                <div className={'flex flex-col gap-4'}>
                  <div className={'flex flex-col'}>
                    <div className="modal-message">{error?.userMessage}</div>
                    {!error?.expected && (
                      <div>
                        If this error persists please email our support team at&nbsp;
                        <a href="mailto:support@reconnect.io">support@reconnect.io</a>.
                      </div>
                    )}
                  </div>
                  {error && <div className={'dev-error'}>{error?.details}</div>}
                  <div className="action-buttons flex flex-row justify-between">
                    <Button variant="outlined" onClick={() => window.location.reload()}>
                      REFRESH
                    </Button>
                    <Button variant="contained" color="secondary" onClick={clear}>
                      OK
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </ToastProvider>
      </MuiThemeProvider>
    </AppContext.Provider>
  );
}
