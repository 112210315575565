import React, { useMemo } from 'react';
import moment from 'moment';

import StatusBlock from '../common/StatusBlock';

function getSpecifics(request) {
  const specifics = [];

  specifics.push(`Request Deadline: ${moment(r.request_deadline, 'YYYY-MM-DD H:mm:ss').format('h:mm A')}`);

  let checkIn = 'Check-In: ';

  if (request.check_time) {
    checkIn += moment(r.check_time, 'YYYY-MM-DD H:mm:ss').format('h:mm A');
  } else {
    if (moment().isBefore(r.request_deadline, 'YYYY-MM-DD H:mm:ss')) {
      checkIn += 'PENDING';
    } else {
      checkIn += 'MISSED';
    }
  }

  specifics.push(checkIn);

  return specifics;
}

const SummaryBlock = ({ requests = [] }) => {
  return useMemo(() => {
    return requests.map((r, i) => {
      return (
        <div className="fade-in-group">
          <StatusBlock
            key={`${r.client_id}-${r.user_id}-${i}`}
            status={r.status}
            blockLabel={`${r.client_first_name} ${r.client_last_name}`}
            specifics={getSpecifics(r)}
          />
        </div>
      )
    });
  }, [requests]);
}

export default SummaryBlock;