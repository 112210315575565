const toasts = (state = [], action) => {
  switch (action.type) {
    case 'ADD_TOAST': {
      return [...state, action.payload];
    }
    case 'REMOVE_TOAST':
      return state?.filter?.((_, i) => i !== action.payload) ?? state;
    default:
      return state;
  }
};

export default toasts;
