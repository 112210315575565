export default function calendars(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_TODAYS_DATE': {
      return {
        ...state,
        pending: true
      };
    }
    case 'REMOVE_PENDING_TODAYS_DATE': {
      return {
        ...state,
        pending: false
      };
    }
    case 'LOAD_SUCCESSFUL_TODAYS_DATE': {
      const { multi, next_test_date, schedule, hash } = action.payload;
      return {
        doneInitialLoad: true,
        pending: false,
        date: { multi, next_test_date, schedule },
        hash
      };
    }
  }
  return state;
}
