import React, { useContext } from 'react';

import { BaselineContext } from 'components/Clients/BaselineImages/BaselineContext';
import BaselineModal from 'components/Clients/BaselineImages/BaselineModal';

const Modals = () => {
  const {
    updateCollection,
    closeSingleClearModal,
    removeSingleImage,
    isModalOpen,
    toggleModal,
    isSingleModalOpen
  } = useContext(BaselineContext);

  return (
    <>
      <BaselineModal
        isOpen={isModalOpen}
        closeModal={toggleModal}
        header="Clear Collection"
        onConfirm={updateCollection(false)}
      >
        Are you sure you want to clear this client&apos;s collection?
      </BaselineModal>
      <BaselineModal
        isOpen={isSingleModalOpen}
        closeModal={closeSingleClearModal}
        header="Clear Image"
        onConfirm={removeSingleImage}
      >
        Are you sure you want to clear this image from the client&apos;s collection?
      </BaselineModal>
    </>
  );
};

export default Modals;
