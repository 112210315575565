import React from 'react';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';
import Input from 'components/Input';
import Map from 'components/Map';
import LocationSearchInput from 'components/LocationSearchInput';
import './style.scss';

const SingleAddressGroup = ({ handleChange, name, value, hideMap = false, ...props }) => {
  const required = !!props.required;

  const updateValue = valueHolder => {
    const event = { target: { name, value: valueHolder, canUpdate: true } };
    handleChange(event, props.blockGroup);
  };

  const handleLenienceChange = (lenience, valueHolder) => {
    let lenienceAsNumber = +lenience;
    if (isNaN(lenienceAsNumber) || lenienceAsNumber < 300) {
      lenienceAsNumber = 300;
    }
    valueHolder.lenience = lenienceAsNumber;
    updateValue(valueHolder);
  };

  const handleLenienceOverlayChange = (geometry, valueHolder) => {
    if (geometry.radius) {
      // Convert the radius received in meters to feet
      const lenienceInFeet = Math.round(+geometry.radius / 0.3048);
      // don't call on change unless the value has changed
      if (Math.round(valueHolder.lenience) !== lenienceInFeet) {
        handleLenienceChange(lenienceInFeet, valueHolder);
      }
    }
  };

  const handleAddressChange = (address, valueHolder) => {
    valueHolder.address_text = address;
    // if the address is cleared, delete the coordinate
    if (!address) {
      delete valueHolder.coordinates;
    }
    updateValue(valueHolder);
  };

  const handleCoordinatesChange = (coordinates, valueHolder) => {
    if (isEqual(valueHolder.coordinates, coordinates)) {
      return;
    }
    valueHolder.coordinates = coordinates;
    updateValue(valueHolder);
  };

  const getLocationSearchInput = valueHolder => {
    return (
      <LocationSearchInput
        key={`location_search_input`}
        address={valueHolder.address_text}
        required={required}
        onChange={address => {
          handleAddressChange(address, valueHolder);
        }}
        error={
          !valueHolder.coordinates
            ? 'Please choose an address from the dropdown.'
            : false
        }
        handleLatLngChange={latLng => {
          handleCoordinatesChange(latLng, valueHolder);
        }}
      />
    );
  };

  const getLenienceInput = valueHolder => {
    const key = `lenience`;
    const fieldProps = {
      key,
      name: key,
      value: isNumber(valueHolder.lenience)
        ? Math.round(valueHolder.lenience)
        : 300,
      label: `Allowed Lenience (in feet)`,
      type: 'number',
      handleChange: event => {
        const {
          target: { value }
        } = event;
        handleLenienceChange(value, valueHolder);
      }
    };
    return <Input {...fieldProps} />;
  };

  const getMap = valueHolder => {
    const key = `address_map`;

    if (
      hideMap ||
      !valueHolder.coordinates ||
      Object.entries(valueHolder.coordinates).length === 0
    )
      return <></>;

    const mapProps = {
      key,
      name: key,
      draw: 'address',
      editLenience: true,
      isNewLocation: true,
      fullscreenControl: false,
      ...(valueHolder.coordinates && {
        primaryMarker: {
          title: 'Address',
          name: 'Address',
          icon: '/static/img/marker_green.png',
          position: valueHolder.coordinates
        },
        ...(valueHolder.lenience && {
          circle: {
            center: valueHolder.coordinates,
            radius: valueHolder.lenience * 0.3048
          }
        }),
        center: valueHolder.coordinates
      }),
      onOverlayUpdate: geometry => {
        handleLenienceOverlayChange(geometry, valueHolder);
      },
      onCenterUpdate: coordinates => {
        handleCoordinatesChange(coordinates, valueHolder);
      }
    };
    return <Map key={`map`} {...mapProps} />;
  };
  return (
    <div className="single-address-group-container">
      {!value ? null : (
        <div className="single-address-group">
          {getLocationSearchInput(value)}
          {!hideMap && getLenienceInput(value)}
          {getMap(value)}
        </div>
      )}
    </div>
  );
};

SingleAddressGroup.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object
};

export default SingleAddressGroup;
