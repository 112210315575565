import React, { useContext, useState } from 'react';
import FormContext from 'components/FormProvider/FormContext';
import { useSubscription } from '../../lib/util';
import { SchemaManager } from 'components/FormProvider/schemaManager';

export class FormFieldEvent {
  constructor(name, value) {
    this.name = name
    this.value = value
  }
}

export class FormFieldChange {
  constructor(name, value, blockGroup, callback) {
    this.target = new FormFieldEvent(name, value)
    this.blockGroup = blockGroup
    this._key = SchemaManager._key(blockGroup, name)
    this.callback = callback
  }
}

export class FormControlChange {
  constructor(control, value) {
    this.control = control
    this.value = value !== undefined ? value : null
  }
}

export function useFieldProps(control) {
  const ctx = useContext(FormContext);

  const [fieldProps, setFieldProps] = useState(ctx.getProps(control))
  // hack: we are instantiating this component from a Schema, which is outside the normal render tree
  // subscribe to state updates to force this component to re-render
  useSubscription(ctx.schemaManager.state$, () => {
    const props = ctx.getProps(control)
    props.getStateValue = () => control._getValue(ctx.state)
    setFieldProps(props);
  }, [ctx.schemaManager.state$])
  return [ctx, fieldProps]
}

export function FieldWrap({ shouldWrap, children }) {
  if (!shouldWrap) return <>{children}</>
  return <div className='field-wrap'>
    {children}
  </div>
}