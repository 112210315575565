/* eslint-disable no-unused-vars */
/**
 * External dependencies
 */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';

/**
 * Internal dependencies
 */
import { makeSkuOptions } from 'components/Settings/Payments/helpers';
import { makeTransactionDetails } from './helpers';
import 'components/Drawer/style.scss';
import './style.scss';

/**
 * @component
 * @description Renders subscription and journal details
 * @param {object} drawer, containing `drawerOpen` and `drawerProps`
 * @returns {Node} `SubscriptionDrawer`
 */
export const SubscriptionDrawer = ({
  drawer: {
    drawerOpen,
    drawerProps: { selectedSubscription, clientName }
  }
}) => {
  const details = useMemo(() => {
    return makeTransactionDetails(selectedSubscription, clientName);
  }, [selectedSubscription, clientName]);

  return (
    <div className="vc-container subscription-drawer">
      <div className="vc-container__scroll-wrapper">
        <div
          className={classnames('vc-container__header', {
            'drawer-open': drawerOpen
          })}
        >
          <h3 className="vc-container__header--heading">Transaction Detail</h3>
        </div>
        <div className="vc-container__info-block">
          {details.map((d, i) =>
            d.value ? (
              <div key={i} className="info-block-row">
                <div className="info-block-row__title">{d.label}</div>
                <div className="info-block-row__value">{d.value}</div>
              </div>
            ) : null
          )}
          <div className="info-block-row">
            <div className="info-block-row__title">DETAILS</div>
            <div className="info-block-row__value">
              <ul>
                {selectedSubscription?.skus?.map(s => {
                  const skuDetails = makeSkuOptions([s] ?? []);
                  return skuDetails.map((s, i) => (
                    <li key={`${s.id}-${i}`}>
                      {s.label}&nbsp;({s.subhead})
                    </li>
                  ));
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SubscriptionDrawer.propTypes = {
  drawer: PropTypes.shape({
    drawerOpen: PropTypes.bool.isRequired,
    drawerProps: PropTypes.shape({
      selectedSubscription: PropTypes.shape({
        program: PropTypes.shape({
          name: PropTypes.string
        }).isRequired,
        start_date: PropTypes.string,
        status: PropTypes.string.isRequired,
        amount: PropTypes.string,
        skus: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            amount: PropTypes.number
          })
        )
      }),
      journal: PropTypes.shape({
        meta: PropTypes.shape({
          created: PropTypes.number,
          current_period_end: PropTypes.number
        })
      }),
      clientName: PropTypes.string.isRequired
    })
  })
};

export default connect(({ drawer }) => ({ drawer }))(SubscriptionDrawer);
