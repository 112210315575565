import React from 'react';
import { Tooltip, darken, makeStyles } from '@material-ui/core';
import { default as MuiIconButton } from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: props => props.padding || '8px 12px',
    backgroundColor: props => props.backgroundColor || theme.palette.branding.primary,
    border: props => props.border || '1px solid #fff',
    borderRadius: 2,
    transform: props => props.transform,
    '&:hover': {
      backgroundColor: props => darken(props.backgroundColor || theme.palette.branding.primary, 0.02)
    },
    '&.Mui-disabled': {
      opacity: 0.5,
      backgroundColor: props => props.backgroundColor || theme.palette.branding.primary
    }
  },
  label: {
    display: 'flex',
    gap: 8,
    justifyContent: 'space-between',
    color: props => props.color || '#fff',
    fontSize: 14,
    whiteSpace: 'nowrap',
    '& div': {
      alignSelf: 'flex-end',
      display: 'none',
      [theme.breakpoints.up('2xl')]: {
        display: 'block'
      }
    }
  }
}));

const IconButton = ({
  tooltip = '',
  size = 'medium',
  onClick,
  label,
  Icon,
  iconProps = {},
  iconButtonProps = {},
  styleProps = {},
  spanClass,
  children
}) => {
  const classes = useStyles(styleProps);

  return (
    <Tooltip title={tooltip}>
      <span className={spanClass}>
        <MuiIconButton
          size={size}
          onClick={onClick}
          {...iconButtonProps}
          classes={{ root: classes.root, label: classes.label }}
        >
          {Icon ? <Icon {...iconProps} /> : null}
          {label ? <div>{label}</div> : null}
          {children}
        </MuiIconButton>
      </span>
    </Tooltip>
  );
};

export default IconButton;
