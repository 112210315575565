/**
 * Internal dependencies
 */
import {
  catchErrors,
  updateIncentivesAndSanctions as updateData,
  getIncentivesAndSanctions as getData
} from 'utils/apiHelpers';

export function getIncentivesAndSanctions(clientId, callback) {
  getData(clientId)
    .then(callback)
    .catch(catchErrors);
}

export function updateIncentivesAndSanctions(data, clientId, callback) {
  updateData(data, clientId)
    .then(callback)
    .catch(catchErrors);
}
