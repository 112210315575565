import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Form = ({ handleSubmitForm, children, ...props }) => {
  return (
    <form className="base-card__form" onSubmit={handleSubmitForm} {...props}>
      {children}
    </form>
  );
};

Form.propTypes = {
  handleSubmitForm: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  props: PropTypes.shape({
    formId: PropTypes.string
  })
};

export default Form;
