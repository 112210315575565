const getRemainingTests = (testIds, testTypes) => testTypes.filter(t => testIds.indexOf(`${t.id}`) === -1);

export const getProfileIdx = (profiles, id) => profiles.findIndex(p => p.id === id);

export const getProfileTypes = hasAccess => {
  let profiles = [
    {
      value: 'period_weekly',
      label: 'Period Weekly'
    },
    {
      value: 'period_monthly',
      label: 'Period Monthly'
    },
    {
      value: 'prob_weekly',
      label: 'True Random'
    }
  ];
  if (hasAccess) {
    profiles = [
      ...profiles,
      {
        value: 'percent_weekly',
        label: 'Percent Weekly'
      },
      {
        value: 'percent_monthly',
        label: 'Percent Monthly'
      }
    ];
  }
  // filler callback here, meant to emulate in FormProvider what TestingProfiles.updateProfile does to assoc. tests
  return profiles.map(p => ({ ...p, callback: f => f }));
};

export const getLumpTests = () => [
  {
    value: 'always',
    label: 'Always'
  },
  {
    value: 'sometimes',
    label: 'Sometimes'
  },
  {
    value: 'never',
    label: 'Never'
  }
];

export const getRemainingTestOptions = (testIds, testTypes) => {
  const remainingTypes = getRemainingTests(testIds, testTypes);
  return remainingTypes.length ? remainingTypes.map(t => ({ value: t.id, label: t.name })) : [];
};

export const GetTestName = (testTypes, id) => testTypes.find(type => type.id === +id).name;

export const isInt = n => {
  // n must be a string.
  if (n.toString().trim() === '') {
    return false;
  }
  return n % 1 === 0;
};

export const isValidFrequency = value => {
  if (value.indexOf('-') !== -1) {
    let values = value.split('-');
    if (values.length !== 2) {
      return false;
    }
    if (!isInt(values[0]) || !isInt(values[1]) || values[0] < 0) {
      return false;
    }

    return values[0] < values[1];
  } else {
    return isInt(value);
  }
};

export const genOptions = (min, max, defaultProvided = false) => {
  const options = [];

  while (min <= max) {
    options.push({
      value: min,
      label: `${min}`
    });
    min++;
  }
  if (defaultProvided) {
    options.unshift({
      value: defaultProvided.value,
      label: defaultProvided.label
    });
  }
  return options;
};

export const getFirstRemainingTest = testIDs => getRemainingTests(testIDs)[0];

export const initialErrors = {
  profileInfo: {
    profile_name: '',
    profile_type: '',
    lump_tests: '',
    expiration: ''
  },
  tests: {
    probTests: '',
    periodTests: '',
    percentTests: '',
    add_test: ''
  }
};

export const getBroadType = type => {
  return type === 'prob_weekly'
    ? 'probTests'
    : type === 'period_weekly' || type === 'period_montly'
    ? 'periodTests'
    : 'percentTests';
};
