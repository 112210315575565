import React from 'react';

import Icon from 'components/Icon';
import NavLink from './NavLink';

const SideNavSupport = () => {
  return (
    <NavLink
      to="#"
      data-testid="support"
      title="Support"
      className="custom-intercom-launcher"
      liClassName="custom-intercom-launcher-icon"
    >
      <Icon name="support" size={24} />
      <span className="sidenav__item-label">Support</span>
    </NavLink>
  );
};

export default SideNavSupport;
