import { useParams } from 'react-router-dom';

const useIsClientContext = () => {
  const { id } = useParams();

  return {
    isClientContext: !!id,
    clientId: id ? +id : null
  };
};

export default useIsClientContext;
