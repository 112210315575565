/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

/**
 * Internal dependencies
 */
import { isMapsLibReady } from 'selectors/mapsLib';
import QueryMapsLib from 'components/data/query-maps-lib';
import Icon from 'components/Icon';
import Label from 'components/Label';
import './style.scss';

class LocationSearchInput extends React.Component {
  static propTypes = {
    address: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    handleLatLngChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,

    // Connected props
    isMapsLibReady: PropTypes.bool
  };

  static defaultProps = {
    disabled: false,
    required: true
  };

  constructor(props) {
    super(props);
  }

  state = {
    address: ''
  };

  componentDidMount() {
    if (this.props.address) {
      this.setState({ address: this.props.address });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.address !== nextProps.address) {
      this.setState({ address: nextProps.address });
    }
  }

  handleChange = address => {
    this.setState({
      address
    });
    if (this.props.onChange) {
      this.props.onChange(address);
    }
  };

  handleSelect = selected => {
    this.setState({ address: selected });
    if (this.props.onSelect) {
      this.props.onSelect(selected);
    } else if (this.props.onChange) {
      this.props.onChange(selected);
    }
    if (this.props.handleLatLngChange) {
      geocodeByAddress(selected)
        .then(results => getLatLng(results[0]))
        .then(latLng => this.props.handleLatLngChange(latLng))
        .catch(error => console.error('Error', error));
    }
  };

  handleCloseClick = () => {
    this.setState({
      address: ''
    });
  };

  render() {
    if (!this.props.isMapsLibReady) {
      return <QueryMapsLib />;
    }

    const { address } = this.state;

    return (
      <div>
        {!!this.props.error && (
          <div className="error-container">
            <Icon name="warning" />
            <Label htmlFor="address" alert mTop mBottom>
              {this.props.error}
            </Label>
          </div>
        )}
        <Label bold required={this.props.required} htmlFor="address">
          Address
        </Label>
        <PlacesAutocomplete
          onChange={this.handleChange}
          value={address}
          onSelect={this.handleSelect}
          shouldFetchSuggestions
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="location-search-bar-container">
                <div className="location-search-input-container">
                  <input
                    {...getInputProps({
                      name: 'address',
                      placeholder: 'Search For Address...',
                      className: 'base-input'
                    })}
                    disabled={this.props.disabled}
                    type="search"
                  />
                </div>
                {suggestions.length > 0 && (
                  <div className="location-autocomplete-container">
                    {suggestions.map(suggestion => {
                      if (!suggestion.id) {
                        suggestion.id = suggestion.placeId;
                      }
                      let className = 'location-suggestion-item';
                      if (suggestion.active) {
                        className += ' location-suggestion-item--active';
                      }

                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{' '}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isMapsLibReady: isMapsLibReady(state)
  };
}

export default connect(mapStateToProps)(LocationSearchInput);
