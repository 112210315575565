/**
 * Internal dependencies
 */
import { loadSuccessful } from './helperActions';

import {
  getDocumentLibraryByFacility,
  getDocument,
  getClientDocuments,
  uploadDocument,
  updateDocument,
  deleteDocument,
  catchErrors
} from 'utils/apiHelpers';

export function getDocumentList(callback = false) {
  return dispatch => {
    getDocumentLibraryByFacility()
      .then(res => {
        dispatch(loadSuccessful('DOCUMENT_LIBRARY', res.data));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getDocumentAction(id, callback = false) {
  return dispatch => {
    getDocument(id)
      .then(res => {
        dispatch(loadSuccessful('DOCUMENT', res.data));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function uploadDocumentAction(data, callback = false, setProgress) {
  return dispatch => {
    uploadDocument(data, null, setProgress)
      .then(res => {
        dispatch(loadSuccessful('UPLOAD_DOCUMENT', res.data));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function updateDocumentAction(data, id, clientId = false, callback = false, setProgress) {
  return dispatch => {
    updateDocument(data, id, clientId, setProgress)
      .then(res => {
        dispatch(loadSuccessful('UPDATE_DOCUMENT', res.data));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function deleteDocumentAction(id, callback = false) {
  return dispatch => {
    deleteDocument(id)
      .then(res => {
        dispatch(loadSuccessful('DELETE_DOCUMENT', res.data));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getClientDocumentsAction(clientId) {
  return dispatch => {
    return getClientDocuments(clientId).then(res => {
      dispatch(loadSuccessful('CLIENT_DOCUMENTS', res.data));
    });
  };
}

export function uploadClientDocumentAction(data, clientId, callback = false, setProgress) {
  return dispatch => {
    uploadDocument(data, clientId, setProgress)
      .then(res => {
        dispatch(loadSuccessful('UPLOAD_CLIENT_DOCUMENT', res.data));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}
