import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { Divider } from '@material-ui/core';

import CustomTooltip from 'components/CustomTooltip';
import Buttons from './Buttons';
import { Case, Switch } from 'react-if';
import CsvButton from 'components/CsvButton';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
    '& > .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      gap: 6,
      fontSize: props => props.fontSize || 15,
      [theme.breakpoints.up('lg')]: {
        fontSize: props => props.fontSize || 18,
        gap: 12
      },
      '& > span': {
        display: 'block',
        lineHeight: 1
      },
      '& .question-mark-container': {
        display: 'none !important',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
          display: 'flex !important'
        }
      }
    }
  },
  link: {
    color: '#00819B',
    textDecoration: 'none',
    fontSize: 12,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const CardHeader = ({
  title,
  titleTooltip = undefined,
  Header = 'h2',
  variant = 'h5',
  tooltipProps = {},
  headerStyleProps = {},
  Separator = null,
  showSeparator = true,
  children,
  showCsvButton,
  csvButtonData,
  csvButtonHeaders,
  csvButtonDisabled
}) => {
  const classes = useStyles(headerStyleProps);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography variant={variant} component={Header} style={{ textAlign: 'left' }}>
            <span>{title}</span>
            {titleTooltip ? (
              <CustomTooltip absolute={false} {...tooltipProps}>
                {titleTooltip}
              </CustomTooltip>
            ) : null}
          </Typography>
          {showCsvButton && (
            <CsvButton
              title="client-locations"
              isDownloadDisabled={!csvButtonData?.length || !csvButtonHeaders || csvButtonDisabled}
              csvData={csvButtonData}
              headers={csvButtonHeaders}
            />
          )}
        </div>
        {children ? <Buttons>{children}</Buttons> : null}
      </div>
      <Switch>
        <Case condition={showSeparator && Separator}>{Separator}</Case>
        <Case condition={showSeparator && !Separator}>
          <Divider />
        </Case>
      </Switch>
    </>
  );
};

export default CardHeader;
