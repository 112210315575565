import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import Button from 'components/Button';
import Icon from 'components/Icon';
import CustomSelect from 'components/CustomSelect';

import './style.scss';

const MultiReminderInput = ({ handleChange, name, value, ...props }) => {
  const handleRemove = index => {
    const newValue = cloneDeep(value);
    newValue.splice(index, 1);
    const e = { target: { name, value: newValue } };
    handleChange(e, props.blockGroup);
  };

  const handleValueChange = ({ target: { value: targetValue } }, index) => {
    const idx = parseInt(index, 10);
    const newValue = cloneDeep(value);
    const valueToUpdate = newValue[idx];
    valueToUpdate.value = targetValue;
    newValue[idx] = valueToUpdate;
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleUnitChange = ({ target: { value: targetValue } }, index) => {
    const idx = parseInt(index, 10);
    const newValue = cloneDeep(value);
    const valueToUpdate = newValue[idx];
    valueToUpdate.unit = targetValue;
    newValue[idx] = valueToUpdate;
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleButtonClick = () => {
    const newValue = cloneDeep(value);
    const newReminder = {
      unit: 'days',
      value: 1
    };
    newValue.push(newReminder);
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const getValueDropDownLength = unit => {
    switch (unit) {
      case 'hours':
        return 23;
      case 'days':
        return 10;
      case 'minutes':
        return 59;
      default:
        return 10;
    }
  };

  const getValueDropDownOptions = interval => {
    const length = getValueDropDownLength(interval);
    const options = [];
    for (let i = 1; i <= length; i++) {
      options.push({
        value: i,
        label: i
      });
    }
    return options;
  };

  return (
    <div className="multi-reminder-input-container">
      {!value
        ? []
        : value.map((v, index) => {
            return (
              <div key={index} className="reminder-input-single">
                <CustomSelect
                  handleCustomSelectChange={event =>
                    handleValueChange(event, index)
                  }
                  name={`${name}_value_${index}`}
                  data-value={index}
                  value={v.value}
                  className="reminder-input-single__value"
                  options={getValueDropDownOptions(v.unit)}
                />
                <CustomSelect
                  handleCustomSelectChange={event =>
                    handleUnitChange(event, index)
                  }
                  name={`${name}_unit_${index}`}
                  data-value={index}
                  value={v.unit}
                  className="reminder-input-single__unit"
                  options={[
                    {
                      value: 'minutes',
                      label: 'Minutes'
                    },
                    {
                      value: 'hours',
                      label: 'Hours'
                    },
                    {
                      value: 'days',
                      label: 'Days'
                    }
                  ]}
                />
                <div className="reminder-input-single__remove_icon">
                  {index !== 0 && (
                    <span onClick={() => handleRemove(index)}>
                      <Icon name="minus" />
                    </span>
                  )}
                </div>
              </div>
            );
          })}
      <Button secondary transparent micro handleButtonClick={handleButtonClick}>
        Add Additional Reminder
      </Button>
    </div>
  );
};

MultiReminderInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array
};

export default MultiReminderInput;
