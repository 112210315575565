/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableHead from './TableHead';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import './style.scss';

const BaseTable = ({ children, className, ...props }) => (
  <table className={`base-table${className ? ` ${className}` : ''}`} {...props}>
    {children}
  </table>
);

const Table = {
  BaseTable,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell
};

export default Table;
