const hasAccessTo = (feature, access_level) => {
  return feature.status && feature.access_level <= access_level;
};

const setupFeatures = (features, access_level) => {
  Object.keys(features).forEach(featureName => {
    features[featureName].hasAccessTo = hasAccessTo(
      features[featureName],
      access_level
    );
  });
  return features;
};

const features = (state = false, action) => {
  switch (action.type) {
    case 'SET_FACILITY_DATA': {
      const { features, access_level } = action.payload;
      return Object.assign({}, setupFeatures(features, access_level));
    }
    default:
      return state;
  }
};

export default features;
