/**
 * External dependencies
 */
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import Icon from 'components/Icon';
import './style.scss';

class ListItem extends React.Component {
  static propTypes = {
    changePage: PropTypes.func.isRequired,
    className: PropTypes.string,
    page: PropTypes.number.isRequired
  };

  // is this a good way of handling this??
  handlePageChange = () => {
    this.props.changePage(this.props.page);
  };

  render() {
    const { page, className } = this.props;
    return (
      <li
        className={`data-table-pagination__page ${className}`}
        onClick={this.handlePageChange}
      >
        <a>{page + 1}</a>
      </li>
    );
  }
}

class Paginator extends React.Component {
  static propTypes = {
    changePage: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    paginatorProps: PropTypes.object
  };

  handlePrev = e => {
    e.preventDefault();
    if (this.props.currentPage !== 0) {
      this.props.changePage(this.props.currentPage - 1);
    }
  };

  handleNext = e => {
    e.preventDefault();
    if (this.props.currentPage + 1 !== this.props.pages) {
      this.props.changePage(this.props.currentPage + 1);
    }
  };

  render() {
    let { changePage, currentPage, pages, className = '' } = this.props;
    let start, finish;
    const MAX_PAGES_TO_RENDER = 8;
    const { leftDisabled, rightDisabled } = this.props;

    var listItems = [];
    if (pages <= MAX_PAGES_TO_RENDER) {
      start = 0;
      finish = pages;
    } else if (currentPage < MAX_PAGES_TO_RENDER / 2) {
      start = 0;
      finish = MAX_PAGES_TO_RENDER;
    } else if (currentPage + MAX_PAGES_TO_RENDER / 2 > pages) {
      start = pages - MAX_PAGES_TO_RENDER - 1;
      finish = pages;
    } else {
      start = currentPage - MAX_PAGES_TO_RENDER / 2;
      finish = currentPage + MAX_PAGES_TO_RENDER / 2;
    }

    while (start < finish) {
      // figure out some tricky stuff.
      let first = currentPage === 0;
      let last = currentPage === finish - 1;
      const optional =
        first || last
          ? Math.abs(start - currentPage) > 2
          : Math.abs(start - currentPage) > 1;

      let classNames = classnames({
        current: start === currentPage,
        hide: optional,
        'medium-up-inline-block': optional
      });

      listItems.push(
        <ListItem
          changePage={changePage}
          className={classNames}
          key={'pagination-' + start}
          page={start}
        />
      );
      start += 1;
    }
    return (
      <div className={`data-table-pagination ${className}`}>
        {listItems.length ? (
          <ul>
            <li
              onClick={this.handlePrev}
              className={classnames('data-table-pagination__arrow', {
                'data-table-pagination__arrow--disabled': leftDisabled
              })}
            >
              <Icon name="chevron-left" />
            </li>
            {listItems}
            <li
              onClick={this.handleNext}
              className={classnames('data-table-pagination__arrow', {
                'data-table-pagination__arrow--disabled': rightDisabled
              })}
            >
              <Icon name="chevron-right" />
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

export default Paginator;
