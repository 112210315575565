import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  link: {
    color: '#00819B',
    position: 'relative',
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '100%',
      transform: 'scaleX(0)',
      height: '1px',
      bottom: '-2px',
      left: 0,
      backgroundColor: '#00819B',
      transformOrigin: 'bottom right',
      transition: 'transform 0.25s ease-out'
    },
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
      '&:after': {
        transform: 'scaleX(1)',
        transformOrigin: 'bottom left'
      }
    },
    '&:active, &:focus': {
      outline: 'none',
      color: '#086C6F'
    },
    '&:visited': {
      color: '#ba57e2',
      '&:after': {
        backgroundColor: '#ba57e2'
      }
    }
  }
}));

const StyledLink = ({ href, children, ...rest }) => {
  const classes = useStyles();

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={classes.link} {...rest}>
      {children}
    </a>
  );
};

export default StyledLink;
