import React, { useMemo } from 'react';
import classnames from 'classnames';

import SummaryBlock from './SummaryBlock';

class OtherItem {
  value = null;
  state_value = null;
  label = null;
  check = null;
  requests = [];

  constructor(value, state_value, label, check) {
    this.value = value;
    this.state_value = state_value;
    this.label = label;
    this.check = check;
  }

  setRequests(requests) {
    if (requests?.[this.check]) {
      this.requests = requests[this.check];
    }

    return this;
  }
}

function groupRequestsByStatus(requests) {
  const requestsByStatus = {};

  for (const request of requests) {
    if (!requestsByStatus[request.status]) {
      requestsByStatus[request.status] = [];
    }

    requestsByStatus[request.status].push(request);
  }

  return requestsByStatus;
}

const SummaryList = ({ report, expanded, setExpanded }) => {
  const items = useMemo(() => {
    const { requests, summary } = report;
    const { pending, checked } = summary;
    const late = summary['checked late'];
    const missed = summary['no check'];

    const requestsByCheck = groupRequestsByStatus(requests);

    return [
      new OtherItem(checked, 'CHECKED', 'Checked', 'checked').setRequests(requestsByCheck),
      new OtherItem(late, 'LATE', 'Late', 'checked late').setRequests(requestsByCheck),
      new OtherItem(missed, 'MISSED', 'Missed', 'no check').setRequests(requestsByCheck),
      new OtherItem(pending, 'PENDING', 'Pending', 'pending').setRequests(requestsByCheck),
    ];
  }, [report]);

  return (
    <ul className="check-summary-list">
      <li>
        <span>Total Check Requests</span>
        <span>{report?.requests?.length ?? 0}</span>
      </li>
      {items.map(({ value, state_value, label, requests }) => (
        <li
          key={state_value}
          onClick={() => setExpanded(expanded !== state_value ? state_value : null)}
          className={classnames({ 'has-dropdown': !!value, active: expanded === state_value })}
        >
          <span>{label}</span>
          <span>{value}</span>
          {expanded === state_value && <SummaryBlock requests={requests} />}
        </li>
      ))}
    </ul>
  );
}

export default SummaryList;