/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './style.scss';

/**
 * @component
 * @description Simple presentation wrapper for action bar buttons -- allows for
 * use in the `FormProvider` by simply passing children, as well as in other places
 * where we need the same UX. Currently, the only viable props are `shorten`, typically
 * based on the current pathname or location, and children (buttons, for now).
 * @param {Boolean} [shorten] whether the account for the presence of a sidebar
 * @param {Array|Node} children
 * @returns {Node} `ActionBarWrapper`
 *
 * @example
 * ```js
 * import React from 'react';
 *
 * import ActionBarWrapper from 'components/ActionBarWrapper';
 * import Button from 'components/Button';
 *
 * const App = () => (
 *  <div>
 *    <h1>Welcome to Reconnect</h1>
 *    <ActionBarWrapper>
 *      <Button handleButtonClick={() => console.log('clicked!')} primary>Click Me</Button>
 *     </ActionBarWrapper>
 *  </div>
 * );
 * ```
 */
const ActionBarWrapper = ({ shorten, children }) => (
  <div
    className={classnames('action-bar', {
      'action-bar--shorten': shorten
    })}
  >
    <div className="container">{children}</div>
  </div>
);

ActionBarWrapper.propTypes = {
  shorten: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ActionBarWrapper;
