import React, { useMemo } from 'react';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';

import Button from 'components/Button';
import Icon from 'components/Icon';
import FeatureRestricted from 'components/FeatureRestricted';
import UserSettingsData from 'utils/UserSettingData';
import CurriculumRequest from 'utils/CurriculumRequest';

const placeholderFn = () => () => { };

const ColumnActions = ({
  row,
  handleGetItem = placeholderFn,
  handleDeleteIntermediary = placeholderFn,
  handleEditUserSettings = placeholderFn,
  handleAddToFacility = placeholderFn,
  isEditDisabled,
  isDeleteDisabled,
  isHideDisabled,
  editButtonProps = {},
  deleteButtonProps = {},
  hideButtonProps = {},
  table = null,
}) => {
  const targetId = useMemo(() => row?.original?.id, [row?.original?.id]);

  const userSettingsData = useMemo(() => {
    return new UserSettingsData(row.original?.id, table, !row.original?.hidden);
  }, [row, table]);

  return (
    <div className="action-buttons">
      <Button transparent invisible handleButtonClick={handleGetItem(targetId, { isView: true })}>
        <Icon name="view" />
      </Button>
      <Button
        transparent
        invisible
        disabled={isEditDisabled}
        handleButtonClick={handleGetItem(targetId, { isEdit: true })}
        {...editButtonProps}
      >
        <Icon name="edit" />
      </Button>
      <FeatureRestricted features="hide_from_curriculum_tables">
        <Button
          transparent
          invisible
          handleButtonClick={handleEditUserSettings(userSettingsData)}
          disabled={isHideDisabled}
          {...hideButtonProps}
        >
          {row?.original?.hidden ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
        </Button>
      </FeatureRestricted>
      <Button
        transparent
        invisible
        disabled={isDeleteDisabled}
        handleButtonClick={handleDeleteIntermediary(targetId)}
        {...deleteButtonProps}
      >
        <Icon name="trash" />
      </Button>
      <FeatureRestricted features={["superadmin", ['curriculum_resource', 'curriculum_packet'].includes(table)]}>
        <Button
          transparent
          invisible
          tooltip={{ title: `SUPERADMIN ONLY: Click to complete a form to make this Learning Lab ${table === CurriculumRequest.KEYS.RESOURCE ? 'resource' : 'packet' } available to any active facilities.` }}
          handleButtonClick={handleAddToFacility(table, targetId)}
        >
          <ShareRoundedIcon />
        </Button>
      </FeatureRestricted>
    </div>
  );
};

export default ColumnActions;
