export default function violations(state = { list: [] }, action) {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return {
        ...state,
        drawerOpen: true,
        ...action.payload
      };
    case 'CLOSE_DRAWER':
      return {
        ...state,
        drawerOpen: false,
        drawerProps: {},
        type: null
      };
    default:
      return state;
  }
}
