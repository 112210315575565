import React from 'react';

import DebouncedInput from 'components/Curriculum/Table/DebouncedInput';

const Filter = ({ column, table }) => {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () => (typeof firstValue === 'number' ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort()),
    [column.getFacetedUniqueValues()]
  );

  return typeof firstValue === 'number' ? (
    <>
      <DebouncedInput
        type="number"
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
        value={columnFilterValue?.[0] ?? ''}
        onChange={value => column.setFilterValue(old => [value, old?.[1]])}
        placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''}`}
        className=""
      />
      <DebouncedInput
        type="number"
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
        value={columnFilterValue?.[1] ?? ''}
        onChange={value => column.setFilterValue(old => [old?.[0], value])}
        placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''}`}
        className=""
      />
    </>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.map(value => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ''}
        onChange={column.setFilterValue}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className=""
        list={column.id + 'list'}
      />
    </>
  );
};

export default Filter;
