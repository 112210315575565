import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    [theme.breakpoints.up('lg')]: {
      gap: 8
    }
  }
}));

const Buttons = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.buttons}>{children}</div>;
};

export default Buttons;
