/**
 * External dependencies
 */
import React, { PureComponent } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { getDetailsStyles } from 'components/Violations/violations-helpers';
import Icon from 'components/Icon';

export default class StatusBlock extends PureComponent {
  render() {
    const { status = false, blockLabel, specifics } = this.props;
    const baseStatus = status ? status : blockLabel;
    const { ...detailsStyle } = getDetailsStyles(baseStatus);

    return (
      <div className={classnames('vc-container__status-block', detailsStyle)}>
        <div className="vc-img">
          {status && <Icon name={status} size="22" />}
        </div>
        <div className="vc-details">
          <h3 className="vc-type">{blockLabel}</h3>
          {specifics.length
            ? specifics.map(
                (specific, i) =>
                  !!specific && (
                    <p key={`specific-${i}`} className="vc-specifics">
                      {specific}
                    </p>
                  )
              )
            : null}
        </div>
      </div>
    );
  }
}
