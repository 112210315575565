/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavLink as Link } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { closeContextualMenu, closeMobileMenu } from 'actions/actionCreators';
import useLiClassName from './useLiClassName';

/* eslint-disable no-unused-vars */
function NavLink({
  staticContext,
  history,
  location,
  match,
  mediumUp,
  small,
  liClassName,
  nestedMenu,
  activeClassName,
  closeContextualMenu,
  closeMobileMenu,
  to,
  ...rest
}) {
  const appliedLiClassName = useLiClassName({ liClassName, mediumUp, small, pathname: location.pathname, to });

  return (
    <li className={appliedLiClassName} style={rest.styles}>
      <Link
        onClick={() => {
          closeContextualMenu();
          closeMobileMenu();
          if (to.indexOf('#') > 1) {
            const parts = to.split('#');
            const element = document.querySelector(`#${parts[1]}`);
            if (!element) {
              return;
            }
            window.scrollTo({
              behavior: 'smooth',
              top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 200
            });
          }
        }}
        to={to}
        activeClassName={activeClassName}
        {...rest}
      >
        {rest.children}
      </Link>
      {nestedMenu}
    </li>
  );
}

NavLink.propTypes = {
  children: PropTypes.any.isRequired,
  mediumUp: PropTypes.bool,
  small: PropTypes.bool,
  to: PropTypes.string.isRequired,
  liClassName: PropTypes.string,
  nestedMenu: PropTypes.any,
  activeClassName: PropTypes.string,
  closeContextualMenu: PropTypes.func.isRequired,
  closeMobileMenu: PropTypes.func.isRequired
};

NavLink.defaultProps = {
  activeClassName: '',
  liClassName: '',
  nestedMenu: null
};

const mapDispatchToProps = {
  closeContextualMenu,
  closeMobileMenu
};

export default withRouter(connect(null, mapDispatchToProps)(NavLink));
