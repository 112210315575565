import { loadPending, removePending, loadSuccessful } from './helperActions';

import { getTestProfiles, catchErrors } from 'utils/apiHelpers';

export function getProfiles(profiles, show_in_use = false) {
  let hash = profiles.doneInitialLoad && profiles.hash;

  return dispatch => {
    // set to pending
    dispatch(loadPending('PROFILES'));

    if (hash) {
      getTestProfiles(hash, show_in_use)
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending('PROFILES'));
          } else {
            dispatch(loadSuccessful('PROFILES', res.data));
          }
        })
        .catch(catchErrors);
    } else {
      getTestProfiles(false, show_in_use)
        .then(res => {
          dispatch(loadSuccessful('PROFILES', res.data));
        })
        .catch(catchErrors);
    }
  };
}
