import React from 'react';
import ReactPaginate from 'react-paginate';

import Icon from 'components/Icon';
import './style.scss';

const Paginator = ({ handlePageClick, pageCount, forcePage }) => {
  return (
    <ReactPaginate
      breakLabel=". . ."
      onPageChange={handlePageClick}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      forcePage={forcePage}
      previousLabel={<Icon name="chevron-left" />}
      nextLabel={<Icon name="chevron-right" />}
      renderOnZeroPageCount={() => null}
      breakClassName="paginator-break"
      nextClassName="paginator-next-button"
      previousClassName="paginator-previous-button"
      activeClassName="paginator-active"
      pageClassName="paginator-page"
      containerClassName="paginator"
      disabledClassName="paginator-disabled"
    />
  );
};

export default Paginator;
