import { isObject } from 'lodash';

import { SelectOption } from 'components/Clients/ClientsGet/Info/BasicInfo/Schemas/helpers';
import { SUBSTANCES_USED } from 'components/Clients/ClientsGet/Info/BasicInfo/SubstanceUseForm';

export class Options {
  static PANELS = [
    '5-Panel Urinalysis Drug Screen',
    '7-Panel Urinalysis Drug Screen',
    '10-Panel Urinalysis Drug Screen',
    '12-Panel Urinalysis Drug Screen',
    '16-Panel Urinalysis Drug Screen',
    '4-Panel Blood Drug Screen',
    '5-Panel Blood Drug Screen',
    '6-Panel Blood Drug Screen',
    '7-Panel Blood Drug Screen',
    '10-Panel Blood Drug Screen',
    '12-Panel Blood Drug Screen',
    '16-Panel Blood Drug Screen',
    'Hair Follicle Testing',
    'Breath Drug Testing',
    'Sweat Drug Testing',
    'Ethyl Glucuronide (ETG) testing',
    'Other'
  ];

  static WAITING_STATUSES = ['Yes', 'Confirmed', 'Confirmation Not Needed'];

  static KEYS = {
    result: 'result',
    panels: 'panels',
    panels_other: 'panels_other',
    substances: 'substances',
    substances_other: 'substances_other',
    waiting_status: 'waiting_status',
    notes: 'notes',
    test_id: 'test_id'
  };

  static get results() {
    return [
      new SelectOption('Not Logged', 'not_logged'),
      new SelectOption('Passed', 'pass'),
      new SelectOption('Failed', 'fail'),
      new SelectOption('Reported', 'reported'),
      new SelectOption('Did Not Report', 'no_show'),
      new SelectOption('Reported Not Provided', 'reported_not_provided'),
      new SelectOption('Excused', 'excused')
    ];
  }

  static get resultsAsCheckboxes() {
    return Options.results.map(o => ({ name: o.value, label: o.label }));
  }

  static get panels() {
    return Options.PANELS.map(panel => new SelectOption(panel, panel));
  }

  static get substances() {
    const options = SUBSTANCES_USED.map(substance => new SelectOption(substance, substance));

    options.push(new SelectOption('Other', 'Other'));

    return options;
  }

  static get statuses() {
    return Options.WAITING_STATUSES.map(status => new SelectOption(status, status));
  }

  static get properties() {
    return Object.values(Options.KEYS);
  }
}

class TestingUtil {
  static getPos(data = {}) {
    if ('object' === typeof data && data?.pos !== null) {
      return {
        lat: data.pos.latitude,
        lng: data.pos.longitude
      };
    }

    return false;
  }

  static getNamesForRequiredTests(requiredTests = [], tests = {}) {
    if (!Array.isArray(requiredTests) || !requiredTests.length) {
      return '-';
    }

    const names = [];

    for (const testId of requiredTests) {
      if (isObject(tests) && testId in tests) {
        names.push(tests[testId].name);
      }
    }

    if (!names.length) {
      return '-';
    }

    return names.join(', ');
  }

  static formatTime(hour, minute) {
    let suffix = 'am';

    if (hour > 12) {
      suffix = 'pm';
      hour = hour - 12;
    } else if (hour == 12) {
      suffix = 'pm';
    }

    return hour + ':' + TestingUtil.makeTwoDigits(minute) + ' ' + suffix;
  }

  static makeTwoDigits(digit) {
    return digit < 0 ? `${24 + digit}` : `${'0' + digit}`.slice(-2);
  }

  static formatWindow(original) {
    const { ad_hoc, meta, window_start_hour, window_end_hour, window_start_minute, window_end_minute } = original;

    if (ad_hoc) {
      return null;
    }

    let start_hour, start_minute, end_hour, end_minute;

    if (meta) {
      start_hour = meta.wsh;
      start_minute = meta.wsm;
      end_hour = meta.weh;
      end_minute = meta.wem;
    } else {
      start_hour = window_start_hour;
      start_minute = window_start_minute;
      end_hour = window_end_hour;
      end_minute = window_end_minute;
    }

    if (start_hour && start_minute && end_hour && end_minute) {
      const startTime = TestingUtil.formatTime(start_hour, start_minute);
      const endTime = TestingUtil.formatTime(end_hour, end_minute);
      return `${startTime} to ${endTime}`;
    }

    return null;
  }
}

export default TestingUtil;
