/**
 * Internal dependencies
 */
import { loadPending, loadSuccessful } from './helperActions';
import {
  catchErrors,
  requestGetBreathalyzerEvents,
  requestGetEventsByClient
} from 'utils/apiHelpers';

export function getBreathalyzerEvents(
  startDate,
  endDate,
  exclude,
  callback = false
) {
  return dispatch => {
    dispatch(loadPending('GET_BREATHALIZER_EVENTS'));

    requestGetBreathalyzerEvents(startDate, endDate, exclude)
      .then(res => {
        dispatch(loadSuccessful('GET_BREATHALIZER_EVENTS'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getEventsByClient(
  client_id,
  startDate,
  endDate,
  exclude,
  callback = false
) {
  return dispatch => {
    dispatch(loadPending('GET_EVENTS_BY_CLIENT'));

    requestGetEventsByClient(client_id, startDate, endDate, exclude)
      .then(res => {
        dispatch(loadSuccessful('GET_EVENTS_BY_CLIENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}
