/**
 * Check if the current user can access a certain feature.
 *
 * @param {Object} state   App state tree.
 * @param {String} feature Slug of the feature that we're checking if the user has access to.
 *
 * @returns {Bool} True if can access the desired feature. False otherwise.
 */
const userCanAccess = (state, feature) => {
  return !!(
    state.features?.[feature]?.status &&
    state.features?.[feature]?.access_level <= state.user.access_level
  );
};

export default userCanAccess;
