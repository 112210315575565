import { useCallback } from 'react';

import { jwtInstance } from 'utils/apiHelpers';

const useClientNotes = clientId => {
  const get = useCallback(async () => {
    return jwtInstance.post(`client/${clientId}/notes/get_notes`, { id: clientId }).then(res => {
      return res.data.notes;
    });
  }, [clientId]);

  const add = useCallback(
    async (note, date = null) => {
      return jwtInstance
        .post(`client/${clientId}/notes/add_note`, {
          id: clientId,
          note,
          date
        })
        .then(res => {
          return res.data.data;
        });
    },
    [clientId]
  );

  const update = useCallback(async note => {
    return jwtInstance
      .post(`client/${note.client_id}/notes/update_note/${note.id}`, {
        id: note.client_id,
        note
      })
      .then(res => {
        return res.data.data;
      });
  }, []);

  return { get, add, update };
};

export default useClientNotes;
