/**
 * External dependencies
 */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import scriptLoader from 'react-async-script-loader';

/**
 * Internal dependencies
 */
import { setMapsLibLoading, setMapsLibReady } from 'actions/actionCreators';
import { getMapsLibStatus } from 'selectors/mapsLib';

@scriptLoader([
  'https://maps.googleapis.com/maps/api/js?v=3.47&key=AIzaSyDbGHPq9kU_iuhsGDTpls0iAfxF829fDpo&libraries=places,geometry,drawing'
])
class QueryMapsLib extends Component {
  static propTypes = {
    // Connected props
    getMapsLibStatus: PropTypes.string,
    setMapsLibLoading: PropTypes.func,
    setMapsLibReady: PropTypes.func
  };

  state = {
    seconds: 0,
    mapsLibReady: false
  };

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }

  componentDidMount() {
    if ('notloaded' === this.props.getMapsLibStatus) {
      this.props.setMapsLibLoading();
      this.interval = setInterval(() => this.tick(), 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    if ('loading' === prevProps.getMapsLibStatus) {
      if (!prevState.mapsLibReady && window.google && window.google.maps) {
        clearInterval(this.interval);
        this.setState({ mapsLibReady: true });
        this.props.setMapsLibReady();
      }
    }
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    getMapsLibStatus: getMapsLibStatus(state)
  };
}

const mapDispatchToProps = {
  setMapsLibLoading,
  setMapsLibReady
};

export default connect(mapStateToProps, mapDispatchToProps)(QueryMapsLib);
