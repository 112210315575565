import { createTheme } from '@material-ui/core';

const theme = createTheme({
  // pallet made by sampling existing reconnect colors in hsl mode, then
  // adjusting the saturation and lightness values
  palette: {
    primary: {
      light: '#396993',
      main: '#30516e',
      dark: '#274159',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ffa98a',
      main: '#ff6d38',
      dark: '#ec5c27',
      contrastText: '#fff'
    },
    branding: {
      primary: '#FF6C37'
    }
  },
  spacing: [0, 4, 8, 12, 16, 24, 32, 48, 64], // This is probably something we should adjust
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    color: '#2F3D49',
    fontSize: 16, // beware! reconnect root font size is different than this
    body1: {
      fontSize: '1.4rem',
      lineHeight: '1.4rem'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 600,
      lg: 768,
      xl: 800,
      '2xl': 960,
      '3xl': 1024
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        color: '#67717a',
        'font-size': '14px !important',
        'text-align': 'left',
        padding: '12px',
        width: '100%',
        'max-width': '300px',
        'box-shadow': 'rgba(0, 0, 0, 0.3) 0 2px 10px'
      }
    },
    MuiAccordion: {
      root: {
        margin: 0,
        boxShadow: 'none !important',
        display: 'flex',
        flexDirection: 'column',
        gap: 18,
        border: 'none !important',
        borderRadius: '0 !important',
        '&.Mui-expanded': {
          margin: '0 !important',
          '&:before': {
            opacity: '1 !important'
          }
        },
        '&:before': {
          display: 'none'
        }
      },
      rounded: {
        border: 'none',
        '& .MuiCollapse-hidden': {
          display: 'none'
        }
      }
    },
    MuiAccordionDetails: {
      root: {
        margin: 0,
        padding: 0
      }
    }
  }
});

export default theme;
