/**
 * External dependencies
 */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { closeDrawer } from 'actions/actionCreators';
import Table from 'components/Table';
import Icon from 'components/Icon';
import './style.scss';
import userCanAccess from 'selectors/userCanAccess';
import Button from 'components/Button';
import {getFormsMergedAsBuffer} from "utils/apiHelpers";
import {asInline} from "utils/pdf";
import toast from "components/Toast/Toast";
import Loading from "components/Helpers/Loading";

function FormSubmissionDrawer({
  drawer: {
    drawerOpen,
    drawerProps: { formattedStart, formattedEnd, clientSubmissions }
  },
  userCanAccess
}) {
  const [isPrinting, setIsPrinting] = useState(false);
  const [printDisable, setPrintDisable] = useState(checkIfCanPrint());

  function checkIfCanPrint() {
    let disable = true;

    clientSubmissions.length && clientSubmissions.map((clientSubmission, key) => {
      clientSubmission.actions.filter(action => {
        if(action.type === 'form')
          disable=false; });
    });
    return disable;
  }

  const handlePrint = e => {
    setIsPrinting(true);
    e?.preventDefault();
    let forms_id = [];
    clientSubmissions.length &&
        clientSubmissions.map((clientSubmission, key) => {
          clientSubmission.actions.filter(action => {
            if (action.type === 'form') {
              forms_id.push(action.response);
            }
          });
    });
    getFormsMergedAsBuffer(forms_id)
        .then(response => {
          asInline(response.data);
        })
        .catch(() => {
          toast.addToast({
            position: 'top right',
            type: 'warning',
            content: 'Unable to generate form. Please try again'
          });
        })
        .finally(() => {
          setIsPrinting(false);
        });
  };
  return (
      <>
        {isPrinting? (
            <>
              <h3 className="client-header align-center">This may take several minutes to generate.</h3>
              <Loading />
            </>
        ) : (
            <div className="vc-container formsubmission-drawer">
              <div className="vc-container__scroll-wrapper">
                <div
                    className={classnames('vc-container__header', {
                      'drawer-open': drawerOpen
                    })}
                >
                  <h3 className="vc-container__header--heading">
                    <span>From {formattedStart}</span>
                    <br />
                    <span>To {formattedEnd}</span>
                  </h3>
                </div>
                <div className="vc-container__print-button" >
                  <Button secondary micro handleButtonClick={handlePrint} disabled={printDisable}>
                    Print
                  </Button>
                </div>
                <div className="client-submission-drawer-table">
                  {userCanAccess('view_all_clients_form_submissions') ? null : (
                      <h3>Your Clients</h3>
                  )}
                  <Table.BaseTable>
                    <Table.TableHead>
                      <Table.TableRow>
                        <Table.TableHeader />
                        <Table.TableHeader>Name</Table.TableHeader>
                        <Table.TableHeader>Status</Table.TableHeader>
                      </Table.TableRow>
                    </Table.TableHead>
                    <Table.TableBody>
                      {clientSubmissions.length
                          ? clientSubmissions.map((clientSubmission, key) => {
                            let formResponseId, checkId;
                            clientSubmission.actions.filter(action => {
                              if (action.type === 'form') {
                                formResponseId = action.response;
                              }
                              if (action.type === 'check_in') {
                                checkId = action.response;
                              }
                            });
                            const urlSuffix =
                                formResponseId && checkId
                                    ? `${formResponseId}/${checkId}`
                                    : formResponseId;
                            return (
                                <Table.TableRow key={key}>
                                  <Table.TableCell>
                                    {clientSubmission.status !== 'Complete' ? null : (
                                        // this link may change when we get the submissions linked together
                                        <NavLink
                                            to={`/clients/get/${clientSubmission.id}/forms/${urlSuffix}`}
                                        >
                                          <Icon name="view" />
                                        </NavLink>
                                    )}
                                  </Table.TableCell>
                                  <Table.TableCell>
                                    {clientSubmission.clientName}
                                  </Table.TableCell>
                                  <Table.TableCell
                                      className={classnames({
                                        missed: clientSubmission.status === 'Missed',
                                        pending: clientSubmission.status === 'Pending'
                                      })}
                                  >
                                    {clientSubmission.status}
                                  </Table.TableCell>
                                </Table.TableRow>
                            );
                          })
                          : null}
                    </Table.TableBody>
                  </Table.BaseTable>
                </div>
              </div>
            </div>
        )}
      </>
  );
};

FormSubmissionDrawer.propTypes = {
  drawer: PropTypes.shape({
    drawerProps: PropTypes.shape({
      formattedStart: PropTypes.string.isRequired,
      formattedEnd: PropTypes.string.isRequired,
      clientSubmissions: PropTypes.array.isRequired
    }),
    drawerOpen: PropTypes.bool.isRequired
  }).isRequired,
  closeDrawer: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  drawer: state.drawer,
  userCanAccess: feature => userCanAccess(state, feature)
});

const mapDispatchToProps = {
  closeDrawer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormSubmissionDrawer);
