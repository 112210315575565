import ControlConfig from 'components/Form/Model/ControlConfig';
import { ControlTypes } from 'components/Form/Model/ControlTypes';

export class FormControl {
  canValidate = false
  renderChecks = [];
  _blockKey = null;
  _getValue = null

  constructor(name, label, type, props) {
    this.name = name;
    this.label = label;
    this.type = type;
    if (type instanceof ControlConfig) {
      this.type = type.name;
      this.config = type;
    } else {
      throw new Error(`unknown type: ${type}; use ControlTypes with a FormControl`);
    }
    Object.assign(this, props);
    this.disabled = false;
  }

  setRequired() {
    this.required = true;
    if (this.config.spec) {
      this.config.spec = this.config.spec.required()
    }
    return this;
  }

  setTooltip(tooltip) {
    this.tooltip = tooltip;
    return this;
  }

  renderCheck(check) {
    this.renderChecks.push(check);
    return this;
  }

  getValue(state) {
    if (this._blockKey !== null) {
      return state[this._blockKey][this.name];
    }
    if (state[this._rootControl?._blockKey]) {
      return state[this._rootControl?._blockKey]?.[this._rootControl?.name]?.[this.formGroup?.index]?.[this.name];
    }
    return state[this.name];
  }

  setDisabled(disabled) {
    this.disabled = disabled;

    return this;
  }

  validate(value) {}
  getErrors(errors, control) {}
}


export class SelectControl extends FormControl {
  multiple = false
  constructor(name, label, options, props) {
    super(name, label, ControlTypes.select(), props)
    this.options = options
  }
  defaultFirst() {
    this._default = this.options[0]
    this._default.defaultSelected = true
    return this
  }
  allowMultiple() {
    this.multiple = true
    return this
  }
  setEnableSearch(value) {
    this.enableSearch = value
    return this
  }
  setMultiple(value) {
    this.multiple = value
    return this
  }
}

