import { apiHelp } from 'utils/apiHelpers';

// setting a user is the first thing we do, and will fail in numerous ways if not set.
// Therefore I think it's okay not to do this with a thunk
export const setUser = user => {
  return {
    type: 'SET_USER_DATA',
    payload: { user }
  };
};

export const markRead = read => {
  return {
    type: 'MARK_READ',
    payload: { read }
  };
};

export const updateUnread = unread => {
  return {
    type: 'UPDATE_UNREAD',
    payload: { unread }
  };
};

export const dismissNotification = (notification_id, notification_type) => {
  return dispatch => {
    // Handle "loading" state between click and api response outside of redux state
    apiHelp('users/dismiss_notification', { notification_id }).then(() => {
      // check if there is another one?

      // Nah, just remove
      dispatch({
        type: 'DISMISS_NOTIFICATION',
        payload: notification_type
      });
    });
  };
};
