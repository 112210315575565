import React, { useContext } from 'react';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';
import SideNavSub from './SideNavSub';

const SideNavBenchmarks = () => {
  const { hasAccessTo } = useContext(AppContext);

  if (hasAccessTo('benchmarks')) {
    return (
      <NavLink
        to="/goals"
        title="Goals"
        nestedMenu={
          <SideNavSub
            title="Goals"
            links={[
              { to: '/goals', label: 'Overview' },
              { to: '/goals/templates', label: 'Templates' },
              { to: '/goals/assign', label: 'Assignments' },
              { to: '/goals/reports', label: 'Reports' }
            ]}
          />
        }
      >
        <Icon name="benchmarks" size={24} />
        <span className="sidenav__item-label">Goals</span>
      </NavLink>
    );
  }

  return null;
};

export default SideNavBenchmarks;
