import { useCallback, useState, useEffect } from 'react';
import moment from 'moment';

export const useDob = dob => {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [hasError, setError] = useState(false);

  useEffect(() => {
    if ('' !== dob && moment(dob, ['YYYY-MM-DD', 'MM-DD-YYYY']).isValid()) {
      if (hasError) {
        setError(false);
      }
      setDob(moment(dob, ['YYYY-MM-DD', 'MM-DD-YYYY']).format('MM-DD-YYYY'));
    } else {
      setError(true);
    }
  }, [dob]);

  const setDob = useCallback(string => {
    const [newMonth, newDay, newYear] = string.split('-');
    setDay(newDay);
    setMonth(newMonth);
    setYear(newYear);
  });

  return [day, setDay, month, setMonth, year, setYear, hasError];
};
