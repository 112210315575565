import moment from 'moment';
import { useEffect } from 'react';

export const short_date_format = (date, with_time) => {
  let format = 'MMMM D, YYYY';
  if (with_time) {
    format = `${format} h:mm A`;
  }
  return moment(date).format(format);
};

// https://stackoverflow.com/questions/56574593/access-firestore-id-generator-on-the-front-end
const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
export function autoId() {
  let autoId = ''
  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(
      Math.floor(Math.random() * CHARS.length)
    )
  }
  return autoId
}


export function useSubscription(obs, callback, deps = []) {
  useEffect(() => {
    const sub = obs?.subscribe(callback)
    return () => sub?.unsubscribe();
  }, [obs, ...deps])
}

export class Objs {
  static keys(obj) {
    return Object.getOwnPropertyNames(obj);
  }

  static* values(obj) {
    for (const key in obj) {
      yield obj[key];
    }
  }

  static valuesList(obj) {
    return Array.from(this.values(obj))
  }
}

export function getOrdinalFromNumber(number) {
  if (isNumeric(number)) {
    if (number % 10 === 1 && number % 100 !== 11) {
      return `${number}st`;
    } else if (number % 10 === 2 && number % 100 !== 12) {
      return `${number}nd`;
    } else if (number % 10 === 3 && number % 100 !== 13) {
      return `${number}rd`;
    } else {
      return `${number}th`;
    }
  }

  return '';
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
