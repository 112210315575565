/**
 * External dependencies
 */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';

/**
 * Internal dependencies
 */
import { apiHelp } from 'utils/apiHelpers';
import { closeDrawer } from 'actions/actionCreators';
import Loading from 'components/Helpers/Loading';
import { rows, parseFilters } from './helpers';
import './style.scss';

const BulkMessageDrawer = ({
  drawer: {
    drawerOpen,
    drawerProps: { id }
  }
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [filters, setFilters] = useState({});
  const [recipients, setRecipients] = useState([]);
  const [user, setUser] = useState('');
  const [date, setDate] = useState('');
  const [scheduled, setScheduled] = useState('No');
  const [status, setStatus] = useState('Pending');

  useEffect(() => {
    getBulkMessage(id);
  }, []);

  const getBulkMessage = useCallback(id => {
    apiHelp('/clients/get_bulk_message', { bulk_message_id: id }).then(res => {
      const bulk_message = res?.data?.bulk_message ?? false;
      if (bulk_message) {
        setMessage(bulk_message.message ?? 'No Message Provided');
        setFilters(parseFilters(bulk_message.filters));
        setRecipients(
          bulk_message.recipients.length
            ? bulk_message.recipients
            : ['No Recipients Selected']
        );
        setUser(bulk_message.user ?? 'Unknown');
        setDate(
          bulk_message.scheduled_time
            ? bulk_message.scheduled_time
            : bulk_message.created_at ?? 'Unknown'
        );
        setScheduled(bulk_message.scheduled_time ? 'Yes' : 'No');
        setStatus(
          bulk_message.cancelled === '1'
            ? 'Cancelled'
            : bulk_message.scheduled_time &&
              moment(bulk_message.scheduled_time).isAfter(moment())
            ? 'Pending'
            : 'Sent'
        );
        setIsLoading(false);
      }
    });
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="vc-container bulk-message-drawer">
        <div className="vc-container__scroll-wrapper">
          <div
            className={classnames('vc-container__header', {
              'drawer-open': drawerOpen
            })}
          >
            <h3 className="vc-container__header--heading">Message</h3>
          </div>
          <div className="vc-container__info-block">
            {rows.map(r => (
              <div key={r.title} className="info-block-row">
                <div className="info-block-row__title">{r.title}</div>
                <div className="info-block-row__value">
                  {r.title === 'SENDER' ? (
                    user
                  ) : r.title === 'SEND DATE' ? (
                    moment(date, 'YYYY-MM-DD H:mm:ss').format('MMMM D, YYYY')
                  ) : r.title === 'SEND TIME' ? (
                    moment(date, 'YYYY-MM-DD H:mm:ss').format('h:mm A')
                  ) : r.title === 'SCHEDULED' ? (
                    scheduled
                  ) : r.title === 'STATUS' ? (
                    status
                  ) : r.title === 'MESSAGE' ? (
                    message
                  ) : r.title === 'RECIPIENTS' ? (
                    <>
                      {recipients.length &&
                      recipients[0] !== 'No Recipients Selected' ? (
                        <div className="recipients">
                          {recipients.length} Recipients
                        </div>
                      ) : null}
                      {recipients.map((r, i) => (
                        <div key={i}>{r}</div>
                      ))}
                    </>
                  ) : (
                    filters[r.key]
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ drawer }) => ({ drawer });

export default connect(mapStateToProps, { closeDrawer })(BulkMessageDrawer);
