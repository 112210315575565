import { loadSuccessful } from './helperActions';

import {
  catchErrors,
  requestConversations as requestConversationsAPI
} from 'utils/apiHelpers';

/**
 * Requests a conversation using the API.
 *
 * @param {object} params Includes clientId & showCaseManagers properties.
 */
export function requestConversations({ clientId, showCaseManagers }) {
  const getClientConvos = showCaseManagers && clientId;
  return dispatch => {
    dispatch(
      getClientConvos
        ? {
            type: 'LOAD_PENDING_CLIENT_CONVERSATIONS',
            payload: { clientId }
          }
        : {
            type: 'LOAD_PENDING_CONVERSATIONS'
          }
    );

    const params = getClientConvos
      ? {
          all_messages: true,
          client_id: clientId
        }
      : {};
    requestConversationsAPI(params)
      .then(res => {
        res.data.clientId = clientId;
        dispatch(
          loadSuccessful(
            getClientConvos ? 'CLIENT_CONVERSATIONS' : 'CONVERSATIONS',
            res.data
          )
        );
      })
      .catch(catchErrors);
  };
}
