import { loadPending, removePending, loadSuccessful } from './helperActions';

import {
  getWindowReport as getTodaysWindowReport,
  catchErrors
} from 'utils/apiHelpers';

export function getWindowReport(today, hash) {
  return dispatch => {
    // set to pending
    dispatch(loadPending('WINDOW_REPORT'));

    if (hash) {
      getTodaysWindowReport(today, hash)
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending('WINDOW_REPORT'));
          } else {
            dispatch(loadSuccessful('WINDOW_REPORT', res.data));
          }
        })
        .catch(catchErrors);
    } else {
      getTodaysWindowReport(today)
        .then(res => {
          dispatch(loadSuccessful('WINDOW_REPORT', res.data));
        })
        .catch(catchErrors);
    }
  };
}
