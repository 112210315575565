import '@babel/polyfill';
import { ConnectedRouter } from 'connected-react-router';
import Cookies from 'js-cookie';
import { Provider } from 'react-redux';
import React, { lazy, Suspense } from 'react';
import ReactDom from 'react-dom';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider as JotaiProvider } from 'jotai';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import store, { history } from './store';
import AuthLock from 'components/Auth/AuthLock';
import Loading from 'components/Helpers/Loading';
import sentryBeforeSend from './sentryHelpers';
const ErrorBoundary = lazy(() => import('components/ErrorBoundary'));

import 'scss/_index.scss';
import 'scss/c2t.scss';
import { Toaster } from 'react-hot-toast';
import { environment } from 'utils/Constants';

if (ENVIRONMENT !== 'production') {
  console.clear(); // eslint-disable-line no-console
}

window.addEventListener('beforeunload', () => {
  if (Cookies.get('iframed')) {
    // IFRAMED users should be logged out when they leave.
    Cookies.remove('id_token');
    Cookies.remove('iframed');
  }
});

if (ENVIRONMENT === 'production') {
  ReactGA.initialize('UA-153188271-2');
}

if (ENVIRONMENT === 'production') {
  history.listen(location => {
    ReactGA.pageview(location.pathname);
  });
}

if (['production', 'staging', 'development'].includes(ENVIRONMENT)) {
  Sentry.init({
    dsn: 'https://e36c9734055a48419c4f6f395eb7b3e2@o441683.ingest.sentry.io/5412226',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: ENVIRONMENT === 'production' ? 0.5 : 1.0,
    normalizeDepth: 5,
    debug: ENVIRONMENT === 'staging',
    beforeSend: sentryBeforeSend
  });
}

ReactDom.render(
  <>
    <Provider store={store}>
      <JotaiProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ConnectedRouter history={history}>
            <Suspense fallback={<Loading />}>
              <ErrorBoundary>
                <AuthLock />
              </ErrorBoundary>
            </Suspense>
          </ConnectedRouter>
        </MuiPickersUtilsProvider>
      </JotaiProvider>
    </Provider>
    {environment.showDetailedErrors && <Toaster />}
  </>,
  document.getElementById('app')
);
