/**
 * External dependencies
 */
import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Swipe from 'react-easy-swipe';
import isEqual from 'lodash/isEqual';

/**
 * Internal dependencies
 */
import { closeDrawer } from 'actions/actionCreators';
import { AppContext } from 'components/Context/AppContext';
import useToast from 'components/Toast/useToast';
import { getDrawerType } from './helpers';
import './style.scss';

/**
 * @component
 * @description Wrapper component for drawer content. Primarily responsible for
 * executing changes in `drawerOpen` state, as well as conditionally modifying
 * other elements' classes based on various conditions. Along with `getDrawerType`,
 * returns `ComponentToRender`.
 * @param {Function} closeDrawer callback to update redux drawerOpen state
 * @param {Boolean} drawerOpen whether the drawer is open
 * @param {String} type the type of drawerf to render (`ComponentToRender`)
 * @param {Boolean} [noScroll] whether to disable scrolling on the body
 * @param {Object} location used to derive differences in the last and current `location.pathname`
 * @returns {Node} `Drawer` with `ComponentToRender` child
 */
const Drawer = ({ closeDrawer, drawerOpen, type, noScroll, location, width }) => {
  const [lastLocation, setLastLocation] = useState('');
  const drawerRef = useRef();
  const toast = useToast();
  const drawerOpenRef = useRef();

  useEffect(() => {
    if (drawerOpenRef?.current !== drawerOpen) {
      drawerOpenRef.current = drawerOpen;
      if (!drawerOpen) {
        document.removeEventListener('mousedown', handleOutsideDrawer, false);
      } else {
        document.addEventListener('mousedown', handleOutsideDrawer, false);
      }
      return drawerOpen && noScroll
        ? document.body.classList.add('noscroll')
        : document.body.classList.remove('noscroll');
    }

    if (!isEqual(lastLocation, location.pathname) && drawerOpen) {
      setLastLocation(location.pathname);
      closeDrawer();
    }
  }, [drawerOpenRef.current, drawerOpen, handleOutsideDrawer, lastLocation, location.pathname]);

  const handleOutsideDrawer = useCallback(
    e => {
      if (document.body.classList.contains('is-lightbox-open')) {
        return;
      }

      if (
        // these top two account for clicking a multi-select in the drawer
        (e.target.parentNode?.classList?.contains('react-select__indicator') ?? false) ||
        (e.target?.classList?.contains('react-select__indicator') ?? false) ||
        (e.target?.classList?.contains('react-toast') ?? false) ||
        (e.target.classList?.contains('drawer-reload') ?? false) ||
        (e.target.parentNode?.classList?.contains('drawer-reload') ?? false)
      ) {
        return;
      }
      const modalPortal = document.querySelector('.ReactModalPortal');
      const otherModals = document.querySelector('.modal');

      if ((modalPortal && modalPortal.contains(e.target)) || (otherModals && otherModals.contains(e.target))) {
        return;
      }
      if (drawerRef?.current && !drawerRef?.current?.contains(e.target)) {
        const activeRow = document.querySelector('.active-row');
        if (activeRow) {
          activeRow.classList.remove('active-row');
        }

        const hasPrintEnabled = document.querySelector('.print-violations');

        if (hasPrintEnabled) {
          hasPrintEnabled.classList.remove('print-violations');
        }
        closeDrawer();
      }
    },
    [closeDrawer]
  );

  const handleDrawerToast = useCallback(({ content, type: toastType }) => {
    toast.addToast({
      position: 'top',
      type: toastType,
      content
    });
  }, []);

  const ComponentToRender = useMemo(() => getDrawerType(type), [type]);

  const className = useMemo(() => {
    if (['TOPIC', 'RESOURCE', 'PACKET', 'ASSIGNMENT'].includes(type)) {
      return ' curriculum';
    }

    return '';
  }, [type]);

  return (
    <Swipe onSwipeRight={closeDrawer} tolerance={100}>
      <div
        ref={drawerRef}
        className={classNames(`drawer-component${className}`, {
          'drawer-open': drawerOpen
        })}
        style={{ width }}
      >
        {!type ? (
          <></>
        ) : (
          <AppContext.Consumer>
            {({ handleLightbox }) => (
              <ComponentToRender handleLightbox={handleLightbox} handleDrawerToast={handleDrawerToast} />
            )}
          </AppContext.Consumer>
        )}
      </div>
    </Swipe>
  );
};

Drawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  // when the drawer is closed, it's still active for animating purposes but doesn't have a `type`
  type: PropTypes.string,
  noScroll: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

const mapStateToProps = ({
  drawer: {
    drawerOpen,
    type,
    drawerProps: { noScroll, width }
  }
}) => ({ drawerOpen, type, noScroll, width });

export default withRouter(connect(mapStateToProps, { closeDrawer })(Drawer));
