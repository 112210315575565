/* eslint-disable no-unused-vars */
/**
 * External dependencies
 */
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { closeDrawer } from 'actions/actionCreators';
import { makeSkuOptions } from 'components/Settings/Payments/helpers';
import { makeTransactionDetails } from './helpers';
import Icon from 'components/Icon';
import 'components/Drawer/style.scss';
import './style.scss';

export const SelfPayWidgetDrawer = ({
  drawer: {
    drawerOpen,
    drawerProps: { selectedSubscription, clientName }
  },
  closeDrawer
}) => {
  const details = useMemo(() => {
    return makeTransactionDetails(selectedSubscription);
  }, [selectedSubscription]);

  return (
    <div className="vc-container subscription-drawer">
      <div className="vc-container__scroll-wrapper">
        <div
          className={classnames('vc-container__header', {
            'drawer-open': drawerOpen
          })}
        >
          <h3 className="vc-container__header--heading">Transaction Detail</h3>
        </div>
        <div className="vc-container__info-block">
          <div className="info-block-row">
            <div className="info-block-row__title">CLIENT</div>
            <div className="info-block-row__value">
              <Link
                className="profile-link"
                to={`/clients/get/${selectedSubscription.client.client_id}/subscription`}
                onClick={closeDrawer}
                key={'client-link'}
              >
                {clientName}
                <Icon name="profile-dark" className="profile-dark" />
              </Link>
            </div>
          </div>
          {details.map((d, i) =>
            d.value ? (
              <div key={i} className="info-block-row">
                <div className="info-block-row__title">{d.label}</div>
                <div className="info-block-row__value">{d.value}</div>
              </div>
            ) : null
          )}
          <div className="info-block-row">
            <div className="info-block-row__title">DETAILS</div>
            <div className="info-block-row__value">
              <ul>
                {selectedSubscription?.programs[0]?.skus?.map(s => {
                  const skuDetails = makeSkuOptions([s] ?? []);
                  return skuDetails.map((s, i) => (
                    <li key={`${s.id}-${i}`}>
                      {s.label}&nbsp;({s.subhead})
                    </li>
                  ));
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ drawer }) => ({ drawer, closeDrawer }))(
  SelfPayWidgetDrawer
);
