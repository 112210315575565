import React from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import isString from 'lodash/isString';
import clone from 'lodash/clone';

import Label from 'components/Label';
import CustomSelect from 'components/CustomSelect';
import formatBadTimes from 'utils/formatBadTimes';
import Icon from 'components/Icon';
import { createOptionsFromStringList } from 'components/Clients/ClientsGet/Info/BasicInfo/Schemas/helpers';
import './style.scss';

function multipartMoment(val) {
  if (!Array.isArray(val)) {
    if (!val) return null;
    return moment(val);
  }
  if (!val[0]) return null;
  const date = moment(clone(val[0]));
  const time = moment(val[1], 'hh:mm A');
  const full = date.set('hour', time.hour()).set('minute', time.minute());
  return full;
}

const meridiemOptions = createOptionsFromStringList('AM, PM');

const TimeInput = ({
  label,
  name,
  error = false,
  value = '',
  handleChange,
  disabled = false,
  blockGroup,
  ...props
}) => {
  const values = value.split(' ');
  const timeValue = values[0];
  const amPmValue = values[1];

  const handleTimeChange = React.useCallback(
    event => {
      const goodTime = formatBadTimes(event.target.value);
      const time =
        goodTime.split(':')[0] > 12 ? moment(goodTime, ['HH:mm']).format('hh:mm A') : goodTime + ' ' + amPmValue;
      const newEvent = {
        target: {
          name,
          value: time
        }
      };
      handleChange(newEvent, blockGroup, props.callback);
      checkForErrors(time);
    },
    [amPmValue, name, handleChange, checkForErrors]
  );

  const handleAmPmChange = React.useCallback(
    ({ target: { value } }) => {
      const time = `${timeValue} ${value}`;
      handleChange({ target: { name, value: time } }, blockGroup, props.callback);
      checkForErrors(time);
    },
    [timeValue, handleChange, checkForErrors, blockGroup, props.callback, name]
  );

  const checkForErrors = React.useCallback(
    time => {
      function getSchemaDate(val) {
        if (!Array.isArray(val)) return props.getStateValue(val, blockGroup);
        return val.map(v => props.getStateValue(v, blockGroup));
      }
      let hasError = false;
      if (!props.setErrorsCallback) return;

      const momentTime = moment(time, 'hh:mm A');
      if (!momentTime.isValid()) {
        hasError = true;
      }
      if (Array.isArray(props.validation) && 'function' === typeof props.getStateValue) {
        for (const validation of props.validation) {
          if (validation.compareValues) {
            // sometimes we get null dates (like no end date) and we account for it here by using the same date
            let dateOne = multipartMoment(getSchemaDate(validation.compareValues));
            let dateTwo = multipartMoment([getSchemaDate(validation.date_value), time]);
            if (!dateOne) {
              dateOne = dateTwo;
            }
            if (!dateTwo) {
              dateTwo = dateOne;
            }

            if (validation?.buffer?.value && validation?.buffer?.unit) {
              // compare to "time from now"
              dateOne = moment().add(validation.buffer.value, validation.buffer.unit);
            }

            if (dateTwo[validation.method](dateOne)) {
              hasError = true;
            }
          } else if (validation.value) {
            const momentCompare = moment(props.getStateValue(validation.value, blockGroup), 'hh:mm A');
            if (momentTime[validation.method](momentCompare)) {
              hasError = true;
            }
          }
          if (hasError) {
            break;
          }
        }
      }
      if (hasError) {
        props.setErrorsCallback(name, blockGroup, true);
      } else {
        if (error) {
          props.setErrorsCallback(name, blockGroup, false);
        }
        if (props.clearsErrors && Array.isArray(props.clearsErrors)) {
          for (const field of props.clearsErrors) {
            props.setErrorsCallback(field, blockGroup, false);
          }
        }
        let inverse = -1;
        if (Array.isArray(props.validation)) {
          inverse = props.validation?.[0]?.compareValues?.findIndex?.(t => name?.split('_')[1] === t?.split('_')[1]);
        }
        if (inverse && inverse > -1) {
          props.setErrorsCallback(name, blockGroup, false, props.validation[0].compareValues[inverse]);
        }
      }
    },
    [props.setErrorsCallback, props.validation, props.getStateValue, blockGroup, name]
  );

  return (
    <div className="time-input">
      {label ? (
        <Label htmlFor={name} bold required={props.required}>
          {label}
        </Label>
      ) : null}
      <div className="input-container">
        <Cleave
          id={props.id ? props.id : name}
          name={name}
          className="base-input"
          disabled={disabled}
          value={timeValue}
          options={{ time: true, timePattern: ['h', 'm'] }}
          onBlur={handleTimeChange}
        />
        <CustomSelect
          name={name + '_suffix'}
          handleCustomSelectChange={handleAmPmChange}
          value={amPmValue}
          disabled={disabled}
          options={meridiemOptions}
        />
      </div>
      {error && (
        <div className="error-container">
          <Icon name="warning" />
          <Label htmlFor={name} alert mBottom>
            {!isString(error)
              ? props.errorMessage
                ? props.errorMessage
                : 'There was an error processing your time.'
              : error}
          </Label>
        </div>
      )}
    </div>
  );
};

TimeInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  blockGroup: PropTypes.string
};

export default TimeInput;
