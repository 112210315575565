/**
 * Internal dependencies
 */
import { buildQueryString } from 'data/dashboardQueryBuilder';
import { getClient } from 'utils/graphQlClient';

export const getData = (
  userId,
  blocks,
  checkInReportDate,
  violationsUnresolvedOnly,
  violationsLimit,
  messagesLimit = 10
) => {
  return getClient().request(
    buildQueryString(blocks, {
      userId,
      checkInReportDate,
      violationsUnresolvedOnly,
      violationsLimit,
      messagesLimit
    })
  );
};
