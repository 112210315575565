import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Loading from 'components/Helpers/Loading';

import './image.scss';

export default class Image extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    styles: PropTypes.object,
    alt: PropTypes.string,
    disabled: PropTypes.bool
  };

  state = {
    loaded: false,
    shouldRotate: false
  };

  imageRef = React.createRef();

  handleClick = () => {
    const { handleClick, src } = this.props;

    if ('function' === typeof handleClick) {
      handleClick(src, this.state.shouldRotate);
    }
  };

  handleLoad = () => {
    if (this.imageRef) {
      const { width, height } = this.imageRef.getBoundingClientRect();

      if (width > height && this.props.isBaseline) {
        this.setState({
          shouldRotate: true
        });
      }
    }
    setTimeout(() => this.setState({ loaded: true }, this.handleCallback), 500);
  };

  handleCallback = () => {
    const { callback } = this.props;
    if (callback) {
      callback(
        this.state.loaded,
        this.state.shouldRotate ? this.props.src : false
      );
    }
  };

  render() {
    const { loaded, shouldRotate } = this.state;

    const {
      handleClick,
      src,
      styles,
      // eslint-disable-next-line no-unused-vars
      isBaseline,
      disabled,
      ...rest
    } = this.props;

    !!rest.callback && delete rest.callback;

    return (
      <div
        className={classnames('dynamo-image-container', {
          loaded: loaded,
          rotate: !!shouldRotate
        })}
      >
        {!loaded ? <Loading /> : null}
        <img
          ref={img => (this.imageRef = img)}
          className={classnames('image', {
            'image-loaded': loaded,
            rotate: !!shouldRotate,
            disabled: !!disabled
          })}
          style={!loaded ? {} : styles}
          src={src}
          onLoad={this.handleLoad}
          onClick={handleClick ? this.handleClick : undefined}
          {...rest}
        />
      </div>
    );
  }
}
