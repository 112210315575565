import { jwtInstance } from 'utils/apiHelpers';
import { loadPending, loadSuccessful, loadFailed } from './helperActions';
import curriculumUtil from 'utils/CurriculumUtil';
import curriculumRequest from 'utils/CurriculumRequest';

export const clearCurriculumError = () => dispatch => {
  dispatch({ type: 'CLEAR_CURRICULUM_ERROR' });
};

export const setItemSubmitting = () => dispatch => {
  dispatch({ type: 'CURRICULUM_ITEM_SUBMITTING' });
};

export const getCurriculumItems = (key, id) => async dispatch => {
  dispatch(loadPending('CURRICULUM_BULK', { type: key }));

  return curriculumRequest.getFetchItemsMethod(key, id)
    .then(result => {
      if (result?.error) {
        dispatch(loadFailed('CURRICULUM', { type: key, error: result.error }));
      } else {
        dispatch(loadSuccessful('CURRICULUM_BULK', { type: key, result }));
      }

      return result;
    })
    .catch(() => {
      const error = `There was an error fetching ${key}`;
      dispatch(loadFailed('CURRICULUM', { type: key, error }));

      return { error };
    });
};

export const getCurriculumItem = (key, paramArray) => async dispatch => {
  const singular = curriculumUtil.getSingularFromKey(key);

  dispatch(loadPending('CURRICULUM_SINGLE', { type: key }));

  return curriculumRequest.getFetchItemMethod(key, ...paramArray)
    .then(result => {
      if (result?.error) {
        dispatch(loadFailed('CURRICULUM', { type: key, error: result.error }));
      } else {
        dispatch(loadSuccessful('CURRICULUM_GET', { type: key, result }));
      }

      return result;
    })
    .catch(() => {
      const error = `There was an error fetching ${singular} ${paramArray[0]}`;

      dispatch(loadFailed('CURRICULUM', { type: key, error }));

      return { error };
    });
};

export const deleteCurriculumItem = (key, paramArray) => async dispatch => {
  const singular = curriculumUtil.getSingularFromKey(key);

  return curriculumRequest.getDeleteMethod(key, ...paramArray)
    .then(result => {
      if (result?.error) {
        dispatch(loadFailed('CURRICULUM', { type: key, error: result.error }));
      } else {
        dispatch(loadSuccessful('CURRICULUM_DELETE', { type: key, id: paramArray[0] }));
      }

      return result;
    })
    .catch(() => {
      const error = `There was an error deleting ${singular} ${paramArray[0]}`;

      dispatch(loadFailed('CURRICULUM', { type: key, error }));

      return { error };
    });
};

export const addCurriculumItem = (key, data) => async dispatch => {
  const singular = curriculumUtil.getSingularFromKey(key);

  dispatch(loadPending('CURRICULUM_SINGLE', { type: key }));

  return curriculumRequest.getAddMethod(key, data)
    .then(result => {
      if (result?.error) {
        dispatch(loadFailed('CURRICULUM', { type: key, error: result.error }));
      } else {
        dispatch(loadSuccessful('CURRICULUM_ADD', { type: key, result }));
      }

      return result;
    })
    .catch((e) => {
      const error = `There was an error adding the ${singular}`;
      dispatch(loadFailed('CURRICULUM', { type: key, error }));

      return { error };
    });
};

export const editCurriculumItem = (key, data) => async dispatch => {
  const singular = curriculumUtil.getSingularFromKey(key);

  dispatch(loadPending('CURRICULUM_SINGLE', { type: key }));

  return curriculumRequest.getEditMethod(key, data)
    .then(result => {
      if (result.error) {
        dispatch(loadFailed('CURRICULUM', { type: key, error: result.error }));
      } else {
        dispatch(loadSuccessful('CURRICULUM_EDIT', { type: key, result }));
      }

      return result;
    })
    .catch(() => {
      const error = `There was an error editing ${singular} ${data.id}`;
      dispatch(loadFailed('CURRICULUM', { type: key, error }));

      return { error };
    });
};

export const editUserSettings = (key, data) => async dispatch => {
  const singular = curriculumUtil.getSingularFromKey(key);

  dispatch(loadPending('CURRICULUM_SINGLE', { type: key }));

  return jwtInstance.post('users/update_curriculum_user_settings', data)
    .then(result => {
      if (result.error) {
        dispatch(loadFailed('CURRICULUM', { type: key, error: result.error }));
      } else {
        dispatch({ type: 'UPDATE_USER_SETTINGS', payload: { key, ...data } });
      }

      return result;
    })
    .catch(() => {
      const error = `There was an error modifying user settings for ${singular} ${data.id}`;
      dispatch(loadFailed('CURRICULUM', { type: key, error }));

      return { error };
    });
};