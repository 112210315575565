import Modal from 'components/Modal';
import Button from 'components/Button';
import React from 'react';

export function ErrorModal({ title, isShowing, handleClose, children }) {
  return (
    <Modal isShowing={isShowing} enableClose closeModal={handleClose} header={title}>
      <div>
        <div className="modal-message">{children}</div>
      </div>
      <div className="action-buttons flex-row justify-end">
        <Button handleButtonClick={handleClose} primary>
          Confirm
        </Button>
      </div>
    </Modal>
  );
}
