import React from 'react';
import moment from 'moment';
import { RenderChecks } from 'components/FormProvider/renderChecks';
import { Link } from 'react-router-dom';

const frequencyCallback = (setState, state, name, converted, backup) => {
  if (converted === 'time_period') {
    setState({
      ...state,
      communityEvent: {
        ...state.communityEvent,
        end_date: state.communityEvent.start_date
      }
    });
  } else {
    setState({
      ...state,
      communityEvent: {
        ...state.communityEvent,
        end_date: null
      }
    });
  }
};

const handleStartDateChange = (setState, state, blockGroup) => {
  if (!state[blockGroup].never_ends) {
    setState({
      ...state,
      [blockGroup]: {
        ...state[blockGroup],
        end_date: state.communityEvent.start_date
      }
    });
  }
};

const handleStartTimeChange = (setState, state, blockGroup) => {
  setState({
    ...state,
    [blockGroup]: {
      ...state[blockGroup],
      end_time: moment(state.communityEvent.start_time, 'hh:mm A')
        .add(1, 'hour')
        .format('hh:mm A')
    }
  });
}

export const schema = (clientOptions) => [
  {
    info: function info() {
      return (
        <>
          <p>
            This is an abbreviated <Link to="/events/community/add">Add Event</Link> form. For more options, such as
            setting a check-in for the event, use the <Link to="/events/community/add">Add Event page</Link>.
          </p>
          <p>
            Required fields are marked with an <sup>*</sup>
          </p>
        </>
      );
    },
    blockGroup: 'communityEvent',
    children: [
      [
        {
          name: 'clients',
          label: 'Client',
          type: 'select',
          options: clientOptions,
          required: true
        },
        {
          name: 'selectedClients',
          type: 'hidden'
        },
        {
          name: 'title',
          label: 'Event Title',
          required: true,
          autoFocus: true
        },
        {
          name: 'start_date',
          label: 'Start Date',
          type: 'date',
          required: true,
          minDate: moment(),
          validation: moment(),
          validationType: 'isBefore',
          errorMessage: 'Start date cannot be before the current date.',
          callback: handleStartDateChange
        },
        {
          type: 'input-group',
          className: 'datetime-fields',
          children: [
            {
              name: 'start_time',
              label: 'Start Time',
              type: 'time',
              required: true,
              validation: [
                {
                  compareValues: ['start_date', 'start_time'],
                  date_value: 'start_date',
                  method: 'isBefore',
                  buffer: {
                    value: 20,
                    unit: 'minutes'
                  }
                }
              ],
              errorMessage: 'Start date should be at least 20 minutes from now.',
              callback: handleStartTimeChange
            },
            {
              name: 'end_time',
              label: 'End Time',
              type: 'time',
              required: true,
              validation: [
                {
                  compareValues: ['start_date', 'start_time'],
                  date_value: 'end_date',
                  method: 'isBefore'
                }
              ],
              errorMessage: 'End date cannot be before the start date.'
            }
          ]
        },
        {
          name: 'frequency',
          label: 'Frequency',
          type: 'select',
          required: true,
          options: [
            {
              value: 'time_period',
              label: 'Does Not Repeat',
              callback: frequencyCallback
            },
            {
              value: 'daily_repeat',
              label: 'Repeats daily',
              callback: frequencyCallback
            },
            {
              value: 'weekly',
              labelFromValue: start_date => `Repeats weekly on ${start_date.format('dddd')}`,
              labelValue: 'start_date',
              callback: frequencyCallback
            },
            {
              value: 'monthly',
              labelFromValue: start_date => `Repeats monthly on the ${start_date.format('Do')}`,
              labelValue: 'start_date',
              callback: frequencyCallback
            },
            {
              value: 'week_day_repeat',
              label: 'Custom',
              callback: frequencyCallback
            }
          ],
          renderChecks: [
            {
              key: 'event_type',
              blockGroup: 'communityEvent',
              value: 'client-initiated',
              method: 'notEqual'
            }
          ]
        },
        {
          type: 'checkbox-group',
          name: 'days',
          groupLabel: 'Days',
          blockGroup: 'assignForm',
          allSelected: true,
          className: 'days',
          renderChecks: [
            {
              key: 'frequency',
              blockGroup: 'assignForm',
              value: 'week_day_repeat',
              method: 'isEqual'
            }
          ],
          options: ['sunday', 'thursday', 'monday', 'friday', 'tuesday', 'saturday', 'wednesday'].map(d => ({
            name: d,
            label: d.charAt(0).toUpperCase() + d.slice(1)
          }))
        },
        {
          type: 'select',
          name: 'weeks',
          label: 'Weeks',
          blockGroup: 'assignForm',
          className: 'weeks',
          renderChecks: [
            {
              key: 'frequency',
              blockGroup: 'assignForm',
              value: 'week_day_repeat',
              method: 'isEqual'
            }
          ],
          options: [
            {
              value: 1,
              label: 'Repeats Every Week'
            },
            {
              value: 2,
              label: 'Repeats Every 2 Weeks'
            },
            {
              value: 3,
              label: 'Repeats Every 3 Weeks'
            },
            {
              value: 4,
              label: 'Repeats Every 4 Weeks'
            }
          ]
        },
        {
          name: 'url',
          label: 'URL',
          renderChecks: [
            {
              key: 'event_type',
              blockGroup: 'communityEvent',
              value: 'client-initiated',
              method: 'notEqual'
            }
          ],
          validation: (name, blockGroup, value, setErrorsCallback, error) => {
            if (!value) {
              return;
            }
            const urlRegex = /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/gi;
            if (error) {
              if (urlRegex.test(value)) {
                setErrorsCallback(name, blockGroup, false);
              }
            } else {
              if (value && !urlRegex.test(value)) {
                setErrorsCallback(name, blockGroup, 'Check URL has no whitespaces or invalid characters.');
              }
            }
          }
        },
        {
          name: 'description',
          label: 'Description',
          placeholder: 'This description will be visible to the client.',
          rows: 5,
          Component: 'textarea'
        },
        {
          name: 'reminders_checkbox',
          label: 'Remind Client Before Event',
          type: 'checkbox-group',
          renderChecks: [
            {
              key: 'event_type',
              blockGroup: 'communityEvent',
              value: 'client-initiated',
              method: 'notEqual'
            }
          ]
        },
        {
          name: 'reminders',
          type: 'multi-reminder',
          renderChecks: [
            {
              key: 'event_type',
              blockGroup: 'communityEvent',
              value: 'client-initiated',
              method: 'notEqual'
            }
          ]
        }
      ],
      [
        {
          name: 'numerator',
          label: 'Random Verification Odds',
          type: 'select',
          renderChecks: [
            {
              key: 'check_in_setting',
              value: 'random_time',
              method: 'isEqual'
            }
          ],
          options: [...Array(15).keys()].map((_, index) => {
            const number = index + 1;
            return {
              value: number,
              label: number + ''
            };
          })
        },
        {
          name: 'denominator',
          label: 'Out Of',
          type: 'select',
          renderChecks: [
            {
              key: 'check_in_setting',
              value: 'random_time',
              method: 'isEqual'
            }
          ],
          options: [...Array(30).keys()].map((item, index) => {
            const number = index + 1;
            return {
              value: number,
              label: number + ''
            };
          })
        },
        {
          name: 'odds',
          label: 'Odds',
          type: 'info',
          renderChecks: [
            {
              key: 'check_in_setting',
              value: 'random_time',
              method: 'isEqual'
            }
          ],
          valueMethod: (getStateValue, setErrorsCallback, getErrors) => {
            const numerator = getStateValue('numerator', 'communityEvent');
            const denominator = getStateValue('denominator', 'communityEvent');
            const hasNumeratorError = getErrors('numerator', 'communityEvent');
            const percentage = (Math.round((10000 * numerator) / denominator) / 100).toFixed(2);

            if (numerator > denominator) {
              if (!hasNumeratorError) {
                setErrorsCallback(
                  'numerator',
                  'communityEvent',
                  `Random verification odds cannot be more than 100%.
                    Try reducing the numerator to ${denominator} or increasing the denominator to ${numerator}.`
                );
              }
            } else {
              if (hasNumeratorError) {
                setErrorsCallback('numerator', 'communityEvent', false);
              }
            }

            return percentage > 100 ? (
              <span>{percentage}%&nbsp;is too high</span>
            ) : (
              <span>
                Clients will have a <b>{percentage}</b>% chance of having their location verified during this event.
              </span>
            );
          }
        }
      ]
    ]
  }
];

export const getActionButtons = () => [
  {
    primary: true,
    type: 'submit',
    label: `Create Event`,
    render: true
  },
  {
    primary: true,
    transparent: true,
    type: 'button',
    label: 'Reset',
    render: true,
    action: 'reset'
  }
];
