const facility = (state = false, action) => {
  switch (action.type) {
    case 'SET_FACILITY_DATA':
      return Object.assign({}, action.payload.facility);
    case 'UPDATE_BILLING_CONTACT':
      return {
        ...state,
        billing_contact: { ...action.payload.billing_contact }
      };
    case 'UPDATE_EMAIL_NOTIFICATIONS':
      return {
        ...state,
        reports_notifications: [...action.payload.reportsNotifications],
        schedule_notifications: [...action.payload.scheduleNotifications]
      };
    case 'UPDATE_MAX_TESTS_PER_DAY':
      return {
        ...state,
        max_tests_per_day: action.payload.maxTestsPerDay
      };
    case 'UPDATE_CUSTOM_MESSAGES':
      return {
        ...state,
        ...action.payload.customMessages
      };
    case 'UPDATE_REDUCED_DAY_WEIGHT':
      return {
        ...state,
        reduced_day_weight: action.payload.weight
      };
    case 'UPDATE_BREATHALYZER_THRESHOLD':
      return {
        ...state,
        breathalyzer_threshold: action.payload.threshold
      };
    case 'LOAD_PENDING_FACILITY_FORMS':
      return {
        ...state,
        isRequestingFacilityForms: true
      };
    case 'LOAD_SUCCESSFUL_FACILITY_FORMS':
      return {
        ...state,
        isRequestingFacilityForms: false,
        forms: action.payload.forms
      };
    default:
      return state;
  }
};

export default facility;
