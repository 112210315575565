/**
 * External dependencies
 */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * Internal dependencies
 */
import Icon from 'components/Icon';
import useDebounce from 'hooks/useDebounce';
import { getClients } from 'actions/actionCreators';

/**
 * Custom hook to conduct the searches through the clients in the state.
 * @param {string} searchTerm Terms input by user to search by.
 * @param {array} clients Clients to search.
 * @return {array} Returns the results, an array of clients.
 */
function useGlobalSearch(searchTerm, clients) {
  const firstNameResults = searchClients(searchTerm, clients, 'f');
  const lastNameResults = searchClients(searchTerm, clients, 'l');
  const clientIdResults = searchClients(searchTerm, clients, 'id');
  // for phone numbers, we strip out any puctuation and just leave numbers, but if there are no numbers, we don't want to search on an empty string
  let phoneResults = [];
  const numbersOnlySearchTerm = searchTerm.replace(/[^0-9]/g, '');
  if (numbersOnlySearchTerm.length > 1) {
    phoneResults = searchClients(numbersOnlySearchTerm, clients, 'phone');
  }
  const emptyResult = { id: 0, message: 'No clients found.' };
  // Deduplicate the results. First concat the results array together, then
  // convert to a set then covert back to an array.
  const result = [...new Set([...firstNameResults, ...lastNameResults, ...clientIdResults, ...phoneResults])];

  if (!result.length) return [emptyResult];
  return result;
}

/**
 * Search the clients' property by the search term.
 * @param {*} searchTerm Term to search by, the needle.
 * @param {*} clients Clients to search, the haystack.
 * @param {*} property Property to search, such as first name or "f".
 */
function searchClients(searchTerm, clients, property) {
  // Escapes all characters that might cause an error in creating the regular expression
  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Create a regex with the search term. The 'g' flag is for global match, and
  // the 'i' flag is for case insensitive.
  const regex = new RegExp(escapedSearchTerm, 'gi');

  return clients.filter(client => {
    // Execute a search for a match between the regular expression and the
    // specified client property, RegExp.prototype.test() returns true or false.
    return regex.test(client[property]);
  });
}

GlobalSearch.propTypes = {
  clients: PropTypes.object,
  htmlId: PropTypes.string,
  getClients: PropTypes.func,
  className: PropTypes.string,
  hasIcon: PropTypes.bool,
  onSelect: PropTypes.func
};

export function GlobalSearch({
  clients,
  htmlId = '',
  getClients,
  className = '',
  hasIcon = true,
  onSelect = false,
  placeholder = 'Search'
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleSubmit = e => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    // It's not ideal to get the clients here, it'd be better to get them
    // higher up in the App and pass them down as props. This is how it's
    // done throughout the codebase though, so that's a project for another day.
    if (!clients.list && !clients.pending) {
      getClients(clients);
    }
  };

  // Since we call this onBlur, we need the setTimeout so that it waits for the user
  // to click the result.
  const clearResults = () => {
    setTimeout(() => {
      setResults([]);
    }, 500);
  };

  const clearBoth = () => {
    setSearchTerm('');
    setResults([]);
  };

  const onClientSelect = event => {
    clearBoth();
    if ('function' === typeof onSelect) {
      onSelect({
        clientId: event.target.dataset.id,
        first: event.target.dataset.first,
        last: event.target.dataset.last
      });
    }
  };

  // useEffect() will only execute if searchTerm hasn't changed in 500ms, thanks to
  // our useDebounce hook.
  useEffect(() => {
    if (debouncedSearchTerm && clients.list) {
      setResults(useGlobalSearch(debouncedSearchTerm, clients.list));
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <div className="global-search">
      {/* <form onKeyUp={handleSubmit}> */}
      <form onSubmit={handleSubmit} onKeyUp={handleSubmit}>
        <input
          id={'' !== htmlId ? htmlId : 'global-search'}
          type="text"
          name="global-search"
          autoComplete="off"
          value={searchTerm}
          onBlur={clearResults}
          onChange={handleSubmit}
          onFocus={clearBoth}
          className={'' !== className ? className : 'global-search__field'}
          placeholder={placeholder}
        />
        {hasIcon && <Icon name="search" />}
      </form>
      {0 < results.length && (
        <div className="global-search__results topbar__dropdown" data-testid="global-search__dropdown">
          <ul>
            {results.map(result => {
              // dont return inactive clients
              if (!result.a) {
                return null;
              }
              const Wrapper = !result.message
                ? 'function' === typeof onSelect
                  ? ({ children }) => (
                      <a
                        role="button"
                        onClick={onClientSelect}
                        data-id={result.id}
                        data-first={result.f}
                        data-last={result.l}
                      >
                        {children}
                      </a>
                    )
                  : ({ onClick, children }) => <a onClick={onClick}>{children}</a>
                : Fragment;
              const wrapperProps =
                !result.message && false === onSelect
                  ? {
                      onClick: () => {
                        clearBoth();
                        document.location.href = `/clients/get/${result.id}/info`;
                      }
                    }
                  : {};
              return (
                <li key={result.id}>
                  <Wrapper {...wrapperProps}>
                    {!!result.f && !!result.l ? (
                      `${result.f} ${result.l}`
                    ) : (
                      <em className="no-results">{result.message}</em>
                    )}
                  </Wrapper>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const clientList = ownProps.clients ? ownProps.clients : state.clients;
  return {
    clients: clientList
  };
}

const mapDispatchToProps = { getClients };

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
