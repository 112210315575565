/**
 * External dependencies
 */

export default function benchmarks(state = {}, action) {
  switch (action.type) {
    case 'LOAD_SUCCESSFUL_BENCHMARK_TEMPLATES':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        templates: action.payload.list
      };
    case 'LOAD_SUCCESSFUL_BENCHMARK_CLIENTS':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        benchmarkClients: action.payload.list
      };
    case 'LOAD_SUCCESSFUL_ASSIGN_BENCHMARK':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        benchmarks: action.payload.benchmarks
      };
  }
  return state;
}
