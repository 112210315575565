/**
 * Internal dependencies
 */
import { loadPending, removePending, loadSuccessful } from './helperActions';
import {
  getLocations as getLocationsAPI,
  requestStates as requestStatesAPI,
  requestCounties as requestCountiesAPI,
  addLocation as addLocationAPI,
  updateLocation as updateLocationAPI,
  removeLocation as removeLocationAPI,
  catchErrors
} from 'utils/apiHelpers';

export function getLocations(locations, callback = false) {
  let hash = locations.doneInitialLoad && locations.hash;
  const clientId = locations.clientId ? locations.clientId : null;
  const action = clientId ? 'CLIENT_LOCATIONS' : 'LOCATIONS';

  return dispatch => {
    // set to pending
    dispatch(loadPending(action, { clientId }));

    if (hash) {
      getLocationsAPI(hash, clientId)
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending(action, { clientId }));
            if (callback) {
              callback();
            }
          } else {
            if (clientId) {
              res.data.clientId = clientId;
            }
            dispatch(loadSuccessful(action, res.data));
            if (callback) {
              callback();
            }
          }
        })
        .catch(catchErrors);
    } else {
      getLocationsAPI(false, clientId)
        .then(res => {
          if (clientId) {
            res.data.clientId = clientId;
          }
          dispatch(loadSuccessful(action, res.data));
          if (callback) {
            callback();
          }
        })
        .catch(catchErrors);
    }
  };
}

/**
 * Action dispatched to add a new location through an API request.
 *
 * @param {object} location The data for the location that will be added.
 *
 * @returns {function}
 */
export function addLocation(location) {
  const {
    address,
    clientId = null,
    coordinates,
    default_lenience,
    definition,
    description,
    exclusion,
    name,
    scope,
    address_lat,
    address_lng,
    start_time,
    end_time,
    weekdays
  } = location;
  const action = clientId ? 'CLIENT_LOCATION_ADD' : 'LOCATION_ADD';

  return dispatch => {
    dispatch(loadPending(action, { clientId }));
    addLocationAPI(
      address,
      clientId,
      coordinates,
      default_lenience,
      definition,
      description,
      exclusion,
      name,
      scope,
      address_lat,
      address_lng,
      start_time,
      end_time,
      weekdays
    )
      .then(res => {
        if (clientId) {
          res.data.clientId = clientId;
        }
        dispatch(loadSuccessful(action, res.data));
      })
      .catch(catchErrors);
  };
}

/**
 * Action dispatched to update a location through an API request.
 *
 * @param {object} location The data for the location that will be added.
 *
 * @returns {function}
 */
export function updateLocation(location) {
  const clientId = location.client_id;
  const scope = location.scope;
  const action = scope === 'client' ? 'CLIENT_LOCATION_UPDATE' : 'LOCATION_UPDATE';
  return dispatch => {
    dispatch(loadPending(action, { clientId }));
    updateLocationAPI(location)
      .then(res => {
        dispatch(loadSuccessful(action, { clientId, ...res.data }));
        if ('LOCATION_UPDATE' === action) {
          // Let's check if new data is needed in client locations
          dispatch(
            loadSuccessful('CLIENT_LOCATION_REFRESH', {
              ...res.data
            })
          );
        }
      })
      .catch(catchErrors);
  };
}

/**
 * Action dispatched to remove a location through an API request.
 *
 * @param {int} locationId The id of the location that will be removed.
 * @param {int|null} client_id Set when deleting a client location or a location assigned to a client. Null when deleting a location from Settings > Locations
 * @param {string} context Whether to remove the entry in the link_client_to_location table only, or in the location table as well. Can be 'all' or 'client'.
 *
 * @returns {function}
 */
export function removeLocation(locationId, clientId = null, context = '') {
  const action = clientId ? 'CLIENT_LOCATION_REMOVE' : 'LOCATION_REMOVE';
  return dispatch => {
    dispatch(loadPending(action, { clientId }));
    removeLocationAPI(locationId, clientId, context)
      .then(res => {
        dispatch(loadSuccessful(action, { clientId, locationId, ...res.data }));
      })
      .catch(catchErrors);
  };
}

export function requestStates() {
  return dispatch => {
    dispatch(loadPending('STATES'));

    requestStatesAPI()
      .then(res => {
        dispatch(loadSuccessful('STATES', res.data));
      })
      .catch(catchErrors);
  };
}

export function requestCounties(stateId) {
  return dispatch => {
    dispatch({
      type: 'LOAD_PENDING_COUNTIES',
      payload: { stateId }
    });

    requestCountiesAPI(stateId)
      .then(res => {
        res.data.stateId = stateId;
        dispatch(loadSuccessful('COUNTIES', res.data));
      })
      .catch(catchErrors);
  };
}

export function setMapsLibLoading() {
  return dispatch => {
    dispatch(loadPending('MAPSLIB'));
  };
}

export function setMapsLibReady() {
  return dispatch => {
    dispatch(loadSuccessful('MAPSLIB'));
  };
}
