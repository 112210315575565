/**
 * External dependencies
 */
import get from 'lodash/get';

/**
 * Internal dependencies
 */
import renderAddress from 'utils/renderAddress';

export function addLocationColumnInfo(location, states) {
  const definition = get(location, 'definition', 'address');
  switch (definition) {
    case 'address':
      location.location = renderAddress(location.address);
      break;
    case 'political':
      // get the states from the location coordinates, which are the keys in the object
      // then get the state name from states in state
      // then join them back into a comma separated string
      location.location = Object.keys(location.coordinates)
        .map(state_id => get(states, state_id + '.name', ''))
        .join(', ');
      break;
    case 'area':
      location.location = 'Custom area';
      break;
  }
  return location;
}
