import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import ModernizedDatePicker from 'components/ModernizedDatePicker';
import moment from 'moment';
import isString from 'lodash/isString';
import Label from 'components/Label';
import Icon from 'components/Icon';
import './style.scss';
import { FormFieldChange } from 'components/Form/FieldWrap';

const timesObj = {
  isSameOrBefore: (date1, date2) => moment(date1).isBefore(date2),
  isSameOrAfter: (date1, date2) => moment(date1).isSameOrAfter(date2),
  isSame: (date1, date2, period) => moment(date1).isSame(date2, period),
  isBefore: (date1, date2, period) => moment(date1).isBefore(date2, period),
  isAfter: (date1, date2, period) => moment(date1).isAfter(date2, period),
  // Verifies that the date checked if after or the same than the reference date, and that it's not set after today.
  isNotFuture: (date1, date2) => moment(date1).isBefore(date2) || moment(date1).isAfter(moment(), 'day'),
  isAfterAndEndDateNotFuture: (date1, date2, period) =>
    moment(date1).isAfter(date2, period) || moment(date2).isAfter(moment(), 'day')
};

const DateInput = ({
  apiError,
  label,
  name,
  error,
  value,
  handleChange,
  disabled,
  blockGroup,
  minDate,
  maxDate,
  mTop,
  mBottom,
  placeholder,
  containerClass,
  noTyping,
  callback,
  getHighlightedDates,
  asCustomInput,
  tooltip,
  dateFormat = 'M/dd/yyyy',
  ...props
}) => {
  const altName = `${Math.random()}`;
  const [customInputLabel, setCustomInputLabel] = useState('');
  const [highlightedDates, setHighlightedDates] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (asCustomInput) {
      setCustomInputLabel(asCustomInput.closedLabel);
    }
  }, [asCustomInput]);

  const [momentValue, setMomentValue] = useState(moment());
  useEffect(() => {
    if (value) {
      setMomentValue(moment.isMoment(value) ? value : moment(value, ['MM-DD-YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY']));
    } else {
      setMomentValue(null);
    }
  }, [value]);

  useEffect(() => {
    if (props.validation && props.validationType && 'function' === typeof props.getStateValue) {
      const comparator = props.getStateValue(props.validation, blockGroup);
      if (timesObj[props.validationType](moment(momentValue), comparator, 'day')) {
        props.setErrorsCallback(name, blockGroup, true);
      } else {
        props.setErrorsCallback(name, blockGroup, false, props.validation);
      }
    }
  }, [props.validationType, momentValue]);

  const handleDateChange = date => {
    const newValue = moment(date);
    const change = new FormFieldChange(name, newValue, blockGroup, callback);
    handleChange(change, blockGroup, callback);
  };

  const retrieveHighlightedDates = () => {
    setHighlightedDates([]);
    if (!getHighlightedDates) {
      return;
    }
    getHighlightedDates(dates => {
      if (asCustomInput && isOpen) {
        setCustomInputLabel(dates.length ? asCustomInput.openLabel : 'no occurrences found');
      }
      setHighlightedDates(dates);
    });
  };

  const disableTyping = noTyping ? { onChangeRaw: e => e.preventDefault() } : null;
  const additionalProps = {
    parentClassName: `date-input ${containerClass ?? ''}`,
    onChange: handleDateChange,
    onFocus: retrieveHighlightedDates
  };
  if (asCustomInput) {
    const CustomInput = forwardRef(({ onClick, label }, ref) => {
      return asCustomInput.getter(label, ref, onClick);
    });
    CustomInput.displayName = 'CustomInput';
    additionalProps.customInput = <CustomInput label={customInputLabel} />;
    additionalProps.onCalendarOpen = () => {
      setIsOpen(true);
      setCustomInputLabel(<>Loading...</>);
      retrieveHighlightedDates();
    };
    additionalProps.onCalendarClose = () => {
      setCustomInputLabel(asCustomInput.closedLabel);
      setIsOpen(false);
      retrieveHighlightedDates();
    };
    additionalProps.onFocus = additionalProps.onChange = false;
    additionalProps.parentClassName = `${additionalProps.parentClassName} custom-input-popper`;
  }

  return (
    <div className={additionalProps.parentClassName}>
      {label ? (
        <Label htmlFor={name} bold required={props.required} tooltip={tooltip}>
          {label}
        </Label>
      ) : null}
      <ModernizedDatePicker
        {...props}
        {...disableTyping}
        id={altName}
        name={name}
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={() => setIsOpen(false)}
        disabled={disabled}
        className={disabled ? 'disabled' : ''}
        selected={momentValue?.toDate() || null}
        minDate={minDate ? moment(minDate).toDate() : null}
        maxDate={maxDate ? moment(maxDate).toDate() : null}
        // onChange={handleDateChange}
        highlightDates={highlightedDates}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        {...additionalProps}
      />
      {error && (
        <div className="error-container">
          <Icon name="warning" />
          <Label htmlFor={name} alert mTop={mTop} mBottom={mBottom}>
            {!isString(error)
              ? props.errorMessage
                ? props.errorMessage
                : 'There was an error processing your dates.'
              : error}
          </Label>
        </div>
      )}
      {apiError && (
        <div className="error-container">
          <Icon name="warning" />
          <Label htmlFor={name} alert mTop={mTop} mBottom={mBottom}>
            {apiError}
          </Label>
        </div>
      )}
    </div>
  );
};

DateInput.defaultProps = {
  apiError: false,
  error: false,
  disabled: false,
  maxDate: moment()
    .add(2, 'years')
    .toDate(),
  mTop: false,
  mBottom: true,
  placeholder: '',
  containerClass: '',
  noTyping: false,
  callback: false
};

DateInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  blockGroup: PropTypes.string,
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noTyping: PropTypes.bool
};

export default DateInput;
