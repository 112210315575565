import React from 'react';
import { get } from 'lodash';

import curriculumUtil from 'utils/CurriculumUtil';

const TooltipContentDuration = ({ active, payload }) => {
  if (active && payload?.[0]?.payload) {
    const min = get(payload, '0.payload.min', 0);
    const max = get(payload, '0.payload.max', 0);
    const value = get(payload, '0.payload.occurrences', 0);

    return (
      <div className="tooltip-content">
        <p>
          {curriculumUtil.formatRange(min, max, value)}.&nbsp;Click to show only assignees that interacted in this
          range.
        </p>
      </div>
    );
  }

  return null;
};

export default TooltipContentDuration;
