import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const SubNav = ({ children, className = '' }) => {
  return <div className={className}>{children}</div>;
};

SubNav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  facility: PropTypes.object.isRequired,
  context: PropTypes.string.isRequired
};

export default connect(({ facility, features }) => ({ facility, features }))(
  SubNav
);
