import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Label from 'components/Label';
import FormContext from 'components/FormProvider/FormContext';

import './style.scss';

const Info = ({ label, value, valueMethod, setErrorsCallback }) => {
  const { getStateValue, getErrors } = useContext(FormContext);

  return (
    <>
      <Label htmlFor={name} bold>
        {label}
      </Label>
      <p className="label-info">
        {valueMethod
          ? valueMethod(getStateValue, setErrorsCallback, getErrors)
          : value}
      </p>
    </>
  );
};

Info.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

export default Info;
