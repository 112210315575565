import * as yup from 'yup';

import ControlConfig from 'components/Form/Model/ControlConfig';

class NumberConfig extends ControlConfig {
  constructor(min, max, defaultValue = null) {
    super('number', yup.number());
    this.min = min;
    this.max = max;
    if (defaultValue !== null) {
      this.spec.default(defaultValue)
    }
  }
}

export default NumberConfig;