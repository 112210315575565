import React from 'react';

import './style.scss';

const NoRecords = ({ customMessage }) => (
  <tr className="base-tr__no-records">
    <td colSpan="100%">
      {customMessage ? customMessage : 'No matching records found.'}
    </td>
  </tr>
);

export default NoRecords;
