import { ControlTypes } from 'components/Form/Model/ControlTypes';
import { SchemaBuilder } from './SchemaBuilder';
import { autoId, Objs } from '../../../lib/util';
import { FormControl } from 'components/Form/Model/FormControl';

export class ListControl extends FormControl {
  constructor(name, label, schema) {
    super(name, label, ControlTypes.list());
    this.canValidate = true;
    if (typeof schema !== 'function')
      throw new Error(`[${name}] you must use a \`yup\` schema with ${ListControl.name}`);
    this.schemaBuilder = new SchemaBuilder(this, schema);
  }

  _items = {};
  items = [];

  setValues(values) {
    const old = Object.assign({}, this._items);
    for (const value of values) {
      this.ensureItem(value);
      delete old[value._key];
    }
    for (const key of Objs.keys(old)) {
      delete this._items[key];
    }
    this._updateItems();
  }

  _updateItems() {
    this.items = Objs.valuesList(this._items);
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].index = i;
    }
  }

  addNew() {
    const formGroup = this.makeNew();
    this._items[formGroup._key] = formGroup;
    this.items = Objs.valuesList(this._items);
    return formGroup;
  }

  makeNew() {
    const formGroup = this.schemaBuilder.build();
    formGroup._key = autoId();
    formGroup.index = this.items.length;
    for (const key in formGroup.controls) {
      const control = formGroup.controls[key];
      control._getValue = (state) => {
        const list = this._getValue(state);
        const item = list[formGroup.index];
        if (item) {
          return control.getValue(item);
        }
        this.removeItem(formGroup);
      };
      control._setValue = (state, value) => {
        const list = this._getValue(state);
        const item = list[formGroup.index];
        item[control.name] = value;
      };
      control._rootControl = this;
    }

    return formGroup;
  }

  ensureItem(value) {
    if (value._key && this._items[value._key])
      return this._items[value._key];
    const formGroup = this.addNew();
    value._key = formGroup._key;
  }

  removeItem(formGroup) {
    if (!formGroup._key) return;
    if (!this._items[formGroup._key]) return;
    delete this._items[formGroup._key];
    this._updateItems();
  }

  validate(values) {
    if (!Array.isArray(values))
      return 'Must be an array';

    let foundError = false
    const errors = new Array(this.items.length);
    for (const item of this.items) {
      const itemErrors = {}
      errors[item.index] = itemErrors
      const value = values[item.index];
      try {
        item.spec.validateSync(value)
      } catch (e) {
        foundError = true
        itemErrors[e.path] = e.message.replace(e.path, item.controls[e.path].label)
      }

    }
    return foundError ? errors : null
  }

  getErrors(errors, control) {
    const itemErrors = errors[control.formGroup.index]
    if (!itemErrors) return null
    return itemErrors[control.name]
  }
}