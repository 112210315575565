import React, { useState, useRef, useEffect } from 'react';
import './style.scss'; // import CSS file
import Button from 'components/Button';
import Icon from 'components/Icon';
import { useToast } from 'components/Toast';
import Player from 'react-player';

const Uploader = ({ blockgroup, handleChange, name, value, presignedUrl }) => {
  // value is the link to the file
  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState(null);
  const [formattedFileUrl, setFormattedFileUrl] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isMp4, setIsMp4] = useState(false);
  const toast = useToast();
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (value) {
      setIsEdit(true);
      setFile(value);
      const fileUrl = value.substring(value.lastIndexOf('/') + 1);
      setFormattedFileUrl(fileUrl.split('?')[0]);
      const mp4Extensions = /(\.mp4|\.m4v)$/i;
      setIsMp4(mp4Extensions.exec(fileUrl.split('?')[0].toLowerCase()) ? true : false);
    }
  }, []);

  const handleDragEnter = e => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = e => {
    e.preventDefault();
    setDragging(false);

    if (e.dataTransfer.files.length > 0) {
      setFile(e.dataTransfer.files[0]);
    }
    handleChange(e, blockgroup);
  };

  const errorToast = content => {
    toast.addToast({
      position: 'top',
      type: 'warning',
      content: JSON.stringify(content)
    });
  };
  const handleFileChange = e => {
    if (e.target.files.length > 0) {
      // Allowing file type
      const allowedExtensions = /(\.jpg|\.jpeg|\.mp4|\.pdf|\.m4v)$/i;
      let filePath = e.target.files[0];
      if (allowedExtensions.exec(filePath.name.toLowerCase())) {
        setFile(e.target.files[0]);
      } else {
        handleRemove();
        errorToast('Invalid file type');
      }
    }
  };
  const handleRemove = () => {
    setFile(null);
    setIsEdit(false);
    handleChange({ target: { name, value: null } });
  };

  const handleBoxClick = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (file) {
      fileAsUrl(file).then(url => {
        handleChange({ target: { name, value: url } });
      });
    }
  }, [file]);

  const fileAsUrl = file => {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  };

  return (
    <div
      className={`uploader${dragging ? ' dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleBoxClick}
    >
      {file ? (
        <div>
          {isEdit ? (
            isMp4 ? (
              <>
                <div style={{ marginBottom: 20 }}>
                  <Player width={320} height={240} url={presignedUrl} controls playsinline />
                </div>
              </>
            ) : (
              <>
                <Icon name="document" className="document-icon-uploaded" size={50} />
                <p>{formattedFileUrl}</p>
                <a target="_blank" rel="noopener noreferrer" href={presignedUrl}>
                  <Button primary className="view-file-button">
                    View File
                  </Button>
                </a>
              </>
            )
          ) : (
            <>
              <Icon name="document" className="document-icon-uploaded" size={50} />
              <p>{file.name}</p>
            </>
          )}
          <Button handleButtonClick={handleRemove} primary transparent>
            Remove
          </Button>
        </div>
      ) : (
        <div className="upload-file">
          <Icon name="upload" size={100} />
          <h3>Select a document to upload</h3>
          <h4>PDF, JPG, MP4</h4>
          <p>or drag and drop it here</p>
          <input
            type="file"
            ref={hiddenFileInput}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".pdf,.mp4, .jpg, .jpeg, application/pdf, video/mp4"
            name={name}
          />
        </div>
      )}
    </div>
  );
};

export default Uploader;
