import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';

import { closeDrawer } from 'actions/actionCreators';
import {
  approveClientInitiatedForm,
  disapproveClientInitiatedForm,
  setToDraftClientInitiatedForm
} from 'utils/apiHelpers';
import { useToast } from 'components/Toast';
import BlockWrapper from '../common/BlockWrapper';
import InfoBlockRow from '../InfoBlockRow.js';
import Button from 'components/Button';
import ActionButtons from './ActionButtons';
import Loading from 'components/Helpers/Loading';
import { hydrate_client_initiated_form } from './helpers';
import ClientNameLink from 'components/ClientNameLink';
import './style.scss';

function TodoClientInitiatedFormDrawer({
  drawer: {
    drawerOpen,
    drawerProps: { todo, onClose, edit, getCallBack }
  },
  closeDrawer
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [responses, setResponses] = useState([]);
  const toast = useToast();
  if (edit == null) edit = true;
  useEffect(() => {
    if (responses.length != 0) {
      return;
    }

    Object.assign(todo, hydrate_client_initiated_form(todo));

    const responseBlocks = [];
    const form = todo.form_response.form
    form.fields.forEach(({ blockGroup, children }, blockIndex) =>
      children.forEach((child, i) =>
        responseBlocks.push(
          <InfoBlockRow
            key={`response-block-${blockIndex}-${i}`}
            title={child.label || child.groupLabel}
            value={getResponseValue(todo, blockGroup, child)}
            titleIsHtml={!!form.alchemer_survey_id}
          />
        )
      )
    );
    setResponses(responseBlocks);
    setIsLoading(false);
  }, [todo]);

  const getResponseValue = (todo, blockGroup, answer) => {
    const value = todo.form_response.data[blockGroup][answer.name];
    if (answer.type == 'checkbox') {
      return parseInt(value) == 1 ? 'Yes' : 'No';
    }
    if (answer.type == 'checkbox-group') {
      return Object.keys(value).join(', ');
    }
    if (answer.type == 'camera-image') {
      return (
        <a href={value} download target="_blank" rel="noopener noreferrer">
          <img width="100%" src={value} alt={answer.label} />
        </a>
      );
    }
    return value;
  };

  const handlePrint = useCallback(e => {
    e?.preventDefault();

    const body = document.querySelector('body');
    body.classList.add('print-violations');

    window.print();
  }, []);

  const handleFormAction = (method, success_message, error_message) => {
    method(todo.external_id, todo.form_response.id).then(response => {
      if (response.data.status == 'success') {
        getCallBack();
        closeDrawer();
        if (onClose && typeof onClose == 'function') {
          onClose();
        }
        toast.addToast({
          duration: 3000,
          type: 'checkmark',
          content: success_message
        });
      } else {
        toast.addToast({
          duration: 3000,
          type: 'warning',
          content: error_message
        });
      }
    });
  };

  const handleDisapproveForm = useCallback(() => {
    handleFormAction(
      disapproveClientInitiatedForm,
      'Form disapproved.',
      'There was an error disapproving the form, please try again.'
    );
  }, [todo.client_id, todo.external_id, closeDrawer, toast]);

  const handleApproveForm = useCallback(() => {
    handleFormAction(
      approveClientInitiatedForm,
      'Form approved.',
      'There was an error approving the form, please try again.'
    );
  }, [todo.client_id, todo.external_id, closeDrawer, toast]);

  const handleSetFormToDraft = useCallback(() => {
    handleFormAction(
      setToDraftClientInitiatedForm,
      'Form set to draft.',
      'There was an error setting the form back to a draft, please try again.'
    );
  }, [todo.client_id, todo.external_id, closeDrawer, toast]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="vc-container">
      <div className="vc-container__scroll-wrapper">
        <div className="print-only">
          <img className="print-logo" src="/static/img/r7t-logo.jpg" />
          <h2>{moment(todo.created_at).format('MMM D, YYYY h:mm A')}</h2>
          <h2>Client-Scheduled Form for {todo.client.first_name + ' ' + todo.client.last_name}</h2>
        </div>
        <div
          className={classnames('vc-container__header', {
            'drawer-open': drawerOpen
          })}
        >
          <h3 className="vc-container__header--heading">Client-Scheduled Form</h3>
          <div className={`status ${todo.form_status.class}`}>
            <span>{todo.form_status.label}</span>
          </div>
        </div>
        <div className="vc-container__print-button">
          <Button secondary micro handleButtonClick={handlePrint}>
            Print
          </Button>
        </div>
        <BlockWrapper
          clientName={<ClientNameLink client_id={todo.client_id} clientName={`${todo.client.first_name} ${todo.client.last_name}`} />}
          closeDrawer={closeDrawer}
          status={todo.status}
          block={{
            blockLabel: todo.form_name
          }}
        />
        <div className="drawer-body vc-container__info-block form-changed">
          {[['Date Submitted', todo.date]].map((e, i) => (
            <InfoBlockRow key={i} title={e[0]} value={e[1]} />
          ))}
        </div>
        <div className="form-responses">
          <h4 className="form-responses-header">Form Responses</h4>
          {/* <div className="form-responses-header">
          <InfoBlockRow key="form-responses-header" title="Form Responses" value={null} />
        </div> */}
          <div className="drawer-body vc-container__info-block form-changed">{responses}</div>
        </div>
        {todo.form_status.label != 'Acknowledged' && edit && (
          <ActionButtons
            drawerOpen={drawerOpen}
            approveForm={handleApproveForm}
            disapproveForm={handleDisapproveForm}
            setFormToDraft={handleSetFormToDraft}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  drawer: state.drawer
});

export default connect(mapStateToProps, { closeDrawer })(TodoClientInitiatedFormDrawer);
