import { loadPending, removePending, loadSuccessful } from './helperActions';

import {
  getViolations as getViolationsAPI,
  getViolationsPolling as getViolationsPollingAPI,
  getViolationsByClient as getViolationsByClientAPI,
  clearViolations as clearViolationsAPI,
  clearViolationsByClient as clearViolationsByClientAPI,
  catchErrors
} from 'utils/apiHelpers';

export function getViolations(
  violations,
  offset = 0,
  limit,
  sort_by,
  sort_direction,
  type,
  callback = false,
  include_unresolved_only = false
) {
  let hash = violations.doneInitialLoad && violations.hash;

  return dispatch => {
    // set to pending
    dispatch(loadPending('VIOLATIONS'));

    if (hash) {
      getViolationsAPI(
        hash,
        offset,
        limit,
        sort_by,
        sort_direction,
        type,
        include_unresolved_only
      )
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending('VIOLATIONS'));
            if (callback) {
              callback();
            }
          } else {
            dispatch(loadSuccessful('VIOLATIONS', res.data));
            if (callback) {
              callback();
            }
          }
        })
        .catch(catchErrors);
    } else {
      getViolationsAPI(
        false,
        offset,
        limit,
        sort_by,
        sort_direction,
        type,
        include_unresolved_only
      )
        .then(res => {
          dispatch(loadSuccessful('VIOLATIONS', res.data));
          if (callback) {
            callback();
          }
        })
        .catch(catchErrors);
    }
  };
}

export function getViolationsPolling(violations, callback = false) {
  let hash = violations.doneInitialLoad && violations.hash;

  return dispatch => {
    // set to pending
    dispatch(loadPending('VIOLATIONS_POLLING'));

    if (hash) {
      getViolationsPollingAPI(hash)
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending('VIOLATIONS_POLLING'));
            if (callback) {
              callback();
            }
          } else {
            dispatch(loadSuccessful('VIOLATIONS_POLLING', res.data));
            if (callback) {
              callback();
            }
          }
        })
        .catch(catchErrors);
    } else {
      getViolationsPollingAPI(false)
        .then(res => {
          dispatch(loadSuccessful('VIOLATIONS_POLLING', res.data));
          if (callback) {
            callback();
          }
        })
        .catch(catchErrors);
    }
  };
}

export function getViolationsByClient(
  client_id,
  offset = 0,
  limit,
  sort_by,
  sort_direction,
  type,
  callback = false
) {
  return dispatch => {
    // set to pending
    dispatch(loadPending('VIOLATIONS'));
    getViolationsByClientAPI(
      client_id,
      offset,
      limit,
      sort_by,
      sort_direction,
      type
    )
      .then(res => {
        dispatch(loadSuccessful('VIOLATIONS', res.data));
        if (callback) {
          callback();
        }
      })
      .catch(catchErrors);
  };
}

export function clearViolations(startDate, endDate) {
  return clearViolationsAPI(startDate, endDate);
}

export function clearViolationsByClient(clientId, startDate, endDate) {
  return clearViolationsByClientAPI(clientId, startDate, endDate);
}
