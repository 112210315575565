/**
 * External dependencies
 */
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';

/**
 * Internal dependencies
 */
import Modal from 'components/Modal';
import Button from 'components/Button';

export const CustomPrompt = ({ when, message }) => {
  const [promptVisible, setPromptVisible] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [locationsEqual, setLocationsEqual] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    setLocationsEqual(pathname === nextLocation);
  }, [pathname, nextLocation]);

  useEffect(() => {
    if (confirmed && nextLocation && !locationsEqual) {
      history.push(nextLocation);
      setConfirmed(false);
      setNextLocation(null);
    }
  }, [confirmed, nextLocation, pathname]);

  const showPrompt = location => {
    setPromptVisible(true);
    setNextLocation(location);
  };

  const closePrompt = () => setPromptVisible(false);

  const handleBlock = newLocation => {
    if (!confirmed && when && !locationsEqual) {
      showPrompt(newLocation.pathname);
      return false;
    }
    return true;
  };
  const handleConfirm = () => {
    if (nextLocation) {
      setConfirmed(true);
    }
    setPromptVisible(false);
  };

  return (
    <>
      <Prompt when={when} message={handleBlock} />
      {promptVisible && (
        <Modal
          header="Alert"
          isShowing={promptVisible}
          enableClose
          closeModal={closePrompt}
        >
          <p className="modal-message">{message}</p>
          <div className="action-buttons">
            <Button handleButtonClick={handleConfirm} primary>
              Leave
            </Button>
            <Button handleButtonClick={closePrompt} primary transparent>
              Cancel
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
export default CustomPrompt;
