/**
 * Internal dependencies
 */
import { loadPending, loadSuccessful } from './helperActions';
import {
  catchErrors,
  requestGetFormEvent,
  requestGetFormEventOccurrences,
  requestGetFormEvents,
  requestUpdateFormEvent,
  requestDeleteFormEvent
} from 'utils/apiHelpers';

export function updateFormEvent(id, data, callback = false) {
  return dispatch => {
    dispatch(loadPending('UPDATE_FORM_EVENT'));

    requestUpdateFormEvent(id, data)
      .then(res => {
        dispatch(
          loadSuccessful('UPDATE_FORM_EVENT', {
            formEvent: {
              ...res.data
            }
          })
        );
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getFormEvents(startDate, isActive, callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_EVENTS'));

    requestGetFormEvents(isActive, startDate, 'form')
      .then(res => {
        dispatch(
          loadSuccessful('GET_EVENTS', {
            events: res.data.events
          })
        );
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function deleteFormEvent(id, callback = false) {
  return dispatch => {
    dispatch(loadPending('DELETE_FORM_EVENT'));

    requestDeleteFormEvent(id)
      .then(res => {
        dispatch(loadSuccessful('DELETE_FORM_EVENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getFormEvent(id, callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_FORM_EVENT'));

    requestGetFormEvent(id)
      .then(res => {
        dispatch(loadSuccessful('GET_FORM_EVENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getEventOccurrences(
  externalId,
  callback = false,
  onlyOne = false
) {
  return dispatch => {
    dispatch(loadPending('GET_EVENT_OCCURRENCES'));

    requestGetFormEventOccurrences(externalId, onlyOne)
      .then(res => {
        dispatch(loadSuccessful('GET_EVENT_OCCURRENCES'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}
