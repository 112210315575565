/**
 * External dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Circle,
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  Polyline,
  DrawingManager
} from '@react-google-maps/api';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

/**
 * Internal dependencies
 */
import { getZoom } from './mapHelpers';
import { isMapsLibReady } from 'selectors/mapsLib';
import QueryMapsLib from 'components/data/query-maps-lib';
import './style.scss';

/**
 * @todo:
 *  - simplify props
 *  - build in lifecycle methods?
 *  - set state or make it totally props-driven?
 *  - may need to use `GroundOverlay` component to achieve dynamic bounds
 *  - test out first on LocationHistory and then migrate it to other `google-maps-react` instances
 *  - remove `google-maps-react`
 *  - dynamically create `zIndex` for all GoogleMap children, especially primaryMarker and polyline
 */

/**
 * Get a group of options for circles and polygons.
 *
 * @param {bool} isExclusion If it's true, the shapes will be red. Otherwise blue.
 *
 * @return {object} Options for circles and polygons.
 */
const shapeOptions = isExclusion => ({
  strokeColor: isExclusion ? '#EF4541' : '#6FBE49',
  fillColor: isExclusion ? '#EF4541' : '#6FBE49',
  strokeWeight: 0,
  fillOpacity: 0.45
});

/**
 * Get an array of coordinates and return data that Google Maps can render.
 *
 * @param {array} zone Array of arrays of coordinates.
 *
 * @return {object} Array of objects with latitude and longitude coordinates.
 */
const coordinatesToMapShape = zone =>
  zone.map(vertex => ({
    lat: parseFloat(vertex[0]),
    lng: parseFloat(vertex[1])
  }));

export class Map extends Component {
  static propTypes = {
    clearOnLoad: PropTypes.bool,
    draw: PropTypes.oneOf(['', 'address', 'area', 'political']),
    editLenience: PropTypes.bool,
    isExclusion: PropTypes.bool,
    onOverlayUpdate: PropTypes.func,
    onCenterUpdate: PropTypes.func,
    showClearButton: PropTypes.bool,
    zone: PropTypes.array,
    zoom: PropTypes.number,
    circles: PropTypes.array,
    multizones: PropTypes.array,
    staticDraw: PropTypes.bool,
    isNewLocation: PropTypes.bool,
    disableDraw: PropTypes.bool,
    // Connected props
    isMapsLibReady: PropTypes.bool
  };

  static defaultProps = {
    circles: [],
    disableDraw: false,
    staticDraw: false,
    editLenience: false,
    isNewLocation: false,
    zoom: 16
  };

  constructor(props) {
    super(props);
    this.state = {
      zoom: props.zoom ? props.zoom : 16,
      hasOverlay: false
    };
  }

  mapRef = React.createRef();
  drawingManager = null;
  selectedShape = null;
  ignoreCircleCenterMove = false;
  overlays = []; // Collect all overlays to delete them later
  circleAddress = null; // For address
  defaultCenter = { lat: 37.2737109, lng: -104.6816578 };

  bounds = false;

  componentWillUnmount() {
    this.mapRef = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      'area' === this.props.draw &&
      'area' === nextProps.draw &&
      !this.props.staticDraw &&
      isEqual(nextProps.zone, this.props.zone) &&
      isEqual(nextProps.isExclusion, this.props.isExclusion) &&
      nextState.hasOverlay === this.state.hasOverlay
    ) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    if (this.bounds == false && window.google && window.google.maps) {
      this.bounds = new window.google.maps.LatLngBounds();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.isMapsLibReady) {
      return null;
    }
    if (this.bounds == false) {
      this.bounds = new window.google.maps.LatLngBounds();
    }

    if (this.drawingManager) {
      if (
        (prevProps.circles &&
          this.props.circles &&
          prevProps.circles.length !== this.props.circles.length) ||
        (prevProps.zone &&
          this.props.zone &&
          prevProps.zone.length !== this.props.zone.length)
      ) {
        // if the number of items was changed, we need to reset our bounds so we don't have ghost bounds
        this.bounds = new window.google.maps.LatLngBounds();
      }
      if (
        prevState.hasOverlay &&
        prevProps.clearOnLoad !== this.props.clearOnLoad &&
        this.props.clearOnLoad
      ) {
        this.deleteAllShapes();
      }
      if (prevProps.draw !== this.props.draw) {
        this.deleteAllShapes();
        if (this.props.draw !== 'political') {
          this.drawingManager.set('drawingControl', true);
        } else {
          this.endDrawingMode();
        }
      }
      if (prevProps.zoom !== this.props.zoom) {
        this.setState({ zoom: this.props.zoom });
      }
      if (
        prevProps.draw !== this.props.draw ||
        prevProps.isExclusion !== this.props.isExclusion ||
        !isEqual(prevProps.zone, this.props.zone)
      ) {
        switch (this.props.draw) {
          case 'address':
            if ('object' === typeof get(this.props, 'circle.center', null)) {
              this.drawCircleForAddress(this.props.circle);
            }
            break;
          case 'area':
          case 'political':
            this.drawPolygons();
            break;
        }
      }
      if (this.props.editLenience && this.props.draw == 'address') {
        if (
          !this.circleAddress &&
          get(this.props, 'circle.center', null) &&
          'object' === typeof get(this.props, 'circle.center', null)
        ) {
          this.drawCircleForAddress(this.props.circle);
          this.setBounds();
        }
      }
      if (
        'address' !== this.props.draw &&
        !isEqual(prevProps.zone, this.props.zone)
      ) {
        this.drawPolygons();
      }
      if (
        'area' === this.props.draw &&
        !isEqual(prevProps.circles, this.props.circles)
      ) {
        if (this.props.circles.length > 0) {
          this.props.circles.forEach(coordinate => {
            this.drawCircleForAddress(coordinate, false);
          });
          this.setBounds();
        }
      }
    }
    if (this.circleAddress) {
      if (
        (!this.props?.circle?.center || !this.props?.circle?.radius) &&
        isEmpty(this.props.circles)
      ) {
        this.deleteAllShapes();
      } else {
        if (
          get(prevProps, 'circle.center', null) !==
          get(this.props, 'circle.center', null)
        ) {
          this.circleAddress.setCenter(this.props.circle.center);
        }
        if (
          get(prevProps, 'circle.radius', null) !==
          get(this.props, 'circle.radius', null)
        ) {
          this.circleAddress.setRadius(this.props.circle.radius);
        }
      }
    }
  }

  /*
   * `onMapLoad` is called when the map instance loads. When React goes through the reconciliation
   * process, we lose the bounds -- this ensures the component doesn't reset to the required `center` prop
   * coordinates. However, it may be lacking -- if the props do change, as in the case of polling,
   * how will this impact bounds?
   */
  onMapLoad = map => {
    const { data, primaryMarker, secondaryMarkers, forceZoom } = this.props;

    if (!map || !this.bounds) return;

    if (data && data.length) {
      data.forEach(location => {
        this.addLocationToBounds(location);
      });
    }

    if (primaryMarker) {
      this.addLocationToBounds(primaryMarker.position);
    }

    if (secondaryMarkers && secondaryMarkers.markers) {
      secondaryMarkers.markers.forEach(location => {
        this.addLocationToBounds(location);
      });
    }

    if (
      this.props.circles.length ||
      (Array.isArray(this.props.circle)
        ? this.props.circle.length
        : !!this.props.circle)
    ) {
      // make sure the map fits our bounds
      this.addCirclesToBounds();
      map.fitBounds(this.bounds);
    }

    if (this.mapRef) {
      if (forceZoom) {
        this.setState({
          zoom: this.props.zoom
        });
      } else {
        const { clientHeight, clientWidth } = this.mapRef;

        const zoom = getZoom(this.bounds, {
          height: clientHeight,
          width: clientWidth
        });

        this.setState({
          zoom
        });
      }
    }
    this.setState({
      map
    });
  };

  /**
   * Extends the bounds of the map to include the given point.
   *
   * @param {object} location Object with latitude and longitude coordinates.
   */
  addLocationToBounds = location => {
    // Map was crashing, looks like it was a race condition where lat and lng
    // were undefined. This check temporarily fixes it but the underlying
    // problem of the undefined lat and lng still needs to be fixed.
    const lat = location?.lat ? location?.lat : location?.latitude;
    const lng = location?.lng ? location?.lng : location?.longitude;
    if (!!lat && !!lng) {
      this.bounds.extend({
        lat: lat,
        lng: lng
      });
    }
  };

  /**
   * When users click on the map, if there's a method to call, call it.
   *
   * @param {object} e The current event.
   */
  onMapClick = e => {
    if (this.props.draw) {
      this.clearSelection();
    }
    if ('function' === typeof this.props.onMapClick) {
      this.props.onMapClick(e);
    }
  };

  /**
   * When the drawing manager is initialized, save its DOM reference.
   * If there is a primary marker and/or a circle to edit lenience,
   * we render them using the drawing manager so we have a single place to handle events.
   *
   * @param {object} drawingManager The DOM object for the drawing manager.
   */
  onDrawingManagerLoad = drawingManager => {
    this.drawingManager = drawingManager;
    const { circle, zone, isNewLocation, draw, circles } = this.props;

    // if we're drawing or editing a map where lenience can be adjusted with a circle
    if (circle && circle.center) {
      this.addCirclesToBounds();
      this.drawCircleForAddress(circle);
    } else if (
      get(zone, [0, 0, 0], []).length > 2 ||
      Array.isArray(get(zone, [0, 'coordinates'], undefined))
    ) {
      this.drawPolygons();
    } else if (circles.length > 0) {
      this.props.circles.forEach(coordinate => {
        this.drawCircleForAddress(coordinate, false);
      });
    }

    if (isNewLocation && draw !== 'area') {
      this.endDrawingMode();
    }
  };

  onOverlayUpdate = shape => {
    const geometry = this.getGeometry(shape);
    if ('function' === typeof this.props.onOverlayUpdate) {
      // If it's a hand drawn shape, conform to the same data structure used for state/county
      this.props.onOverlayUpdate(
        'area' === this.props.draw ? [[[geometry.coordinates]]] : geometry
      );
    }
  };

  onCenterUpdate = coordinates => {
    if ('function' === typeof this.props.onCenterUpdate) {
      this.props.onCenterUpdate(coordinates);
    }
  };

  /**
   * When the user finishes drawing a marker or a polygon.
   *
   * @param {object} newShape Overlay completed by user.
   */
  onOverlayComplete = shape => {
    const newShape = shape.overlay;
    const type = shape.type;
    this.overlays.push(newShape);
    this.setState({ hasOverlay: true });

    const maps = window.google.maps;

    this.endDrawingMode();
    this.onOverlayUpdate(newShape);

    if (!this.props.staticDraw) {
      maps.event.addListener(newShape, 'dragend', () => {
        this.onOverlayUpdate(newShape);
      });

      if (type === maps.drawing.OverlayType.CIRCLE) {
        maps.event.addListener(newShape, 'radius_changed', () => {
          this.onOverlayUpdate(newShape);
        });
        maps.event.addListener(newShape, 'center_changed', () => {
          const coordinates = {
            lat: newShape.center.lat(),
            lng: newShape.center.lng()
          };
          this.onCenterUpdate(coordinates);
        });
      }

      if (type !== maps.drawing.OverlayType.MARKER) {
        maps.event.addListener(newShape, 'click', e => {
          if (e.vertex !== undefined) {
            const path = newShape.getPaths().getAt(e.path);
            path.removeAt(e.vertex);
            if (path.length < 3) {
              newShape.setMap(null);
            }
            this.onOverlayUpdate(newShape);
          }

          this.setSelection(newShape);
        });
        maps.event.addListener(newShape, 'mouseup', () => {
          this.onOverlayUpdate(newShape);
        });
      } else {
        maps.event.addListener(newShape, 'click', () => {
          this.setSelection(newShape);
        });
      }
    }

    this.setSelection(newShape);
  };

  /**
   * Used to render a circle in the map. This is used in the address mode.
   *
   * @param {object} circle Includes center and radius of the circle.
   * @param {boolean} clearFirst Whether to clear any shape before drawing.
   */
  drawCircleForAddress = (circle, clearFirst = true) => {
    if (clearFirst) {
      this.deleteAllShapes();
    }
    const shape = new window.google.maps.Circle({
      ...{
        ...shapeOptions(
          undefined === circle.exclusion
            ? this.props.isExclusion
            : parseInt(circle.exclusion, 10) === 1
        ),
        ...circle
      },
      map: this.state.map,
      editable: this.props.editLenience,
      draggable: false,
      center: circle.center,
      radius: circle.radius
    });
    this.onOverlayComplete({
      overlay: shape,
      type: 'circle'
    });
    // Save the circle to later move it when the address changes
    this.circleAddress = shape;
  };

  /**
   * Used to render a polygon in the map. This is used in the area and political modes.
   *
   * @param {object} zone A list of the area coordinates to draw as a polygon.
   * @param {boolean|undefined} exclusion Whether the polygon is an exclusion o inclusion area.
   */
  drawPolygonForZone = (zone, exclusion = undefined) => {
    const shape = new window.google.maps.Polygon({
      ...shapeOptions(
        undefined === exclusion ? this.props.isExclusion : exclusion
      ),
      map: this.state.map,
      editable: 'area' === this.props.draw && !this.props.staticDraw,
      draggable: 'area' === this.props.draw && !this.props.staticDraw,
      paths: coordinatesToMapShape(zone)
    });
    this.onOverlayComplete({
      overlay: shape,
      type: 'polygon'
    });
  };

  /**
   * Render the multiple polygons involved in political locations, and also the single polygon in area locations.
   */
  drawPolygons = () => {
    this.deleteAllShapes();
    // set our bounds
    const zone = this.props.zone;
    const theLoop = (first, exclusion = undefined) => {
      if (Array.isArray(first)) {
        first.forEach(second =>
          second.forEach(third => {
            third.forEach(coordinate =>
              this.bounds.extend(
                new window.google.maps.LatLng(
                  parseFloat(coordinate[0]),
                  parseFloat(coordinate[1])
                )
              )
            );
            this.drawPolygonForZone(third, exclusion);
          })
        );
      }
    };
    if (get(zone, [0, 0, 0], []).length > 2) {
      zone.forEach(first => theLoop(first));
    } else if (Array.isArray(get(zone, [0, 'coordinates'], undefined))) {
      zone.forEach(({ coordinates, exclusion = undefined }) => {
        if (get(coordinates, [0, 0, 0], []).length > 2) {
          coordinates.forEach(first => theLoop(first, exclusion));
        }
      });
      // Doing this here because otherwise when polygons are drawn they're erased
      // and if we prevent the erasing, the polygons are drawn multiple times.
      this.props.circles.forEach(coordinate => {
        this.drawCircleForAddress(coordinate, false);
      });
    }
    this.setBounds();
  };

  setBounds = () => {
    this.addCirclesToBounds();
    const map = this.state.map;
    if (!map) {
      return;
    }
    if (this.bounds.getCenter().lat()) {
      map.fitBounds(this.bounds);
      this.defaultCenter = {
        lat: this.bounds.getCenter().lat(),
        lng: this.bounds.getCenter().lng()
      };
    } else {
      this.defaultCenter = this.props.center;
    }
    map.setCenter(this.defaultCenter);
  };

  /**
   * Add circle bounds to global map bounds
   *
   */
  addCirclesToBounds = () => {
    this.props.circles.forEach(coordinate => {
      const circle = new window.google.maps.Circle({
        center: {
          lat: parseFloat(coordinate.center.lat),
          lng: parseFloat(coordinate.center.lng)
        },
        radius: coordinate.radius
      });
      const bounds = circle.getBounds();
      if (bounds) {
        this.bounds.union(bounds);
      }
    });
    if ('object' === typeof get(this.props, 'circle.center', null)) {
      const circle = new window.google.maps.Circle(this.props.circle);
      const bounds = circle.getBounds();
      if (bounds) {
        this.bounds.union(bounds);
      }
    }
  };

  clearSelection = () => {
    if (this.selectedShape) {
      this.selectedShape = null;
    }
  };

  setSelection = shape => {
    if (!this.props.staticDraw) {
      if (shape.type !== 'marker') {
        this.clearSelection();
        if ('political' !== this.props.draw) {
          shape.setEditable(true);
        }
      }
      this.selectedShape = shape;
    }
  };

  deleteAllShapes = e => {
    e && 'function' === e.preventDefault && e.preventDefault();
    this.setState({ hasOverlay: false });
    while (this.overlays[0]) {
      this.overlays.pop().setMap(null);
    }
    this.circleAddress = null;
    if ('area' === this.props.draw) {
      this.drawingManager.set('drawingControl', true);
    }
  };

  getGeometry = shape => {
    const geometry = {};
    if ('function' === typeof shape.getPath) {
      const path = shape.getPath();
      geometry.coordinates = [];
      for (var i = 0; i < path.getLength(); i++) {
        geometry.coordinates[i] = path
          .getAt(i)
          .toUrlValue(5)
          .split(',')
          .map(coordinate => parseFloat(coordinate));
      }
    } else if (shape.radius) {
      geometry.coordinates = [shape.getCenter().lat(), shape.getCenter().lng()];
      geometry.radius = shape.radius;
    }
    return geometry;
  };

  /**
   * End drawing. This is called every time a draw is completed so users won't inadvertently draw again and ruin the drawing.
   */
  endDrawingMode = () => {
    this.drawingManager.setDrawingMode(null);
    this.drawingManager.set('drawingControl', false);
  };

  render() {
    if (!this.props.isMapsLibReady) {
      return <QueryMapsLib />;
    }

    const {
      center,
      circle,
      containerStyles,
      draw,
      editLenience,
      fullscreenControl,
      isExclusion,
      isNewLocation,
      parentStyles,
      polyline,
      primaryMarker,
      renderClusterer,
      secondaryMarkers,
      showInfoWindow,
      showClearButton,
      disableDraw,
      mapType = 'satellite'
    } = this.props;

    const { zoom, hasOverlay } = this.state;
    return (
      <div ref={map => (this.mapRef = map)} style={{ ...parentStyles }}>
        {draw && hasOverlay && showClearButton && (
          <button className="map__clear" onClick={this.deleteAllShapes}>
            Clear
          </button>
        )}
        <GoogleMap
          center={
            (isNewLocation && !primaryMarker) ||
            'object' !== typeof center ||
            (0 === center.lat, 0 === center.lng)
              ? this.defaultCenter
              : center
          }
          mapContainerClassName="map-container"
          mapContainerStyle={{
            height:
              !!containerStyles && !!containerStyles.height
                ? containerStyles.height
                : '640px',
            width: '100%',
            ...containerStyles
          }}
          onClick={this.onMapClick}
          options={{
            zoomControl: true,
            mapTypeControl: true,
            mapTypeId: mapType,
            scaleControl: false,
            streetViewControl: false,
            controlSize: 20,
            fullscreenControl: fullscreenControl !== false,
            maxZoom: 18,
            gestureHandling: 'greedy'
          }}
          zoom={isNewLocation && !primaryMarker ? 4 : zoom}
          onLoad={this.onMapLoad}
        >
          {!!primaryMarker && (
            <Marker
              position={primaryMarker.position}
              clickable={!!primaryMarker.clickable}
              onClick={
                'function' === typeof primaryMarker.handleClick
                  ? primaryMarker.handleClick
                  : null
              }
              icon={
                primaryMarker.icon
                  ? primaryMarker.icon
                  : {
                      path: window.google.maps.SymbolPath.CIRCLE,
                      scale: zoom * 1.05,
                      fillColor: '#6cc049',
                      fillOpacity: 0.6,
                      strokeColor: 'transparent',
                      strokeWeight: 1,
                      strokeOpacity: 0.2,
                      zIndex: 99999
                    }
              }
            />
          )}
          {!!secondaryMarkers && (
            <MarkerClusterer>
              {clusterer =>
                secondaryMarkers.markers.map((marker, i) => {
                  // marker may be a falsey value (as in from ViolationsDrawer)
                  if (marker) {
                    return (
                      <Marker
                        key={`${marker.id}-${i}`}
                        position={
                          marker.position
                            ? marker.position
                            : { lat: marker.latitude, lng: marker.longitude }
                        }
                        clusterer={renderClusterer ? clusterer : null}
                        clickable={!!marker.clickable}
                        icon={
                          marker.icon
                            ? marker.icon
                            : {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: this.state.zoom * 1.05,
                                fillColor: '#0000FF',
                                fillOpacity: 0.5,
                                strokeColor: 'transparent',
                                strokeWeight: 1,
                                zIndex: 999999 + i
                              }
                        }
                        onClick={() => secondaryMarkers.onMarkerClick(marker)}
                      />
                    );
                  }
                })
              }
            </MarkerClusterer>
          )}
          {!draw &&
            !editLenience &&
            !!circle &&
            !!circle.length &&
            circle.map((c, i) => {
              return (
                !!c && (
                  <Circle
                    key={`circle-${i}`}
                    center={c.center}
                    options={{
                      strokeColor: c.strokeColor ?? '#4A89EF',
                      strokeOpacity: c.strokeOpacity ?? 1,
                      strokeWeight: c.strokeWeight ?? 1,
                      fillColor: c.fillColor ?? '#4A89EF',
                      fillOpacity: c.fillOpacity ?? 0.2,
                      clickable: false,
                      draggable: false,
                      editable: false,
                      visible: c.visible ?? true,
                      radius: c.radius ?? 0,
                      zIndex: 99999
                    }}
                  />
                )
              );
            })}
          {!!polyline && (
            <Polyline
              options={{
                strokeColor: polyline.strokeColor
                  ? polyline.strokeColor
                  : '#0000FF',
                strokeOpacity: polyline.strokeOpacity
                  ? polyline.strokeOpacity
                  : 0.8,
                strokeWeight: polyline.strokeWeight ? polyline.strokeWeight : 2,
                path: polyline.path
              }}
            />
          )}
          {!!showInfoWindow && !!showInfoWindow.visible && (
            <InfoWindow
              onCloseClick={this.onMapClick}
              visible={showInfoWindow.visible}
              position={showInfoWindow.position} // set to active marker instead of dedicated props
            >
              {showInfoWindow.infoWindowChildren}
            </InfoWindow>
          )}
          {((draw && !disableDraw) || editLenience) && (
            <DrawingManager
              drawingMode={null}
              options={{
                drawingControlOptions: {
                  drawingModes: draw == 'area' ? ['polygon'] : null
                },
                polygonOptions: shapeOptions(isExclusion),
                circleOptions: shapeOptions(isExclusion)
              }}
              onLoad={this.onDrawingManagerLoad}
              onOverlayComplete={this.onOverlayComplete}
            />
          )}
        </GoogleMap>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isMapsLibReady: isMapsLibReady(state)
  };
}

export default connect(mapStateToProps)(Map);
