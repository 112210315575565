class GenericBuilder {
  items = [];

  constructor() {}

  addItem(item) {
    this.items.push(item);

    return this;
  }

  addItems(items = []) {
    for (const item of items) {
      this.addItem(item);
    }

    return this;
  }
}

export default GenericBuilder;