/**
 * Internal dependencies
 */
import { loadPending, loadSuccessful } from './helperActions';
import {
  catchErrors,
  requestAddCommunityEvent,
  requestUpdateCommunityEvent,
  requestGetCommunityEvent,
  requestDeleteCommunityEvent
} from 'utils/apiHelpers';

export function addCommunityEvent(data, callback = false) {
  return dispatch => {
    dispatch(loadPending('ADD_COMMUNITY_EVENT'));

    requestAddCommunityEvent(data)
      .then(res => {
        dispatch(
          loadSuccessful('ADD_COMMUNITY_EVENT', {
            communityEvent: {
              ...res.data
            }
          })
        );
        if (callback !== false) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function updateCommunityEvent(id, data, callback = false) {
  return dispatch => {
    dispatch(loadPending('UPDATE_COMMUNITY_EVENT'));

    requestUpdateCommunityEvent(id, data)
      .then(res => {
        dispatch(
          loadSuccessful('UPDATE_COMMUNITY_EVENT', {
            communityEvent: {
              ...res.data
            }
          })
        );
        if (callback !== false) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getCommunityEvent(id, callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_COMMUNITY_EVENT'));

    requestGetCommunityEvent(id)
      .then(res => {
        dispatch(loadSuccessful('GET_COMMUNITY_EVENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function deleteCommunityEvent(id, callback = false) {
  return dispatch => {
    dispatch(loadPending('DELETE_COMMUNITY_EVENT'));

    requestDeleteCommunityEvent(id)
      .then(res => {
        dispatch(loadSuccessful('DELETE_COMMUNITY_EVENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}
