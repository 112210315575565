/**
 * External dependencies
 */
import moment from 'moment';

/**
 * Add start_time and end_time properties of each object in the collection to start and end.
 *
 * @param {array<object>} events Collection of events
 *
 * @returns {array<object>}
 */
const mapStartEndDates = events =>
  events.map(x => ({
    ...x,
    start: moment(x.start_time).toDate(),
    end: moment(x.end_time).toDate()
  }));

export default mapStartEndDates;
