import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { When } from 'react-if';
import { clone, pick } from 'lodash';

import BlockWrapper from '../common/BlockWrapper';
import Recording from '../common/Recording';
import ResultDataBox from '../Violations/ResultDataBox';
import getDrawerProps, { getIsDrawerOpen } from 'selectors/getDrawerProps';
import { useCloseDrawer } from 'components/Curriculum/hooks';
import SummaryList from './SummaryList';
import MapAndOrImage from './MapAndOrImage';
import NotesForm from './NotesForm';
import ReportForm from './ReportForm';
import WindowReport from './WindowReport';
import { Options } from 'utils/TestingUtil';
import './styles.scss';

const DAFAULT_REPORT = {
  summary: {},
  clients: {}
}

const DEFAULT_STATE = {
  ready: false,
  playingSound: false,
  search: '',
  result: null,
  notes: null,
  panels: null,
  panels_other: null,
  substances: [],
  substances_other: null,
  test_type: null,
  waiting_status: null,
  expanded: null,
  windowReport: DAFAULT_REPORT,
  filteredReport: DAFAULT_REPORT,
  old: {},
};

const CheckInHistoryDrawer = () => {
  const [state, setState] = useState(DEFAULT_STATE);
  const drawerProps = useSelector(getDrawerProps);
  const drawerOpen = useSelector(getIsDrawerOpen);
  const handleCloseDrawer = useCloseDrawer();

  useDeepCompareEffect(() => {
    const { windowReport, type, block } = drawerProps;

    const result = block?.test_result ?? 'not_logged';
    const notes = block?.test_notes ?? 'not_logged';
    const panels = block?.panels ?? [];
    const panels_other = block?.panels_other ?? '';
    const substances = block?.substances ?? [];
    const substances_other = block?.substances_other ?? '';
    const test_type = block?.test_type ?? '';
    const waiting_status = block?.waiting_status ?? '';

    const initialState = {
      windowReport: windowReport,
      filteredReport: windowReport,
      type,
      result,
      notes,
      panels,
      panels_other,
      substances,
      substances_other,
      test_type,
      waiting_status,
    };

    initialState.old = clone(initialState);

    setState(prev => ({ ...prev, ...initialState, ready: true }));
  }, [drawerProps]);

  const handleChange = useCallback(({ target: { name, value } }) => {
    const state = { [name]: value };

    if (name === Options.KEYS.result && value !== 'fail') {
      state.substances = [];
      state.substances_other = null;
      state.waiting_status = null;
    }

    if (name === Options.KEYS.panels && value !== 'Other') {
      state.panels_other = null;
    }

    if (name === Options.KEYS.substances && !value.includes('Other')) {
      state.substances_other = null;
    }

    setState(prev => ({ ...prev, ...state }));
  }, []);

  const handleButtonClick = useCallback(e => {
    e.preventDefault();
    const { saveInfo } = drawerProps;

    if ('function' === typeof saveInfo) {
      saveInfo(pick(state, Options.properties));
      handleCloseDrawer();
    }
  }, [drawerProps?.saveInfo, handleCloseDrawer, state]);

  const togglePlay = useCallback((e) => {
    e.preventDefault();
    setState(prev => ({ ...prev, playingSound: !prev.playingSound }));
  }, []);

  const stopSound = useCallback(() => {
    setState(prev => ({ ...prev, playingSound: false }))
  }, []);

  const setExpanded = useCallback(type => {
    setState(prev => ({ ...prev, expanded: type }))
  }, []);

  const isSaveDisabled = useMemo(() => {
    return Options.properties.every(key => isEqual(state[key], state.old[key]));
  }, [state]);

  const title = useMemo(() => {
    return get(drawerProps, 'windowReport.window_info.name', drawerProps.type);
  }, [drawerProps]);

  const blockProps = useMemo(() => {
    if (drawerProps?.type !== 'Check-in Report') {
      return {};
    }

    return {
      recordingUrl: drawerProps.block.recordingUrl,
      clientName: drawerProps.block.clientName,
      hasPhone: drawerProps.block.hasPhone,
      resultData: drawerProps.block.result_data,
      status: drawerProps.status,
      hasPos: drawerProps.hasPos,
      block: drawerProps.block
    }
  }, [drawerProps]);

  return (
    <div className="vc-container client-report">
      <div className="vc-container__scroll-wrapper">
        <div className={classnames('vc-container__header', { 'drawer-open': drawerOpen })}>
          <h3 className="vc-container__header--heading">
            {title}
          </h3>
        </div>
        <>
          <When condition={drawerProps.type === 'Window Report'}>
            <div className="vc-container__info-block">
              <WindowReport filteredReport={state.filteredReport} />
            </div>
          </When>
          <When condition={drawerProps.type === 'Check-in Report'}>
            <MapAndOrImage />
            <BlockWrapper
              clientName={blockProps.clientName}
              hasPos={blockProps.hasPos}
              status={blockProps.status}
              block={blockProps.block}
              checkUrl={blockProps.recordingUrl ? (
                <Recording
                  togglePlay={togglePlay}
                  stopSound={stopSound}
                  checkUrl={blockProps.recordingUrl}
                  fromPhone={blockProps.hasPhone}
                  playingSound={state.playingSound}
                />
              ) : false}
            />
            {blockProps.resultData ? <ResultDataBox resultData={blockProps.resultData} /> : null}
            <NotesForm
              handleChange={handleChange}
              notes={state.notes}
              drawerOpen={drawerOpen}
              isSaveDisabled={isSaveDisabled}
              handleButtonClick={handleButtonClick}
            />
            <ReportForm
              state={state}
              handleChange={handleChange}
              handleButtonClick={handleButtonClick}
              isSaveDisabled={isSaveDisabled}
            />
          </When>
          <When condition={drawerProps.type === 'Check-In Summary'}>
            <SummaryList
              report={drawerProps?.report ?? []}
              expanded={state.expanded}
              setExpanded={setExpanded}
            />
          </When>
        </>
      </div>
    </div>
  )
}

export default CheckInHistoryDrawer;
