export default function profiles(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_PROFILES':
      return {
        ...state,
        pending: true
      };
    case 'REMOVE_PENDING_PROFILES':
      return {
        ...state,
        pending: false
      };
    case 'LOAD_SUCCESSFUL_PROFILES':
      return {
        doneInitialLoad: true,
        pending: false,
        list: action.payload.test_profiles,
        hash: action.payload.hash
      };
  }
  return state;
}
