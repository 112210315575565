import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { validate } from 'email-validator';

import Icon from 'components/Icon';
import Button from 'components/Button';
import ValidatedInput from 'components/ValidatedInput';
import Input from 'components/Input';
import FormContext from 'components/FormProvider/FormContext';

import './style.scss';
import Label from 'components/Label';

const InputList = ({
  handleChange,
  setErrorsCallback,
  name,
  value,
  blockGroup,
  label,
  ...props
}) => {
  const { getValueHasChanged } = useContext(FormContext);

  const handleRemove = i => {
    handleChange(
      { target: { name, value: value.filter((_, idx) => i !== idx) } },
      blockGroup
    );
    if (props.handleRemove) {
      props.handleRemove(i);
    }
  };

  const handleAdd = () => {
    const newVals = [...value, ''];
    handleChange({ target: { name, value: newVals } }, blockGroup);
  };

  const handleInputChange = ({ target: { value: newVal } }, i) => {
    const newVals = [...value];
    newVals[i] = newVal;
    handleChange({ target: { name, value: newVals } }, blockGroup);
  };

  const disabled =
    (!!props.handleAdd && !value[value.length - 1]) ||
    (!!value.length && props.validate && !validate(value[value.length - 1]));

  return (
    <div className="base-array-list">
      {props.groupLabel ? <Label bold>{props.groupLabel}</Label> : null}
      {!!value.length &&
        value.map((v, i) => {
          return (
            v !== undefined &&
            v !== null && (
              <div
                key={i}
                className={classnames('base-array-list__input', {
                  'with-icon': !!props.removable && !!v
                })}
              >
                {props.validate ? (
                  <ValidatedInput
                    handleChange={e => handleInputChange(e, i)}
                    subType={props.validate}
                    value={v}
                    blockGroup={blockGroup}
                    name={`${name}-${i}`}
                    setErrorsCallback={setErrorsCallback}
                  />
                ) : (
                  <Input
                    handleChange={e => handleInputChange(e, i)}
                    value={v}
                    label={label}
                  />
                )}
                {!!props.removable && !!v && (
                  <a
                    className={classnames('base-array-list__icon-minus', {
                      'icon-top':
                        !!props.savable &&
                        value.length - 1 !== i &&
                        getValueHasChanged(name, blockGroup, i)
                    })}
                    onClick={() => handleRemove(i)}
                  >
                    <Icon name="minus" />
                  </a>
                )}
                {!!props.savable &&
                  value.length - 1 !== i &&
                  getValueHasChanged(name, blockGroup, i) && (
                    <a
                      className="base-array-list__icon-plus"
                      onClick={() => props.handleSave(v, i)}
                    >
                      <Icon name="checkmark" />
                    </a>
                  )}
              </div>
            )
          );
        })}
      {!!props.appendable && (
        <div className="base-array-list__add-button">
          <Button
            handleButtonClick={
              !props.handleAdd
                ? handleAdd
                : () => props.handleAdd(value[value.length - 1])
            }
            secondary
            transparent
            micro
            disabled={disabled}
          >
            {props.addLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

InputList.propTypes = {
  handleChange: PropTypes.func.isRequired,
  setErrorsCallback: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  blockGroup: PropTypes.string.isRequired,
  props: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    type: PropTypes.oneOf(['array-list']),
    validate: PropTypes.oneOf(['email']),
    removable: PropTypes.bool,
    appendable: PropTypes.bool
  })
};

export default InputList;
