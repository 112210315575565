export default function formEvents(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_ADD_FORM_EVENT':
      return {
        ...state,
        isAddingFormEvent: true
      };
    case 'LOAD_SUCCESSFUL_ADD_FORM_EVENT':
      return {
        ...state,
        isAddingFormEvent: false
      };
    case 'LOAD_PENDING_UPDATE_FORM_EVENT':
      return {
        ...state,
        isUpdatingFormEvent: true
      };
    case 'LOAD_SUCCESSFUL_UPDATE_FORM_EVENT':
      return {
        ...state,
        isUpdatingFormEvent: false
      };
    case 'LOAD_PENDING_DELETE_FORM_EVENT':
      return {
        ...state,
        isDeletingFormEvent: true
      };
    case 'LOAD_SUCCESSFUL_DELETE_FORM_EVENT':
      return {
        ...state,
        isDeletingFormEvent: false
      };
    case 'LOAD_PENDING_GET_EVENTS':
      return {
        ...state,
        isLoadingEvents: true
      };
    case 'LOAD_SUCCESSFUL_GET_EVENTS':
      return {
        ...state,
        isLoadingEvents: false,
        list: action.payload.events
      };
  }
  return state;
}
