import React from 'react';

import Button from 'components/Button';
import { useCloseDrawer } from 'components/Curriculum/hooks';

const ActionButtons = ({ handleSubmit, submitDisabled, formId }) => {
  const closeDrawer = useCloseDrawer();

  return (
    <div className="vc-container__scroll-wrapper--action-buttons">
      <Button handleButtonClick={handleSubmit} disabled={submitDisabled} primary type="submit" form={formId}>
        Submit
      </Button>
      <Button handleButtonClick={closeDrawer} primary transparent>
        Cancel
      </Button>
    </div>
  );
};

export default ActionButtons;
