/**
 * External dependencies
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
/**
 * Internal dependencies
 */
import { apiHelp, catchErrors } from 'utils/apiHelpers';
import FormProvider from 'components/FormProvider';
import { schema, actionButtons } from './schema';

class Feedback extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  state = {
    submitting: false,
    sent: false
  };

  submitEmail = ({ feedbackForm: { feedback } }) => {
    const {
      user: { id }
    } = this.props;

    const { submitting } = this.state;

    if (!feedback || submitting) {
      return;
    }

    this.setState({ submitting: true }, () => {
      apiHelp('feedback/send', {
        id,
        feedback
      })
        .then(() => {
          this.setState({ submitting: false, sent: true }, () => {
            this.redirect = setTimeout(
              () => this.props.history.push('/dashboard'),
              10000
            );
          });
        })
        .catch(catchErrors);
    });
  };

  componentWillUnmount() {
    clearTimeout(this.redirect);
  }

  render() {
    const { sent } = this.state;
    return (
      <div className={`base-card has-${actionButtons.length}-buttons`}>
        <FormProvider
          handleSubmit={this.submitEmail}
          data={{
            feedback: ''
          }}
          schema={schema(sent)}
          actionButtons={actionButtons}
        />
      </div>
    );
  }
}

export default Feedback;
