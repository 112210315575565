import * as yup from 'yup';

import CheckboxGroupConfig from 'components/Form/Model/CheckboxGroupConfig';
import ControlConfig from 'components/Form/Model/ControlConfig';
import SelectConfig from 'components/Form/Model/SelectConfig';
import ListConfig from 'components/Form/Model/ListConfig';

export class ControlTypes {
  static all = [];

  static list() {
    return this.config(new ListConfig());
  }

  static select() {
    return this.config(new SelectConfig());
  }

  static number() {
    return this.config('number', yup.number().default(0));
  }

  static toggle() {
    return this.config('toggle', yup.boolean().default(false));
  }

  static radioGroup() {
    return this.config('radiobutton-group', yup.string().default(''));
  }

  static checkboxGroup() {
    return this.config(new CheckboxGroupConfig());
  }

  static date() {
    return this.config('date', yup.string().default(''));
  }

  static textarea() {
    return this.config('textarea', yup.string().default(''));
  }

  static text() {
    return this.config('text', yup.string().default(''));
  }

  static generic() {
    return this.config('generic', yup.string().default(''));
  }


  static config(name, spec) {
    if (typeof name !== 'string' && name instanceof ControlConfig) {
      this.all.push(name);
      return name;
    }
    const config = new ControlConfig(name, spec);
    this.all.push(config);
    return config;
  }

  static map() {
    const obj = {};
    for (const c of this.all) {
      obj[c.name] = c;
    }
    return obj;
  }
}
