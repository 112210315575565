import React, { Fragment, useContext, useEffect } from 'react';
import classnames from 'classnames';

import { getToRender } from 'components/FormProvider/utils';
import DateInput from 'components/DateInput';
import Input from 'components/Input';
import PasswordWidget from 'components/PasswordWidget';
import FormContext from 'components/FormProvider/FormContext';
import Form from 'components/Form';
import CheckboxGroup from 'components/CheckboxGroup';
import RadioButtonGroup from 'components/RadioButtonGroup';
import CustomSelect from 'components/CustomSelect';
import EmailWidget from 'components/EmailWidget';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import ValidatedInput from 'components/ValidatedInput';
import DateOfBirth from 'components/DOBWidget';
import TimeInput from 'components/TimeInput';
import Info from 'components/Info';
import Loading from 'components/Helpers/Loading';
import { PermBlock } from 'components/PermBlock';
import Births from 'components/Clients/Births';
import Addresses from 'components/Clients/Addresses';
import Links from 'components/Clients/Links';
import ProgramPhases from 'components/Clients/ProgramPhases';
import PhoneMultiInput from 'components/Clients/PhoneInputs/PhoneMultiInput';
import MultiReminderInput from 'components/MultiReminderInput';
import MultiAddressGroup from 'components/MultiAddressGroup';
import SingleAddressGroup from 'components/SingleAddressGroup';
import InputList from 'components/InputList';
import EditLink from 'components/EditLink';
import FormTable from 'components/FormTable';
import PeriodTests from 'components/Settings/TestingProfiles/PeriodTests';
import PercentTests from 'components/Settings/TestingProfiles/PercentTests';
import ProbTests from 'components/Settings/TestingProfiles/ProbTests';
import AddTest from 'components/Settings/TestingProfiles/AddTest';
import CheckInBlock from 'components/CheckInBlock';
import states from 'utils/states.json';
import FormLink from 'components/FormLink';
import Map from 'components/Map';
import LocationSearchInput from 'components/LocationSearchInput';
import BenchmarkRequirementsGroup from 'components/Benchmarks/RequirementsGroup/RequirementsGroup';
import CustomSelectCreatable from 'components/CustomSelectCreatable';
import Uploader from 'components/Uploader';
import SuccessStreaksWidget from 'components/SuccessStreaksWidget';
import { Toggle } from 'components/Toggle';
import { CardBlockBlock, CardBlockContent, CollapsibleSection } from 'components/CardBlock/components';
import { FormStates } from 'components/FormProvider/renderChecks';
import './style.scss';

/**
 * States to feed to select-state-usa select control.
 * @var array<object>
 */
const stateOptions = Object.entries(states).map(pair => ({
  label: pair[0],
  value: pair[1]
}));

export const CardBlock = ({ wrapFieldLabel = false }) => {
  const {
    getCheckboxGroupProps,
    getRadioButtonGroupProps,
    getCustomSelectProps,
    handleSubmitForm,
    onKeyPress,
    handleFormButtons,
    getStateValue,
    getProps,
    getCheckboxProps,
    getDobProps,
    getToggleProps,
    getMapProps,
    getLocationSearchProps,
    getTableProps,
    getTestProps,
    getButtonProps,
    getCheckinBlockProps,
    getLinkProps,
    schema,
    reset,
    formId,
    isReady,
    getBackupValue
  } = useContext(FormContext);

  useEffect(() => {
    if (schema[0]?.children && Array.isArray(schema?.[0]?.children?.[0])) {
      const baseCard = document.querySelector('.base-card');
      if ('object' === typeof baseCard && null !== baseCard) {
        baseCard.classList.add('has-columns');
        baseCard.classList.add(`has-${schema[0].children.length}-columns`);
      }
      if (schema[0]?.fullWidth) {
        baseCard.classList.add('full-width');
      }
    }
  }, []);

  /**
   * Render fields
   *
   * @param {array} fields Form fields to render
   * @param {object} block Information about the group
   */
  const renderFields = (fields, block) =>
    fields.map((child, i) => {
      const key = `schema-elem-${i}`;
      if (getToRender(child.renderChecks, child._blockKey, getStateValue, null, getBackupValue)) {
        const type = 'function' === typeof child.type ? child.type(getStateValue(child.key, child.group)) : child.type;
        let field;
        let fieldProps;
        const getField = () => {
          switch (type) {
            case 'hidden':
              return null;
            case 'filler':
              return <div className="card-content__filler" />;
            case 'select':
              fieldProps = getCustomSelectProps(child, child._blockKey);
              field = <CustomSelect getStateValue={getStateValue} {...fieldProps} />;
              return wrapFieldLabel ? <div className={`field-wrap ${fieldProps.layout ?? ''}`}>{field}</div> : field;
            case 'select-creatable':
              fieldProps = getCustomSelectProps(child, child._blockKey);
              field = <CustomSelectCreatable getStateValue={getStateValue} {...fieldProps} />;
              return wrapFieldLabel ? <div className={`field-wrap ${fieldProps.layout ?? ''}`}>{field}</div> : field;
            case 'select-state-usa':
              field = (
                <CustomSelect
                  getStateValue={getStateValue}
                  {...Object.assign(getCustomSelectProps(child, child._blockKey), { options: stateOptions })}
                />
              );
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'checkbox-group':
              if (child.options && !!child.options.length) {
                field = (
                  <CheckboxGroup getStateValue={getStateValue} {...getCheckboxGroupProps(child, child._blockKey)} />
                );
              } else {
                field = <Checkbox {...getCheckboxProps(child, child._blockKey)} />;
              }
              return wrapFieldLabel ? <div className="field-wrap field-wrap__checkbox-group">{field}</div> : field;
            // eslint-disable-next-line no-fallthrough
            case 'radiobutton-group':
              if (child.options && !!child.options.length) {
                return (
                  <RadioButtonGroup
                    getStateValue={getStateValue}
                    {...getRadioButtonGroupProps(child, child._blockKey)}
                  />
                );
              }
            // eslint-disable-next-line no-fallthrough
            case 'toggle':
              return <Toggle textBefore={child.label} {...getToggleProps(child, child._blockKey)} />;
            case 'checkbox':
              return <Checkbox {...getCheckboxProps(child, child._blockKey)} />;
            case 'password':
              return <PasswordWidget {...getProps(child, child._blockKey)} />;
            case 'email':
              return <EmailWidget {...getProps(child, child._blockKey)} />;
            case 'button':
            case 'submit':
              return (
                <Button
                  handleButtonClick={
                    child.type === 'submit' || child.buttonType === 'submit'
                      ? handleSubmitForm
                      : child.buttonType === 'reset'
                      ? reset
                      : e => {
                          e.preventDefault();
                          handleFormButtons(child.action);
                        }
                  }
                  {...getButtonProps(child)}
                >
                  {child.label}
                </Button>
              );
            case 'list-block':
              return child?.children?.length ? (
                <ul className="list-block">
                  {' '}
                  {child?.children?.map((c, i) => (
                    <li key={i}>{c}</li>
                  ))}{' '}
                </ul>
              ) : null;
            case 'text-block':
              return <p className={classnames('text-block', { bold: child.bold })}> {child.value} </p>;
            case 'info':
              return <Info key={`schema-elem-${i}`} {...getProps(child, child._blockKey)} />;
            case 'simple-save-button':
              return (
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    type="button"
                    disabled={!getStateValue(FormStates.hasChanged)}
                    className="button-extension-of-Save"
                    handleButtonClick={() => {
                      document.querySelector('button[type="submit"]').click();
                    }}
                    primary={true}
                    form={undefined}
                  >
                    {child.label}
                  </Button>
                </div>
              );
            case 'single-row':
              return (
                <div key={key} className={`single-row flex ${child.className ? ` ${child.className}` : ''}`}>
                  {renderFields(child.rowElements, block)}
                </div>
              );
            case 'simple-title':
              if (child.info) {
                return (
                  <div>
                    <h3>{child.label}</h3>
                    <p>{child.info}</p>
                  </div>
                );
              }
              return <h3>{child.label}</h3>;
            case 'h4':
              if (child.info) {
                return (
                  <div>
                    <h4>{child.label}</h4>
                    <p>{child.info}</p>
                  </div>
                );
              }
              return <h4>{child.label}</h4>;
            case 'divider':
              return <div className="divider"></div>;
            case 'date':
              field = <DateInput {...getProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'check-input':
              return <ValidatedInput {...getProps(child, child._blockKey)} />;
            case 'births':
              field = <Births {...getProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'addresses':
              field = <Addresses {...getProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'links':
              field = <Links {...getProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'program-phases':
              field = <ProgramPhases {...getProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'multi-phone':
              field = <PhoneMultiInput {...getProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'multi-reminder':
              field = <MultiReminderInput {...getProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'multi-address-group':
              field = <MultiAddressGroup {...getProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'single-address-group':
              field = <SingleAddressGroup {...getProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'dob':
              field = <DateOfBirth {...getDobProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'time':
              field = <TimeInput {...getProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'perm':
              return <PermBlock {...getCustomSelectProps(child, child._blockKey)} />;
            case 'array-list':
              field = <InputList {...getProps(child, child._blockKey)} />;
              return wrapFieldLabel ? <div className="field-wrap">{field}</div> : field;
            case 'link':
              return <EditLink {...getProps(child, child._blockKey)} />;
            case 'table':
              return <FormTable {...getTableProps(child, child._blockKey)} />;
            case 'periodTests':
              return <PeriodTests {...getTestProps(child)} />;
            case 'probTests':
              return <ProbTests {...getTestProps(child)} />;
            case 'percentTests':
              return <PercentTests {...getTestProps(child)} />;
            case 'addTest':
              return <AddTest />;
            case 'two-column':
              return (
                <div className={`two-column${child.className ? ` ${child.className}` : ''}`}>
                  <div>{renderFields(child.columnA, block)}</div>
                  <div>{renderFields(child.columnB, block)}</div>
                </div>
              );
            case 'input-group':
              return (
                <div className={`input-group${child.className ? ` ${child.className}` : ''}`}>
                  {!child.info && child.label && (
                    <div className="label-container">
                      <label className="base-label bold">{child.label}</label>
                    </div>
                  )}
                  {child.info && (
                    <div className="label-container">
                      <Info label={child.label} value={child.info} />
                    </div>
                  )}
                  {renderFields(child.children, block)}
                </div>
              );
            case 'check_details':
              return <CheckInBlock {...getCheckinBlockProps(child)} />;
            case 'camera-image':
              return <FormLink {...getLinkProps(child, child._blockKey)} />;
            case 'map':
              return <Map {...getMapProps(child, child._blockKey)} />;
            case 'location_search':
              return <LocationSearchInput {...getLocationSearchProps(child, child._blockKey)} />;
            case 'benchmark-requirements':
              return <BenchmarkRequirementsGroup {...getProps(child, child._blockKey)} />;
            case 'streaks-widget':
              return <SuccessStreaksWidget {...getProps(child, child._blockKey)} />;
            case 'document-uploader':
              return <Uploader {...getProps(child, child._blockKey)} />;
            default:
              fieldProps = getProps(child, child._blockKey);
              field = <Input {...fieldProps} getStateValue={getStateValue} />;
              return wrapFieldLabel ? (
                <div className={`field-wrap field-wrap__${fieldProps.type}`}>{field}</div>
              ) : (
                field
              );
          }
        };
        return <Fragment key={key}>{getField()}</Fragment>;
      }
    });

  /**
   * Render columns
   *
   * @param {array} columns List of columns with fields to render
   * @param {object} block Information about the group
   */
  const renderColumns = (columns, block) => {
    return columns.map((column, idx) => {
      return (
        <div key={`fields-col-${idx}`} className="column">
          {renderFields(column, block)}
        </div>
      );
    });
  };

  function schemaMapper(blockSchema, block, b, keyPrefix) {
    if (block.html) {
      return <Fragment key={`block-${keyPrefix}-${b}`}>{block.html()}</Fragment>;
    }
    if (block.type == 'collapsible-section') {
      const blockChildren = block.children;
      return (
        <CollapsibleSection key={`collapsible-section-${keyPrefix}-${b}`}>
          {blockChildren.map((newBlock, index) => schemaMapper(blockChildren, newBlock, index, 'collapsible'))}
        </CollapsibleSection>
      );
    }

    if (!getToRender(block.renderChecks, block.blockGroup, getStateValue)) return null;
    return (
      <CardBlockBlock
        key={`block-${keyPrefix}-${b}`}
        classes={classnames(
          {
            'full-width': block.style === 'full-width'
          },
          block.classList
        )}
      >
        <CardBlockContent
          id={block.anchor ? block.anchor : `block-header-${keyPrefix}-${b}`}
          header={block.header}
          info={block.info}
          toggles={block.toggles}
          open={block.open}
          keyPrefix={keyPrefix}
          index={b}
        >
          {block.children &&
            (Array.isArray(blockSchema[0].children[0])
              ? renderColumns(block.children, block)
              : renderFields(block.children, block))}
        </CardBlockContent>
      </CardBlockBlock>
    );
  }

  const processSchemaForRendering = aSchema => {
    const newSchema = [];
    let collapsibleSection = {};
    for (let i = 0; i < aSchema.length; i++) {
      if (aSchema[i].type == 'collapsible-start') {
        collapsibleSection = {
          type: 'collapsible-section',
          children: []
        };
        continue;
      } else if (aSchema[i].type == 'collapsible-end') {
        newSchema.push(collapsibleSection);
        collapsibleSection = {};
        continue;
      }
      if (collapsibleSection.children) {
        collapsibleSection.children.push(aSchema[i]);
      } else {
        newSchema.push(aSchema[i]);
      }
    }
    return newSchema;
  };

  const schemaToRender = processSchemaForRendering(schema);
  return (
    <>
      {!isReady ? (
        <Loading />
      ) : (
        <Form onSubmit={handleSubmitForm} onKeyPress={onKeyPress} id={formId}>
          {schemaToRender.map((block, b) => schemaMapper(schemaToRender, block, b, 'main'))}
        </Form>
      )}
    </>
  );
};

export default CardBlock;
