import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';
import { IconButton, Tooltip } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Button from 'components/Button';
import Input from 'components/Input';
import Map from 'components/Map';
import LocationSearchInput from 'components/LocationSearchInput';
import './style.scss';

const MultiAddressGroup = ({ handleChange, name, value, ...props }) => {
  const required = !!props.required;

  const handleRemove = index => {
    const newValue = cloneDeep(value);
    newValue.splice(index, 1);
    const e = { target: { name, value: newValue, canUpdate: true } };
    handleChange(e, props.blockGroup);
    value = newValue;
  };

  const handleButtonClick = () => {
    const newValue = cloneDeep(value || []);
    newValue.push({
      radius: 300
    });
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const updateValue = (index, valueHolder) => {
    const newValue = cloneDeep(value).map((p, i) =>
      i === index ? valueHolder : p
    );
    const event = { target: { name, value: newValue, canUpdate: true } };
    handleChange(event, props.blockGroup);
  };

  const handleLenienceChange = (index, lenience, valueHolder) => {
    let lenienceAsNumber = +lenience;
    if (isNaN(lenienceAsNumber) || lenienceAsNumber < 300) {
      lenienceAsNumber = 300;
    }
    valueHolder.radius = lenienceAsNumber;
    updateValue(index, valueHolder);
  };

  const handleLenienceOverlayChange = (index, geometry, valueHolder) => {
    if (geometry.radius) {
      // Convert the radius received in meters to feet
      const lenienceInFeet = Math.round(+geometry.radius / 0.3048);
      // don't call on change unless the value has changed
      if (Math.round(valueHolder.radius) !== lenienceInFeet) {
        handleLenienceChange(index, lenienceInFeet, valueHolder);
      }
    }
  };

  const handleAddressChange = (index, address, valueHolder) => {
    valueHolder.address_text = address;
    // if the address is cleared, delete the coordinate
    if (!address) {
      delete valueHolder.coordinates;
    }
    updateValue(index, valueHolder);
  };

  const handleCoordinatesChange = (index, coordinates, valueHolder) => {
    if (isEqual(valueHolder.coordinates, coordinates)) {
      return;
    }
    valueHolder.coordinates = coordinates;
    updateValue(index, valueHolder);
  };

  const getLocationSearchInput = (index, valueHolder) => {
    return (
      <LocationSearchInput
        key={`location_search_input_${index}`}
        address={valueHolder.address_text}
        required={required}
        onChange={address => {
          handleAddressChange(index, address, valueHolder);
        }}
        error={
          !valueHolder.coordinates
            ? 'Please choose an address from the dropdown.'
            : false
        }
        handleLatLngChange={latLng => {
          handleCoordinatesChange(index, latLng, valueHolder);
        }}
      />
    );
  };

  const getLenienceInput = (index, valueHolder) => {
    const key = `lenience${index}`;
    const fieldProps = {
      key,
      name: key,
      value: isNumber(valueHolder.radius)
        ? Math.round(valueHolder.radius)
        : 300,
      label: `Lenience (in feet)`,
      type: 'number',
      handleChange: event => {
        const {
          target: { value }
        } = event;
        handleLenienceChange(index, value, valueHolder);
      }
    };
    return <Input {...fieldProps} />;
  };

  const getMap = (index, valueHolder) => {
    const key = `address_map_${index}`;
    const mapProps = {
      key,
      name: key,
      draw: 'address',
      editLenience: true,
      isNewLocation: true,
      fullscreenControl: false,
      ...(valueHolder.coordinates && {
        primaryMarker: {
          title: 'Address',
          name: 'Address',
          icon: '/static/img/marker_green.png',
          position: valueHolder.coordinates
        },
        ...(valueHolder.radius && {
          circle: {
            center: valueHolder.coordinates,
            radius: valueHolder.radius * 0.3048
          }
        }),
        center: valueHolder.coordinates
      }),
      onOverlayUpdate: geometry => {
        handleLenienceOverlayChange(index, geometry, valueHolder);
      },
      onCenterUpdate: coordinates => {
        handleCoordinatesChange(index, coordinates, valueHolder);
      }
    };
    return <Map key={`map_${index}`} {...mapProps} />;
  };

  return (
    <div className="multi-address-group-container">
      {!value
        ? []
        : value.map((v, index) => {
          return (
            <div key={index} className="multi-address-group">
              {value.length > 1 && (
                <Tooltip title="Click to remove this address">
                  <span className="multi-address-group__remove-button">
                    <IconButton
                      onClick={() => handleRemove(index)}
                      size="medium"
                      className="group-remove"
                      color="secondary"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {getLocationSearchInput(index, v)}
              {getLenienceInput(index, v)}
              {getMap(index, v)}
            </div>
          );
        })}
      <Button
        className="multi-address-group__add-address"
        secondary
        transparent
        micro
        handleButtonClick={handleButtonClick}
      >
        Add Address
      </Button>
    </div>
  );
};

MultiAddressGroup.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default MultiAddressGroup;
