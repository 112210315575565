import React from 'react';
import PropTypes from 'prop-types';

import { getToRender } from 'components/FormProvider/utils';

import RadioButton from 'components/RadioButton';

import './style.scss';

const RadioButtonGroup = ({ groupLabel, options, handleChange, className, control, getStateValue, disabled }) => {
  return (
    <div className={`base-radiobutton-group ${className ?? ''}`}>
      <fieldset className="radiobutton-row">
        <legend>{groupLabel}</legend>
        <div className="radiobuttons">
          {options.map((o, i) => {
              if (o.renderChecks) {
                if (!getToRender(o.renderChecks, control._blockKey, getStateValue)) {
                  return null;
                } else {
                  delete o.renderChecks;
                }
              }
              return (
                <RadioButton key={`radiobutton-${i}`} {...o} disabled={disabled} handleChange={handleChange} />
              );
            })}
        </div>
      </fieldset>
    </div>
  );
};

RadioButtonGroup.propTypes = {
  groupLabel: PropTypes.string,
  options: PropTypes.array.isRequired,
  blockGroup: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
};

export default RadioButtonGroup;
