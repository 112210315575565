import React, { useContext } from 'react';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';

const SideNavDashboard = () => {
  const { hasAccessTo } = useContext(AppContext);

  if (hasAccessTo('dashboard')) {
    return (
      <NavLink to="/dashboard" title="Dashboard">
        <Icon name="dashboard" size={24} />
        <span className="sidenav__item-label">Dashboard</span>
      </NavLink>
    );
  }
  return null;
};

export default SideNavDashboard;
