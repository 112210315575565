import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import Label from 'components/Label';
import Select from 'react-select';
import Icon from 'components/Icon';
import ModernizedDatePicker from 'components/ModernizedDatePicker';
import Button from 'components/Button';
import { createOptionsFromStringList } from '../ClientsGet/Info/BasicInfo/Schemas/helpers';
import './style.scss';

const EMPTY_BIRTH = {
  date: '',
  drug_free: ''
};

const DRUG_FREE = createOptionsFromStringList('Yes, No, Unknown');

const Births = ({ handleChange, name, value, ...props }) => {
  value = value ? value : [cloneDeep(EMPTY_BIRTH)];

  const handleRemove = index => {
    const newValue = cloneDeep(value).filter((_, i) => i !== index);
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleDateChange = (date, index) => {
    const newObj = value[index];
    newObj.date = date;
    const newValue = cloneDeep(value).map((p, i) => (i === index ? newObj : p));
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleDrugFreeChange = (drug_free, index) => {
    const newObj = value[index];
    newObj.drug_free = drug_free;
    const newValue = cloneDeep(value).map((p, i) => (i === index ? newObj : p));
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleButtonClick = () => {
    const newValue = cloneDeep(value);
    newValue.push(cloneDeep(EMPTY_BIRTH));
    const e = { target: { name, value: newValue } };
    handleChange(e, props.blockGroup);
  };

  return (
    <div className="births-multi-input-container">
      {value.map((v, index) => {
        return (
          <div key={index} className="births-single">
            <div>
              <Label htmlFor={`${name}_date_${index}`} bold>
                Past Birth Date
              </Label>
              <ModernizedDatePicker
                id={`${name}_date_${index}`}
                name={`${name}_date_${index}`}
                dateFormat="M/dd/yyyy"
                selected={v.date}
                onChange={date => handleDateChange(date, index)}
              />
            </div>
            <div>
              <Label htmlFor={`${name}_drug_free_${index}`} bold>
                Drug Free
              </Label>
              <Select
                name={`${name}_drug_free_${index}`}
                className="selector"
                value={DRUG_FREE.filter(o => v.drug_free == o.value)}
                onChange={({ value }) => handleDrugFreeChange(value, index)}
                options={DRUG_FREE}
              />
            </div>
            <div className="remove-box">
              <span onClick={() => handleRemove(index)}>
                <Icon name="minus" />
              </span>
            </div>
          </div>
        );
      })}
      <Button
        secondary
        transparent
        micro
        handleButtonClick={handleButtonClick}
        disabled={value.length ? value.some(v => v.drug_free === '' || v.date === '') : false}
      >
        Add Birth
      </Button>
    </div>
  );
};

Births.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array
};

export default Births;
