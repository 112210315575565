/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import MapContainer from 'components/Map';
import './style.scss';

/**
 * @param {Object} pos shape of { latitude: number, longitude: number }
 * @returns {Node} `MapBlock` component
 */

const MapBlock = ({ pos: { latitude, longitude } }) => {
  const initialCenter = { lat: latitude, lng: longitude };
  const primaryMarker = {
    position: { lat: latitude, lng: longitude },
    clickable: false
  };

  return (
    <div className="map-block">
      <MapContainer
        center={initialCenter}
        containerStyles={{
          height: '345px'
        }}
        parentStyles={{
          width: '100%',
          flex: 1
        }}
        primaryMarker={primaryMarker}
      />
    </div>
  );
};

MapBlock.propTypes = {
  addr: PropTypes.string.isRequired,
  pos: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }).isRequired
};

export default MapBlock;
