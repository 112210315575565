import React, { useCallback, useContext, useMemo } from 'react';
import moment from 'moment-timezone';
import {
  makeStyles,
  IconButton,
  Tooltip,
  LinearProgress,
  Accordion,
  AccordionDetails,
  lighten
} from '@material-ui/core';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import Input from 'components/Input';
import { NotesContext } from 'components/Clients/ClientNotes/NotesContext';
import useNotes from 'hooks/useNotes';
import CardHeader from 'components/CardHeader';
import CsvButton from 'components/CsvButton';
import useToggle from 'components/Curriculum/hooks/useToggle';
import { TableAltButton } from 'components/CardHeader/common';
import TableBuilder, { Column } from 'utils/TableBuilder';
import NoteHeading from 'components/Clients/ClientNotes/NoteHeading';
import HistoryCard from 'components/Clients/ClientNotes/HistoryCard';

const builder = new TableBuilder().addColumns([
  new Column('Author', 'updated_by'),
  new Column('Date', 'updated').isDate(),
  new Column('Note', 'note')
]);

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    borderRadius: 2,
    border: '1px solid #ccc',
    padding: 18,
    opacity: props => (props.isSaving ? 0.5 : 1),
    position: 'relative'
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
  header: {
    fontSize: 14,
    fontWeight: 500
  },
  date: {
    fontSize: 12,
    fontWeight: 300
  },
  button: {
    padding: 8
  },
  input: {
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: 99,
    fieldSizing: 'content',
    '&:disabled': {
      backgroundColor: lighten(theme.palette.action.selected, 0.6)
    }
  },
  loading: {
    height: 1
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18
  },
  card: {
    borderRadius: 2,
    width: '100%',
    border: '1px solid #ccc',
    padding: 18,
    display: 'flex',
    flexDirection: 'column',
    gap: 18
  }
}));

export function Note({ note, index, history = null }) {
  const { startEdit, handleUpdateNote } = useContext(NotesContext);
  const { note: stateNote, setState, handleChange, isSaving, setIsSaving, isEditing, setIsEditing } = useNotes(note);
  const classes = useStyles({ isSaving });
  const [isExpanded, setIsExpanded] = useToggle(false);

  const startEditing = useCallback(
    value => {
      startEdit(value);
      setIsEditing(value);
    },
    [startEdit, setIsEditing]
  );

  const cancelEdit = useCallback(() => {
    startEdit(false);
    setIsEditing(false);
    setState(prev => ({ ...prev, note: note.note }));
  }, [note, startEdit, setIsEditing, setState]);

  const updateNote = useCallback(async () => {
    setIsSaving(true);

    await handleUpdateNote(
      {
        ...stateNote,
        note: stateNote.note,
        date: stateNote?.date ? moment(stateNote.date).format('YYYY-MM-DD H:mm:ss') : null
      },
      index
    );

    setTimeout(() => {
      setIsEditing(false);
      setIsSaving(false);
    }, 1000);
  }, [stateNote, setIsSaving, handleUpdateNote, index]);

  const details = useMemo(() => {
    return history || { ...note, history: note.history ? note.history.map(h => ({ ...h, date: h.updated })) : null };
  }, [note, history]);

  return (
    <div className={classes.container}>
      <CardHeader
        title={<NoteHeading author={details.author} date={details.date} classes={classes} />}
        Separator={
          isSaving ? (
            <LinearProgress variant="indeterminate" color="secondary" classes={{ root: classes.loading }} />
          ) : null
        }
      >
        <Tooltip title={!isEditing ? '' : 'Click to edit this note'}>
          <span>
            <IconButton
              onClick={() => startEditing(true)}
              classes={{ root: classes.button }}
              disabled={isEditing || !!history}
            >
              <EditRoundedIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={
            stateNote.note === details.note ? 'Only notes that have changed may be saved' : 'Click to save this note'
          }
        >
          <span>
            <IconButton
              onClick={updateNote}
              classes={{ root: classes.button }}
              disabled={stateNote.note === details.note || isSaving || !!history}
            >
              <SaveRoundedIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={!isEditing ? '' : 'Click to reset the note and undo any changes'}>
          <span>
            <IconButton onClick={cancelEdit} classes={{ root: classes.button }} disabled={!isEditing || !!history}>
              <CancelRoundedIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </CardHeader>
      <Input
        placeholder="Provide a replacement or updated note here"
        value={stateNote.note}
        handleChange={handleChange('note')}
        autoFocus
        Component="textarea"
        disabled={!isEditing || isSaving || !!history}
        className={classes.input}
      />
      {Array.isArray(details.history) && details.history.length ? (
        <Accordion expanded={isExpanded}>
          <CardHeader title="History" Header="h4" headerStyleProps={{ fontSize: 16 }} showSeparator={false}>
            <CsvButton
              title="notes-history"
              isDownloadDisabled={false}
              csvData={details.history}
              headers={builder.table.csvHeaders}
            />
            <TableAltButton
              isTableView={isExpanded}
              onSwitchClick={setIsExpanded}
              TableIcon={ExpandLessRoundedIcon}
              NonTableIcon={ExpandMoreRoundedIcon}
            />
          </CardHeader>
          <AccordionDetails classes={{ root: classes.details }}>
            {details.history.map((n, i) => (
              <HistoryCard key={`history-${i}`} note={n} classes={classes} />
            ))}
          </AccordionDetails>
        </Accordion>
      ) : null}
    </div>
  );
}

export default Note;
