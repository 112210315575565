import curriculumUtil from 'utils/CurriculumUtil';

const PENDING_OVERRIDE = {
  pending: true,
  doneInitialLoad: false
};

const SUCCESS_OVERRIDE = {
  pending: false,
  doneInitialLoad: true
};

const DEFAULT_STATE = {
  topics: {
    items: [],
    item: {},
    pending: false,
    doneInitialLoad: false
  },
  resources: {
    items: [],
    item: {},
    pending: false,
    doneInitialLoad: false
  },
  packets: {
    items: [],
    item: {},
    pending: false,
    doneInitialLoad: false
  },
  assignments: {
    items: [],
    item: {},
    pending: false,
    doneInitialLoad: false
  },
  isItemSubmitting: false,
  error: null
};

export default function curriculum(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'LOAD_PENDING_CURRICULUM_BULK':
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          ...PENDING_OVERRIDE,
          items: []
        }
      };
    case 'LOAD_SUCCESSFUL_CURRICULUM_BULK':
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          ...SUCCESS_OVERRIDE,
          items: curriculumUtil.mutateStateForType(state, action.payload.type, action.payload.result)
        }
      };
    case 'LOADING_PENDING_CURRICULUM_SINGLE':
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          item: {}
        }
      };
    case 'LOAD_SUCCESSFUL_CURRICULUM_GET':
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          item: curriculumUtil.mutateStateForType(state, action.payload.type, [action.payload.result])[0]
        }
      };
    case 'LOAD_SUCCESSFUL_CURRICULUM_EDIT': {
      const editedItem = curriculumUtil.mutateStateForType(state, action.payload.type, [action.payload.result])[0];

      return {
        ...state,
        isItemSubmitting: false,
        [action.payload.type]: {
          ...state[action.payload.type],
          items: state[action.payload.type].items.map(i => {
            if (+i.id === +action.payload.result.id) {
              return editedItem;
            }

            return i;
          }),
          item: editedItem
        }
      };
    }
    case 'LOAD_SUCCESSFUL_CURRICULUM_ADD':
      return {
        ...state,
        isItemSubmitting: false,
        [action.payload.type]: {
          ...state[action.payload.type],
          items: [
            ...state[action.payload.type].items,
            curriculumUtil.mutateStateForType(state, action.payload.type, [action.payload.result])[0]
          ]
        }
      };
    case 'LOAD_SUCCESSFUL_CURRICULUM_DELETE':
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          items: state[action.payload.type].items.filter(i => +i.id !== +action.payload.id)
        }
      };
    case 'FAILED_REQUEST_CURRICULUM':
      return {
        ...state,
        isItemSubmitting: false,
        [action.payload.type]: {
          ...state[action.payload.type],
          ...SUCCESS_OVERRIDE
        },
        error: action.payload.error
      };
    case 'CLEAR_CURRICULUM_ERROR':
      return {
        ...state,
        isItemSubmitting: false,
        error: null
      };
    case 'CURRICULUM_ITEM_SUBMITTING':
      return {
        ...state,
        isItemSubmitting: true
      };
    case 'UPDATE_USER_SETTINGS': {
      const { key, id } = action.payload;

      const items = [];

      for (const item of state[key].items) {
        if (+item.id === +id) {
          items.push({ ...item, hidden: !item.hidden });
        } else {
          items.push(item);
        }
      }

      return {
        ...state,
        [key]: {
          ...state[key],
          items: items
        }
      };
    }
    default:
      return state;
  }
}
