import * as yup from 'yup';

import ControlConfig from 'components/Form/Model/ControlConfig';

class ListConfig extends ControlConfig {
  constructor() {
    super('list', yup.array().ensure());
  }

  getDefaultValue(control, stateValue) {
    const values = super.getDefaultValue(control, stateValue)
    control.setValues(values)
    return values;
  }
}

export default ListConfig;