/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import './style.scss';

const InfoBlockRow = ({ title, value, titleIsHtml=false }) => {
  const [correctValue, setCorrectValue] = useState('');

  useEffect(() => {
    if ('function' === typeof value) {
      setCorrectValue(value());
    } else if (value && 'function' === typeof value.then) {
      value.then(newValue => {
        setCorrectValue(newValue);
      });
    } else {
      setCorrectValue(value);
    }
  }, [value]);

  return (
    <div className="info-block-row">
      {!titleIsHtml &&<div className="info-block-row__title">{title}</div>}
      {titleIsHtml && <div className="info-block-row__title" dangerouslySetInnerHTML={{ __html: title }}></div>}
      {correctValue && (
        <div className="info-block-row__value">{correctValue}</div>
      )}
    </div>
  );
};

InfoBlockRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any
};

export default InfoBlockRow;
