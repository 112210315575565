/**
 * External dependencies
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
/**
 * Internal dependencies
 */
import Label from 'components/Label';
import './style.scss';

const RadioButton = ({
  handleChange,
  checked,
  disabled,
  name,
  value,
  blockGroup = false,
  group,
  label,
  numberOverride,
  numberOverrideInitialValue,
  control = null
}) => {
  const [enteredText, setEnteredText] = useState('');

  useEffect(() => {
    if (!checked && numberOverride) {
      setEnteredText('');
    } else if (checked && numberOverride) {
      // check if we have a value
      setEnteredText(numberOverrideInitialValue);
    }
  }, [checked]);

  const handleNumberOverrideChange = event => {
    setEnteredText(event.target.value);
    handleChange(event.target.value, 'numberOverride', blockGroup, group, control);
  };

  return (
    <div
      className={classnames('radio-container', {
        disabled: disabled,
        'with-number-field': numberOverride
      })}
      onClick={() => handleChange(value, name, blockGroup, group)}
    >
      <input
        className="radio-hidden"
        type="radio"
        onClick={() => handleChange(value, name, blockGroup, group, control)}
        onChange={() => handleChange(value, name, blockGroup, group, control)}
        checked={checked}
        disabled={disabled}
        aria-checked={checked}
        aria-disabled={disabled}
        value={value}
      />
      <span className="radio-visible" />
      {numberOverride ? (
        <input
          className="radio-number-input"
          type="number"
          onChange={event => handleNumberOverrideChange(event)}
          value={enteredText}
          disabled={!checked}
          min="1"
        />
      ) : null}
      {label ? <Label bold>{label}</Label> : null}
    </div>
  );
};

RadioButton.propTypes = {
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  group: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  blockGroup: PropTypes.string,
  handleChange: PropTypes.func
};

RadioButton.defaultProps = {
  name: '',
  label: '',
  disabled: false
};

export default RadioButton;
