import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import Input from 'components/Input';
import Button from 'components/Button';
import getDrawerProps from 'selectors/getDrawerProps';

const NotesForm = ({ handleChange, notes, drawerOpen, isSaveDisabled, handleButtonClick }) => {
  const { editClientNotes, result_data, hideResultsAndNotes = false } = useSelector(getDrawerProps);

  if (!!result_data && !hideResultsAndNotes) {
    return (
      <>
        <div className="vc-container__form">
          <h3 className="header-text">Notes</h3>
          <Input
            Component="textarea"
            disabled={!editClientNotes}
            handleChange={handleChange}
            value={notes}
            name="notes"
            rows={2}
          />
        </div>
        <div
          className={classnames('vc-container__action-buttons', {
            'drawer-open': drawerOpen
          })}
        >
          <Button disabled={isSaveDisabled} handleButtonClick={handleButtonClick} primary>
            Save
          </Button>
        </div>
      </>
    );
  }

  return null;
};

export default NotesForm;
