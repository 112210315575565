import React, { useContext, useMemo } from 'react';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';
import SideNavSub from './SideNavSub';
import Badge from 'components/Badge';

const SideNavMessages = ({ pathname }) => {
  const {
    hasAccessTo,
    facility,
    user: { unread_messages }
  } = useContext(AppContext);

  const active = useMemo(() => {
    return facility?.check_permissions?.app?.active ?? false;
  }, [facility.check_permissions]);

  const NestedMenu = useMemo(() => {
    if (pathname.startsWith('/communications')) {
      return (
        <SideNavSub
          title="Communications"
          links={[
            {
              to: '/communications/inbox',
              label: 'Inbox',
              access: active && hasAccessTo('send_app_messages')
            },
            {
              label: 'Announcements',
              to: `/communications/announcements`,
              access: hasAccessTo('send_announcements')
            },
            {
              label: (
                <>
                  Bulk Message
                  <Icon name="chevron-right" size="10" />
                </>
              ),
              to: '/communications/bulk/send',
              access: active && hasAccessTo('send_app_messages') && hasAccessTo('can_send_bulk_message'),
              className: pathname.includes('/communications/bulk') ? 'sidenavsub__open' : '',
              foldMenu: pathname.includes('/communications/bulk/') && (
                <ul className="sidenavsub__fold">
                  <NavLink
                    to="/communications/bulk/log"
                    activeClassName={
                      '/communications/bulk/log' === pathname ? 'sidenavsub__link sidenavsub__link--active' : ''
                    }
                  >
                    Log
                  </NavLink>
                </ul>
              )
            }
          ]}
        />
      );
    }

    return null;
  }, [pathname, active, hasAccessTo]);

  if (!!active && (hasAccessTo('send_app_messages') || hasAccessTo('send_announcements'))) {
    return (
      <NavLink
        to={hasAccessTo('send_app_messages') ? '/communications/inbox' : '/communications/announcements'}
        title="Communications"
        className={hasAccessTo('send_app_messages') ? 'sidenav__item--has-badge' : ''}
        nestedMenu={NestedMenu}
      >
        <Icon name="messages" size={24} />
        <span className="sidenav__item-label">Communications</span>
        <Badge className="sidenav__badge" number={unread_messages} />
      </NavLink>
    );
  }

  return null;
};

export default SideNavMessages;
