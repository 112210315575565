import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';

import curriculumUtil from 'utils/CurriculumUtil';
import useToggle from '../hooks/useToggle';
import IconButton from 'components/IconButton';

const useStyles = makeStyles(theme => ({
  cellPills: {
    display: 'flex',
    gap: 4,
    flexWrap: 'wrap'
  },
  pill: {
    maxWidth: '100%',
    backgroundColor: theme.palette.action.selected,
    borderRadius: 1,
    display: 'flex',
    padding: '4px 6px',
    minWidth: 0,
    boxSizing: 'border-box',
    whiteSpace: 'nowrap'
  },
  limit: {
    padding: '4px 6px',
    minWidth: 0,
    maxWidth: '100%'
  },
  span: {
    width: '100%'
  }
}));

const CellList = ({ items = [], type = 'general', style = {}, options = {} }) => {
  const [isExpanded, setIsExpanded] = useToggle(false);
  const classes = useStyles();

  const [shown, collapsible] = useMemo(() => {
    if (!options?.limit || isExpanded) return [items, []];

    const limit = options.limit;

    return [items.slice(0, limit), items.slice(limit)];
  }, [items, options, isExpanded]);

  const minWidth = useMemo(() => {
    if (items?.length) {
      return curriculumUtil.getLongestWord(items, 16);
    }

    return undefined;
  }, [items]);

  const expandText = useMemo(() => {
    if (options.limit) {
      const difference = items.length - options.limit;

      if (isExpanded) return `Hide ${difference}`;

      return `Show ${difference} more`;
    }

    return '';
  }, [items.length, options.limit, isExpanded]);

  if (shown?.length) {
    return (
      <div className={classes.cellPills} style={{ minWidth: minWidth }}>
        {shown.map((item, index) => (
          <div key={`cell-list-${type}-${index}`} className={classes.pill} style={style}>
            {item}
          </div>
        ))}
        {isExpanded
          ? collapsible.map((item, index) => (
              <div key={`cell-list-collapsible-${type}-${index}`} className={classes.pill} style={{ style }}>
                {item}
              </div>
            ))
          : null}
        {options.limit && items.length > options.limit ? (
          <IconButton
            label={expandText}
            size="small"
            onClick={setIsExpanded}
            Icon={isExpanded ? RemoveCircleOutlineRoundedIcon : AddCircleOutlineRoundedIcon}
            styleProps={{ backgroundColor: '#fff', color: 'inherit', border: '1px solid #ccc' }}
            iconButtonProps={{ className: classes.limit }}
            spanClass={classes.span}
          />
        ) : null}
      </div>
    );
  }

  return '-';
};

export default CellList;
