const formatBadTimes = function(time) {
  if (time.length === 3) {
    time = time + '00';
  } else if (time.length === 1) {
    time = '0' + time + ':00';
  } else if (time.length === 4) {
    if (time.indexOf(':') === 2) {
      time = time + '0';
    } else if (time.indexOf(':') === 1) {
      time = '0' + time;
    }
  }
  // make sure our first 2 digits aren't 00, and if so, change them to 24 so we can parse
  if (time.indexOf('00:') === 0) {
    time = '24' + time.slice(2, 5);
  }

  return time;
};

export default formatBadTimes;
