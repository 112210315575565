export default function communityEvent(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_ADD_COMMUNITY_EVENT':
      return {
        ...state,
        isAddingCommunityEvent: true
      };
    case 'LOAD_SUCCESSFUL_ADD_COMMUNITY_EVENT':
      return {
        ...state,
        isAddingCommunityEvent: false
      };
    case 'LOAD_PENDING_UPDATE_COMMUNITY_EVENT':
      return {
        ...state,
        isUpdatingCommunityEvent: true
      };
    case 'LOAD_SUCCESSFUL_UPDATE_COMMUNITY_EVENT':
      return {
        ...state,
        isUpdatingCommunityEvent: false
      };
    case 'LOAD_PENDING_DELETE_COMMUNITY_EVENT':
      return {
        ...state,
        isDeletingCommunityEvent: true
      };
    case 'LOAD_SUCCESSFUL_DELETE_COMMUNITY_EVENT':
      return {
        ...state,
        isDeletingCommunityEvent: false
      };
    case 'LOAD_PENDING_GET_COMMUNITY_EVENT':
      return {
        ...state,
        isLoadingCommunityEvent: true
      };
    case 'LOAD_SUCCESSFUL_GET_COMMUNITY_EVENT':
      return {
        ...state,
        isLoadingCommunityEvent: false
      };
  }
  return state;
}
