import React, { Component } from 'react';
import moment from 'moment-timezone';
import classnames from 'classnames';

import ErrorOrConfirm from 'components/ErrorOrConfirm';
import Input from 'components/Input';
import Button from 'components/Button';

class DrawerNotesOrNotifications extends Component {
  state = {
    notes: ''
  };

  handleInputChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value });

  handleSend = () => {
    const { notes } = this.state;
    this.props.handleUpdateViolation(false, notes);
    this.setState({
      notes: ''
    });
  };

  getNoteStatus = note => {
    return (
      <span className="note-created">
        {moment(note.created).isValid() ? (
          note.status === 'delivered' ? (
            `Delivered on ${moment(note.created, 'YYYY-MM-DD H:mm:ss').format(
              'MMM Do, YYYY, h:mm A'
            )}`
          ) : note.status === 'cancelled' ? (
            `Notification delivery cancelled. Sent on ${moment(
              note.created,
              'YYYY-MM-DD H:mm:ss'
            ).format('MMM Do, YYYY, h:mm A')}`
          ) : note.status !== 'failed' ? (
            <span>
              Notification delivery {note.status}&nbsp;
              {moment(note.created, 'YYYY-MM-DD H:mm:ss').format(
                'MMM Do, YYYY, h:mm A'
              )}
            </span>
          ) : (
            <span className="alert">Delivery failed (app not connected)</span>
          )
        ) : null}
      </span>
    );
  };

  render() {
    const { notes, errors, title, showForm, type, noBottom } = this.props;

    return (
      <div className="vc-container__form">
        <h3 className="header-text">{title}</h3>
        {!!showForm && (
          <div className="notes-form">
            <p className="note-hint">
              Notes are only visible to you and other facility staff.
            </p>
            <Input
              Component="textarea"
              className="new-note"
              name="notes"
              handleChange={this.handleInputChange}
              value={this.state.notes}
              id="notes-text-area"
            />
            <Button
              secondary
              micro
              transparent
              handleButtonClick={this.handleSend}
            >
              Save
            </Button>
          </div>
        )}
        <>
          <div
            className={classnames('notes-history', {
              'no-bottom': !!noBottom
            })}
          >
            {notes.length ? (
              notes.map((note, i) => {
                const noteTimeOrStatus = note.status ? (
                  this.getNoteStatus(note)
                ) : (
                  <>
                    <div className="note-header">
                      <div className="note-user">{note.user}</div>
                      <div className="note-created">
                        {moment(note.created, 'YYYY-MM-DD H:mm:ss').format(
                          'MMM Do, YYYY, h:mm A'
                        )}
                      </div>
                    </div>
                  </>
                );
                return (
                  <>
                    <div className="single-note" key={`note-${i}`}>
                      {noteTimeOrStatus}
                      <div className="note-note">{note.note}</div>
                    </div>
                  </>
                );
              })
            ) : (
              <p className="single-note empty">
                There are currently no{' '}
                {type === 'violation'
                  ? 'notes'
                  : type === 'check in'
                    ? 'notifications'
                    : 'records'}{' '}
                for this {type === 'violation' ? 'alert' : type}.
              </p>
            )}
          </div>
          <ErrorOrConfirm messages={errors} type="error" />
        </>
      </div>
    );
  }
}

export default DrawerNotesOrNotifications;
