/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import Icon from 'components/Icon';
import './style.scss';

const TableHeader = ({ children, handleSort, ...props }) => {
  const toggleSort = () => {
    if (props.unsortable) return;

    if (!!handleSort && 'function' === typeof handleSort) {
      const {
        sort: { type, name },
        headerInfo: { sortCol }
      } = props;

      const newSort = {
        name: sortCol,
        type: 'asc'
      };

      if (name === sortCol) {
        newSort.type = type === 'asc' ? 'desc' : 'asc';
      }

      handleSort(newSort);
    }
  };

  const active = props.sort && props.headerInfo && props.sort.name === props.headerInfo.sortCol;
  const doesSort = !!props.headerInfo && !props.headerInfo.unsortable;

  return (
    <th className={`base-th${props.className ? ` ${props.className}` : ''}`}>
      <div onClick={toggleSort} className={`base-th__content-wrapper ${doesSort ? 'action-sort' : ''}`}>
        <span>{children}</span>
        {doesSort && (
          <div className="sort-icons">
            <Icon
              name="triangle-bottom"
              className={`top${!!active && props?.sort?.type === 'desc' ? ' active' : ''}`}
            />
            <Icon
              name="triangle-bottom"
              className={`bottom${!!active && props?.sort?.type === 'asc' ? ' active' : ''}`}
            />
          </div>
        )}
      </div>
      {props?.extra ?? null}
    </th>
  );
};

export default TableHeader;
