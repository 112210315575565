import React from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';

const AssigneesHeader = ({ handleButtonClick, isDisabled }) => {
  return (
    <>
      <div className="divider" />
      <p className="completions__container--item__status-header">
        <strong>Assignee&nbsp;Statuses</strong>
        <Button
          micro
          secondary
          handleButtonClick={handleButtonClick}
          disabled={isDisabled}
          tooltip={{ title: isDisabled ? '' : 'Reset assignees list' }}
        >
          <Icon name="replay" size={18} />
        </Button>
      </p>
    </>
  );
};

export default AssigneesHeader;
