import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { apiHelp } from 'utils/apiHelpers';
import { useToast } from 'components/Toast';
import useToggle from 'components/Curriculum/hooks/useToggle';
import BaselineUtil, { Endpoints } from 'utils/BaselineUtil';

const useBaselineImages = (client_id, getCallback) => {
  const [state, setState] = useState(BaselineUtil.DEFAULT_STATE);
  const [isModalOpen, toggleModal] = useToggle(false);
  const [isSingleModalOpen, toggleIsSingleModalOpen] = useToggle(false);
  const [imageRefs, setImageRefs] = useState({});
  const params = useParams();
  const { addToast } = useToast();

  const handleError = useCallback(
    (content = 'We encountered an error. Please, try again later.') => {
      addToast({ position: 'top', content, duration: 3000 });
    },
    [addToast]
  );

  const handleResult = useCallback(
    async (endPoint, data) => {
      try {
        const result = await apiHelp(endPoint, data);

        if (result.data.status === 'success') {
          setState(prev => ({
            ...prev,
            ...BaselineUtil.getState(result?.data?.baseline_images, result?.data?.collection_active)
          }));
        } else {
          handleError();
        }
      } catch (e) {
        handleError();
      } finally {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    },
    [handleError]
  );

  const closeSingleClearModal = useCallback(() => {
    setState(prev => ({ ...prev, pendingFaceId: null }));
    toggleIsSingleModalOpen();
  }, [toggleIsSingleModalOpen]);

  const getImages = useCallback(async () => {
    await handleResult(Endpoints.GET, { client_id: client_id || params.id });
  }, [handleResult, client_id, params.id]);

  const updateCollection = useCallback(
    active => async () => {
      setState(prev => ({ ...prev, isLoading: true }));
      await handleResult(Endpoints.UPDATE, { client_id: client_id || params.id, active: active === true });

      if ('function' === typeof getCallback) {
        await getCallback();
      }

      if (isModalOpen) {
        toggleModal();
      }
    },
    [handleResult, toggleModal, client_id, params.id, isModalOpen, toggleModal, getCallback]
  );

  const removeSingleImage = useCallback(async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    await handleResult(Endpoints.CLEAR_SINGLE, { client_id: client_id || params.id, face_id: state.pendingFaceId });

    if ('function' === typeof getCallback) {
      await getCallback();
    }
    closeSingleClearModal();
  }, [state.pendingFaceId, client_id, params.id, handleResult, closeSingleClearModal, getCallback]);

  const handleSingleClearModal = useCallback(
    face_id => () => {
      setState(prev => ({ ...prev, pendingFaceId: face_id }));
      toggleIsSingleModalOpen();
    },
    [toggleIsSingleModalOpen]
  );

  const setImageRef = useCallback(
    img_url => ref => {
      if (!(img_url in imageRefs)) {
        setImageRefs(prev => ({ ...prev, [img_url]: ref }));
      }
    },
    [imageRefs]
  );

  const handleLoadImage = useCallback(
    img_url => () => {
      setState(prev => ({ ...prev, loadedImages: { ...prev.loadedImages, [img_url]: true } }));

      if (img_url in imageRefs && 'function' === typeof imageRefs?.[img_url]?.getBoundingClientRect) {
        const { width, height } = imageRefs[img_url].getBoundingClientRect();

        if (width > height) {
          setState(prev => ({ ...prev, shouldRotate: { ...prev.shouldRotate, [img_url]: true } }));
        }
      }
    },
    [imageRefs, state.shouldRotate, state.loadedImages]
  );

  const isApprovedDisabled = useMemo(() => !!(state.active && state.canApprove) || !state.canApprove, [state]);

  useEffect(() => {
    getImages();
  }, []);

  return {
    state,
    imageRefs,
    setImageRef,
    getImages,
    updateCollection,
    handleSingleClearModal,
    closeSingleClearModal,
    removeSingleImage,
    handleLoadImage,
    isModalOpen,
    toggleModal,
    isSingleModalOpen,
    toggleIsSingleModalOpen,
    isApprovedDisabled
  };
};

export default useBaselineImages;
