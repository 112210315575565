import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';

import Button from 'components/Button';
import Icon from 'components/Icon';
import ValidatedInput from 'components/ValidatedInput';

import './style.scss';

const PhoneMultiInput = ({ handleChange, label, name, value, ...props }) => {
  const handleRemove = index => {
    const newValue = cloneDeep(value);
    newValue.splice(index, 1);

    if (!newValue.length) {
      newValue.push({
        primary: 0,
        active: 1,
        phone_number: ''
      });
    }
    const e = { target: { name, value: newValue } };
    handleChange(e, props.blockGroup);
  };

  const handleChangeInput = ({
    target: {
      dataset: { value: index },
      value: targetValue
    }
  }) => {
    const idx = parseInt(index, 10);
    const newNum = {
      primary: !!targetValue && idx === 0 ? 1 : 0,
      active: 1,
      phone_number: targetValue
    };
    const newValue = cloneDeep(value).map((p, i) => (i === idx ? newNum : p));
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleButtonClick = () => {
    const newValue = cloneDeep(value);
    const phone_number = {
      primary: 0,
      active: 1,
      phone_number: ''
    };
    newValue.push(phone_number);
    const e = { target: { name, value: newValue } };
    handleChange(e, props.blockGroup);
  };

  const isValidNumber = number => {
    const phone = number ? number.replace(/\D/g, '') : '';
    return !!phone && !!phone.length && phone.length === 10 && /^\d+$/.test(phone);
  };

  return (
    <div className="phone-multi-input-container">
      {value.map((v, index) => {
        const isValid = isValidNumber(v.phone_number);
        return (
          <div key={index} className="phone-input-single">
            <div
              className={classnames('phone-input-single__input-with-icon', {
                'no-icon': !isValid || index === 0 // work on rendering logic here
              })}
            >
              <ValidatedInput
                label={index === 0 ? label : `Alternate Phone ${index}`}
                handleChange={handleChangeInput}
                key={`${name}_${index}`}
                name={name}
                value={v.phone_number}
                data-value={index}
                subType="phone"
                maxLength={14}
                setErrorsCallback={props.setErrorsCallback}
                blockGroup={props.blockGroup}
                // to allow for the primary phone to be removed, we need to be able to disable validation
                validate={(index !== 0 || (index === 0 && v.phone_number !== '')).toString()}
              />
              {!!isValid && index !== 0 && (
                <span onClick={() => handleRemove(index)}>
                  <Icon name="minus" />
                </span>
              )}
            </div>
          </div>
        );
      })}
      <Button
        secondary
        transparent
        micro
        handleButtonClick={handleButtonClick}
        disabled={value.length ? value.some(v => !isValidNumber(v.phone_number)) : true}
      >
        Add New Phone Number
      </Button>
    </div>
  );
};

PhoneMultiInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array
};

export default PhoneMultiInput;
