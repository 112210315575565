/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Label from 'components/Label';
import CustomSelectCreatable from 'components/CustomSelectCreatable';
import { STREAK_TITLE_OPTIONS as streakTitles } from 'utils/Constants';
import { FormControlChange, useFieldProps } from 'components/Form/FieldWrap';
import './style.scss';

const SuccessStreaksWidget = ({control}) => {
  const [formContext, props] = useFieldProps(control)
  const {
    name,
    label,
    value,
    error,
    handleCustomCreate = () => {},
    setErrors,
  } = props
  const [streaks, setStreaks] = useState(value || []);

  function saveToForm(newStreaks) {
    formContext.handleInputChange(new FormControlChange(control, newStreaks))
  }

  const canAddNewStreak = () => {
    return !streaks.filter(s => !s.title).length;
  };

  const getReadableDate = date => {
    const PENDING_DATE = 'Pending client entry';
    let newDate = date;
    newDate ??= PENDING_DATE;
    try {
      newDate = new Date(newDate);
      if (newDate.toString() === 'Invalid Date') {
        throw Error('Invalid Date ' + date);
      }
      newDate = newDate.toLocaleDateString('en', { dateStyle: 'medium' });
    } catch (error) {
      newDate = PENDING_DATE;
    }
    return newDate;
  };

  const handleNewStreak = () => {
    setStreaks([...streaks, { id: -1, title: '', active: 0, start_date: null }]);
  };

  const handleRemoveStreak = index => {
    const updatedStreaks = [...streaks];
    const streak = updatedStreaks[index];
    streak.removed_at = Date.now();
    saveToForm(updatedStreaks);
  };

  const handleTitleChange = (index, event) => {
    const { value } = event?.target;
    const updatedStreaks = [...streaks];
    updatedStreaks[index].title = value;
    saveToForm(updatedStreaks);
  };

  return (
    <div className="input-container streaks-container">
      <div>
        <Label htmlFor={name} bold>
          {label}
        </Label>
        {!!error && (
          <Label htmlFor={name} alert>
            {error}
          </Label>
        )}
      </div>
      <p className="streaks-desc">
        {`Count consecutive days of success, such as days of sobriety and days in treatment. This will show in the client's application as "[Title] for X days!" For example, it would show "Substance-free for 52 days!" or "Opioid-free for 23 days!"`}
      </p>
      <div>
        {streaks.map((streak, index) => {
          return (
            <div key={index} className={'streak-row ' + (streak.removed_at ? 'streak-removed' : '')}>
              <CustomSelectCreatable
                key={index}
                className="streak-title"
                name={streak.id}
                value={streak.title ?? ''}
                handleCustomSelectChange={event => handleTitleChange(index, event)}
                createOption={handleCustomCreate}
                options={Object.values(streakTitles)}
                setErrorsCallback={setErrors}
                required={true}
                multiple={false}
              />
              <div className="streak-details">
                <p>since</p>
                <strong className="streak-date">{getReadableDate(streak.start_date)}</strong>
              </div>
              <span className="remove-streak" onClick={() => handleRemoveStreak(index)}>
                <Icon name="minus" size={18} />
              </span>
            </div>
          );
        })}
        <Button handleButtonClick={handleNewStreak} disabled={!canAddNewStreak()} secondary transparent micro>
          Add Streak
        </Button>
      </div>
    </div>
  );
};

SuccessStreaksWidget.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  activeStreaks: PropTypes.array,
  blockGroup: PropTypes.string,
  handleCustomCreate: PropTypes.func,
  handleUpdateTitle: PropTypes.func,
  setErrors: PropTypes.func
};

export default SuccessStreaksWidget;
