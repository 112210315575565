/**
 * Internal dependencies
 */
import ViolationsDrawer from './Violations/ViolationsDrawer';
import CheckInHistoryDrawer from './CheckInHistory';
import WindowReportDrawer from './WindowReportDrawer';
import LocationsDrawer from './LocationsDrawer';
import DayNotesDrawer from './DayNotesDrawer';
import DevicePermissionsDrawer from './DevicePermissionsDrawer';
import CheckInDrawer from './CheckIn';
import BulkMessageDrawer from './BulkMessageDrawer';
import FormSubmissionDrawer from './FormSubmissionDrawer';
import SubscriptionDrawer from './SubscriptionDrawer';
import TodoClientEventDrawer from './TodoClientEventDrawer';
import TodoClientInitiatedFormDrawer from './TodoClientInitiatedFormDrawer';
import TodoHomeAddressDrawer from './TodoHomeAddressDrawer';
import TodoFormResponseChanged from './TodoFormResponseChanged';
import TodoBaselineImageReview from './TodoBaselineImageReview';
import EventInfoDrawer from './EventInfoDrawer';
import SelfPayWidgetDrawer from './SelfPayWidgetDrawer';
import ManuallyCompletedDrawer from './ManuallyCompletedDrawer';
import TopicDrawer from 'components/Curriculum/Topic/TopicDrawer';
import ResourceDrawer from 'components/Curriculum/Resource/ResourceDrawer';
import PacketDrawer from 'components/Curriculum/Packet/PacketDrawer';
import AssignmentDrawer from 'components/Curriculum/Assignment/AssignmentDrawer';
import IndividualMeetingDrawer from './IndividualMeetingDrawer';
import DrawerTypes from './types';
/**
 * @description Helper that returns a renderable node based on the string type passed in.
 * Used in `components/Drawer/index.js`
 * @param {String} type the type of component to return
 * @returns {Node} `ComponentToRender`
 */

const getDrawerType = type => {
  switch (type) {
    case DrawerTypes.VIOLATIONS:
      return ViolationsDrawer;
    case DrawerTypes.CLIENT_EVENT:
      return CheckInHistoryDrawer;
    case DrawerTypes.WINDOWS:
      return WindowReportDrawer;
    case DrawerTypes.LOCATIONS:
      return LocationsDrawer;
    case DrawerTypes.DAYNOTES:
      return DayNotesDrawer;
    case DrawerTypes.CHECKIN:
      return CheckInDrawer;
    case DrawerTypes.BULK_MESSAGE:
      return BulkMessageDrawer;
    case DrawerTypes.FORM_SUBMISSION:
      return FormSubmissionDrawer;
    case DrawerTypes.DEVICE_PERMISSIONS:
      return DevicePermissionsDrawer;
    case DrawerTypes.SUBSCRIPTION:
      return SubscriptionDrawer;
    case DrawerTypes.TODO_CLIENT_EVENT:
      return TodoClientEventDrawer;
    case DrawerTypes.CLIENT_INITIATED_FORM:
      return TodoClientInitiatedFormDrawer;
    case DrawerTypes.TODO_HOME_ADDRESS:
      return TodoHomeAddressDrawer;
    case DrawerTypes.TODO_FORM_RESPONSE_CHANGED:
      return TodoFormResponseChanged;
    case DrawerTypes.TODO_BASELINE_IMAGE_REVIEW:
      return TodoBaselineImageReview;
    case DrawerTypes.EVENT_INFO:
      return EventInfoDrawer;
    case DrawerTypes.SELF_PAY_WIDGET:
      return SelfPayWidgetDrawer;
    case DrawerTypes.MANUALLY_COMPLETED_REQUIREMENT:
      return ManuallyCompletedDrawer;
    case DrawerTypes.TOPIC:
      return TopicDrawer;
    case DrawerTypes.RESOURCE:
      return ResourceDrawer;
    case DrawerTypes.PACKET:
      return PacketDrawer;
    case DrawerTypes.ASSIGNMENT:
      return AssignmentDrawer;
    case DrawerTypes.INDIVIDUAL_MEETINGS:
      return IndividualMeetingDrawer;
    default:
      return null;
  }
};

export { getDrawerType };
