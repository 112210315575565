import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import TableChartRoundedIcon from '@material-ui/icons/TableChartRounded';
import EqualizerRoundedIcon from '@material-ui/icons/EqualizerRounded';
import Tooltip from '@material-ui/core/Tooltip';

export const TableAltButton = ({
  tooltip = '',
  disabled,
  isTableView,
  onSwitchClick,
  TableIcon = EqualizerRoundedIcon,
  NonTableIcon = TableChartRoundedIcon
}) => {
  if ('function' !== typeof onSwitchClick) return null;

  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton
          onClick={onSwitchClick}
          disabled={disabled}
          aria-label={isTableView ? 'table-view' : 'chart-view'}
          size="medium"
        >
          {isTableView ? <TableIcon /> : <NonTableIcon />}
        </IconButton>
      </span>
    </Tooltip>
  );
};
