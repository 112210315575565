import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clearCurriculumError } from 'actions/curriculumActions';
import { useToast } from 'components/Toast';
import { getCurriculumError } from 'selectors/curriculumSelectors';

const useCurriculumError = () => {
  const error = useSelector(getCurriculumError);
  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    toast.setCallbackRef(() => dispatch(clearCurriculumError()));

    return () => {
      toast.resetCallbackRef();
    };
  }, [toast.setCallbackRef, toast.resetCallbackRef]);

  useEffect(() => {
    if (error) {
      toast.addToast({
        position: 'top',
        duration: 3000,
        type: 'warning',
        content: error
      });
    }
  }, [error]);
};

export default useCurriculumError;
