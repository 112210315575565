/**
 * External dependencies
 */
import get from 'lodash/get';

/**
 * Check if the geographical states are being requested.
 *
 * @param {object} state App state tree.
 *
 * @returns {bool} True if a network request is currrently undergoing. False otherwise.
 */
const isRequestingStates = state =>
  get(state, 'locations.isRequestingStates', false);

export default isRequestingStates;
