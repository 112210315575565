/**
 * External dependencies
 */
import get from 'lodash/get';

/**
 * Check if Drawer is open.
 *
 * @param {object} state App state tree.
 *
 * @returns {boolean} True if Drawer is open, false otherwise. It's false by default, because if there's no data here something went wrong initializing the app and drawer won't open.
 */
const isDrawerOpen = state => get(state.drawer, 'drawerOpen', false);

export default isDrawerOpen;
