import T from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

class Partner extends Component {
  static propTypes = {
    params: T.shape({
      splat: T.string,
      jwt: T.string.isRequired
    })
  };

  componentDidMount() {
    Cookies.set('id_token', this.props.match.params.jwt);
    Cookies.set('iframed', '1');
    document.body.classList.add('iframed');

    if (this.props.match.params.splat) {
      this.props.history.replace('/' + this.props.match.params.splat);
    } else {
      this.props.history.replace('/dashboard');
    }
  }

  render() {
    return <div />;
  }
}

export default withRouter(Partner);
