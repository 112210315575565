import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { closeDrawer } from 'actions/actionCreators';
import { jwtInstance } from 'utils/apiHelpers';
import { useToast } from 'components/Toast';
import InfoBlockRow from '../InfoBlockRow.js';
import ActionButtons from './ActionButtons';
import Loading from 'components/Helpers/Loading';
import './style.scss';
import Input from 'components/Input';
import { short_date_format } from '../../../lib/util';

function ManuallyCompletedDrawer({ drawer, closeDrawer }) {
  const {
    drawerOpen,
    drawerProps: { todo: manualCompletion, benchmarkId, clientId, onClose, edit, getCallBack }
  } = drawer;
  const [isLoading, setIsLoading] = useState(true);
  const [responses, setResponses] = useState([]);
  const [notes, setNotes] = useState('');
  const toast = useToast();
  useEffect(() => {
    if (edit || edit == null) {
      setIsLoading(false);
      return;
    }
    let responseBlocks = [];
    responseBlocks.push(
      <InfoBlockRow key="response-block-user" title="Created by" value={manualCompletion._user ?? ''} />
    );
    responseBlocks.push(
      <InfoBlockRow
        key="response-block-created"
        title="Date"
        value={short_date_format(manualCompletion.created_at, true)}
      />
    );
    responseBlocks.push(<InfoBlockRow key="response-block-note" title="Note" value={manualCompletion.note} />);

    setResponses(responseBlocks);
    setIsLoading(false);
  }, [manualCompletion]);

  const handleActionResponse = (response, success_message, error_message) => {
    if (response.data?.status == 'success') {
      getCallBack();
      closeDrawer();
      if (onClose && typeof onClose == 'function') {
        onClose();
      }
      toast.addToast({
        duration: 3000,
        type: 'checkmark',
        content: success_message
      });
    } else {
      toast.addToast({
        duration: 3000,
        type: 'warning',
        content: error_message
      });
    }
  };

  const handleApproveCompletedRequirement = useCallback(() => {
    jwtInstance
      .post(`benchmark/manually_completed`, {
        note: notes,
        requirement_id: manualCompletion.requirementId,
        benchmark_id: benchmarkId,
        client_id: clientId
      })
      .then(res => handleActionResponse(res, 'Bubble Manually Filled.', 'There was an error, please try again.'));
  }, [notes, manualCompletion]);

  const handleDeleteCompletedRequirement = useCallback(() => {
    jwtInstance
      .delete(`benchmark/manually_completed/${manualCompletion.id}`)
      .then(res => handleActionResponse(res, 'Bubble Deleted.', 'There was an error, please try again.'));
  }, [manualCompletion]);

  const handleInputChange = ({ target: { value } }) => setNotes(value);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="vc-container">
      <div className="vc-container__scroll-wrapper">
        <div className="print-only">
          <img className="print-logo" src="/static/img/r7t-logo.jpg" />
        </div>
        <div
          className={classnames('vc-container__header', {
            'drawer-open': drawerOpen
          })}
        >
          <h3 className="vc-container__header--heading">Manually Complete Requirement</h3>
        </div>
        <div className="form-responses">
          <div className="drawer-body vc-container__info-block form-changed">
            {/*Add note block*/}
            {responses}
            {edit ? (
              <>
                <p className="modal-message--no-border">
                  Please explain the reason for manually completing this requirement.
                </p>
                <Input
                  autoFocus
                  Component="input"
                  type="textarea"
                  name="notes"
                  handleChange={handleInputChange}
                  handleBlur={handleInputChange}
                  value={notes}
                />
              </>
            ) : null}
          </div>
        </div>
        {edit ? (
          <ActionButtons
            drawerOpen={drawerOpen}
            approveCompleted={handleApproveCompletedRequirement}
            deleteCompleted={null}
            closeDrawer={closeDrawer}
          />
        ) : (
          <ActionButtons
            drawerOpen={drawerOpen}
            approveCompleted={null}
            deleteCompleted={handleDeleteCompletedRequirement}
            closeDrawer={closeDrawer}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  drawer: state.drawer
});

export default connect(mapStateToProps, { closeDrawer })(ManuallyCompletedDrawer);
