/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

/**
 * Internal dependencies
 */
import { genOptions, GetTestName } from '../helpers';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import CustomSelect from 'components/CustomSelect';
import Icon from 'components/Icon';

const SingleProbTest = ({
  id,
  days_denominator,
  max_days,
  min_days,
  tests_numerator,
  saveTest,
  removeTest,
  testTypes,
  formatPercent,
  // eslint-disable-next-line no-unused-vars
  errors
}) => {
  const [state, setState] = useState({
    days_denominator: 7,
    tests_numerator: 2,
    max_days: 7,
    min_days: 0
  });
  const [error, setError] = useState('');

  useEffect(() => {
    setState({
      ...state,
      days_denominator: days_denominator
        ? days_denominator
        : state.days_denominator,
      max_days: max_days ? max_days : state.max_days,
      min_days: min_days ? min_days : state.min_days,
      tests_numerator: tests_numerator ? tests_numerator : state.tests_numerator
    });
  }, [days_denominator, max_days, min_days, tests_numerator]);

  const handleChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value
    });
  };

  useEffect(() => {
    if (+state.tests_numerator > +state.days_denominator) {
      setError(
        'The frequency must not be greater than 100%. Change the numerator so it is less than or equal to the denominator.'
      );
    } else {
      setError('');
    }
  }, [state.tests_numerator, state.days_denominator]);

  const handleRemoveTest = () => removeTest(id);

  const handleSaveTest = () => !error && saveTest(id, state);

  const readyToSave = () => {
    const propObject = {
      days_denominator,
      max_days,
      min_days,
      tests_numerator
    };

    return !isEqual(state, propObject) && !error;
  };

  const name = GetTestName(testTypes, id);

  return (
    <div className="test-select__test">
      <Input value={name} disabled label="Test Name" />
      {error && (
        <div className="error-container">
          <Icon name="warning" />
          <Label alert mBottom>
            {error}
          </Label>
        </div>
      )}
      <Label bold>
        Frequency:&nbsp;
        {formatPercent(state.tests_numerator, state.days_denominator)}
      </Label>
      <div className="multiple-CustomSelects">
        <CustomSelect
          handleCustomSelectChange={handleChange}
          value={state.tests_numerator}
          name="tests_numerator"
          options={genOptions(1, 15)}
        />
        <span className="divider-word">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
        <CustomSelect
          handleCustomSelectChange={handleChange}
          value={state.days_denominator}
          name="days_denominator"
          options={genOptions(1, 30)}
        />
      </div>
      <CustomSelect
        label="Min. Tests Per Week"
        handleCustomSelectChange={handleChange}
        value={state.min_days}
        name="min_days"
        options={genOptions(1, 6, { value: '0', label: '0' })}
      />
      <CustomSelect
        label="Max. Tests Per Week"
        handleCustomSelectChange={handleChange}
        value={state.max_days}
        name="max_days"
        options={genOptions(1, 6, { value: '7', label: 'Unlimited' })}
      />
      <div className="tests-container__buttons">
        <Button
          handleButtonClick={handleSaveTest}
          secondary
          micro
          disabled={!readyToSave()}
        >
          Save Test
        </Button>
        <Button
          handleButtonClick={handleRemoveTest}
          secondary
          transparent
          micro
        >
          Remove Test
        </Button>
      </div>
    </div>
  );
};

SingleProbTest.propTypes = {
  days_denominator: PropTypes.number,
  id: PropTypes.number.isRequired,
  max_days: PropTypes.number,
  min_days: PropTypes.number,
  saveTest: PropTypes.func.isRequired,
  removeTest: PropTypes.func.isRequired,
  tests_numerator: PropTypes.number,
  formatPercent: PropTypes.func.isRequired
};

export default SingleProbTest;
