/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Input from 'components/Input';
import Button from 'components/Button';
import isEqual from 'lodash/isEqual';

const errorMessages = {
  notNumeric: 'Please provide a numeric percentage only.',
  notPositive: 'Please provide positive percentages only.',
  not100: 'Percentages must add up to 100.'
};

const SinglePercentTest = ({
  test_id,
  percentage,
  name,
  removeTest,
  saveTest
}) => {
  const [percent, setPercent] = useState('');
  const [error, setError] = useState([]);

  useEffect(() => {
    setPercent(percentage);
  }, []);

  useEffect(() => {
    const errorsArr = [];

    if (!+percent || +percent < 0 || +percent !== 100) {
      if (!+percent) {
        errorsArr.push(errorMessages.notNumeric);
      }

      if (+percent < 0) {
        errorsArr.push(errorMessages.notPositive);
      }

      if (+percent !== 100) {
        errorsArr.push(errorMessages.not100);
      }

      if (errorsArr.length) {
        setError(errorsArr);
      }
    } else {
      setError([]);
    }
  }, [percent]);

  const handlePercentageChange = ({ target: { value } }) => setPercent(value);

  const readyToSave = () => !isEqual(percentage, percent) && !error.length;

  return (
    <div key={`percent-test-${test_id}`} className="test-select__test">
      <Input value={name} disabled label="Test Name" />
      <Input
        label={`Percentage${percent ? `: ${percent}%` : ''}`}
        handleChange={handlePercentageChange}
        value={percent}
        name={test_id}
      />
      <Button
        handleButtonClick={() => saveTest(test_id)}
        disabled={!readyToSave()}
        secondary
        micro
      >
        Save Test
      </Button>
      <Button
        handleButtonClick={() => removeTest(test_id)}
        secondary
        transparent
        micro
      >
        Remove Test
      </Button>
    </div>
  );
};

SinglePercentTest.propTypes = {
  test_id: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  saveTest: PropTypes.func.isRequired,
  removeTest: PropTypes.func.isRequired
};

export default SinglePercentTest;
