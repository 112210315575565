export const openDrawer = (type, drawerProps) => {
  return {
    type: 'OPEN_DRAWER',
    payload: {
      type,
      drawerProps
    }
  };
};

export const closeDrawer = () => {
  return {
    type: 'CLOSE_DRAWER'
  };
};
