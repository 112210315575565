/**
 * Disposes the PDF document as an attachment download.
 *
 * @param {String} buffer
 * @param {String} filename
 */
const asAttachment = (buffer, filename = null) => {
  const blob = bufferToBlob(buffer);

  // If no filename is assigned, provide a unique filename
  if (!filename) {
    filename = new Date().getTime();
  }

  if (window.top.navigator.msSaveOrOpenBlob) {
    // Handle IE
    window.top.navigator.msSaveOrOpenBlob(blob, `${filename}.pdf`);
  } else {
    // Handle other browsers
    let element = document.createElement('a');
    element.setAttribute('href', URL.createObjectURL(blob));
    element.setAttribute('download', `${filename}.pdf`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
};

/**
 * Disposes the PDF document as a stream to the browser.
 *
 * @param {String} buffer
 * @param {String} target
 */
const asInline = (buffer, target = '_blank') => {
  const blob = bufferToBlob(buffer);

  if (window.top.navigator.msSaveOrOpenBlob) {
    // Handle IE
    window.top.navigator.msSaveOrOpenBlob(blob);
  } else {
    // Handle other browsers
    window.open(URL.createObjectURL(blob), target);
  }
};

/**
 * Converts the PDF buffer to a Blob
 *
 * @param {String} buffer
 * @returns {Blob}
 */
const bufferToBlob = buffer => {
  const sliceSize = sliceSize || 512;

  const byteCharacters = [].reduce.call(
    new Uint8Array(buffer),
    function(p, c) {
      return p + String.fromCharCode(c);
    },
    ''
  );
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: 'application/pdf' });
};

export { asAttachment, asInline };
