import React, { useContext, useMemo } from 'react';
import moment from 'moment';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';
import SideNavSub from './SideNavSub';

const SideNavEvents = ({ pathname }) => {
  const { hasAccessTo } = useContext(AppContext);

  const links = useMemo(
    () => [
      {
        to: `/events/explorer`,
        label: 'Event Explorer',
        className: pathname.includes('/events/explorer') ? 'sidenavsub__link--active' : ''
      },
      {
        to: '/events/community/add',
        label: 'Add Event',
        access: hasAccessTo('community_events')
      },
      {
        to: '/events/curfew/add',
        label: 'Add Curfew',
        access: hasAccessTo('curfew_events')
      },
      {
        to: '/events/appointment/add',
        label: 'Add Appointment',
        access: hasAccessTo('appointments')
      },
      {
        to: '/events/breathalyzer/add',
        label: 'Add Breathalyzer Check-In',
        access: hasAccessTo('breathalyzer')
      },
      {
        to: `/events/log/${moment().format('YYYY/MM')}`,
        label: 'Event Log',
        className: pathname.includes('/events/log') ? 'sidenavsub__link--active' : ''
      },
      {
        to: `/events/appointment/log`,
        label: 'Appointments Log',
        className: pathname.includes('/events/appointment/log') ? 'sidenavsub__link--active' : '',
        access: hasAccessTo('appointments')
      }
    ],
    [pathname, hasAccessTo]
  );

  if (hasAccessTo('curfew_events') || hasAccessTo('community_events')) {
    return (
      <NavLink to={'/events/explorer'} title="Events" nestedMenu={<SideNavSub title="Events" links={links} />}>
        <Icon name="events" size={24} />
        <span className="sidenav__item-label">Events</span>
      </NavLink>
    );
  }

  return null;
};

export default SideNavEvents;
