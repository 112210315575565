import { loadPending, removePending, loadSuccessful } from './helperActions';

import { getTestsList, catchErrors } from 'utils/apiHelpers';

export const getTests = (hash = false) => async dispatch => {
  dispatch(loadPending('TESTS'));

  if (hash) {
    try {
      const res = await getTestsList(hash);

      if (res.data.hash === hash) {
        dispatch(removePending('TESTS'));
      } else {
        dispatch(loadSuccessful('TESTS', res.data));
      }
    } catch (e) {
      catchErrors(e);
    }
  } else {
    try {
      const res = await getTestsList(false);

      dispatch(loadSuccessful('TESTS', res.data));
    } catch (e) {
      catchErrors(e);
    }
  }
}
