/**
 * External dependencies
 */
import React, { useContext, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

/**
 * Internal dependencies
 */
import CustomSelect from 'components/CustomSelect';
import Button from 'components/Button';
import FormContext from 'components/FormProvider/FormContext';
import { getRemainingTestOptions } from '../helpers';
import Label from 'components/Label';
import Icon from 'components/Icon';

const AddTest = () => {
  const { getState, handleSetState, getErrors } = useContext(FormContext);
  const [selectedTest, setSelectedTest] = useState('');

  const {
    selectedProfile: { tests, type },
    testTypes
  } = getState();

  const handleCustomSelectChange = ({ target: { value } }) =>
    setSelectedTest(value);

  const error = getErrors('add_test', 'tests');

  const addTest = () => {
    let newTest;
    switch (type) {
      case 'prob_weekly':
        newTest = {
          days_denominator: 5,
          tests_numerator: 2,
          max_days: 3,
          min_days: 2
        };
        break;
      case 'period_weekly':
      case 'period_monthly':
        newTest = {
          frequency: '1',
          maxDaysBetweenTests: 0,
          minDaysBetweenTests: 0
        };
        break;
      case 'percent_weekly':
      case 'percent_monthly':
        newTest = {
          percentage: 0
        };
        break;
    }

    const newTests = cloneDeep(tests);

    newTests[selectedTest] = newTest;

    handleSetState(
      { target: { name: 'tests', value: newTests } },
      'selectedProfile'
    );

    setSelectedTest('');
  };

  const remainingTests = [
    { value: '', label: 'Select a Test' },
    ...getRemainingTestOptions(Object.keys(tests), testTypes)
  ];

  return (
    <>
      {error && isEmpty(tests) && (
        <div className="error-container add-test">
          <Icon name="warning" />
          <Label htmlFor="add_test" alert mBottom>
            {error}
          </Label>
        </div>
      )}
      <CustomSelect
        id="add_test"
        label="Select New Test"
        value={selectedTest}
        options={remainingTests}
        handleCustomSelectChange={handleCustomSelectChange}
      />
      <Button
        handleButtonClick={addTest}
        secondary
        micro
        disabled={!selectedTest}
      >
        Add New Test to Profile
      </Button>
    </>
  );
};

export default AddTest;
