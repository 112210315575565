import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
// import logger from 'redux-logger';

import rootReducer from './reducers/root';

export const history = createBrowserHistory();

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const enhancers = compose(
  applyMiddleware(thunk, routerMiddleware(history)),
  sentryReduxEnhancer,
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    : f => f
);

const initialState = {
  calendars: { doneInitialLoad: false },
  clients: { doneInitialLoad: false },
  drawer: {
    drawerOpen: false,
    drawerProps: {},
    type: null
  },
  facility: {},
  features: {},
  groups: { doneInitialLoad: false },
  profiles: { doneInitialLoad: false },
  tests: { doneInitialLoad: false },
  todaysReport: { doneInitialLoad: false },
  todaysDate: { doneInitialLoad: false },
  user: {},
  users: { doneInitialLoad: false },
  windowReport: { doneInitialLoad: false },
  windows: { doneInitialLoad: false },
  dashboard: { doneInitialLoad: false },
  toasts: [],
};

const store = createStore(rootReducer(history), initialState, enhancers);

export default store;
