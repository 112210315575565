import * as yup from 'yup';

import ControlConfig from 'components/Form/Model/ControlConfig';

class CheckboxGroupConfig extends ControlConfig {
  constructor() {
    super('checkbox-group', yup.string().default(''));
  }

  getDefaultValue(control, stateValue) {
    if (control.allSelected &&
      control.options &&
      control.options.length) {
      return control.options
        .map(o => ({ [o.name]: true }))
        .reduce((ob, ck) => {
          return { ...ob, ...ck };
        }, {});
    }
    return stateValue
  }
}

export default CheckboxGroupConfig;