import React from 'react';
import classnames from 'classnames';
import CardGroupInfo from 'components/CardBlock/CardGroupInfo';

export function CollapsibleSection({children}) {
  return <div className="collapsible-section">
      {children}
  </div>
}

export function CardBlockBlock({children, classes}) {
  return <div
    className={classnames('card-block', classes)}
  >{children}</div>
}

export function CardBlockContent({children, id, header, info, toggles, open, keyPrefix, index}) {
  return <>
    <CardGroupInfo
      id={id}
      header={header}
      info={info}
      toggles={toggles}
      open={open}
      key={`CardGroupInfo${id}`} 
    />
    <div
      id={`block-${keyPrefix}-${index}`}
      key={`block-${keyPrefix}-${index}`}
      data-for-header={header}
      className={`card-content ${toggles ? 'toggleable' : ''} ${
        toggles && !open ? 'hidden' : ''
      }`}
    >
      {children}
    </div>
  </>
}