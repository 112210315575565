import React, { useCallback, useMemo } from 'react';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';

import Input from 'components/Input';
import Label from 'components/Label';
import Icon from 'components/Icon';
import curriculumUtil from 'utils/CurriculumUtil';
import { useCurriculumItemProps } from 'components/Curriculum/hooks';
import CurriculumDrawerForm from 'components/Curriculum/CurriculumDrawerForm';

const schema = object({
  title: string()
    .trim()
    .min(3, 'Title must be at least three characters')
    .max(40, 'Title cannot be more than 40 characters')
    .required('Title is required')
});

const TopicDrawer = () => {
  const {
    item: topic,
    isEdit,
    isNew,
    isView,
    isDrawerOpen,
    handleAdd,
    handleEdit,
    isItemSubmitting
  } = useCurriculumItemProps({
    selector: curriculumUtil.SELECTOR_KEYS.TOPICS
  });
  const { values, errors, touched, setFieldValue, handleBlur, handleSubmit, isSubmitting, isValid } = useFormik({
    initialValues: curriculumUtil.getInitialValues({
      isEdit,
      isView,
      original: { title: topic.title },
      fallback: { title: '' }
    }),
    validationSchema: schema,
    onSubmit: handleSubmitIntermediary,
    validateOnMount: false,
    validateOnBlur: true,
    isInitialValid: isEdit,
    validateOnChange: true,
    enableReinitialize: true
  });

  async function handleSubmitIntermediary() {
    if (isEdit) {
      handleEdit({ id: topic.id, title: values.title });
    } else if (isNew) {
      handleAdd({ title: values.title });
    }
  }

  const handleChange = useCallback(e => setFieldValue('title', e.target.value, true), [setFieldValue]);

  const isSubmitDisabled = useMemo(() => {
    return isEqual(topic.title, values?.title) || isSubmitting || !isValid || isItemSubmitting || isView;
  }, [topic?.title, values?.title, isSubmitting, isValid, isItemSubmitting, isView]);

  return (
    <CurriculumDrawerForm
      isDrawerOpen={isDrawerOpen}
      header={`${isEdit ? 'Edit' : isNew ? 'Add' : 'View'} Topic`}
      handleSubmit={handleSubmit}
      isSubmitDisabled={isSubmitDisabled}
      isSubmitting={isSubmitting || isItemSubmitting}
    >
      <Input
        autoFocus
        placeholder="Enter a unique topic title"
        value={values.title}
        tooltip={
          isEdit || isNew ? 'Topic titles must be both unique and a minimum of three characters long' : undefined
        }
        required={isEdit || isNew}
        handleChange={handleChange}
        handleBlur={handleBlur}
        id="title"
        label="Title"
        disabled={isView}
      />
      {touched.title && errors.title && (
        <div className="error-container">
          <Icon name="warning" />
          <Label htmlFor="title" alert>
            {errors.title}
          </Label>
        </div>
      )}
    </CurriculumDrawerForm>
  );
};

export default TopicDrawer;
