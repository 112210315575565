import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';

const rows = [
  {
    title: 'SENDER'
  },
  {
    title: 'SEND DATE'
  },
  {
    title: 'SEND TIME'
  },
  {
    title: 'SCHEDULED'
  },
  {
    title: 'STATUS'
  },
  {
    title: 'CLIENT GROUP',
    key: 'groups'
  },
  {
    title: 'TESTING PROFILE',
    key: 'profiles'
  },
  {
    title: 'CHECK-IN WINDOW',
    key: 'windows'
  },
  {
    title: 'CALENDAR',
    key: 'calendars'
  },
  {
    title: 'CASE MANAGER',
    key: 'users'
  },
  {
    title: 'MESSAGE'
  },
  {
    title: 'RECIPIENTS'
  }
];

const parseFilters = filters => {
  const baseObj = {};

  Object.keys(filters).forEach(key => {
    baseObj[key] =
      null !== filters[key] && !isEmpty(filters[key])
        ? flatten(filters[key].map(el => Object.values(el))).join(', ')
        : 'None Selected';
  });

  return baseObj;
};

export { rows, parseFilters };
