import React from 'react';
import classnames from 'classnames';

import Icon from 'components/Icon';

const ActionButtons = ({
  approveForm,
  disapproveForm,
  setFormToDraft,
  drawerOpen
}) => (
  <div
    className={classnames('vc-container__action-buttons', {
      'drawer-open': drawerOpen
    })}
  >
    <button
      type="button"
      data-action="approve"
      className={'ab-excuse approve'}
      onClick={approveForm}
    >
      <Icon name="checkmark" size="20" />
      &nbsp;
      <span className="ab-status">APPROVE</span>
    </button>
    <button
      type="button"
      data-action="disapprove"
      className={'ab-excuse disapprove'}
      onClick={disapproveForm}
    >
      <Icon name="crossmark" size="20" />
      &nbsp;
      <span className="ab-status disapprove">DISAPPROVE</span>
    </button>
    <button
      type="button"
      data-action="setToDraft"
      className={'ab-excuse ab-open'}
      onClick={setFormToDraft}
    >
      <Icon name="warning" size="20" />
      &nbsp;
      <span className="ab-status">SET TO DRAFT</span>
    </button>
  </div>
);

export default ActionButtons;
