/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

/**
 * Internal dependencies
 */
import { genOptions, GetTestName, isInt } from '../helpers';
import Button from 'components/Button';
import Input from 'components/Input';
import CustomSelect from 'components/CustomSelect';

const SinglePeriodTest = ({
  saveTest,
  removeTest,
  hasMinDays,
  hasMaxDays,
  id,
  type,
  weekly,
  testTypes,
  frequency,
  maxDaysBetweenTests,
  minDaysBetweenTests,
  // eslint-disable-next-line no-unused-vars
  errors,
  // eslint-disable-next-line no-unused-vars
  ...props
}) => {
  const [state, setState] = useState({});
  const [validFrequency, setValidFrequency] = useState(true);

  useEffect(() => {
    isValidFrequency({ target: { value: frequency } });

    setState({
      ...state,
      frequency: frequency ? frequency : '',
      minDaysBetweenTests,
      maxDaysBetweenTests
    });
  }, [frequency, minDaysBetweenTests, maxDaysBetweenTests]);

  const isValidFrequency = ({ target: { value } }) => {
    if (value.indexOf('-') !== -1) {
      let values = value
        .split('-')
        .map(v => parseInt(v))
        .map(v => (isNaN(v) ? 0 : v));
      if (values.length !== 2) {
        return setValidFrequency(false);
      }
      if (!isInt(values[0]) || !isInt(values[1]) || values[0] < 0) {
        return setValidFrequency(false);
      }

      return setValidFrequency(values[0] < values[1]);
    } else {
      return setValidFrequency(isInt(value));
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value
    });

    if (name === 'frequency') {
      isValidFrequency({ target: { value } });
    }
  };

  const handleRemoveTest = () => removeTest(id);

  const handleSaveTest = () => saveTest(id, state);

  const readyToSave = () => {
    const propsObject = {
      frequency,
      minDaysBetweenTests,
      maxDaysBetweenTests
    };

    return !isEqual(state, propsObject) && validFrequency;
  };

  const name = GetTestName(testTypes, id);

  const maxDaysOptions = genOptions(3, weekly ? 14 : 60, {
    value: '0',
    label: 'Not Set'
  });

  const minDaysOptions = genOptions(1, weekly ? 7 : 30, {
    value: '0',
    label: 'Not Set'
  });

  return (
    <div className="test-select__test">
      <Input value={name} disabled label="Test Name" />
      <Input
        error={
          !validFrequency
            ? 'Frequency is invalid. Please, enter a valid frequency.'
            : ''
        }
        label={`Tests Per ${type === 'period_weekly' ? 'Week' : 'Month'}`}
        handleBlur={isValidFrequency}
        handleChange={handleChange}
        size="4"
        value={state.frequency}
        name="frequency"
      />
      {hasMaxDays ? (
        <CustomSelect
          label="Max. Days Between Tests"
          handleCustomSelectChange={handleChange}
          value={state.maxDaysBetweenTests}
          name="maxDaysBetweenTests"
          options={maxDaysOptions}
        />
      ) : null}
      {hasMinDays ? (
        <CustomSelect
          label="Min. Days Between Tests"
          handleCustomSelectChange={handleChange}
          value={minDaysBetweenTests}
          name="minDaysBetweenTests"
          options={minDaysOptions}
        />
      ) : null}
      <div className="tests-container__buttons">
        <Button
          handleButtonClick={handleSaveTest}
          secondary
          micro
          disabled={!readyToSave()}
        >
          Save Test
        </Button>
        <Button
          handleButtonClick={handleRemoveTest}
          secondary
          transparent
          micro
        >
          Remove Test
        </Button>
      </div>
    </div>
  );
};

SinglePeriodTest.propTypes = {
  frequency: PropTypes.string,
  hasMinDays: PropTypes.bool,
  hasMaxDays: PropTypes.bool,
  id: PropTypes.number.isRequired,
  maxDaysBetweenTests: PropTypes.number,
  minDaysBetweenTests: PropTypes.number,
  saveTest: PropTypes.func.isRequired,
  weekly: PropTypes.bool
};

export default SinglePeriodTest;
