/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import SinglePropTest from './SingleProbTest';

const ProbTests = ({
  selectedProfile: { tests },
  saveTest,
  removeTest,
  testTypes,
  errors
}) => {
  const testIDs = Object.keys(tests);

  if (!testIDs.length) return null;

  const formatPercent = (tests_numerator, days_denominator) =>
    (Math.round((10000 * tests_numerator) / days_denominator) / 100).toFixed(
      2
    ) + '%';

  return (
    <div className="tests-container">
      {testIDs.map(id => (
        <SinglePropTest
          errors={
            errors.tests && errors.tests.probTests
              ? errors.tests.probTests
              : false
          }
          key={`prob-test-${id}`}
          id={+id}
          {...tests[id]}
          saveTest={saveTest}
          removeTest={removeTest}
          testTypes={testTypes}
          formatPercent={formatPercent}
        />
      ))}
    </div>
  );
};

ProbTests.propTypes = {
  saveTest: PropTypes.func.isRequired,
  removeTest: PropTypes.func.isRequired,
  selectedProfile: PropTypes.shape({
    tests: PropTypes.object
  }).isRequired,
  testTypes: PropTypes.array.isRequired
};

export default ProbTests;
