import { loadPending, removePending, loadSuccessful } from './helperActions';

import {
  getEvent as getEventAPI,
  requestGetEventTags,
  catchErrors
} from 'utils/apiHelpers';

export function getEvent(events, client_id, id, callback = false) {
  let hash = events.doneInitialLoad && events.hash;

  return dispatch => {
    // set to pending
    dispatch(loadPending('EVENTS'));

    if (hash) {
      getEventAPI(hash, client_id, id)
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending('EVENTS'));
            if (callback) {
              callback();
            }
          } else {
            dispatch(loadSuccessful('EVENT', res.data));
            if (callback) {
              callback();
            }
          }
        })
        .catch(catchErrors);
    } else {
      getEventAPI(false, client_id, id)
        .then(res => {
          dispatch(loadSuccessful('EVENT', res.data));
          if (callback) {
            callback();
          }
        })
        .catch(catchErrors);
    }
  };
}

export function getEventTags() {
  return dispatch => {
    // set to pending
    dispatch(loadPending('EVENT_TAGS'));
    requestGetEventTags()
      .then(res => {
        dispatch(loadSuccessful('EVENT_TAGS', res.data));
      })
      .catch(catchErrors);
  };
}
