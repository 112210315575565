import PropTypes from 'prop-types';
import React from 'react';

class Header extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    handleSort: PropTypes.func.isRequired,
    sort: PropTypes.object.isRequired,
    sortCol: PropTypes.string.isRequired,
    // Not used, but could be useful?
    style: PropTypes.object,
    unsortable: PropTypes.bool
  };

  toggleSort = () => {
    if (this.props.unsortable) {
      return false;
    }
    const { handleSort, sort, sortCol } = this.props;
    let newSort = {
      name: sortCol,
      type: 'asc'
    };

    if (sort.name === sortCol) {
      newSort.type = sort.type === 'asc' ? 'desc' : 'asc';
    }

    handleSort(newSort);
  };

  render() {
    const { children, className, sort, sortCol, style } = this.props;
    let newClassName;

    if (sort.name === sortCol) {
      newClassName = sort.type === 'desc' ? 'down' : 'up';
      newClassName = className ? className + ' ' + newClassName : newClassName;
    } else {
      newClassName = className ? className : '';
    }

    return (
      <th
        onClick={this.toggleSort}
        className={newClassName}
        style={style || {}}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <span className="header-text" style={{ paddingLeft: '6px' }}>
            {children}
          </span>
          {!this.props.unsortable ? (
            <span
              className="header-icons"
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 1rem'
              }}
            >
              <span
                className="icon-triangle-up"
                style={{ marginBottom: '0.15rem' }}
              />
              <span className="icon-triangle-down" />
            </span>
          ) : null}
        </div>
      </th>
    );
  }
}

export default Header;
