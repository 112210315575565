import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { closeContextualMenu, closeMobileMenu } from 'actions/actionCreators';

const useMobileContext = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  /**
   * When user is on a new page, this ensures that
   * the mobile menu and contextual menu are closed.
   */
  useEffect(() => {
    dispatch(closeMobileMenu());
    dispatch(closeContextualMenu());
  }, [pathname]);
};

export default useMobileContext;
