import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

import ReconnectLabel from 'components/Label';
import TooltipContentDuration from 'components/Curriculum/Assignment/Completions/TooltipContentDuration';

const DurationBar = ({ data, handleFilter }) => {
  return (
    <div data-tip="" data-for="chart" className="chart">
      <ReconnectLabel htmlFor={`chart-duration`} bold mBottom={false}>
        Time Engaged With Resource (in minutes)
      </ReconnectLabel>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{
            right: 10,
            left: -25
          }}
          onClick={handleFilter}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="duration" dy={5} />
          <YAxis dx={-5} />
          <Tooltip content={<TooltipContentDuration />} />
          <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
          <Bar dataKey="occurrences" fill="#f95d6a" barSize={15} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DurationBar;
