/**
 * Internal dependencies
 */
import { loadPending, loadSuccessful } from './helperActions';

import {
  catchErrors,
  requestAddAppointmentEvent,
  requestUpdateAppointmentEvent,
  requestGetAppointmentEvent,
  requestGetAppointmentEvents,
  requestDeleteAppointmentEvent,
  updateEventOccurrence,
  requestGetClosedAppointmentEvents
} from 'utils/apiHelpers';

export function updateAppointmentOccurrenceSpecializationData(occurrence_id, specialization_data) {
  return updateEventOccurrence(occurrence_id, specialization_data);
}

export function addAppointmentEvent(data, callback = false) {
  return dispatch => {
    dispatch(loadPending('ADD_APPOINTMENT_EVENT'));

    requestAddAppointmentEvent(data)
      .then(res => {
        dispatch(
          loadSuccessful('ADD_APPOINTMENT_EVENT', {
            communityEvent: {
              ...res.data
            }
          })
        );
        if (callback !== false) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function updateAppointmentEvent(id, data, callback = false) {
  return dispatch => {
    dispatch(loadPending('UPDATE_APPOINTMENT_EVENT'));

    requestUpdateAppointmentEvent(id, data)
      .then(res => {
        dispatch(
          loadSuccessful('UPDATE_APPOINTMENT_EVENT', {
            communityEvent: {
              ...res.data
            }
          })
        );
        if (callback !== false) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getAppointmentEvent(id, callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_APPOINTMENT_EVENT'));

    requestGetAppointmentEvent(id)
      .then(res => {
        dispatch(loadSuccessful('GET_APPOINTMENT_EVENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function deleteAppointmentEvent(id, callback = false) {
  return dispatch => {
    dispatch(loadPending('DELETE_APPOINTMENT_EVENT'));

    requestDeleteAppointmentEvent(id)
      .then(res => {
        dispatch(loadSuccessful('DELETE_APPOINTMENT_EVENT'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}

export function getAppointmentEvents(startDate, endDate, callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_APPOINTMENT_EVENTS'));

    return requestGetAppointmentEvents(startDate, endDate)
      .then(res => {
        dispatch(loadSuccessful('GET_APPOINTMENT_EVENTS'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}
export function getCompletedAppointmentEvents(startDate, endDate, callback = false) {
  return dispatch => {
    dispatch(loadPending('GET_CLOSED_APPOINTMENT_EVENTS'));

    return requestGetClosedAppointmentEvents(startDate, endDate)
      .then(res => {
        dispatch(loadSuccessful('GET_CLOSED_APPOINTMENT_EVENTS'));
        if (callback) {
          callback(res);
        }
      })
      .catch(catchErrors);
  };
}
