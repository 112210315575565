/**
 * External dependencies
 */
import get from 'lodash/get';

/**
 * Get the locations for a given client.
 *
 * @param {object} state App state tree.
 * @param {number} clientId Id of the client that we want to get the locations from.
 *
 * @returns {bool|array} A collection of locations. False if they're not yet available.
 */
const getClientLocations = (state, clientId = -Infinity) => {
  if (-Infinity !== clientId) {
    return get(state, ['clients', 'clientList', clientId, 'locations', 'list'], false);
  }

  const allClientLocations = state.clients?.clientList ?? [];
  return Object.keys(allClientLocations).reduce((acc, clientId) => {
    return {
      ...acc,
      [clientId]: allClientLocations[clientId]?.locations?.list ?? false
    };
  }, {});
};

export default getClientLocations;
