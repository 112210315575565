import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, lighten, makeStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(() => ({
  icon: {
    color: lighten('#2F3D49', 0.75),
    display: 'inline',
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 18
  },
  static: {
    position: 'unset'
  }
}));

const CustomTooltip = ({
  children,
  position,
  Icon = HelpIcon,
  absolute = true,
  arrow,
  enterTouchDelay,
  enterNextDelay,
  enterDelay,
  disableTouchListener,
  disableFocusListener,
  disableHoverListener,
  leaveTouchDelay,
  leaveDelay,
  interactive,
  onClose,
  onOpen,
  PaperComponent,
  PopperProps,
  TransitionComponent,
  TransitionProps,
  open
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={children}
      placement={position}
      arrow={arrow}
      enterTouchDelay={enterTouchDelay}
      enterNextDelay={enterNextDelay}
      enterDelay={enterDelay}
      disableTouchListener={disableTouchListener}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      leaveTouchDelay={leaveTouchDelay}
      leaveDelay={leaveDelay}
      interactive={interactive}
      onClose={onClose}
      onOpen={onOpen}
      PaperComponent={PaperComponent}
      PopperProps={PopperProps}
      TransitionComponent={TransitionComponent}
      TransitionProps={TransitionProps}
      open={open}
    >
      <Icon classes={{ root: `${classes.icon}${!absolute ? ` ${classes.static}` : ''}` }} />
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
};

export default CustomTooltip;
