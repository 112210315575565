import GenericBuilder from "./GenericBuilder";

export class ChartDuration {
  constructor(duration, min, max, occurrences = 0) {
    this.duration = duration;
    this.min = min;
    this.max = max;
    this.occurrences = occurrences;
  }

  setDuration(duration) {
    this.duration = duration;

    return this;
  }

  setMin(min) {
    this.min = min;

    return this;
  }

  setMax(max) {
    this.max = max;

    return this;
  }

  incrementOccurrences() {
    this.occurrences++;

    return this;
  }

  getInRange(duration) {
    return duration >= this.min && duration <= this.max;
  }
}

class ChartDurations extends GenericBuilder {
  constructor() {
    super();
  };

  evaluateDurations(clients = []) {
    for (const client of clients) {
      const index = this.items.findIndex(chartDuration => chartDuration.getInRange(client.duration));
      const chartDuration = this.items[index];

      if (chartDuration instanceof ChartDuration) {
        chartDuration.incrementOccurrences();
      }
    }

    return this;
  }
}

export default ChartDurations;