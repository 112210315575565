import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isObject from 'lodash/isObject';
import Label from 'components/Label';
import CharacterCount from 'components/CharacterCount';
import { generateId } from 'components/FormProvider/utils';
import Icon from 'components/Icon';

import './style.scss';

export function InputError({ error, inputId }) {
  if (!error) return null;

  return (
    <div className="input-error">
      <Icon name="warning" />
      <Label htmlFor={inputId} alert mBottom>
        {error}
      </Label>
    </div>
  );
}

export function InputLabel({ label, labelIsHtml, inputId, required, tooltip, children }) {
  return <div className="input-label">
    {!!label && (
      <Label htmlFor={inputId} bold required={required} tooltip={tooltip}>
        {!labelIsHtml && label}
        {labelIsHtml && <div dangerouslySetInnerHTML={{ __html: label }}></div>}
      </Label>
    )}
    {children}
  </div>
}

const Input = forwardRef(({
  autoFocus = false,
  handleFocus,
  blockGroup,
  small = false,
  Component = 'input',
  label,
  name = '',
  id = generateId(),
  error = false,
  type = 'text',
  value = '',
  handleChange,
  handleBlur,
  disabled = false,
  limit = -Infinity,
  placeholder = '',
  mTop = false,
  mBottom = true,
  className,
  containerClass,
  tooltip,
  // eslint-disable-next-line no-unused-vars
  renderChecks,
  setErrorsCallback = false,
  required = false,
  apiError = false,
  disabledIf = false,
  dynamicText = false,
  getStateValue = false,
  // eslint-disable-next-line no-unused-vars
  callback,
  validation,
  inputProps,
  // eslint-disable-next-line no-unused-vars
  setState,
  // eslint-disable-next-line no-unused-vars
  setErrors,
  ...props
}, ref) => {
  useEffect(() => {
    if ('function' === typeof validation && 'function' === typeof setErrorsCallback) {
      validation(name, blockGroup, value, setErrorsCallback, error, getStateValue);
    }
  }, [value]);

  if ('textarea' === type && 'input' === Component) {
    Component = 'textarea';
  }

  const hasLimit = 0 < limit;

  const originalValue =
    value !== undefined && value !== null ? (isObject(value) && value?.[name] ? `${value[name]}` : `${value}`) : '';
  const isFileInput = type == 'file';
  const inputValue = hasLimit ? originalValue.slice(0, limit) : originalValue;
  const otherProps = isFileInput ? {} : { placeholder, value: inputValue };

  const displayError = error || apiError

  return (
    <div className={`input-container ${containerClass ?? ''}`}>
      <InputLabel inputId={id} label={label} required={required} tooltip={tooltip}>
        {hasLimit && <CharacterCount text={inputValue} limit={limit} tooltip={!!tooltip} />}
      </InputLabel>
      {dynamicText && (
        <div className="base-input text-only" data-name={name}>
          &nbsp;
        </div>
      )}
      {!dynamicText && (
        <Component
          ref={ref}
          className={classnames(`base-input${className ? ` ${className}` : ''}`, {
            disabled: !!disabled,
            small: !!small
          })}
          {...props}
          autoFocus={autoFocus}
          id={id}
          type={type}
          {...otherProps}
          onChange={e => handleChange(e, blockGroup)}
          onBlur={handleBlur ? handleBlur : undefined}
          name={name}
          disabled={disabled || (disabledIf && disabledIf(getStateValue))}
          onFocus={handleFocus}
          {...(hasLimit ? { maxLength: limit } : null)}
          {...inputProps}
        />
      )}
      <InputError error={displayError} inputId={id} />
    </div>
  );
});

Input.propTypes = {
  Component: PropTypes.oneOf(['textarea', 'input']),
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  disabledIf: PropTypes.func,
  limit: PropTypes.number,
  placeholder: PropTypes.string,
  blockGroup: PropTypes.string,
  mTop: PropTypes.bool,
  mBottom: PropTypes.bool,
  required: PropTypes.bool
};

export default Input;
