/**
 * External dependencies
 */
import Query from 'graphql-query-builder';

/**
 * Builds the GraphQL query.
 *
 * @param {Array<String>} blocks The array of block identifier keys
 * @param {Object} variables An key - value map of the variables for this query
 *
 * @returns {String} The GraphQL query.
 */
export const buildQueryString = (blocks = [], variables = {}) => {
  const checkInReport = new Query('checkInReport', {
    date: variables.checkInReportDate
  }).find(
    {
      tests: ['id', 'facility_id', 'name', 'description']
    },
    {
      clients: [
        'ad_hoc',
        'id',
        'first_name',
        'last_name',
        'test_today',
        'required_tests',
        'called_in',
        'call_in_time',
        'window_start_hour',
        'window_start_minute',
        'window_end_hour',
        'window_end_minute',
        'confirmation',
        'test_date',
        'test_master_detail_id',
        'test_notes',
        'test_result',
        'location_meta',
        'recording_url',
        'panels',
        'panels_other',
        'substances',
        'substances_other',
        'waiting_status',
      ]
    }
  );

  const violations = new Query('violations', {
    unresolvedOnly: variables.violationsUnresolvedOnly,
    limit: variables.violationsLimit
  }).find('id', 'first_name', 'last_name', 'created', 'status', {
    detail: ['type']
  });

  const messages = new Query('messages', {
    limit: variables.messagesLimit
  }).find('user_id', 'client_id', 'first_name', 'last_name', 'unread', {
    last_message: ['sent', 'message']
  });

  const mapBlockToFragment = {
    'check-in-report': {
      alias: 'checkInReport',
      query: checkInReport
    },
    messages: {
      query: messages
    },
    violations: {
      query: violations
    }
  };

  const fragments = [];

  for (const block in mapBlockToFragment) {
    if (blocks.includes(block)) {
      fragments.push({
        [mapBlockToFragment[block].alias || block]: mapBlockToFragment[block]
          .query
      });
    }
  }

  const dashboard = new Query('dashboard', { userId: variables.userId }).find(
    ...fragments
  );

  return `{ ${dashboard} }`;
};
