import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startCase } from 'lodash';

import {
  getCurriculumDrawerProps,
  getItemByKey,
  getItemSubmitting,
  getItemsByKey
} from 'selectors/curriculumSelectors';
import { useToast } from 'components/Toast';
import getFacilityForms from 'selectors/getFacilityForms';
import curriculumUtil from 'utils/CurriculumUtil';
import { addCurriculumItem, editCurriculumItem, setItemSubmitting } from 'actions/curriculumActions';
import { useCloseDrawer } from 'components/Curriculum/hooks';
import { SelectOption } from 'components/Clients/ClientsGet/Info/BasicInfo/Schemas/helpers';
import { CurriculumAssignmentUx, CurriculumPacketUx, CurriculumResourceUx } from 'utils/CurriculumUxUtil';

function filterHiddenOptions(items) {
  return items.filter(i => !i.hidden).map(i => new SelectOption(i.title, i.id));
}

const useCurriculumItemProps = ({ selector }) => {
  const { isEdit, isNew, isView, isDuplicate, isDrawerOpen } = useSelector(getCurriculumDrawerProps);
  const item = useSelector(getItemByKey(selector));
  const dispatch = useDispatch();
  const forms = useSelector(getFacilityForms);
  const topics = useSelector(getItemsByKey(curriculumUtil.SELECTOR_KEYS.TOPICS));
  const resources = useSelector(getItemsByKey(curriculumUtil.SELECTOR_KEYS.RESOURCES));
  const packets = useSelector(getItemsByKey(curriculumUtil.SELECTOR_KEYS.PACKETS));
  const closeDrawer = useCloseDrawer();
  const isItemSubmitting = useSelector(getItemSubmitting);

  const toast = useToast();

  const successCallback = useCallback(
    (isUpdate = false) => {
      if (isDrawerOpen) {
        closeDrawer();
      }

      toast.addToast({
        position: 'top',
        duration: 3000,
        type: 'checkmark',
        content: `${startCase(selector.slice(0, -1))} successfully ${isUpdate ? 'updated' : 'created'}`
      });
    },
    [toast, selector, closeDrawer, isDrawerOpen]
  );

  const actualItem = useMemo(() => {
    switch (selector) {
      case curriculumUtil.SELECTOR_KEYS.RESOURCES: {
        const builder = new CurriculumResourceUx();

        return isNew ? builder : builder.setData(item);
      }
      case curriculumUtil.SELECTOR_KEYS.PACKETS: {
        const builder = new CurriculumPacketUx().setIsEdit(isEdit);

        if (isView || isEdit) {
          builder.setId(item.id);
        }

        if (!isNew) {
          builder.setTitle(item.title).setResources(item.curriculum_resource);
        }

        return builder;
      }
      case curriculumUtil.SELECTOR_KEYS.ASSIGNMENTS: {
        const builder = new CurriculumAssignmentUx();

        return !isNew ? builder.setData(item) : builder;
      }
      default:
        return item;
    }
  }, [selector, item, isNew, isEdit, isView]);

  const formOptions = useMemo(() => {
    switch (selector) {
      case curriculumUtil.SELECTOR_KEYS.RESOURCES:
        return {
          forms: forms?.map(f => new SelectOption(f.name, f.id)) ?? [],
          topics: filterHiddenOptions(topics)
        };
      case curriculumUtil.SELECTOR_KEYS.PACKETS:
        return {
          resources: filterHiddenOptions(resources)
        };
      default:
        return {};
    }
  }, [selector, forms, topics, resources, packets]);

  const handleAdd = useCallback(async (data) => {
    dispatch(setItemSubmitting());

    return dispatch(addCurriculumItem(selector, data))
      .then((result) => {
        if (!result?.error) {
          successCallback(false);
        }
      });
  }, [dispatch, selector, successCallback]);

  const handleEdit = useCallback((data, callback) => {
    dispatch(setItemSubmitting());
    dispatch(editCurriculumItem(selector, data))
      .then(result => {
        if (!result?.error) {
          successCallback(true);

          if ('function' === typeof callback) {
            callback();
          }
        }
      });
  }, [dispatch, selector, successCallback]);

  return {
    item: actualItem,
    isEdit,
    isNew,
    isView,
    isDuplicate,
    isDrawerOpen,
    isItemSubmitting,
    successCallback,
    handleAdd,
    handleEdit,
    ...formOptions
  };
};

export default useCurriculumItemProps;
