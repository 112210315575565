import React, { useState, useRef, useEffect, useCallback } from 'react';
import * as classNames from 'classnames';
import Player from 'react-player';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { useToast } from 'components/Toast';
import './style.scss';

const MP4_EXTENSIONS = /(\.mp4|\.m4v)$/i;
const ALLOWED_EXTENSIONS = /(\.jpg|\.jpeg|\.mp4|\.pdf|\.m4v)$/i;

const ResourceUpload = ({ handleChange, name, value, presignedUrl, ...props }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState(null);
  const [isMp4, setIsMp4] = useState(false);
  const toast = useToast();
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (value) {
      setIsMp4(
        MP4_EXTENSIONS.exec(
          value
            .substring(value.lastIndexOf('/') + 1)
            .split('?')[0]
            .toLowerCase()
        )
          ? true
          : false
      );
    }
  }, [value]);

  const handleDragEnter = useCallback(e => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback(e => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback(e => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  }, []);

  const handleDrop = useCallback(
    e => {
      e.preventDefault();
      setIsDragging(false);

      if (e.dataTransfer.files.length > 0) {
        setFile(e.dataTransfer.files[0]);
      }

      handleChange(e);
    },
    [handleChange]
  );

  const handleRemove = useCallback(() => {
    handleChange({ target: { name, value: null } });
  }, [handleChange]);

  const handleError = useCallback(() => {
    handleRemove();
    toast.addToast({
      position: 'top',
      type: 'warning',
      content: 'Invalid file type'
    });
  }, [toast, handleRemove]);

  const handleFileChange = useCallback(
    e => {
      if (e.target.files.length > 0) {
        const filePath = e.target.files[0];
        if (ALLOWED_EXTENSIONS.exec(filePath.name.toLowerCase())) {
          setFile(e.target.files[0]);
        } else {
          handleError();
        }
      }
    },
    [toast, handleError]
  );

  const handleBoxClick = useCallback(() => {
    if ('function' === typeof hiddenFileInput?.current?.click) {
      hiddenFileInput.current.click();
    }
  }, []);

  const fileAsUrl = useCallback(f => {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.onerror = reject;
      fr.readAsDataURL(f);
    });
  }, []);

  useEffect(() => {
    if (file) {
      fileAsUrl(file).then(url => {
        handleChange({ target: { name, value: url } });
      });
    }
  }, [file]);

  if (presignedUrl || value?.startsWith('data')) {
    return (
      <div className="uploader">
        {presignedUrl && isMp4 ? (
          <div style={{ marginBottom: 20 }}>
            <Player width={320} height={240} url={presignedUrl} controls playsinline />
          </div>
        ) : null}
        <Icon name="document" className="document-icon-uploaded" size={50} />
        <div className="uploader-buttons">
          {presignedUrl ? (
            <a target="_blank" rel="noopener noreferrer" href={presignedUrl}>
              <Button secondary micro>
                View File
              </Button>
            </a>
          ) : null}
          <Button handleButtonClick={handleRemove} secondary transparent micro disabled={props.disabled}>
            Remove
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(`uploader`, {
        dragging: isDragging
      })}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleBoxClick}
      disabled={props.disabled}
    >
      <div className="upload-file">
        <Icon name="upload" size={50} />
        <h3>Click (or tap) the dropzone to upload a resource</h3>
        <em>or drag and drop your file</em>
        <p>Possible file types: PDF, JPG, MP4</p>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileChange}
          onBlur={props.handleBlur}
          accept=".pdf,.mp4,.jpg,.jpeg,application/pdf,video/mp4"
          name={name}
        />
      </div>
    </div>
  );
};

export default ResourceUpload;
