// reducer reduces action and state -> state
export default function events(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_EVENTS':
      return {
        ...state,
        pending: true
      };
    case 'REMOVE_PENDING_EVENTS':
      return {
        ...state,
        pending: false
      };
    case 'LOAD_SUCCESSFUL_EVENTS':
      return {
        doneInitialLoad: true,
        pending: false,
        list: action.payload.events,
        hash: action.payload.hash
      };
    case 'LOAD_SUCCESSFUL_EVENT':
      return {
        doneInitialLoad: true,
        pending: false,
        list: updateInfo(state.list, action.payload.event),
        hash: action.payload.hash
      };
    case 'LOAD_PENDING_EVENT_TAGS':
      return {
        ...state,
        pending: true
      };
    case 'LOAD_SUCCESSFUL_EVENT_TAGS':
      return {
        pending: false,
        tags: action.payload.tags
      };
  }
  return state;
}

const updateInfo = (list = [], payload) => {
  const idx = list.findIndex(event => event.id === payload.id);
  if (idx < 0) {
    return [payload];
  } else {
    let newList = [...list.slice(0, idx), payload, ...list.slice(idx + 1)];

    return newList;
  }
};
