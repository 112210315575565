import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import moment from 'moment';

const CsvButton = ({ title, isDownloadDisabled, csvData, headers, handleCsv }) => {
  const filename = useMemo(() => {
    if (!title) return undefined;

    return `${title.replace(' ', '-')}-${moment().format('YYYYMMDDhhmmss')}.csv`;
  }, [title]);

  const tooltip = useMemo(() => {
    if (isDownloadDisabled) return 'No data available to produce a CSV';

    return `Download ${filename}`;
  }, [isDownloadDisabled, filename]);

  return (
    <Tooltip title={tooltip}>
      <span>
        <CSVLink
          data={csvData}
          headers={headers}
          filename={filename}
          style={{ pointerEvents: isDownloadDisabled ? 'none' : undefined }}
          disabled={isDownloadDisabled}
        >
          <IconButton onClick={handleCsv} disabled={isDownloadDisabled} aria-label="Download CSV" size="medium">
            <GetAppRoundedIcon />
          </IconButton>
        </CSVLink>
      </span>
    </Tooltip>
  );
};

export default CsvButton;
