import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';

const testsSelector = state => state.tests.list;

// this is likely way overkill for the number of tests we are dealing with. Oh well.
const testsLookup = createSelector(
  [testsSelector],
  tests => {
    if (!tests) {
      return false;
    }

    // look up
    return keyBy(tests, 'id');
  }
);

export default testsLookup;
