import isEqual from 'lodash/isEqual';

// isInteger polyfill
Number.isInteger =
  Number.isInteger ||
  function(value) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  };

const DataTableMixin = {
  getInitialState: function() {
    const perPage = this.props.perPage || 100;
    const currentPage = 0;
    const multiColumnFilters = this.props.multiColumnFilters || false;
    const multiColumnFiltersValue = this.props.multiColumnFilterValue || '';
    const columnFilters = this.props.columnFilters || {};

    const sort = this.props.sort || {};
    let filteredData = this.getFilteredData(
      this.props.data,
      multiColumnFilters,
      multiColumnFiltersValue,
      columnFilters
    );
    filteredData = this.getSortedData(filteredData, sort);

    const currentPageData = this.getCurrentPageData(filteredData, currentPage, perPage);
    const pages = this.getNumPages(filteredData, perPage);
    return {
      currentPageData,
      filteredData,
      pages,
      currentPage,
      perPage,
      multiColumnFilters,
      multiColumnFiltersValue,
      columnFilters,
      sort
    };
  },
  UNSAFE_componentWillReceiveProps: function(newProps) {
    // I could definitely see this causing trouble someday...
    if (newProps.dontUpdate) {
      return;
    }

    if (!isEqual(this.props.data, newProps.data)) {
      const multiColumnFilters = this.props.multiColumnFilters || this.state.multiColumnFilters;
      const multiColumnFiltersValue = this.props.multiColumnFiltersValue || this.state.multiColumnFiltersValue;

      const columnFilters = this.props.columnFilters || {};
      const currentPage = 0;

      const sort = this.props.sort || {};
      let filteredData = this.getFilteredData(
        newProps.data,
        multiColumnFilters,
        multiColumnFiltersValue,
        columnFilters
      );
      filteredData = this.getSortedData(filteredData, sort);

      const currentPageData = this.getCurrentPageData(filteredData, currentPage, this.state.perPage);
      const pages = this.getNumPages(filteredData, this.state.perPage);

      this.setState({
        filteredData,
        currentPageData,
        pages,
        currentPage,
        sort,
        multiColumnFilters,
        multiColumnFiltersValue,
        columnFilters
      });
    }
  },
  changePage: function(page) {
    // page/currentPage is zero indexed, just for fun.
    const perPage = this.state.perPage;
    this.setState({
      currentPage: page,
      currentPageData: this.getCurrentPageData(this.state.filteredData, page, perPage)
    });
  },
  handleFilter: function(multiColumnFiltersValue, columnFilters) {
    const multiColumnFilters = this.state.multiColumnFilters;
    let filteredData = this.getFilteredData(
      this.props.data,
      multiColumnFilters,
      multiColumnFiltersValue,
      columnFilters
    );
    filteredData = this.getSortedData(filteredData, this.state.sort);
    const pages = this.getNumPages(filteredData, this.state.perPage);
    const currentPageData = this.getCurrentPageData(filteredData, 0, this.state.perPage);

    this.setState({
      filteredData,
      multiColumnFilters,
      multiColumnFiltersValue,
      columnFilters,
      pages,
      currentPageData,
      currentPage: 0
    });
  },
  getFilteredData: function(data, multiColumnFilters, multiColumnFiltersValue, columnFilters = {}) {
    if (!multiColumnFilters && (!columnFilters || !Object.keys(columnFilters).length)) {
      return data.slice(0);
    }

    // prioritize multicolumn
    if (multiColumnFilters) {
      let searchTerm = multiColumnFiltersValue.toLowerCase();

      data = data.filter(function(obj) {
        let found = false;

        multiColumnFilters.forEach(function(col) {
          let needle = '' + obj[col];
          if (needle.toLowerCase().indexOf(searchTerm) > -1) {
            found = true;
          }
        });

        return found;
      });
    }

    // column filters
    let filteredData = data.filter(function(obj) {
      let cols = Object.keys(columnFilters);

      // we want to require that every column contains the given string
      // this is a mess. Probably terrible (...or brilliant, hoping for brilliant)
      return cols.every(function(col) {
        let contains = columnFilters[col];

        // let's check if what we are searching for is inside the array.
        if (Array.isArray(obj[col])) {
          let vals = obj[col];
          return vals.indexOf(contains) !== -1;
        }

        // strict search for boolean filters (or integers)
        if (contains === true || contains === false || Number.isInteger(contains)) {
          return obj[col] === contains;
        }

        // otherwise see if it contains the filter text
        if (!obj[col]) {
          return false;
        }
        return ('' + obj[col]).toLowerCase().indexOf(contains.toLowerCase()) > -1;
      });
    });

    return filteredData;
  },
  getCurrentPageData: function(filteredData, page, perPage) {
    return filteredData.slice(page * perPage, (page + 1) * perPage);
  },
  getNumPages: function(filteredData, perPage) {
    return Math.ceil(filteredData.length / perPage);
  },
  getSortedData: function(filteredData, sortBy) {
    const { name, type } = sortBy;
    if (!name || !type) {
      return filteredData;
    }

    // fully admit this is probably silly.
    const sortFunctionHelper = { asc: [-1, 1], desc: [1, -1] };

    return filteredData.splice(0).sort(
      function(clientA, clientB) {
        let compareA = clientA[name];
        let compareB = clientB[name];
        if (typeof compareA === 'string' && typeof compareB === 'string') {
          compareA = compareA.toLowerCase();
          compareB = compareB.toLowerCase();
        }

        // a bit hacky. Seems effective enough. Always compare strings would be the idea...
        compareA =
          compareA === null || compareA === false ? 'zzzzzzzzzzz' : compareA === true ? 'aaaaaaaaaaaaa' : compareA;
        compareB =
          compareB === null || compareB === false ? 'zzzzzzzzzzz' : compareB === true ? 'aaaaaaaaaaaaa' : compareB;

        if (compareA < compareB) {
          return sortFunctionHelper[type][0];
        } else if (compareA > compareB) {
          return sortFunctionHelper[type][1];
        } else if (this.props.defaultSort && this.props.defaultSort.name !== name) {
          // TODO: This is ripe for refactoring.
          let { name, type } = this.props.defaultSort;

          let compareDefaultA = clientA[name];
          let compareDefaultB = clientB[name];

          if (typeof compareDefaultA === 'string' && typeof compareDefaultB === 'string') {
            compareDefaultA = compareDefaultA.toLowerCase();
            compareDefaultB = compareDefaultB.toLowerCase();
          }

          compareDefaultA =
            compareDefaultA === null || compareDefaultA === false
              ? 'zzzzzzzzzzz'
              : compareDefaultA === true
              ? 'aaaaaaaaaaaaa'
              : compareDefaultA;
          compareDefaultB =
            compareDefaultB === null || compareDefaultB === false
              ? 'zzzzzzzzzzz'
              : compareDefaultB === true
              ? 'aaaaaaaaaaaaa'
              : compareDefaultB;

          return compareDefaultA < compareDefaultB ? sortFunctionHelper[type][0] : sortFunctionHelper[type][1];
        } else {
          // Doesn't really matter;
          return 1;
        }
      }.bind(this)
    );
  },
  sortColumn: function(sortBy) {
    const newFiltered = this.getSortedData(this.state.filteredData, sortBy);
    const newPage = this.getCurrentPageData(newFiltered, this.state.currentPage, this.state.perPage);

    this.setState({
      sort: sortBy,
      filteredData: newFiltered,
      currentPageData: newPage
    });
  },
  updatePageLength: function(perPage) {
    const currentPageData = this.getCurrentPageData(this.state.filteredData, 0, perPage);
    const pages = this.getNumPages(this.state.filteredData, perPage);

    this.setState({ currentPageData, pages, perPage, currentPage: 0 });
  },
  updateColumnFilters: function(newColumnFilters) {
    this.handleFilter(this.state.multiColumnFiltersValue, newColumnFilters);
  },
  updateMultiColumnFiltersValue: function(value) {
    this.handleFilter(value, this.state.columnFilters);
  }
};

export default DataTableMixin;
