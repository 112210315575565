/* eslint-disable react/display-name */
/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import ErrorOrConfirm from 'components/ErrorOrConfirm';
import { RenderChecks } from 'components/FormProvider/renderChecks';

export const schema = sent => [
  {
    header: 'Share Your Feedback!',
    info: () => (
      <>
        <p>
          We&apos;ve been hard at work building the new and improved Reconnect.
          What do you think? Let us know what you do and don&apos;t like, and
          any suggestions you may have to help us build a better experience for
          you.
        </p>
        {!!sent && (
          <ErrorOrConfirm type="confirmation">
            Thanks for your feedback. We appreciate it and will be in touch
            soon!
          </ErrorOrConfirm>
        )}
      </>
    ),
    blockGroup: 'feedbackForm',
    children: [
      {
        Component: 'textarea',
        rows: 10,
        name: 'feedback'
      }
    ]
  }
];

export const actionButtons = [
  {
    type: 'submit',
    primary: true,
    label: 'Submit',
    render: true,
    renderChecks: [
      RenderChecks.hasChanged,
    ]
  },
  {
    type: 'button',
    primary: true,
    transparent: true,
    label: 'Cancel',
    action: 'reset',
    render: true,
    renderChecks: [
      RenderChecks.hasChanged,
    ]
  }
];
