/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import { GetTestName } from '../helpers';
import SinglePercentTest from './SinglePercentTest';

/* eslint-disable */
const PercentTests = ({ selectedProfile, removeTest, saveTest, testTypes }) => {
  const testIDs = Object.keys(selectedProfile.tests);

  if (!testIDs.length) return null;
  
  return (
    <>
      {testIDs.map(test_id => {
        const test = selectedProfile.tests[test_id];
        <SinglePercentTest 
          percentage={test.percentage}
          name={GetTestName(testTypes, test_id)}
          test_id={test_id}
          removeTest={removeTest}
          saveTest={saveTest}
        />
      })}
    </>
  );
};

PercentTests.propTypes = {
  selectedProfile: PropTypes.object.isRequired,
  removeTest: PropTypes.func.isRequired,
  saveTest: PropTypes.func.isRequired,
  testTypes: PropTypes.array.isRequired
}

export default PercentTests;
