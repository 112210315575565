import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import calendars from './calendarsReducer';
import clients from './clientsReducer';
import communityEvent from './communityEventReducer';
import curfewEvent from './curfewEventReducer';
import curfewEvents from './curfewEventsReducer';
import drawer from './drawerReducer';
import events from './eventsReducer';
import facility from './facilityReducer';
import features from './featuresReducer';
import formEvents from './formEventReducer';
import locations from './locationsReducer';
import messages from './messagesReducer';
import tests from './testsReducer';
import user from './userReducer';
import users from './usersReducer';
import profiles from './profilesReducer';
import groups from './groupsReducer';
import todaysReport from './todaysReportReducer';
import todaysDate from './todaysDateReducer';
import ui from './uiReducer';
import windowReport from './windowReportReducer';
import windows from './windowsReducer';
import violations from './violationsReducer';
import termsOfService from './termsOfService/termsOfServiceReducer';
import dashboard from './dashboard/dashboardReducer';
import toasts from './toastsReducer';
import programs from './selfpayProgramReducer';
import benchmark from './benchmarksReducer';
import documentLibrary from './documentLibraryReducer';
import curriculum from './curriculumReducer';

const rootReducer = history =>
  combineReducers({
    calendars,
    clients,
    communityEvent,
    curfewEvent,
    curfewEvents,
    events,
    facility,
    features,
    formEvents,
    programs,
    drawer,
    locations,
    messages,
    groups,
    tests,
    user,
    users,
    profiles,
    todaysReport,
    todaysDate,
    ui,
    windowReport,
    windows,
    violations,
    termsOfService,
    dashboard,
    toasts,
    benchmark,
    documentLibrary,
    curriculum,
    router: connectRouter(history)
  });

export default rootReducer;
