import React from 'react';
import PropTypes from 'prop-types';

import { getToRender } from 'components/FormProvider/utils';

import Checkbox from 'components/Checkbox';

import './style.scss';

const CheckboxGroup = ({
  groupLabel,
  options,
  handleChange,
  className,
  disabled,
  blockGroup,
  getStateValue,
  wrapperProps = {},
  children = null
}) => {
  return (
    <div className={`base-checkbox-group ${className}`}>
      <fieldset className="checkbox-row">
        <legend>{groupLabel}</legend>
        <div className="checkboxes" {...wrapperProps}>
          {children}
          {options.map((o, i) => {
            if (o.renderChecks) {
              const canRender = getToRender(o.renderChecks, blockGroup, getStateValue);
              if (!canRender) {
                return null;
              } else {
                delete o.renderChecks;
              }
            }
            return <Checkbox key={`checkbox-${i}`} {...o} disabled={disabled} handleChange={handleChange} />;
          })}
        </div>
      </fieldset>
    </div>
  );
};

CheckboxGroup.propTypes = {
  getStateValue: PropTypes.func,
  group: PropTypes.string,
  groupLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  blockGroup: PropTypes.string
};

export default CheckboxGroup;
