import React from 'react';

const TableLink = ({ href }) => {
  if (href) {
    return (
      <a href={href} target="__blank" rel="noopener noreferrer">
        View
      </a>
    );
  }

  return '-';
};

export default TableLink;
