import React from 'react';
import classnames from 'classnames';
import './Loading.scss';

export default function Loading({ className, compact, ready, overlay, children }) {
  if (ready) return null;
  if (!overlay) {
    return (
      <div
        className={classnames('loading-spinner', className, {
          'compact-spinner': !!compact
        })}
      >
        <img src="/static/img/spin.gif" />
      </div>
    );
  }

  return (
    <div
      className={classnames('flex flex-col justify-center items-center', {
        'spinner-overlay': !!overlay
      })}
    >
      <div>{children}</div>
      <div className={classnames('loading-spinner', className, 'compact-spinner')} style={{ position: 'relative' }}>
        {/*the gif doesn't do well over a background*/}
        <svg className={'svg-spinner'} viewBox={'-54 -54 108 108'} style={{ width: 108, height: 108, zIndex: 10 }}>
          <circle className={'dot-1'} r={5} cx={0} cy={-36} fill={'#D5DFE4'}></circle>
          <circle className={'dot-2'} r={5} cx={26} cy={-26} fill={'#D5DFE4'}></circle>
          <circle className={'dot-3'} r={5} cx={36} cy={0} fill={'#D5DFE4'}></circle>
          <circle className={'dot-4'} r={5} cx={26} cy={26} fill={'#D5DFE4'}></circle>
          <circle className={'dot-5'} r={5} cx={0} cy={36} fill={'#D5DFE4'}></circle>
          <circle className={'dot-6'} r={5} cx={-26} cy={26} fill={'#D5DFE4'}></circle>
          <circle className={'dot-7'} r={5} cx={-36} cy={0} fill={'#D5DFE4'}></circle>
          <circle className={'dot-8'} r={5} cx={-26} cy={-26} fill={'#D5DFE4'}></circle>
        </svg>
      </div>
    </div>
  );
}
