/**
 * External dependencies
 */
import merge from 'lodash/merge';

// reducer reduces action and state -> state
export default function locations(state = {}, action) {
  switch (action.type) {
    case 'LOAD_PENDING_LOCATIONS':
      return {
        ...state,
        pending: true
      };
    case 'REMOVE_PENDING_LOCATIONS':
      return {
        ...state,
        pending: false
      };
    case 'LOAD_SUCCESSFUL_LOCATIONS':
      return {
        ...state,
        doneInitialLoad: true,
        pending: false,
        list: action.payload.locations,
        hash: action.payload.hash
      };
    case 'LOAD_PENDING_LOCATION_ADD':
      return {
        ...state,
        isAddingLocation: true,
        updatedLocation: null
      };
    case 'REMOVE_PENDING_LOCATION_ADD':
      return {
        ...state,
        isAddingLocation: false,
        updatedLocation: null
      };
    case 'LOAD_SUCCESSFUL_LOCATION_ADD':
      return merge({}, state, {
        isAddingLocation: false,
        list: action.payload.error
          ? state.list
          : [...state.list, action.payload.location],
        updatedLocation: {
          status: action.payload.status,
          error: action.payload.error ?? null,
          locationId: action.payload.location?.id ?? null,
          locationName: action.payload.location?.name ?? null
        }
      });
    case 'LOAD_PENDING_LOCATION_UPDATE':
      return {
        ...state,
        isUpdatingLocation: true,
        updatedLocation: null
      };
    case 'REMOVE_PENDING_LOCATION_UPDATE':
      return {
        ...state,
        isUpdatingLocation: false,
        updatedLocation: null
      };
    case 'LOAD_SUCCESSFUL_LOCATION_UPDATE':
      return merge({}, state, {
        isUpdatingLocation: false,
        list: state.list.map(location =>
          location.id !== action.payload.location.id
            ? location
            : action.payload.location
        ),
        updatedLocation: {
          status: action.payload.status,
          error: action.payload.error ?? null,
          locationId: action.payload.location?.id ?? null,
          locationName: action.payload.location?.name ?? null
        }
      });
    case 'LOAD_PENDING_LOCATION_REMOVE':
      return {
        ...state,
        isRemovingLocation: true,
        updatedLocation: null
      };
    case 'REMOVE_PENDING_LOCATION_REMOVE':
      return {
        ...state,
        isRemovingLocation: false,
        updatedLocation: null
      };
    case 'LOAD_SUCCESSFUL_LOCATION_REMOVE':
      return {
        ...state,
        isRemovingLocation: false,
        updatedLocation: {
          status: action.payload.status,
          error: action.payload.error ?? null
        },
        list: state.list.filter(
          location => location.id !== action.payload.locationId
        )
      };
    case 'LOAD_PENDING_STATES':
      return {
        ...state,
        isRequestingStates: true
      };
    case 'LOAD_SUCCESSFUL_STATES':
      return {
        ...state,
        isRequestingStates: false,
        states: action.payload.states
      };
    case 'LOAD_PENDING_COUNTIES':
      return merge({}, state, {
        states: {
          [action.payload.stateId]: {
            isRequestingCounties: true
          }
        }
      });
    case 'LOAD_SUCCESSFUL_COUNTIES':
      return merge({}, state, {
        states: {
          [action.payload.stateId]: {
            isRequestingCounties: false,
            counties: action.payload.counties
          }
        }
      });
    case 'LOAD_PENDING_MAPSLIB':
      return {
        ...state,
        mapsLibStatus: 'loading'
      };
    case 'LOAD_SUCCESSFUL_MAPSLIB':
      return {
        ...state,
        mapsLibStatus: 'ready'
      };
  }
  return state;
}
