import { useMemo } from 'react';

const testingProfilesPath = '/settings/testing_profiles';
const messagesListPath = '/messages/list';

const useLiClassName = ({ liClassName, mediumUp, small, pathname, to }) => {
  return useMemo(() => {
    if ('' === liClassName) {
      if (mediumUp) {
        liClassName = 'show-for-medium-up';
      }
      if (small) {
        liClassName = 'show-for-small-only';
      }
    }

    if (
      pathname.startsWith(to) ||
      (pathname.startsWith(testingProfilesPath) && to.startsWith(testingProfilesPath)) ||
      (to.includes(messagesListPath) && /messages(\/list|\/user)/.test(pathname)) ||
      (to.startsWith('/communications') && pathname.startsWith('/communications')) ||
      (to.startsWith('/events') && pathname.startsWith('/events')) ||
      (to.startsWith('/reports/') &&
        /\/reports\/(clients|client_overview|detailed|single(\/?(\d{4}-\d{2}-\d{2})?\/\d.*)?|transcript|app_logins|incentives_and_sanctions|client_app_session_timer|bulk_message_history)/.test(
          pathname
        ))
    ) {
      liClassName += ' active';
    }

    return liClassName;
  }, [liClassName, mediumUp, small, pathname, to]);
};

export default useLiClassName;
