import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import Label from 'components/Label';
import Select from 'react-select';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Button from 'components/Button';
import { createOption } from '../ClientsGet/Info/BasicInfo/Schemas/helpers';

import './style.scss';

const EMPTY_ADDRESS = {
  street: '',
  unit: '',
  city: '',
  state: '',
  zip: ''
};

const STATES = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
].map(state => createOption(state));

const Addresses = ({ handleChange, name, value, multiple, ...props }) => {
  value = value && value.length ? value : [cloneDeep(EMPTY_ADDRESS)];
  multiple = multiple ?? false;

  const handleRemove = index => {
    const newValue = cloneDeep(value).filter((_, i) => i !== index);
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleInputChange = (e, index, key) => {
    let {
      target: { value: stringValue }
    } = e;

    const newObj = value[index];
    newObj[key] = stringValue;
    const newValue = cloneDeep(value).map((p, i) => (i === index ? newObj : p));
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleStateChange = (state, index) => {
    value[index];
    const newObj = value[index];
    newObj.state = state;
    const newValue = cloneDeep(value).map((p, i) => (i === index ? newObj : p));
    const event = { target: { name, value: newValue } };
    handleChange(event, props.blockGroup);
  };

  const handleButtonClick = () => {
    const newValue = cloneDeep(value);
    newValue.push(cloneDeep(EMPTY_ADDRESS));
    const e = { target: { name, value: newValue } };
    handleChange(e, props.blockGroup);
  };

  return (
    <div className="addresses-multi-input-container">
      <h3>{props.label}</h3>
      {value.map((v, index) => {
        return (
          <div key={index} className="addresses-single">
            <div className="row">
              <div>
                <div className="row">
                  <div className="street">
                    <Label htmlFor={`${name}_street_${index}`} bold>
                      Street
                    </Label>
                    <Input
                      name={`${name}_street_${index}`}
                      value={v.street}
                      handleChange={e => handleInputChange(e, index, 'street')}
                    />
                  </div>
                  <div className="unit">
                    <Label htmlFor={`${name}_status_${index}`} bold>
                      Unit #
                    </Label>
                    <Input
                      name={`${name}_unit_${index}`}
                      value={v.unit}
                      handleChange={e => handleInputChange(e, index, 'unit')}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="city">
                    <Label htmlFor={`${name}_city_${index}`} bold>
                      City
                    </Label>
                    <Input
                      name={`${name}_city_${index}`}
                      value={v.city}
                      handleChange={e => handleInputChange(e, index, 'city')}
                    />
                  </div>
                  <div className="state">
                    <Label htmlFor={`${name}_state_${index}`} bold>
                      State
                    </Label>
                    <Select
                      name={`${name}_state_${index}`}
                      className="selector"
                      value={STATES.filter(o => v.state == o.value)}
                      onChange={({ value }) => handleStateChange(value, index)}
                      options={STATES}
                    />
                  </div>
                  <div className="zip">
                    <Label htmlFor={`${name}_zip_${index}`} bold>
                      Zip
                    </Label>
                    <Input
                      name={`${name}_zip_${index}`}
                      value={v.zip}
                      handleChange={e => handleInputChange(e, index, 'zip')}
                    />
                  </div>
                </div>
              </div>
              {multiple && (
                <div className="remove-box">
                  <span onClick={() => handleRemove(index)}>
                    <Icon name="minus" />
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      })}
      {multiple && (
        <Button
          secondary
          transparent
          micro
          handleButtonClick={handleButtonClick}
          disabled={value.length ? value.some(
                  v =>
                    v.name === '' ||
                    v.city === '' ||
                    v.state === '' ||
                    v.zip === ''
                ) : false}
        >
          Add a New Address
        </Button>
      )}
    </div>
  );
};

Addresses.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array
};

export default Addresses;
