class ChartCompletion {
  constructor(value, completed) {
    this.value = value;
    this.completed = completed;
    this.#setTitle(completed);
    this.#setColor(completed);
  }

  #setTitle(completed) {
    const filler = completed ? '' : 'not ';

    this.title = `${this.value} clients have ${filler}completed this resource. Click to show only assignees that have ${filler}completed this resource.`;
  }

  #setColor(completed) {
    if (completed) {
      this.color = '#6CC049';
    } else {
      this.color = '#F4443F';
    }
  }
}

export default ChartCompletion;