import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get, isEqual, isUndefined } from 'lodash';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';

import DebouncedInput from 'components/Curriculum/Table/DebouncedInput';
import ResourceClient from 'components/Curriculum/Assignment/Completions/ResourceClient';
import CompletionPie from 'components/Curriculum/Assignment/Completions/CompletionPie';
import DurationBar from 'components/Curriculum/Assignment/Completions/DurationBar';
import CompletionHeader from 'components/Curriculum/Assignment/Completions/CompletionHeader';
import AssigneesHeader from 'components/Curriculum/Assignment/Completions/AssigneesHeader';
import { getClientsForResource } from 'selectors/curriculumSelectors';
import ChartDurations, { ChartDuration } from 'utils/ChartDuration';
import ChartCompletion from 'utils/ChartCompletion';
import GenericBuilder from 'utils/GenericBuilder';
import useScrollTo from 'components/Curriculum/hooks/useScrollTo';
import './style.scss';

const CompletionSection = ({
  title,
  id,
  start_date,
  due_in_days,
  order,
  isTrackingClientCompletions = true,
  ...rest
}) => {
  const engagements = useSelector(getClientsForResource(rest?.engagements ?? []));
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  const { pathname } = useLocation();
  const [ref, setShouldScrollTo] = useScrollTo({ behavior: 'smooth', block: 'center' }, { threshold: 1, rootMargin: '1px' });

  const isBulkContext = useMemo(() => pathname === '/learning-lab/assignments', [pathname]);

  const { clients, total, completed } = useMemo(() => {
    return {
      clients: orderBy(engagements?.clients ?? [], ['date_completed', 'duration'], ['asc', 'desc']),
      total: engagements?.total ?? 0,
      completed: engagements?.completed ?? 0
    };
  }, [engagements]);

  useEffect(() => {
    setFilteredClients(clients);
  }, []);

  const handleSearch = useCallback(
    value => {
      setSearchTerm(value);

      if (!value) {
        setFilteredClients(clients);
      }

      const newClients = [];

      for (const client of clients) {
        if (client.name.toLowerCase().includes(value.toLowerCase())) {
          newClients.push(client);
        }
      }

      setFilteredClients(newClients);
    },
    [clients]
  );

  const handleFilter = useCallback(
    ({ activePayload, payload }) => {
      if (!isUndefined(activePayload)) {
        const value = get(activePayload, '0.payload', {});
        const newClients = clients.filter(c => c.duration >= value.min && c.duration <= value.max);

        setFilteredClients(newClients);
      } else if (!isUndefined(payload)) {
        const completed = get(payload, 'payload.completed', false);
        const newClients = clients.filter(c => c.completed === completed);

        setFilteredClients(newClients);
      }

      setShouldScrollTo(true);
    },
    [clients, setShouldScrollTo]
  );

  const handleResetFilters = useCallback(() => setFilteredClients(clients), []);

  const completionProgress = useMemo(() => {
    return new GenericBuilder()
      .addItems([
        new ChartCompletion(total - completed, false),
        new ChartCompletion(completed, true)
      ]).items;
  }, [total, completed]);

  const durationsData = useMemo(() => {
    if (!Array.isArray(clients) || !clients.length) {
      return [];
    }

    return new ChartDurations()
      .addItems([
        new ChartDuration('0', 0, 0),
        new ChartDuration('5', 1, 299),
        new ChartDuration('10', 300, 599),
        new ChartDuration('15', 600, 899),
        new ChartDuration('20', 900, 1199),
        new ChartDuration('25', 1200, 1499),
        new ChartDuration('30', 1500, Infinity)
      ])
      .evaluateDurations(clients).items;
  }, [clients]);

  const isResetDisabled = useMemo(() => isEqual(filteredClients, clients), [filteredClients, clients]);

  const engagementData = useMemo(() => {
    if (!isBulkContext) {
      return null;
    }

    if (completed > 0) {
      return (
        <>
          {isTrackingClientCompletions ? <CompletionPie data={completionProgress} handleFilter={handleFilter} /> : null}
          <DurationBar data={durationsData} handleFilter={handleFilter} />
        </>
      );
    }

    const plural = isTrackingClientCompletions ? 's are' : ' is';

    return (
      <p className="completions__container--item__warning">
        {isTrackingClientCompletions ? <span><strong>Completion Progress</strong> and </span> : ''}<strong>Time Engaged with Resource</strong> chart{plural} unavailable
        until assignee engagement data has been logged for this resource
      </p>
    );
  }, [isBulkContext, completed, completionProgress, handleFilter, durationsData, isTrackingClientCompletions]);

  return (
    <div className="completions__container--item" key={id}>
      <CompletionHeader title={title} order={order} start_date={start_date} due_in_days={due_in_days} />
      {engagementData}
      {clients.length && isBulkContext ? (
        <AssigneesHeader handleButtonClick={handleResetFilters} isDisabled={isResetDisabled} />
      ) : null}
      {clients.length > 10 ? (
        <DebouncedInput value={searchTerm} onChange={handleSearch} placeholder="Search assignees..." />
      ) : null}
      {clients.length ? (
        <div className="completions__container--item__scrollable" ref={ref}>
          {filteredClients.map(c => (
            <ResourceClient key={c.id} client={c} isTrackingClientCompletions={isTrackingClientCompletions} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CompletionSection;
