/**
 * External dependencies
 */
import React, { useMemo } from 'react';

/**
 * Internal dependencies
 */
import Checkmark from '../../img/checkmark.svg';
import Crossmark from '../../img/x.svg';
import Cross from '../../img/cross.svg';
import Warning from '../../img/warning.svg';
import ChevronRight from '../../img/chevron-right.svg';
import ChevronLeft from '../../img/chevron-left.svg';
import Marker from '../../img/pin.svg';
import MarkerSolid from '../../img/pin-solid-dotted.svg';
import Close from '../../img/close.svg';
import Play from '../../img/play.svg';
import LocationSpoofed from '../../img/location-spoofed.svg';
import LocationClientSpoofed from '../../img/location-client-marker.svg';
import LocationEventMarker from '../../img/location-event-marker.svg';
import LocationInclusionZoneMarker from '../../img/location-inclusion-zone-marker.svg';
import LocationExclusionZoneMarker from '../../img/location-exclusion-zone-marker.svg';
import ProfileDark from '../../img/profile-dark.svg';
import User from '../../img/user.svg';
import TriangleBottom from '../../img/triangle-bottom.svg';
import TriangleTop from '../../img/triangle-top.svg';
import Minus from '../../img/minus.svg';
import Search from '../../img/search.svg';
import Clients from '../../img/clients.svg';
import RemoveClient from '../../img/remove-client.svg';
import DashboardIcon from '../../img/dashboard.svg';
import MessagesIcon from '../../img/messages.svg';
import Notes from '../../img/notes.svg';
import Reports from '../../img/reports.svg';
import Settings from '../../img/settings.svg';
import Support from '../../img/support.svg';
import ViolationsIcon from '../../img/violations.svg';
import ChevronUp from '../../img/chevron-up.svg';
import ChevronDown from '../../img/chevron-down.svg';
import Send from '../../img/send.svg';
import Pending from '../../img/pending.svg';
import Edit from '../../img/edit.svg';
import Calendar from '../../img/calendar.svg';
import QuestionMark from '../../img/question-mark.svg';
import Check from '../../img/check.svg';
import Print from '../../img/print.svg';
import View from '../../img/view.svg';
import AlertAdd from '../../img/alert-add.svg';
import ToggleCheckmark from '../../img/toggle-checkmark.svg';
import Trash from '../../img/trash.svg';
import MissedCall from '../../img/missed-call.svg';
import OutgoingCall from '../../img/outgoing-call.svg';
import Phone from '../../img/phone.svg';
import Muted from '../../img/muted.svg';
import Mute from '../../img/mute.svg';
import CameraOn from '../../img/camera-on.svg';
import CameraOff from '../../img/camera-off.svg';
import HangUp from '../../img/hang-up.svg';
import Pause from '../../img/pause.svg';
import Stop from '../../img/stop.svg';
import Assignment from '../../img/assignment.svg';
import AppActive from '../../img/app-active.svg';
import AppUnknown from '../../img/app-unknown.svg';
import AppInactive from '../../img/app-inactive.svg';
import AppNoDevice from '../../img/app-no-device.svg';
import CallDeclined from '../../img/call-declined.svg';
import Forms from '../../img/forms.svg';
import Error from '../../img/error.svg';
import Events from '../../img/events.svg';
import Benchmarks from '../../img/benchmarks.svg';
import Document from '../../img/document.svg';
import Upload from '../../img/upload-doc.svg';
import Book from '../../img/learning-lab.svg';
import Infinite from '../../img/infinite.svg';
import Drag from '../../img/drag.svg';
import Replay from '../../img/replay.svg';
import './style.scss';

const getSVG = name => {
  switch (name) {
    case 'marker':
      return Marker;
    case 'marker-solid':
      return MarkerSolid;
    case 'checkmark':
    case 'excused':
    case 'Early':
    case 'Checked In':
    case 'checked':
      return Checkmark;
    case 'crossmark':
    case 'confirmed':
    case 'Out of Bounds, Late':
    case 'Late':
    case 'Out of Bounds':
    case 'Failed to Check In':
    case 'missed':
    case 'no check':
      return Crossmark;
    case 'warning':
    case 'open':
    case 'pending':
    case 'Pending':
    case 'late':
      return Warning;
    case 'chevron-right':
      return ChevronRight;
    case 'chevron-left':
      return ChevronLeft;
    case 'close':
      return Close;
    case 'uploaded':
    case 'play':
      return Play;
    case 'profile-dark':
      return ProfileDark;
    case 'location-spoofed':
      return LocationSpoofed;
    case 'location-client-marker':
      return LocationClientSpoofed;
    case 'location-event-marker':
      return LocationEventMarker;
    case 'location-inclusion-zone-marker':
      return LocationInclusionZoneMarker;
    case 'location-exclusion-zone-marker':
      return LocationExclusionZoneMarker;
    case 'user':
      return User;
    case 'triangle-bottom':
      return TriangleBottom;
    case 'triangle-top':
      return TriangleTop;
    case 'minus':
      return Minus;
    case 'search':
      return Search;
    case 'clients':
      return Clients;
    case 'remove-client':
      return RemoveClient;
    case 'dashboard':
      return DashboardIcon;
    case 'messages':
      return MessagesIcon;
    case 'notes':
      return Notes;
    case 'reports':
      return Reports;
    case 'settings':
      return Settings;
    case 'support':
      return Support;
    case 'violations':
      return ViolationsIcon;
    case 'chevron-up':
      return ChevronUp;
    case 'chevron-down':
      return ChevronDown;
    case 'send':
      return Send;
    case 'active':
      return Pending;
    case 'edit':
      return Edit;
    case 'calendar':
      return Calendar;
    case 'question-mark':
      return QuestionMark;
    case 'check':
      return Check;
    case 'cross':
      return Cross;
    case 'alert-add':
      return AlertAdd;
    case 'view':
      return View;
    case 'print':
      return Print;
    case 'toggle-checkmark':
      return ToggleCheckmark;
    case 'unanswered':
      return MissedCall;
    case 'outgoing':
      return OutgoingCall;
    case 'phone':
      return Phone;
    case 'muted':
      return Muted;
    case 'mute':
      return Mute;
    case 'camera-on':
      return CameraOn;
    case 'camera-off':
      return CameraOff;
    case 'hang-up':
      return HangUp;
    case 'pause':
      return Pause;
    case 'stop':
      return Stop;
    case 'assignment':
      return Assignment;
    case 'app-status-indicator green':
      return AppActive;
    case 'app-status-indicator yellow':
      return AppUnknown;
    case 'app-status-indicator red':
      return AppInactive;
    case 'app-status-indicator gray':
      return AppNoDevice;
    case 'declined':
      return CallDeclined;
    case 'forms':
      return Forms;
    case 'trash':
      return Trash;
    case 'error':
      return Error;
    case 'events':
      return Events;
    case 'benchmarks':
      return Benchmarks;
    case 'document':
      return Document;
    case 'upload':
      return Upload;
    case 'book':
      return Book;
    case 'infinite':
      return Infinite;
    case 'drag':
      return Drag;
    case 'replay':
      return Replay;
    default:
      return null;
  }
};

const Icon = ({ name, size, className, ...props }) => {
  const RenderedIcon = useMemo(() => getSVG(name), [name]);

  const classes = useMemo(() => {
    let baseClass = 'svgicon';
    if (name) {
      baseClass += ` svgicon-${name}`;
    }

    if (className) {
      baseClass += ` ${className}`;
    }

    return baseClass;
  }, [name, className]);

  if (RenderedIcon) {
    return <RenderedIcon width={size ? size : 16} className={classes} {...props} />;
  }

  return null;
};

export default Icon;
