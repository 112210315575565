import { RenderChecks } from 'components/FormProvider/renderChecks';

export function testingFields(props) {
  const { options, hasMultiCalendar, handleWarningModal, userEditClient, withSaveButton, disableCheckIn } = props;

  const fields = [
    {
      name: 'testing_profile',
      label: 'Testing Profile',
      type: 'select',
      required: true,
      options: options.profileOptions,
      disabled: !userEditClient
    },
    {
      name: 'call_in_window',
      label: 'Check-In Window',
      type: 'select',
      required: true,
      options: options.windowOptions,
      disabled: !userEditClient
    },
    {
      name: 'calendar',
      label: 'Calendar',
      required: true,
      type: !hasMultiCalendar ? 'text' : 'select',
      options: options.calendarOptions.map(o => ({
        ...o,
        callback: handleWarningModal
      })),
      disabled: !hasMultiCalendar || !userEditClient
    }
  ];
  if (withSaveButton) {
    fields.push({
      label: 'Save Changes',
      type: 'simple-save-button'
    });
  }
  disableCheckIn ? fields.splice(1, 1) : fields;
  return fields
}

/**
 * Renders action buttons based on context and permissions.
 *
 * @param {bool} isBasicInfoEdit Whether this is the Basic Info edit form
 * @param {bool} requireClientId Whether the facility requires a client Id
 * @returns {array}
 */
export const actionButtons = (client, features, saveAndActivateCallback) => {
  const noErrors = RenderChecks.hasNoErrors
  const hasChanged = RenderChecks.hasChanged
  const save = {
    primary: true,
    type: 'submit',
    label: 'Save',
    render: true,
    renderChecks: [noErrors, hasChanged]
  };
  const cancel = {
    primary: true,
    transparent: true,
    type: 'button',
    label: 'Cancel',
    render: true,
    action: 'reset',
    renderChecks: [hasChanged]
  };

  const saveAndActivate = {
    primary: true,
    type: 'button',
    label: 'Save and Activate',
    render: !client.active,
    renderChecks: [noErrors],
    callback: saveAndActivateCallback
  };

  if (features.hasDailyCheckins) {
    saveAndActivate.renderChecks.push(
      {
        group: 'clientTestingInfo',
        key: 'testing_profile',
        method: 'isTruthy'
      },
      {
        group: 'clientTestingInfo',
        key: 'call_in_window',
        value: true,
        method: 'isDefined'
      },
      {
        group: 'clientTestingInfo',
        key: 'calendar',
        value: true,
        method: 'isDefined'
      }
    );
  }

  if (features.requireClientId) {
    save.renderChecks.push({
      key: 'client_id',
      group: 'clientProfileInfo',
      value: '',
      method: 'notEmpty'
    });
  } else {
    save.renderChecks.push(
      {
        key: 'first_name',
        group: 'clientProfileInfo',
        value: '',
        method: 'notEmpty'
      },
      {
        key: 'last_name',
        group: 'clientProfileInfo',
        value: '',
        method: 'notEmpty'
      }
    );
  }

  return { saveAndActivate, save, cancel };
};

export const numberSequenceOptions = (min, max) => {
  const options = [];
  for (let i = min; i <= max; i++) {
    options.push(createOption(i));
  }
  return options;
};

export class SelectOption {
  constructor(label, value, defaultSelected = null, isDisabled = false, hidden = false) {
    this.label = label;
    this.value = value;
    this.defaultSelected = this.label === defaultSelected;
    this.isDisabled = isDisabled;
    this.hidden = hidden;
  }
}

export class RadioOption extends SelectOption {
  constructor(label, value, defaultSelected = null, isDisabled = false, hidden = false) {
    super(label, value, defaultSelected, isDisabled, hidden)
  }
}

export class RangeOption extends RadioOption {
  constructor(label, value, range) {
    super(`${label} (${range.label})`, value);
    this.range = range
  }
}

export const createOption = (label, defaultSelected = null, isDisabled = false, hidden = false) => {
  return new RadioOption(label, label, defaultSelected, isDisabled, hidden);
};

export const createOptionsFromStringList = (string, separator = ',', defaultSelected = null, isDisabled = false, hidden = false) => {
  return string.split(separator).sort().map(o => createOption(o.trim(), defaultSelected, isDisabled, hidden));
}

export function numberedRadioOptions(...values) {
  return values.map((v, i) => new RadioOption(v, i));
}
