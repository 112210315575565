/**
 * Internal dependencies
 */
import { loadPending, loadSuccessful } from './helperActions';
import {
  requestFacilityForms as requestFacilityFormsAPI,
  catchErrors
} from 'utils/apiHelpers';

/**
 * Dispatch form request actions and initiate data fetching.
 */
export function requestFacilityForms() {
  return async dispatch => {
    dispatch(loadPending('FACILITY_FORMS'));
    return requestFacilityFormsAPI()
      .then(res => {
        dispatch(loadSuccessful('FACILITY_FORMS', res.data));

        return res;
      })
      .catch(catchErrors);
  };
}

export const setFacility = (facility, features, access_level) => {
  return {
    type: 'SET_FACILITY_DATA',
    payload: { facility, features, access_level }
  };
};

export const updateBillingContactAction = billing_contact => {
  return {
    type: 'UPDATE_BILLING_CONTACT',
    payload: { billing_contact }
  };
};

export const updateEmailNotifications = (reportsNotifications, scheduleNotifications) => {
  return {
    type: 'UPDATE_EMAIL_NOTIFICATIONS',
    payload: { reportsNotifications, scheduleNotifications }
  };
};

export const updateMaxTestsPerDay = maxTestsPerDay => {
  return {
    type: 'UPDATE_MAX_TESTS_PER_DAY',
    payload: { maxTestsPerDay }
  };
};

export const updateCustomMessages = customMessages => {
  return {
    type: 'UPDATE_CUSTOM_MESSAGES',
    payload: { customMessages }
  };
};

export const updateReducedDayWeight = weight => {
  return {
    type: 'UPDATE_REDUCED_DAY_WEIGHT',
    payload: { weight }
  };
};

export const updateBreathalyzerThreshold = threshold => {
  return {
    type: 'UPDATE_BREATHALYZER_THRESHOLD',
    payload: { threshold }
  };
};
