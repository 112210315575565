import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { Subject } from 'rxjs';

import { ControlTypes } from 'components/Form/Model/ControlTypes';


export class SchemaManager {
  state$ = new Subject()

  constructor(schema) {
    this.schema = schema
    this.controls = []
    this.controlsMap = {}

    for (const block of this.schema) {
      this.processChildren(block, block.children);
    }
  }

  getControl(key) {
    return this.controlsMap[key]
  }

  processChildren(block, children) {
    if (!children?.length) return
    const configMap = ControlTypes.map()
    children = children.flat()
    for (const child of children) {
      child._isInput = !!child.name;
      child._block = block;
      child._blockKey = block.blockGroup;
      child._key = SchemaManager._key(child._blockKey, child.name)
      child._getValue = (state) => state[child._blockKey][child.name]
      if (!child.config) {
        // compatibility with old code
        child.config = configMap[child.type] || ControlTypes.generic()
      }
      if (child._isInput) {
        this.controls.push(child);
        this.controlsMap[child._key] = child
      }
      if (Array.isArray(child.options) && child.options?.length && !child._default) {
        for (const o of child.options) {
          if (!o.defaultSelected) continue
          child._default = o;
          break;
        }
      }

      this.processChildren(block, child.children)
      this.processChildren(block, child.columnA)
      this.processChildren(block, child.columnB)
      this.processChildren(block, child.rowElements)
    }
  }

  static _key(blockGroup, name) {
    return `${blockGroup}.${name}`
  }

  makeFormState(initialData) {
    const data = cloneDeep(initialData);
    const state = {};
    for (const control of this.controls) {
      let blockState = state[control._blockKey];
      if (!blockState) {
        blockState = {
          canUpdate: false
        };
        state[control._blockKey] = blockState;
      }

      if (!control._isInput) continue
      let stateValue
      if (control.subKey) {
        const subValue = data[control.subKey] || {}
        stateValue = subValue[control.name]
      } else {
        stateValue = data[control.name]
      }
      if (isObject(stateValue)) {
        stateValue = cloneDeep(stateValue);
      }

      const controlValue = control.config.getDefaultValue(control, stateValue)

      if (controlValue !== undefined) {
        delete data[control.name];
      }

      blockState[control.name] = controlValue
    }

    const returnObj = { ...state, ...data };

    // sometimes, the `data` object overwrites the `state` object
    // due to having the same property names in the merge; the
    // merge is necessary to store, for example, top-level renderCheck
    // values, such as access types and their respective Booleans.
    // This ensures that, in the event `data` overwrites `state` above,
    // we still place a `canUpdate` property on nested objects. Not
    // always necessary since we only use `canUpdate` on super-specific
    // top-level properties defined in schema and callee API methods, for example.
    // But it ensures the `hasChanged` functionality works more consistently.

    Object.keys(returnObj).forEach(key => {
      if (isObject(returnObj[key])) {
        if (undefined === returnObj[key].canUpdate) {
          returnObj[key].canUpdate = false;
        }
      }
    });

    return returnObj;
  };

  makeFormErrorsState() {
    return {
      fields: {},
      _hasErrors: false,
      _errorMessages: []
    };
  };

  validate(state, getStateValue, errors) {
    let validation = cloneDeep(errors);
    validation._errorMessages = [];

    for (const control of this.controls) {
      const stateValue = state[control._blockKey][control.name];
      this.validateControl(control, getStateValue, stateValue, validation);
    }
    return validation;
  }

  validateControl(control, getStateValue, stateValue, validation) {
    let didUpdate = false
    let e = false;
    const isRequired = control.required || (control.requiredIf && control.requiredIf(getStateValue));

    const missingValue = (Array.isArray(stateValue) && !stateValue.length)
      || stateValue === undefined || stateValue === null || stateValue === '';

    if (control.canValidate) {
      e = control.validate(stateValue);
    }
    if (isRequired && missingValue) {
      if (control.label) {
        e = `${control.label} is a required field.`;
        validation._errorMessages.push(e);
      } else {
        e = 'This is a required field.';
      }
    }

    if (e) {
      didUpdate = true
      validation._hasErrors = true;
      validation.fields[control._key] = e;
    } else if (validation.fields[control._key]) {
      didUpdate = true
      delete validation.fields[control._key];
    }
    return didUpdate
  }

  dataToSave(formState) {
    const data = {}
    for (const control of this.controls) {
      const block = formState[control._blockKey]
      if (!block) continue
      let obj = data
      if (control.subKey) {
        if (!obj[control.subKey]) {
          obj[control.subKey] = {}
        }
        obj = obj[control.subKey]
      }
      obj[control.name] = block[control.name]
    }
    return data
  }

  getErrors(errors, blockGroup, name) {
    return errors.fields[SchemaManager._key(blockGroup, name)] || null
  }

  getInputErrors(errors, input) {
    if (input._rootControl) {
      const rootErrors = this.getInputErrors(errors, input._rootControl)
      if (!rootErrors) return null
      return input._rootControl.getErrors(rootErrors, input)
    }
    return errors.fields[input._key] || null
  }

  clearError(errors, blockGroup, name) {
    const newErrors = cloneDeep(errors)
    delete errors.fields[SchemaManager._key(blockGroup, name)]
    this.checkErrors(errors)
    return newErrors
  }

  setError(errors, blockKey, name, error) {
    if (!error) return this.clearError(errors, blockKey, name)
    const newValue = isString(error) ? error : !!error;
    const newErrors = cloneDeep(errors)
    newErrors.fields[SchemaManager._key(blockKey, name)] = newValue
    newErrors._hasErrors = true
    return newErrors
  }

  checkErrors(errors) {
    for (const key in errors.fields) {
      if (key) {
        errors._hasErrors = true
        return;
      }
    }
    errors._hasErrors = false
  }
}