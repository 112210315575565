import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

import Label from 'components/Label';
import CustomLabel from 'components/Curriculum/Assignment/Completions/CustomLabel';
import TooltipContentCompletion from 'components/Curriculum/Assignment/Completions/TooltipContentCompletion';

const COLORS = ['#bc5090', '#ffa600'];

const CompletionPie = ({ data, handleFilter }) => {
  return (
    <div data-tip="" data-for="chart" className="chart">
      <Label htmlFor={`chart-completion`} bold mBottom={false}>
        Completion Progress
      </Label>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={CustomLabel}
            outerRadius={150}
            fill="#8884d8"
            dataKey="value"
            onClick={handleFilter}
          >
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={<TooltipContentCompletion />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CompletionPie;
