import axios from 'axios';
import axiosRetry from 'axios-retry';
import Cookies from 'js-cookie';

import { API_URL_PREFIX, environment, errorToast } from './Constants';
import { Options } from './TestingUtil';
export const API_URL = API_URL_PREFIX + 'apiv1/';

let instance = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'text/plain' }
});

axiosRetry(instance, { retries: 3 });

const jwtHeaderKey = 'Auth-Jwt';
const jwtInstance = axios.create({
  baseURL: API_URL,
  timeout: 100000000,
  headers: {
    'Content-Type': 'application/json',
    [jwtHeaderKey]: getJWTFromCookie()
  }
});
jwtInstance.interceptors.request.use(req => {
  if (!req.headers[jwtHeaderKey]) {
    req.headers[jwtHeaderKey] = getJWTFromCookie();
  }
  return req;
});

jwtInstance.interceptors.response.use(
  res => {
    if (res.data?.status !== 'success' && !res.data?.success) {
      if (environment.showDetailedErrors) {
        errorToast(res.data);
      }
      return Promise.reject(res);
    }
    return res;
  },
  error => {
    if (environment.showDetailedErrors) {
      errorToast(error);
    }
    return Promise.reject(error);
  }
);

/**
 * Get axios config for non-post/put requests.
 * @returns {object} config object for axios
 */
function getAxiosConfig() {
  return {
    headers: { 'Auth-Jwt': getJWTFromCookie() }
  };
}

/**
 * Get jwt from cookies.
 * @returns {string} jwt from cookies
 */
export function getJWTFromCookie() {
  return Cookies.get('id_token');
}

const FACILITY_ID_MISMATCH = 'Facility ID mismatch';

function getJWT() {
  return { auth_jwt: getJWTFromCookie() };
}

function catchErrors(error) {
  if (ENVIRONMENT === 'development') {
    debugger; // eslint-disable-line
  }

  // did axios change how this works?
  if (error.response && error.response.status === 401) {
    Cookies.remove('id_token');
    window.location.replace(
      'https://call2test.auth0.com/v2/logout?returnTo=' +
        window.location.protocol +
        '//' +
        window.location.host +
        '/login'
    );
  } else if (error instanceof Error) {
    if (window._errs) {
      window._errs.meta = window.meta;
      window._errs.push(error);
    }

    if (environment.showDetailedErrors) {
      errorToast(error);
      return;
    } else if (error.message === FACILITY_ID_MISMATCH) {
      window.location.replace('/dashboard');
    }
    throw new Error(error);
  }
}

function apiHelp(path, params = {}, method = 'post') {
  if (method == 'get') {
    return instance.get(path, getAxiosConfig());
  } else if (method == 'delete') {
    return instance.delete(path, getAxiosConfig());
  }
  let data = Object.assign(params, getJWT());
  return instance[method](path, data);
}

function getUser(params) {
  params = Object.assign({}, params, getJWT());
  return instance.post('users/get', params);
}

function addInterceptor(response) {
  const facilityId = response.data.facility_id;

  // should we add an error interceptor?
  instance.interceptors.response.use(function(res) {
    if (res.data.facility_id && facilityId !== res.data.facility_id) {
      errorToast(res.data);
      return Promise.reject(new Error(FACILITY_ID_MISMATCH));
    }
    return res;
  });
  return response;
}

function getFacility() {
  return instance.post('facility/get', getJWT());
}

function getClient(id) {
  let data = Object.assign(getJWT(), { id });
  return instance.post('clients/get', data);
}

function getDateInfo(date, hash = false) {
  let hashObj = hash ? { hash } : {};
  let data = Object.assign({ date }, hashObj, getJWT());
  return instance.post('facility/schedule/get_date', data);
}

function getReport(date, hash = false) {
  let hashObj = hash ? { hash } : {};
  let data = Object.assign({ date }, hashObj, getJWT());
  return instance.post('reports/day', data);
}

function getReportClient(client_id, start_date, end_date) {
  let data = Object.assign({ id: client_id, start_date, end_date }, getJWT());
  return instance.post('reports/client', data);
}

// unused at the moment?
function getReportRange(start_date, end_date) {
  let data = Object.assign({ start_date, end_date }, getJWT());
  return instance.post('reports/range', data);
}

function getWindowReport(date, hash = false) {
  let hashObj = hash ? { hash } : {};
  let data = Object.assign({ date }, hashObj, getJWT());
  return instance.post('reports/undocumented_window_report/6aElbf8WmQAkWI8T128AcSyVl2EO33NaZQDawHDZ', data);
}

function uploadDocument(data, clientId = false, setProgress) {
  data = {
    auth_jwt: Cookies.get('id_token'),
    client_id: clientId,
    ...data
  };

  return instance.post('document/', data, {
    onUploadProgress: data => {
      setProgress(Math.round((100 * data.loaded) / data.total));
    }
  });
}

function updateDocument(data, document_id, clientId = false, setProgress) {
  data = {
    auth_jwt: Cookies.get('id_token'),
    client_id: clientId,
    ...data
  };

  return instance.put(`document/${document_id}`, data, {
    onUploadProgress: data => {
      setProgress(Math.round((100 * data.loaded) / data.total));
    }
  });
}

function getDocument(document_id) {
  return instance.get(`document/${document_id}`, getAxiosConfig());
}

function getDocumentLibraryByFacility() {
  return instance.get('document/list', getAxiosConfig());
}

function updateIncentivesAndSanctions(data, clientId = false) {
  data = {
    auth_jwt: Cookies.get('id_token'),
    client_id: clientId,
    ...data
  };

  return instance.post(`incentives-and-sanctions/by-client/${clientId}`, data);
}

function getIncentivesAndSanctions(clientId) {
  return instance.get(`incentives-and-sanctions/by-client/${clientId}`, getAxiosConfig());
}

function getClientDocuments(client_id) {
  return instance.get(`document/client/${client_id}`, getAxiosConfig());
}

function deleteDocument(document_id) {
  return instance.delete(`document/${document_id}`, getAxiosConfig());
}

function getBenchmarkGoalList(benchmark_id) {
  return instance.get(`benchmark/goal/list/${benchmark_id}`, getAxiosConfig());
}

function updateStreak(streakId, title, isRemoved) {
  return instance.put(
    `client/streak/${streakId}`,
    Object.assign({ title: title, removed_at: isRemoved || null }, getJWT())
  );
}

function getCustomFacilityOptions(field_name) {
  const data = { field_name, ...getJWT() };
  return instance.post('facility/get_options', data);
}

function addCustomFacilityOptions(field_name, option) {
  const data = { field_name, option, ...getJWT() };
  return instance.post('facility/add_option', data);
}

function getClientList(small = false, hash = false) {
  let params = {
    auth_jwt: Cookies.get('id_token')
  };
  if (small) {
    params.small_response = true;
  }
  if (hash) {
    params['hash-only'] = hash;
  }

  return instance.post('clients/list', params);
}

function requestClientDevices(clientId) {
  return instance.post('clients/get_client_devices', Object.assign({ client_id: clientId }, getJWT()));
}

function getClientGroups(hash = false) {
  let hashObj = hash ? { hash } : {};
  let data = Object.assign(hashObj, getJWT());
  return instance.post('facility/client_groups/list', data);
}

/**
 * Get Form Events.
 * @param {string} is_active Load only active events
 * @param {string} start_date start date for form events
 */
function requestGetFormEvents(is_active, start_date, event_type) {
  const params = { is_active, start_date, event_type };
  const urlParams = Object.keys(params)
    .map(function(key) {
      return key in params ? key + '=' + params[key] : false;
    })
    .filter(Boolean)
    .join('&');
  return instance.get(`events/list_clean?${urlParams}`, getAxiosConfig());
}

/**
 * Delete Form Event.
 * @param {string} id Event id
 */
function requestDeleteFormEvent(id) {
  return instance.delete('events/' + id, getAxiosConfig());
}

/**
 * Update Form Event.
 * @param {string} id Event id
 */
function requestUpdateFormEvent(id, data) {
  return instance.put('events/' + id, Object.assign({ data }, getJWT()));
}

/**
 * Get Form Event.
 * @param {string} id Event id
 */
function requestGetFormEvent(id) {
  return instance.get('events/' + id, getAxiosConfig());
}

/**
 * Get Form Event Occurrences.
 * @param {string} externalId Event externalId
 */
function requestGetFormEventOccurrences(externalId, onlyOne) {
  return instance.get(`events/${externalId}/occurrences${onlyOne ? '?only_one=1' : ''}`, getAxiosConfig());
}

/**
 * Get Event tags.
 */
function requestGetEventTags() {
  return instance.get('events/tags', getAxiosConfig());
}

/**
 * Fetch conversations for a user.
 * @param {object} params Includes client_id & all_messages properties.
 */
function requestConversations(params) {
  return instance.post('users/conversations_list', Object.assign(params, getJWT()));
}

/**
 * Fetch facility forms.
 */
function requestFacilityForms() {
  return instance.post('form/list', getJWT());
}

/**
 * Fetch form responses for a client.
 * @param {integer|string} clientId Id of the client whose entries will be fetch.
 */
function requestClientCompletedForms(clientId) {
  return instance.post('form_response/list', Object.assign({ client_id: clientId }, getJWT()));
}

/**
 * Fetch single form response for a client.
 * @param {integer} entryId Id of the client form entry.
 */
function requestSingleCompletedForm(entryId, checkId) {
  return instance.post(
    'form_response/get',
    Object.assign({ id: entryId, check_id: checkId ? checkId : null, with_meta: true }, getJWT())
  );
}

/**
 * Get Form template keys.
 */
function getFormTemplateKeys() {
  return instance.get('form/template_keys', getAxiosConfig());
}

function getCalendarsList(hash = false) {
  let hashObj = hash ? { hash } : {};
  let data = Object.assign(hashObj, getJWT());
  return instance.post('facility/calendars/list', data);
}
1;
function getCallInWindows(hash = false) {
  let hashObj = hash ? { hash } : {};
  let data = Object.assign(hashObj, getJWT());
  return instance.post('facility/call_in_windows/list', data);
}

function getTestsList(hash = false) {
  let hashObj = hash ? { hash } : {};
  let data = Object.assign(hashObj, getJWT());
  return instance.post('facility/tests/list', data);
}

function getTestProfiles(hash = false, show_in_use = false) {
  let hashObj = hash ? { hash } : {};
  return instance.post('facility/test_profiles/list', {
    ...hashObj,
    show_in_use,
    ...getJWT()
  });
}

function getNotifications(client_id) {
  let data = Object.assign({ client_id }, getJWT());
  return instance.post('clients/get_notifications', data);
}

function getCheckRequestList(client_id, options) {
  let data = Object.assign({ client_id, options }, getJWT());
  return instance.post('clients/get_check_requests', data);
}

function getCheckRequest(client_id, test_check_request_id) {
  let data = Object.assign({ client_id, test_check_request_id }, getJWT());
  return instance.post('reports/detailed_check_request', data);
}

function getLocationTracking(client_id, start_date, end_date, week = false) {
  const data = { client_id, start_date, end_date, week, ...getJWT() };
  return instance.post('clients/get_locations', data);
}

function getMostRecentLocation(client_id) {
  let data = Object.assign({ client_id }, getJWT());
  return instance.post('clients/get_most_recent_location', data);
}

function getLocations(hash = false, clientId = null) {
  const dataObj = {};
  if (hash) {
    dataObj.hash = hash;
  }
  if (clientId) {
    dataObj.client_id = clientId;
  }
  let data = Object.assign(dataObj, getJWT());
  return instance.post('locations/list', data);
}

function getLocationCsv(clientIds, startDate, endDate) {
  return instance.post('clients/get_locations_dump', {
    client_ids: clientIds,
    start_date: startDate,
    end_date: endDate,
    ...getJWT()
  });
}

function requestStates() {
  return instance.post('locations/states', Object.assign({}, getJWT()));
}

function requestCounties(stateId) {
  return instance.post('locations/state_counties', Object.assign({ state_id: stateId }, getJWT()));
}

function getViolations(
  hash = false,
  offset = 0,
  limit,
  sort_by,
  sort_direction,
  filter_type,
  include_unresolved_only = false
) {
  let hashObj = hash
    ? {
        hash,
        offset,
        limit,
        sort_by,
        sort_direction,
        filter_type,
        include_unresolved_only
      }
    : {
        offset,
        limit,
        sort_by,
        sort_direction,
        filter_type,
        include_unresolved_only
      };
  let data = Object.assign(hashObj, getJWT());
  return instance.post('violations/list', data);
}

function getViolationsByClient(client_id, offset = 0, limit, sort_by, sort_direction, filter_type) {
  let data = Object.assign({ client_id, offset, limit, sort_by, sort_direction, filter_type }, getJWT());
  return instance.post('violations/client', data);
}

function getViolationsPolling(hash = false) {
  let hashObj = hash ? { hash } : {};
  let data = Object.assign(hashObj, getJWT());
  return instance.post('violations/polling', data);
}

function clearViolations(startDate, endDate) {
  let data = Object.assign({ startDate, endDate }, getJWT());
  return instance.post('facility/clear_violations', data);
}

function clearViolationsByClient(clientId, startDate, endDate) {
  let data = Object.assign({ clientId, startDate, endDate }, getJWT());
  return instance.post('violations/clear_violations_by_client', data);
}

const getViolationByViolationId = id => instance.post('violations/get', { id, ...getJWT() });

const getViolationIdByExternalEventId = (external_event_id, closed_datetime) =>
  instance.post('violations/external_event_id', {
    external_event_id,
    closed_datetime,
    ...getJWT()
  });

const getResultDataByViolationId = id => instance.post('violations/result_data', { id, ...getJWT() });

const addViolationNote = (id, notes) => instance.post('violations/update', { id, notes, ...getJWT() });

function getClientCheckIns(hash = false, client_id, options) {
  let data = hash
    ? Object.assign({ hash, client_id, options }, getJWT())
    : Object.assign({ client_id, options }, getJWT());
  return instance.post('events/check_ins', data);
}

function getEvent(hash = false, client_id, id, external_event_id, test_check_request_id) {
  let data = hash
    ? Object.assign({ hash, client_id, id, external_event_id, test_check_request_id }, getJWT())
    : Object.assign({ client_id, id, external_event_id, test_check_request_id }, getJWT());
  return instance.post('events/get', data);
}

function getUsersList(hash = false) {
  let hashObj = hash ? { hash } : {};
  let data = Object.assign(hashObj, getJWT());
  return instance.post('users/list', data);
}

function updateTestResults(
  clientID,
  testDate,
  testResult,
  testNote,
  test_master_detail_id,
  panels,
  panelsOther,
  substances,
  substancesOther,
  waitingStatus
) {
  const validResults = Options.results.map(o => o.value);

  return instance.post(API_URL + 'clients/update_test_results', {
    auth_jwt: Cookies.get('id_token'),
    id: clientID,
    test_date: testDate,
    result: validResults.indexOf(testResult) === -1 ? 'not_logged' : testResult,
    panels,
    panels_other: panelsOther,
    substances,
    substances_other: substancesOther,
    waiting_status: waitingStatus,
    test_master_detail_id,
    note: testNote
  });
}

function addClient(first_name, last_name, client_id, testing_profile = null, call_in_window = null, calendar = null) {
  const data = {
    first_name,
    last_name,
    client_id,
    ...(testing_profile &&
      call_in_window &&
      calendar && {
        testing_profile: [testing_profile],
        call_in_window,
        calendar
      }),
    ...getJWT()
  };
  return instance.post('clients/add', data);
}

function addLocation(
  address,
  client_id,
  coordinates,
  default_lenience,
  definition,
  description,
  exclusion,
  name,
  scope,
  lat,
  lng,
  start_time,
  end_time,
  weekdays
) {
  let data = Object.assign(
    {
      address,
      client_id,
      coordinates,
      default_lenience,
      definition,
      description,
      exclusion,
      name,
      scope,
      lat,
      lng,
      start_time,
      end_time,
      weekdays
    },
    getJWT()
  );
  return instance.post('locations/add', data);
}

/**
 * Request to save the location data provided.
 * @param {object} locationData Includes the following props.
 * address,
 * client_id,
 * coordinates,
 * default_lenience,
 * definition,
 * description,
 * exclusion
 * id,
 * name,
 * scope,
 */
function updateLocation(locationData) {
  return instance.post('locations/update', Object.assign(locationData, getJWT()));
}

function handleUpdateViolation(id, status, note, reason) {
  let data = Object.assign({ id, status, note, reason }, getJWT());
  return instance.post('violations/update', data);
}

/**
 * Requests to remove a location.
 *
 * @param {int} id Location to remove.
 * @param {int|null} client_id Set when deleting a client location or a location assigned to a client. Null when deleting a location from Settings > Locations
 * @param {string} context Whether to remove the entry in the link_client_to_location table only, or in the location table as well. Can be 'all' or 'client'.
 *
 * @returns {Promise}
 */
function removeLocation(id, client_id = null, context = '') {
  let data = Object.assign({ id, client_id, context }, getJWT());
  return instance.post('locations/remove', data);
}

function activateClient(id) {
  let data = Object.assign({ id }, getJWT());
  return instance.post('clients/activate', data);
}

function deactivateClient(id, deactivation_reason) {
  let data = Object.assign({ id, deactivation_reason }, getJWT());
  return instance.post('clients/deactivate', data);
}

export function updateBillingContact(billing_contact) {
  let data = Object.assign({ billing_contact }, getJWT());
  return instance.post('facility/set', data);
}

export function updateUser(id, user_data) {
  const data = { id, user_data, ...getJWT() };
  return instance.post('users/update_user', data);
}
export function disapproveClientInitiatedForm(todo_id, form_response_id) {
  const data = { todo_id, form_response_id, ...getJWT() };
  return instance.post('form_response/reject', data);
}

export function approveClientInitiatedForm(todo_id, form_response_id) {
  const data = { todo_id, form_response_id, ...getJWT() };
  return instance.post('form_response/approve', data);
}

export function setToDraftClientInitiatedForm(todo_id, form_response_id) {
  const data = { todo_id, form_response_id, ...getJWT() };
  return instance.post('form_response/set_to_draft', data);
}

export const endCall = session_id => instance.post('users/end_call', { session_id, ...getJWT() });

export const getTodos = last_id =>
  instance.post(`users/get_todos${last_id ? '/' + last_id : ''}`, {
    ...getJWT()
  });

export const removeClientsFromEvent = (related_external_id, clients) => {
  const data = { clients, ...getJWT() };
  return instance.post(`events/remove_clients/${related_external_id}`, data);
};

export const eventSampler = data => {
  return instance.post(`events/occurrence_sampler`, {
    data,
    ...getJWT()
  });
};

export const getTodosByType = (type, status) => {
  status = status || 'pending,complete';
  return instance.get(`todos/get_by_type/${type}/${status.replace(',', '-and-')}`, getAxiosConfig());
};

/**
 * Request to accept the terms of service.
 *
 * @param {integer} userId Id of the user accepting the ToS.
 * @param {integer} tosId  Id of the ToS accepted by the user.
 */
function acceptTermsOfService(userId, tosId) {
  const data = { id: userId, tos_id: tosId, ...getJWT() };
  return instance.post('users/tos/accept', data);
}

/**
 * Retrieves the array buffer of the form PDF document.
 *
 * @param {integer} id
 * @param {integer} checkId
 */
function getFormAsBuffer(id, checkId = null) {
  return axios.get(`${API_URL}document/form/${id}`, {
    params: {
      auth_jwt: getJWTFromCookie(),
      check_id: checkId
    },
    responseType: 'arraybuffer'
  });
}

/**
 * Retrieves the array buffer of the forms PDF document.
 *
 * @param {array} forms_id
 */
function getFormsMergedAsBuffer(forms_id) {
  return axios.post(
    `${API_URL}document/forms`,
    {
      forms_id,
      ...getJWT()
    },
    { responseType: 'arraybuffer' }
  );
}

/**
 * Retrieves the array buffer of the form PDF document.
 *
 * @param {integer} id
 * @param {integer} checkId
 */
function getBenchmarkReportAsBuffer(client_id, start_date, end_date, timezone) {
  return axios.get(`${API_URL}document/benchmark/${client_id}`, {
    params: {
      auth_jwt: getJWTFromCookie(),
      start_date,
      end_date,
      timezone
    },
    responseType: 'arraybuffer'
  });
}

/**
 * Adds a new curfew event.
 * @param {object} data Curfew event details.
 */
function requestAddCurfewEvent(data) {
  return instance.post('events/curfew', Object.assign({ data }, getJWT()));
}

/**
 * Deletes a curfew event.
 * @param {string} id Event id
 */
function requestDeleteCurfewEvent(id) {
  return instance.delete('events/' + id, getAxiosConfig());
}

/**
 * Updates a curfew event.
 * @param {string} id Event id
 */
function requestUpdateCurfewEvent(id, data) {
  return instance.put('events/' + id, Object.assign({ data }, getJWT()));
}

/**
 * Retrieves a curfew event.
 * @param {string} id Event id
 */
function requestGetCurfewEvent(id) {
  return instance.get('events/' + id, getAxiosConfig());
}

/**
 * Adds a new breathalyzer event.
 * @param {object} data breathalyzer event details.
 */
function requestAddBreathalyzerEvent(data) {
  return instance.post('events/breathalyzer', Object.assign({ data }, getJWT()));
}

/**
 * Deletes a breathalyzer event.
 * @param {string} id Event id
 */
function requestDeleteBreathalyzerEvent(id) {
  return instance.delete('events/' + id, getAxiosConfig());
}

/**
 * Updates a breathalyzer event.
 * @param {string} id Event id
 */
function requestUpdateBreathalyzerEvent(id, data) {
  return instance.put('events/' + id, Object.assign({ data }, getJWT()));
}

/**
 * Retrieves a breathalyzer event.
 * @param {string} id Event id
 */
function requestGetBreathalyzerEvent(id) {
  return instance.get('events/' + id, getAxiosConfig());
}

/**
 * Approve event.
 * @param {string} event_id Event id
 * @param {int} client_id Client id
 */
function approveEvent(event_id, client_id, todo_id) {
  return instance.post('events/approve/' + event_id, {
    client_id,
    todo_id,
    ...getJWT()
  });
}

/**
 * Deny event.
 * @param {string} event_id Event id
 * @param {int} client_id Client id
 */
function denyEvent(event_id, client_id, todo_id) {
  return instance.post('events/deny/' + event_id, {
    client_id,
    todo_id,
    ...getJWT()
  });
}

/**
 * Approve home address change.
 * @param {string} location_id Location id
 * @param {int} client_id Client id
 */
function approveHomeAddressChange(location_id, client_id, todo_id) {
  return instance.post('clients/approve_home_address', {
    client_id,
    location_id,
    todo_id,
    ...getJWT()
  });
}

/**
 * Deny home address change.
 * @param {string} location_id Location id
 * @param {int} client_id Client id
 */
function denyHomeAddressChange(location_id, client_id, todo_id) {
  return instance.post('clients/deny_home_address', {
    client_id,
    todo_id,
    ...getJWT()
  });
}

function requestGetCurfewEvents(since, up_to, exclude, clientIds = '') {
  const clientIdsQuery = clientIds ? `&client_ids=${clientIds}` : '';
  return instance.get(
    `events/facility/occurrences?since=${since}&up_to=${up_to}&exclude=${exclude}${clientIdsQuery}`,
    getAxiosConfig()
  );
}

function requestGetBreathalyzerEvents(since, up_to, exclude) {
  return instance.get(`events/facility/occurrences?since=${since}&up_to=${up_to}&exclude=${exclude}`, getAxiosConfig());
}

function requestGetEventsByClient(client_id, start_date, end_date, exclude) {
  return instance.get(
    `events/client/occurrences?client_id=${client_id}&start_date=${start_date}&end_date=${end_date}&exclude=${exclude}`,
    getAxiosConfig()
  );
}

/**
 * Retrieves all facility programs
 */
function requestFacilityPrograms() {
  return instance.get('program/list', getAxiosConfig());
}

/**
 * Delete facility program by program ID
 * @param {integer} id
 */
function requestRemoveFacilityProgram(id) {
  return instance.delete(`program/${id}`, getAxiosConfig());
}

/**
 * Update facility program by program ID w/ name, billable, and SKUs
 * @param {integer} id
 * @param {object} program data to update
 */
function requestUpdateFacilityProgram(id, data) {
  return instance.put(`program/${id}`, { ...data, ...getJWT() });
}

/**
 * Create facility program by program ID w/ name, billable, and SKUs
 * @param {integer} id
 * @param {object} new program data
 */
function requestCreateFacilityProgram(data) {
  return instance.post(`program/`, { ...data, ...getJWT() });
}

/**
 * Fetch all client subscriptions
 * @param {integer} client_id
 */
function requestGetClientSubscriptions(client_id) {
  return instance.get(`client/${client_id}/subscriptions`, getAxiosConfig());
}

/**
 * Fetch all current client subscriptions by facility
 */
function requestGetClientSubscriptionsByFacility() {
  return instance.get('facility/get_client_subscriptions_by_facility', getAxiosConfig());
}

/**
 * Create client subscription
 * @param {integer} client_id
 * @param {object} new subscription data {program_id, start_date, end_date, skus: []}
 */
function requestCreateClientSubscription(client_id, data) {
  return instance.post(`client/${client_id}/subscription`, {
    ...data,
    ...getJWT()
  });
}

/**
 * Update client subscription
 * @param {integer} subscription_id
 * @param {object} data to update
 */
function requestUpdateClientSubscription(subscription_id, data) {
  return instance.put(`client/subscription/${subscription_id}`, {
    ...data,
    ...getJWT()
  });
}

/**
 * Cancel client subscription
 * @param {integer} subscription_id
 * @param {integer} client_id
 */
function requestCancelClientSubscription(subscription_id, client_id) {
  return instance.put(`client/subscription/${subscription_id}/cancel`, {
    ...{
      client_id
    },
    ...getJWT()
  });
}

/**
 * Get active client subscription
 * @param {integer} client_id
 */
function requestGetClientSubscription(client_id) {
  return instance.get(`client/${client_id}/subscription/active`, getAxiosConfig());
}

/*
 * Adds a new community event.
 * @param {object} data Community event details.
 */
function requestAddCommunityEvent(data) {
  return instance.post('events/community', Object.assign({ data }, getJWT()));
}

/**
 * Deletes a community event.
 * @param {string} id Event id
 */
function requestDeleteCommunityEvent(id) {
  return instance.delete('events/' + id, getAxiosConfig());
}

/**
 * Updates a community event.
 * @param {string} id Event id
 */
function requestUpdateCommunityEvent(id, data) {
  return instance.put('events/' + id, Object.assign({ data }, getJWT()));
}

/**
 * Retrieves a community event.
 * @param {string} id Event id
 */
function requestGetCommunityEvent(id) {
  return instance.get('events/' + id, getAxiosConfig());
}

function requestGetCommunityEvents(since, up_to, exclude) {
  return instance.get(`events/facility/occurrences?since=${since}&up_to=${up_to}&exclude=${exclude}`, getAxiosConfig());
}

/*
 * Adds a new appointment event.
 * @param {object} data Community event details.
 */
function requestAddAppointmentEvent(data) {
  return instance.post('events/appointment', Object.assign({ data }, getJWT()));
}

/**
 * Deletes a appointment event.
 * @param {string} id Event id
 */
function requestDeleteAppointmentEvent(id) {
  return instance.delete('events/' + id, getAxiosConfig());
}

/**
 * Updates a appointment event.
 * @param {string} id Event id
 */
function requestUpdateAppointmentEvent(id, data) {
  return instance.put('events/appointment/' + id, Object.assign({ data }, getJWT()));
}

/**
 * Retrieves a appointment event.
 * @param {string} id Event id
 */
function requestGetAppointmentEvent(id) {
  return instance.get('events/' + id, getAxiosConfig());
}

function requestGetAppointmentEvents(since, up_to) {
  const exclude = 'form,curfew,community,client-initiated,breathalizer,generic';
  return instance.get(
    `events/facility/occurrences?is_appointment=true&since=${since}&up_to=${up_to}&exclude=${exclude}`,
    getAxiosConfig()
  );
}

function requestGetClosedAppointmentEvents(since, up_to) {
  return instance.get(
    `events/closed-occurrences?event_type=appointment&since=${since}&up_to=${up_to}`,
    getAxiosConfig()
  );
}

function updateEventOccurrence(occurrence_id, specialization_data) {
  return instance.put(`events/occurrence/${occurrence_id}`, {
    specialization_data,
    ...getJWT()
  });
}

/**
 * Update an events notes.
 * @param {string} event_id Event id
 * @param {string} notes Notes
 */
function updateEventNotes(event_id, notes) {
  return instance.put('events/notes/' + event_id, Object.assign({ notes }, getJWT()));
}

/**
 * Deny event.
 * @param {string} event_id Event id
 * @param {int} client_id Client id
 */
function acknowledgeFormResponseChanged(client_id, todo_id) {
  return instance.post('clients/acknowledge_form_responses_changed', {
    client_id,
    todo_id,
    ...getJWT()
  });
}

function getChangedFormResponses(client_id, form_response_id) {
  return instance.post('clients/get_form_responses_changed', {
    client_id,
    form_response_id,
    ...getJWT()
  });
}

function sendPaymentRequest(id) {
  return instance.post('notification/subscription_payment_request', {
    id,
    ...getJWT()
  });
}

function sendBackgroundLocationNotification(client_id) {
  return jwtInstance.post('clients/send_background_location_notification', {
    client_id
  });
}

function getLatestDeviceInfo(client_id) {
  return jwtInstance.post('clients/get_latest_device_info', {
    client_id
  });
}

function getClientAlertMeterProfile(client_id) {
  return instance.get(`client/${client_id}/alertmeter`, getAxiosConfig());
}

function upsertClientAlertMeterStatus(client_id, status) {
  return instance.post(`client/${client_id}/alertmeter`, {
    ...status,
    ...getJWT()
  });
}

function syncClientAlertMeterProfile(client_id) {
  return instance.get(`client/${client_id}/alertmeter/sync`, getAxiosConfig());
}

export {
  apiHelp,
  addViolationNote,
  catchErrors,
  instance,
  getUser,
  addInterceptor,
  getAxiosConfig,
  getFacility,
  getCheckRequest,
  getCheckRequestList,
  getClient,
  getClientGroups,
  getClientList,
  requestClientDevices,
  getCalendarsList,
  getLatestDeviceInfo,
  requestGetFormEvent,
  requestGetFormEvents,
  requestGetEventTags,
  requestGetFormEventOccurrences,
  requestDeleteFormEvent,
  requestUpdateFormEvent,
  requestConversations,
  requestFacilityForms,
  requestClientCompletedForms,
  requestSingleCompletedForm,
  getClientCheckIns,
  getLocations,
  requestStates,
  requestCounties,
  getEvent,
  getTestsList,
  getTestProfiles,
  getCallInWindows,
  getUsersList,
  getReport,
  getDateInfo,
  getMostRecentLocation,
  getWindowReport,
  getReportClient,
  getReportRange,
  getNotifications,
  getLocationTracking,
  getViolations,
  getViolationsPolling,
  getViolationsByClient,
  getViolationByViolationId,
  getViolationIdByExternalEventId,
  updateTestResults,
  addClient,
  addLocation,
  updateLocation,
  removeLocation,
  handleUpdateViolation,
  approveEvent,
  denyEvent,
  approveHomeAddressChange,
  denyHomeAddressChange,
  updateEventNotes,
  activateClient,
  deactivateClient,
  acceptTermsOfService,
  getFormAsBuffer,
  getFormsMergedAsBuffer,
  getBenchmarkReportAsBuffer,
  getResultDataByViolationId,
  clearViolations,
  clearViolationsByClient,
  requestAddCurfewEvent,
  requestUpdateCurfewEvent,
  requestDeleteCurfewEvent,
  requestGetCurfewEvent,
  requestGetCurfewEvents,
  requestAddBreathalyzerEvent,
  requestUpdateBreathalyzerEvent,
  requestGetBreathalyzerEvent,
  requestDeleteBreathalyzerEvent,
  requestGetBreathalyzerEvents,
  requestFacilityPrograms,
  requestRemoveFacilityProgram,
  requestUpdateFacilityProgram,
  requestCreateFacilityProgram,
  requestGetClientSubscriptionsByFacility,
  requestGetClientSubscriptions,
  requestCreateClientSubscription,
  requestUpdateClientSubscription,
  requestCancelClientSubscription,
  requestGetClientSubscription,
  requestAddCommunityEvent,
  requestUpdateCommunityEvent,
  requestDeleteCommunityEvent,
  requestGetCommunityEvent,
  requestGetCommunityEvents,
  requestAddAppointmentEvent,
  requestUpdateAppointmentEvent,
  requestDeleteAppointmentEvent,
  requestGetAppointmentEvent,
  requestGetAppointmentEvents,
  requestGetClosedAppointmentEvents,
  updateEventOccurrence,
  requestGetEventsByClient,
  acknowledgeFormResponseChanged,
  getChangedFormResponses,
  sendBackgroundLocationNotification,
  sendPaymentRequest,
  getFormTemplateKeys,
  getBenchmarkGoalList,
  jwtInstance,
  updateStreak,
  uploadDocument,
  getDocument,
  getClientDocuments,
  getDocumentLibraryByFacility,
  deleteDocument,
  updateDocument,
  updateIncentivesAndSanctions,
  getIncentivesAndSanctions,
  getLocationCsv,
  getCustomFacilityOptions,
  addCustomFacilityOptions,
  getClientAlertMeterProfile,
  upsertClientAlertMeterStatus,
  syncClientAlertMeterProfile
};
