import { loadPending, removePending, loadSuccessful } from './helperActions';

import { getUsersList, catchErrors } from 'utils/apiHelpers';

export function getUsers(users) {
  let hash = users.doneInitialLoad && users.hash;

  return dispatch => {
    // set to pending
    dispatch(loadPending('USERS'));

    if (hash) {
      getUsersList(hash)
        .then(res => {
          if (res.data.hash === hash) {
            dispatch(removePending('USERS'));
          } else {
            dispatch(loadSuccessful('USERS', res.data));
          }
        })
        .catch(catchErrors);
    } else {
      getUsersList(false)
        .then(res => {
          dispatch(loadSuccessful('USERS', res.data));
        })
        .catch(catchErrors);
    }
  };
}
