import { useCallback, useState } from 'react';

const useToggle = (initialToggled = true) => {
  const [isToggled, setIsToggled] = useState(initialToggled);

  const handleClick = useCallback(() => setIsToggled(prev => !prev), []);

  return [isToggled, handleClick];
};

export default useToggle;
