/**
 * External dependencies
 */
import get from 'lodash/get';
import find from 'lodash/find';

/**
 * Get the forms for the current facility.
 *
 * @param {object} state App state tree.
 *
 * @returns {array|bool} A collection of forms. An empty array if they're not yet available.
 */
const getFacilityForms = state => get(state, 'facility.forms', false);

export default getFacilityForms;

/**
 * Retrieve a single form from the collection of facility forms.
 *
 * @param {object} state App state tree.
 * @param {number} formId Id of the form to retrieve.
 *
 * @returns {object} A single facility form.
 */
export const getSingleFacilityForm = (state, formId) => {
  const forms = getFacilityForms(state);
  if (0 === forms.length) {
    return {};
  }
  const singleForm = find(forms, form => +form.id === formId);
  if (undefined === singleForm) {
    return {};
  }
  return singleForm;
};
