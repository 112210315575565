import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import CheckEvaluator from 'utils/CheckEvaluator';
import { catchErrors, getCheckRequest } from 'utils/apiHelpers';
import { makePoints } from '../common/drawerHelpers';
import { closeDrawer } from 'actions/actionCreators';

import Loading from 'components/Helpers/Loading';
import Image from 'components/Image';
import MapContainer from 'components/Map';
import BlockWrapper from '../common/BlockWrapper';
import ClientNameLink from 'components/ClientNameLink';

class CheckInDrawer extends Component {
  static propTypes = {
    drawer: PropTypes.shape({
      drawerProps: PropTypes.shape({
        checkRequestId: PropTypes.number.isRequired,
        clientId: PropTypes.number.isRequired,
        clientName: PropTypes.string.isRequired
      }),
      drawerOpen: PropTypes.bool.isRequired
    }).isRequired,
    closeDrawer: PropTypes.func.isRequired,
    handleLightbox: PropTypes.func.isRequired
  };

  state = {
    blocks: [],
    ready: false,
    hasLoaded: false
  };

  componentDidMount() {
    const {
      drawer: {
        drawerProps: { checkRequestId, clientId }
      }
    } = this.props;
    if (checkRequestId && clientId) {
      this.getCheckRequest(clientId, checkRequestId);
    } else {
      this.props.closeDrawer();
    }
  }

  getCheckRequest = (clientId, checkRequestId) => {
    getCheckRequest(clientId, checkRequestId)
      .then(res => {
        if (res.data.status === 'success') {
          const {
            drawer: {
              drawerProps: { clientName }
            }
          } = this.props;

          const { blocks, ...rest } = new CheckEvaluator(
            false,
            false,
            res.data.test_check_request_log
          ).parseData();

          const hasSpoofed = blocks.find(block => !!block.hasSpoofed);

          this.setState(
            {
              blocks,
              event,
              clientName,
              hasSpoofed:
                !!hasSpoofed && !!hasSpoofed.hasSpoofed
                  ? hasSpoofed.hasSpoofed
                  : false,
              ready: true,
              ...rest
            },
            this.makePoints
          );
        }
      })
      .catch(catchErrors);
  };

  makePoints = () => {
    const { hasEvent, hasPos, blocks } = this.state;
    const extra = blocks.find(block => !!block.hasSpoofed);
    const points = makePoints(hasEvent, hasPos, extra);
    this.setState({ points });
  };

  hasLoaded = bool => {
    this.setState({
      hasLoaded: bool
    });
  };

  render() {
    if (!this.state.ready) {
      return <Loading />;
    }

    const {
      blocks,
      checkUrl,
      clientName,
      hasEvent,
      hasPos,
      hasSpoofed,
      status,
      points,
      hasLoaded
    } = this.state;

    const {
      drawer: {
        drawerOpen,
        drawerProps: { clientId }
      }
    } = this.props;

    const { handleLightbox } = this.props;
    const { ...hasPosLatLng } = hasPos;
    const { lat, lng, lenience } = hasEvent;
    const eventCoords = { lat, lng };
    const { lat: spoofedLat, lng: spoofedLng } = hasSpoofed;
    const spoofedCoords = { lat: spoofedLat, lng: spoofedLng };

    return (
      <div className="vc-container check-in-offset">
        <div className="vc-container__scroll-wrapper">
          <div
            className={classnames('vc-container__header', {
              'drawer-open': drawerOpen
            })}
          >
            <h3 className="vc-container__header--heading">Check-In</h3>
          </div>
          {checkUrl || hasPos ? (
            <div className="vc-container__top-block">
              {checkUrl ? (
                <Image
                  src={checkUrl}
                  handleClick={handleLightbox}
                  callback={this.hasLoaded}
                />
              ) : null}
              {!!hasPos && !!hasLoaded ? (
                <MapContainer
                  center={hasPosLatLng}
                  containerStyles={{
                    height: '200px'
                  }}
                  parentStyles={{
                    width: '100%',
                    marginLeft: '1rem',
                    flex: 1
                  }}
                  data={points}
                  primaryMarker={{
                    position: hasPosLatLng,
                    title: 'Check-In Location',
                    name: 'Checked In Here',
                    icon: '/static/img/marker_red.png'
                  }}
                  secondaryMarkers={
                    !!hasEvent || !!hasSpoofed
                      ? {
                          markers: [
                            !!hasEvent && {
                              position: eventCoords,
                              title: 'Event Location',
                              name: 'Event',
                              icon: '/static/img/marker_green.png'
                            },
                            !!hasSpoofed && {
                              position: spoofedCoords,
                              title: 'Spoofed Location',
                              name: 'Spoof Attempt',
                              icon: '/static/img/marker_spoofed.png'
                            }
                          ]
                        }
                      : null
                  }
                  circle={[
                    !!hasEvent && {
                      radius: parseInt(lenience, 10) / 3.281, // lenience is in feet, we need to convert to meters
                      center: eventCoords,
                      strokeColor: 'transparent',
                      strokeOpacity: 0,
                      strokeWeight: 5,
                      fillColor: '#6cc049',
                      fillOpacity: 0.2
                    },
                    !!hasPos &&
                      !!hasPos.accuracy && {
                        radius: parseInt(hasPos.accuracy, 10),
                        center: { lat: hasPos.lat, lng: hasPos.lng },
                        strokeColor: 'transparent',
                        strokeOpacity: 0,
                        strokeWeight: 5,
                        fillColor: '#ff6363',
                        fillOpacity: 0.2
                      }
                  ]}
                  polyline={
                    !isEmpty(hasPos) && !isEmpty(hasEvent)
                      ? {
                          path: [hasPosLatLng, eventCoords]
                        }
                      : null
                  }
                />
              ) : null}
            </div>
          ) : null}
          {blocks.length &&
            blocks.map((block, i) => (
              <BlockWrapper
                key={`block-${i}`}
                clientName={<ClientNameLink client_id={clientId} clientName={clientName} />}
                closeDrawer={this.props.closeDrawer}
                hasEvent={hasEvent}
                status={status}
                hasPos={hasPos}
                block={block}
              />
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  drawer: state.drawer
});

export default connect(mapStateToProps, { closeDrawer })(CheckInDrawer);
