import React, { useContext } from 'react';

import Table from 'components/Table';
import { getToRender } from 'components/FormProvider/utils';
import FormContext from 'components/FormProvider/FormContext';
import './style.scss';

const FormTable = props => {
  const { getStateValue, getState } = useContext(FormContext);
  const getCell = l => {
    if (l.cell && ('string' === typeof l.cell || 'object' === typeof l.cell)) {
      return l.cell;
    } else {
      if (l.Component) {
        const { Component, ...rest } = l;
        return <Component {...rest} />;
      } else if (l.rowLabel) {
        return <span>{l.rowLabel}</span>;
      }
    }
  };
  const handleRowClick = (rowClick, rowKey, index) => {
    if ('function' === typeof rowClick) {
      const state = getState();
      let value = state[props.blockGroup][props.name]
        ? state[props.blockGroup][props.name][index][rowKey]
        : index;
      rowClick(value);
    }
  };
  return !props.headers ? null : (
    <>
      <Table.BaseTable
        className={`form-table${props.className ? ` ${props.className}` : ''}`}
      >
        <Table.TableHead>
          <Table.TableRow>
            {props.headers.map((h, i) => (
              <Table.TableHeader key={i}>
                {h.renderHeader ? h.renderHeader(h.label) : h.label}
              </Table.TableHeader>
            ))}
          </Table.TableRow>
        </Table.TableHead>
        <Table.TableBody
          className={props.tableRows.length ? 'has-data' : 'has-no-data'}
        >
          {props.tableRows && props.tableRows.length
            ? props.tableRows.map((r, j) => {
                return (
                  <Table.TableRow
                    key={`${j}`}
                    onClick={() =>
                      props.onRowClick
                        ? handleRowClick(props.onRowClick, props.rowClickKey, j)
                        : undefined
                    }
                  >
                    {r.map((l, k) => {
                      if (getToRender(l.renderChecks, false, getStateValue))
                        return (
                          <Table.TableCell key={`${k}`}>
                            {getCell(l)}
                          </Table.TableCell>
                        );
                    })}
                  </Table.TableRow>
                );
              })
            : null}
        </Table.TableBody>
      </Table.BaseTable>
    </>
  );
};

export default FormTable;
