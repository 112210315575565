import React, { useContext, useMemo } from 'react';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';
import SideNavSub from './SideNavSub';
import ViolationsBadge from 'components/ViolationsBadge';

const SideNavViolations = ({ pathname }) => {
  const {
    hasAccessTo,
    user: { access_level }
  } = useContext(AppContext);

  const NestedMenu = useMemo(() => {
    if (pathname.startsWith('/alerts')) {
      return (
        <SideNavSub
          title="Alerts"
          links={[
            {
              to: '/alerts',
              label: 'List',
              access: hasAccessTo('violations')
            },
            {
              to: '/alerts/clear_alerts',
              label: 'Clear Alerts',
              access: hasAccessTo('violations') && access_level >= 20
            }
          ]}
        />
      );
    }

    return null;
  }, [hasAccessTo, access_level, pathname]);

  if (hasAccessTo('violations')) {
    return (
      <NavLink to="/alerts" title="Alerts" className="sidenav__item--has-badge" nestedMenu={NestedMenu}>
        <Icon name="violations" size={24} />
        <span className="sidenav__item-label">Alerts</span>
        <ViolationsBadge className="sidenav__badge" />
      </NavLink>
    );
  }
  return null;
};

export default SideNavViolations;
