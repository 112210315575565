import { useCallback, useEffect, useRef } from 'react';
import { cloneDeep, isEmpty } from 'lodash';

const useBackup = state => {
  const backup = useRef({});

  useEffect(() => {
    if (!isEmpty(state) && isEmpty(backup.current)) {
      backup.current = cloneDeep(state);
    }
  }, [backup, state]);

  const setBackup = useCallback((newState) => {
    backup.current = newState;
  }, []);

  return [backup.current, setBackup];
}

export default useBackup;