import React, { useMemo } from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';

import curriculumUtil from 'utils/CurriculumUtil';
import CompletionSection from 'components/Curriculum/Assignment/Completions/CompletionSection';
import { getOrdinalFromNumber } from 'lib/util';

const useFormatAssigneesForUx = ({
  packet = {},
  selections = {},
  clients = [],
  users = [],
  calendars = [],
  windows = [],
  profiles = [],
  groups = [],
  isReview = false
}) => {
  const clientSelections = useMemo(() => {
    if (Array.isArray(selections?.client_id) && selections.client_id.length) {
      return clients.filter(client => selections.client_id.includes(client.id));
    }
    return [];
  }, [selections?.client_id, clients]);

  const caseManagerSelections = useMemo(() => {
    if (Array.isArray(selections?.case_manager_id) && selections.case_manager_id.length) {
      return users.filter(user => selections.case_manager_id.includes(user.id));
    }
    return [];
  }, [selections?.case_manager_id, users]);

  const calendarSelections = useMemo(() => {
    if (Array.isArray(selections?.calendar_id) && selections.calendar_id.length) {
      return calendars.filter(calendar => selections.calendar_id.includes(calendar.id));
    }
    return [];
  }, [selections?.calendar_id, calendars]);

  const windowSelections = useMemo(() => {
    if (Array.isArray(selections?.window_id) && selections.window_id.length) {
      return windows.filter(window => selections.window_id.includes(window.id));
    }
    return [];
  }, [selections?.window_id, windows]);

  const profileSelections = useMemo(() => {
    if (Array.isArray(selections?.profile_id) && selections.profile_id.length) {
      return profiles.filter(profile => selections.profile_id.includes(profile.id));
    }
    return [];
  }, [selections?.profile_id, profiles]);

  const groupSelections = useMemo(() => {
    if (Array.isArray(selections?.group_id) && selections.group_id.length) {
      return groups.filter(group => selections.group_id.includes(group.id));
    }
    return [];
  }, [selections?.group_id, groups]);

  const packetItems = useMemo(() => {
    const items = [
      <span key="packet-item-1">
        <strong>Title</strong>: {packet?.title ?? ''}
      </span>
    ];

    items.push(
      <span key="packet-item-2">
        <strong>Start Date</strong>: {curriculumUtil.formatDate(selections?.start_date ?? moment(), true)}
      </span>
    );

    items.push(
      <span key="packet-item-3">
        <strong>Deadline</strong>:{' '}
        {curriculumUtil.getDueInDaysDate(
          moment(selections?.start_date, ['YYYY-MM-DD', 'YYYY-MM-DD H:mm:ss']),
          selections?.due_in_days
        )}
      </span>
    );

    items.push(
      <span key="packet-item-4">
        <strong>Client Completion</strong>: {selections?.client_completion ? 'On' : 'Off'}
      </span>
    );

    return items;
  }, [packet, selections]);

  const resourcesItems = useMemo(() => {
    if (isReview) {
      if (!Array.isArray(selections?.curriculum_resource_details)) {
        return [];
      }

      return orderBy(selections.curriculum_resource_details, ['order'], ['asc'])
        .map(({ id, title = '', order = 0, due_in_days = null }) => {
          return (
            <div key={`detail-item-${id}`} className="review-item__list--item__sub">
              <span>
                <strong>Title</strong>: {title}
              </span>
              <span>
                <strong>Presented</strong>: {getOrdinalFromNumber(+order + 1)}
              </span>
              <span>
                <strong>Deadline</strong>:{' '}
                {curriculumUtil.getDueInDaysDate(
                  selections.start_date,
                  due_in_days ? due_in_days : selections?.due_in_days
                )}
              </span>
            </div>
          );
        })
        .flat();
    }

    return orderBy(selections?.curriculum_resource ?? [], ['order'], ['asc'])
      .map(r => {
        return (
          <div className="completions__container" key={r.id}>
            <CompletionSection {...r} start_date={selections?.start_date} isTrackingClientCompletions={!!selections?.client_completion} />
          </div>
        );
      })
      .flat();
  }, [selections, isReview]);

  return {
    clientSelections,
    caseManagerSelections,
    calendarSelections,
    windowSelections,
    profileSelections,
    groupSelections,
    packetItems,
    resourcesItems
  };
};

export default useFormatAssigneesForUx;
