import { useCallback, useState } from 'react';

import { environment } from 'utils/Constants';

export class AppError {
  constructor(userMessage, error, title = 'Unexpected Error') {
    if (error?.response?.status === 400) {
      this.expected = true;
      this.userMessage = error.response.data.error;
      if (this.userMessage) {
        this.title = userMessage;
      } else {
        this.userMessage = userMessage;
      }
    } else {
      this.expected = false;
      this.title = title;
      this.userMessage = userMessage;
      if (error && environment.showDetailedErrors) {
        this.details = error.toString();
      } else {
        this.details = null;
      }
    }
  }
}

const useApiError = () => {
  const [error, _setAppError] = useState(null);

  const set = useCallback((userMessage, error) => {
    _setAppError(new AppError(userMessage, error));
  }, []);

  const clear = useCallback(() => {
    _setAppError(null);
  }, []);

  return [error, { set, clear }];
};

export default useApiError;
