import React, { useCallback, useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

import { NotesContext } from './NotesContext';
import Input from 'components/Input';
import DateInput from 'components/DateInput';
import TimeInput from 'components/TimeInput';
import Label from 'components/Label';

const useStyles = makeStyles(() => ({
  container: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    containerType: 'inline-size',
    containerName: 'note-field',
    '& .react-datepicker-wrapper .react-datepicker__input-container input': {
      marginBottom: 0
    },
    ['@container (min-width: 768px)']: {
      flexDirection: 'row'
    }
  },
  date: {
    display: 'flex',
    gap: 18,
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 2,
    border: '1px solid #ccc',
    padding: 18,
    width: '100%',
    '& *': {
      margin: 0
    },
    '& .label-container': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: 18,
      justifyContent: 'space-between',
      '& .base-label': {
        margin: 0,
        lineHeight: 1
      },
      '& .MuiSvgIcon-root': {
        position: 'static'
      }
    },
    '& .date-input, & .time-input': {
      width: '100%'
    },
    ['@container (min-width: 768px)']: {
      width: '33%',
      minWidth: 190
    }
  },
  note: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  flex: {
    display: 'flex'
  }
}));

const DATE = 'YYYY-MM-DD';
const TIMEMERIDIAN = 'hh:mm A';
const TIME = 'HH:mm:ss';

const NoteField = () => {
  const { note, handleChange, isSaving } = useContext(NotesContext);
  const classes = useStyles();

  const date = useMemo(() => {
    if (!note?.date) return moment();

    return moment(note.date, `${DATE} ${TIME}`);
  }, [note?.date]);

  const handleChangeIntermediary = useCallback(
    type => e => {
      const { value } = e.target;

      const newDate = type === 'date' ? moment(value).format(DATE) : date.clone().format(DATE);
      const time = type === 'time' ? moment(value, TIMEMERIDIAN).format(TIME) : date.clone().format(TIME);

      const event = { target: { value: `${newDate} ${time}` } };

      handleChange('date')(event);
    },
    [date, handleChange]
  );

  return (
    <div className={classes.container}>
      <div className={classes.date}>
        <Label
          bold
          tooltip="The 'Date' field is optional but allows you to clarify on which day and time a missed note occurred. If you do not provide a date, the logged note's date defaults to today."
        >
          Date
        </Label>
        <DateInput
          value={date.format(DATE)}
          handleChange={handleChangeIntermediary('date')}
          disabled={isSaving}
          maxDate={moment()}
        />
        <TimeInput
          value={date.format(TIMEMERIDIAN)}
          handleChange={handleChangeIntermediary('time')}
          disabled={isSaving}
        />
      </div>
      <Input
        className={classes.note}
        containerClass={classes.flex}
        placeholder="Type new note here"
        value={note.note}
        handleChange={handleChange('note')}
        Component="textarea"
        rows={5}
        autoFocus
        disabled={isSaving}
      />
    </div>
  );
};

export default NoteField;
