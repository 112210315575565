import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import moment, { isMoment } from 'moment';

import Select from 'react-select';
import './style.scss';

const buildOption = label => ({ label, value: label });

const getYears = (min = 1930, max) => {
  if (!max) {
    max = new Date().getFullYear() + 2;
  }
  return [...Array(max - min).keys()].map(i => i + min + 1).reverse();
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const ModernizedDatePicker = ({ id, name, dateFormat, selected, onChange, minYear, maxYear, ...props }) => {
  const yearOptions = getYears(minYear, maxYear).map(buildOption);
  const monthOptions = months.map(buildOption);

  /**
   * We have to do this for now because the JS Date class doesn't represent
   * a date. Rather, it assumes a GMT/UTC timezone, causing some YYYY-MM-DD HH:mm:ss
   * strings to appear one-day off. For now, given we have access to Moment rather
   * than, say, date-fns -- and because we have both various data types and formats
   * coming in -- the best way is to use the uber-ridiculous Moment package
   * to convert the date into a format the Date class respects (this is a bug, not
   * a feature). For more information, see:
   *
   * https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
   */
  const actualDate = useMemo(() => {
    if (
      (selected instanceof Date && !isNaN(selected)) ||
      isMoment(selected) ||
      ('string' === typeof selected && selected !== 'Invalid Date')
    ) {
      return moment(selected).toDate();
    }

    return null;
  }, [selected]);

  const customHeader = ({ date, changeYear, changeMonth }) => (
    <div className="header">
      <Select
        className="selector"
        value={yearOptions.filter(o => o.value === date.getFullYear())}
        onChange={({ value }) => changeYear(value)}
        options={yearOptions}
      />
      <Select
        className="selector"
        value={monthOptions[date.getMonth()]}
        onChange={({ value }) => changeMonth(months.indexOf(value))}
        options={monthOptions}
      />
    </div>
  );

  return (
    <DatePicker
      className="modernizedDatePicker"
      renderCustomHeader={customHeader}
      id={id}
      name={name}
      dateFormat={dateFormat}
      selected={actualDate}
      onChange={onChange}
      {...props}
    />
  );
};

export default ModernizedDatePicker;
