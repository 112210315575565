/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { startCase } from 'lodash/fp';
/**
 * Internal dependencies
 */
import { getEventTags } from 'actions/actionCreators';
import { catchErrors, getFormTemplateKeys } from 'utils/apiHelpers';
import Input from 'components/Input';
import CustomSelect from 'components/CustomSelect';
import Icon from 'components/Icon';
import './RequirementField.scss';

const BenchmarkRequirementField = ({
  /* eslint-disable no-unused-vars */
  value,
  handleUpdateRequirement,
  index,
  handleRemoveRequirement,
  getEventTags,
  tags
}) => {
  const [requirementTypes, setRequirementTypes] = useState([]);
  const [formTemplateKeys, setFormTemplateKeys] = useState([]);
  const [unitSelected, setUnitSelected] = useState(value.unit ?? 'instances');
  const [unitAmount, setUnitAmount] = useState(value.amount ?? '');
  const [eventType, setEventType] = useState(value.type ?? 'self-help-meeting');
  const [requirementId, setRequirementId] = useState(value.id);

  useEffect(() => {
    getFormTemplateKeys()
      .then(res => {
        if (res.data.status === 'success') {
          setFormTemplateKeys(res.data.template_keys);
        }
      })
      .catch(catchErrors);
  }, []);

  useEffect(() => {
    if (!tags) {
      getEventTags();
    } else {
      setRequirementTypes([...tags, ...formTemplateKeys]);
    }
  }, [tags, formTemplateKeys]);

  const handleUnitAmountChange = event => {
    const value = event.target.value;
    setUnitAmount(value);

    // send back up
    handleUpdateRequirement(
      index,
      getRequirementData(unitSelected, value, eventType)
    );
  };
  const handleUnitChange = event => {
    const value = event.target.value;

    setUnitSelected(value);
    if (value == 'instances') {
      setRequirementTypes([...tags, ...formTemplateKeys]);
    } else {
      setRequirementTypes(tags);
    }
    // send back up
    handleUpdateRequirement(
      index,
      getRequirementData(value, unitAmount, eventType)
    );
  };
  const getRequirementData = (unit, amount, type) => {
    const data = { unit, amount, type };
    if (requirementId) {
      data.id = requirementId;
    }
    return data;
  };

  const handleTypeChange = event => {
    const value = event.target.value;
    setEventType(value);

    // send back up
    handleUpdateRequirement(
      index,
      getRequirementData(unitSelected, unitAmount, value)
    );
  };

  return (
    <div className="benchmark-requirements-field">
      <CustomSelect
        className="benchmark-type-select"
        style={{ textTransform: 'capitalize' }}
        onChange={() => {}}
        name="benchmark-type"
        handleCustomSelectChange={handleTypeChange}
        options={
          !requirementTypes
            ? []
            : requirementTypes.map(requirementType => {
                return {
                  value: requirementType.name,
                  label: startCase(
                    requirementType.form_name
                      ? requirementType.form_name
                      : requirementType.name.replace(/-/g, ' ')
                  )
                };
              })
        }
        value={eventType}
      />
      for
      <Input
        className="benchmark-unit-amount"
        type="number"
        handleChange={handleUnitAmountChange}
        min="1"
        value={unitAmount}
      />
      <CustomSelect
        className="benchmark-unit-select"
        onChange={() => {}}
        name="benchmark-unit"
        handleCustomSelectChange={handleUnitChange}
        options={[
          {
            value: 'hours',
            label: 'hours'
          },
          {
            value: 'instances',
            label: 'instances'
          }
        ]}
        value={unitSelected}
      />
      .
      {index > 0 ? (
        <span
          className="remove-requirement"
          onClick={() => handleRemoveRequirement(index)}
        >
          <Icon name="minus" size={18} />
        </span>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  tags: state.events.tags
});

const mapDispatchToProps = {
  getEventTags
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenchmarkRequirementField);
