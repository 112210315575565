import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { startCase } from 'lodash';

import curriculumUtil from 'utils/CurriculumUtil';
import { useCurriculumItemProps, useFormatAssigneesForUx, useIsClientContext } from 'components/Curriculum/hooks';
import AssigneesItem from 'components/Curriculum/Assignment/AssigneesItem';
import CurriculumDrawerForm from 'components/Curriculum/CurriculumDrawerForm';

const AssignmentDrawer = ({ clients = [], users = [], calendars = [], windows = [], profiles = [], groups = [] }) => {
  const { isClientContext } = useIsClientContext();
  const { item: assignment, isDrawerOpen } = useCurriculumItemProps({
    selector: curriculumUtil.SELECTOR_KEYS.ASSIGNMENTS
  });
  const {
    clientSelections,
    caseManagerSelections,
    calendarSelections,
    windowSelections,
    profileSelections,
    groupSelections,
    packetItems,
    resourcesItems
  } = useFormatAssigneesForUx({
    packet: { title: assignment?.title },
    selections: assignment,
    clients,
    users,
    calendars,
    windows,
    profiles,
    groups
  });

  const itemStyle = useMemo(() => {
    return curriculumUtil.getAssignmentItemStyles(assignment.enrollment_type);
  }, [assignment?.enrollment_type]);

  const classes = useMemo(() => {
    return curriculumUtil.getAssignmentDrawerResourceClasses(assignment?.curriculum_resource ?? []);
  }, [assignment?.curriculum_resource]);

  return (
    <CurriculumDrawerForm
      isDrawerOpen={isDrawerOpen}
      header="View Assignment"
      isSubmitting={false}
      isSubmitDisabled={true}
    >
      <>
        {!isClientContext ? (
          <AssigneesItem header="Enrollment Type" items={[startCase(assignment.enrollment_type)]} style={itemStyle} />
        ) : null}
        <AssigneesItem header="Packet" items={packetItems} />
        <AssigneesItem header="Resources" items={resourcesItems} classes={classes} isDrawer />
        {!isClientContext && assignment?.all_clients ? (
          <AssigneesItem header="Assignees - General" items={['All Clients']} style={itemStyle} />
        ) : (
          <>
            {clientSelections.length ? (
              <AssigneesItem
                header="Assignees - Client(s)"
                items={clientSelections.map(c => c.name)}
                style={itemStyle}
              />
            ) : null}
            {caseManagerSelections.length ? (
              <AssigneesItem
                header="Assignees - Case Manager(s)"
                items={caseManagerSelections.map(cm => cm.name)}
                style={itemStyle}
              />
            ) : null}
            {calendarSelections.length ? (
              <AssigneesItem
                header="Assignees - Calendar(s)"
                items={calendarSelections.map(cal => cal.name)}
                style={itemStyle}
              />
            ) : null}
            {windowSelections.length ? (
              <AssigneesItem
                header="Assignees - Window(s)"
                items={windowSelections.map(w => w.name)}
                style={itemStyle}
              />
            ) : null}
            {profileSelections.length ? (
              <AssigneesItem
                header="Assignees - Profile(s)"
                items={profileSelections.map(p => p.name)}
                style={itemStyle}
              />
            ) : null}
            {groupSelections.length ? (
              <AssigneesItem header="Assignees - Group(s)" items={groupSelections.map(g => g.name)} style={itemStyle} />
            ) : null}
          </>
        )}
      </>
    </CurriculumDrawerForm>
  );
};

const mapStateToProps = ({ calendars, clients, groups, profiles, users, windows }) => ({
  clients: curriculumUtil.buildClientsFromStore(clients?.list ?? []),
  users: users?.list?.map(u => ({ ...u, name: `${u.first_name} ${u.last_name}` })) ?? [],
  windows: windows?.list ?? [],
  groups: groups?.list ?? [],
  profiles: profiles?.list ?? [],
  calendars: calendars?.list ?? []
});

export default connect(mapStateToProps)(AssignmentDrawer);
