/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { startCase } from 'lodash/fp';
/**
 * Internal dependencies
 */
import { getEventTags } from 'actions/actionCreators';
import { catchErrors, getFormTemplateKeys } from 'utils/apiHelpers';
import Input from 'components/Input';
import CustomSelect from 'components/CustomSelect';
import Icon from 'components/Icon';
import './RequirementField.scss';

const customRequirement = name => ({ name });

const FIXED_TYPES = [
  'form',
  'event-check-in',
  'immediate-check-in',
  'manager-user-completion-only',
  'hour-requirement'
];

const fixedRequirementTypes = () => FIXED_TYPES.map(type => customRequirement(type));

const defaultEventTypeState = value => {
  if (!value) {
    return 'form';
  }

  if (!FIXED_TYPES.includes(value)) {
    return 'form';
  }
  return value;
};

const BenchmarkRequirementFieldV2 = ({
  /* eslint-disable no-unused-vars */
  value,
  handleUpdateRequirement,
  index,
  handleRemoveRequirement,
  tags
}) => {
  const initialEventType = defaultEventTypeState(value.type);
  const [requirementTypes] = useState(fixedRequirementTypes());
  const [formTemplateKeys, setFormTemplateKeys] = useState([]);
  const [unitSelected, setUnitSelected] = useState('instances');
  const actionItem = value.action_item ?? '';
  const unitAmount = value.amount ?? '';
  const [eventType, setEventType] = useState(initialEventType);
  const [showFormTypes, setShowFormTypes] = useState(!FIXED_TYPES.includes(value.type));
  const formEventType = value.type ?? 'form';

  const [requirementId] = useState(value.id);

  useEffect(() => {
    getFormTemplateKeys()
      .then(res => {
        if (res.data.status === 'success') {
          setFormTemplateKeys(res.data.template_keys);
        }
      })
      .catch(catchErrors);
  }, []);

  const handleUnitAmountChange = event => {
    const value = event.target.value;

    // send back up
    handleUpdateRequirement(index, getRequirementData(unitSelected, value, eventType, actionItem, eventType));
  };
  const handleActionItemChange = event => {
    const value = event.target.value;

    // send back up
    handleUpdateRequirement(index, getRequirementData(unitSelected, unitAmount, eventType, value, eventType));
  };
  const getRequirementData = (unit, amount, type, action_item, task_type) => {
    const data = {
      unit: type === 'hour-requirement' ? 'hours' : 'instances',
      amount,
      type: type === 'form' ? formEventType : type,
      task_type: task_type,
      action_item
    };
    if (requirementId) {
      data.id = requirementId;
    }
    return data;
  };

  const handleTypeChange = event => {
    const value = event.target.value;
    setEventType(value);
    setShowFormTypes(value === 'form');
    if (value === 'hour-requirement') setUnitSelected('hours');
    else setUnitSelected('instances');
    // send back up
    handleUpdateRequirement(index, getRequirementData(unitSelected, unitAmount, value, actionItem, value));
  };
  const handleFormTypeChange = event => {
    const value = event.target.value;
    // send back up
    handleUpdateRequirement(index, getRequirementData(unitSelected, unitAmount, value, actionItem, 'form'));
  };

  return (
    <div className="benchmark-requirements-field">
      <div className="benchmark-action-item">
        <Input type="text" handleChange={handleActionItemChange} min="1" value={actionItem} />
      </div>
      {unitSelected === 'instances' ? (
        <div className="benchmark-unit-amount">
          <Input type="number" handleChange={handleUnitAmountChange} min="1" value={unitAmount} />
        </div>
      ) : null}
      <div className="benchmark-type-select">
        <CustomSelect
          style={{ textTransform: 'capitalize' }}
          onChange={() => {}}
          name="benchmark-type"
          handleCustomSelectChange={handleTypeChange}
          options={
            !requirementTypes
              ? []
              : requirementTypes.map(requirementType => {
                  return {
                    value: requirementType.name,
                    label: startCase(requirementType.name.replace(/-/g, ' '))
                  };
                })
          }
          value={eventType}
        />
      </div>
      {showFormTypes && (
        <div className="benchmark-type-select">
          <CustomSelect
            style={{ textTransform: 'capitalize' }}
            onChange={() => {}}
            name="benchmark-form-types"
            handleCustomSelectChange={handleFormTypeChange}
            options={
              !formTemplateKeys
                ? []
                : formTemplateKeys.map(requirementType => {
                    return {
                      value: requirementType.name,
                      label: startCase(requirementType.form_name)
                    };
                  })
            }
            value={formEventType}
          />
        </div>
      )}
      {unitSelected === 'hours' ? (
        <div className="benchmark-unit-amount">
          for
          <Input type="number" handleChange={handleUnitAmountChange} min="1" value={unitAmount} />
          hours.
        </div>
      ) : null}

      {index > 0 ? (
        <span className="remove-requirement" onClick={() => handleRemoveRequirement(index)}>
          <Icon name="minus" size={18} />
        </span>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  tags: state.events.tags
});

const mapDispatchToProps = {
  getEventTags
};

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarkRequirementFieldV2);
