const user = (state = false, action) => {
  switch (action.type) {
    case 'SET_USER_DATA':
      return Object.assign({}, action.payload.user);
    case 'UPDATE_UNREAD':
      return { ...state, unread_messages: action.payload.unread };
    case 'MARK_READ': {
      const unread_messages = Math.max(
        0,
        state.unread_messages - action.payload.read
      );
      return { ...state, unread_messages };
    }
    case 'DISMISS_NOTIFICATION': {
      return {
        ...state,
        notifications: { ...state.notifications, [action.payload]: null }
      };
    }
    default:
      return state;
  }
};
export default user;
