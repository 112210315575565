import React from 'react';
import classnames from 'classnames';

import Icon from 'components/Icon';

const ActionButtons = ({ acknowledgeTodo, drawerOpen }) => (
  <div
    className={classnames('vc-container__action-buttons', {
      'drawer-open': drawerOpen
    })}
  >
    <button
      type="button"
      data-action="approve"
      className={'ab-excuse'}
      onClick={acknowledgeTodo}
    >
      <Icon name="checkmark" size="20" />
      &nbsp;
      <span className="ab-status">Acknowledge</span>
    </button>
  </div>
);

export default ActionButtons;
