/* eslint react/display-name: 0 */
import React from 'react';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { get } from 'lodash';

import curriculumUtil from './CurriculumUtil';
import CellList from 'components/Curriculum/CellList';
import ColumnActions from 'components/Curriculum/ColumnActions';
import Icon from 'components/Icon';
import TableLink from 'components/Curriculum/TableLink';
import { Link } from 'react-router-dom';
import TableProps from './TablePropsUtil';
import { isNumeric } from 'lib/util';

class ColumnHelpers {
  static validateCellPropsCallback(row, cellProps = {}, cellPropsCallback) {
    if ('function' === typeof cellPropsCallback) {
      const props = cellPropsCallback(row);

      if ('object' === typeof props && Object.keys(props).length) {
        return { ...cellProps, ...props };
      }
    }

    return cellProps;
  }
}

class CellFns {
  static default(info) {
    const value = info.getValue();

    if (!['', null, undefined].includes(value)) {
      return value;
    }

    return '-';
  }

  static title(info) {
    const title = info.getValue();

    return (
      <Tooltip title={title}>
        <strong>{title}</strong>
      </Tooltip>
    );
  }

  static duration(info) {
    return curriculumUtil.formatDuration(info.getValue());
  }

  static percentage(info) {
    const value = info.getValue();

    if (value) {
      return `${value}%`;
    }

    return '-';
  }

  static list(accessorKey, cellProps = {}, cellPropsCallback, options = {}) {
    return function({ row }) {
      const items = 'function' === typeof cellProps?.mutator ? cellProps.mutator(row) : row?.original?.[accessorKey];

      return (
        <CellList
          items={items}
          type={accessorKey}
          {...ColumnHelpers.validateCellPropsCallback(row, cellProps, cellPropsCallback)}
          options={options}
        />
      );
    };
  }

  static columnActions(cellProps = {}, cellPropsCallback) {
    return function({ row }) {
      return (
        <ColumnActions row={row} {...ColumnHelpers.validateCellPropsCallback(row, cellProps, cellPropsCallback)} />
      );
    };
  }

  static dueInDays({ row }) {
    if (row.original?.enrollment_type === curriculumUtil.ENROLLMENT_TYPES.AUTOMATIC) {
      return (
        <div className="automatic-enrollment">
          <Tooltip title="Automatic assignments do not have deadlines">
            <span>
              <Icon name="infinite" />
            </span>
          </Tooltip>
        </div>
      );
    }

    if (row.original?.enrollment_type === curriculumUtil.ENROLLMENT_TYPES.MANUAL) {
      if (row.original?.start_date && isNumeric(row.original?.due_in_days)) {
        const base = moment(row.original.start_date).add(row.original.due_in_days, 'days');
        const date = base.clone().format(curriculumUtil.DATE_FORMAT_SHORT);
        const time = base
          .clone()
          .endOf('day')
          .format(curriculumUtil.TIME_FORMAT);

        return (
          <div className="stacked">
            <div>{date}</div>
            <div>by {time}</div>
          </div>
        );
      }
    }

    if (!row.original?.client_completion) {
      return (
        <Tooltip
          title={
            <span>
              Assignments with <em>Client Completion</em> turned off do not have deadlines
            </span>
          }
        >
          <span>N/A</span>
        </Tooltip>
      );
    }

    return 'N/A';
  }

  static externalLink(accessorKey) {
    return function({ row }) {
      return <TableLink href={row?.original?.[accessorKey]} />;
    };
  }

  static internalLink(hasAccessTo, feature, hrefCb, label, accessorKey) {
    return function({ row }) {
      const value = row?.original?.[accessorKey];

      if (value) {
        if ('function' !== typeof hasAccessTo || (feature && hasAccessTo(feature))) {
          return <Link to={hrefCb(value)}>{label}</Link>;
        }

        return value;
      }

      return '-';
    };
  }
}

export class AccessorFns {
  static default(row, accessorKey) {
    return row[accessorKey];
  }

  static date(row, accessorKey, shortFormat, customFormat) {
    return curriculumUtil.formatDate(get(row, accessorKey), shortFormat, customFormat);
  }

  static list(row, accessorKey) {
    if (accessorKey in row && Array.isArray(row[accessorKey])) {
      return row[accessorKey].join(' ');
    }
  }

  static object(row, accessorKey, includeKeys) {
    return Object.values(row?.[accessorKey]).join(' ') + includeKeys ? Object.keys(row?.[accessorKey]).join(' ') : '';
  }
}

export class Column {
  header = '';
  accessorKey = '';
  footer = undefined;
  meta = {};
  cell = CellFns.default;
  accessorFn = undefined;
  enableColumnFilter = false;
  enableSorting = true;
  cellProps = {};

  /**
   * @param {string} header
   * @param {string} accessorKey
   */
  constructor(header, accessorKey) {
    this.header = header;
    this.accessorKey = accessorKey;
  }

  setEnableSorting(enabled = true) {
    this.enableSorting = enabled;

    return this;
  }

  setEnableColumnFilter(enabled = true) {
    this.enableColumnFilter = enabled;

    return this;
  }

  isColumnActions(cellPropsCallback) {
    this.cell = CellFns.columnActions(this.cellProps, cellPropsCallback);
    this.setClassName('cell-actions');

    return this;
  }

  isDate(shortFormat = false, customFormat) {
    this.accessorFn = row => AccessorFns.date(row, this.accessorKey, shortFormat, customFormat);
    this.setClassName('date');
    this.sortingFn = (a, b) => {
      return new Date(b.original[this.accessorKey]) - new Date(a.original[this.accessorKey]);
    };
    return this;
  }

  isObject(includeKeys = false) {
    this.accessorFn = row => AccessorFns.object(row, this.accessorKey, includeKeys);
    this.setClassName('object');

    return this;
  }

  isDuration() {
    this.cell = CellFns.duration;

    return this;
  }

  isList(cb, options = {}) {
    this.accessorFn = row => AccessorFns.list(row, this.accessorKey);
    this.cell = CellFns.list(this.accessorKey, this.cellProps, cb, options);

    return this;
  }

  isPercentage() {
    this.cell = CellFns.percentage;

    return this;
  }

  isTitle() {
    this.cell = CellFns.title;
    this.setClassName('title');

    return this;
  }

  isDueInDays() {
    this.cell = CellFns.dueInDays;
    this.setClassName('date');

    return this;
  }

  isExternalLink() {
    this.cell = CellFns.externalLink(this.accessorKey);

    return this;
  }

  isInternalLink(hasAccessTo, feature, hrefCb, label) {
    this.cell = CellFns.internalLink(hasAccessTo, feature, hrefCb, label, this.accessorKey);

    return this;
  }

  setMeta(meta) {
    if ('object' === typeof meta && Object.keys(meta).length) {
      this.meta = { ...this.meta, ...meta };
    }

    return this;
  }

  setCell(cell) {
    if ('function' === typeof cell) {
      this.cell = cell;
    }

    return this;
  }

  setClassName(className) {
    if ('string' === typeof className) {
      this.meta.className = className;
    }

    return this;
  }

  setMetaAttribute(key, value) {
    this.meta[key] = value;

    return this;
  }

  setAccessorFn(accessorFn) {
    if ('function' === typeof accessorFn) {
      this.accessorFn = accessorFn;
    }

    return this;
  }

  setCellProps(cellProps) {
    if ('object' === typeof cellProps && Object.keys(cellProps).length) {
      this.cellProps = cellProps;
    }

    return this;
  }

  clone() {
    return new Column(this.header, this.accessorKey);
  }
}

export class Table {
  columns = [];
  csvHeaders = [];
  tableProps = {};

  constructor() {}
}

export class CsvHeader {
  constructor(label, key, props = {}) {
    this.label = label;
    this.key = key;
    this.props = props;
  }
}

export default class TableBuilder {
  table = new Table();

  constructor() {}

  addColumn(column, cell) {
    if ('function' === typeof cell) {
      column.setCell(cell);
    }

    this.table.columns.push(column);

    if (column.header && column.accessorKey) {
      this.table.csvHeaders.push(new CsvHeader(column.header, column.accessorKey, column.meta));
    }

    return this;
  }

  addColumns(columns = [], cell) {
    for (const column of columns) {
      this.addColumn(column, cell);
    }

    return this;
  }

  setTableProps(props) {
    if (props instanceof TableProps) {
      this.table.tableProps = props.props;
    } else if ('object' === typeof props) {
      this.table.tableProps = props;
    }

    return this;
  }
}
