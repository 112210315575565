import React, { useContext, useMemo } from 'react';
import moment from 'moment';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';
import SideNavSub from './SideNavSub';

const SideNavReports = () => {
  const { hasAccessTo, user: { access_level } = {} } = useContext(AppContext);

  const links = useMemo(() => {
    return [
      { to: '/reports/clients', label: 'Clients', access: hasAccessTo('client_csv_report') },
      {
        to: '/reports/client_overview',
        label: 'Client Overview',
        access: hasAccessTo('case_management_report_client_overview')
      },
      { to: '/reports/detailed', label: 'Detailed' },
      {
        to: `/reports/single/${moment().format('YYYY-MM-DD')}`,
        label: "Today's Randomization",
        access: hasAccessTo('reports')
      },
      { to: '/reports/transcripts', label: 'Transcripts' },
      { to: '/reports/app_logins', label: 'App Logins' },
      {
        to: '/reports/incentives_and_sanctions',
        label: 'Incentives & Sanctions',
        access: hasAccessTo('incentives_and_sanctions')
      },
      {
        to: '/reports/client_app_session_timer',
        label: 'Time Spent in App',
        access: hasAccessTo('client_app_session_timer')
      },
      {
        to: '/reports/bulk_message_history',
        label: 'Bulk Message History',
        access: access_level >= 7
      }
    ];
  }, [hasAccessTo, access_level]);

  if (hasAccessTo('reports')) {
    return (
      <NavLink
        to={hasAccessTo('client_csv_report') ? '/reports/clients' : '/reports/detailed'}
        title="Reports"
        nestedMenu={<SideNavSub title="Reports" links={links} />}
      >
        <Icon name="reports" size={24} />
        <span className="sidenav__item-label">Reports</span>
      </NavLink>
    );
  }

  return null;
};

export default SideNavReports;
