import React from 'react';
import moment from 'moment-timezone';

const NoteHeading = ({ author, date, classes }) => {
  return (
    <div className={classes.heading}>
      <span className={classes.header}>{author}</span>
      <span className={classes.date}>{moment(date).format('MMM DD, YYYY, h:mm a')}</span>
    </div>
  );
};

export default NoteHeading;
