/**
 * External dependencies
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

/**
 * Internal dependencies
 */
import { getLocations } from 'actions/actionCreators';
import { TableHead, Paginator } from 'components/DataTable';
import Loading from 'components/Helpers/Loading';
import { addLocationColumnInfo } from 'utils/addLocationColumnInfo';
import QueryStates from 'components/data/query-states';
import getStates from 'selectors/getStates';
import getClientLocations from 'selectors/getClientLocations';
import Table from 'components/Table';
import Input from 'components/Input';
import Icon from 'components/Icon';
import 'components/CardBlock/style.scss';
import './style.scss';

/**
 * Makes replacements on the link to inject dynamic data in template links.
 *
 * @param {string} baseLink Link where replacements will be made.
 * @param {object} replacements List of replacements. Each prop name found in baseLink is replaced with its value.
 *
 * @returns {string} The link with the replacements made.
 */
const buildLink = (baseLink, replacements) =>
  baseLink.replace(/(%locationId%)/, match => replacements[match]);

/**
 * Filters a list of locations based on the client Id.
 *
 * @param {array} locations The list of locations to filter.
 * @param {string|int} clientId The client Id number.
 *
 * @returns {array} List of locations including only those for the client.
 */
const locationsByCurrentClient = (locations = [], clientId) =>
  locations.filter(
    location => parseInt(location.client_id) === parseInt(clientId)
  );

/**
 * Filters a list of locations for facility scope.
 *
 * @param {array} locations The list of locations to filter.
 *
 * @returns {array} List of locations for with facility scope.
 */
const locationsWithFacilityScope = (locations = []) =>
  locations.filter(location => location.scope === 'facility');

class LocationsTable extends Component {
  static propTypes = {
    clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onEdit: PropTypes.func,
    onLocationUpdate: PropTypes.func,
    editLink: PropTypes.string,
    receivesLocations: PropTypes.bool,

    // Connected props
    states: PropTypes.object.isRequired,
    locations: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]).isRequired // this can be set either by connect() or passed as a prop
  };
  static defaultProps = {
    editLink: '',
    receivesLocations: false,
    locations: []
  };

  state = {
    locations: [],
    shownLocations: [],
    sort: {
      name: 'name',
      type: 'asc'
    },
    limit: {
      value: 25
    },
    name: '',
    location: '',
    scope: '',
    isLoading: false,
    currentPage: 0,
    perPage: 25,
    isSearch: false
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    const { locations, receivesLocations } = this.props;
    // When used in Location > Zones, don't fetch locations: they will be provided by Zones
    if (receivesLocations) {
      this.saveLocationsToState();
      this.setState({ isLoading: false });
    } else {
      const { getLocations, clientId } = this.props;
      getLocations(Object.assign(locations, { clientId }), () => {
        this.saveLocationsToState();
        this.setState({ isLoading: false });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.states !== this.props.states ||
      get(prevProps.locations, 'length', 0) !==
        get(this.props.locations, 'length', 0) ||
      JSON.stringify(prevProps.locations) !==
        JSON.stringify(this.props.locations)
    ) {
      this.saveLocationsToState();
    }
  }

  saveLocationsToState = () => {
    const { clientId, locations: list, states, hasAccessTo } = this.props;
    const locationList = hasAccessTo('constant_location_tracking')
      ? clientId
        ? locationsByCurrentClient(list, clientId)
        : locationsWithFacilityScope(list)
      : list;

    if (locationList) {
      // prepare our data so location can show something in the location field
      const preparedList = locationList.map(location => {
        return addLocationColumnInfo(location, states);
      });
      this.setState(
        { locations: preparedList, shownLocations: preparedList },
        this.onLocationUpdate
      );
    }
  };

  /**
   * Runs when the location data finished loading and it's in the state.
   * This is useful to update surrounding elements like a map that relies on retrieved locations.
   */
  onLocationUpdate = () => {
    if (this.props.onLocationUpdate) {
      this.props.onLocationUpdate(this.state.shownLocations);
    }
  };

  handleChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, () =>
      this.handleColChange({ target: { name, value } })
    );

  handleColChange = debounce(event => {
    const { value, name } = event.target;
    const { locations } = this.state;
    const shownLocations = locations.filter(
      location => location[name].toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    this.setState({ shownLocations, isSearch: true });
  }, 200);

  /**
   * Saves the entries that will be displayed based on the current page.
   */
  setEntriesToShow = () => {
    const base = this.state.currentPage * this.state.perPage;
    this.setState({
      shownLocations: this.state.locations.slice(
        base,
        base + this.state.perPage
      )
    });
  };

  /**
   * Save to state the new current page.
   *
   * @param {number} newPage The new current page.
   *
   * @returns {void}
   */
  changePage = newPage =>
    this.setState({ currentPage: newPage }, this.setEntriesToShow);

  /**
   * Sort items by date.
   *
   * @param {object} sort Pair that specifies the desired sort direction and the column to use as for sorting.
   */
  sortColumn = ({ name: newName }) => {
    const {
      sort: { type }
    } = this.state;
    let sortBy = 1;
    if ('desc' === type) {
      sortBy = -1;
    }

    const locations = this.state.locations.sort((a, b) => {
      if (a[newName] < b[newName]) return -1 * sortBy;
      if (a[newName] > b[newName]) return 1 * sortBy;
      return 0;
    });

    this.setState(
      {
        locations,
        sort: { type: 'desc' === type ? 'asc' : 'desc', name: newName }
      },
      this.setEntriesToShow
    );
  };

  /**
   * Update value of Display select box when it changes.
   *
   * @param {object} e The event.
   *
   * @returns {void}
   */
  onDisplayChange = e =>
    this.setState(
      { perPage: parseInt(e.target.value), currentPage: 0 },
      this.setEntriesToShow
    );

  render() {
    if (this.props.isLoading || isEmpty(this.props.states)) {
      return (
        <Fragment>
          <Loading />
          <QueryStates />
        </Fragment>
      );
    }

    const {
      shownLocations,
      locations,
      sort,
      isLoading,
      currentPage,
      limit
    } = this.state;
    const { editLink, hasAccessTo } = this.props;

    const hasLocationTracking = hasAccessTo('constant_location_tracking');

    const headers = [
      { sortCol: 'name', children: 'NAME' },
      { sortCol: 'location', children: 'LOCATION' }
    ];

    if (!hasLocationTracking) {
      headers.push({ sortCol: 'scope', children: 'SCOPE' });
    }

    const headerProps = { sort, handleSort: this.sortColumn };

    const pageinatorProps = {
      leftDisabled: currentPage + 1 === 1,
      rightDisabled:
        Math.ceil(locations.length / limit.value) === currentPage + 1
    };

    if (0 === shownLocations.length && !this.state.isSearch) {
      return null;
    }

    if (this.props.mapOnly) {
      return null;
    }

    return isLoading ? (
      <>
        <Loading />
        <QueryStates />
      </>
    ) : (
      <>
        <Table.BaseTable className="locations-table">
          <TableHead {...headerProps} headers={headers} />
          <Table.TableHead>
            <Table.TableRow>
              <Table.TableHeader className="with-input">
                <Input
                  handleChange={event => {
                    event.persist();
                    this.handleChange(event);
                  }}
                  name="name"
                  placeholder="Name"
                  value={this.state.name}
                  autoFocus
                />
              </Table.TableHeader>
              <Table.TableHeader className="with-input">
                <Input
                  handleChange={event => {
                    event.persist();
                    this.handleChange(event);
                  }}
                  name="location"
                  value={this.state.location}
                  placeholder="Location"
                />
              </Table.TableHeader>
              {!hasLocationTracking ? (
                <Table.TableHeader className="with-input">
                  <Input
                    handleChange={event => {
                      event.persist();
                      this.handleChange(event);
                    }}
                    name="scope"
                    value={this.state.scope}
                    placeholder="Scope"
                  />
                </Table.TableHeader>
              ) : null}
            </Table.TableRow>
          </Table.TableHead>
          <Table.TableBody>
            {shownLocations.length
              ? shownLocations.map(location => {
                  return (
                    <Table.TableRow
                      key={location.id}
                      onClick={
                        editLink
                          ? () =>
                              this.props.history.push(
                                buildLink(editLink, {
                                  '%locationId%': location.id
                                })
                              )
                          : undefined
                      }
                    >
                      <Table.TableCell>
                        <Icon name="edit" />
                        <span className="location-name">{location.name}</span>
                      </Table.TableCell>
                      <Table.TableCell>{location.location}</Table.TableCell>
                      {!hasLocationTracking ? (
                        <Table.TableCell>
                          {location.scope.charAt(0).toUpperCase() +
                            location.scope.slice(1)}
                        </Table.TableCell>
                      ) : null}
                    </Table.TableRow>
                  );
                })
              : null}
          </Table.TableBody>
        </Table.BaseTable>
        <Paginator
          {...pageinatorProps}
          currentPage={this.state.currentPage}
          pages={Math.ceil(locations.length / limit.value) || 25}
          changePage={this.changePage}
        />
      </>
    );
  }
}

const mapStateToProps = (state, { clientId }) => {
  const clientLocations = getClientLocations(state, clientId) || [];
  return {
    locations: clientId
      ? clientLocations
      : state.locations?.list?.filter(l => l?.scope === 'facility') ?? [],
    states: getStates(state)
  };
};

export default withRouter(
  connect(mapStateToProps, { getLocations })(LocationsTable)
);
