import React from 'react';
import NoRecords from 'components/NoRecords';

const TableBody = ({ children, customMessage = '', ...props }) => (
  <tbody
    className={`base-tbody${props.className ? ` ${props.className}` : ''}`}
  >
    {children !== null ? children : <NoRecords customMessage={customMessage} />}
  </tbody>
);

export default TableBody;
