/**
 * External dependencies
 */
import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import Button from 'components/Button';
import Icon from 'components/Icon';

const Toast = ({ children, remove, duration = 5000 }) => {
  const removeRef = useRef();
  removeRef.current = remove;

  useEffect(() => {
    const id = setTimeout(() => removeRef.current(), duration);

    return () => clearTimeout(id);
  }, [duration]);

  return (
    <div
      className={classnames('toast', {
        checkmark: children?.type === 'checkmark',
        warning: children?.type === 'warning'
      })}
    >
      <div className="toast__message">
        <Icon name={children.type ? children.type : 'warning'} />
        <p className="toast__message--text">{children?.content ?? children}</p>
      </div>
      <Button handleButtonClick={remove} none className="toast__close-btn">
        <Icon name="close" />
      </Button>
    </div>
  );
};

export default Toast;
