import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';

import Loading from 'components/Helpers/Loading';
import Note from 'components/Clients/ClientNotes/Note';
import { NotesContext } from 'components/Clients/ClientNotes/NotesContext';
import EmptyTable from 'components/Curriculum/EmptyTable';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18
  }
}));

const NotesList = () => {
  const { list } = useContext(NotesContext);
  const classes = useStyles();

  if (list === undefined) {
    return <Loading />;
  }

  if (!list.length) {
    return <EmptyTable message={['No notes for this client']} noShadow />;
  }

  return (
    <div className={classes.container}>
      {list.map((note, index) => (
        <Note key={note.id} note={note} index={index} />
      ))}
    </div>
  );
};

export default NotesList;
