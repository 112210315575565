import { useContext, useMemo } from 'react';

import { AppContext } from 'components/Context/AppContext';

const useHasAccess = feature => {
  const { hasAccessTo } = useContext(AppContext);

  return useMemo(() => hasAccessTo(feature), [hasAccessTo, feature]);
};

export default useHasAccess;
