/**
 * External dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Icon from 'components/Icon';
import './style.scss';

class Notice extends Component {
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    dismiss: PropTypes.func.isRequired,
    notifications: PropTypes.shape({
      all_pages: PropTypes.shape({
        id: PropTypes.number.isRequired,
        message_body: PropTypes.string.isRequired,
        dismissable: PropTypes.bool.isRequired
      }),
      dashboard: PropTypes.shape({
        id: PropTypes.number.isRequired,
        message_body: PropTypes.string.isRequired,
        dismissable: PropTypes.bool.isRequired
      })
    })
  };

  state = { dismissing: false, urgent: false };

  handleDismiss = (id, notification_type) => {
    this.setState({ dismissing: true });
    this.props.dismiss(id, notification_type);
    setTimeout(() => this.setState({ dismissing: false }), 1000);
  };

  render() {
    const { dashboard, all_pages } = this.props.notifications;
    const { dismissing } = this.state;

    if (!dashboard && !all_pages) {
      return false;
    }

    const message = all_pages?.id
      ? all_pages
      : dashboard?.id && this.props.pathname === '/dashboard'
      ? dashboard
      : {};

    const notification_type = all_pages ? 'all_pages' : 'dashboard';

    const { message_body, dismissable, id, message_type } = message;

    return message.message_type && message.message_body ? (
      <div
        className={'notice alert-box radius ' + message_type}
        style={{ opacity: dismissing ? 0.4 : 1 }}
      >
        <span dangerouslySetInnerHTML={{ __html: message_body }} />
        {dismissable && (
          <a
            onClick={() => this.handleDismiss(id, notification_type)}
            className="close"
          >
            <Icon name="close" />
          </a>
        )}
      </div>
    ) : null;
  }
}
export default Notice;
