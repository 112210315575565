import { makeStyles, darken } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    [theme.breakpoints.up('2xl')]: {
      flexDirection: 'row'
    },
    ' & .MuiTypography-h6': {
      fontWeight: 500,
      fontSize: 18,
    }
  },
  side: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    flex: 1,
    '&:first-of-type': {
      [theme.breakpoints.up('2xl')]: {
        minWidth: 275,
        maxWidth: '30%'
      }
    }
  },
  bordered: {
    padding: 18,
    border: '1px solid #ccc',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    position: 'relative',
    '& > *': {
      margin: 0
    },
    '& .dynamo-image-container.loaded': {
      height: 'fit-content',
    }
  },
  imageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 18,
    '& .MuiButtonBase-root': {
      backgroundColor: '#fff',
      border: '1px solid #00819B',
      borderRadius: 2,
      '& .MuiIconButton-label': {
        color: '#00819B'
      },
      '&:hover': {
        backgroundColor: darken('#fff', 0.015),
      }
    }
  },
  drawerContainer: {
    padding: '0 1rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}));