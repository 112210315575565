/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * Internal dependencies
 */
import { setTermsOfServiceAccepted } from 'actions/actionCreators';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { useToast } from 'components/Toast';
import './style.scss';

export const TermsOfService = ({
  userId,
  show,
  url,
  accepted,
  setTermsOfServiceAccepted,
  tosMessage,
  tosId
}) => {
  const [showModal, setShowModal] = useState(show);

  const toast = useToast();

  /**
   * Track prop changes
   */
  useEffect(() => {
    if (accepted === true) {
      toast.addToast({
        duration: 3000,
        type: 'checkmark',
        content: 'Terms of Service accepted. Thank you!'
      });
    } else if (accepted === false) {
      toast.addToast({
        duration: 3000,
        type: 'warning',
        content: 'An error has occurred saving your terms of service state'
      });
    }
  }, [accepted]);

  /**
   * Called when the terms are accepted.
   * Dispatches the redux action and closes the modal.
   */
  const acceptTerms = () => {
    setTermsOfServiceAccepted(userId, tosId);
    setShowModal(false);
  };

  return (
    <div>
      {showModal ? (
        <Modal
          isShowing={showModal}
          header="Terms of Use"
          className="tos-modal"
        >
          {tosMessage ? (
            <div
              className="modal-message"
              dangerouslySetInnerHTML={{ __html: tosMessage }}
            ></div>
          ) : (
            <div className="modal-message">
              We&apos;ve updated our{' '}
              <a
                className="tos-link"
                target="_blank"
                rel="noopener noreferrer"
                href={url}
              >
                Terms of Use
              </a>
              . By continuing to use Reconnect you agree to the updated terms of
              service.
            </div>
          )}
          <div className="action-buttons">
            <Button
              primary
              handleButtonClick={acceptTerms}
              className="tos-accept-btn"
            >
              OK
            </Button>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

TermsOfService.defaultProps = {
  show: false,
  accepted: null
};

TermsOfService.propTypes = {
  userId: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  setTermsOfServiceAccepted: PropTypes.func.isRequired,
  tosMessage: PropTypes.string.isRequired,
  tosId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  accepted: state.termsOfService.accepted,
  tosMessage: state.user.tos_accepted.message ?? '',
  tosId: state.user.tos_accepted.tos_id ?? -Infinity
});

const mapDispatchToProps = {
  setTermsOfServiceAccepted
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfService);
