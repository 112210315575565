import React, { useContext } from 'react';

import { AppContext } from 'components/Context/AppContext';
import Icon from 'components/Icon';
import NavLink from './NavLink';
import SideNavSub from './SideNavSub';

const SideNavForms = () => {
  const { hasAccessTo } = useContext(AppContext);

  if (hasAccessTo('schedule_management')) {
    return (
      <NavLink
        to="/forms"
        title="Forms"
        nestedMenu={
          <SideNavSub
            title="Forms"
            links={[
              { to: '/forms', label: 'Overview' },
              { to: '/forms/new', label: 'Assign a Form' },
              { to: '/forms/open', label: 'Open Forms' },
              { to: '/forms/archive', label: 'Form Responses' },
              {
                to: '/forms/client-initiated',
                label: 'Client-Scheduled Forms'
              }
            ]}
          />
        }
      >
        <Icon name="forms" size={24} />
        <span className="sidenav__item-label">Forms</span>
      </NavLink>
    );
  }

  return null;
};

export default SideNavForms;
