import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { isEmpty, pickBy } from 'lodash';

import Input from 'components/Input';
import CustomSelect from 'components/CustomSelect';
import Button from 'components/Button';
import getDrawerProps, { getIsDrawerOpen } from 'selectors/getDrawerProps';
import { Options } from 'utils/TestingUtil';
import ReportRowBuilder from './ReportRowBuilder';

const ReportForm = ({ state = {}, handleChange, handleButtonClick, isSaveDisabled = true }) => {
  const drawerOpen = useSelector(getIsDrawerOpen);
  const {
    block: {
      editClientResults,
      editClientNotes,
      selectOptions,
      result_data: resultData,
      hideResultsAndNotes = false,
      panelsOptions,
      substancesOptions,
      waitingStatusOptions
    } = {}
  } = useSelector(getDrawerProps);

  const disabledStates = useMemo(() => {
    if (!hideResultsAndNotes && !resultData) {
      const base = { ...Options.KEYS };

      base.result = !editClientResults;
      base.panels = !editClientResults;
      base.panels_other = !editClientResults || state.panels !== 'Other';
      base.substances = !editClientResults || state.result !== 'fail';
      base.substances_other = !editClientNotes || !state.substances.includes('Other') || state.result !== 'fail';
      base.waiting_status = !editClientResults || state.result !== 'fail';
      base.notes = !editClientNotes;

      return base;
    }

    return {};
  }, [editClientNotes, editClientResults, hideResultsAndNotes, resultData, state]);

  const rows = useMemo(() => {
    if (!hideResultsAndNotes && !resultData) {
      return [
        new ReportRowBuilder('Results', CustomSelect, Options.KEYS.result)
          .setOptions(selectOptions),
        new ReportRowBuilder('Panel Used', CustomSelect, Options.KEYS.panels)
          .setOptions(panelsOptions),
        new ReportRowBuilder('Panel Used - Other', Input, Options.KEYS.panels_other)
          .setIsInput()
          .setRequired(state.panels === 'Other'),
        new ReportRowBuilder('Substances Found', CustomSelect, Options.KEYS.substances)
          .setIsMultiple()
          .setOptions(substancesOptions)
          .setRequired(state.result === 'fail'),
        new ReportRowBuilder('Substances - Other', Input, Options.KEYS.substances_other)
          .setRequired(state.substances.includes('Other'))
          .setIsInput(),
        new ReportRowBuilder('Waiting on Lab Confirmation', CustomSelect, Options.KEYS.waiting_status)
          .setOptions(waitingStatusOptions)
          .setRequired(state.result === 'fail'),
        new ReportRowBuilder('Notes', Input, Options.KEYS.notes)
          .setIsInput()
          .setExtra({ Component: 'textarea' })
      ];
    }

    return [];
  }, [
    state,
    hideResultsAndNotes,
    resultData,
    selectOptions,
    panelsOptions,
    substancesOptions,
    waitingStatusOptions
  ]);

  const errors = useMemo(() => {
    const result = state[Options.KEYS.result];
    const panels = state[Options.KEYS.panels];
    const panelsOther = state[Options.KEYS.panels_other];
    const substances = state[Options.KEYS.substances];
    const substancesOther = state[Options.KEYS.substances_other];
    const waitingStatus = state[Options.KEYS.waiting_status];

    const base = {
      [Options.KEYS.result]: !result,
      [Options.KEYS.panels]: !panels,
      [Options.KEYS.panels_other]: panels === 'Other' && !panelsOther,
      [Options.KEYS.substances]: result === 'fail' && !substances.length,
      [Options.KEYS.substances_other]: result === 'fail' && substances.includes('Other') && !substancesOther,
      [Options.KEYS.waiting_status]: result === 'fail' && !waitingStatus,
      [Options.KEYS.notes]: false
    }

    return pickBy(base, (value) => !!value);
  }, [state]);

  if (!hideResultsAndNotes && !resultData) {
    return (
      <div className="vc-container__scroll-wrapper--elements">
        {rows.map(({ Component, required, isInput, props }) => {
          props[isInput ? 'handleChange' : 'handleCustomSelectChange'] = handleChange;

          return required ? (
            <div className="vc-container__form" key={props.name}>
              <Component
                {...props}
                value={state[props.name]}
                disabled={disabledStates[props.name]}
                required={required}
                error={errors?.[props?.name] ? 'This is a required field' : ''}
              />
            </div>
          ) : null
        })}
        <div className={classnames('vc-container__action-buttons', { 'drawer-open': drawerOpen })}>
          <Button disabled={!isEmpty(errors) || isSaveDisabled} handleButtonClick={handleButtonClick} primary>
            Save
          </Button>
        </div>
      </div>
    );
  }

  return null;
}

export default ReportForm;