import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import { AppContext } from 'components/Context/AppContext';
import { AuthContext } from 'components/Context/AuthContext';

import NoAccessYet from 'components/Helpers/NoAccessYet';

/**
 * This component conditionally renders either `NoAccessYet` with an optional fallback message,
 * else the provided component. Rendered components receive all route and Context.Consumer props.
 * @param {Component} component, the component to possibly render
 * @param {String} path, route string (not URL), as in /something/:id/:date
 * @param {Boolean} exact, whether the route path should match exactly
 * @param {Array} feature, an array of possibly access restrictions in the shape of: string, boolean, or function
 * @param {String} fallbackMessage, optional fallback message to display to users prior to being redirected
 * @param {String} redirect, optional redirect URL (defaults to `/`)
 * @param {any} props, additional props passed directly on subscriping route component children
 * @return {Component}, one of NoAccessYet or the provided component, dependent on access restrictions
 *
 * @todo: consider limiting the context values a component receives. For example, components may provided an
 * array of strings, such as `hasAccessTo`, and these can be dynamically constructed at the time of render
 * against the `context` object itself. Something like:
 *
 * ```javascript
 * const contextProps = {};
 *
 * subscriptions.forEach(sub => !!context[sub] ? context[sub] = context[sub]);
 *
 * return (
 *  <Component
 *    {...{ ...props, ...route, ...context }}
 *  />
 * );
 *
 * ```
 */

const ProtectedRouteConsumer = ({
  component: Component,
  path,
  title,
  exact,
  feature,
  fallbackMessage,
  redirect,
  key,
  ...props
}) => {
  const authContext = useContext(AuthContext);
  const appContext = useContext(AppContext);

  useEffect(() => {
    document.title = `Reconnect · ${title}`;
  }, [title]);

  if (!authContext?.isAuthenticated()) {
    return <Redirect to="/login" />;
  }

  if (!appContext.hasAccessToMulti(feature)) {
    return <NoAccessYet fallbackMessage={fallbackMessage} redirect={redirect} />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      key={key || path}
      render={renderProps => <Component {...{ ...props, ...renderProps, ...appContext }} />}
    />
  );
};

ProtectedRouteConsumer.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.element, PropTypes.node]),
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  exact: PropTypes.bool,
  feature: PropTypes.array,
  fallbackMessage: PropTypes.string,
  redirect: PropTypes.string,
  props: PropTypes.object,
  title: PropTypes.string.isRequired
};

export default ProtectedRouteConsumer;
